/* global gapi */
import React, { Component } from 'react';
import logoPS from '../../assets/logo-ps-w.png';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import { Icon } from 'react-icons-kit'
import { ic_notifications } from 'react-icons-kit/md';
import {ic_exit_to_app} from 'react-icons-kit/md/ic_exit_to_app'

class IHeader extends Component {
    constructor(props) {
      super(props);
      this.state = {
        userFullname: '',
        userPicture: '',
        userPosition: '',
        notifCount: 0
      };
      this.handleClick = this.handleClick.bind(this);
      this.handleLoad = this.handleLoad.bind(this);
    }
    
    handleClick(ln) {    
        if(ln==='logout') {
         var auth2 = gapi.auth2.getAuthInstance();
         auth2.signOut().then(function () {
           auth2.disconnect();
           localStorage.clear();
           alert('User signed out.');
           window.location.reload();
         }).catch(err => {
            console.log(err);
            localStorage.clear();
         }); 
        }
    }

    handleLoad() {
        try{
            var userPosition = "Staf", userFullName ="";
            if(localStorage.getItem('uposition') !== '') { userPosition = localStorage.getItem('uposition'); }
            if(localStorage.getItem('ufullname') !== '') { userFullName = localStorage.getItem('ufullname'); }
            gapi.load('auth2', () => {
                this.auth2 = gapi.auth2.init({
                    client_id:  global.config.credClientId,
                });
                this.auth2.then((res) => {
                    var ggleUser = res.currentUser.get().getBasicProfile();
                    this.setState({
                        userFullname: userFullName,
                        userPicture: ggleUser.getImageUrl(),
                        userPosition: userPosition,
                    });
                });
            }); 
        } catch (e) {
            console.log(e);
            localStorage.clear();
        }
    }

    componentDidMount() {
        window.addEventListener('load',this.handleLoad);
    }
    render() { 
      return (
           <div className="flex-container">
               <div className="flex-container header-left">
                    <a href="/"><img src={logoPS} className="header-logo" alt="logo"/></a>
                    <div className="spacer"></div>
                    <div className="header-logo-t divCenter">Internal Portal</div>
               </div>
               <div className="header-menu divCenter">
                  <div className="mgR30 flex-container">
                    <div className="mgR20 divMiddle">
                    {(localStorage.getItem('ulevel') !== undefined && localStorage.getItem('ulevel') === global.config.userlevel[0])?<div className="header-icon cursor-on flex-container divMiddle"><Icon icon={ic_notifications} size={25} className="iconDark" onClick={() => this.handleClick('logout')}/></div>: ''}
                    </div>
                    <div className="mgR30 flex-container header-profile">
                        <div className="mgR10"><img src={this.state.userPicture} className="header-picprofile" alt={this.state.userFullname}/></div>
                        <div className="divMiddle">
                            <div className="textBold">{this.state.userFullname}</div>
                            <div>{this.state.userPosition}</div>
                        </div>
                        
                    </div>
                    <div className="divMiddle">
                        <div className="header-icon cursor-on divMiddle" onClick={() => this.handleClick('logout')}><Icon icon={ic_exit_to_app} size={25} className="iconDark"/></div>
                    </div>
                  </div>
                   
               </div>
           </div>
       
      );
    }
   }
    
   export default IHeader;