import React, { Component } from 'react';
import axios from 'axios';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[9];
const errmessage = ["please upload document only", "your uploaded file is more than 10Mb.","please upload image only"];
function uploadThumbnail(libid,liblesid,filedoc2) {
    return new Promise((resolve, reject) => {
        if(filedoc2 !== '' && filedoc2 !== null && (filedoc2.type.indexOf('image') > -1)) {
                const data = new FormData() 
                data.append('file', filedoc2);
                data.append('docid', libid);
                data.append('subdocid', liblesid);
                data.append('index', 1);
                data.append('source', 'library-attach');
                data.append('filetype',filedoc2.type);
                axios.post(global.config.urlLink+"/uploadfile", 
                data, { 
                    headers: { 
                        'content-type': 'multipart/form-data',
                        'Authorization': authKey
                    }
                }).then(res => { 
                    if(res.data.status === "200") {
                        resolve(res.data.values.linkfile1);
                    } else {
                        console.log("Something happened wrong");
                        resolve("");
                    }
                }).catch(err => { 
                    console.log(err); 
                    resolve("");
                })
        } else { resolve(""); }
    })
}

class ILibraryAttachForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            library_id: '',
            library_title: '',
            library_attach_id: '',
            libfile_name: '',
            libfile_id: '',
            libfile_url: '',
            file_url: '',
            selectedFileDocument: null,
            selectedFileDocument2: null,
            formErrors: { selectedFileDocument: '', file_url: '', selectedFileDocument2: '' },
            selectedFileDocumentValid: false,
            selectedFileDocument2Valid: false,
            file_urlValid: false,
            formValid: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let selectedFileDocumentValid = this.state.selectedFileDocumentValid;
        let file_urlValid = this.state.file_urlValid;
        let selectedFileDocument2Valid = this.state.selectedFileDocument2Valid;
        switch(fieldName) {
            case 'selectedFileDocument': 
                if(this.state.file_url === "") {
                    selectedFileDocumentValid = value !== null;
                    fieldValidationErrors.selectedFileDocument = selectedFileDocumentValid ? '' : 'file need to be selected';
                }
                break;
            case 'file_url': 
                if(this.state.selectedFileDocument === null || this.state.selectedFileDocument === "") {
                    file_urlValid = value !== null;
                    fieldValidationErrors.file_url = file_urlValid ? '' : 'file need to be filled';
                }
                break;
            case 'selectedFileDocument2': 
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        selectedFileDocumentValid: selectedFileDocumentValid,
                        file_urlValid: file_urlValid,
                        selectedFileDocument2Valid: selectedFileDocument2Valid,
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.selectedFileDocumentValid && this.state.file_urlValid && this.state.selectedFileDocument2Valid });
    }
    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    
    onChangeFileHandler = (e) => {
        if(e.target.files[0] !== undefined) {
            var inputType = e.target.files[0].type;
            var inputValue = e.target.files[0];
            var name = ''; 
            var validname = '';
            let fieldValidationErrors = this.state.formErrors;
            let selectedFileValid = false, selectedFileSizeValid = false;
            if(e.target.id === "fileDocument2") {
                if(inputType.indexOf('image') > -1) { selectedFileValid = true; }
                else { selectedFileValid = false; }
                name =  'selectedFileDocument2';
                validname = 'selectedFileDocument2Valid';
                fieldValidationErrors.selectedFileDocument2 = selectedFileValid ? '' : errmessage[2];
            } else {
                if(inputType.indexOf('pdf') > -1 || inputType.indexOf('video') > -1) {
                    selectedFileValid = true;
                    if(inputValue.size < 10000000) {
                        selectedFileSizeValid = true;
                    } else {
                        selectedFileSizeValid = false;
                    }
                } else {
                    selectedFileValid = false;
                }
                name =  'selectedFileDocument';
                validname = 'selectedFileDocumentValid';
                if(selectedFileValid === false) { fieldValidationErrors.selectedFileDocument = errmessage[0]; }
                else { 
                    if(selectedFileSizeValid === false) { fieldValidationErrors.selectedFileDocument = errmessage[1]; } 
                    else { fieldValidationErrors.selectedFileDocument = ''; }
                }
            }
            this.setState({
                [name]: inputValue,
                formErrors: fieldValidationErrors,
                [validname] : selectedFileValid
            }, this.validateForm);
        } else {
            if(e.target.id === "fileDocument2") { 
                this.setState({
                    selectedFileDocument2: null,
                    formErrors: { selectedFileDocument2: '' },
                    selectedFileDocument2Valid: false,
                    formValid: false
                }); 
            } else { 
                this.setState({
                    selectedFileDocument: null,
                    formErrors: { selectedFileDocument: '', file_url: '' },
                    selectedFileDocumentValid: false,
                    formValid: false
                }); 
            }
        }
    }

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    
    
    handleSubmit(e) {
        e.preventDefault();
        if(this.state.selectedFileDocument !== '' && this.state.selectedFileDocument !== null) {
            if(this.state.selectedFileDocument.type.indexOf('pdf') > -1 || this.state.selectedFileDocument.type.indexOf('video') > -1) { 
                document.getElementById('loading-area').style.display = 'block'; 
                var selectedFileValue = this.state.selectedFileDocument;
                if (selectedFileValue.size < 10000000) {
                    const data = new FormData() 
                    data.append('file', selectedFileValue);
                    data.append('docid', this.state.library_id);
                    data.append('subdocid', this.state.library_lesson_id);
                    data.append('index', 1);
                    data.append('source', 'library-attach');
                    data.append('filetype',selectedFileValue.type);

                    if(this.state.selectedFileDocument2 !== '' && this.state.selectedFileDocument2 !== null) {
                        if(this.state.selectedFileDocument2.type.indexOf('image') > -1) {
                            data.append('file2', this.state.selectedFileDocument2);
                        }
                    }
                    axios.post(global.config.urlLink+"/uploadfiledoc", 
                    data, { 
                        headers: { 
                            'content-type': 'multipart/form-data',
                            'Authorization': authKey
                        }
                    }).then(res => { 
                        document.getElementById('loading-area').style.display = 'none'; 
                        window.location.href='/IEditDetailLibrary/'+this.state.library_id;
                    }) 
                } else {
                    document.getElementById('loading-area').style.display = 'none';
                    let fieldValidationErrors = this.state.formErrors;
                    let selectedFileValid = false
                    fieldValidationErrors.selectedFileDocument = errmessage[1];
                    fieldValidationErrors.file_url = '';
                    this.setState({
                        selectedFileDocument: this.state.selectedFileDocument,
                        formErrors: fieldValidationErrors,
                        selectedFileDocumentValid : selectedFileValid,
                        file_urlValid : selectedFileValid
                    }, this.validateForm);
                }
            } else {
                document.getElementById('loading-area').style.display = 'none'; 
                let fieldValidationErrors = this.state.formErrors;
                let selectedFileValid = false
                fieldValidationErrors.selectedFileDocument = errmessage[0];
                fieldValidationErrors.file_url = '';
                this.setState({
                    selectedFileDocument: this.state.selectedFileDocument,
                    formErrors: fieldValidationErrors,
                    selectedFileDocumentValid : selectedFileValid,
                    file_urlValid : selectedFileValid
                }, this.validateForm);
            }
        } else if(this.state.file_url !== '' && this.state.file_url !== null) {
            document.getElementById('loading-area').style.display = 'block'; 
            
            uploadThumbnail(this.state.library_id,this.state.library_lesson_id,this.state.selectedFileDocument2)
            .then((res) => {
                var datatext, fileUrl = '', fileKey = 0, fileId = '';
                if(this.textInputFileUrl.value === '') { fileUrl = this.state.file_url; } else { fileUrl = this.textInputFileUrl.value; }
                var urls = fileUrl.split("/");
                urls.forEach((element, key) => {
                    if (element === "d") fileKey = key + 1;
                });
                fileId = urls[fileKey];
                datatext ='{"docid":"'+this.state.library_id+'","subdocid":"'+this.state.library_lesson_id+'","fileid":"'+fileId+'","fileurl":"https://drive.google.com/file/d/'+fileId+'/preview","filethumb":"'+res+'","form_id":"form-addattachment"}';
                var data = JSON.parse(datatext);

                fetch(global.config.urlLink+"/libraryattachmentadd", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey,
                    }, body : JSON.stringify(data)
                }).then(res => {
                    if(res.status === 200) 
                        return res.json() 
                }).then( resJson => {
                    document.getElementById('loading-area').style.display = 'none'; 
                    if(resJson.status === '200') {
                        window.location.href='/IEditDetailLibrary/'+this.state.library_id;
                    } else {
                        console.log('Something happened wrong');
                        this.setState({
                            formErrors: { selectedFileDocument: '', file_url: resJson.values[1] },
                            selectedFileDocumentValid : false,
                            file_urlValid : false
                        }, this.validateForm);
                    }
                }).catch(err => { 
                    document.getElementById('loading-area').style.display = 'none'; 
                });
            })

        } else {
            var arrfieldName = ['selectedFileDocument'];
            var arrvalue = [this.state.selectedFileDocument];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    componentDidMount() {
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        this.setState({
            library_id: othersContent.libraryId,
            library_lesson_id: othersContent.libraryLessonId,
            library_title: othersContent.libraryTitle,
            library_attach_id: othersContent.libattachid,
            libfile_name: '',
            libfile_id: '',
            libfile_url: '',
            file_url: '',
            selectedFileDocument: null,
            selectedFileDocument2: null,
            formErrors: { selectedFileDocument: '', file_url: '', selectedFileDocument2: '',  },
            selectedFileDocumentValid: false,
            selectedFileDocument2Valid: false,
            file_urlValid: false,
            formValid: false
        }); 
    }

    render() {
        return (
            <div className="main-container">
                <div className="mgT30">
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <input type="hidden" id="library_id" name="library_id" value={this.state.library_id} />
                        <input type="hidden" id="library_attach_id" name="library_attach_id" value={this.state.library_attach_id} />
                    </div>
                    <div className="row-field mgT10 st4Title">
                        {this.state.library_title}
                    </div>
                    <div className="row-field mgT20">
                        <label className="labelFormL" htmlFor="library_document">Upload File</label>
                        <div>
                            <div>
                                <input id="fileDocument" type="file"
                                    onChange={this.onChangeFileHandler}>
                                </input> 
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>  }</div>
                                <div className="mgT10 warningText">
                                Please upload document in pdf format, or upload video (max 10MB)
                                </div>
                            </div>
                            <div className="mgT20 divCenter textBold">OR</div>
                            <div className="mgT20">Paste the Shared File Link from Google Drive</div>
                            <div>
                                <input type="text" className="form-control" name="file_url"
                                    placeholder={this.state.file_url}
                                    value={this.state.file_url}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputFileUrl = input}/>
                                <div className="mgT05 notesText">eg. https://drive.google.com/file/d/XXXXXXXXXXXXXXX/</div>
                                
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.file_url}/>  }</div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row-field mgT50">
                        <div className="labelFormL">
                            <div>Upload Thumbnail</div>
                            <div className="notesText">(only for mp4 file)</div>
                        </div>
                        <div>
                                <input id="fileDocument2" type="file"
                                    onChange={this.onChangeFileHandler}>
                                </input> 
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument2}/>  }</div>
                                <div className="mgT10 warningText">
                                Please upload file in jpg/jpeg format with 1280 px X 720 px (max 1MB).
                                </div>
                        </div>
                        
                    </div>

                    <div className="row-field mgT30 mgB30">
                        <div className="w100p"><button id="submit-button"  type="submit" className="w100p submitButton">Submit</button></div>
                    </div>
                </form>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
          );
        }
     }
      
export default ILibraryAttachForm;
