import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../cinc/cStyle.css';
import CHeader from './cfragmentheader';
import CSidemenu from './cfragmentsidemenu';
import CUC from './cfragmentuc';
 
class CAbout extends Component {
 componentDidMount() {
    document.title = global.cconfig.webtitle;
 } 
 
 render() {
   return (
    
      <div className="main-container flex-container cwrapper">
        <div id="sidemenu" className="csidebar"><CSidemenu/></div>
        <div className="w100p">
          <div className="cheader"><CHeader/></div>
          <div className="cmain-content">
            {/* ====== */}
            <div>
                <div className="stTitle">About</div>
                <div className="stIndiTitle">Pointstar Company Profile</div>
                        
                <div className="mgT30">
                    <CUC/>
                </div>
            </div>
            {/* ====== */}
          </div>
        </div>
      </div>
   
    
    
   );
 }
}
 
export default CAbout;