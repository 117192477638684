/* global gapi */
import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../cinc/cStyle.css';
import Credentials from '../cinc/cOACredentials2.json';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import MenuIcon from '@material-ui/icons/Menu';

class CHeader extends Component {
    constructor(props) {
      super(props);
      this.state = {
        userFullname: '',
        userPicture: '',
        userPosition: '',
        notifCount: 0,
        isOpenMenu: '',
        hideNav: '',
      };
      this.handleClick = this.handleClick.bind(this);
      this.handleLoad = this.handleLoad.bind(this);
      this.handleChange = this.handleChange.bind(this);

    }
    handleChange(e) {
        this.setState({ [e.target.name] : e.target.value });
    }
    
    handleClick(ln,op) {    
        if(ln==='logout') {
         var auth2 = gapi.auth2.getAuthInstance();
         auth2.signOut().then(function () {
           auth2.disconnect();
           localStorage.removeItem("cuid");
           localStorage.removeItem("cutk");
           localStorage.removeItem("cudocid");
           localStorage.removeItem("cuposition");
           localStorage.removeItem("culevel");
           localStorage.removeItem("cufullname");
           localStorage.removeItem("cuimg");
           localStorage.removeItem("cam");
           alert('User signed out.');
           window.location.href = '/clogin';
         }); 
        } else if(ln === 'notif') {
            alert("There's no notification at this moment");
        } else if (ln === 'openmenu'){
            document.getElementById('sidemenu').style.display = 'block';
            this.setState({
                        isOpenMenu : 'open'
                      });
        } else if(ln === 'profile') {
          document.location.href='/cmyprofile'; 
        }
    }

    handleLoad() {
          if(localStorage.getItem('cudocid') !== '' && localStorage.getItem('cudocid') !== null) {
            var userPosition = "Staf", userFullName ="", userPictureProfile="";
            if(localStorage.getItem('cuposition') !== '') { userPosition = localStorage.getItem('cuposition'); }
            if(localStorage.getItem('cufullname') !== '') { userFullName = localStorage.getItem('cufullname'); }
            if(localStorage.getItem('cuimg') !== '') { userPictureProfile = localStorage.getItem('cuimg'); }
            this.setState({
                userFullname: userFullName,
                userPicture: userPictureProfile,
                userPosition: userPosition,
            });
          }
          gapi.load('auth2', () => {
              this.auth2 = gapi.auth2.init({
                client_id: Credentials.web.client_id,
              });
          }); 
        
    }
    
    componentDidMount() {
        window.addEventListener('load',this.handleLoad);
    }
    render() { 
      const { showHideNav } = this.props;
      if(this.state.isOpenMenu === ''){
        if(showHideNav === true) { document.getElementById('sidemenu').style.display = 'none'; }
      }  
      return (
        <div className="w100p flex-container">
            
            <div className="divMiddle ">
            <MenuIcon className="burger-button" onClick={() => this.handleClick('openmenu', this.state.isOpenMenu)}/>
            </div>
            
            <div className="cheader-menu divMiddle">
              <div className="flex-container">
                <div className="mgR20 divMiddle">
                </div>
                <div className="mgR30 flex-container cheader-profile cursor-on" onClick={() => this.handleClick('profile','')}>
                  <div className="mgR10"><img src={this.state.userPicture} className="cheader-picprofile" alt={this.state.userFullname}/></div>
                  <div className="divMiddle">
                      <div className="font-user-name">{this.state.userFullname}</div>
                      <div className="font-user-company-name">{this.state.userPosition}</div>
                  </div>
                  
                </div>
                <div className="divMiddle"><PowerSettingsNewIcon className="iconGrey" onClick={() => this.handleClick('logout','')}></PowerSettingsNewIcon></div>
              </div>
            </div>

          
        </div>  
        
        
      );
    }
   }
    
   export default CHeader;