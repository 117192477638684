import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../cinc/cStyle.css';
import CheckLogin from '../cinc/ccekLogin';
import { FormErrors } from '../../inc/error-form';
import CheckBox from '../../inc/checkbox';
import DateTimeFormats from '../../inc/date-time-format';
import CHeader from './cfragmentheader';
import CSidemenu from './cfragmentsidemenu';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
 
const authKey = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[26];

class CReqRenewLicence extends Component {
  constructor (props) {
    super(props);
    this.state = {
        memberdoc_id: localStorage.getItem('cudocid'),
        domain_name: '',
        licence_type: '',
        licence_addseat: '',
        company_list: [],
        licence_list: [],
        licence_addon_list: [],
        licence_addon_seat: [],
        request_list: [],
        formErrors: {domain_name: '',licence_type: '',licence_addseat: ''},
        domainNameValid: false,
        licenceTypeValid: false,
        licenceAddSeatValid: false,
        formValid: false,
        errmsg: '',
        isSignin: false,
        hideNav: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  resize() {
    let currentHideNav = (window.innerWidth <= 480);
    if (currentHideNav !== this.state.hideNav) {
        this.setState({hideNav: currentHideNav});
    }
    if(currentHideNav === false) { 
      document.getElementById("sidemenu").style.display = "block";
    } else {
      document.getElementById("sidemenu").style.display = "none";
    }
  
  }
  urlmore() {
    window.location.href='/cviewrenewlicence';
  }

  handleLicenceAddOn = (e) => {
    var stat = false;
    var licaddon = this.state.licence_addon_list;
    licaddon.forEach(licaddons => {
       if (licaddons.value === e.target.value) { licaddons.isChecked =  e.target.checked; }
       if(licaddons.isChecked === true) { stat = true; }
    })
    this.setState({
      licence_addon_list: licaddon,
      formErrors: {domain_name: '',licence_type: '',licence_addseat: ''},
    }, () => { 
      this.validateField("licence_addon_list", stat)
    })
    
  } 
  handleUserInput2 = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    
    var licaddonseat = this.state.licence_addon_seat;
    licaddonseat.forEach(licaddonseats => {
       if (licaddonseats.fieldId === name) { licaddonseats.value =  value; }
    })
    this.setState({
      licence_addon_seat: licaddonseat
    })
  } 
  handleLicenceType = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    var licSeat = 0;
    
    if(value !== "") {
      var licName = value.split("$$")[1];
      licSeat = value.split("$$")[2];
      if(licName === global.cconfig.typeLicenceAddon) {
        document.getElementById("gaddon-area").style.display = 'block';
      } else {
        document.getElementById("gaddon-area").style.display = 'none';  
      }
    } else {   document.getElementById("gaddon-area").style.display = 'none';  }
    this.setState({
      [name]: value,
      licence_addseat: licSeat,
      formErrors: {domain_name: '',licence_type: '',licence_addseat: ''},
    }, () => { this.validateField(name, value)});
  } 
  handleUserSelect = (e) => {
    document.getElementById("gaddon-area").style.display = 'none';
    if(this.state.licence_list.length > 0) {
      var liclist = JSON.parse(this.state.licence_list);
      for(var x=0; x<liclist.length; x++) {
        document.getElementById("licence_type_"+x).checked = false;
      }
    }
    var value = e.target.value;
    var inputname = e.target.name;
    var arrValue = value.split("$$");
    this.setState( prevState => {
      return {  
          ...prevState.domain_name, domain_name: value,
          ...prevState.licence_list, licence_list: arrValue[4],
          ...prevState.licence_addseat, licence_addseat: '',
          ...prevState.licence_type, licence_type: '',
      }
    }, () => { 
        this.validateField(inputname, value)
    }) 
  }
  handleUserInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  } 
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let domainNameValid = this.state.domainNameValid;
    let licenceTypeValid = this.state.licenceTypeValid;
    let licenceAddSeatValid = this.state.licenceAddSeatValid;

    switch(fieldName) {
        case 'domain_name': 
          domainNameValid = value.trim().length > 0;
          fieldValidationErrors.domain_name = domainNameValid ? '' : 'domain name need to be selected';
          break;
        case 'licence_type': 
          licenceTypeValid = value.trim().length > 0;
          fieldValidationErrors.licence_type = licenceTypeValid ? '' : 'google workspace licence need to be selected';
          break;
        case 'licence_addseat': 
          licenceAddSeatValid = value.trim().length > 0;
          fieldValidationErrors.licence_addseat = licenceAddSeatValid ? '' : 'number of licences need to be filled';
          break;
        default:
            break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    domainNameValid: domainNameValid,
                    licenceTypeValid: licenceTypeValid,
                    licenceAddSeatValid: licenceAddSeatValid,
                }, this.validateForm);                            
  }
  validateForm() {
    this.setState({formValid: this.state.domainNameValid && this.state.licenceTypeValid && this.state.licenceAddSeatValid });
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  handleSubmit(e) {
    e.preventDefault();
    
    var arrLicence = this.state.licence_type.split("$$");
    var checkedAddOn = false;
    var licenceAddOnList = "";
    var licenceAddOnSeat = "";
    if(arrLicence[1] === global.cconfig.typeLicenceAddon) {
      for(var al=0; al<this.state.licence_addon_list.length; al++) {
        if(licenceAddOnList !== "") { licenceAddOnList += "$$"; }
        licenceAddOnList += this.state.licence_addon_list[al].label+"|"+this.state.licence_addon_list[al].isChecked;
        if(this.state.licence_addon_list[al].isChecked === true) { checkedAddOn = true; }
      }
      for(var as=0; as<this.state.licence_addon_seat.length; as++) {
        if(licenceAddOnSeat !== "") { licenceAddOnSeat += "$$"; }
        var seatval = 0;
        if(this.state.licence_addon_seat[as].value !== "") { seatval = this.state.licence_addon_seat[as].value; }
        licenceAddOnSeat += this.state.licence_addon_seat[as].label+"|"+seatval;
      }
    }
    var amountAddSeat = "";
    if(this.textInputLicenceAddSeat.value === '') { amountAddSeat = this.state.licence_addseat; } else { amountAddSeat = this.textInputLicenceAddSeat.value; }
    
    if(checkedAddOn === true) {
      if(amountAddSeat === "") { amountAddSeat = 0; }
    } 

    if(this.state.domain_name !== '' && this.state.licence_type !== '' && amountAddSeat !== '') {
      document.getElementById('loading-area').style.display = 'block'; 
      var arrDomainName = this.state.domain_name.split("$$");
      
      var urlFetch, datatext;
      datatext ='{"client_id":"'+arrDomainName[0]+'","client_name":"'+arrDomainName[1]+'","domain_name":"'+arrDomainName[2]+'","client_country":"'+arrDomainName[3]+'","licence_id":"'+arrLicence[0]+'","licence_type":"'+arrLicence[1]+'","licence_addseat":"'+amountAddSeat+'","member_id":"'+this.state.memberdoc_id+'","form_id":"form-add-renewlicence","licence_addon":"'+licenceAddOnList+'","licence_addon_seat":"'+licenceAddOnSeat+'"}';
      urlFetch = global.cconfig.urlLink+"/addreqrenewlicence";
  
      var data = JSON.parse(datatext); 
      fetch(urlFetch, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }, body : JSON.stringify(data)
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          document.getElementById('form-content').style.display = 'none';
          if(resJson.status === '200') {
              document.getElementById('thankyou-page').style.display = 'block';
              document.getElementById('error-page').style.display = 'none';
          } else {
              document.getElementById('thankyou-page').style.display = 'none';
              document.getElementById('error-page').style.display = 'block';
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
          document.getElementById('form-content').style.display = 'none';
          document.getElementById('thankyou-page').style.display = 'none';
          document.getElementById('error-page').style.display = 'block';
        }) ;

    } else {
      var arrfieldName = ['domain_name','licence_type','licence_addseat'];
      var arrvalue = [this.textInputDomainName.value,this.state.licence_type,this.textInputLicenceAddSeat.value];
      for(var ck in arrfieldName) {
          this.validateField(arrfieldName[ck], arrvalue[ck]);
      }
    }
  }
  componentDidMount() {
    document.title = global.cconfig.webtitle;
    document.getElementById('loading-area').style.display = 'block'; 
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    CheckLogin().then((statL) =>{ 
      if(statL === true) {

        document.getElementById('form-content').style.display='block';
        var arrLicenceAddOn = [];
        var arrLicenceAddOnSeat = [];
        var addontype = global.cconfig.addonLicence;
        for(var dt=0; dt<addontype.length; dt++) {
          arrLicenceAddOn = arrLicenceAddOn.concat({'id':dt+1,'fieldId':'licence_addon_'+(dt+1),'value':addontype[dt],'label':addontype[dt],'isChecked': false,'isDisable': false});
          arrLicenceAddOnSeat = arrLicenceAddOnSeat.concat({'id':dt+1,'fieldId':'licence_addon_seat_'+(dt+1),'value':'','label':addontype[dt]});
        }
        fetch(global.cconfig.urlLink+"/infolicence/"+this.state.memberdoc_id, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') { 

            this.setState({
              memberdoc_id: resJson.values.memberData[0].docid,
              domain_name: '',
              licence_type: '',
              licence_addseat: '',
              company_list: resJson.values.companyData,
              licence_list: [],
              licence_addon_list: arrLicenceAddOn,
              licence_addon_seat: arrLicenceAddOnSeat,
              request_list: resJson.values.memberData[0].spcontent,
              formErrors: {domain_name: '',licence_type: '',licence_addseat: ''},
              domainNameValid: false,
              licenceTypeValid: false,
              licenceAddSeatValid: false,
              formValid: false,
              errmsg: '',
              isSignin: true
            });
          } else {
            document.getElementById('loading-area').style.display = 'none'; 
          }
        }).catch(err => { 
          document.getElementById('loading-area').style.display = 'none'; 
        });
      } else {
        window.location.href="/clogin";
      }
    });
  } 
  requestlist() {
    var arrRequestList = [];
    if(this.state.request_list.length > 0) {
      var limitlen = this.state.request_list.length;
      if(limitlen > 5) { limitlen = 5; } 
      for(var ls3=0; ls3<limitlen; ls3++) {
        var cDatez="";
        if(this.state.request_list[ls3].specialcontent.createDate !== undefined) {
            cDatez = DateTimeFormats(this.state.request_list[ls3].specialcontent.createDate._seconds,"datetime-long");
        }
        arrRequestList.push(<div className="list-request" key={ls3+1}>
            <div className="detail-list w100p">
              <span className="title-request">{this.state.request_list[ls3].specialcontent.licenceType}</span><br/>
              <span className="content-request">{this.state.request_list[ls3].specialcontent.domainName}</span><br/>
              <span className="content-request">request <span className="textBold">{this.state.request_list[ls3].specialcontent.licenceAddSeat}</span> seat(s)</span><br/>
              <span className="date-request">{cDatez} by {this.state.request_list[ls3].specialcontent.createByName}</span>
              <span className="smcontent-request divRight">status : <span className="mgL10" style={{fontWeight:"500"}}>{this.state.request_list[ls3].specialcontent.requestStatus}</span></span>
            </div>
          </div>)
      }
      if(this.state.request_list.length > limitlen) {
        arrRequestList.push(<div key={ls3+1}>
            <div className="w100p divCenter more-request" onClick={this.urlmore.bind(this)}>
              more »
            </div>
          </div>)
      }
    } else {
      arrRequestList.push(<div key="0" className="list-request textCenter">
            <div className="detail-list w100p"><span className="content-request">no record</span></div>
          </div>)
    }
    return arrRequestList;
  }
  inputdomainlist() {
    var arrinputDomainList = [];
    if(this.state.company_list.length > 0) {
      for(var ls2=0; ls2<this.state.company_list.length; ls2++) {
        arrinputDomainList.push(<option key={ls2} value={
          this.state.company_list[ls2].clientdocid+"$$"+
          this.state.company_list[ls2].clientcontent.clientName+"$$"+
          this.state.company_list[ls2].clientcontent.domainName+"$$"+
          this.state.company_list[ls2].clientcontent.clientCountry+"$$"+
          JSON.stringify(this.state.company_list[ls2].clientlicencelist)
        }>{this.state.company_list[ls2].clientcontent.domainName}</option>)
      }
    }
    return arrinputDomainList;
  }
  inputlicencelist() {
    var arrinputLicList = [];
    if(this.state.licence_list.length > 0) {
      var licenceList = JSON.parse(this.state.licence_list);
      for(var ls=0; ls<licenceList.length; ls++) {
        arrinputLicList.push(<div key={ls}><label>
            <input type="radio" name="licence_type" id={"licence_type_"+ls} 
              value={licenceList[ls].clientlicdocid+"$$"+licenceList[ls].clientliccontent.licenceType+"$$"+licenceList[ls].clientliccontent.licenceTotalSeat}
              onChange={this.handleLicenceType} /> 
              <span className="mgL10">{licenceList[ls].clientliccontent.licenceType}</span>
          </label></div>
        )
      }
    }
    return arrinputLicList;
  }
  inputlicaddonseat() {
    var arrinputLicAddOnSeat = [];
    if(this.state.licence_addon_seat.length > 0) {
      for(var ls4=0; ls4<this.state.licence_addon_seat.length; ls4++) {
        arrinputLicAddOnSeat.push(<div className="mgB10" key={ls4}>
            <input type="number" className="form-control" 
              name={this.state.licence_addon_seat[ls4].fieldId}
              value={this.state.licence_addon_seat[ls4].value}
              onChange={this.handleUserInput2}  
              min="1"/> 
          </div>
        )
      }
    }
    return arrinputLicAddOnSeat;
  }

 render() {
  var inputLicAddOnSeat = this.inputlicaddonseat();
  var inputLicList = this.inputlicencelist();
  var inputDomainList = this.inputdomainlist();
  var requestList = this.requestlist();
  
   return (
    
      <div className="main-container flex-container cwrapper">
        <div id="sidemenu" className="csidebar">{(this.state.isSignin === true)? <CSidemenu showHideNav={this.state.hideNav}/> : '' }</div>
        <div className="w100p">
          <div className="cheader"><CHeader showHideNav={this.state.hideNav}/></div>
          <div className="cmain-content">
            {/* ====== */}
            {(this.state.isSignin === true)? 
            <div>
                <div className="stTitle">Renewal Licence</div>
                <div className="stIndiTitle">Request to Renew Licence</div>
                        
                <div className="mgT30">
                  <div className="row">
                      <div className="col-xl-7">
                        <div className="card card-main card-main-white">
                          <div id="error-page" className="divHide">
                            <div className="flex-container">
                                <CancelIcon className="iconRed"></CancelIcon>
                                <div className="mgL20 done-text">Failed to submit the request.</div>
                              </div>
                          </div>
                          <div id="thankyou-page" className="divHide">
                            <div className="flex-container">
                                <CheckCircleIcon className="iconGreen"></CheckCircleIcon>
                                <div className="mgL20 done-text">Thank you, your customer representative will contact you shortly.</div>
                              </div>
                          </div>
                          <div id="form-content">
                            <div className="label-title-card">Requirement</div>
                            <div id="FormCenter" className="mgT20">
                              <form onSubmit={this.handleSubmit}>
                                <div className="mgT20">
                                  <label className="textMiddle" htmlFor="domain_name">Which Domain do you want to renew?</label>
                                  <div>
                                    <select name="domain_name" className="form-control" 
                                        value={this.state.domain_name} 
                                        onChange={this.handleUserSelect}
                                        ref={(input) => this.textInputDomainName = input}
                                    >
                                        <option value="">-- please select --</option>
                                        {inputDomainList}
                                    </select>
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.domain_name}/>  }</div>
                                  </div>
                                </div>
                                <div className="mgT30">
                                  {(inputLicList.length > 0)? 
                                  <div>
                                    <label className="textMiddle" htmlFor="licence_type">Which product plan do you want to renew?</label>
                                    {inputLicList}
                                   <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_type}/>  }</div>
                                  </div>
                                   : '' } 
                                </div>
                                <div className="mgT30 flex-container">
                                  <label className="mgT05" htmlFor="licence_addseat">Number of Licences</label>
                                  <div className="mgL20">
                                    <input type="number" className="form-control" name="licence_addseat"
                                          placeholder={this.state.licence_addseat}
                                          value={this.state.licence_addseat}
                                          onChange={this.handleUserInput}  
                                          ref={(input) => this.textInputLicenceAddSeat = input}
                                          min="0"/> 
                                      <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_addseat}/>  }</div>
                                    </div>
                                </div>
                                <div id="gaddon-area" className="mgT30 divHide">
                                  {(this.state.licence_addon_list.length > 0)? 
                                  <div>
                                    <label className="textMiddle mgT10" htmlFor="licence_type">Do you also want to renew the Add On Licence?</label>
                                    <div className="flex-container">
                                      <div className="mgR20">
                                        {
                                          this.state.licence_addon_list.map((licence_addon, index) => {
                                            return (<div className="divMiddle mgB10" style={{height:"38px"}}><CheckBox key={index} handleInputChecked={this.handleLicenceAddOn}  {...licence_addon} /></div>)
                                          })
                                        }
                                      </div>
                                      <div>
                                      {inputLicAddOnSeat}
                                      </div>
                                    </div>
                                    
                                  </div>
                                   : '' } 
                                </div>
                                <div className="mgT30"><button id="submit-button"  type="submit" className="submitButton">Ask for Quotation</button></div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="card card-main card-main-blue">
                          <div className="card-request">
                            <div className="label-title-card white-font">Request</div>
                            <div>
                              {requestList}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            : <div id="form-content"></div> }
            {/* ====== */}
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div> 
      </div>
   
    
    
   );
 }
}
 
export default CReqRenewLicence;