/* global gapi */
import React from 'react';
import '../cinc/cGlobal';
import '../../inc/genstyle.css';
import logoPS from '../../assets/logo-ps.png';
import Credentials from '../cinc/cOACredentials2.json';
import CDashboard from './chomepage';
import Alert from '@material-ui/lab/Alert';

const authKey = global.cconfig.authBearerKey;

class CLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSignedIn: false,
            statLoad: 0
        }
        this.handleLoad = this.handleLoad.bind(this);
        this.clearSession = this.clearSession.bind(this);
    }
    clearSession() {
        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(function () {
            auth2.disconnect();
            localStorage.removeItem("cuid");
            localStorage.removeItem("cutk");
            localStorage.removeItem("cudocid");
            localStorage.removeItem("cuposition");
            localStorage.removeItem("culevel");
            localStorage.removeItem("cufullname");
            localStorage.removeItem("cuimg");
            localStorage.removeItem("cam");
            window.location.reload();
        });
    }
    onSuccess(res) {
        var datatext ='{"user_email":"'+res.getBasicProfile().getEmail()+'","user_id":"'+res.getBasicProfile().getId()+'","user_fullname":"'+res.getBasicProfile().getName()+'","user_picture":"'+res.getBasicProfile().getImageUrl()+'"}';
        var uidValue = res.getBasicProfile().getEmail();
        var utkValue = res.getBasicProfile().getId();
        var imgValue = res.getBasicProfile().getImageUrl();

        document.getElementById("loginButton").style.display = "none";
        var data = JSON.parse(datatext); 
        fetch(global.cconfig.urlLink+"/ceklogin", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }, body : JSON.stringify(data)
          }).then(res2 => {
            if(res2.status === 200) 
                return res2.json() 
          }).then( resJson => {
            if(resJson.status === '200') {
                localStorage.setItem('cuid', uidValue);
                localStorage.setItem('cutk', utkValue);
                localStorage.setItem('cudocid', resJson.values[0].docid);
                localStorage.setItem('cuposition', resJson.values[0].memberPosition);
                localStorage.setItem('culevel', resJson.values[0].userLevel);
                localStorage.setItem('cufullname', resJson.values[0].memberName);
                localStorage.setItem('cuimg', imgValue);
                var arrAuth = authKey.split("##");
                var remakeAuthKey = utkValue+"##"+arrAuth[1]+"##"+arrAuth[2];
                fetch(global.cconfig.urlLink+"/amlist/"+resJson.values[0].docid, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': remakeAuthKey,
                    }
                }).then(res2 => {
                    if(res2.status === 200) 
                        return res2.json() 
                }).then( resJson2 => {
                    if(resJson2.status === '200') { 
                        localStorage.setItem('cam', JSON.stringify(resJson2.values.amData));
                        this.setState( prevState => {
                            return {  
                                ...prevState.isSignedIn, isSignedIn: true,
                                ...prevState.statLoad, statLoad: 1
                            }
                        })
                        window.location.href="/chome";
                    } else {
                        this.setState( prevState => {
                            return {  
                                ...prevState.isSignedIn, isSignedIn: false,
                                ...prevState.statLoad, statLoad: 1
                            }
                        })
                        this.clearSession();
                    }
                })
            } else {
                document.getElementById("loginButton").style.display = "block";
                document.getElementById("errormsg").style.display = "block"; 
                this.setState( prevState => {
                    return {  
                        ...prevState.isSignedIn, isSignedIn: false,
                        ...prevState.statLoad, statLoad: 1
                    }
                })
                this.clearSession();
                // var auth2 = gapi.auth2.getAuthInstance();
                // auth2.signOut().then(function () {
                //     auth2.disconnect();
                //     localStorage.clear();
                // }); 
            }
          }).catch(err => err);
    }

    onLoginFailed(err) {
        document.getElementById("loginButton").style.display = "block";
        document.getElementById("errormsg").style.display = "block";
        this.setState( prevState => {
            return {  
                ...prevState.isSignedIn, isSignedIn: false,
                ...prevState.statLoad, statLoad: 1
            }
        })
    }
    
    handleLoad() {
        const successCallback = this.onSuccess.bind(this);
        const failureCallback = this.onLoginFailed.bind(this);
            gapi.load('auth2', () => {
              this.auth2 = gapi.auth2.init({
                client_id: Credentials.web.client_id,
                scope: ('profile')
              })
            });    
        
            gapi.load('signin2', function() {
              var opts = {
                width: 220,
                height: 50,
                longtitle: true,
                theme: 'dark', 
                client_id: Credentials.web.client_id,
                onsuccess: successCallback,
                onfailure: failureCallback
              }
              gapi.signin2.render('loginButton', opts)
              
            })
            //localStorage.clear();            
    }
    componentDidMount() {
        document.title = global.cconfig.webtitle;
        window.addEventListener('load',this.handleLoad);
        document.getElementById("errormsg").style.display = 'none';
    }

    getContent() {
        if (this.state.isSignedIn === true) {
            return <div><CDashboard/></div>
        } else {
              return (
                <div className="main-container center-screen">
                    <div className="login-area divMiddle">
                        <div className="mgT30 divCenter"><img src={logoPS} alt="pointstar" width="150"></img></div>
                        <div className="mgT20 mgB30 divCenter textBold">Sign in with your Company Account</div>
                        <div className="mgT20 mgB20 divCenter"><div className="divhide w70p" id="errormsg"><Alert severity="error">You are not successfully login.<br/>Please contact administrator for more information</Alert></div></div>
                        <div className="mgT30 mgB50 divCenter">
                            <div id="loginButton"></div>
                        </div>
                    </div>
                </div>
              )
        }
    }
    
    render() {
        return (
            <div>{this.getContent()}</div>
           
        )
    }
}
export default CLogin;
