import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../cinc/cStyle.css';
import CheckLogin from '../cinc/ccekLogin';
import DateTimeFormats from '../../inc/date-time-format';
//import ReplaceAllContent from '../../inc/replace-all';
import CHeader from './cfragmentheader';
import CSidemenu from './cfragmentsidemenu';
//import NAimage from '../../assets/na.png';
import Popup from '../../inc/popup';
import '../../inc/popup.css'
import CRequestForm from './crequest-form';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';


class CRequestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
        memberdoc_id: localStorage.getItem('cudocid'),
        stTitle: '',
        substTitle: '',
        typeSlug: '',
        //pageShow: '10',
        //keyword: '',
        //styleTb: 'table',
        request_list: [],
        isSignin: false,
        hideNav: '',
        otherContent:''
    };
    //this.handleChange = this.handleChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
    //this.deleteHandler = this.deleteHandler.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  resize() {
    let currentHideNav = (window.innerWidth <= 480);
    if (currentHideNav !== this.state.hideNav) {
        this.setState({hideNav: currentHideNav});
    }
    if(currentHideNav === false) { 
      document.getElementById("sidemenu").style.display = "block";
    } else {
      document.getElementById("sidemenu").style.display = "none";
    }
  
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
      }
    })
  } 
  urldetailRequest(valId,res) {
    console.log("valId");
    console.log(valId);
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: true,
          ...prevState.otherContent, otherContent: '{"request_id":"'+valId+'","licence_type":"'+res.licenceType+'","domain_name":"'+res.domainName+'","add_seat":"'+res.licenceAddSeat+'","mpayment": "'+res.methodPayment+'"}', 
      }
    })
  }

  loadData(value) {
    //console.log("loading...");
    document.getElementById('loading-area').style.display = 'block'; 
    //var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'","type":"'+value+'","form_id":"pubonly"}';
    //var data = JSON.parse(datatext); 

    var typeSlug="", titleName="", subTitleName="", ckindCd="", fetchurl="";    
    if(value === 'cviewaddlicence') { typeSlug="add-licence"; titleName = "Add Licence"; subTitleName="Request additional seat for your organization"; ckindCd = global.cconfig.kindcode[16]; fetchurl= global.cconfig.urlLink+"/infolicence/"+this.state.memberdoc_id; }
    else if(value === 'cviewclaim') { typeSlug="claim"; titleName = "Claim GCP"; subTitleName="Request to Claim Google Cloud Platform"; ckindCd = global.cconfig.kindcode[22]; fetchurl= global.cconfig.urlLink+"/infolicence/"+this.state.memberdoc_id; }
    else if(value === 'cviewupgrade') { typeSlug="upgrade-licence"; titleName = "Upgrade Licence"; subTitleName="Request to Upgrade current licence"; ckindCd = global.cconfig.kindcode[24]; fetchurl= global.cconfig.urlLink+"/infolicence/"+this.state.memberdoc_id; }
    else if(value === 'cviewreqtraining') { typeSlug="req-training"; titleName = "Training Session"; subTitleName="Set up a training with our certified trainer"; ckindCd = global.cconfig.kindcode[18]; fetchurl= global.cconfig.urlLink+"/inforeqtraining/"+this.state.memberdoc_id; }
    else if(value === 'cviewrenewlicence') { typeSlug="renew-licence"; titleName = "Renewal Licence"; subTitleName="Request to Renew Licence"; ckindCd = global.cconfig.kindcode[26]; fetchurl= global.cconfig.urlLink+"/infolicence/"+this.state.memberdoc_id; }
    
    if(ckindCd !== "") {
      var authKey = global.cconfig.authBearerKey+"##"+ckindCd;

      fetch(fetchurl, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          console.log(resJson);
          console.log(resJson.status);
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') { 

              this.setState({
                  stTitle: titleName,
                  substTitle: subTitleName,
                  typeSlug: typeSlug,
                  request_list: resJson.values.memberData[0].spcontent,
                  isSignin: true,
              })
          } else {
            document.getElementById('loading-area').style.display = 'none'; 
            console.log('Something happened wrong');
            console.log(resJson);
          }
        }).catch(err => { 
          document.getElementById('loading-area').style.display = 'none'; 
          console.log(err);
        });
    } else {
      window.location.href="/CUC";
    }
  }

 componentDidMount() {
    document.title = global.cconfig.webtitle;
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    CheckLogin().then((statL) =>{ 
      if(statL === true) {
        var geturl = this.props.location; 
        var arrString = geturl.pathname;
        var arrUrl = [];
        arrUrl = arrString.split('/');
        this.loadData(arrUrl[1]);
      } else {
        window.location.href="/clogin";
      }
    });

 } 
 
 requestlist() {
    var arrRequestList = [];
    if(this.state.request_list.length > 0) {
      for(var ls3=0; ls3<this.state.request_list.length; ls3++) {
        var requestid = this.state.request_list[ls3].specialdocid;
        var retResult = this.state.request_list[ls3].specialcontent;
        var cDatez="";
        if(this.state.request_list[ls3].specialcontent.createDate !== undefined) {
            cDatez = DateTimeFormats(retResult.createDate._seconds,"date-short");
        }
        console.log(requestid);
        if(this.state.typeSlug==="add-licence" || this.state.typeSlug==="renew-licence") {
          arrRequestList.push(<tr key={requestid}>
              <th scope="row-field">{ls3+1}</th>
              <td>{cDatez}<br/>by {retResult.createByName}</td>
              <td>{retResult.licenceType}<br/><span className="sub-font">{retResult.domainName}</span></td>
              <td>{retResult.licenceAddSeat}</td>
              <td style={{width:'150px'}}>{retResult.requestStatus}</td>
              <td style={{width:'80px'}}>
                {(retResult.requestStatus === global.cconfig.requestStatus[4])? 
                  <RemoveRedEyeIcon className="iconGrey cursor-on" onClick={this.urldetailRequest.bind(this, requestid, retResult)}></RemoveRedEyeIcon>
                : ''}
              </td>
              
            </tr>)
        } else if(this.state.typeSlug==="claim") {
          arrRequestList.push(<tr key={retResult.docid}>
              <th scope="row-field">{ls3+1}</th>
              <td>{cDatez}<br/>by {retResult.createByName}</td>
              <td>{retResult.domainName}</td>
              <td style={{width:'150px'}}>{retResult.requestStatus}</td>
            </tr>)
        } else if(this.state.typeSlug==="upgrade-licence") {
          arrRequestList.push(<tr key={retResult.docid}>
              <th scope="row-field">{ls3+1}</th>
              <td>{cDatez}<br/>by {retResult.createByName}</td>
              <td>{retResult.licenceType}<br/><span className="sub-font">{retResult.domainName}</span></td>
              <td>{retResult.licenceRequestType}</td>
              <td style={{width:'150px'}}>{retResult.requestStatus}</td>
            </tr>)
        } else if(this.state.typeSlug==="req-training") {
          arrRequestList.push(<tr key={retResult.docid}>
              <th scope="row-field">{ls3+1}</th>
              <td>{cDatez}<br/>by {retResult.createByName}</td>
              <td>
                <p>Subject Training : <br/>{retResult.requestSubjectTraining}</p>
                <p>Type : {retResult.productType}</p>
                <p>Held On : {retResult.requestEventDate}</p>
                <p>Attendance : {retResult.requestAttendance}</p>
                <p>For Level : {retResult.requestLevelUser}</p>
                {(retResult.requestMessage !== "") ? <p>Remarks :<br/> {retResult.requestMessage}</p> : '' }
              </td>
              <td style={{width:'150px'}}>{retResult.requestStatus}</td>
            </tr>)
        }
      }
      
    } else {
      arrRequestList.push(<tr key="0">
        <th scope="row-field"></th>
        <td></td>
        <td><span className="content-request">no record</span></td>
        <td></td>
      </tr>)
      // arrRequestList.push(<div key="0" className="list-request textCenter">
      //       <div className="detail-list w100p"><span className="content-request">no record</span></div>
      //     </div>)
    }
    return arrRequestList;
  }
 render() {
   var requestList = this.requestlist();
   
   return (

      <div className="main-container flex-container cwrapper">
        <div id="sidemenu" className="csidebar">{(this.state.isSignin === true)? <CSidemenu showHideNav={this.state.hideNav}/> : '' }</div>
        <div className="w100p">
          <div className="cheader"><CHeader showHideNav={this.state.hideNav}/></div>
          <div className="cmain-content">
            {/* ====== */}
            {(this.state.isSignin === true)? 
            <div>
                <div className="stTitle">{this.state.stTitle}</div>
                <div className="stIndiTitle">{this.state.substTitle}</div>
                        
                <div className="mgT30">
                  {/*<div className="card card-body">*/}
                    <div className="row-field mgT20">
                        <table className="table table-striped">
                        <thead className="table-head">
                            <tr key="0">
                            <th scope="col">#</th>
                            <th scope="col">Request</th>
                            {
                              (this.state.typeSlug==="add-licence" || this.state.typeSlug==="renew-licence" || this.state.typeSlug==="upgrade-licence")? 
                                <th scope="col">Licence Info</th> : 
                              (this.state.typeSlug==="claim")? 
                                <th scope="col">Domain Name</th>
                                : <th scope="col">Description</th> 
                            }

                            {
                              (this.state.typeSlug==="add-licence" || this.state.typeSlug==="renew-licence")? <th scope="col">Seat(s)</th> : 
                              (this.state.typeSlug==="upgrade-licence")? <th scope="col">Upgrade To</th> : '' 
                            }
                            
                            <th scope="col" style={{width:'150px'}}>Status</th>
                            <th scope="col" style={{width:'80px'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {requestList}
                        </tbody>
                        </table>
                    </div>
                    <div>
                    {this.state.isOpen && <Popup
                              content={<><CRequestForm/></>}
                              id={this.state.licencedoc_id} 
                              title={"Confirmation Payment"}
                              others={this.state.otherContent}
                              styles={"50%"}
                              handleClose={this.togglePopClose.bind(this)}
                            />}
                    </div>
                  {/*</div> */}
                </div>
            </div>
            : <div></div> }
             {/* ====== */}
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div>
      </div>
   
   );
 }
}
 
export default CRequestList;