import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import { FormErrors } from '../../inc/error-form';
import '../iinc/iGlobal';
import Alert from '@material-ui/lab/Alert';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[4];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[3];

class ReqRenewForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            clientdoc_id: '',
            licencedoc_id: '',
            client_name: '',
            domain_name: '',
            client_country: '',
            client_licence_type: '',
            client_licence_currseat: '',
            client_licence_info: '',
            licence_request_action: '',
            licence_request_type: '',
            licence_amount_seat: '',
            licence_request_list: [],
            formErrors: {licence_request_action: '', licence_request_type: '', licence_amount_seat: ''},
            licencerequestactionValid: false,
            licencerequesttypeValid: false,
            licenceamountseatValid: false,
            formValid: false,
            errmsg: '' 
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleUserSelect = (e) => {
        const value = e.target.value;
        const inputname = e.target.name;
        if(inputname === "licence_request_action") {
            if(value === "renew") {
                document.getElementById("reqtype-area").style.display = "none";
                this.setState( prevState => {
                    return {  
                        ...prevState.licence_request_action, licence_request_action: value,
                        ...prevState.licence_request_type, licence_request_type: this.state.client_licence_type
                    }
                }, () => { this.validateField(inputname, value) }) 
            } else {
                document.getElementById("reqtype-area").style.display = "block";
                this.setState( prevState => {
                    return {  
                        ...prevState.licence_request_action, licence_request_action: value,
                    }
                }, () => { this.validateField(inputname, value) }) 
            }
            
        } else if(inputname === "licence_request_type") {
            this.setState( prevState => {
              return {  
                  ...prevState.licence_request_type, licence_request_type: value
              }
            }, () => { this.validateField(inputname, value) }) 
        }
        
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(value);
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    } 
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let licencerequestactionValid = this.state.licencerequestactionValid;
        let licencerequesttypeValid = this.state.licencerequesttypeValid;
        let licenceamountseatValid = this.state.licenceamountseatValid;
        
        switch(fieldName) {
            case 'licence_request_action': 
                licencerequestactionValid = value.trim().length > 0;
                fieldValidationErrors.licence_request_action = licencerequestactionValid ? '' : 'action need to be filled';
                break;
            case 'licence_request_type': 
                licencerequesttypeValid = value.trim().length > 0;
                fieldValidationErrors.licence_request_type = licencerequesttypeValid ? '' : 'next licence need to be filled';
                break;
            case 'licence_amount_seat': 
                licenceamountseatValid = value.trim().length > 0;
                fieldValidationErrors.licence_amount_seat = licenceamountseatValid ? '' : 'amount of seats need to be filled';
                break;
            default:
                break;
        }
        //alert(fieldName + ':' + JSON.stringify(fieldValidationErrors));
        
        this.setState({formErrors: fieldValidationErrors,
                        licencerequestactionValid: licencerequestactionValid,
                        licencerequesttypeValid: licencerequesttypeValid,
                        licenceamountseatValid: licenceamountseatValid,
                    }, this.validateForm);
      }
      validateForm() {
        this.setState({formValid: this.state.licencerequestactionValid && this.state.licencerequesttypeValid && this.state.licenceamountseatValid });
      }
    
      errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
      }
      handleSubmit(e) {
        e.preventDefault();
        //alert(JSON.stringify(this.state));
        //alert(this.event_enddate)
        if(this.state.licence_amount_seat !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var urlFetch, datatext;
            var licenceReqAction, licenceReqType, licenceAmountSeat;
                
            if(this.state.licencedoc_id !== null && this.state.licencedoc_id !== ''  && this.state.licencedoc_id !== undefined) { 
                if(this.textInputLicenceRequestAction.value === '') { licenceReqAction = this.state.licence_request_action; } else { licenceReqAction = this.textInputLicenceRequestAction.value; }
                if(this.textInputLicenceRequestType.value === '') { licenceReqType = this.state.licence_request_type; } else { licenceReqType = this.textInputLicenceRequestType.value; }
                if(this.textInputLicenceAmountSeat.value === '') { licenceAmountSeat = this.state.licence_amount_seat; } else { licenceAmountSeat = this.textInputLicenceAmountSeat.value; }
                
                var formid = "form-"+licenceReqAction+"-request";
                var arrLicValue =  this.state.client_licence_info.split("$$");
                var licencePriceCurrency = "USD";
                if(this.state.client_country.toLowerCase() === "indonesia") { licencePriceCurrency = "IDR"; }
                // datatext ='{"licence_client_docid":"'+this.state.licencedoc_id+'"}';
                
                datatext ='{"client_licence_type":"'+this.state.client_licence_type+'","licence_category":"'+arrLicValue[0]+'","licence_price":"0","licence_price_currency":"'+licencePriceCurrency+'","licence_start_date":"'+arrLicValue[1]+'","licence_periode_num":"'+arrLicValue[3]+'","licence_periode_unit":"'+arrLicValue[4]+'","licence_remarks":"'+arrLicValue[6]+'","licence_request_action":"'+licenceReqAction+'","licence_request_type":"'+licenceReqType+'","licence_amount_seat":"'+licenceAmountSeat+'","licence_renewal_price":"'+arrLicValue[10]+'","licence_renewal_price_currency":"'+arrLicValue[11]+'","clientdoc_id":"'+this.state.clientdoc_id+'","licencedoc_id":"'+this.state.licencedoc_id+'","form_id":"'+formid+'"}';
                urlFetch = global.config.urlLink+"/reqrenewlicence";
            
                var data = JSON.parse(datatext); 
            
                fetch(urlFetch, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey,
                    }, body : JSON.stringify(data)
                }).then(res => {
                    console.log(res);
                    if(res.status === 200) 
                        return res.json() 
                }).then( resJson => {
                    console.log(resJson);
                    console.log(resJson.status);
                    document.getElementById('loading-area').style.display = 'none'; 
                    if(resJson.status === '200') {
                        window.location.href='/IViewLicence/'+this.state.clientdoc_id+'/'+this.state.licencedoc_id;
                    } else {
                        console.log('Something happened wrong');
                        console.log(resJson);
                    }
                }).catch(err => {
                    document.getElementById('loading-area').style.display = 'none'; 
                    console.log(err);
                });
            } else {
                var msgError = "There's no Client is defined";
                document.getElementById("errormsg").style.display="block";
                this.setState( prevState => {
                    return {  
                        ...prevState.errmsg, errmsg: msgError
                    }
                  })
            }
        } else {
            var arrfieldName = ['licence_amount_seat'];
            var arrvalue = [this.textInputLicenceAmountSeat.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        } 
      }
    componentDidMount() {
        //console.log("id:"+document.getElementById("contentid").innerText);
        document.getElementById("errormsg").style.display = 'none';
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        var lictypedata = JSON.parse('{"keyword":"singapore","category":"'+othersContent.licence_category+'","pageShow":"1000"}'); 
        fetch(global.config.urlLink+"/licencetypelist/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey2,
            }, body : JSON.stringify(lictypedata)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJsonLicType => {
            var arrContentsList = [];
            var arrContentSortByAsc = [];
            if(resJsonLicType.values.length > 0 && resJsonLicType.status === '200') {
                for(var v=0; v < resJsonLicType.values.length; v++) {
                    arrContentsList.push(resJsonLicType.values[v].content.licenceType);
                    // arrContentsList.push({
                    //     docid: resJsonLicType.values[v].docid,
                    //     licType: resJsonLicType.values[v].content.licenceType,
                    // })
                }
                arrContentSortByAsc = arrContentsList.sort();
                // if(arrContentsList.length>0) {
                //     arrContentSortByAsc = arrContentsList.sort((c,d) => {
                //         return c.licType - d.licType;
                //     })
                // }
                console.log("avv");
                console.log(arrContentSortByAsc);
                
            } 
            console.log(othersContent);
            this.setState({
                stPopTitle: '',
                clientdoc_id: othersContent.client_id,
                licencedoc_id: document.getElementById("contentid").innerText,
                client_name: othersContent.client_name,
                domain_name: othersContent.domain_name,
                client_country: othersContent.client_country,
                client_licence_type: othersContent.licence_type,
                client_licence_currseat: othersContent.licence_seat,
                client_licence_info: othersContent.licence_info,
                licence_request_action: 'renew',
                licence_request_type: othersContent.licence_type,
                licence_amount_seat: '',
                licence_request_list: arrContentSortByAsc,
                formErrors: {licence_request_action: '', licence_request_type: '', licence_amount_seat: ''},
                licencerequestactionValid: false,
                licencerequesttypeValid: false,
                licenceamountseatValid: false,
                formValid: false,
                errmsg: ''
            }); 
        });
    }
    inputlicencetype() {
        var arrinputLicType = [];
        if(this.state.licence_request_list.length > 0) {
          //var licenceTypeList = JSON.parse(this.state.licence_request_list);
          for(var ls4=0; ls4<this.state.licence_request_list.length; ls4++) {
            //console.log(licenceList[ls]);
            arrinputLicType.push(<option value={this.state.licence_request_list[ls4]} key={ls4}>{this.state.licence_request_list[ls4]}</option>)
          }
        }
        return arrinputLicType;
    }
    render() {
      var sellicenceTypeList = this.inputlicencetype();
      return (
        <div className="main-container">
            <div className="mgT20">
            <div className="divhide w100p" id="errormsg"><Alert severity="error">{this.state.errmsg}</Alert></div>
            <form id="form-renew-request" onSubmit={this.handleSubmit}>
              <div className="mgL20">
                <div className="st3Title">{this.state.client_name}</div>
                <div><h5 className="detailJoined">{this.state.client_country} - {this.state.domain_name}</h5></div>
              </div>
              <div className="mgT20 mgL20">
                {/* ---- */}
                <div>

                    
                    <div className="mgT05 flex-container">
                        <div className="w30p">Type of Licence :</div>
                        <div className="mgL10">{this.state.client_licence_type}</div>
                    </div>
                    <div className="mgT05 flex-container">
                        <div className="w30p">Active Licence :</div>
                        <div className="mgL10">{this.state.client_licence_currseat} seat(s)</div>
                    </div>
                    
                    <div className="mgT30 lineBottom">
                        <div className="textBold">Request Licence</div>
                    </div>
                    <div className="mgT20 flex-container">
                        <div className="w30p">Request</div>
                        <div className="w70p">
                            <select name="licence_request_action" className="form-control" 
                                value={this.state.licence_request_action} 
                                onChange={this.handleUserSelect}
                                ref={(input) => this.textInputLicenceRequestAction = input}
                            >
                                <option value="renew">Renew</option>
                                <option value="upgrade">Upgrade</option>
                                <option value="downgrade">Downgrade</option>
                            </select> 
                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_request_action}/>  }</div>
                        </div>
                    </div>
                    <div className="mgT05 divHide" id="reqtype-area">
                        <div className="flex-container ">
                            <div className="w30p">Will be {this.state.licence_request_action} to </div>
                            {(sellicenceTypeList.length > 0)? 
                            <div className="w70p">
                                <select name="licence_request_type" className="form-control" 
                                        value={this.state.licence_request_type} 
                                        onChange={this.handleUserSelect}
                                        ref={(input) => this.textInputLicenceRequestType = input}
                                    >
                                    <option value="" key="">-- please select --</option>
                                    {sellicenceTypeList}
                                </select>
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_request_type}/>  }</div>
                            </div>
                            : '' } 
                        </div>
                    </div>
                    <div className="mgT05 flex-container">
                        <div className="w30p">Amount of Seats</div>
                        <div className="w70p flex-container">
                            <div>
                                <input type="number" className="form-control" name="licence_amount_seat"
                                    placeholder={this.state.licence_amount_seat}
                                    value={this.state.licence_amount_seat}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputLicenceAmountSeat = input}
                                    min="1"/> 
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_amount_seat}/>  }</div>
                            </div>
                            <div className="mgL20 mgT05"> seat(s) </div>
                        </div>
                    
                    </div>
                </div>

                {/* ---- */}
                
              </div>
              <div className="row-field mgT30 mgB30">
                    <input type="hidden" required name="clientdoc_id" value={this.state.clientdoc_id} />
                    <input type="hidden" required name="licencedoc_id" value={this.state.licencedoc_id} />
                    <button type="submit" className="w100p submitButton">Submit Request {this.state.licence_request_action}</button>
              </div>
            </form>
            </div>
            <div id="loading-area" className="divHide loading"></div> 
        </div>
      );
    }
 }
  
export default ReqRenewForm;