import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[0];

function urladdMember() {
    window.location.href='/IAddMembers';
}

class IMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        styleTb: 'table',
        data: [],
        isAccess: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  loadData() {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/userlist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        var styleTb = 'table';
        if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; } 
        this.setState({
            styleTb: styleTb,
            data: resJson,
            isAccess: true
        })
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  urlsetMember(valId) {
    window.location.href='/ISetMembers/'+valId;
  }
  urldelMember(valId) {
    document.getElementById('loading-area').style.display = 'block'; 
    var urlString = global.config.urlLink+"/userdelete/"+valId;
    fetch(urlString, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }
    }).then(response => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(response.status === 200) {
            window.location.reload();
        } else {
            console.log('Something happened wrong delete');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value });
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    this.setState( prevState => {
        return {  
            ...prevState.pageShow, pageShow: value
        }
    })
  }
    
  handleSubmit(e) {
    e.preventDefault();
    this.loadData();
  }
  componentDidMount() {
    if(localStorage.getItem('ulevel') === global.config.userlevel[0]) { 
        this.setState({ isAccess: true })
        this.loadData();
    } else {
        this.setState({ isAccess: false })
    }
 }
 render() {
    var memberList = [];
    if(this.state.data.values.length > 0 && this.state.data.status === '200') {
        for(var ls=0; ls < this.state.data.values.length; ls++) {
            var retResult = this.state.data.values[ls];
            var editURL = "/IEditMembers/"+retResult.docid;
            var viewURL = "/IViewMembers/"+retResult.docid;
            memberList.push(<tr key={retResult.docid}>
                <th scope="row">{ls+1}</th>
                <td>{retResult.content.userFullname}</td>
                <td>{retResult.content.userEmail}</td>
                <td>{retResult.content.userLevel}</td>
                <td><button className="backButton" onClick={this.urlsetMember.bind(this, retResult.docid)}>set am to client</button></td>
                <td>
                    <a href={editURL}><EditIcon className="iconGrey mgR20" /></a>
                    <a href={viewURL}><DescriptionIcon className="iconGrey mgR20" /></a>
                    <DeleteIcon className="iconGrey cursor-on" onClick={this.urldelMember.bind(this, retResult.docid)}/>
                </td>
              </tr>)
        }
    } else {
        memberList.push(<tr key="1">
            <td colSpan="7" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
          </tr>)
    }
    
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Member</div>
                {(this.state.isAccess === true)? 
                <div>
                    <div className="stIndiTitle">List of PointStar members</div>
                    <div className="mgT30">
                        {/*--*/}
                        <div className="content-container card card-body">
                            <div className="row show">
                                <div className="col-6 divMiddle">
                                    <form method="post" id="search-form" className="form-inline" onSubmit={this.handleSubmit}>
                                        <div className="flex-container" style={{display:'none'}}>
                                            <input type="text" className="inputSearch mr-sm-2" name="keyword" id="keyword"
                                                placeholder="Search entries.."
                                                value={this.state.keyword}
                                                onChange={this.handleChange.bind(this)}/> 
                                            <SearchIcon style={{ fontSize: 35 }} className="iconGrey mgT10 cursor-on" onClick={this.handleSubmit}></SearchIcon>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-6 form-inline justify-content-end">
                                    <button className="genButton mgL20" onClick={urladdMember}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>Add New Member</button>
                                </div>
                            </div>
                            <div className="row mgT20">
                                <table className={this.state.styleTb}>
                                <thead>
                                    <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Member Name</th>
                                    <th scope="col">Member Email</th>
                                    <th scope="col">Member Level</th>
                                    <th scope="col">Setting</th>
                                    <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {memberList}
                                </tbody>
                                </table>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <div className="flex-container" style={{display:'none'}}>
                                        <div className="mgR20 divMiddle">Showing</div>
                                        <div className="mgR20 divMiddle show">
                                            <select className="btn btn-secondary" name="pageShow" 
                                                value={this.state.pageShow} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputPageShow = input} >
                                                <option value="1">1</option>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                        <div className="mgR20 divMiddle">list</div>
                                    </div>
                                </div>
                                <div className="col-6 form-inline justify-content-end">
                                    
                                </div>
                            </div>
                        </div>
                        {/*--*/}
                    </div>
                  </div>
                  :   
                  <div><INA/></div>
                  }
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div>
    </div>
   );
 }
}
 
export default IMember;