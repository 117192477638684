import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import '../../inc/popup.css';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import DatePicker from 'react-date-picker';
import { FormErrors } from '../../inc/error-form';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[30];

class IUserTraining extends Component {
  constructor(props) {
    super(props);
    this.state = {
      full_name: '',
      email: '',
      phone: '',
      language: '',
      picture_url: '',
      expiry_date: new Date(),
      file_url: '',
      defTabIndex: 0,
      usageFor: '',
      formErrors: { full_name: '', email: '', phone: '', language: '', picture_url: '', expiry_date: '', file_url: '' },
      formValid: false,
      fullNameValid: '',
      emailValid: '',
      phoneValid: '',
      languageValid: '',
      pictureUrlValid: '',
      expiryDateValid: '',
      fileUrlValid: '',
      isOpen: false,
      otherContent: '', 
    };
    this.handleSelectTab = this.handleSelectTab.bind(this);
    this.handleSubmit1 = this.handleSubmit1.bind(this);
    this.handleSubmitBulk = this.handleSubmitBulk.bind(this);
  }

  togglePopClose() {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen, isOpen: false,
      }
    })
  }
  
  handleSelectTab(idx) {
    var used = "bulk";
    if(idx === 1) { used = "single"; }
    else if(idx===2) { used = "picker"; }
    this.setState( prevState => {
      return {  
        ...prevState.usageFor, usageFor: used
      }
    })
  }
  
  handleUserInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState({
      [name]: value
    }, () => { 
      this.validateField(name, value);
    });
  }

  handleInputSelect = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    this.setState(prevState => {
      return {
        ...prevState.language, language: value,
      }
    }, () => { 
      this.validateField(name, value);
    });
  }

  handleInputDate = date => {
    this.setState(prevState => {
      return {  
        ...prevState.expiry_date, expiry_date: date
      }
    }, () => { 
      this.validateField('expiry_date', date);
    }); 
  }

  handleLinkClick() {    
    var url = 'https://docs.google.com/spreadsheets/d/1lUm510fwYimA4a-c5wD--XHTI0pVhHP_MQpJ5VBqP18/';
    window.open(url, '_blank', 'noreferrer');
  }
  
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let fullNameValid = this.state.fullNameValid;
    let emailValid = this.state.emailValid;
    let phoneValid = this.state.phoneValid;
    let languageValid = this.state.languageValid; 
    let pictureUrlValid = this.state.pictureUrlValid; 
    let expiryDateValid = this.state.expiryDateValid;

    switch(fieldName) {
      case 'full_name': 
        fullNameValid = value.trim().length > 0;
        fieldValidationErrors.full_name = fullNameValid ? '' : 'Full name need to be filled';
        break;
      case 'email': 
        emailValid = value.trim().length > 0;
        fieldValidationErrors.email = emailValid ? '' : 'Email token need to be filled';
        break;
      case 'expiry_date': 
        expiryDateValid = value !== null;
        fieldValidationErrors.expiry_date = expiryDateValid ? '' : 'Expiry Date need to be filled';
        break;
      default:
        break;
    }
    
    this.setState({
      formErrors: fieldValidationErrors,
      fullNameValid: fullNameValid,
      emailValid: emailValid,
      phoneValid: phoneValid,
      languageValid: languageValid,
      pictureUrlValid: pictureUrlValid,
      expiryDateValid: expiryDateValid,
    }, this.validateForm);
  }

  validateForm() {
    this.setState({formValid: this.state.fullNameValid && this.state.emailValid && this.state.phoneValid && this.state.languageValid && this.state.pictureUrlValid && this.state.expiryDateValid});
  }

  handleSubmitBulk(e) {
    e.preventDefault();
    if(this.state.file_url !== '') {
      document.getElementById('loading-area').style.display = 'block'; 
      var fileUrl;
      if(this.textInputFileUrl.value === '') { fileUrl = this.state.file_url; } else { fileUrl = this.textInputFileUrl.value; }
      
      var arrLinkPath = fileUrl.replace("https://","").split('/');
      var fileid = fileUrl;
      if(arrLinkPath.length > 0) {
          fileid = arrLinkPath[3];
      }
      var rangez = "Data!A2:F";
      var datatext = '{"fileid":"'+fileid+'","range":"'+rangez+'","fromset":"users"}';
      var data = JSON.parse(datatext); 
      var urlFetch = global.config.urlLink+"/gettmdata";
            
      fetch(urlFetch, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey,
        },
        body : JSON.stringify(data)
      }).then(res => {
        if(res.status === 200) 
          return res.json() 
      }).then( resJson => {
        if(resJson.status === '200') {
          window.location.href='/IUserTraining/';
        } else {
          console.log(resJson);
        }
      });
    }
  }

  handleSubmit1(e) {
    e.preventDefault();
    if((this.state.full_name !== '') && (this.state.email !== '') && (this.state.expiry_date !== '')) {
      document.getElementById('loading-area').style.display = 'block';
      var fullName,email,phone,language,pictureUrl,expiryDate;      
      if(this.textInputFullName.value === '') { fullName = this.state.full_name; } else { fullName = this.textInputFullName.value; }
      if(this.textInputEmail.value === '') { email = this.state.email; } else { email = this.textInputEmail.value; }
      if(this.textInputPhone.value === '') { phone = this.state.phone; } else { phone = this.textInputPhone.value; }
      if(this.textInputLanguage.value === '') { language = this.state.language; } else { language = this.textInputLanguage.value; }
      if(this.textInputPictureUrl.value === '') { pictureUrl = this.state.picture_url; } else { pictureUrl = this.textInputPictureUrl.value; }
      expiryDate = this.state.expiry_date;
      
      var datatext ='{"user_fullname": "'+fullName+'","user_email": "'+email+'","user_phone": "'+phone+'","user_language": "'+language+'","user_picture": "'+pictureUrl+'","expiry_date": "'+expiryDate+'"}';
      var datasend = JSON.parse(datatext);

      fetch(global.config.urlLink+"/ceklogin", {
        method: "POST",
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'Access-Control-Allow-Origin': "*",
          'Authorization': authKey
        },
        body: JSON.stringify(datasend),
      })
      .then((result) => {
        if (result.status === 200) {
          return result.json();
        } else {
          console.log("Something happened wrong");
        }
      })
      .then((resultJson) => {
        if(resultJson.status === '200') {
          window.location.href='/IUserTraining/';
        } else {
          console.log(resultJson);
        }
      });        
    } else {
      console.log(this.textInputFullName.value);
      var arrfieldName = ['full_name','email','phone','language','picture_url','expiry_date'];
      var arrvalue = [this.textInputFullName.value,this.textInputEmail.value,this.textInputPhone.value,this.textInputLanguage.value,this.textInputPictureUrl.value,this.state.course_date];
      for(var ck in arrfieldName) {
        this.validateField(arrfieldName[ck], arrvalue[ck]);
      }
    }
  }

  contentTabs(param, props) {
    if (param === "bulk") {
      return(
        <div>
          <div className="row show">
            <div className="col-6 divMiddle"></div>
            <div className="col-6 form-inline justify-content-end">#1</div>
          </div>
          <div className="mgT20">
            <div className="divHide" id="result2-area">
              <div id="result2-value" className="row-field mgT30 mgB30 errText textCenter"></div>
            </div>
            <form onSubmit={this.handleSubmitBulk}>
              <div className="row-field mgT20">
                <label className="w280x mgT05">To input your list of users</label>
                <div className="w280x-min flex-container">
                  <button className="subgenButton" onClick={() => this.handleLinkClick()}>Open Template</button>        
                </div>
              </div>
              <div className="row-field mgT05">
                <label className="w280x mgT05" htmlFor="file_url">File Url</label>
                <div className="w280x-min">
                  <input type="text" className="form-control" name="file_url"
                    placeholder={this.state.file_url}
                    value={this.state.file_url}
                    onChange={this.handleUserInput}  
                    ref={(input) => this.textInputFileUrl = input}/>
                  {/* <div className="mgT05">
                    {<FormErrors formErrors={this.state.formErrors.file_url}/>}
                  </div> */}
                </div>
              </div>
              <div className="row-field mgT30 mgB30">
                <div className="w100p"><button id="submit-button" type="submit" className="w100p submitButton">Submit</button></div>
              </div>
            </form>
          </div>
        </div>
      )
    } else {
      return(
        <div>
          <div className="row show">
            <div className="col-6 divMiddle"></div>
            <div className="col-6 form-inline justify-content-end">#2</div>
          </div>
          <div className="mgT20">
            <div className="divHide" id="result-area">
              <div id="result-value" className="row-field mgT10 mgB30 errText textCenter"></div>
            </div>
            <form onSubmit={this.handleSubmit1}>
              <div className="row-field mgT20">
                <label className="w280x mgT05" htmlFor="full_name">Full Name</label>
                <div className="w280x-min">
                  <input type="text" className="form-control" name="full_name"
                    placeholder={this.state.full_name}
                    value={this.state.full_name}
                    onChange={this.handleUserInput}  
                    ref={(input) => this.textInputFullName = input}/>
                  <div className="mgT05">
                    {<FormErrors formErrors={this.state.formErrors.full_name}/>}
                  </div>
                </div>
              </div>
              <div className="row-field mgT20">
                <label className="w280x mgT05" htmlFor="email">Email</label>
                <div className="w280x-min">
                  <input type="email" className="form-control" name="email"
                    placeholder={this.state.email}
                    value={this.state.email}
                    onChange={this.handleUserInput}  
                    ref={(input) => this.textInputEmail = input}/>
                  <div className="mgT05">
                    {<FormErrors formErrors={this.state.formErrors.email}/>}
                  </div>
                </div>
              </div>
              <div className="row-field mgT20">
                <label className="w280x mgT05" htmlFor="phone">Phone</label>
                <div className="w280x-min">
                  <input type="text" className="form-control" name="phone"
                    placeholder={this.state.phone}
                    value={this.state.phone}
                    onChange={this.handleUserInput}  
                    ref={(input) => this.textInputPhone = input}/>
                  <div className="mgT05">
                    {<FormErrors formErrors={this.state.formErrors.phone}/>}
                  </div>
                </div>
              </div>
              <div className="row-field mgT20">
                <label className="w280x mgT05" htmlFor="language">Language</label>
                <div className="w280x-min">
                  <select name="language" className="form-control" 
                    value={this.state.language} 
                    onChange={this.handleInputSelect}
                    ref={(input) => this.textInputLanguage = input}
                  >
                    <option value="">-- please select --</option>
                    <option>English</option>
                    <option>Indonesian</option>
                  </select>
                  <div className="mgT05">
                    {<FormErrors formErrors={this.state.formErrors.language}/>}
                  </div>
                </div>
              </div>
              <div className="row-field mgT20">
                <label className="w280x mgT05" htmlFor="picture_url">Picture URL</label>
                <div className="w280x-min">
                  <input type="text" className="form-control" name="picture_url"
                    placeholder={this.state.picture_url}
                    value={this.state.picture_url}
                    onChange={this.handleUserInput}  
                    ref={(input) => this.textInputPictureUrl = input}/>
                  <div className="mgT05">
                    {<FormErrors formErrors={this.state.formErrors.picture_url}/>}
                  </div>
                </div>
              </div>
              <div className="row-field mgT20">
                <label className="w280x mgT05" htmlFor="expiry_date">Expiry Date</label>
                <div className="w280x-min">                                    
                  <DatePicker
                    value={this.state.expiry_date}
                    onChange={this.handleInputDate}
                    customStyles={{dateInput:{borderWidth: 0}}}
                  />
                  <div className="mgT05">
                    {<FormErrors formErrors={this.state.formErrors.expiry_date}/>}
                  </div>
                </div>
              </div>
              <div className="row-field mgT30 mgB30">
                <div className="w100p"><button id="submit-button"  type="submit" className="w100p submitButton">Submit</button></div>
              </div>
            </form>
          </div>
        </div>
      )
    }
  }  
  
  componentDidMount() {
    var usageFor = 'bulk'
    var defindex = 0;
    
    this.setState({
      full_name: '',
      email: '',
      phone: '',
      language: '',
      picture_url: '',
      expiry_date: '',
      defTabIndex: defindex,
      usageFor: usageFor,
      formErrors: { full_name: '', email: '', phone: '', language: '', picture_url: '', expiry_date: '' },
      fullNameValid: false,
      emailValid: false,
      phoneValid: false,
      languageValid: false,
      pictureUrlValid: false,
      expiryDateValid: false,
      formValid: false,
      isOpen: false,
      otherContent: '',
    }) 
  }

  render() {    
    if (this.state.usageFor === '') {
      return (<div className="main-container"></div>);
    } else {
      var defindex = 0;
      var suppContentPop = "";
      return (
        <div className="main-container">
          <div className="header-main"><IHeader/></div>
          <div className="flex-container">
            <div className="sidebar-main"><ISidemenu/></div>
            <div className="main-content">
              <div className="stTitle">Users</div>
              {
                ((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[8] === "ON")) ? (
                  <div>
                    <div className="stIndiTitle">Users &gt; Add Users</div>
                    <div className="mgT30">
                      <Tabs defaultIndex={defindex} onSelect={this.handleSelectTab}>
                        <TabList className="tabs-class__tab-list">
                          <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Bulk Upload</Tab>
                          <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Single Upload</Tab>
                        </TabList>
                        <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">{this.contentTabs("bulk")}</TabPanel>
                        <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">{this.contentTabs("single")}</TabPanel>
                      </Tabs>
                    </div>
                  </div>
                ) : (
                  <div><INA/></div>
                )
              }
            </div>
          </div>
          <div id="loading-area" className="divHide loading"></div>
        </div>
      );
    }
  }
}
  
export default IUserTraining;