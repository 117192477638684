import React, { Component } from 'react';
import '../../inc/genstyle.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
//import IUC from './ifragmentuc';
import INA from './ifragmentna';
import Popup from '../../inc/popup';
import '../../inc/popup.css'
import ILicenceTypeForm from './ilicencetype-form';
import ILicenceTypeDetail from './ilicencetype-detail';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[3];

class ILicencesType extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        styleTb: 'table',
        data: [],
        data_tab: [],
        stPopTitle: '',
        isOpen: false,
        isOpen2: false,
        otherContent: '',
        defTabIndex: 0,
        usageFor: 'Singapore',
        selectLicCath: '',
        
    };
    //this.handleChange = this.handleChange.bind(this);
    //this.deleteHandler = this.deleteHandler.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleSelectTab = this.handleSelectTab.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false,
      }
    })
  }  
 
  urlview(used,id,action) {
    var stPopTitle = "";
    if(used === 'Singapore') { stPopTitle = action+" Singapore Licence"; }
    else if(used === 'Malaysia') { stPopTitle = action+" Malaysia Licence"; }
    else if(used === 'Indonesia') { stPopTitle = action+" Indonesia Licence"; }
    this.setState( prevState => {
      return {  
        ...prevState.stPopTitle, stPopTitle: stPopTitle,  
          ...prevState.isOpen2, isOpen2: true,
          ...prevState.otherContent, otherContent : '{"usageFor":"'+used+'","lictypeId":"'+id+'","action":"'+action+'"}'
      }
    })
  }
  urlform(used,id,action) {
    console.log("form:"+used);
    var stPopTitle = "";
    if(used === 'Singapore') { stPopTitle = action+" Singapore Licence"; }
    else if(used === 'Malaysia') { stPopTitle = action+" Malaysia Licence"; }
    else if(used === 'Indonesia') { stPopTitle = action+" Indonesia Licence"; }
    console.log("form:"+stPopTitle);
    this.setState( prevState => {
      return {  
          ...prevState.stPopTitle, stPopTitle: stPopTitle,
          ...prevState.isOpen, isOpen: true,
          ...prevState.otherContent, otherContent : '{"usageFor":"'+used+'","lictypeId":"'+id+'","action":"'+action+'"}'
      }
    })
  }
  urldelete(used,valId) {
    var stat = false;
    stat = window.confirm("Are you sure you want to delete this data?");
    if(stat === true) {
      document.getElementById('loading-area').style.display = 'block'; 
      //var urlString = global.config.urlLink+"/categorydelete/"+valId;
      var urlString = global.config.urlLink+"/licencetypedelete";
      var datatext ='{"docid":"'+valId+'","numchild":0,"usageFor":"'+used.toLowerCase()+'"}';
      var data = JSON.parse(datatext); 
      fetch(urlString, {
          //method: 'DELETE',
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJson => {
          console.log(resJson);
          console.log(resJson.status);
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === "200") {
              window.location.reload();
              //window.location.href='/listevent';
          } else {
              console.log('Something happened wrong delete');
              console.log(resJson);
          }
      }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
        console.log(err);
      });
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    this.loadData(this.state.usageFor,this.textInputLicenceCategory.value);
  }
  handleUserSelect = (e) => {
    //alert(this.textInputMailTemplate.value)
    //alert(e.target.value)
    const value = e.target.value;
    const inputname = e.target.name;
    if(inputname === "select_liccategory") { 
        this.setState( prevState => {
            return {  
                ...prevState.selectLicCath, selectLicCath: value
            }
        })
        this.loadData(this.state.usageFor,value);
    } 
  }
  handleSelectTab(idx) {
    console.log(idx);
    var used = "Singapore";
    if(idx===0) { used = "Singapore"; }
    else if(idx===1) { used = "Malaysia"; }
    else if(idx===2) { used = "Indonesia"; }
    
    console.log("vdata")
    console.log(this.state.data);
    var datas = this.state.data.values;
    var resulttab = datas?.filter(
      // (list) => console.log(used.toLocaleLowerCase())
      (list) =>  list.content?.licencePriceForCountry.trim().toLowerCase() === used.toLowerCase()
    );
    console.log("result")
    console.log(resulttab)
    this.setState( prevState => {
      return {  
          ...prevState.usageFor, usageFor: used,
          ...prevState.data_tab, data_tab: resulttab
      }
    })
    // this.setState( prevState => {
    //     return {  
    //         ...prevState.data, data: result
    //     }
    // })
    // this.loadData(used,this.state.selectLicCath);

  }
  loadData(usedfor,selliccath) {
    // document.getElementById('loading-area').style.display = 'block'; 
    // var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'","usageFor":"'+usedfor+'","country":"'+this.state.usageFor+'","filter1":"'+this.state.selectliccath+'"}';
    // var datatext = '{"keyword":"'+usedfor.toLowerCase()+'","category":"'+selliccath+'","pageShow":"'+this.state.pageShow+'"}'; 
    var datatext = '{"keyword":"'+this.state.keyword+'","category":"'+selliccath+'","pageShow":"'+this.state.pageShow+'"}'; 
    console.log(datatext);
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/licencetypelist", {
        method: 'POST',
        //mode: 'no-cors', 
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            console.log('response');
            console.log(response);
            return response.json()
        } else {
            console.log('Something happened wrong');
            console.log(response);
        }
    }).then(resJson => {
        console.log(resJson);
        document.getElementById('loading-area').style.display = 'none'; 
        //resJson = '{status: 200, values: { userEmail: "melia@point-star.com",userFullname: "Melia",userId: "111773733087018575892",userLevel: "Admin",userName: "melia@point-star.com",userPassword: ""}}';
        
        var resulttab = resJson.values?.filter(
          // (list) => console.log(used.toLocaleLowerCase())
          (list) =>  list.content.licencePriceForCountry === usedfor.toLowerCase()
        );
        var styleTb = 'table';
        if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; } 
        this.setState({
            styleTb: styleTb,
            data: resJson,
            data_tab: resulttab
        })
        console.log("load result");
        console.log(resulttab);
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
        console.log(err);
    });
  }
  componentDidMount() {
    // var currentURL = window.location.pathname;
    // var arrPath = currentURL.split('/');
    //console.log(currentURL);
    //console.log(arrPath);
    //console.log(arrPath[2]);
    var usageFor = 'Singapore'
    var  defindex = 0;
    // if(arrPath[1] !== undefined && arrPath[1].toLowerCase() === "icategorycourse") { defindex = 1; usageFor = "course"; }
    
    this.setState({
      defTabIndex: defindex,
      usageFor: usageFor
    })
    //console.log("did:"+usageFor);
    this.loadData(usageFor,'');
  }

  contentTabs(param) {
    console.log("Tabs : "+param);
    // var stLabel = "singapore"; if(param === 'course') { stLabel = "Workshop"; }
    var stLabel = this.state.usageFor;
    var lictypeList = [];
    var datavalue = this.state.data_tab;
    // if(this.state.data.values.length > 0 && this.state.data.status === '200') {
    if(datavalue.length > 0) {
      for(var ls=0; ls < datavalue.length; ls++) {
          var retResult = datavalue[ls];
          var licStatus = false;
          if(retResult.content.licenceStatus !== undefined) { licStatus = retResult.content.licenceStatus; }
          lictypeList.push(<tr key={retResult.docid}>
              <th scope="row-field">{ls+1}</th>
              <td>{retResult.content.licenceType}</td>
              <td>{retResult.content.licenceCategory}</td>
              <td>{retResult.content.licencePrice}</td>
              <td>{retResult.content.licencePriceUSD}</td>
              <td>{retResult.content.licenceStatus}</td>
              <td>
                  <EditIcon className="iconGrey cursor-on mgR20" title="edit" onClick={this.urlform.bind(this, param,retResult.docid,'Edit')}/>
                  <DescriptionIcon className="iconGrey cursor-on mgR20" onClick={this.urlview.bind(this, param,retResult.docid,'View')}/>
                  <DeleteIcon className="iconGrey cursor-on" onClick={this.urldelete.bind(this, this.state.usageFor, retResult.docid)}/>
              </td>
            </tr>)
        }
       
    } else {
        lictypeList.push(<tr key="0">
            <td colSpan="5" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
          </tr>)
    }
    var licenceCathList = [];
    if(global.config.categoryLicence.length > 0) {
        for(var r in global.config.categoryLicence) {
            licenceCathList.push(<option key={r} value={global.config.categoryLicence[r]}>{global.config.categoryLicence[r]}</option>)
        }
    }
    return(<div>
              <div className="row show">
                  <div className="col-6 divMiddle">
                  <form method="post" id="search-form" className="form-inline" onSubmit={this.handleSubmit}>
                        <div className="flex-container">
                            <select name="select_liccategory" className="form-control"
                                    value={this.state.selectLicCath} 
                                    onChange={this.handleUserSelect}
                                    ref={(input) => this.textInputLicenceCategory = input}
                                >
                                <option value="">-- All Category --</option>
                                {licenceCathList}
                            </select>
                        </div>
                    </form>
                  </div>
                  <div className="col-6 form-inline justify-content-end">
                      <button className="genButton mgL20" onClick={this.urlform.bind(this, param,'','Add')}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>Add {stLabel} Licence</button>
                  </div>
              </div>
              <div className="row-field mgT20">
                  <table className={this.state.styleTb}>
                  <thead>
                      <tr key="0">
                      <th scope="col">#</th>
                      <th scope="col">Licence Name</th>
                      <th scope="col" style={{width: "180px"}}>Category</th>
                      <th scope="col" style={{width: "100px"}}>Price ({(this.state.usageFor === "Singapore")? "SGD" : (this.state.usageFor === "Malaysia") ? "RM" : "IDR" })</th>
                      <th scope="col" style={{width: "100px"}}>Price (USD)</th>
                      <th scope="col" style={{width: "100px"}}>Status</th>
                      <th scope="col" style={{width: "150px"}}>Action</th>
                      </tr>
                  </thead>
                  <tbody>
                      {lictypeList}
                  </tbody>
                  </table>
              </div>
           </div>
    )
  }  

  render() {
    if(this.state.usageFor === '') {
    //   console.log("test");
      return (<div className="main-container"></div>);
    } else {
      //console.log("abc");
    //   var currentURL = window.location.pathname;
    //   var arrPath = currentURL.split('/');
      // console.log(currentURL);
      // console.log(arrPath);
      // console.log(arrPath[2]);
      // var  defindex = 0;
      // if(arrPath[2] === "course") { defindex = 1; }
      // this.setState({
      //   defTabIndex: defindex,
      //   usageFor: arrPath[2]
      // })
      var  defindex = 0;
    //   if(arrPath[1] !== undefined && arrPath[1].toLowerCase() === "icategorycourse") { defindex = 1;  }
      
      return (
        <div className="main-container">
          <div  className="header-main"><IHeader/></div>
              <div className="flex-container">
                  <div className="sidebar-main"><ISidemenu/></div>
                  <div className="main-content">
                    <div className="stTitle">SKU Licence</div>
                    {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[1] === "ON"))? 
                    <div>
                      <div className="stIndiTitle">List of SKU Licence</div>
                      <div className="mgT30">
                        {/*<div className="content-container card card-body"> */}
                          <Tabs defaultIndex={defindex} onSelect={this.handleSelectTab}>
                            <TabList className="tabs-class__tab-list">
                              <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Singapore</Tab>
                              <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Malaysia</Tab>
                              <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Indonesia</Tab>
                            </TabList>

                            <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">{this.contentTabs("Singapore")}</TabPanel>
                            <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">{this.contentTabs("Malaysia")}</TabPanel>
                            <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">{this.contentTabs("Indonesia")}</TabPanel>
                          </Tabs>
                        {/*</div>*/}
                      </div>
                      <div>
                              {this.state.isOpen && <Popup
                                content={<><ILicenceTypeForm/></>}
                                title={this.state.stPopTitle}
                                others={this.state.otherContent}
                                styles={"50%"}
                                handleClose={this.togglePopClose.bind(this)}
                              />}
                              {this.state.isOpen2 && <Popup
                                content={<><ILicenceTypeDetail/></>}
                                title={this.state.stPopTitle}
                                others={this.state.otherContent}
                                styles={"70%"}
                                handleClose={this.togglePopClose.bind(this)}
                              />}
                        </div>
                    </div>
                    :   
                    <div><INA/></div>
                    }
                  </div>
              </div>
              <div id="loading-area" className="divHide loading"></div>
        </div>
      );
    }
  }
}
 
export default ILicencesType;