import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../../inc/popup.css';
import Popup from '../../inc/popup';
import ZTELogDetail from './ipopzte-log-detail';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import DescriptionIcon from '@material-ui/icons/Description';
import DateTimeFormats from '../../inc/date-time-format';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[30];
 
class IZTElog extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        styleTb: 'table',
        data: [],
        isOpen: false,
        otherContent: '', 
    };
    this.loadData = this.loadData.bind(this);
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
      }
    })
  }
  urlviewDetail(valId,content) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: true,
          ...prevState.otherContent, otherContent: content
      }
    })
  }
  loadData(state,value) {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'","fromset":"ztelog"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/zteloglist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200' && resJson.values.length > 0) { 
            var arrList = [];
            var arrSortByDesc = [];
            for(var x=0; x<resJson.values.length; x++) {
                arrList.push({
                    docid: resJson.values[x].docid,
                    createdDate: resJson.values[x].createdate,
                    content: resJson.values[x].content
                })
                
            }
            if(arrList.length>0) {
                arrSortByDesc = arrList.sort((c,d) => {
                  return d.createdDate._seconds - c.createdDate._seconds;
                })
              }
            this.setState({
                styleTb: 'table table-striped',
                data: arrSortByDesc,
                isOpen: false,
                otherContent: '',
            })
        } else {
            this.setState({
                styleTb: 'table',
                data: [],
                isOpen: false,
                otherContent: '',
            })
        } 
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  
  componentDidMount() {
    this.loadData();
  }

  render() {
    var logZteList = [];
    if(this.state.data.length > 0) {
     for(var ls=0; ls < this.state.data.length; ls++) {
         var retResult = this.state.data[ls];
         var cDatez="", domainName="", customerId="";
         if(retResult.content.createDate !== undefined) {
              cDatez = DateTimeFormats(retResult.content.createDate._seconds,"date-short");
         }
         if(retResult.content.request.preProvisionedDevice !== undefined) {
          domainName = retResult.content.request.preProvisionedDevice.domain;
	  if(retResult.content.request.preProvisionedDevice.customer_id !== undefined) {
		customerId = retResult.content.request.preProvisionedDevice.customer_id;
	  }
         } else { 
	   domainName = retResult.content.request.domain; 
	   if(retResult.content.request.customer_id !== undefined) {
		customerId = retResult.content.request.customer_id;
	   }
	   if(retResult.content.request.customer_id !== undefined) {
		customerId = retResult.content.request.customer_id;
	   }
	 }
         logZteList.push(<tr key={ls+1}>
             <th scope="row-field">{ls+1}</th>
             <td>{cDatez}<br/>by. {retResult.content.createByName}</td>
             <td>{domainName}</td>
             <td>{customerId}</td>
             <td>{retResult.content.type}</td>
             <td>
                <DescriptionIcon className="iconGrey mgR20 cursor-on" onClick={this.urlviewDetail.bind(this, retResult.docid, JSON.stringify(retResult))}/>
             </td>
           </tr>)
     }
    } else {
        logZteList.push(<tr key="0">
           <td colSpan="4" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
         </tr>)
    }
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Log ZTE Request</div>
                <div className="stIndiTitle">List of log request ZTE</div>
                <div className="mgT30">
                    {/*--*/}
                    <div className="content-container card card-body">
                        
                        <div className="row-field mgT20">
                            <table className={this.state.styleTb}>
                            <thead>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Requested</th>
                                <th scope="col">Domain Name</th>
                                <th scope="col">Customer Id</th>
                                <th scope="col">Type</th>
                                <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logZteList}
                            </tbody>
                            </table>
                        </div>
                        <div className="row mt-3">
                           
                        </div>
                    </div>
                    <div>
                        {this.state.isOpen && <Popup
                        content={<><ZTELogDetail/></>}
                        id={localStorage.getItem('uid')} 
                        title={"Log ZTE Detail"}
                        others={this.state.otherContent}
                        styles={"80%"}
                        handleClose={this.togglePopClose.bind(this, localStorage.getItem('uid'))}
                        />}
                    </div>
                    {/*--*/}
                </div>
              </div>
              
          </div>
          <div id="loading-area" className="divHide loading"></div> 
    </div>
   );
 }
}
 
export default IZTElog;