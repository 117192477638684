import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
//import IHeader from './ifragmentheader';
import DateTimeFormats from '../../inc/date-time-format';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';

const authKey = global.config.authBearerKey;
  
function urladdTest() {
    window.location.href='/TestAddDemoAppsheet';
}

class ITestDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        styleTb: 'table',
        data: [],
        isOpen: false,
        otherContent: ''
    };
    //this.handleChange = this.handleChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
    //this.deleteHandler = this.deleteHandler.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
      }
    })
  } 
  urlviewDetail(valId,content) {
    console.log(valId);
    console.log(content);
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: true,
          ...prevState.otherContent, otherContent: content, 
        
      }
    })
  }
  loadData() {
    console.log("loading...");
    document.getElementById('loading-area').style.display = 'block'; 
    //var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'"}';
    //var data = JSON.parse(datatext); 
    
        fetch(global.config.urlLink+"/testlist", {
            method: 'GET',
            //mode: 'no-cors', 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }
        }).then(response => {
            if(response.status === 200) {
                console.log('response');
                console.log(response);
                return response.json()
            } else {
                console.log('Something happened wrong');
                console.log(response);
            }
        }).then(resJson => {
            console.log(resJson);
            document.getElementById('loading-area').style.display = 'none'; 
            //resJson = '{status: 200, values: { userEmail: "melia@point-star.com",userFullname: "Melia",userId: "111773733087018575892",userLevel: "Admin",userName: "melia@point-star.com",userPassword: ""}}';
            var styleTb = 'table';
            if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; }
            var arrContentsList = [];
            var arrContentSortByDesc = [];
            if(resJson.values.length > 0 && resJson.status === '200') {
                for(var v=0; v < resJson.values.length; v++) {
                    arrContentsList.push({
                        docid: resJson.values[v].docid,
                        content: resJson.values[v].content,
                        contentdate: resJson.values[v].content.registeredDate,
                    })
                }
                if(arrContentsList.length>0) {
                    arrContentSortByDesc = arrContentsList.sort((c,d) => {
                        return d.contentdate._seconds - c.contentdate._seconds;
                    })
                }
            } 
            //console.log(arrContentSortByDesc)
            
            this.setState({
                styleTb: styleTb,
                data: arrContentSortByDesc
            })
        }).catch(err => {
            document.getElementById('loading-area').style.display = 'none'; 
            console.log(err);
        });
    
  }

  componentDidMount() {
    var geturl = this.props.location; //console.log(geturl);
    var arrString = geturl.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');
    //console.log(arrUrl[2]);

    this.loadData(arrUrl[2]);
    /*
    var resStrJson = '{"status": "200", "values": [{ "docid": "xX0lD0mz0Q1I4U8eTDhh", "content": {"userEmail": "melia@point-star.com","userFullname": "Melia","userId": "111773733087018575892","userLevel": "Admin","userName": "melia@point-star.com","userPassword": "","lastLogin": {"_nanoseconds": "114000000","_seconds": "1607851009"}}},{ "docid": "xX0lD0mz0Q1I4U8eTDhh", "content": {"userEmail": "melia2@point-star.com","userFullname": "Melia2","userId": "111773733087018575892","userLevel": "User","userName": "melia2@point-star.com","userPassword": "","lastLogin": { "_nanoseconds": "114000000", "_seconds": "1607851009" }}}]}';
    console.log(resStrJson);
    var resJson = JSON.parse(resStrJson);
    var styleTb = 'table';
    if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; } 
    this.setState({
        styleTb: styleTb,
        data: resJson
    })
    */
 }
 render() {
    var testList = [];
    //console.log(this.state.data.values);
    //console.log(this.state.data.status);
    if(this.state.data.length > 0) {
        for(var ls=0; ls < this.state.data.length; ls++) {
            var retResult = this.state.data[ls];
            
            var editURL = "/TestEditDemoAppsheet/"+retResult.docid;
            //var viewURL = "/IViewTest/"+retResult.domain_name;
            // var date_ob = new Date(lastLoginz);
            // var date1 = date_ob.getDate();
            // var month1 = date_ob.getMonth() + 1;
            // var year1 = date_ob.getFullYear();
            var regDatez = "";
            if(retResult.content.registeredDate !== undefined) {
                regDatez = DateTimeFormats(retResult.content.registeredDate._seconds,"datetime-long");
            }
            
            testList.push(<tr key={retResult.docid}>
                <th scope="row-field">{ls+1}</th>
                <td>{regDatez}</td>
                <td>{retResult.content.userEmail}</td>
                <td>{retResult.content.userLevel}</td>
                <td>{retResult.content.userPosition}</td>
                <td>{retResult.content.userRegion}</td>
                <td>{retResult.content.userName}</td>
                <td><a href={editURL}><EditIcon className="iconGrey mgR20" title="edit" /></a></td>
              </tr>)
        }
    } else {
        testList.push(<tr key="1">
            <td colSpan="8" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
          </tr>)
        //memberList.push(<div className="mgT50 textCenter fontRed">:: no record ::</div>)
    }
    
   return (
    <div className="main-container">
          <div className="flex-container">
              {/*<div className="sidebar-main"><ISidemenu/></div>*/}
              <div className="w100p mgL20 mgR20" style={{marginTop:"20px"}}>
                <div className="stTitle"></div>
                <div className="stIndiTitle"></div>
                <div className="mgT30">
                    {/*--*/}
                    <div className="content-container card card-body">
                        <div className="row show">
                              <div className="col-6 divMiddle">
                              </div>
                              <div className="col-6 form-inline justify-content-end">
                                  <button className="genButton mgL20" onClick={urladdTest}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>Add New</button>
                              </div>
                        </div>
                        <div className="row-field mgT20">
                            <table className={this.state.styleTb}>
                            <thead>
                                <tr key="0">
                                <th scope="col">#</th>
                                <th scope="col">Register Date</th>
                                <th scope="col">Email</th>
                                <th scope="col">Level</th>
                                <th scope="col">Position</th>
                                <th scope="col">Region</th>
                                <th scope="col">Username</th>
                                <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {testList}
                            </tbody>
                            </table>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className="flex-container" style={{display:'none'}}>
                                    
                                </div>
                            </div>
                            <div className="col-6 form-inline justify-content-end">
                                
                            </div>
                        </div>
                    </div>
                    <div>
                          
                    </div>
                    {/*--*/}
                </div>
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div>
    </div>
   );
 }
}
 
export default ITestDemo;