import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../cinc/cStyle.css';
import { FormErrors } from '../../inc/error-form';
import DatePicker from 'react-date-picker';
import Axios from 'axios';

//import Alert from '@material-ui/lab/Alert';
// import parse from 'html-react-parser';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[17];

class CRequestForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            docid:  '',
            request_domainName:  '',
            request_licName:  '',
            licence_addseat:  '',
            request_payment_date: '',
            request_payment_remarks: '',
            request_method_payment: '',
            contentoth: '',
            formErrors: {request_payment_date: '',request_payment_remarks: ''},
            requestpaymentdateValid: false,
            requestpaymentremarksValid: false,
            formValid: false,
            errmsg: '',
            input_photo: null,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.handleInputPhoto = this.handleInputPhoto.bind(this);
    }
     
    handlePaymentDate = date => {
        //this.resetErrorMsg();
        console.log("dt:"+date);
        this.setState( prevState => {
            return {  
                ...prevState.request_payment_date, request_payment_date: date
            }
          }, () => { this.validateField('request_payment_date', date)
        }) 
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    } 
    handleInputPhoto = (e) => {
        if (e.target.files[0] !== null) {
          var value = e.target.files[0];
          if (value.type.indexOf('image') > -1) {
            this.setState({
              input_photo: value,
              form_error: []
            }, () => {
              document.getElementById('input-photo').innerText = this.state.input_photo.name;
            });
          }
        }
      }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let requestpaymentdateValid = this.state.requestpaymentdateValid;
        let requestpaymentremarksValid = this.state.requestpaymentremarksValid;
       
        switch(fieldName) {
            case 'request_payment_date': 
                requestpaymentdateValid = (value !== null && value !== '');
                fieldValidationErrors.request_payment_date = requestpaymentdateValid ? '' : 'payment date need to be filled';
                break;
            case 'request_payment_remarks': 
                requestpaymentremarksValid = value.trim().length > 0;
                fieldValidationErrors.request_payment_remarks = requestpaymentremarksValid ? '' : 'remarks need to be filled';
                break;
            default:
                break;
        }
        //alert(fieldName + ':' + JSON.stringify(fieldValidationErrors));
        
        this.setState({formErrors: fieldValidationErrors,
                        requestpaymentdateValid: requestpaymentdateValid,
                        requestpaymentremarksValid: requestpaymentremarksValid,
                    }, this.validateForm);                            
    }
    validateForm() {
        this.setState({formValid: this.state.requestpaymentdateValid && this.state.requestpaymentremarksValid});
    }
    handleSubmit(e) {
        e.preventDefault();
        if(this.state.request_payment_date !== "" && this.state.request_payment_remarks !== "") { 
            document.getElementById('loading-area').style.display = 'block'; 
            const data = new FormData();
            data.append('file', this.state.input_photo);
            data.append('source', 'cust-confirmation');
            Axios.post(global.config.urlLink+ "/uploadconfirmpayment", 
            data, { 
                headers: { 
                'content-type': 'multipart/form-data',
                'Authorization': authKey
                }
            }
            )
            .then(result => {
                var photo = result.data.values;
                var dataText = '{"request_id":"' + this.state.docid + '","update_status": "'+global.cconfig.requestStatus[5]+'","confirmation_date":"' + this.state.request_payment_date + '","confirmation_remarks":"' + this.state.request_payment_remarks+ '","confirmation_photo":"' + photo +'", "form_id" : "form-update-payment-addlicence"}';
                var dataSend = JSON.parse(dataText);

                fetch(global.config.urlLink+ "/updatereqaddlicence", {
                    method: 'POST',
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey
                    }, 
                    body : JSON.stringify(dataSend)
                })
                .then(response => {
                    if(response.status === 200) {
                        return response.json();
                    } else {
                        console.log('Something happened wrong.');
                    }
                }).then(resultJson => {
                    document.getElementById('loading-area').style.display = 'none'; 
                    console.log(resultJson);
                    // window.scrollTo(0, 0);
                    // window.location.reload();
                });
            });
        } else {
            var arrfieldName = ['request_payment_date','request_payment_remarks'];
            var arrvalue = [this.state.request_payment_date,this.textInpuRequestPaymentRemarks.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    componentDidMount() {
        //console.log("id:"+document.getElementById("contentid").innerText);
        //document.getElementById("errormsg").style.display = 'none';
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        console.log("test");
        console.log(othersContent);
        //var resSendContent = JSON.parse(othersContent.subcontent);
        //console.log(resSendContent);
       
        this.setState({
            stPopTitle: '',
            docid: othersContent.request_id,
            request_domainName: othersContent.domain_name,
            request_licName: othersContent.licence_type,
            licence_addseat: othersContent.add_seat,
            request_method_payment: othersContent.mpayment,
            contentoth: document.getElementById("contentoth").innerText
        }); 
    }
    render() {
      
      return (
        <div className="main-container">
            <div className="mgT20">
              <div >
                <div className="st3Title">Your Request</div>
              </div>
              <div className="mgT20">
                {/* ---- */}
                <div >
                    <div id="Form2aCenter" className="mgT20">
                        <div className="mgT20 flex-container">
                            <div className="w40p mgT05">Domain</div>
                            <div className="w60p mgT05">{this.state.request_domainName}</div>
                        </div>
                        <div className="mgT10 flex-container">
                            <div className="w40p mgT05">SKU</div>
                            <div className="w60p mgT05">{this.state.request_licName}</div>
                        </div>
                        <div className="mgT10 flex-container">
                            <div className="w40p mgT05">Additional Number of Licences</div>
                            <div className="w60p mgT05">{this.state.licence_addseat} seat(s)</div>
                        </div>
                        <div className="mgT20 flex-container">
                            <div className="w40p mgT05">Paid using</div>
                            <div className="w60p mgT05">{
                                (this.state.request_method_payment === global.cconfig.methodPayment[0])? 'PayPal' : 
                                (this.state.request_method_payment === global.cconfig.methodPayment[1])? 'Cash' : 
                                (this.state.request_method_payment === global.cconfig.methodPayment[2])? 'PayNow' : 
                                (this.state.request_method_payment === global.cconfig.methodPayment[3])? 'Wire / Bank Transfer' : 
                                (this.state.request_method_payment === global.cconfig.methodPayment[4])? 'Credit Card' : 
                                ''
                            } </div>
                        </div>
                    </div>
                    
                </div>
                <div className="mgT30 lineBottom">
                    <div className="flex-container">
                        <div className="w100p textBold">Confirm Your Payment</div>
                        
                    </div>
                </div>
                <div className="mgT20" id="request-payment-form">
                    <form id="form-request-payment" onSubmit={this.handleSubmit}>
                        <div>
                            <div className="flex-container mgT10">
                                <div className="w40p"><label className="mgT10" htmlFor="request_payment_date">Payment Received On</label></div>
                                <div className="w60p">
                                    <DatePicker
                                        onChange={this.handlePaymentDate}
                                        value={this.state.request_payment_date}
                                        customStyles={{dateInput:{borderWidth: 0}}}
                                        />
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.request_payment_date}/>  }</div>
                                </div>
                            </div>

                            <div className="flex-container mgT10">
                                <div className="w40p"><label className="mgT05" htmlFor="request_payment_receipt">Transfer Receipt</label></div>
                                <div className="w60p flex-container">
                                    <div className="custom-file">
                                        <input type="file" className="custom-file-input" name="confirmation_photo" onChange={this.handleInputPhoto}/>
                                        <label className="custom-file-label" id="input-photo" htmlFor="photo">Choose file</label>
                                    </div>
                                </div>
                            </div>
                            <div className="mgT30 textBold">Remarks</div>
                            <div className="mgT05">
                                <textarea name="request_payment_remarks" className="form-control" 
                                    rows={4} cols={30} 
                                    /*placeholder={this.state.event_name}*/
                                    value={this.state.request_payment_remarks} 
                                    onChange={this.handleUserInput} 
                                    ref={(input) => this.textInputRequestPaymentRemarks = input}
                                />
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.request_payment_remarks}/>  }</div>
                            </div>
                            <div className="mgT30 divCenter">
                                <div className="flex-container">
                                <button type="submit" className="submitButton mgR20">Submit</button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
                {/* ---- */}
                
              </div>
              
            </div>
            <div id="loading-area" className="divHide loading"></div> 
        </div>
      );
    }
 }
  
export default CRequestForm;

