
module.exports.message = function(value, type, vault, tax, region) {
    var subject_email, body_email;
    if(value === 'admin') {
        if (type === 'enterprise') { 
            subject_email = '[New Order: Licences Only] [[domainname]] | [[licenseval]] GAL | [[packageTypeName]]';
        } else {
            subject_email = '[New Order: Licences Only] [[domainname]] | [[licenseval]] GAL | [[licenceCurrency]] [[grandtotal]]';
        }
        body_email = '';
        body_email += '<table style="width=100%; border: .5px solid rgb(45,45,45); display:table;">';
        body_email += '<tr style="width:100%; height:100px; background:rgb(45,45,45); display:table;"><th style="text-align: left; padding: 0 15px; color: white; font-weight: lighter;"><img src="cid:logo" width="100" alt=""><br><span style="font-style: italic;font-size: 10px;">"Bringing Cloud to the World One Business at a Time."</span></th></tr>';
        body_email += '<tr style="display:table; margin-top:20px;"><td>New PointStar Additional <b>[[packageTypeName]]</b> Licence(s) Order :</td></tr>';
        body_email += '<tr><td style="text-align:center; font-weight:bold; font-size:20px; border-bottom:1px solid; display:table; margin-top:20px;">Order Details</td></tr>';
        
        body_email += '<tr><td style="display:table;" width="100%"><table width="100%">';
  
        body_email += '<tr><td align="right" valign="top" width="39%">Requester\'s Name</td><td align="center" valign="top" width="2%">:</td><td valign="top" width="59%">[[contactname]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Requester\'s Email</td><td align="center" valign="top">:</td><td valign="top">[[contactemail]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Requester\'s Phone</td><td align="center" valign="top">:</td><td valign="top">[[contactphone]]</td></tr>';
  
        body_email += '<tr><td height="10" colspan="3"></td></tr>';
        body_email += '<tr><td align="right" valign="top">Selected SKU</td><td align="center" valign="top">:</td><td valign="top">[[packageTypeName]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Month of Renewals</td><td align="center" valign="top">:</td><td valign="top">[[licenceMonthRenewal]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Company\'s Domain Name</td><td align="center" valign="top">:</td><td valign="top">[[domainname]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Company\'s Name</td><td align="center" valign="top">:</td><td valign="top">[[companyname]]</td></tr>';
        
        // body_email += '<tr><td align="right" valign="top">Country of Business</td><td align="center" valign="top">:</td><td valign="top">[[country]]</td></tr>';
        if (type === 'enterprise') {
            body_email += '<tr><td align="right" valign="top">No. of Licence(s) Requested </td><td align="center" valign="top">:</td><td valign="top">[[licenseval]]</td></tr>';
        }
        // body_email += '<tr><td align="right" valign="top">Month of Renewal</td><td align="center" valign="top">:</td><td valign="top">[[licmonthval]]</td></tr>';
        // if (typeprice === 'existCustomer') {
        //     body_email += '<tr><td align="right" valign="top">Last Renewal Date</td><td align="center" valign="top">:</td><td valign="top">[[licmonthval]]&nbsp;[[renYear]]</td></tr>';
        // } else {
        //     body_email += '<tr><td align="right" valign="top">Purchase Month</td><td align="center" valign="top">:</td><td valign="top">[[licmonthval]]&nbsp;[[renYear]]</td></tr>';
        // }
        
      if (type === 'enterprise') { } 
      else {
        body_email += '<tr><td height="10" colspan="3"></td></tr>';
        body_email += '<tr><td align="right" valign="top" style="width:35%;">Selected Payment Method</td><td align="center" valign="top">:</td><td valign="top">[[methodmessage]]</td></tr>';
        
        body_email += '<tr><td height="10" colspan="3"></td></tr>';
        body_email += '<tr><td height="10" colspan="3"><center><table>';

        body_email += '<tr><td style="border:solid 1px #000; background-color:#000; color:#fff; padding:5px; text-align:center;" width="40%">Item</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center;" width="15%">Unit</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center; " width="15%">Price / unit / year</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center; " width="15%">of Subscribed Month</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center; " width="15%">Amount</td></tr>';
        body_email += '<tr><td style="border:solid 1px #666; padding:5px;">[[packageTypeName]] Licences<br/><small><i>prorated for [[licenceMonthRate]] month(s)</i></small></td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licenseval]] user(s)</td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licenceCurrency]] [[licenceSeatRp_output]]</td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licenceMonthRate]] month(s)</td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licenceCurrency]] [[licenseTotalRp_output]]</td></tr>';
      if (vault > 0) { 
        body_email += '<tr><td style="border:solid 1px #666; padding:5px;">Vault Licences</td><td style="border:solid 1px #666; padding:5px; text-align:center;">[[vaultval]] user(s)</td><td style="border:solid 1px #666; text-align:center; padding:5px;">[[licenceCurrency]] [[vaultSeatRp_output]]</td><td style="border:solid 1px #666; text-align:center; padding:5px;">[[licenceMonthRate]] month(s)</td><td style="border:solid 1px #666; text-align:center; padding:5px;">[[licenceCurrency]] [[vaulttotal_output]]</td></tr>';
      }			
        body_email += '<tr><td style="padding:5px; text-align:center;" colspan="3"></td><td style="padding:5px; text-align:right;">Subtotal</td><td style="text-align:center; padding:5px;">[[licenceCurrency]] [[licenceSubtotalRp_output]]</td></tr>';
      if (tax > 0) {	
        body_email += '<tr><td style="padding:5px; text-align:center;" colspan="3"></td><td style="padding:5px; text-align:right;">GST [[taxRatePersen]]</td><td style="text-align:center; padding:5px;">[[licenceCurrency]] [[taxRp_output]]</td></tr>';
      }
        body_email += '<tr><td style="padding:5px; text-align:center;" colspan="3"></td><td style="font-weight:bold; font-size:130%; padding:5px; text-align:right;">Total</td><td style="font-weight:bold; font-size:130%; text-align:center; padding:5px;">[[licenceCurrency]] [[grandtotal]]</td></tr>';

        // body_email += '<tr><td style="border:solid 1px #000; background-color:#000; color:#fff; padding:5px; text-align:center;" width="50%">Description</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center;" width="15%">Quantity</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center; " width="15%">Price (USD)</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center; " width="20%">Frequency</td></tr>';
        // body_email += '<tr><td style="border:solid 1px #666; padding:5px;">[[packageTypeName]] Licences</td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licenseval]]</td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licensevalRp_output]]</td><td style="text-align:center; border:solid 1px #666; padding:5px;">Yearly</td></tr>';
        // if (vault > 0) { 
        //     body_email += '<tr><td style="border:solid 1px #666; padding:5px;">Vault Licences </td><td style="border:solid 1px #666; padding:5px; text-align:center;">[[vault]]</td><td style="border:solid 1px #666; text-align:center; padding:5px;">[[vaulttotal_output]]</td><td style="border:solid 1px #666; padding:5px; text-align:center;">Yearly</td></tr>';
        // }			
        // if (tax > 0) {			
        //     body_email += '<tr><td style="border:solid 1px #666; padding:5px;">+8% GST (Goods & Services Tax)</td><td style="border:solid 1px #666; padding:5px;"></td><td style="border:solid 1px #666; padding:5px; text-align:center;">[[taxRp_output]]</td><td style="border:solid 1px #666; padding:5px;"></td></tr>'
        // }
        // body_email += '<tr><td style="font-weight:bold; padding:5px; text-align:center; font-size:130%;">Total</td><td style="padding:5px; text-align:center;"></td><td style="padding:5px; font-weight:bold; font-size:130%; text-align:center;">[[grandtotal]]</td><td></td></tr>';
        body_email += '</table></center></td></tr>';
      }
        body_email += '</table></td></tr>';	
        body_email += '</table>';
        
    } else {
        subject_email = "Your Order: [[domainname]] on [[packageTypeName]]";
        
        body_email = '';
        body_email += '<table style="width=600px; border: .5px solid rgb(45,45,45); display:table;">';
        body_email += '<tr style="width:100%; height:100px; background:rgb(45,45,45); display:table;"><th style="text-align: left; padding: 0 15px; color: white; font-weight: lighter;"><img src="cid:logo" width="100" alt="asd"><br><span style="font-style: italic;font-size: 10px;">"Bringing Cloud to the World One Business at a Time."</span></th></tr>';
        body_email += '<tr style="display:table; margin-top:20px;"><td>Dear <span style="font-weight:bold;">[[contactname]]</span>,</td></tr>';
        body_email += '<tr style="display:table; margin-top:10px;"><td>Congratulations on your company expansion. Thank you for purchasing additional <span style="font-weight:bold;">[[packageTypeName]]</span> licence(s). Below are your order details. Please save this email for your own record.</td></tr>';
        body_email += '<tr><td style="text-align:center; font-weight:bold; font-size:20px; border-bottom:1px solid; display:table; margin-top:20px;">Order Details</td></tr>';
        
        body_email += '<tr><td style="display:table;" width="100%"><table width="100%">';
  
        body_email += '<tr><td height="10" colspan="3"></td></tr>';
        body_email += '<tr><td align="right" valign="top">Selected SKU</td><td align="center" valign="top">:</td><td valign="top">[[packageTypeName]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Company\'s Domain Name</td><td align="center" valign="top">:</td><td valign="top">[[domainname]]</td></tr>';
        body_email += '<tr><td align="right" valign="top">Company\'s Name</td><td align="center" valign="top">:</td><td valign="top">[[companyname]]</td></tr>';
        // body_email += '<tr><td align="right" valign="top">Country of Business</td><td align="center" valign="top">:</td><td valign="top">[[country]]</td></tr>';
        
      if (type === 'enterprise') { } 
      else {
        body_email += '<tr><td height="10" colspan="3"></td></tr>';
        body_email += '<tr><td align="right" valign="top" style="width:35%;">Selected Payment Method</td><td align="center" valign="top">:</td><td valign="top">[[methodmessage]]</td></tr>';
        
        body_email += '<tr><td height="10" colspan="3"></td></tr>';
        body_email += '<tr><td height="10" colspan="3"><center><table>';

        body_email += '<tr><td style="border:solid 1px #000; background-color:#000; color:#fff; padding:5px; text-align:center;" width="40%">Item</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center;" width="15%">Unit</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center; " width="15%">Price / unit / year</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center; " width="15%">of Subscribed Month</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center; " width="15%">Amount</td></tr>';
        body_email += '<tr><td style="border:solid 1px #666; padding:5px;">[[packageTypeName]] Licences<br/><small><i>prorated for [[licenceMonthRate]] month(s)</i></small></td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licenseval]] user(s)</td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licenceCurrency]] [[licenceSeatRp_output]]</td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licenceMonthRate]] month(s)</td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licenceCurrency]] [[licenseTotalRp_output]]</td></tr>';
      if (vault > 0) { 
        body_email += '<tr><td style="border:solid 1px #666; padding:5px;">Vault Licences</td><td style="border:solid 1px #666; padding:5px; text-align:center;">[[vaultval]] user(s)</td><td style="border:solid 1px #666; text-align:center; padding:5px;">[[licenceCurrency]] [[vaultSeatRp_output]]</td><td style="border:solid 1px #666; text-align:center; padding:5px;">[[licenceMonthRate]] month(s)</td><td style="border:solid 1px #666; text-align:center; padding:5px;">[[licenceCurrency]] [[vaulttotal_output]]</td></tr>';
      }			
        body_email += '<tr><td style="padding:5px; text-align:center;" colspan="3"></td><td style="padding:5px; text-align:right;">Subtotal</td><td style="text-align:center; padding:5px;">[[licenceCurrency]] [[licenceSubtotalRp_output]]</td></tr>';
      if (tax > 0) {	
        body_email += '<tr><td style="padding:5px; text-align:center;" colspan="3"></td><td style="padding:5px; text-align:right;">GST [[taxRatePersen]]</td><td style="text-align:center; padding:5px;">[[licenceCurrency]] [[taxRp_output]]</td></tr>';
      }
        body_email += '<tr><td style="padding:5px; text-align:center;" colspan="3"></td><td style="font-weight:bold; font-size:130%; padding:5px; text-align:right;">Total</td><td style="font-weight:bold; font-size:130%; text-align:center; padding:5px;">[[licenceCurrency]] [[grandtotal]]</td></tr>';
        body_email += '</table></center></td></tr>';
      

        
        // body_email += '<tr><td style="border:solid 1px #000; background-color:#000; color:#fff; padding:5px; text-align:center;" width="50%">Description</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center;" width="15%">Quantity</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center; " width="15%">Price (USD)</td><td style="border:solid 1px #000; padding:5px 20px; background-color:#000; color:#fff; text-align:center; " width="20%">Frequency</td></tr>';
        // body_email += '<tr><td style="border:solid 1px #666; padding:5px;">[[packageTypeName]] Licences</td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licenseval]]</td><td style="text-align:center; border:solid 1px #666; padding:5px;">[[licensevalRp_output]]</td><td style="text-align:center; border:solid 1px #666; padding:5px;">Yearly</td></tr>';
        // if (vault > 0) { 
        // body_email += '<tr><td style="border:solid 1px #666; padding:5px;">Vault Licences</td><td style="border:solid 1px #666; padding:5px; text-align:center;">[[vault]]</td><td style="border:solid 1px #666; text-align:center; padding:5px;">[[vaulttotal_output]]</td><td style="border:solid 1px #666; padding:5px; text-align:center;">Yearly</td></tr>';
        // }			
        // if (tax > 0) {			
        // body_email += '<tr><td style="border:solid 1px #666; padding:5px;">+8% GST (Goods & Services Tax)</td><td style="border:solid 1px #666; padding:5px;"></td><td style="border:solid 1px #666; padding:5px; text-align:center;">[[taxRp_output]]</td><td style="border:solid 1px #666; padding:5px;"></td></tr>'
        // }
        // body_email += '<tr><td style="font-weight:bold; padding:5px; text-align:center; font-size:130%;">Total</td><td style="padding:5px; text-align:center;"></td><td style="padding:5px; font-weight:bold; font-size:130%; text-align:center;">[[grandtotal]]</td><td></td></tr>';
        // body_email += '</table></center></td></tr>';
      }
        body_email += '</table></td></tr>';	
  
        body_email += '<tr style="display:table; margin-top:20px;"><td>';
      if (type === 'enterprise') {
        body_email += 'Your order will be processed in short time.';
        body_email += '<br/><br/>If you have any other queries or concerns, you may write in to renewal-sg@point-star.com';
      } else {
        // body_email += 'Please note that by proceeding with this order, you had already agreed to PointStar&acute;s <a href="https://gsuite.google.com/intl/en/terms/reseller_premier_terms.html" target="_blank">terms and conditions</a> as Google&acute;s Reseller.<br/><br/>Your order will be processing during office operation hours from 9 am to 6 pm UTC +8 (Mondays to Fridays, excl. public holidays).  If you are ordering from Indonesia, processing hours would be 8 am to 5 pm UTC +7 (Mondays to Fridays, excl. public holidays).<br/><br/>If you have any other queries or concerns, you may call us at <a href="tel:+6567730987">+65 6773 0987</a> on Singapore operation hours or <a href="tel:+622125558789">+62 21 2555 8789</a> on Indonesia operation hours to speak to one of our PointStar assistants who are always happy to help.</td></tr>';
        body_email += 'Please note that by proceeding with this order, you agree with PointStar\'s <a href="https://gsuite.google.com/intl/en/terms/reseller_premier_terms.html" target="_blank">terms and conditions</a> as Google\'s Reseller.';
        body_email += '<br/><br/>Your order will be processed during operating hours from 9 am to 6 pm GMT +8 (Mondays to Fridays, excluding public holidays).';
        body_email += '<br/><br/>If you have any other queries or concerns, you may write in to renewal-sg@point-star.com';
      }
        body_email += '</table>';
  
    }
    return [subject_email,body_email];
}

