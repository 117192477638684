import React from 'react';
import PropTypes from 'prop-types';
import '../iinc/iStyle.css';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import MailIcon from '@material-ui/icons/Mail';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Label from '@material-ui/icons/Label';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PeopleIcon from '@material-ui/icons/People';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SchoolIcon from '@material-ui/icons/School';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import FolderSharedIcon from '@material-ui/icons/FolderShared';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[300]})`,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[300]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    flexDirection: 'row-reverse',
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 280,
  },
});

 
function onNodeSelect(url) {
  document.location.href=url;
} 
export default function ISidemenu() {
  const classes = useStyles();
  return (
    <div className="sidebar-content">
      <div className="smLeftMenu">MAIN MENU</div>
      <div>
      <TreeView
        className={classes.root}
        defaultExpanded={['3','5','8','9']}
        defaultCollapseIcon={<KeyboardArrowDownIcon />}
        defaultExpandIcon={<KeyboardArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        <StyledTreeItem nodeId="1" labelText="Dashboard" labelIcon={HomeIcon} onLabelClick={node => onNodeSelect("/InternalPortal")}/>

        {((localStorage.getItem('ulevel') === global.config.userlevel[0])  || (JSON.parse(localStorage.getItem('urole'))[0] !== undefined && JSON.parse(localStorage.getItem('urole'))[0] === "ON") || (JSON.parse(localStorage.getItem('urole'))[1] !== undefined && JSON.parse(localStorage.getItem('urole'))[1] === "ON") || (JSON.parse(localStorage.getItem('urole'))[7] !== undefined && JSON.parse(localStorage.getItem('urole'))[7] === "ON"))? 
        <StyledTreeItem nodeId="2" labelText="Client" labelIcon={LocalOfferIcon} onLabelClick={node => onNodeSelect("/IClients")}/>
        : ''}
        {((localStorage.getItem('ulevel') === global.config.userlevel[0])  || (JSON.parse(localStorage.getItem('urole'))[1] !== undefined && JSON.parse(localStorage.getItem('urole'))[1] === "ON"))? 
        <StyledTreeItem nodeId="3" labelText="Licences" labelIcon={FeaturedPlayListIcon}>
          {(localStorage.getItem('ulevel') === global.config.userlevel[0])? 
          <StyledTreeItem
            nodeId="31"
            labelText="SKU Licence"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/ILicenceTypes")}
          />
          : ''}
          <StyledTreeItem
            nodeId="32"
            labelText="Licence Expired"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/ILicenceExpired")}
          />
        </StyledTreeItem>
        : ''}
        {((localStorage.getItem('ulevel') === global.config.userlevel[0])  || (JSON.parse(localStorage.getItem('urole'))[1] !== undefined && JSON.parse(localStorage.getItem('urole'))[1] === "ON"))? 
        <StyledTreeItem nodeId="4" labelText="Log Notification" labelIcon={AccessTimeIcon} onLabelClick={node => onNodeSelect("/ILogNotification")}/>
        : ''}

        {((localStorage.getItem('ulevel') === global.config.userlevel[0])  || (JSON.parse(localStorage.getItem('urole'))[5] !== undefined && JSON.parse(localStorage.getItem('urole'))[5] === "ON") || (JSON.parse(localStorage.getItem('urole'))[7] !== undefined && JSON.parse(localStorage.getItem('urole'))[7] === "ON"))? 
        <StyledTreeItem nodeId="5" labelText="Client's Request" labelIcon={AssignmentLateIcon}>
          <StyledTreeItem
            nodeId="51"
            labelText="Add Licence Request"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/IAddLicence")}
          />
          <StyledTreeItem
            nodeId="52"
            labelText="Renewal Licence Request"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/IRenewLicence")}
          />
          <StyledTreeItem
            nodeId="53"
            labelText="Claim GCP Request"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/IClaimGCP")}
          />
          <StyledTreeItem
            nodeId="54"
            labelText="Upgrade Licence Request"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/IUpgradeLicence")}
          />
          <StyledTreeItem
            nodeId="55"
            labelText="Training Session Request"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/ITraining")}
          />
        </StyledTreeItem>
        : ''}
       
        {((localStorage.getItem('ulevel') === global.config.userlevel[0])  || (JSON.parse(localStorage.getItem('urole'))[5] !== undefined && JSON.parse(localStorage.getItem('urole'))[5] === "ON")  || (JSON.parse(localStorage.getItem('urole'))[6] !== undefined && JSON.parse(localStorage.getItem('urole'))[6] === "ON"))? 
        <StyledTreeItem nodeId="6" labelText="Content Insight" labelIcon={ListAltIcon} onLabelClick={node => onNodeSelect("/IAddContent")}/>
        : ''}
        {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[5] !== undefined && JSON.parse(localStorage.getItem('urole'))[5] === "ON"))? 
        <StyledTreeItem nodeId="7" labelText="Message From Clients" labelIcon={MailIcon} onLabelClick={node => onNodeSelect("/IClientMessages")}/>
        : ''}

        {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[2] !== undefined && JSON.parse(localStorage.getItem('urole'))[2] === "ON") || (JSON.parse(localStorage.getItem('urole'))[3] !== undefined && JSON.parse(localStorage.getItem('urole'))[3] === "ON") || (JSON.parse(localStorage.getItem('urole'))[4] !== undefined && JSON.parse(localStorage.getItem('urole'))[4] === "ON"))? 
        <StyledTreeItem nodeId="8" labelText="Training Booking System" labelIcon={SchoolIcon}>
          <StyledTreeItem
            nodeId="81"
            labelText="Category"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/ICategory")}
          />
          <StyledTreeItem
            nodeId="82"
            labelText="Library"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/ILibrary")}
          />
          <StyledTreeItem
            nodeId="83"
            labelText="Workshops"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/ICourse")}
          />
          <StyledTreeItem
            nodeId="84"
            labelText="Trainers"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/ITutor")}
          />
          <StyledTreeItem
            nodeId="85"
            labelText="Payment"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/IPayment")}
          />
          <StyledTreeItem
            nodeId="86"
            labelText="Users"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/IUserTraining")}
          />
          <StyledTreeItem
            nodeId="87"
            labelText="Feedback"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/IFeedback")}
          />
        </StyledTreeItem>
        : ''}
      {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[8] !== undefined && JSON.parse(localStorage.getItem('urole'))[8] === "ON"))? 
      <StyledTreeItem nodeId="9" labelText="ZTE" labelIcon={Label}>
          <StyledTreeItem
            nodeId="91"
            labelText="Register ZTE"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/IZTE")}
          />
          <StyledTreeItem
            nodeId="92"
            labelText="Log ZTE"
            labelIcon={ChevronRightIcon}
            labelInfo=""
            color="#E0E0E0"
            bgColor="#E0E0E0"
            classes={{ content : 'own-style-1' }}
            onLabelClick={node => onNodeSelect("/IZTELog")}
          />
          
      </StyledTreeItem>
      : ''}
      
      <StyledTreeItem nodeId="10" labelText="External Shared Drive" labelIcon={FolderSharedIcon} onLabelClick={node => onNodeSelect("/IExtDrive")}/>
      <StyledTreeItem nodeId="11" labelText="My Profile" labelIcon={PersonIcon} onLabelClick={node => onNodeSelect("/IMyProfile")}/>
      
        {(localStorage.getItem('ulevel') === global.config.userlevel[0])? 
        <StyledTreeItem nodeId="12" labelText="Member" labelIcon={PeopleIcon} onLabelClick={node => onNodeSelect("/IMembers")}/>
        : ''}
        
      </TreeView>
      </div>
      <div><p><br/><br/></p></div>
    </div>
  );
}
