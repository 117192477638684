import React, { Component } from 'react';
import '../../inc/genstyle.css';
import DataTable from "react-data-table-component";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
//import IUC from './ifragmentuc';
import INA from './ifragmentna';
import Popup from '../../inc/popup';
import '../../inc/popup.css'
import DateTimeFormats from '../../inc/date-time-format';

import ISExtDriveDetail from './iextdrive-detail';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[47];
const TopNavBar = [
  { id: 0, title: "My Drive", value: "mydrive" },
  { id: 1, title: "Shared to External", value: "sharedtoexternal" },
  { id: 2, title: "Shared to Anyone", value: "sharedtoanyonewithlink" },
];
class ISExtDrive extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        styleTb: 'table',
        driveData: [],
        columnsData: [],
        data_tab: TopNavBar,
        stPopTitle: '',
        isOpen: false,
        otherContent: '',
        defTabIndex: 0,
        pending: true
    };
    //this.handleChange = this.handleChange.bind(this);
    //this.deleteHandler = this.deleteHandler.bind(this);
    this.loadData = this.loadData.bind(this);
    this.handleSelectTab = this.handleSelectTab.bind(this);
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
      }
    })
  }  
 
  urlview(used,id,action) {
    var stPopTitle = "";
    if(used === 'Singapore') { stPopTitle = action+" Singapore Licence"; }
    else if(used === 'Malaysia') { stPopTitle = action+" Malaysia Licence"; }
    else if(used === 'Indonesia') { stPopTitle = action+" Indonesia Licence"; }
    this.setState( prevState => {
      return {  
        ...prevState.stPopTitle, stPopTitle: stPopTitle,  
          ...prevState.isOpen2, isOpen2: true,
          ...prevState.otherContent, otherContent : '{"usageFor":"'+used+'","lictypeId":"'+id+'","action":"'+action+'"}'
      }
    })
  }
  
  
  handleSelectTab(idx) {
    this.loadData(idx);
    // console.log(idx);
    // var used = "Singapore";
    // if(idx===0) { used = "Singapore"; }
    // else if(idx===1) { used = "Malaysia"; }
    // else if(idx===2) { used = "Indonesia"; }
    
    // console.log("vdata")
    // console.log(this.state.data);
    // var datas = this.state.data.values;
    // var resulttab = datas?.filter(
    //   // (list) => console.log(used.toLocaleLowerCase())
    //   (list) =>  list.content?.licencePriceForCountry.trim().toLowerCase() === used.toLowerCase()
    // );
    // console.log("result")
    // console.log(resulttab)
    // this.setState( prevState => {
    //   return {  
    //       ...prevState.usageFor, usageFor: used,
    //       ...prevState.data_tab, data_tab: resulttab
    //   }
    // })
    // this.setState( prevState => {
    //     return {  
    //         ...prevState.data, data: result
    //     }
    // })
    // this.loadData(used,this.state.selectLicCath);

  }
  loadData(tabindex) {
    const ActionCols = (row) => (
      <div className=" flex flex-row justify-center items-center">
        <MoreVertIcon
          className={`cursor-pointer hover:bg-gray-100`}
          size={40}
          onClick={this.urlview.bind(this, tabindex,row,'View')}
        />
      </div>
    );
    // const CellCols = (row) => {
    //   console.log(Math.round(new Date(row.modifiedTime).getTime()/1000));
    //   return row.modifiedTime;
      
    // };
    const columns = [
      {
        name: "Name",
        cell: (row) => row.name,
      },
      {
        name: "Type",
        cell: (row) => row.mimeType,
      },
      {
        name: "Modified Time",
        cell: (row) => DateTimeFormats(Math.round(new Date(row.modifiedTime).getTime()/1000),"datetime-short"),
        // cell: CellCols
      },
      {
        name: "Action",
        ignoreRowClick: true,
        grow: 0,
        // cell: (row) => <PopUpMenu key={row.id} data={row} />,
        cell: ActionCols,
      },
    ];
    

    /* ========= */
    // var urlEndPoint = this.state.data_tab[tabindex].value;
    document.getElementById('loading-area').style.display = 'block'; 
    var data = {
      paramType : this.state.data_tab[tabindex].value
    };
    fetch(global.config.urlLink+"/sharedrive", {
      method: 'POST',
      //mode: 'no-cors', 
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey,
      }, body : JSON.stringify(data)
  
    }).then((res) => res.json())
      .then((resJson) => {
          if (resJson.status === "200") {
            console.log(resJson);
            this.setState({
              driveData: resJson.values,
              columnsData: columns,
              pending: false
            })
          } else {
            console.log("error fetching status code");
          }
          document.getElementById('loading-area').style.display = 'none'; 
      }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
        console.log(err);
    });
  }
  componentDidMount() {
    var  defindex = 0;
    this.setState({
      defTabIndex: defindex
    })
    this.loadData(defindex);
  }

  contentTabs(param) {
    
    return(<div>
              <div className="row-field mgT20">
              <DataTable
                data={this.state.driveData}
                columns={this.state.columnsData}
                // progressPending={this.state.pending}
                dense
                pagination
                paginationPerPage={15}
                pointerOnHover
                customStyles={{
                  headRow: {
                    style: {
                      borderBottomWidth: "3px",
                      borderBottomStyle: "solid",
                      fontWeight: "bold",
                      backgroundColor: "#F5F6FA",
                      color: "#8A92A6",
                      fontSize: '12px'
                    },
                    denseStyle: {
                      minHeight: "50px",
                    },
                  },
                  rows: {
                    style: {
                      minHeight: "72px", // override the row height
                    },                    
                  },
                  cells: {
                    style: {
                      paddingLeft: "8px", // override the cell padding for data cells
                      paddingRight: "8px",
                      fontSize: '11px'
                    },
                  },
                  // table: {
                  //   style: {
                  //     height: `calc(100vh - 180px - ${height}px)`,
                  //   },
                  // },
                }}
              />
              </div>
           </div>
    )
  }  

  render() {
    
      var  defindex = 0;
    //   if(arrPath[1] !== undefined && arrPath[1].toLowerCase() === "icategorycourse") { defindex = 1;  }
      return (
        <div className="main-container">
          <div  className="header-main"><IHeader/></div>
              <div className="flex-container">
                  <div className="sidebar-main"><ISidemenu/></div>
                  <div className="main-content">
                    <div className="stTitle">External Shared Drive</div>
                    {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[2] === "ON") || (JSON.parse(localStorage.getItem('urole'))[3] === "ON") || (JSON.parse(localStorage.getItem('urole'))[4] === "ON"))? 
                    <div>
                      <div className="stIndiTitle">List of files on Google Drive that are shared outside the organization</div>
                      <div className="mgT30">
                        {/*<div className="content-container card card-body"> */}
                          <Tabs defaultIndex={defindex} onSelect={this.handleSelectTab}>
                            <TabList className="tabs-class__tab-list">
                              {
                                
                                this.state.data_tab.map((item, j) => {
                                  return (
                                    <Tab id={j}  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">{item.title}</Tab>
                                  )
                                })
                              }
                            </TabList>
                            {   
                                this.state.data_tab.map((item2, k) => {
                                  return (
                                    <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">{this.contentTabs(k)}</TabPanel>
                                  )
                                })
                            }
                            
                          </Tabs>
                        {/*</div>*/}
                      </div>
                      <div>
                              {this.state.isOpen && <Popup
                                content={<><ISExtDriveDetail/></>}
                                title={this.state.stPopTitle}
                                others={this.state.otherContent}
                                styles={"50%"}
                                handleClose={this.togglePopClose.bind(this)}
                              />}
                        </div>
                    </div>
                    :   
                    <div><INA/></div>
                    }
                  </div>
              </div>
              <div id="loading-area" className="divHide loading"></div>
        </div>
      );
    
  }
}
 
export default ISExtDrive;