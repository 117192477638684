import React, { Component } from 'react';
import '../../inc/genstyle.css';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import Popup from '../../inc/popup';
import '../../inc/popup.css'
import DateTimeFormats from '../../inc/date-time-format';
import NumberFormats from '../../inc/number-format';
import ReplaceAllContent from '../../inc/replace-all';
import ICourseAgendaForm from './icourse-agenda-form';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import NAimage from '../../assets/na.png';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[10];

class ICourseDetailEdit extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: 'Edit Workshop',
        course_docid: '',
        course_title: '',
        course_meet_link: '',
        course_short_desc: '',
        course_long_desc: '',
        course_language: '',
        course_currency: '',
        course_price: '',
        course_date: '',
        course_date2: '',
        course_start_time: '',
        course_end_time: '',
        course_picture: '',
        course_video: '',
        course_recording: '',
        course_category: '',
        course_subcategory: '',
        course_tutor: '',
        course_agenda_list: [],
        isOpen: false,
        isOpen2: false,
        stPopTitle: '',
        stPopTitle2: '',
        otherContent: '',
        otherContent2: ''
    }
    this.loadData = this.loadData.bind(this);
  }

  handleBack = () => {
    this.props.history.goBack();
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false
      }
    })
  }  
  urlnewAgenda(valId,subvalId,action) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: true,
          ...prevState.isOpen2, isOpen2: false,
          ...prevState.stPopTitle, stPopTitle: action+" Agenda",
          ...prevState.otherContent, otherContent : '{"courseId":"'+valId+'","courseTitle":"'+this.state.course_title+'","courseDate":"'+this.state.course_date2+'","crsagendaid":"'+subvalId+'"}'
      }
    })
  }

  urldeleteAgenda(valId,subvalId) {
    var stat = false;
    stat = window.confirm("Are you sure you want to delete this data?");
    if(stat === true) {
      document.getElementById('loading-area').style.display = 'block'; 
      var urlString = global.config.urlLink+"/courseagendadelete";
      var datatext ='{"docid":"'+valId+'","subdocid":"'+subvalId+'","numchild":0}';
      var data = JSON.parse(datatext); 
      fetch(urlString, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
      }).then(res => {
          if(res.status === 200) 
              return res.json() 
      }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === "200") {
              this.loadData(valId);
          } else {
              console.log('Something happened wrong delete');
          }
      }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
      });
    }
  }

  loadData(id) {    
      document.getElementById('loading-area').style.display = 'block'; 
      fetch(global.config.urlLink+"/courseview/"+id, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') {
              var lTitle="",lDate="",lMeetLink="",lShortDesc="",lLongDesc="",lLanguage="",lCategory="",lSubCategory="",lTutor="",lPriceCurrency="",lPrice="",crsDate="",crsStartTime="",crsEndTime="",lPicture="",lVideo="",lRecording="";
              var resultContent = resJson.values[0].content[0].maincontent;
              if(resultContent.courseTitle !== undefined) { 
                lTitle = resultContent.courseTitle; 
              }
              if(resultContent.courseShortDesc !== undefined) {
                  lShortDesc = ReplaceAllContent('[;]',',',resultContent.courseShortDesc);
                  lShortDesc = ReplaceAllContent('<br/>','\n',lShortDesc);
              }
              if(resultContent.courseLongDesc !== undefined) { 
                  lLongDesc = ReplaceAllContent('[;]',',',resultContent.courseLongDesc);
                  lLongDesc = ReplaceAllContent('<br/>','\n',lLongDesc);
              }
              if(resultContent.courseLanguage !== undefined) { 
                lLanguage = resultContent.courseLanguage;
              }
              if(resultContent.categoryId !== undefined) { 
                  lCategory = resultContent.categoryName;
              }
              if(resultContent.subcategoryId !== undefined) { 
                  lSubCategory = resultContent.subcategoryName;
              }
              if(resultContent.tutorId !== undefined) {
                var tutorNames = resultContent.tutorName.split(',');
                lTutor = tutorNames.join(', ');
              }
              if(resultContent.coursePicture !== undefined && resultContent.coursePicture !== "") { 
                lPicture = resultContent.coursePicture;
              } else { lPicture = NAimage; }
              if(resultContent.courseVideo !== undefined && resultContent.courseVideo !== "") { 
                lVideo = resultContent.courseVideo;
              }
              if(resultContent.courseRecording !== undefined && resultContent.courseRecording !== "") { 
                lRecording = resultContent.courseRecording;
              }
              if(resultContent.courseCurrency !== undefined && resultContent.courseCurrency !== "") { 
                lPriceCurrency = resultContent.courseCurrency;
              }
              if(resultContent.coursePrice !== undefined && resultContent.coursePrice !== "") { 
                lPrice = NumberFormats(resultContent.coursePrice);
              }
              if(resultContent.courseDate !== undefined && resultContent.courseDate !== "") { 
                lDate = resultContent.courseDate._seconds*1000;
                crsDate = DateTimeFormats(resultContent.courseDate._seconds,"date-long");
              }
              if(resultContent.courseStartTime !== undefined && resultContent.courseStartTime !== "") { 
                crsStartTime = DateTimeFormats(resultContent.courseStartTime._seconds,"time-24");
              }
              if(resultContent.courseEndTime !== undefined && resultContent.courseEndTime !== "") { 
                crsEndTime = DateTimeFormats(resultContent.courseEndTime._seconds,"time-24");
              }
              if(resultContent.courseMeetLink !== undefined) { 
                lMeetLink = resultContent.courseMeetLink; 
              }
              this.setState({
                  stTitle: 'Edit Workshop',
                  course_docid: resJson.values[0].docid,
                  course_title: lTitle,
                  course_meet_link: lMeetLink,
                  course_short_desc: lShortDesc,
                  course_long_desc: lLongDesc,
                  course_language: lLanguage,
                  course_currency: lPriceCurrency,
                  course_price: lPrice,
                  course_date: crsDate,
                  course_date2: lDate,
                  course_start_time: crsStartTime,
                  course_end_time: crsEndTime,
                  course_picture: lPicture,
                  course_video: lVideo,
                  course_recording: lRecording,
                  course_category: lCategory,
                  course_subcategory: lSubCategory,
                  course_tutor: lTutor,
                  course_agenda_list: resJson.values[0].scontent,
                  isOpen: false,
                  isOpen2: false,
                  stPopTitle: '',
                  stPopTitle2: '',
                  otherContent: '',
                  otherContent2: ''
              }) 
          } else {
              console.log('Something happened wrong');
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
        }); 
  }

  componentDidMount() {
    var geturl = this.props.location; 
    var arrUrl = [];
    arrUrl = geturl.pathname.split('/');
    this.loadData(arrUrl[2]);
  }

  render() {
   var agendaList = [];
   if(this.state.course_agenda_list.length > 0) {
    this.state.course_agenda_list.sort((a, b) => a.subcontent.agendaTime._seconds - b.subcontent.agendaTime._seconds);
    for(var ls=0; ls < this.state.course_agenda_list.length; ls++) {
      var retResult3 = this.state.course_agenda_list[ls];
      var trcls = '';
      var time = DateTimeFormats(retResult3.subcontent.agendaTime._seconds,"time-24");
      agendaList.push(
        <tr key={ls+1} className={trcls}>
          <th scope="row">{time}</th>
          <td>{retResult3.subcontent.agendaDuration + " minutes"}</td>
          <td>{retResult3.subcontent.agendaTitle}</td>
          <td>{retResult3.subcontent.agendaDesc}</td>
          <td>
              <EditIcon className="iconGrey cursor-on mgR20" title="edit" onClick={this.urlnewAgenda.bind(this, this.state.course_docid,retResult3.subdocid,'Edit')}/>
              <DeleteIcon className="iconGrey cursor-on" onClick={this.urldeleteAgenda.bind(this, this.state.course_docid, retResult3.subdocid)}/> 
          </td>
        </tr>
      );
    }
   } else {
      agendaList.push(<tr key="0">
          <td colSpan="5" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
        </tr>)
   }
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Workshops</div>
                {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[2] === "ON") || (JSON.parse(localStorage.getItem('urole'))[3] === "ON")  || (JSON.parse(localStorage.getItem('urole'))[4] === "ON"))? 
                <div>
                  <div className="stIndiTitle">Workshops { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                  <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="flex-container mgB20">
                            <div className="st2Title w70p"></div>
                            <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        {/* --- Start - DETAIL INFORMATION ---------- */}
                        <div id="detail-info-area">
                          <div className="flex-container">
                            <div className="w70p st4Title divMiddle">Detail Information</div>
                            <div className="w30p divRight">
                                <div className="linkText cursor-on"><a href={"/IEditCourse/"+this.state.course_docid}><EditIcon className="mgR05" style={{fontSize:"14pt"}} ></EditIcon><span style={{marginTop:"3px"}}>Edit Info &raquo;</span></a></div>
                            </div>
                          </div>
                          <div className="mgT30">
                            <div className="mgT20 flex-container">
                                <div className="w20p"><h6>Title</h6></div>
                                <div className="w80p"><span className="mgR10">:</span>{this.state.course_title}</div>
                            </div>
                            <div className="mgT05 flex-container">
                                <div className="w20p"><h6>Category</h6></div>
                                <div className="w80p"><span className="mgR10">:</span>{this.state.course_category} <KeyboardArrowRightIcon className="iconLightGrey"></KeyboardArrowRightIcon> {this.state.course_subcategory}</div>
                            </div>
                            <div className="mgT05 flex-container">
                                <div className="w20p"><h6>Language</h6></div>
                                <div className="w80p"><span className="mgR10">:</span>{this.state.course_language}</div>
                            </div>
                            <div className="mgT05 flex-container">
                                <div className="w20p"><h6>Price</h6></div>
                                <div className="w80p"><span className="mgR10">:</span>{this.state.course_currency} {this.state.course_price}</div>
                            </div>
                            <div className="mgT30 flex-container">
                                <div className="w20p"><h6>Tutor</h6></div>
                                <div className="w80p"><span className="mgR10">:</span>{this.state.course_tutor}</div>
                            </div>
                            <div className="mgT05 flex-container">
                                <div className="w20p"><h6>Schedule</h6></div>
                                <div className="w80p flex-container">
                                  <div><span className="mgR10">:</span>{this.state.course_date} </div>
                                  <div className="mgL30">
                                    <div className="flex-container"><div style={{width:"70px"}}> Start at</div> <div>{this.state.course_start_time}</div></div>
                                    <div className="flex-container"><div style={{width:"70px"}}> End at </div> <div>{this.state.course_end_time} </div></div>
                                  </div>
                                </div>
                            </div>
                            <div className="mgT30">
                                <div><h6>Short Description :</h6></div>
                                <div>{this.state.course_short_desc}</div>
                            </div>
                            <div className="mgT30">
                                <div><h6>Full Description :</h6></div>
                                <div>{this.state.course_long_desc}</div>
                            </div>
                            <div className="mgT30">
                                <div><h6>Google Meet Link :</h6></div>
                                <div><a href={this.state.course_meet_link} target="_blank" rel="noreferrer">{this.state.course_meet_link}</a></div>
                            </div>
                            <div className="mgT30">
                                <div><h6>Picture :</h6></div>
                                <div><img width="150" src={this.state.course_picture} alt=""/></div>
                            </div>
                            {(this.state.course_video !== "" && this.state.course_video !== undefined)?
                            <div className="mgT30">
                              <div>
                                <h6>Video Intro :</h6>
                                <iframe src={this.state.course_video} title={this.state.course_title}></iframe>
                              </div>
                            </div>
                            : '' }
                            {(this.state.course_recording !== "" && this.state.course_recording !== undefined)?
                            <div className="mgT30">
                              <div>
                                <h6>Video Recording :</h6>
                                <iframe src={this.state.course_recording} title={this.state.course_title}></iframe>
                              </div>
                            </div>
                            : '' }
                          </div>
                        </div>
                        {/* --- End - DETAIL INFORMATION ---------- */}

                       
                        {/* --- Start - AGENDA ---------- */}
                        <div className="mgT50" id="agenda-area">
                          <div className="flex-container">
                            <div className="w70p st4Title divMiddle">Agenda</div>
                            <div className="w30p divRight">
                              <button className="subgenButton mgL20" onClick={this.urlnewAgenda.bind(this, this.state.course_docid,'','Add New')}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>add new agenda</button>
                            </div>
                          </div>
                          <div className="mgT20">
                            <table className="table table-hover">
                              <thead className="thead-light">
                                  <tr key="0">
                                  <th scope="col textWrap" style={{width:"50px"}}>Time</th>
                                  <th scope="col textWrap">Duration</th>
                                  <th scope="col textWrap">Subject</th>
                                  <th scope="col textWrap">Description</th>
                                  <th scope="col textWrap" style={{width:"100px"}}>Action</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {agendaList}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {/* --- End - AGENDA ---------- */}
                    </div>
                    {/* ====== */}
                  </div>  
                </div>
                :   
                <div><INA/></div>
                }
              </div>
              {
                this.state.isOpen && <Popup
                content={<><ICourseAgendaForm/></>}
                id={this.state.course_id} 
                title={this.state.stPopTitle}
                others={this.state.otherContent}
                styles={"70%"}
                handleClose={this.togglePopClose.bind(this)}
                />
              }
          </div>
          <div id="loading-area" className="divHide loading"></div>
    </div>
   );
  }
}
 
export default ICourseDetailEdit;