import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import '../../inc/popup.css';
import Popup from '../../inc/popup';
import ZTEList from './ipopzte-list';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import { FormErrors } from '../../inc/error-form';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[30];
const zteKey = global.config.zteAPIKey;
const linktoregister = "https://chromecommercial.googleapis.com/v1/preProvisionedDevices:batchCreate?";

function firstFunction(currRecord,endRecord,data) {
    return new Promise((resolve, reject) => {
        var domainName = data.domainName;
        var customerId = data.customerId;
        var preprovisionToken = data.preprovisionToken;
        var fileId = data.fileId;
        var datatext = "";
        for(var n=0; n<endRecord; n++) {
            var contentData = data.dataSerialNumber[currRecord];
            var adId=contentData.adId;
            var hardwareModel = contentData.hardwareModel;
            var serialNumber = contentData.serialNumber;
            if(datatext !== "") { datatext += ","; }
            datatext +='{"preProvisionedDevice": {';
            datatext +='"serial_number": "'+serialNumber+'"';
            datatext +=',"hardware_model": "'+hardwareModel+'"';
            datatext +=',"device_pre_provisioning_token": "'+preprovisionToken+'"';
            datatext +=',"attested_device_id": "'+adId+'"';
            //datatext +=',"domain": "'+domainName+'"';
	    datatext +=',"customer_id": "'+ customerId +'"';
            if(contentData.assetId !== undefined && contentData.assetId !== "") {
                datatext +=',"annotated_asset_id": "'+contentData.assetId+'"';
            }
            if(contentData.location !== undefined && contentData.location !== "") {
                datatext +=',"annotated_location": "'+contentData.location+'"';
            }
            if(contentData.userEmail !== undefined && contentData.userEmail !== "") {
                datatext +=',"annotated_user": "'+contentData.userEmail+'"';
            }
            datatext +='}}';

            currRecord++;
            if(n === (endRecord-1)) {
                var statresult = "success";
                var senddatatext = '{"requests": ['+datatext+']}';
                var dataSend = JSON.parse(senddatatext); 
                var urlFetch = linktoregister+"key="+zteKey;
                fetch(urlFetch, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }, 
                    body : JSON.stringify(dataSend)
                }).then(res => {
                    document.getElementById('loading-area').style.display = 'none'; 
                        return res.json() 
                }).then( resJson => {
                    if(resJson.error !== undefined) {
                        statresult = resJson.error.message;
                    } else if(resJson.responses[0].status !== undefined) {
                        statresult = resJson.responses[0].status.message;
                    }
                    var reqData = '{"preProvisionedDevice": {"domain": "'+domainName+'","customer_id": "'+customerId+'","device_pre_provisioning_token": "'+preprovisionToken+'","fileid": "'+fileId+'"}}';
                    var datatext2 = '{"request":'+reqData+', "response":'+JSON.stringify(resJson)+',"fromset":"zte", "form_id":"insert-log"}';
                    var data2 = JSON.parse(datatext2); 
                    var urlFetch2 = global.config.urlLink+"/inszteres";
                    fetch(urlFetch2, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Authorization': authKey,
                        }, body : JSON.stringify(data2)
                    }).then(res2 => {
                        if(res2.status === 200) 
                            return res2.json() 
                    }).then( resJson2 => {
                        resolve([currRecord,senddatatext,statresult]);
                    })
                }).catch(err => {
                    document.getElementById('loading-area').style.display = 'none'; 
                });
            }
        }
    })
}
async function secondFunction(docid,resRow,limitSend,data) {
    var ceilData = Math.ceil(resRow/limitSend);
    var modData = resRow%limitSend;
    if(ceilData > 0) {
        var currRecord = 0;
        var endRecord = limitSend;
        for(var c=0; c<ceilData; c++) {
            if(modData > 0 && c === (ceilData-1)) { endRecord = modData; }
            var returnArr = await firstFunction(currRecord,endRecord,data);
            currRecord = returnArr[0];
            var returnErrMessage = "";
            var feedbackstatus = returnArr[2];
            returnErrMessage = feedbackstatus;
            if(c === (ceilData-1)) {
                if(docid !== "") {
                    var datatext ='{"doc_id":"'+docid+'","form_id":"form-edit-zte"}';
                    var urlFetch = global.config.urlLink+"/zteedit";
                    var dataupdate = JSON.parse(datatext); 
                    
                    fetch(urlFetch, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Authorization': authKey,
                        }, body : JSON.stringify(dataupdate)
                    }).then(res => {
                        if(res.status === 200) 
                            return res.json() 
                    }).then( resJson => {
                        document.getElementById('loading-area').style.display = 'none'; 
                        document.getElementById('result2-area').style.display = 'block';
                        document.getElementById('result2-value').innerHTML = returnErrMessage;
                    
                    }).catch(err => err);
                } else {
                    document.getElementById('loading-area').style.display = 'none'; 
                    document.getElementById('result2-area').style.display = 'block';
                    document.getElementById('result2-value').innerHTML = returnErrMessage;
                    
                }
                return currRecord;
            }
        }
    }
    
}; 
        
class IZTEForm extends Component {
    constructor(props) {
      super(props);
      this.state = {
          domain_name: '',
	  customer_id: '',
          preprovision_token: '',
          hardware_model: '',
          serial_number: '',
          ad_id: '',
          asset_id: '',
          asset_location: '',
          asset_user_email: '',
          domain2_name: '',
	  customer2_id: '',
          preprovision2_token: '',
          link2_url: '',
          defTabIndex: 0,
          usageFor: '',
          formErrors: { domain_name: '', customer_id: '', preprovision_token: '', hardware_model: '', serial_number: '', ad_id: '', domain2_name: '', customer2_id: '', preprovision2_token: '', link2_url: ''},
          domainnameValid: false,
	  customeridValid: false,
          preprovisiontokenValid:false,
          hardwaremodelValid: false,
          serialnumberValid: false,
          adidValid: false,
          domain2nameValid: false,
	  customer2idValid: false,
          preprovision2tokenValid:false,
          link2urlValid: false,
          formValid: false,
          isOpen: false,
          otherContent: '', 
      };
      this.handleSelectTab = this.handleSelectTab.bind(this);
      this.handleSubmit1 = this.handleSubmit1.bind(this);
      this.handleSubmitBulk = this.handleSubmitBulk.bind(this);
    }
    togglePopClose() {
        this.setState( prevState => {
          return {  
              ...prevState.isOpen, isOpen: false,
          }
        })
    }
    handleSelectTab(idx) {
        var used = "bulk";
        if(idx===1) { used = "single"; }
        this.setState( prevState => {
          return {  
              ...prevState.usageFor, usageFor: used
          }
        })
    }
    
    handleOpenListFile(e) {
        e.preventDefault();
        this.setState( prevState => {
            return {  
                ...prevState.isOpen, isOpen: true,
                ...prevState.otherContent, otherContent: '{}', 
            }
        })
        
    }
    handleGetFile(e) {
        e.preventDefault();
        document.getElementById('loading-area').style.display = 'block'; 
        var datatext = '{"fromset":"zte","form_id":"create-template"}';
        var data = JSON.parse(datatext); 
        var urlFetch = global.config.urlLink+"/createtemplatess";
        fetch(urlFetch, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }, body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                 return res.json() 
        }).then( resJson => {
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') {
                this.setState({ link2_url : resJson.values.fileinfo.fileLink});
                window.open(resJson.values.fileinfo.fileLink);
            } else {
                console.log('Something happened wrong');
            }
        }).catch(err => {
            document.getElementById('loading-area').style.display = 'none'; 
        });
    }
    
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    handleUserSelect = (e) => {
        var value = e.target.value;
        var inputname = e.target.name;
        
        this.setState( prevState => {
          return {  
              ...prevState.hardware_model, hardware_model: value,
          }
        }, () => { 
            this.validateField(inputname, value)
        }) 
      }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let domainnameValid = this.state.domainnameValid;
	let customeridValid = this.state.customeridValid;
        let preprovisiontokenValid = this.state.preprovisiontokenValid; 
        let hardwaremodelValid = this.state.hardwaremodelValid; 
        let serialnumberValid = this.state.serialnumberValid; 
        let adidValid = this.state.adidValid; 
        let domain2nameValid = this.state.domain2nameValid;
	let customer2idValid = this.state.customer2idValid;
        let preprovision2tokenValid = this.state.preprovision2tokenValid; 
        let link2urlValid = this.state.link2urlValid;

        switch(fieldName) {
            case 'domain_name': 
                domainnameValid = value.trim().length > 0;
                fieldValidationErrors.domain_name = domainnameValid ? '' : 'domain name need to be filled';
                break;
            case 'customer_id': 
                customeridValid = value.trim().length > 0;
                fieldValidationErrors.customer_id = customeridValid ? '' : 'customer id need to be filled';
                break;
            case 'preprovision_token': 
                preprovisiontokenValid = value.trim().length > 0;
                fieldValidationErrors.preprovision_token = preprovisiontokenValid ? '' : 'pre-provisioning token need to be filled';
                break;
            case 'hardware_model': 
                hardwaremodelValid = value.trim().length > 0;
                fieldValidationErrors.hardware_model = hardwaremodelValid ? '' : 'hardware model need to be filled';
                break;
            case 'serial_number': 
                serialnumberValid = value.trim().length > 0;
                fieldValidationErrors.serial_number = serialnumberValid ? '' : 'serial number need to be filled';
                break;
            case 'ad_id': 
                adidValid = value.trim().length > 0;
                fieldValidationErrors.ad_id = adidValid ? '' : 'ad id need to be filled';
                break;
            case 'domain2_name': 
                domain2nameValid = value.trim().length > 0;
                fieldValidationErrors.domain2_name = domain2nameValid ? '' : 'domain name need to be filled';
                break;
            case 'customer2_id': 
                customer2idValid = value.trim().length > 0;
                fieldValidationErrors.customer2_id = customer2idValid ? '' : 'customer id need to be filled';
                break;
            case 'preprovision2_token': 
                preprovision2tokenValid = value.trim().length > 0;
                fieldValidationErrors.preprovision2_token = preprovision2tokenValid ? '' : 'pre-provisioning token need to be filled';
                break;
            case 'link2_url':
                link2urlValid = value.trim().length > 0;
                fieldValidationErrors.link2_url = link2urlValid ? '' : 'link url need to be filled';
                break;
            default:
                break;
        }
        
        this.setState({formErrors: fieldValidationErrors,
                        domainnameValid: domainnameValid,
                        customeridValid: customeridValid,
                        preprovisiontokenValid: preprovisiontokenValid,
                        hardwaremodelValid: hardwaremodelValid,
                        serialnumberValid: serialnumberValid,
                        adidValid: adidValid,
                        domain2nameValid: domain2nameValid,
                        customer2idValid: customer2idValid,
                        preprovision2tokenValid: preprovision2tokenValid,
                        link2urlValid: link2urlValid
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.domainnameValid && this.state. customeridValid && this.state.preprovisiontokenValid && this.state.hardwaremodelValid && this.state.serialnumberValid && this.state.adidValid});
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit1(e) {
        e.preventDefault();
        if(this.state.domain_name !== '' && this.state.customer_id !== '' && this.state.preprovision_token !== '' && this.state.hardware_model !== '' && this.state.serial_number !== '' && this.state.ad_id !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var domainName,customerId,preprovisionToken,hardwareModel,serialNumber,adId;
            var assetId,assetLocation,assetUserEmail;
            if(this.textInputDomainName.value === '') { domainName = this.state.domain_name; } else { domainName = this.textInputDomainName.value; }
	    if(this.textInputCustomerId.value === '') { customerId = this.state.customer_id; } else { customerId = this.textInputCustomerId.value; }
            if(this.textInputPreprovisionToken.value === '') { preprovisionToken = this.state.preprovision_token; } else { preprovisionToken = this.textInputPreprovisionToken.value; }
            if(this.textInputHardwareModel.value === '') { hardwareModel = this.state.hardware_model; } else { hardwareModel = this.textInputHardwareModel.value; }
            if(this.textInputSerialNumber.value === '') { serialNumber = this.state.serial_number; } else { serialNumber = this.textInputSerialNumber.value; }
            if(this.textInputADID.value === '') { adId = this.state.ad_id; } else { adId = this.textInputADID.value; }
            if(this.textInputAssetID.value === '') { assetId = this.state.asset_id; } else { assetId = this.textInputAssetID.value; }
            if(this.textInputAssetLocation.value === '') { assetLocation = this.state.asset_location; } else { assetLocation = this.textInputAssetLocation.value; }
            if(this.textInputAssetUserEmail.value === '') { assetUserEmail = this.state.asset_user_email; } else { assetUserEmail = this.textInputAssetUserEmail.value; }
            
            // var datatext ='{"preProvisionedDevice": {"serial_number": "'+serialNumber+'","hardware_model": "'+hardwareModel+'","device_pre_provisioning_token": "'+preprovisionToken+'","attested_device_id": "'+adId+'","domain": "'+domainName+'"}}';
            var datatext ='{"preProvisionedDevice": {';
            datatext += '"serial_number": "'+serialNumber+'"'
            datatext += ',"hardware_model": "'+hardwareModel+'"';
            datatext += ',"device_pre_provisioning_token": "'+preprovisionToken+'"';
            datatext += ',"attested_device_id": "'+adId+'"';
            //datatext += ',"domain": "'+domainName+'"';
            datatext += ',"customer_id": "'+customerId+'"';
            if(assetId !== undefined && assetId !== "") {
                datatext +=',"annotated_asset_id": "'+assetId+'"';
            }
            if(assetLocation !== undefined && assetLocation !== "") {
                datatext +=',"annotated_location": "'+assetLocation+'"';
            }
            if(assetUserEmail !== undefined && assetUserEmail !== "") {
                datatext +=',"annotated_user": "'+assetUserEmail+'"';
            }
            datatext += '}}';
	    
            var senddatatext = '{"requests": ['+datatext+']}';
            var data = JSON.parse(senddatatext); 
console.log(data);
            var urlFetch = linktoregister+"key="+zteKey;
            fetch(urlFetch, {
                method: 'POST',
                body : JSON.stringify(data)
            }).then(res => {
                document.getElementById('loading-area').style.display = 'none'; 
                return res.json() 
            }).then( resJson => {
		var dataSave = JSON.parse(datatext);
		dataSave.preProvisionedDevice.domain = domainName;
		datatext = JSON.stringify(dataSave);
                var datatext2 = '{"request":'+datatext+', "response":'+JSON.stringify(resJson)+',"fromset":"zte", "form_id":"insert-log"}';
                var data2 = JSON.parse(datatext2); 
                var urlFetch2 = global.config.urlLink+"/inszteres";
                fetch(urlFetch2, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey,
                    }, body : JSON.stringify(data2)
                }).then(res2 => {
                    if(res2.status === 200) 
                        return res2.json() 
                }).then( resJson2 => {
                
                    document.getElementById('loading-area').style.display = 'none'; 
                    document.getElementById('result-area').style.display = 'block';
                    
                    if(resJson.responses[0].preProvisionedDevice !== undefined) {
                        document.getElementById('result-value').innerHTML = resJson.responses[0].preProvisionedDevice.deviceState + " Success";
                    } else {
                        console.log('Something happened wrong');
                        console.log(resJson);
                        document.getElementById('result-value').innerHTML = resJson.responses[0].status.message;
                        
                    }
                })
            }).catch(err => {
		document.getElementById('result-value').innerHTML = err;
                document.getElementById('loading-area').style.display = 'none'; 
            });

        } else {
            var arrfieldName = ['domain_name','customer_id','preprovision_token','hardware_model','serial_number','ad_id'];
            var arrvalue = [this.textInputDomainName.value,this.textInputCustomerId.value,this.textInputPreprovisionToken.value,this.textInputHardwareModel.value,this.textInputSerialNumber.value,this.textInputADID.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    handleSubmitBulk(e) {
        e.preventDefault();
        if(this.state.domain2_name !== '' && this.state.customer2_id !== '' && this.state.preprovision2_token !== '' && this.state.link2_url !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var domain2Name,customer2Id,preprovision2Token,link2Url;
            if(this.textInputDomain2Name.value === '') { domain2Name = this.state.domain2_name; } else { domain2Name = this.textInputDomain2Name.value; }
            if(this.textInputCustomer2Id.value === '') { customer2Id = this.state.customer2_id; } else { customer2Id = this.textInputCustomer2Id.value; }
            if(this.textInputPreprovision2Token.value === '') { preprovision2Token = this.state.preprovision2_token; } else { preprovision2Token = this.textInputPreprovision2Token.value; }
            if(this.textInputLink2Url.value === '') { link2Url = this.state.link2_url; } else { link2Url = this.textInputLink2Url.value; }
            
            var arrLinkPath = link2Url.replace("https://","").split('/');
            var fileid = link2Url;
            if(arrLinkPath.length > 0) {
                fileid = arrLinkPath[3];
            }
            var rangez = "Data!A2:F";
            var datatext = '{"fileid":"'+fileid+'","range":"'+rangez+'","fromset":"zte"}';
            var data = JSON.parse(datatext); 
            var urlFetch = global.config.urlLink+"/getssdata";
            
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                 }, body : JSON.stringify(data)
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                if(resJson.status === '200') {
                    var dataSN = resJson.values.content;
                    var docid = resJson.values.docid;
                    if(dataSN.length > 0) {
                        var dataTransfer = {
                            "domainName": domain2Name,
                            "customerId": customer2Id,
                            "preprovisionToken": preprovision2Token,
                            "fileId": fileid,
                            "dataSerialNumber": dataSN
                        }
                        var resRow = dataSN.length;
                        var limitSend = 99;
                        var response = secondFunction(docid,resRow,limitSend,dataTransfer);
                    } else {
                        document.getElementById('loading-area').style.display = 'none'; 
                    }
                    
                } else {
                    document.getElementById('loading-area').style.display = 'none'; 
                }
              }).catch(err => {
                document.getElementById('loading-area').style.display = 'none'; 
              }) ;
        } else {
            var arrfieldName = ['domain2_name','customer2_id','preprovision2_token','link2_url'];
            var arrvalue = [this.textInputDomain2Name.value,this.textInputCustomer2Id.value,this.textInputPreprovision2Token.value,this.textInputLink2Url.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    
    hardwareList() {
        var hwmodel = require('../iinc/zte-hw.json');
        var arrhwlist = [];
        if(hwmodel.hardwareModelAndBrandCodes.length > 0) {
            for(var h=0; h<hwmodel.hardwareModelAndBrandCodes.length; h++) {
                arrhwlist.push(<option key={h} value={hwmodel.hardwareModelAndBrandCodes[h].hardwareModel}>
                        {hwmodel.hardwareModelAndBrandCodes[h].hardwareModel}
                    </option>)
            }
        } 
        return arrhwlist;
    }
    contentTabs(param,props) {
      var inputHardwareList = this.hardwareList();
      if(param === "bulk") {
        return(<div>
            <div className="row show">
                <div className="col-6 divMiddle"></div>
                <div className="col-6 form-inline justify-content-end">#1
                </div>
            </div>
            <div className="mgT20">
                    <div className="divHide" id="result2-area">
                        <div id="result2-value" className="row-field mgT30 mgB30 errText textCenter"></div>
                    </div>
                <form onSubmit={this.handleSubmitBulk}>
                    <div className="row-field mgT20">
                        <label className="w280x mgT05" htmlFor="domain2_name">Domain Name</label>
                        <div className="w280x-min">
                            <input type="text" className="form-control" name="domain2_name"
                                placeholder={this.state.domain2_name}
                                value={this.state.domain2_name}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputDomain2Name = input}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.domain2_name}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT20">
                        <label className="w280x mgT05" htmlFor="customer2_id">Customer Id</label>
                        <div className="w280x-min">
                            <input type="text" className="form-control" name="customer2_id"
                                placeholder={this.state.customer2_id}
                                value={this.state.customer2_id}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputCustomer2Id = input}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.customer2_id}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT20">
                        <label className="w280x mgT05" htmlFor="preprovision2_token">Pre-provisioning token</label>
                        <div className="w280x-min">
                            <input type="text" className="form-control" name="preprovision2_token"
                                placeholder={this.state.preprovision2_token}
                                value={this.state.preprovision2_token}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputPreprovision2Token = input}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.preprovision2_token}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT20">
                        <label className="w280x mgT05">To input your list of serial number</label>
                        <div className="w280x-min flex-container">
                                <button className="subgenButton" onClick={this.handleGetFile.bind(this)}>Get new file</button>
                                <button className="mgL20 subgenButton" onClick={this.handleOpenListFile.bind(this)}>Open existing file</button>                 
                        </div>                            
                    </div>
                    <div className="row-field mgT05">
                        <label className="w280x mgT05" htmlFor="link2_url">File Url</label>
                        <div className="w280x-min">
                            <input type="text" className="form-control" name="link2_url"
                                placeholder={this.state.link2_url}
                                value={this.state.link2_url}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputLink2Url = input}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.link2_url}/>  }
                            </div>
                        </div>
                    </div>
                    
                    <div className="row-field mgT30 mgB30">
                        <div className="w100p"><button id="submit-button"  type="submit" className="w100p submitButton">Submit</button></div>
                    </div>
                    
                </form>
            </div>
         </div>)
      
      } else {
        return(<div>
                <div className="row show">
                    <div className="col-6 divMiddle"></div>
                    <div className="col-6 form-inline justify-content-end">#2
                    </div>
                </div>
                <div className="mgT20">
                        <div className="divHide" id="result-area">
                            <div id="result-value" className="row-field mgT10 mgB30 errText textCenter"></div>
                        </div>
                    <form onSubmit={this.handleSubmit1}>
                        <div className="row-field mgT20">
                            <label className="w280x mgT05" htmlFor="domain_name">Domain Name</label>
                            <div className="w280x-min">
                                <input type="text" className="form-control" name="domain_name"
                                    placeholder={this.state.domain_name}
                                    value={this.state.domain_name}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputDomainName = input}/>
                                <div className="mgT05">
                                    { <FormErrors formErrors={this.state.formErrors.domain_name}/>  }
                                </div>
                            </div>
                        </div>
                        <div className="row-field mgT20">
                            <label className="w280x mgT05" htmlFor="domain_name">Customer Id</label>
                            <div className="w280x-min">
                                <input type="text" className="form-control" name="customer_id"
                                    placeholder={this.state.customer_id}
                                    value={this.state.customer_id}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputCustomerId = input}/>
                                <div className="mgT05">
                                    { <FormErrors formErrors={this.state.formErrors.customer_id}/>  }
                                </div>
                            </div>
                        </div>
                        <div className="row-field mgT20">
                            <label className="w280x mgT05" htmlFor="preprovision_token">Pre-provisioning token</label>
                            <div className="w280x-min">
                                <input type="text" className="form-control" name="preprovision_token"
                                    placeholder={this.state.preprovision_token}
                                    value={this.state.preprovision_token}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputPreprovisionToken = input}/>
                                <div className="mgT05">
                                    { <FormErrors formErrors={this.state.formErrors.preprovision_token}/>  }
                                </div>
                            </div>
                        </div>
                        <div className="row-field mgT20">
                            <label className="w280x mgT05" htmlFor="hardware_model">Hardware Model</label>
                            <div className="w280x-min">
                                <input type="text" className="form-control" name="hardware_model"
                                    placeholder={this.state.hardware_model}
                                    value={this.state.hardware_model}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputHardwareModel = input}/>
			
                                
                                <div className="mgT05">
                                    { <FormErrors formErrors={this.state.formErrors.hardware_model}/>  }
                                </div>
                            </div>
                        </div>
                        <div className="row-field mgT20">
                            <label className="w280x mgT05" htmlFor="serial_number">Serial Number</label>
                            <div className="w280x-min">
                                <input type="text" className="form-control" name="serial_number"
                                    placeholder={this.state.serial_number}
                                    value={this.state.serial_number}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputSerialNumber = input}/>
                                <div className="mgT05">
                                    { <FormErrors formErrors={this.state.formErrors.serial_number}/>  }
                                </div>
                            </div>
                        </div>
                        <div className="row-field mgT20">
                            <label className="w280x mgT05" htmlFor="ad_id">AD ID</label>
                            <div className="w280x-min">
                                <input type="text" className="form-control" name="ad_id"
                                    placeholder={this.state.ad_id}
                                    value={this.state.ad_id}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputADID = input}/>
                                <div className="mgT05">
                                    { <FormErrors formErrors={this.state.formErrors.ad_id}/>  }
                                </div>
                            </div>
                        </div>
                        <div className="row-field mgT20">
                            <label className="w280x mgT05" htmlFor="asset_id">Asset ID</label>
                            <div className="w280x-min">
                                <input type="text" className="form-control" name="asset_id"
                                    placeholder={this.state.asset_id}
                                    value={this.state.asset_id}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputAssetID = input}/>
                            </div>
                        </div>
                        <div className="row-field mgT20">
                            <label className="w280x mgT05" htmlFor="asset_location">Location</label>
                            <div className="w280x-min">
                                <input type="text" className="form-control" name="asset_location"
                                    placeholder={this.state.asset_location}
                                    value={this.state.asset_location}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputAssetLocation = input}/>
                            </div>
                        </div>
                        <div className="row-field mgT20">
                            <label className="w280x mgT05" htmlFor="asset_user_email">User Email</label>
                            <div className="w280x-min">
                                <input type="text" className="form-control" name="asset_user_email"
                                    placeholder={this.state.asset_user_email}
                                    value={this.state.asset_user_email}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputAssetUserEmail = input}/>
                            </div>
                        </div>
                        <div className="row-field mgT30 mgB30">
                            <div className="w100p"><button id="submit-button"  type="submit" className="w100p submitButton">Submit</button></div>
                        </div>
                    </form>
                </div>
             </div>
        )
      }
    }  
    
    componentDidMount() {
        var usageFor = 'bulk'
        var  defindex = 0;
        
        this.setState({
          domain_name: '',
          customer_id: '',
          preprovision_token: '',
          hardware_model: '',
          serial_number: '',
          ad_id: '',
          domain2_name: '',
          customer2_id: '',
          preprovision2_token: '',
          link2_url: '',
          defTabIndex: defindex,
          usageFor: usageFor,
          formErrors: { domain_name: '', customer_id: '', preprovision_token: '', hardware_model: '', serial_number: '', ad_id: '', domain2_name: '', customer2_id: '', preprovision2_token: '',  link2_url:''},
          domainnameValid: false,
          customeridValid: false,
          preprovisiontokenValid:false,
          hardwaremodelValid: false,
          serialnumberValid: false,
          adidValid: false,
          domain2nameValid: false,
          customer2idValid: false,
          preprovision2tokenValid:false,
          link2urlValid: false,
          formValid: false,
          isOpen: false,
          otherContent: '',
        }) 
    }
    render() {
        
      if(this.state.usageFor === '') {
        return (<div className="main-container"></div>);
      } else {
        var  defindex = 0;
        var suppContentPop = '{"usage":"createzte"}';
        return (
          <div className="main-container">
            <div  className="header-main"><IHeader/></div>
                <div className="flex-container">
                    <div className="sidebar-main"><ISidemenu/></div>
                    <div className="main-content">
                      <div className="stTitle">Register ZTE</div>
                      {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[8] === "ON"))? 
                      <div>
                        <div className="stIndiTitle">registered chrome book identity to google</div>
                        <div className="mgT30">
                          {/*<div className="content-container card card-body"> */}
                            <Tabs defaultIndex={defindex} onSelect={this.handleSelectTab}>
                              <TabList className="tabs-class__tab-list">
                                <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Bulk Upload</Tab>
                                <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Single Upload</Tab>
                              </TabList>
  
                              <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">{this.contentTabs("bulk")}</TabPanel>
                              <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">{this.contentTabs("single")}</TabPanel>
                            </Tabs>
                          {/*</div>*/}
                        </div>
                        <div>
                            {this.state.isOpen && <Popup
                            content={<><ZTEList/></>}
                            id={localStorage.getItem('uid')} 
                            title={"File List"}
                            others={suppContentPop}
                            styles={"80%"}
                            handleClose={this.togglePopClose.bind(this, localStorage.getItem('uid'))}
                          />}
                        </div>
                      </div>
                      :   
                      <div><INA/></div>
                      }
                    </div>
                </div>
                <div id="loading-area" className="divHide loading"></div>
          </div>
        );
      }
    }
  }
   
  export default IZTEForm;