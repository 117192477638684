import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[1];
  
function urladdClient() {
    window.location.href='/IAddClients';
}

class IClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        selectRegion: '',
        styleTb: 'table',
        data: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  loadData(keyword,pagenum,selregion) {
    document.getElementById('loading-area').style.display = 'block'; 
    
    var datatext ='{"keyword":"'+keyword+'","pageShow":"'+pagenum+'"';
    if(selregion !== "") {
        datatext += ',"filter1":["'+selregion+'"]';
    } else { datatext += ',"filter1":[]'; }
    datatext += '}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/clientlist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        var styleTb = 'table';
        if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; } 
        this.setState({
            styleTb: styleTb,
            data: resJson
        })
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  urldeactiveClient(valId) {
    document.getElementById('loading-area').style.display = 'block';
    var datatext ='{"flagActive":false,"client_docid":"'+valId+'","form_id":"form-deactive"}';
    var data = JSON.parse(datatext); 
    var urlFetch = global.config.urlLink+"/clientdeactive/";
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJson => {
        document.getElementById('loading-area').style.display = 'none';
        if(resJson.status === '200') {
            window.location.href='/IClients';
        } else {
            console.log('Something happened wrong');
        }
      }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
      });
  }
  
  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value });
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    const inputname = e.target.name;
    if(inputname === "select_region") { 
        this.setState( prevState => {
            return {  
                ...prevState.selectRegion, selectRegion: value
            }
        })
        this.loadData(this.state.keyword,this.state.pageShow,value);
    } else {
        this.setState( prevState => {
            return {  
                ...prevState.pageShow, pageShow: value
            }
        })
    }
  }
    
  handleSubmit(e) {
    e.preventDefault();
    this.loadData(this.textInputKeyword.value,this.state.pageShow,this.textInputMemberRegion.value);
  }
  componentDidMount() {
    this.loadData(this.state.keyword,this.state.pageShow,this.state.selectRegion);
 }
 render() {
    var clientList = [];
    if(this.state.data.values.length > 0 && this.state.data.status === '200') {
        for(var ls=0; ls < this.state.data.values.length; ls++) {
            var retResult = this.state.data.values[ls];
            var editURL = "/IEditClients/"+retResult.docid;
            var viewURL = "/IViewClients/"+retResult.docid;
            clientList.push(<tr key={retResult.docid}>
                <th scope="row-field">{ls+1}</th>
                <td>{retResult.content.clientName}</td>
                <td>{retResult.content.domainName}</td>
                <td>{retResult.content.clientCountry}</td>
                <td style={{width:'150px'}}>
                    <a href={editURL}><EditIcon className="iconGrey mgR20" title="edit" /></a>
                    <a href={viewURL}><DescriptionIcon className="iconGrey mgR20" /></a>
                    {(localStorage.getItem('ulevel') === global.config.userlevel[0]) ? <DeleteIcon className="iconGrey cursor-on" onClick={this.urldeactiveClient.bind(this, retResult.docid)}/> : '' }
                </td>
              </tr>)
        }
    } else {
        clientList.push(<tr key="1">
            <td colSpan="5" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
          </tr>)
    }
    
    var regionList = [];
    if(global.config.userregion.length > 0) {
        for(var r in global.config.userregion) {
            regionList.push(<option value={global.config.userregion[r]}>{global.config.userregion[r]}</option>)
        }
    }
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Clients</div>
                {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[0] === "ON") || (JSON.parse(localStorage.getItem('urole'))[1] === "ON")  || (JSON.parse(localStorage.getItem('urole'))[7] === "ON"))? 
                <div>
                    <div className="stIndiTitle">List of our clients</div>
                    <div className="mgT30">
                        {/*--*/}
                        <div className="content-container card card-body">
                            <div className="row show">
                                <div className="col-6 divMiddle">
                                    <form method="post" id="search-form" className="form-inline" onSubmit={this.handleSubmit}>
                                    {(localStorage.getItem('ulevel') === global.config.userlevel[0])? 
                                        <div className="flex-container">
                                            <select name="select_region" className="form-control"
                                                    value={this.state.selectRegion} 
                                                    onChange={this.handleUserSelect}
                                                    ref={(input) => this.textInputMemberRegion = input}
                                                >
                                                <option value="">All Region</option>
                                                {regionList}
                                            </select>
                                        </div>
                                    : <div></div>
                                    }
                                        <div className="flex-container" style={{display:'none'}}>
                                            <input type="text" className="inputSearch mr-sm-2" name="keyword" id="keyword"
                                                placeholder="Search entries.."
                                                value={this.state.keyword}
                                                onChange={this.handleChange.bind(this)}
                                                ref={(input) => this.textInputKeyword = input}/> 
                                            <SearchIcon style={{ fontSize: 35 }} className="iconGrey mgT05 cursor-on" onClick={this.handleChange.bind(this)}></SearchIcon>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-6 form-inline justify-content-end">
                                    <button className="genButton mgL20" onClick={urladdClient}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>Add New Client</button>
                                </div>
                            </div>
                            <div className="row-field mgT20">
                                <table className={this.state.styleTb}>
                                <thead>
                                    <tr key="0">
                                    <th scope="col">#</th>
                                    <th scope="col">Client Name</th>
                                    <th scope="col">Domain Name</th>
                                    <th scope="col">Country</th>
                                    <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientList}
                                </tbody>
                                </table>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <div className="flex-container" style={{display:'none'}}>
                                        <div className="mgR20 divMiddle">Showing</div>
                                        <div className="mgR20 divMiddle show">
                                            <select className="btn btn-secondary" name="pageShow" 
                                                value={this.state.pageShow} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputPageShow = input} >
                                                <option value="1">1</option>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                        <div className="mgR20 divMiddle">list</div>
                                    </div>
                                </div>
                                <div className="col-6 form-inline justify-content-end">
                                    
                                </div>
                            </div>
                        </div>
                        {/*--*/}
                    </div>
                </div>
                : <div><INA/></div>
              }
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div> 
    </div>
   );
 }
}
 
export default IClient;