import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iGlobal';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import defMemberPicture from '../../assets/pic-profile.png';
 
const authKey = global.config.authBearerKey+"##"+global.config.kindcode[0];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[1];

class IMemberSetForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        memberdoc_id: '',
        member_email: '',
        member_position: '',
        member_level: '',
        member_fullname: '',
        member_picture: '',
        client_list: [],
        clientam_list: [],
        input_disabled: true
    }
  }
  handleBack = () => {
    this.props.history.goBack();
  }
  urlremoveAMClient(mId,cId,docId,stat,idx) {
    var urlString = global.config.urlLink+"/userremoveam/"+docId;
    fetch(urlString, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }
    }).then(response => {
        if(response.status === 200) {
            document.getElementById('removeam-'+idx).style.display = 'none'; 
        } else {
            console.log('Something happened wrong delete');
        }
    }).catch(err => err);
      
  }
  urlsetAMClient(mId,cId,docId,stat,idx) {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"member_docid":"'+mId+'","client_docid":"'+cId+'","doc_id":"'+docId+'","amstat":"'+stat+'","form_id":"form-set-am"}';
    var urlFetch =  global.config.urlLink+"/usersetam"; 
    var data = JSON.parse(datatext); 
    fetch(urlFetch, {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey,
      }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
            document.getElementById('setam-'+idx).style.display = 'none'; 
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => {
      document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  
  componentDidMount() {
    var geturl = this.props.location; 
    var arrString = geturl.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');
    
    if(arrUrl[1] === 'ISetMembers') {
        if(arrUrl[2] !== '') { 
            document.getElementById('loading-area').style.display = 'block'; 
            fetch(global.config.urlLink+"/userview/"+arrUrl[2], {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                if(resJson.status === '200') { 
                    var datatext2 ='{"keyword":"","pageShow":""}';
                    var data2 = JSON.parse(datatext2); 
                    
                    fetch(global.config.urlLink+"/clientlist", {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Authorization': authKey2,
                        }, body : JSON.stringify(data2)
                    }).then(res2 => {
                        if(res2.status === 200) 
                            return res2.json() 
                    }).then(resJson2 => {
                        document.getElementById('loading-area').style.display = 'none'; 
                        if(resJson2.status === '200') {
                            var inputdisable = false;
                    
                            var memberPicture = '';
                            if(resJson.values[0].content[0].maincontent.userPicture !== undefined && resJson.values[0].content[0].maincontent.userPicture !== '') {
                                memberPicture = resJson.values[0].content[0].maincontent.userPicture;
                            } else { memberPicture = defMemberPicture; }
                            
                            var clientlist_value = [];
                            if(resJson2.values.length > 0) {
                                for(var v=0; v < resJson2.values.length; v++) {
                                    var resclientam = false;
                                    var clientam_id = "";
                                    for(var q in resJson.values[0].spcontent) {
                                        if(resJson2.values[v].docid === resJson.values[0].spcontent[q].specialcontent.clientId) {
                                            resclientam = true; 
                                            clientam_id = resJson.values[0].spcontent[q].specialdocid; 
                                            break;
                                        } else { resclientam = false; }
                                    }
                                    clientlist_value.push({
                                        "docid":resJson2.values[v].docid,
                                        "content":resJson2.values[v].content,
                                        "createdate":resJson2.values[v].createdate,
                                        "stat":resclientam,
                                        "clientamid": clientam_id
                                    })
                                    
                                }
                            }
                            
                            if(arrUrl[1] === 'ISetMembers') {
                                this.setState({
                                    stTitle: 'Detail Member',
                                    memberdoc_id: resJson.values[0].docid,
                                    member_email: resJson.values[0].content[0].maincontent.userEmail,
                                    member_position: resJson.values[0].content[0].maincontent.userPosition,
                                    member_level: resJson.values[0].content[0].maincontent.userLevel,
                                    member_fullname: resJson.values[0].content[0].maincontent.userFullname,
                                    member_picture: memberPicture,
                                    client_list: clientlist_value,
                                    clientam_list: resJson.values[0].spcontent,
                                    formErrors: {member_client_am: ''},
                                    memberclientamValid: false,
                                    formValid: false,
                                    input_disabled: inputdisable
                                }) 
                            }
        
        
                        } else {
                            console.log('Something happened wrong');
                        }
                    }).catch(err => {
                        document.getElementById('loading-area').style.display = 'none'; 
                    });

                } else {
                    console.log('Something happened wrong');
                }
              }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
              });
        }
    } 
  }
 
  clientslist() {
    var arrClientList = [];
    if(this.state.client_list.length > 0) {
        for(var ls=0; ls<this.state.client_list.length; ls++) {
            var retResult = this.state.client_list[ls];
            var cls1="", cls2="";
            if(retResult.stat === true) { cls1="trspButton divHide"; cls2="trspButton"; }
            else { cls1="trspButton"; cls2="trspButton divHide"; }
            var idbutton1 = "setam-"+ls;
            var idbutton2 = "removeam-"+ls;
            var dividButton = "buttonset_"+ls;
            arrClientList.push(<tr key={ls+1}>
                <td>{retResult.content.clientName}<br/><span className="font-sublist">{retResult.content.domainName}</span></td>
                <td style={{width:"150px"}}>
                    <div id={dividButton}>
                        <button id={idbutton1} className={cls1} onClick={this.urlsetAMClient.bind(this, this.state.memberdoc_id, retResult.docid, retResult.clientamid, retResult.stat, ls)}>set as AM</button>
                        <button id={idbutton2} className={cls2} onClick={this.urlremoveAMClient.bind(this, this.state.memberdoc_id, retResult.docid, retResult.clientamid, retResult.stat, ls)}>remove as AM</button>
                    </div>
                </td>
              </tr>)
        }
    } else {
        arrClientList.push(<tr key="0">
            <td colSpan="2" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
          </tr>)
    }
    return arrClientList;
  }
  render() {
    var clientList = this.clientslist();
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
        <div className="flex-container">
            <div className="sidebar-main"><ISidemenu/></div>
            <div className="main-content">
            <div className="stTitle">Member</div>
            {(localStorage.getItem('ulevel') === global.config.userlevel[0])? 
            <div>
                <div className="stIndiTitle">Member { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                        
                <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="flex-container">
                            <div className="st2Title w70p">{ this.state.member_fullname}</div>
                            <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        <div className="mgT20">
                            <div id="memberinfo">
                                <div className="row">
                                    <div className="col-3">
                                    { this.state.member_picture !== ''? 
                                        <div className="mgB30"><img src={this.state.member_picture} alt={this.state.member_fullname} className="memberPhoto"/></div>
                                        : ''
                                    } 
                                    </div>
                                    <div className="col-9">
                                        <div className="mgT20">
                                            <div className="textBold">{this.state.member_email}</div>
                                            <div className="mgT05">{this.state.member_position}</div>
                                            <div>{this.state.member_phone}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div id="FormCenter" className="mgT20">
                                <div className="row-field mgT30">
                                    <div className="w100p st3Title divMiddle">Account Representative</div>
                                    <div className="w100p stIndiTitle divMiddle">This Member will become an Account Representative for clients :</div>
                                    <div className="w100p mgT20">
                                        <table className="table">
                                            <tbody>
                                                {clientList}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                    
                            </div>
                        </div>
                        
                    </div>
                    {/* ====== */}
                </div>
            </div>
            :   
            <div><INA/></div>
            }
        </div>
      </div>
      <div id="loading-area" className="divHide loading"></div> 
    </div>
   );
 }
}
 
export default IMemberSetForm;