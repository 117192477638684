import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';

import logoPS from '../../assets/logo-ps-round.png';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import PersonIcon from '@material-ui/icons/Person';
import ListAltIcon from '@material-ui/icons/ListAlt';
import RateReviewIcon from '@material-ui/icons/RateReview';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import CloseIcon from '@material-ui/icons/Close';
import defAMPicture from '../../assets/pic-profile-sq.jpg';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[200]})`,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[200]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    flexDirection: 'row-reverse',
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(3),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1.5),
    paddingLeft: theme.spacing(1.5),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 1,
    '& $content': {
      paddingLeft: theme.spacing(3),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.8, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(3),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 280,
  },
});

 
function onNodeSelect(url) {
  document.location.href=url;
} 

function menuClose() {
  document.getElementById("sidemenu").style.display = "none";
}

function getAMList() {
  var am_json = JSON.parse(localStorage.getItem('cam'));
  if(am_json !== null && am_json.length > 0) {
    var amlist = [];
    var a=0;
    am_json.forEach((resList) => {
      a++;
      var amPicture = defAMPicture, amName = "", amPosition = "";
      if(resList.membercontent !== undefined) {
        if(resList.membercontent.userPicture !== undefined && resList.membercontent.userPicture !== '') {
          amPicture = resList.membercontent.userPicture;
        } 
        if(resList.membercontent.userFullname !== undefined && resList.membercontent.userFullname !== '') {
          amName = resList.membercontent.userFullname;
        } 
        if(resList.membercontent.userPosition !== undefined && resList.membercontent.userPosition !== '') {
          amPosition = resList.membercontent.userPosition;
        } 
        amlist.push(<div className="cs-profile" key={a}>
            <img src={amPicture} className="cs-profile-img" alt="csprofile"/>
            <div className="cs-detail">
                <span className="font-cs-name">{amName} </span><br/>
                <span className="font-cs-company-name">{amPosition}</span>
            </div>
        
       </div>)
      }
    })
    return amlist;
  }
}

export default function CSidemenu(props) {
  const classes = useStyles();
  var geturl = window.location.pathname;
  var arrUrl = [];
  arrUrl = geturl.split('/');
  var currNode = '';
  if(arrUrl[1].toLowerCase() === 'cnews' || arrUrl[1].toLowerCase() === 'cnewsview') { currNode = '2'; }
  else if(arrUrl[1].toLowerCase() === 'caddlicence' || arrUrl[1].toLowerCase() === 'cclaim' || arrUrl[1].toLowerCase() === 'cupgrade' || arrUrl[1].toLowerCase() === 'creqtraining' || arrUrl[1].toLowerCase() === 'crenewlicence') { currNode = '3'; }
  else if(arrUrl[1].toLowerCase() === 'ccontact') { currNode = '4'; }
  
  var ss_am_list = getAMList();
  const { showHideNav } = props;
  return (
    <div id="sidemenu" className="csidebar">
          <div className="company-profile">
            <a href="/"><img src={logoPS} className="company-logo" alt="logo"/></a>
            <div className="company-title">
              PointStar<br/>
              Customer Portal
            </div>
            <div className="csidebar-btn">
              { (showHideNav === true)? 
              <CloseIcon id="btCloseMenu" onClick={menuClose} ></CloseIcon>
              : ''
              }
            </div>
          </div>
          <div className="csidebar-content">
            <div>
              {ss_am_list}
            </div>
            <div className="font-main-menu">MAIN MENU</div>
            <div>
              <TreeView
                className={classes.root}
                defaultExpanded= {[(currNode !== '')? currNode : ''] }
                defaultCollapseIcon={<KeyboardArrowDownIcon />}
                defaultExpandIcon={<KeyboardArrowRightIcon />}
                defaultEndIcon={<div style={{ width: 24 }} />}
              >
                <StyledTreeItem nodeId="1" labelText="Dashboard" labelIcon={DesktopMacIcon} onLabelClick={node => onNodeSelect("/chome")}/>
                <StyledTreeItem nodeId="2" labelText="Insights" labelIcon={ListAltIcon}>
                  <StyledTreeItem
                    nodeId="21"
                    labelText="News, Info & Updates"
                    labelIcon={ArrowRightIcon}
                    labelInfo=""
                    color="#E0E0E0"
                    bgColor="#E0E0E0"
                    classes={{ content : 'own-style-1' }}
                    onLabelClick={node => onNodeSelect("/cnews/1")}
                  />
                  <StyledTreeItem
                    nodeId="22"
                    labelText="Promo"
                    labelIcon={ArrowRightIcon}
                    labelInfo=""
                    color="#E0E0E0"
                    bgColor="#E0E0E0"
                    classes={{ content : 'own-style-1' }}
                    onLabelClick={node => onNodeSelect("/cnews/2")}
                  />
                  <StyledTreeItem
                    nodeId="23"
                    labelText="Events & Announcement"
                    labelIcon={ArrowRightIcon}
                    labelInfo=""
                    color="#E0E0E0"
                    bgColor="#E0E0E0"
                    classes={{ content : 'own-style-1' }}
                    onLabelClick={node => onNodeSelect("/cnews/3")}
                  />
                  
                </StyledTreeItem>
                <StyledTreeItem nodeId="3" labelText="Requests" labelIcon={RateReviewIcon}>
                  <StyledTreeItem
                    nodeId="31"
                    labelText="Add Licence"
                    labelIcon={ArrowRightIcon}
                    labelInfo=""
                    color="#E0E0E0"
                    bgColor="#E0E0E0"
                    classes={{ content : 'own-style-1' }}
                    onLabelClick={node => onNodeSelect("/caddlicence")}
                  />
                  <StyledTreeItem
                    nodeId="32"
                    labelText="Renewal Licence"
                    labelIcon={ArrowRightIcon}
                    labelInfo=""
                    color="#E0E0E0"
                    bgColor="#E0E0E0"
                    classes={{ content : 'own-style-1' }}
                    onLabelClick={node => onNodeSelect("/crenewlicence")}
                  />
                   {/* <StyledTreeItem
                    nodeId="33"
                    labelText="Upgrade Licence"
                    labelIcon={ArrowRightIcon}
                    labelInfo=""
                    color="#E0E0E0"
                    bgColor="#E0E0E0"
                    classes={{ content : 'own-style-1' }}
                    onLabelClick={node => onNodeSelect("/cupgrade")}
                  /> */}
                  <StyledTreeItem
                    nodeId="34"
                    labelText="Training Session"
                    labelIcon={ArrowRightIcon}
                    labelInfo=""
                    color="#E0E0E0"
                    bgColor="#E0E0E0"
                    classes={{ content : 'own-style-1' }}
                    onLabelClick={node => onNodeSelect("/creqtraining")}
                  />
                  {/* <StyledTreeItem
                    nodeId="35"
                    labelText="Claim GCP"
                    labelIcon={ArrowRightIcon}
                    labelInfo=""
                    color="#E0E0E0"
                    bgColor="#E0E0E0"
                    classes={{ content : 'own-style-1' }}
                    onLabelClick={node => onNodeSelect("/cclaim")}
                  /> */}
                </StyledTreeItem>
                <StyledTreeItem nodeId="4" labelText="Support" labelIcon={HeadsetMicIcon}>
                  <StyledTreeItem
                    nodeId="41"
                    labelText="Contact Us"
                    labelIcon={ArrowRightIcon}
                    labelInfo=""
                    color="#E0E0E0"
                    bgColor="#E0E0E0"
                    classes={{ content : 'own-style-1' }}
                    onLabelClick={node => onNodeSelect("/ccontact")}
                  />
                </StyledTreeItem>

                <StyledTreeItem nodeId="5" labelText="My Subscription" labelIcon={PersonIcon} onLabelClick={node => onNodeSelect("/cmyprofile")}/>
              
                
              </TreeView>
            </div>
          </div>
          
    </div>

  );
}
