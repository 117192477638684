import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import parse from 'html-react-parser';

class NotifDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            client_name: '',
            to: '',
            cc: '',
            subject: '',
            message: '',
            contentoth: ''
        }
    }
     
    componentDidMount() {
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        this.setState({
            stPopTitle: '',
            client_name: othersContent.subcontent.clientName,
            to: othersContent.subcontent.to,
            cc: othersContent.subcontent.cc,
            subject: othersContent.subcontent.subject,
            message: othersContent.subcontent.message,
            contentoth: document.getElementById("contentoth").innerText
        }); 
    }
    render() {
      
      return (
        <div className="main-container">
            <div className="mgT20">
              <div className="mgL20">
                <div className="st3Title">{this.state.client_name}</div>
              </div>
              <div className="mgT20 mgL20">
                {/* ---- */}
                <div  className="content-container card card-body">
                    <div className="flex-container mgT20">
                        <div className="divMiddle"><h6>To :</h6></div>
                        <div className="divMiddle mgL20"><h6>
                            {this.state.to}</h6>
                        </div>
                    </div>
                    <div className="flex-container mgT20">
                        <div className="divMiddle"><h6>CC :</h6></div>
                        <div className="divMiddle mgL20"><h6>
                            {this.state.cc}</h6>
                        </div>
                    </div>
                    <div className="mgT20">
                        <div className="divMiddle"><h6>Subject :</h6></div>
                        <div className="divMiddle"><h6>
                            {this.state.subject}</h6>
                        </div>
                    </div>
                    
                    <div className="mgT30">
                    <div><h6>Message :</h6></div>
                    <div>{parse(this.state.message)}</div>
                    </div>
                    
                </div>

                {/* ---- */}
                
              </div>
              
            </div>
            <div id="loading-area" className="divHide loading"></div> 
        </div>
      );
    }
 }
  
export default NotifDetail;

