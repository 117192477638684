import axios from 'axios';
import '../iinc/iGlobal';
class MyUploadAdapter {
    constructor( loader , config) {
        this.loader = loader;
        this.config = config._config;
    }
    upload() {
        return this.loader.file
            .then( file => new Promise( ( resolve, reject ) => {
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                }); 
                return toBase64(file).then(cFile=>{
                    document.getElementById('loading-area').style.display = 'block'; 
                    const data = new FormData();
                    data.append('file', file);
                    data.append('docid', this.config.state.content_docid);
                    data.append('source', this.config.state.source);
                    return axios.post(global.config.urlLink+"/uploadfile", 
                        data, { 
                            headers: { 
                                'content-type': 'multipart/form-data',
                                'Authorization': this.config.key
                            }
                        }).then(res => { 
                            document.getElementById('loading-area').style.display = 'none'; 
                            if(res.data.status === "200") {
                                resolve( {
                                    default: res.data.values.linkfile1
                                } );
                            } else {
                                document.getElementById('loading-area').style.display = 'none';
                                reject(`Couldn't upload file: ${ file.name }.`)
                            }
                        }).catch(err => { 
                            document.getElementById('loading-area').style.display = 'none'; 
                            reject(`Couldn't upload file: ${ file.name }.`)
                        })
                })                
            } ) );
    }
}

export default function MyCustomUploadAdapterPlugin( editor ) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new MyUploadAdapter( loader , editor.config );
    };
}