import React, { Component } from 'react';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';
import ReplaceAllContent from '../../inc/replace-all';
import MyCustomUploadAdapterPlugin from '../iinc/ckImageUploader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[12];

class ILibraryLessonForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            library_id: '',
            library_title: '',
            liblesson_id: '',
            liblesson_number: '',
            liblesson_subject: '',
            liblesson_description: '',
            source: 'library',
            formErrors: { liblesson_number: '', liblesson_subject: '', liblesson_description: '' },
            liblessonnumberValid: false,
            liblessonsubjectValid: false,
            liblessondescriptionValid: false,
            formValid: false,
            input_disabled: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleUserCKEditorInput = (e) => {
        const name = "liblesson_description";
        const value = e.getData();
        console.log(value)
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let liblessonnumberValid = this.state.liblessonnumberValid;
        let liblessonsubjectValid = this.state.liblessonsubjectValid;
        let liblessondescriptionValid = this.state.liblessondescriptionValid;
        
        switch(fieldName) {
            case 'liblesson_number': 
                liblessonnumberValid = value.trim().length > 0;
                fieldValidationErrors.liblesson_number = liblessonnumberValid ? '' : 'lesson number need to be filled';
                break;
            case 'liblesson_subject': 
                liblessonsubjectValid = value.trim().length > 0;
                fieldValidationErrors.liblesson_subject = liblessonsubjectValid ? '' : 'subject lesson need to be filled';
                break;
            case 'liblesson_description': 
                liblessondescriptionValid = value.trim().length > 0;
                fieldValidationErrors.liblesson_description = liblessondescriptionValid ? '' : 'description need to be filled';
                break;
            default:
                break;
        }
        
        this.setState({formErrors: fieldValidationErrors,
                        liblessonnumberValid: liblessonnumberValid,
                        liblessonsubjectValid: liblessonsubjectValid,
                        liblessondescriptionValid: liblessondescriptionValid,
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.liblessonnumberValid && this.state.liblessonsubjectValid && this.state.liblessondescriptionValid});
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit(e) {
        e.preventDefault();
        if(this.state.liblesson_number !== '' && this.state.liblesson_subject !== '' && this.state.liblesson_description !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var liblessonNumber, liblessonSubject, liblessonDescription;
            if(this.textInputLibLessonNumber.value === '') { liblessonNumber = this.state.liblesson_number; } else { liblessonNumber = this.textInputLibLessonNumber.value; }
            if(this.textInputLibLessonSubject.value === '') { liblessonSubject = this.state.liblesson_subject; } else { liblessonSubject = this.textInputLibLessonSubject.value; }
            liblessonDescription = ReplaceAllContent('"',"'",this.state.liblesson_description);
        
            var datatext ='{"library_id":"'+this.state.library_id+'","liblesson_id":"'+this.state.liblesson_id+'","liblesson_number":"'+liblessonNumber+'","liblesson_subject":"'+liblessonSubject+'","liblesson_description":"'+liblessonDescription+'","form_id":"form-editlesson"}';
            var urlFetch = global.config.urlLink;
            if(this.state.liblesson_id !== '') { urlFetch = urlFetch+"/librarylessonedit"; }
            else { urlFetch = urlFetch+"/librarylessonadd"; }
            var data = JSON.parse(datatext); 
            
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    window.location.href='/IEditDetailLibrary/'+this.state.library_id;
                } else {
                    console.log('Something happened wrong');
                }
            }).catch(err => {
                document.getElementById('loading-area').style.display = 'none'; 
            });
            
        } else {
            var arrfieldName = ['liblesson_number', 'liblesson_subject','liblesson_description'];
            var arrvalue = [this.textInputLibLessonNumber.value, this.textInputLibLessonSubject.value, this.state.liblesson_description];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    componentDidMount() {
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        if(othersContent.liblessonid !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            fetch(global.config.urlLink+"/librarylessonview/"+othersContent.libraryId+"/"+othersContent.liblessonid, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') { 
                    this.setState({
                        library_id: resJson.values[0].docid,
                        library_title: resJson.values[0].content[0].maincontent.libraryTitle,
                        liblesson_id: resJson.values[0].scontent[0].subdocid,
                        liblesson_number: resJson.values[0].scontent[0].subcontent.lessonNumber,
                        liblesson_subject: resJson.values[0].scontent[0].subcontent.lessonTitle,
                        liblesson_description: resJson.values[0].scontent[0].subcontent.lessonDesc,
                        source: 'library',
                        formErrors: { liblesson_number: '', liblesson_subject: '', liblesson_description: '' },
                        liblessonnumberValid: false,
                        liblessonsubjectValid: false,
                        liblessondescriptionValid: false,
                        formValid: false,
                        input_disabled: false
                    }); 
                } else {
                    console.log('Something happened wrong');
                }
              }).catch(err => {
                document.getElementById('loading-area').style.display = 'none'; 
              });
        } else {
            this.setState({
                library_id: othersContent.libraryId,
                library_title: othersContent.libraryTitle,
                liblesson_id: othersContent.liblessonid,
                liblesson_number: '',
                liblesson_subject: '',
                liblesson_description: '',
                source: 'library',
                formErrors: { liblesson_number: '', liblesson_subject: '', liblesson_description: '' },
                liblessonnumberValid: false,
                liblessonsubjectValid: false,
                liblessondescriptionValid: false,
                formValid: false,
                input_disabled: false
            }); 
        }
    }

    render() {
        return (
            <div className="main-container">
                <div className="mgT30">
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <input type="hidden" id="library_id" name="library_id" value={this.state.library_id} />
                        <input type="hidden" id="liblesson_id" name="liblesson_id" value={this.state.liblesson_id} />
                    </div>
                    <div className="row-field mgT20">
                        <h6>{this.state.library_title}</h6>
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="liblesson_number">Module Number</label>
                        <div className="w70p">
                            <input type="number" className="form-control" name="liblesson_number"
                                placeholder={this.state.liblesson_number}
                                value={this.state.liblesson_number}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputLibLessonNumber = input}
                                disabled={this.state.input_disabled}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.liblesson_number}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="liblesson_subject">Subject </label>
                        <div className="w70p">
                            <textarea name="liblesson_subject" className="form-control" 
                                rows={2} cols={30} 
                                value={this.state.liblesson_subject} 
                                onChange={this.handleUserInput} 
                                ref={(input) => this.textInputLibLessonSubject = input}
                                disabled={this.state.input_disabled}/>
                    
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.liblesson_subject}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="mgT20">
                        <label className="textMiddle" htmlFor="liblesson_description">Description</label>
                        <div>
                            <CKEditor
                                    editor  = { ClassicEditor }
                                    name    = "liblesson_description"
                                    data    = {this.state.liblesson_description}
                                    config  = {{extraPlugins:[MyCustomUploadAdapterPlugin], state: this.state, key:authKey}}
                                    onReady = { editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log( 'Editor is ready to use!', editor );
                                        editor.ui.view.editable.element.style.minHeight = "500px";
                                    } }
                                    onChange={ ( event, editor ) => {
                                        this.handleUserCKEditorInput(editor);
                                        const data = editor.getData();
                                        
                                        //this.textInputContentLongDesc = data;
                                        console.log( { event, editor, data } );
                                        editor.ui.view.editable.element.style.minHeight = "500px";
                                    } }
                                    onBlur={ ( event, editor ) => {
                                        console.log( 'Blur.', editor );
                                        editor.ui.view.editable.element.style.minHeight = "500px";
                                    } }
                                    onFocus={ ( event, editor ) => {
                                        console.log( 'Focus.', editor );
                                        editor.ui.view.editable.element.style.minHeight = "500px";
                                    } }
                                />
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.liblesson_description}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT30 mgB30">
                        <div className="w100p"><button id="submit-button"  type="submit" className="w100p submitButton">Submit</button></div>
                    </div>
                </form>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
          );
        }
     }
      
export default ILibraryLessonForm;
