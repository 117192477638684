import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import DateTimeFormats from '../../inc/date-time-format';
import ReplaceAllContent from '../../inc/replace-all';
import { FormErrors } from '../../inc/error-form';
import Alert from '@material-ui/lab/Alert';
import PostAddIcon from '@material-ui/icons/PostAdd';
import EditIcon from '@material-ui/icons/Edit';
import DatePicker from 'react-date-picker';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[4];

class RenewDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            clientdoc_id: '',
            licencedoc_id: '',
            renewdoc_id: '',

            client_name: '',
            domain_name: '',
            client_country: '',
            client_licence_type: '',
            client_licence_currseat: '',

            client_licence_reqseat: '',
            client_licence_reqprice: '',
            client_licence_reqdate: '',
            client_licence_reqby: '',

            client_licence_renew_status_curr: '',
            client_licence_renew_status: '',
            client_licence_active_seat: '',
            client_licence_active_date: '',
            client_licence_active_by: '',
            client_licence_expired_date: '',
            client_licence_update_date: '',
            
            client_licence_payment_status: '', 
            client_licence_payment_top: '',
            client_licence_payment_num: '',
            client_licence_renew_remarks: '',
            client_licence_renewal_payment_list: [],
            client_licence_additional_list: [],

            licence_renewal_activate_status: '',
            //licence_renewal_activate_date: new Date(),
            licence_renewal_activate_date: '',
            licence_renewal_activate_seat: '',
            licence_renewal_price: '',
            licence_renewal_price_currency: 'USD',
            licence_renewal_top: '',
            licence_renewal_num: '',
            licence_renewal_payment_date: '',
            licence_renewal_payment_price: '',
            licence_renewal_payment_price_currency: 'USD',
            licence_renewal_payment_comment: '',

            licence_periode_unit: '',
            licence_periode_num: '',
            licence_periode_unit_billcycle: '',
            licence_periode_num_billcycle: '',

            formErrors: {licence_renewal_activate_status: '',licence_renewal_activate_date: '',licence_renewal_activate_seat: '',licence_renewal_price: '',licence_renewal_top: '',licence_renewal_num: '', licence_renewal_payment_date: '', licence_renewal_payment_price: '', licence_renewal_payment_comment: '', licence_periode_num: '', licence_periode_num_billcycle: ''},
            licRenewActStatusValid: false,
            licRenewActDateValid: false,
            licRenewActSeatValid: false,
            licRenewPriceValid: false,
            licRenewPaymentTopValid: false,
            licRenewPaymentNumValid: false,
            licRenewPaymentVerfDateValid: false,
            licRenewPaymentVerfPriceValid: false,
            licenceperiodenumValid: false,
            licenceperiodenumbillcycleValid: false,
            formValid: false,
            errmsg: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadFormRenewData = this.loadFormRenewData.bind(this);
        this.resetErrorMsg = this.resetErrorMsg.bind(this);
    }
    resetErrorMsg() {
        console.log("err");
        
        this.setState({
            formErrors: {licence_renewal_activate_status: '',licence_renewal_activate_date: '',licence_renewal_activate_seat: '',licence_renewal_price: '',licence_renewal_top: '',licence_renewal_num: '', licence_renewal_payment_date: '', licence_renewal_payment_price: '', licence_renewal_payment_comment: '', licence_periode_num: '', licence_periode_num_billcycle: ''},
            licRenewActStatusValid: false,
            licRenewActDateValid: false,
            licRenewActSeatValid: false,
            licRenewPriceValid: false,
            licRenewPaymentTopValid: false,
            licRenewPaymentNumValid: false,
            licRenewPaymentVerfDateValid: false,
            licRenewPaymentVerfPriceValid: false,
            licenceperiodenumValid: false,
            licenceperiodenumbillcycleValid: false,
            formValid: false,
            errmsg: '' 
        }, this.validateForm);
        
        /*var arrfieldName = ['licence_renewal_activate_status','licence_renewal_activate_date','licence_renewal_price','licence_renewal_activate_seat','licence_renewal_num','licence_renewal_top'];
        var arrvalue = [this.state.licence_renewal_activate_status,this.state.licence_renewal_activate_date,this.textInputLicenceRenewalPrice.value,this.textInputLicenceActiveSeat.value,this.textInputLicencePaymentNum.value,this.textInputLicencePaymentTop.value];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }*/
    }
    loadFormRenewData() {
        //document.getElementById('loading-area').style.display = 'block'; 
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        var clientId = othersContent.client_id;
        var licenceId = othersContent.licence_id;
        var renewId = othersContent.renew_id;
        fetch(global.config.urlLink+"/renewlicenceview/"+clientId+"/"+licenceId+"/"+renewId, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }
          }).then(res => {
            if(res.status === 200) 
                return res.json() 
          }).then( resJson => {
            console.log(resJson);
            //console.log(resJson.status);
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') {
                console.log(resJson);
                console.log(resJson.values[0].ss1content[0].subsubcontent);
                var reqLicenceSeat='', reqLicencePrice='', reqLicencePriceVal = '', reqLicenceDate='', reqLicenceBy='', renewStatus='';
                var renewActiveSeat='', renewActiveDate='', renewActiveBy='', renewExpiredDate='', renewActiveDateVal='', renewUpdateDate=''; 
                var renewPaymentStatus='Not Paid', renewPaymentTop='', renewPaymentNum='', renewPaymentNumVal = '', renewRemarks='';
                //var renewPaymentDate = '', renewPaymentPrice = '', renewPaymentComment = '';
                var licRenewActiveDate = '', licRenewActiveStatus = '', licRenewActiveSeat='', licRenewPrice='', licRenewPriceCurrency='', licRenewPaymentTop='', licRenewPaymentNum='', licRenewPaymentDate='', licRenewPaymentPrice='', licRenewPaymentPriceCurrency='', licRenewPaymentComment='';
                if(resJson.values[0].ss1content[0].subsubcontent.renewLicenceSeat !== undefined) { reqLicenceSeat = resJson.values[0].ss1content[0].subsubcontent.renewLicenceSeat; }
                if(resJson.values[0].ss1content[0].subsubcontent.renewLicenceTotalPrice !== undefined) { 
                    // reqLicencePriceVal = resJson.values[0].ss1content[0].subsubcontent.renewLicenceTotalPrice; 
                    reqLicencePriceVal = resJson.values[0].ss1content[0].subsubcontent.renewLicencePriceSeat; 
                    if(resJson.values[0].ss1content[0].subsubcontent.renewLicencePriceCurrency !== undefined) { 
                        licRenewPriceCurrency = resJson.values[0].ss1content[0].subsubcontent.renewLicencePriceCurrency;
                        reqLicencePrice = resJson.values[0].ss1content[0].subsubcontent.renewLicencePriceCurrency+' '+reqLicencePriceVal; 
                    } else {
                        licRenewPriceCurrency = "USD";
                        reqLicencePrice = 'USD '+reqLicencePriceVal; 
                    }
                }
                if(resJson.values[0].ss1content[0].subsubcontent.renewRequestDate !== undefined) { reqLicenceDate = DateTimeFormats(resJson.values[0].ss1content[0].subsubcontent.renewRequestDate._seconds,"date-long"); }
                if(resJson.values[0].ss1content[0].subsubcontent.renewRequestByName !== undefined) { reqLicenceBy = resJson.values[0].ss1content[0].subsubcontent.renewRequestByName; }
                if(resJson.values[0].ss1content[0].subsubcontent.renewStatus !== undefined) { renewStatus = resJson.values[0].ss1content[0].subsubcontent.renewStatus; }
                if(resJson.values[0].ss1content[0].subsubcontent.renewActiveSeats !== undefined) { renewActiveSeat = resJson.values[0].ss1content[0].subsubcontent.renewActiveSeats; }
                if(resJson.values[0].ss1content[0].subsubcontent.renewActiveDate !== undefined) { 
                    renewActiveDateVal = resJson.values[0].ss1content[0].subsubcontent.renewActiveDate._seconds;
                    renewActiveDate = DateTimeFormats(renewActiveDateVal,"date-long"); 
                }
                if(resJson.values[0].ss1content[0].subsubcontent.renewActiveByName !== undefined) { renewActiveBy = resJson.values[0].ss1content[0].subsubcontent.renewActiveByName; }
                if(resJson.values[0].ss1content[0].subsubcontent.renewExpiredOn !== undefined) { renewExpiredDate = DateTimeFormats(resJson.values[0].ss1content[0].subsubcontent.renewExpiredOn._seconds,"date-long"); }
                if(resJson.values[0].ss1content[0].subsubcontent.renewActiveUpdateDate !== undefined) { renewUpdateDate = DateTimeFormats(resJson.values[0].ss1content[0].subsubcontent.renewActiveUpdateDate._seconds,"date-long"); }
                if(resJson.values[0].ss1content[0].subsubcontent.renewPaymentStatus !== undefined) { renewPaymentStatus = resJson.values[0].ss1content[0].subsubcontent.renewPaymentStatus; }
                if(resJson.values[0].ss1content[0].subsubcontent.renewPaymentTOP !== undefined) { renewPaymentTop = resJson.values[0].ss1content[0].subsubcontent.renewPaymentTOP; }
                if(resJson.values[0].ss1content[0].subsubcontent.renewPaymentNum !== undefined) { renewPaymentNumVal = resJson.values[0].ss1content[0].subsubcontent.renewPaymentNum; renewPaymentNum = 'within '+renewPaymentNumVal+' times payment(s)'; }
                if(resJson.values[0].ss1content[0].subsubcontent.renewRemarks !== undefined) { renewRemarks = resJson.values[0].ss1content[0].subsubcontent.renewRemarks; }
                //if(resJson.values[0].ss11content[0].subsubsubcontent.verifiedDate !== undefined) { renewPaymentDate = DateTimeFormats(resJson.values[0].ss11content[0].subsubsubcontent.verifiedDate._seconds,"date-long"); }
                //if(resJson.values[0].ss11content[0].subsubsubcontent.verifiedPrice !== undefined) { renewPaymentPrice = resJson.values[0].ss11content[0].subsubsubcontent.verifiedPrice; }
                //if(resJson.values[0].ss11content[0].subsubsubcontent.verfiedRemarks !== undefined) { renewPaymentComment = resJson.values[0].ss11content[0].subsubsubcontent.verfiedRemarks; }
                console.log("actv date : "+new Date(renewActiveDateVal*1000));
                console.log("periode unit :"+othersContent.licence_periode_unit);
                if(renewStatus !== '' && renewStatus !== global.config.licenceRenewStatus[0]) { licRenewActiveStatus = renewStatus; }
                if(renewActiveDate !== '') { licRenewActiveDate = new Date(renewActiveDateVal*1000); }
                if(renewActiveSeat !== '') { licRenewActiveSeat = renewActiveSeat; }
                if(reqLicencePrice !== '') { licRenewPrice = reqLicencePriceVal; }
                if(renewPaymentTop !== '') { licRenewPaymentTop = renewPaymentTop; }
                if(renewPaymentNum !== '') { licRenewPaymentNum = renewPaymentNumVal; }
                if(renewRemarks !== '') { renewRemarks = ReplaceAllContent('<br/>','\n',renewRemarks); }
                //if(renewPaymentDate !== '') { licRenewPaymentDate = renewPaymentDate; }
                //if(renewPaymentPrice !== '') { licRenewPaymentPrice = renewPaymentPrice; }
                //if(renewPaymentComment !== '') { licRenewPaymentComment = renewPaymentComment; }
                //console.log("hist payment:");
                //console.log(resJson.values[0].ss11content);
                this.setState({
                    stPopTitle: '',
                    clientdoc_id: clientId,
                    licencedoc_id: licenceId,
                    renewdoc_id: renewId,

                    client_name: othersContent.client_name,
                    domain_name: othersContent.domain_name,
                    client_country: othersContent.client_country,
                    client_licence_type: othersContent.licence_type,
                    client_licence_currseat: othersContent.licence_seat,

                    client_licence_reqseat: reqLicenceSeat,
                    client_licence_reqprice: reqLicencePrice,
                    client_licence_reqdate: reqLicenceDate,
                    client_licence_reqby: reqLicenceBy,

                    client_licence_renew_status_curr: renewStatus,
                    client_licence_renew_status: renewStatus,
                    client_licence_active_seat: renewActiveSeat,
                    client_licence_active_date: renewActiveDate,
                    client_licence_active_by: renewActiveBy,
                    client_licence_expired_date: renewExpiredDate,
                    client_licence_update_date: renewUpdateDate,

                    client_licence_payment_status: renewPaymentStatus, 
                    client_licence_payment_top: renewPaymentTop,
                    client_licence_payment_num: renewPaymentNum,
                    client_licence_renew_remarks: renewRemarks,
                    client_licence_renewal_payment_list: resJson.values[0].ss11content,
                    client_licence_additional_list: [],

                    licence_renewal_activate_status: licRenewActiveStatus,
                    licence_renewal_activate_date: licRenewActiveDate,
                    licence_renewal_activate_seat: licRenewActiveSeat,
                    licence_renewal_price: licRenewPrice,
                    licence_renewal_price_currency: licRenewPriceCurrency,
                    licence_renewal_top: licRenewPaymentTop,
                    licence_renewal_num: licRenewPaymentNum,
                    licence_renewal_payment_date: licRenewPaymentDate,
                    licence_renewal_payment_price: licRenewPaymentPrice,
                    licence_renewal_payment_price_currency: licRenewPaymentPriceCurrency,
                    licence_renewal_payment_comment: licRenewPaymentComment,

                    licence_periode_unit: othersContent.licence_periode_unit.toLowerCase(),
                    licence_periode_num: othersContent.licence_periode_num,
                    licence_periode_unit_billcycle: othersContent.licence_periode_unit_billcycle.toLowerCase(),
                    licence_periode_num_billcycle: othersContent.licence_periode_num_billcycle,
                    
                    formErrors: {licence_renewal_activate_status: '',licence_renewal_activate_date: '',licence_renewal_activate_seat: '',licence_renewal_price: '',licence_renewal_top: '',licence_renewal_num: '', licence_renewal_payment_date: '', licence_renewal_payment_price: '', licence_renewal_payment_comment: '', licence_periode_num: '', licence_periode_num_billcycle: ''},
                    licRenewActStatusValid: false,
                    licRenewActDateValid: false,
                    licRenewActSeatValid: false,
                    licRenewPriceValid: false,
                    licRenewPaymentTopValid: false,
                    licRenewPaymentNumValid: false,
                    licRenewPaymentVerfDateValid: false,
                    licRenewPaymentVerfPriceValid: false,
                    licenceperiodenumValid: false,
                    licenceperiodenumbillcycleValid: false,
                    formValid: false,
                    errmsg: '' 
                });
            } else {
                console.log('Something happened wrong');
                console.log(resJson);
            }
          }).catch(err => { 
            document.getElementById('loading-area').style.display = 'none'; 
            console.log(err);
          }); 
        
    }
    urlcancelForm(valId,divId,exdivId) {
        document.getElementById(divId).style.display = "none";
        document.getElementById(exdivId).style.display = "none";        
        document.getElementById("renew-detail-info").style.display = "block";
        //return false;
    }
    urleditInfo(valId) {
        console.log("aa"+valId);
        if(this.state.client_licence_renew_status === global.config.licenceRenewStatus[1]) {
            document.getElementById("form-active").style.display = "block";
            document.getElementById("form-term-payment").style.display = "block";
        } else {
            document.getElementById("form-active").style.display = "none";
            document.getElementById("form-term-payment").style.display = "none";
        }
        
        document.getElementById("renew-edit-form").style.display = "block";
        document.getElementById("renew-payment-form").style.display = "none";        
        document.getElementById("renew-detail-info").style.display = "none";
        this.loadFormRenewData();
        /*this.setState( prevState => {
          return {  
              ...prevState.isOpen2, isOpen2: true
          }
        })*/
    }
    urladdPayment(valId) {
        console.log("bb"+valId);
        document.getElementById("renew-payment-form").style.display = "block";
        document.getElementById("renew-edit-form").style.display = "none";        
        document.getElementById("renew-detail-info").style.display = "none";
        this.loadFormRenewData();
    }
    handlePaymentDate = date => {
        //this.resetErrorMsg();
        console.log("dt:"+date);
        this.setState( prevState => {
            return {  
                ...prevState.licence_renewal_payment_date, licence_renewal_payment_date: date
            }
          }, () => { this.validateField('licence_renewal_payment_date', date)
        }) 
    }
    handleActivateDate = date => {
        //this.resetErrorMsg();
        console.log("dt:"+date);
        this.setState( prevState => {
            return {  
                ...prevState.licence_renewal_activate_date, licence_renewal_activate_date: date
            }
          }, () => { this.validateField('licence_renewal_activate_date', date)
        }) 
    }
    handleActivateStatus = (e) => {
        this.resetErrorMsg();
        const value = e.target.value;
        const inputname = e.target.name;
        console.log(value);
        console.log(inputname);
        /*
        if(inputname === "licence_renewal_activate_status") {
            if(value === "Active") {
                this.setState({
                    formErrors: {licence_renewal_activate_status: '',licence_renewal_activate_date: '',licence_renewal_activate_seat: '',licence_renewal_price: '',licence_renewal_top: '',licence_renewal_num: ''},
                    licRenewActStatusValid: true,
                    licRenewActDateValid: false,
                    licRenewActSeatValid: false,
                    licRenewPriceValid: false,
                    licRenewPaymentTopValid: false,
                    licRenewPaymentNumValid: false,
                    }, () => { this.validateField(inputname, value)}
                );
                    
                this.setState({
                    formErrors: {licence_renewal_activate_status: '',licence_renewal_activate_date: '',licence_renewal_activate_seat: '',licence_renewal_price: '',licence_renewal_top: '',licence_renewal_num: ''},
                    licRenewActStatusValid: false,
                    licRenewActDateValid: false,
                    licRenewActSeatValid: false,
                    licRenewPriceValid: false,
                    licRenewPaymentTopValid: false,
                    licRenewPaymentNumValid: false,
                    formValid: false,
                    errmsg: '' 
                }, this.validateForm); 
            }

        } */
        if(value === global.config.licenceRenewStatus[1]) {
            document.getElementById("form-active").style.display = "block";
            document.getElementById("form-term-payment").style.display = "block";
        } else {
            document.getElementById("form-active").style.display = "none";
            document.getElementById("form-term-payment").style.display = "none";
        }
        this.setState({[inputname]: value},
            () => {
                var arrfieldName = ['licence_renewal_activate_status','licence_renewal_activate_date','licence_renewal_activate_seat'];
                var arrvalue = [value,this.state.licence_renewal_activate_date,this.state.licence_renewal_activate_seat];
                for(var ck in arrfieldName) {
                    console.log(arrfieldName[ck]+":"+arrvalue[ck]);
                    this.validateField(arrfieldName[ck], arrvalue[ck]);
                }
            });
        /*this.setState({[inputname]: value},
            () => { this.validateField(inputname, value)});
        */
        /*this.setState( prevState => {
            return {  
                ...prevState.licence_renewal_activate_status, licence_renewal_activate_status: value,
                ...prevState.licRenewActStatusValid, licRenewActStatusValid: false,
                ...prevState.licRenewActDateValid, licRenewActDateValid: false,
                ...prevState.licRenewActSeatValid, licRenewActSeatValid: false,
                ...prevState.licRenewPriceValid, licRenewPriceValid: false,
                ...prevState.licRenewPaymentTopValid, licRenewPaymentTopValid: false,
                ...prevState.licRenewPaymentNumValid, licRenewPaymentNumValid: false,
                ...prevState.formValid, formValid: false,
                    
            }
          }, () => { this.validateField(inputname, value) 
        }) */
    }
    handleUserSelect = (e) => {
        //alert(this.textInputMailTemplate.value)
        //alert(e.target.name)
        //this.resetErrorMsg();
        const value = e.target.value;
        const inputname = e.target.name;
        console.log(value);
        console.log(inputname);
        if(inputname === "licence_renewal_num") {
            this.setState( prevState => {
                return {  
                    ...prevState.licence_renewal_num, licence_renewal_num: value
                }
              }, () => { 
                  //this.validateField(inputname, value)
                  var arrfieldName = ['licence_renewal_num','licence_renewal_top'];
                  var arrvalue = [value,this.state.licence_renewal_top];
                  for(var ck in arrfieldName) {
                    this.validateField(arrfieldName[ck], arrvalue[ck]);
                  }
            }) 
        } else if(inputname === "licence_renewal_top") {
            this.setState( prevState => {
                return {  
                    ...prevState.licence_renewal_top, licence_renewal_top: value
                }
              }, () => { 
                  //this.validateField(inputname, value)
                  var arrfieldName = ['licence_renewal_num','licence_renewal_top'];
                  var arrvalue = [this.state.licence_renewal_num,value];
                  for(var ck in arrfieldName) {
                    this.validateField(arrfieldName[ck], arrvalue[ck]);
                  }
            }) 
        } else if(inputname === "licence_renewal_price_currency") {
            this.setState( prevState => {
                return {  
                    ...prevState.licence_renewal_price_currency, licence_renewal_price_currency: value
                }
              }) 
        } else if(inputname === "licence_renewal_payment_price_currency") {
            this.setState( prevState => {
                return {  
                    ...prevState.licence_renewal_payment_price_currency, licence_renewal_payment_price_currency: value
                }
                }) 
        } else if(inputname === "licence_periode_unit") {
            this.setState( prevState => {
                return {  
                    ...prevState.licence_periode_unit, licence_periode_unit: value
                }
            }) 
        } else if(inputname === "licence_periode_unit_billcycle") {
            this.setState( prevState => {
                return {  
                    ...prevState.licence_periode_unit_billcycle, licence_periode_unit_billcycle: value
                }
            })  
        }
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(value);
        //this.resetErrorMsg();
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    } 
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let licRenewActStatusValid = this.state.licRenewActStatusValid;
        let licRenewActDateValid = this.state.licRenewActDateValid;
        let licRenewActSeatValid = this.state.licRenewActSeatValid;
        let licRenewPriceValid = this.state.licRenewPriceValid;
        let licRenewPaymentTopValid = this.state.licRenewPaymentTopValid;
        let licRenewPaymentNumValid = this.state.licRenewPaymentNumValid;
        let licRenewPaymentVerfDateValid = this.state.licRenewPaymentVerfDateValid;
        let licRenewPaymentVerfPriceValid = this.state.licRenewPaymentVerfPriceValid;
        let licenceperiodenumValid = this.state.licenceperiodenumValid;
        let licenceperiodeunitValid = this.state.licenceperiodeunitValid;
        let licenceperiodenumbillcycleValid = this.state.licenceperiodenumbillcycleValid;
        let licenceperiodeunitbillcycleValid = this.state.licenceperiodeunitbillcycleValid;
        switch(fieldName) {
            case 'licence_renewal_activate_status': 
                /*if(this.state.licence_renewal_activate_status === '' && this.state.licence_renewal_activate_date === '' && this.state.licence_renewal_price === '' && this.state.licence_renewal_activate_seat === '' && this.state.licence_renewal_num === '' && this.state.licence_renewal_top === '') {
                    licRenewActStatusValid = value.trim().length > 0;
                    fieldValidationErrors.licence_renewal_activate_status = licRenewActStatusValid ? '' : 'activation status need to be filled';
                } else {
                    licRenewActStatusValid = true;
                    fieldValidationErrors.licence_renewal_activate_status = '';
                }*/
                licRenewActStatusValid = value.trim().length > 0;
                fieldValidationErrors.licence_renewal_activate_status = licRenewActStatusValid ? '' : 'activation status need to be filled';
                break;
            case 'licence_renewal_activate_date': 
                if((this.state.licence_renewal_activate_status === global.config.licenceRenewStatus[1]) || 
                    (this.state.licence_renewal_activate_status === '' && this.state.licence_renewal_price === '' && this.state.licence_renewal_activate_seat === '' && this.state.licence_renewal_num === '' && this.state.licence_renewal_top === '')
                  ) {
                    licRenewActDateValid = (value !== null && value !== '');
                    fieldValidationErrors.licence_renewal_activate_date = licRenewActDateValid ? '' : 'activation date need to be filled';
                } else {
                    licRenewActDateValid = true;
                    fieldValidationErrors.licence_renewal_activate_date = '';
                }
                break;
            case 'licence_renewal_activate_seat': 
                if((this.state.licence_renewal_activate_status === global.config.licenceRenewStatus[1]) || 
                    (this.state.licence_renewal_activate_status === '' && this.state.licence_renewal_activate_date === '' && this.state.licence_renewal_price === '' && this.state.licence_renewal_num === '' && this.state.licence_renewal_top === '')
                  ) {
                    licRenewActSeatValid = value.trim().length > 0;
                    fieldValidationErrors.licence_renewal_activate_seat = licRenewActSeatValid ? '' : 'activation seats need to be filled';
                } else {
                    licRenewActSeatValid = true;
                    fieldValidationErrors.licence_renewal_activate_seat = '';
                }
                break;
            case 'licence_renewal_price': 
                if(this.state.licence_renewal_activate_status === '' && this.state.licence_renewal_activate_date === '' && this.state.licence_renewal_activate_seat === '' && this.state.licence_renewal_num === '' && this.state.licence_renewal_top === '') {
                    licRenewPriceValid = value.trim().length > 0;
                    fieldValidationErrors.licence_renewal_price = licRenewPriceValid ? '' : 'total charge need to be filled';
                } else {
                    licRenewPriceValid = true;
                    fieldValidationErrors.licence_renewal_price = '';
                }
                break;
            case 'licence_renewal_top': 
                if(this.state.licence_renewal_num !== '' || 
                    (this.state.licence_renewal_activate_status === '' && this.state.licence_renewal_activate_date === '' && this.state.licence_renewal_price === '' && this.state.licence_renewal_activate_seat === '' && this.state.licence_renewal_num === '')
                  ) {
                    licRenewPaymentTopValid = value.trim().length > 0;
                    fieldValidationErrors.licence_renewal_top = licRenewPaymentTopValid ? '' : 'term of payments need to be filled';
                } else {
                    licRenewPaymentTopValid = true;
                    fieldValidationErrors.licence_renewal_top = '';
                }
                break;
            case 'licence_renewal_num': 
                if(this.state.licence_renewal_top !== '' || 
                    (this.state.licence_renewal_activate_status === '' && this.state.licence_renewal_activate_date === '' && this.state.licence_renewal_price === '' && this.state.licence_renewal_activate_seat === '' && this.state.licence_renewal_top === '')
                  ) {
                    licRenewPaymentNumValid = value.trim().length > 0;
                    fieldValidationErrors.licence_renewal_num = licRenewPaymentNumValid ? '' : 'number of payments need to be filled';
                } else {
                    licRenewPaymentNumValid = true;
                    fieldValidationErrors.licence_renewal_num = '';
                }
                break;
            case 'licence_renewal_payment_date':
                if(this.state.licence_renewal_payment_date === '' && this.state.licence_renewal_payment_price === '') {
                    licRenewPaymentVerfDateValid = value.trim().length > 0;
                    fieldValidationErrors.licence_renewal_payment_date = licRenewPaymentVerfDateValid ? '' : 'payment date received need to be filled';
                } else {
                    licRenewPaymentVerfDateValid = true;
                    fieldValidationErrors.licence_renewal_payment_date = '';
                }
                break;
            case 'licence_renewal_payment_price':
                if(this.state.licence_renewal_payment_date === '' && this.state.licence_renewal_payment_price === '') {
                    licRenewPaymentVerfPriceValid = value.trim().length > 0;
                    fieldValidationErrors.licence_renewal_payment_price = licRenewPaymentVerfPriceValid ? '' : 'payment received need to be filled';
                } else {
                    licRenewPaymentVerfPriceValid = true;
                    fieldValidationErrors.licence_renewal_payment_price = '';
                }
                break;
            case 'licence_periode_num': 
            case 'licence_periode_unit': 
                    licenceperiodenumValid = value.trim().length > 0;
                    licenceperiodeunitValid = value.trim().length > 0;
                    fieldValidationErrors.licence_periode_num = (licenceperiodenumValid || licenceperiodeunitValid) ? '' : 'periode renewal need to be filled';
                    break;
            case 'licence_periode_num_billcycle': 
            case 'licence_periode_unit_billcycle': 
                    licenceperiodenumbillcycleValid = value.trim().length > 0;
                    licenceperiodeunitbillcycleValid = value.trim().length > 0;
                    fieldValidationErrors.licence_periode_num_billcycle = (licenceperiodenumbillcycleValid || licenceperiodeunitbillcycleValid) ? '' : 'periode renewal need to be filled';
                    break;
            default:
                break;
        }
        //alert(fieldName + ':' + JSON.stringify(fieldValidationErrors));
        
        this.setState({formErrors: fieldValidationErrors,
                        licRenewActStatusValid: licRenewActStatusValid,
                        licRenewActDateValid: licRenewActDateValid,
                        licRenewActSeatValid: licRenewActSeatValid,
                        licRenewPriceValid: licRenewPriceValid,
                        licRenewPaymentTopValid: licRenewPaymentTopValid,
                        licRenewPaymentNumValid: licRenewPaymentNumValid,
                        licRenewPaymentVerfDateValid: licRenewPaymentVerfDateValid,
                        licRenewPaymentVerfPriceValid: licRenewPaymentVerfPriceValid,
                        licenceperiodenumValid: licenceperiodenumValid,
                        licenceperiodenumbillcycleValid: licenceperiodenumbillcycleValid,
                    }, this.validateForm);                            
      }
      validateForm() {
        this.setState({formValid: this.state.licRenewActStatusValid && this.state.licRenewActDateValid && this.state.licRenewActSeatValid && this.state.licRenewPriceValid && this.state.licRenewPaymentTopValid && this.state.licRenewPaymentNumValid  && this.state.licRenewPaymentVerfDateValid  && this.state.licRenewPaymentVerfPriceValid && this.state.licenceperiodenumValid});
      }
    
      errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
      }
      handleSubmit(e) {
        e.preventDefault();
        //this.resetErrorMsg();
        //alert(JSON.stringify(this.state));
        //alert(this.event_enddate)
        //console.log(e);
        console.log("1:"+this.state.licRenewActStatusValid+" - "+this.state.formErrors.licence_renewal_activate_status);
        console.log("2:"+this.state.licRenewActDateValid+" - "+this.state.formErrors.licence_renewal_activate_date);
        console.log("3:"+this.state.licRenewActSeatValid+" - "+this.state.formErrors.licence_renewal_activate_seat);
        console.log("4:"+this.state.licRenewPriceValid+" - "+this.state.formErrors.licence_renewal_price);
        console.log("5:"+this.state.licRenewPaymentTopValid+" - "+this.state.formErrors.licence_renewal_top);
        console.log("6:"+this.state.licRenewPaymentNumValid+" - "+this.state.formErrors.licence_renewal_num);
        
        console.log("actv stat:"+this.state.licence_renewal_activate_status);
        console.log("actv date:"+this.state.licence_renewal_activate_date);
        console.log("actv price:"+this.state.licence_renewal_price);
        console.log("actv seat:"+this.state.licence_renewal_activate_seat);
        console.log("actv num:"+this.state.licence_renewal_num);
        console.log("actv top:"+this.state.licence_renewal_top);
        if(e.target.id === "form-renew-edit") { 
            // if(this.state.licence_renewal_activate_status === "" && this.state.licence_renewal_activate_date === "" && this.state.licence_renewal_price === "" && this.state.licence_renewal_activate_seat === "" && this.state.licence_renewal_num === "" && this.state.licence_renewal_top === "") {
            if(this.state.licence_renewal_activate_status !== "") {
                if((this.state.licence_renewal_activate_status === global.config.licenceRenewStatus[1]) && (this.state.licence_renewal_activate_date === "" || this.state.licence_renewal_activate_seat === "")) { 
                    // var arrfieldName = ['licence_renewal_activate_status','licence_renewal_activate_date','licence_renewal_price','licence_renewal_activate_seat','licence_renewal_num','licence_renewal_top'];
                    // var arrvalue = [this.state.licence_renewal_activate_status,this.state.licence_renewal_activate_date,this.textInputLicenceRenewalPrice.value,this.textInputLicenceActiveSeat.value,this.textInputLicencePaymentNum.value,this.textInputLicencePaymentTop.value];
                    var arrfieldName2 = ['licence_renewal_activate_status','licence_renewal_activate_date','licence_renewal_activate_seat','licence_periode_num','licence_periode_unit','licence_periode_num_billcycle','licence_periode_unit_billcycle'];
                    var arrvalue2 = [this.state.licence_renewal_activate_status,this.state.licence_renewal_activate_date,this.textInputLicenceActiveSeat.value,this.textInputLicencePeriodeNum.value,this.textInputLicencePeriodeUnit.value,this.textInputLicencePeriodeNumBillCycle.value,this.textInputLicencePeriodeUnitBillCycle.value];
                    for(var ck2 in arrfieldName2) {
                        this.validateField(arrfieldName2[ck2], arrvalue2[ck2]);
                    }
                } else {

                

                // if(
                //     (this.state.licence_renewal_activate_status === global.config.licenceRenewStatus[1] && this.state.licence_renewal_activate_date !== "" && this.state.licence_renewal_activate_seat !== "") || 
                //     (this.state.licence_renewal_activate_status === global.config.licenceRenewStatus[3]) || 
                //     (this.state.licence_renewal_price !== "") || 
                //     (this.state.licence_renewal_top !== "" && this.state.licence_renewal_num !== "")
                // ) {
                    console.log("submit");
                    document.getElementById('loading-area').style.display = 'block'; 
                    var urlFetch, datatext;
                    var licRenewActivateStatus = this.state.licence_renewal_activate_status;
                    var licRenewActivateDate = this.state.licence_renewal_activate_date;
                    var licRenewActivateSeat = this.state.licence_renewal_activate_seat;
                    var licRenewPrice = this.state.licence_renewal_price; 
                    var licRenewPriceCurrency = this.state.licence_renewal_price_currency;
                    var licRenewPaymentTop = this.state.licence_renewal_top;
                    var licRenewPaymentNum = this.state.licence_renewal_num;
                    var licencePeriodeNum = this.state.licence_periode_num;
                    var licencePeriodeUnit = this.state.licence_periode_unit;
                    var licencePeriodeNumBillCycle = this.state.licence_periode_num_billcycle;
                    var licencePeriodeUnitBillCycle = this.state.licence_periode_unit_billcycle;
                    if(this.textInputLicenceActiveSeat.value !== '') { licRenewActivateSeat = this.textInputLicenceActiveSeat.value; } 
                    if(this.textInputLicenceRenewalPrice.value !== '') { licRenewPrice = this.textInputLicenceRenewalPrice.value; } 
                    if(this.textInputLicencePaymentTop.value !== '') { licRenewPaymentTop = this.textInputLicencePaymentTop.value ; } 
                    if(this.textInputLicencePaymentNum.value !== '') { licRenewPaymentNum = this.textInputLicencePaymentNum.value; } 
                    if(this.textInputLicenceRenewalPriceCurrency.value !== '') { licRenewPriceCurrency = this.textInputLicenceRenewalPriceCurrency.value; }
                    if(this.textInputLicencePeriodeNum.value !== '') { licencePeriodeNum = this.textInputLicencePeriodeNum.value; }
                    if(this.textInputLicencePeriodeUnit.value !== '') { licencePeriodeUnit = this.textInputLicencePeriodeUnit.value; }
                    //urlFetch = global.config.urlLink+"/renewlicenceedit/"+this.state.clientdoc_id+"/"+this.state.licencedoc_id+"/"+this.state.renewdoc_id;
                    urlFetch = global.config.urlLink+"/renewlicenceedit";
                    
                    if(this.state.client_licence_renew_status_curr === licRenewActivateStatus && this.state.client_licence_renew_status_curr === global.config.licenceRenewStatus[3]) { 
                        console.log("not insert");
                        document.getElementById("renew-edit-form").style.display = "none";
                        document.getElementById("renew-payment-form").style.display = "none";
                        document.getElementById("renew-detail-info").style.display = "block";
                        this.loadFormRenewData();
                    
                    } else {
                        console.log("insert");
                        var licenceRemarks = ReplaceAllContent('\n','<br/>',this.state.client_licence_renew_remarks);
                        if(this.state.client_licence_renew_status_curr !== global.config.licenceRenewStatus[0]) { 
                            if(this.state.client_licence_renew_remarks !== "") { 
                                licenceRemarks += "<br/><br/>"; 
                            }
                            if(this.state.client_licence_renew_status_curr === global.config.licenceRenewStatus[3]) {
                                licenceRemarks += "- has been canceled before on "+ this.state.client_licence_update_date + "  by. " + this.state.client_licence_active_by;
                            } else if(this.state.client_licence_renew_status_curr === global.config.licenceRenewStatus[1]) {
                                licenceRemarks += "- has been activated before on "+ this.state.client_licence_update_date + "  by. " + this.state.client_licence_active_by;
                            } 
                        }
                        
                        datatext ='{"licence_renewal_activate_status":"'+licRenewActivateStatus+'","licence_renewal_activate_date":"'+licRenewActivateDate+'","licence_renewal_activate_seat":"'+licRenewActivateSeat+'","licence_renewal_price":"'+licRenewPrice+'","licence_renewal_price_currency":"'+licRenewPriceCurrency+'","licence_renewal_top":"'+licRenewPaymentTop+'","licence_renewal_num":"'+licRenewPaymentNum+'","licence_periode_unit":"'+licencePeriodeUnit+'","licence_periode_num":"'+licencePeriodeNum+'","licence_periode_unit_billcycle":"'+licencePeriodeUnitBillCycle+'","licence_periode_num_billcycle":"'+licencePeriodeNumBillCycle+'","licence_renew_remarks":"'+licenceRemarks+'","clientdoc_id":"'+this.state.clientdoc_id+'","licencedoc_id":"'+this.state.licencedoc_id+'","renewdoc_id":"'+this.state.renewdoc_id+'","form_id":"'+e.target.id+'"}';
                        console.log(datatext);
                        var data = JSON.parse(datatext); 
                        
                        fetch(urlFetch, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Access-Control-Allow-Origin': '*',
                                'Authorization': authKey,
                            }, body : JSON.stringify(data)
                        }).then(res => {
                            console.log(res);
                            if(res.status === 200) 
                                return res.json() 
                        }).then( resJson => {
                            console.log(resJson);
                            console.log(resJson.status);
                            document.getElementById('loading-area').style.display = 'none'; 
                            if(resJson.status === '200') {
                                document.getElementById("renew-edit-form").style.display = "none";
                                document.getElementById("renew-payment-form").style.display = "none";
                                document.getElementById("renew-detail-info").style.display = "block";
                                this.loadFormRenewData();
                                this.loadLicencePage(this.state.clientdoc_id,this.state.licencedoc_id);
                                //window.location.href='/IViewLicence/'+this.state.clientdoc_id+'/'+this.state.licencedoc_id;
                            } else {
                                console.log('Something happened wrong');
                                console.log(resJson);
                            }
                        }).catch(err => err);
                    }
                }
            } else {
                console.log("filled status");
                // var arrfieldName = ['licence_renewal_activate_status','licence_renewal_activate_date','licence_renewal_price','licence_renewal_activate_seat','licence_renewal_num','licence_renewal_top'];
                // var arrvalue = [this.state.licence_renewal_activate_status,this.state.licence_renewal_activate_date,this.textInputLicenceRenewalPrice.value,this.textInputLicenceActiveSeat.value,this.textInputLicencePaymentNum.value,this.textInputLicencePaymentTop.value];
                var arrfieldName = ['licence_renewal_activate_status'];
                var arrvalue = [this.state.licence_renewal_activate_status];
                for(var ck in arrfieldName) {
                    this.validateField(arrfieldName[ck], arrvalue[ck]);
                }
            } 
            // ---------
        } else if(e.target.id === "form-renew-payment") { 
            if(this.state.licence_renewal_payment_date === "" && this.state.licence_renewal_payment_price === "") {
                var arrfieldName3 = ['licence_renewal_payment_date','licence_renewal_payment_price'];
                var arrvalue3 = [this.state.licence_renewal_payment_date,this.textInputLicenceRenewalPaymentPrice.value];
                for(var ck3 in arrfieldName3) {
                    this.validateField(arrfieldName3[ck3], arrvalue3[ck3]);
                }
            } else {
                console.log("submit payment");

                document.getElementById('loading-area').style.display = 'block'; 
                var urlFetch2, datatext2;
                var licRenewPaymentDate = this.state.licence_renewal_payment_date;
                var licRenewPaymentPrice = this.state.licence_renewal_payment_price;
                var licRenewPaymentPriceCurrency = this.state.licence_renewal_payment_price_currency;
                var licRenewPaymentComment = this.state.licence_renewal_payment_comment; 
                if(this.textInputLicenceRenewalPaymentPrice.value !== '') { licRenewPaymentPrice = this.textInputLicenceRenewalPaymentPrice.value; } 
                if(this.textInputLicenceRenewalPaymentComment.value !== '') { licRenewPaymentComment = this.textInputLicenceRenewalPaymentComment.value; } 
                
                datatext2 ='{"licence_renewal_payment_date":"'+licRenewPaymentDate+'","licence_renewal_payment_price":"'+licRenewPaymentPrice+'","licence_renewal_payment_price_currency":"'+licRenewPaymentPriceCurrency+'","licence_renewal_payment_comment":"'+licRenewPaymentComment+'","clientdoc_id":"'+this.state.clientdoc_id+'","licencedoc_id":"'+this.state.licencedoc_id+'","renewdoc_id":"'+this.state.renewdoc_id+'","form_id":"'+e.target.id+'"}';
                //urlFetch = global.config.urlLink+"/renewlicenceedit/"+this.state.clientdoc_id+"/"+this.state.licencedoc_id+"/"+this.state.renewdoc_id;
                urlFetch2 = global.config.urlLink+"/renewlicenceaddpayment";
                var data2 = JSON.parse(datatext2); 
            
                fetch(urlFetch2, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey,
                    }, body : JSON.stringify(data2)
                }).then(res => {
                    console.log(res);
                    if(res.status === 200) 
                        return res.json() 
                }).then( resJson => {
                    console.log(resJson);
                    console.log(resJson.status);
                    document.getElementById('loading-area').style.display = 'none'; 
                    if(resJson.status === '200') {
                        document.getElementById("renew-edit-form").style.display = "none";
                        document.getElementById("renew-payment-form").style.display = "none";
                        document.getElementById("renew-detail-info").style.display = "block";
                        this.loadFormRenewData();
                        //window.location.href='/IViewLicence/'+this.state.clientdoc_id+'/'+this.state.licencedoc_id;
                    } else {
                        console.log('Something happened wrong');
                        console.log(resJson);
                    }
                }).catch(err => err);
            }
        }
      }
    componentDidMount() {
        //console.log("id:"+document.getElementById("contentid").innerText);
        document.getElementById('loading-area').style.display = 'block'; 
        document.getElementById("errormsg").style.display = 'none';
        this.loadFormRenewData();
    }
    render() {
      //console.log(this.state.licence_renewal_activate_date);
      console.log(this.state.client_licence_renewal_payment_list);
      var paymentHistoryList = [];
      if(this.state.client_licence_renewal_payment_list.length > 0) {
        for(var ls2=0; ls2 < this.state.client_licence_renewal_payment_list.length; ls2++) {
            var retResult2 = this.state.client_licence_renewal_payment_list[ls2];
            var verfDatez = "", verfUpdateDate = "", verfRemarks = "", verfPriceCurrency = "USD";
            if(retResult2.subsubsubcontent.verifiedUpdateDate !== undefined) {
                verfUpdateDate = DateTimeFormats(retResult2.subsubsubcontent.verifiedUpdateDate._seconds,"date-short");
            }
            if(retResult2.subsubsubcontent.verifiedDate !== undefined) {
                verfDatez = DateTimeFormats(retResult2.subsubsubcontent.verifiedDate._seconds,"date-short");
            }
            if(retResult2.subsubsubcontent.verfiedRemarks !== undefined && retResult2.subsubsubcontent.verfiedRemarks !== "") {
                verfRemarks = "Notes : \n"+retResult2.subsubsubcontent.verfiedRemarks;
            }
            if(retResult2.subsubsubcontent.referenceDocument !== undefined) {
                if(verfRemarks !== "") { verfRemarks = verfRemarks + "\n\n"; }
                verfRemarks = verfRemarks + retResult2.subsubsubcontent.referenceDocument;
                verfRemarks = "attachment »";
            }
            if(retResult2.subsubsubcontent.verfiedCurrency !== undefined) {
                verfPriceCurrency = retResult2.subsubsubcontent.verfiedCurrency;
            }
            paymentHistoryList.push(<tr key={ls2+1}>
                <td>{verfUpdateDate}</td>
                <td>{verfPriceCurrency} {retResult2.subsubsubcontent.verifiedPrice}</td>
                <td>{retResult2.subsubsubcontent.verfiedByName}</td>
                <td>{verfDatez}</td>
                <td>{verfRemarks}</td>
              </tr>)
        }
    }
      /*var tempContent1 = ["15 Oct 2020","15 Nov 2020","15 Dec 2020"];
      var tempContent2 = ["USD 75","USD 50","USD 25"];
      var tempContent3 = ["Suzana", "Suzana", "Suzana"];
      var tempContent4 = ["18 Oct 2020", "17 Nov 2020", "17 Dec 2020"];
      var tempContent5 = ["attachment »", "attachment »", "attachment »"];
      for(var a=0; a<tempContent1.length; a++) {
        paymentHistoryList.push(<tr key={a+1}>
            <td>{tempContent1[a]}</td>
            <td>{tempContent2[a]}</td>
            <td>{tempContent3[a]}</td>
            <td>{tempContent4[a]}</td>
            <td>{tempContent5[a]}</td>
          </tr>)
      } */

      var suppDocList = [];
      /*var tempDocContent1 = ["INV/312/X/2020, click here »","QUT/312/X/2020, click here »"];
      for(var b=0; b<tempDocContent1.length; b++) {
      
        suppDocList.push(<li key={b+1}>
            {tempDocContent1[b]}
          </li>)
      }*/
      console.log("err top:"+this.state.formErrors.licence_renewal_price);
      return (
        <div className="main-container">
            <div className="mgT20">
            <div className="divhide w100p" id="errormsg"><Alert severity="error">{this.state.errmsg}</Alert></div>
              <div className="mgL20">
                <div className="st3Title">{this.state.client_name}</div>
                <div><h5 className="detailJoined">{this.state.client_country} - {this.state.domain_name}</h5></div>
              </div>
              <div className="mgT20 mgL20">
                {/* ---- */}
                <div>

                    <div className="mgT05 flex-container">
                        <div className="w30p">Product </div>
                        <div className="mgL10"> {this.state.client_licence_type}</div>
                    </div>
                    <div className="mgT05 flex-container">
                        <div className="w30p">Current Active Licence </div>
                        <div className="mgL10"> {this.state.client_licence_currseat} seat(s)</div>
                    </div>
                    {(this.state.client_licence_active_date !== '' && this.state.client_licence_expired_date !== '')? 
                    <div className="mgT05 flex-container">
                        <div className="w30p">Active Periode </div>
                        <div className="mgL10"> {this.state.client_licence_active_date} <span className="mgL10 mgR10">-</span> {this.state.client_licence_expired_date}</div>
                    </div>
                    : ''}
                   

                    
                    <div className="mgT30 lineBottom">
                        <div className="flex-container">
                            <div className="w30p textBold">Detail Information</div>
                            <div className="w70p divRight">
                            {(this.state.client_licence_renew_status === global.config.licenceRenewStatus[0] || this.state.client_licence_renew_status === global.config.licenceRenewStatus[1]  || this.state.client_licence_renew_status === global.config.licenceRenewStatus[3])?
                                <div className="mgR30 linkText cursor-on" onClick={this.urleditInfo.bind(this, this.state.renewdoc_id)}><EditIcon className="mgR05" style={{fontSize:"14pt"}} ></EditIcon><span style={{marginTop:"3px"}}>Edit Info &raquo;</span></div>
                            : ''}    
                            {(this.state.client_licence_renew_status === global.config.licenceRenewStatus[0] || this.state.client_licence_renew_status === global.config.licenceRenewStatus[1])?   
                                <div className="mgR05 linkText cursor-on" onClick={this.urladdPayment.bind(this, this.state.renewdoc_id)}><PostAddIcon className="mgR05" style={{fontSize:"15pt"}}></PostAddIcon><span style={{marginTop:"3px"}}>Add Payment &raquo;</span></div>
                            : '' }
                            </div>
                            
                        </div>
                    </div>
                    { /* --------- */ }
                    <div className="mgT20 divHide" id="renew-payment-form">
                    <form id="form-renew-payment" onSubmit={this.handleSubmit}>
                        <div className="smallForm">
                            <div className="flex-container mgT10">
                                <div className="w40p"><label className="mgT10" htmlFor="licence_renewal_payment_date">Payment Received On</label></div>
                                <div className="w60p">
                                    <DatePicker
                                        onChange={this.handlePaymentDate}
                                        value={this.state.licence_renewal_payment_date}
                                        customStyles={{dateInput:{borderWidth: 0}}}
                                        />
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_renewal_payment_date}/>  }</div>
                                </div>
                            </div>

                            <div className="flex-container mgT10">
                                <div className="w40p"><label className="mgT05" htmlFor="licence_renewal_payment_price">Payment Received</label></div>
                                <div className="w60p flex-container">
                                    <div className="mgR20">
                                        <select name="licence_renewal_payment_price_currency" className="form-control" 
                                            value={this.state.licence_renewal_payment_price_currency} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputLicenceRenewalPaymentPriceCurrency = input}
                                        >
                                            <option value="USD">USD</option>
                                            <option value="SGD">SGD</option>
                                            <option value="IDR">IDR</option>
                                            <option value="RM">RM</option>
                                        </select> 
                                    </div>
                                    <div>
                                        <input type="number" className="form-control" name="licence_renewal_payment_price"
                                            placeholder={this.state.licence_renewal_payment_price}
                                            value={this.state.licence_renewal_payment_price}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputLicenceRenewalPaymentPrice = input}/> 
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_renewal_payment_price}/>  }</div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="mgT30 textBold">Remarks</div>
                            <div className="mgT05">
                                <textarea name="licence_renewal_payment_comment" className="form-control" 
                                    rows={4} cols={30} 
                                    /*placeholder={this.state.event_name}*/
                                    value={this.state.licence_renewal_payment_comment} 
                                    onChange={this.handleUserInput} 
                                    ref={(input) => this.textInputLicenceRenewalPaymentComment = input}
                                />
                                
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_renewal_payment_comment}/>  }</div>
                            </div>
                            <div className="mgT30 divCenter">
                                <div className="flex-container">
                                <button type="submit" className="submitButton mgR20">Submit</button>
                                <div className="submitButton cursor-on" onClick={this.urlcancelForm.bind(this, this.state.renewdoc_id, "renew-payment-form","renew-edit-form")}>Cancel</div>
                                </div>
                            </div>

                        </div>
                    </form>
                    </div>
                    { /* --------- */ }
                    <div className="mgT20 divHide" id="renew-edit-form">
                    <form id="form-renew-edit" onSubmit={this.handleSubmit}>
                        <div className="smallForm">
                            <div className="flex-container">
                                <div className="w40p"><label className="mgT10" htmlFor="licence_renewal_activate_status">Licence has been activated?</label></div>
                                <div className="w60p">
                                    <div className="mgR20 mgT10">
                                        <label className="mgR30">
                                        <input type="radio" name="licence_renewal_activate_status" 
                                            value={global.config.licenceRenewStatus[1]}
                                            checked={this.state.licence_renewal_activate_status === global.config.licenceRenewStatus[1]} 
                                            onChange={this.handleActivateStatus} /> Yes
                                        </label>
                                        <label>
                                        <input type="radio" name="licence_renewal_activate_status" 
                                            value={global.config.licenceRenewStatus[3]} 
                                            checked={this.state.licence_renewal_activate_status === global.config.licenceRenewStatus[3]} 
                                            onChange={this.handleActivateStatus} /> No / Cancel Renew
                                        </label>    
                                    </div>
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_renewal_activate_status}/>  }</div>
                                </div>
                            </div> 
                            <div id="form-active">
                                <div className="flex-container mgT10">
                                    <div className="w40p"><label className="mgT10" htmlFor="licence_renewal_activate_date">Activated On</label></div>
                                    <div className="w60p">
                                        <DatePicker
                                            onChange={this.handleActivateDate}
                                            value={this.state.licence_renewal_activate_date}
                                            customStyles={{dateInput:{borderWidth: 0}}}
                                            />
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_renewal_activate_date}/>  }</div>
                                    </div>
                                </div>

                                <div className="flex-container mgT10">
                                    <div className="w40p"><label className="mgT05" htmlFor="licence_renewal_activate_seat">Number of Activated Seats </label></div>
                                    <div className="w60p flex-container">
                                        <div>
                                            <input type="number" className="form-control" name="licence_renewal_activate_seat"
                                                placeholder={this.state.licence_renewal_activate_seat}
                                                value={this.state.licence_renewal_activate_seat}
                                                onChange={this.handleUserInput}  
                                                ref={(input) => this.textInputLicenceActiveSeat = input}
                                                min="1"/> 
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_renewal_activate_seat}/>  }</div>
                                        </div>
                                        <div className="mgL20 mgT05"> seat(s) </div>
                                    </div>
                                        
                                </div>
                                <div className="flex-container mgT10">
                                    <div className="w40p"><label className="mgT05" htmlFor="licence_renewal_price_currency">Price /seat/month</label></div>
                                    <div className="w60p flex-container">
                                        <div className="mgR20">
                                            <select name="licence_renewal_price_currency" className="form-control" 
                                                value={this.state.licence_renewal_price_currency} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputLicenceRenewalPriceCurrency = input}
                                            >
                                                <option value="USD">USD</option>
                                                <option value="SGD">SGD</option>
                                                <option value="IDR">IDR</option>
                                                <option value="RM">RM</option>
                                            </select> 
                                        </div>
                                        <div>
                                            <input type="number" className="form-control" name="licence_renewal_price"
                                                placeholder={this.state.licence_renewal_price}
                                                value={this.state.licence_renewal_price}
                                                onChange={this.handleUserInput}  
                                                ref={(input) => this.textInputLicenceRenewalPrice = input}/> 
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_renewal_price}/>  }</div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="flex-container mgT10">
                                    <div className="w40p"><label className="mgT05" htmlFor="licence_periode_num_billcycle">Period of Billing Cycle </label></div>
                                    <div className="w60p">
                                        <div className="flex-container">
                                            <div className="w30p"><input type="number" className="form-control" name="licence_periode_num_billcycle"
                                                placeholder={this.state.licence_periode_num_billcycle}
                                                value={this.state.licence_periode_num_billcycle}
                                                onChange={this.handleUserInput}  
                                                ref={(input) => this.textInputLicencePeriodeNumBillCycle = input}
                                                min="1"/> 
                                            </div>
                                            <div className="w70p mgL20">
                                                <select name="licence_periode_unit_billcycle" className="form-control" 
                                                    value={this.state.licence_periode_unit_billcycle} 
                                                    onChange={this.handleUserSelect}
                                                    ref={(input) => this.textInputLicencePeriodeUnitBillCycle = input}
                                                    disabled={this.state.input_disabled}
                                                >
                                                    <option value="">-- please select --</option>
                                                    <option value="month">Month</option>
                                                    <option value="year">Year</option>
                                                </select>
                                            
                                            </div>
                                        </div>
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_periode_num_billcycle}/>  }</div>
                                    </div>
                                        
                                </div>
                                <div className="flex-container mgT10">
                                    <div className="w40p"><label className="mgT05" htmlFor="licence_periode_num">Period of Contract </label></div>
                                    <div className="w60p">
                                        <div className="flex-container">
                                            <div className="w30p"><input type="number" className="form-control" name="licence_periode_num"
                                                placeholder={this.state.licence_periode_num}
                                                value={this.state.licence_periode_num}
                                                onChange={this.handleUserInput}  
                                                ref={(input) => this.textInputLicencePeriodeNum = input}
                                                min="1"/> 
                                            </div>
                                            <div className="w70p mgL20">
                                                <select name="licence_periode_unit" className="form-control" 
                                                    value={this.state.licence_periode_unit} 
                                                    onChange={this.handleUserSelect}
                                                    ref={(input) => this.textInputLicencePeriodeUnit = input}
                                                    disabled={this.state.input_disabled}
                                                >
                                                    <option value="">-- please select --</option>
                                                    <option value="month">Month</option>
                                                    <option value="year">Year</option>
                                                </select>
                                            
                                            </div>
                                        </div>
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_periode_num}/>  }</div>
                                    </div>
                                        
                                </div>
                            </div>
                            <div id="form-term-payment">
                                <div className="mgT30 textBold">Term of Payment</div>
                                <div className="flex-container mgT05">
                                    <div className="w40p"><label className="mgT05" htmlFor="licence_renewal_top">Payment will be paid</label></div>
                                    <div className="w60p">
                                        <select name="licence_renewal_top" className="form-control" 
                                            value={this.state.licence_renewal_top} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputLicencePaymentTop = input}
                                            disabled={this.state.input_disabled}
                                        >
                                            <option value="">-- please select --</option>
                                            <option value="Cash Before Delivery">Cash Before Delivery</option>
                                            <option value="Cash On Delivery">Cash On Delivery</option>
                                            <option value="End of Month">End of Month</option>
                                            <option value="7 days after delivery">7 days after delivery</option>
                                            <option value="14 days after delivery">14 days after delivery</option>
                                            <option value="30 days after delivery">30 days after delivery</option>
                                        </select>
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_renewal_top}/>  }</div>
                                    </div>
                                </div>
                                <div className="flex-container mgT10">
                                    <div className="mgR10 w40p"><label className="mgT05" htmlFor="licence_renewal_num">within</label></div>
                                    <div className="mgR20 w60p flex-container">
                                        <div>
                                            <select name="licence_renewal_num" className="form-control" 
                                                value={this.state.licence_renewal_num} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputLicencePaymentNum = input}
                                                disabled={this.state.input_disabled}
                                            >
                                                <option value="">-- please select --</option>
                                                <option value="1">Full (1)</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </select>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_renewal_num}/>  }</div>
                                        </div>
                                        <div className="mgL10 mgT05">times payment(s)</div>
                                        
                                    </div>
                                </div> 
                            </div>   
                            <div className="mgT30 divCenter">
                                <div className="flex-container">
                                <button type="submit" className="submitButton mgR20">Submit</button>
                                <div className="submitButton cursor-on" onClick={this.urlcancelForm.bind(this, this.state.renewdoc_id, "renew-edit-form", "renew-payment-form")}>Cancel</div>
                                </div>
                            </div>

                        </div>
                    </form>
                    </div>
                    { /* --------- */ }
                    <div className="mgT20" id="renew-detail-info">
                        <div className="mgT20">
                            <div className="mgT10 flex-container">
                                <div className="w30p">Renewal Status </div>
                                <div className="mgL10 st4Title"> <span className={this.state.client_licence_renew_status === global.config.licenceRenewStatus[1]? "fontGreen textBold" : "textBold"}>{this.state.client_licence_renew_status}</span> </div>
                            </div>
                            {((this.state.client_licence_renew_status === global.config.licenceRenewStatus[1]) && (this.state.client_licence_active_seat !== ''))? 
                            <div className="mgT05 flex-container">
                                <div className="w30p">Renew Number of Licence </div>
                                <div className="mgL10"> {this.state.client_licence_active_seat} seat(s) </div>
                            </div>
                            : ''}
                            {(this.state.client_licence_reqdate !== '')? 
                                <div className="mgT30 flex-container">
                                    <div className="w30p">Request</div>
                                    <div className="mgL10">
                                        <span> {this.state.client_licence_reqseat} seat(s)</span>
                                        <span className="mgL30">on.</span><span className="mgL10"> {this.state.client_licence_reqdate}</span>
                                        <span className="mgL30">by.</span><span className="mgL10"> {this.state.client_licence_reqby}</span>
                                    </div>
                                </div>
                            : ''}
                            {(this.state.client_licence_update_date !== '')?
                            <div className="mgT05 flex-container">
                                <div className="w30p">Update</div>
                                <div className="mgL10"> 
                                    on. <span className="mgL10"> {this.state.client_licence_update_date}</span> 
                                    <span className="mgL30">by.</span><span className="mgL10">{this.state.client_licence_active_by}</span>
                                </div>
                            </div>
                            : ''}
                            
                            {(this.state.client_licence_renew_remarks !== '')? 
                            <div className="mgT30 flex-container">
                                <div className="w30p">Remarks </div>
                                <div className="mgL10"> {this.state.client_licence_renew_remarks} </div>
                            </div>
                            : ''}
                        
                        </div>

                        
                        <div className="mgT50 textBold">Payment Information</div>
                        <div className="mgL10">
                            <div className="mgT10 flex-container">
                                <div className="w30p">Status Payment </div>
                                <div className="mgL10"> <span className="textBold">{this.state.client_licence_payment_status}</span> </div>
                            </div>
                            {(this.state.client_licence_reqprice !== '')? 
                            <div className="mgT05 flex-container">
                                <div className="w30p">Total Price </div>
                                <div className="mgL10"> {this.state.client_licence_reqprice} </div>
                            </div>
                            : ''}
                            {(this.state.client_licence_payment_top !== '')? 
                            <div className="mgT05 flex-container">
                                <div className="w30p">Terms of Payment </div>
                                <div className="mgL10"> {this.state.client_licence_payment_top} <span className="mgL30">{this.state.client_licence_payment_num}</span></div>
                            </div>
                            : ''}
                            {(paymentHistoryList.length > 0)? 
                            <div className="mgT30">
                                <div className="textBold">History of Payment</div>
                                <div className="mgT10">
                                    <table className="table">
                                    <thead className="thead-light">
                                        <tr>
                                        <th scope="col textWrap">Date</th>
                                        <th scope="col textWrap">Price</th>
                                        <th scope="col textWrap">Verified By</th>
                                        <th scope="col textWrap">Verified On</th>
                                        <th scope="col textWrap">Remarks</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paymentHistoryList}
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                            : ''}
                        </div>
                        {(suppDocList.length > 0)? 
                        <div className="mgT30">
                            <div className="textBold">Supporting Document</div>
                            <div className="mgT10 mgL10">{suppDocList}</div>
                        </div>
                        : ''}
                    </div> 

                </div>

                {/* ---- */}
                
              </div>
              <div className="row-field mgT30 mgB30">
                    {/*<input type="hidden" required name="clientdoc_id" value={this.state.clientdoc_id} />
                    <input type="hidden" required name="licencedoc_id" value={this.state.licencedoc_id} />
                    <button type="submit" className="w100p submitButton">Submit</button> */}
              </div>
            </div>
            <div id="loading-area" className="divHide loading"></div> 
        </div>
      );
    }
 }
  
export default RenewDetail;