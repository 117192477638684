import React, { Component } from 'react';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[3];

class ILicenceTypeForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            licencedoc_id: '',
            licence_name: '',
            licence_price: '',
            licence_category: '',
            licence_region: '',
            licence_currency: '',
            licence_price_usd: '',
            licence_status: global.config.licenceStatus[1],
            
            usage_for: '',
            formErrors: { licence_name: '',licence_price: '', licence_category: '',licence_price_usd: '',licence_status: ''},
            licencenameValid: false,
            licencepriceValid: false,
            licencecategoryValid: false,
            licencepriceusdValid: false,
            licencestatusValid: false,
            formValid: false,
            input_disabled: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleUserSelect = (e) => {
        const value = e.target.value;
        const inputname = e.target.name;
        console.log(value);
        console.log(inputname);
        this.setState( prevState => {
            return {  
                ...prevState.licence_category, licence_category: value,
            }
          }, () => { this.validateField(inputname, value)
        }) 
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let licencenameValid = this.state.licencenameValid;
        let licencepriceValid = this.state.licencepriceValid;
        let licencecategoryValid = this.state.licencecategoryValid;
        let licencepriceusdValid = this.state.licencepriceusdValid;
        let licencestatusValid = this.state.licencestatusValid;
        switch(fieldName) {
            case 'licence_category': 
                licencecategoryValid = value.trim().length > 0;
                fieldValidationErrors.licence_category = licencecategoryValid ? '' : 'category need to be filled';
                break;
            case 'licence_name': 
                licencenameValid = value.trim().length > 0;
                fieldValidationErrors.licence_name = licencenameValid ? '' : 'licence name need to be filled';
                break;
            case 'licence_price': 
                licencepriceValid = value.trim().length > 0;
                fieldValidationErrors.licence_price = licencepriceValid ? '' : 'price each licence need to be filled';
                break;
            case 'licence_price_usd': 
                licencepriceusdValid = value.trim().length > 0;
                fieldValidationErrors.licence_price_usd = licencepriceusdValid ? '' : 'price each licence for USD need to be filled';
                break;
            case 'licence_status': 
                licencestatusValid = value.trim().length > 0;
                fieldValidationErrors.licence_status = licencestatusValid ? '' : 'status need to be filled';
                break;
            default:
                break;
        }
        
        this.setState({formErrors: fieldValidationErrors,
                        licencenameValid: licencenameValid,
                        licencepriceValid: licencepriceValid,
                        licencecategoryValid: licencecategoryValid,
                        licencepriceusdValid: licencepriceusdValid,
                        licencestatusValid: licencestatusValid,
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.licencenameValid && this.state.licencepriceValid && this.state.licencecategoryValid && this.state.licencepriceusdValid && this.state.licencestatusValid});
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit(e) {
        e.preventDefault();
        //alert(JSON.stringify(this.state));
        //alert(this.event_enddate)
        if(this.state.licence_name !== '' && this.state.licence_category !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var licenceName,licencePrice,licenceCategory,licencePriceUsd,licenceStatus;
            if(this.textInputLicenceName.value === '') { licenceName = this.state.licence_name; } else { licenceName = this.textInputLicenceName.value; }
            if(this.textInputLicencePrice.value === '') { licencePrice = this.state.licence_price; } else { licencePrice = this.textInputLicencePrice.value; }
            if(this.textInputLicenceCategory.value === '') { licenceCategory = this.state.licence_category; } else { licenceCategory = this.textInputLicenceCategory.value; }
            if(this.textInputLicencePriceUSD.value === '') { licencePriceUsd = this.state.licence_price_usd; } else { licencePriceUsd = this.textInputLicencePrice.value; }
            if(this.textInputLicenceStatus.value === '') { licenceStatus = this.state.licence_status; } else { licenceStatus = this.textInputLicenceStatus.value; }
            
            var datatext ='{"licencedoc_id":"'+this.state.licencedoc_id+'","licence_name":"'+licenceName+'","licence_price":"'+licencePrice+'","licence_category":"'+licenceCategory+'","licence_currency":"'+this.state.licence_currency+'","licence_region":"'+this.state.licence_region.toLowerCase()+'","licence_price_usd":"'+this.state.licence_price_usd+'","licence_status":"'+licenceStatus+'","form_id":"form-licencetype"}';
            var urlFetch = global.config.urlLink;
            if(this.state.licencedoc_id !== '') { urlFetch = urlFetch+"/licencetypeedit"; }
            else { urlFetch = urlFetch+"/licencetypeadd"; }
            // console.log(datatext);
            // console.log(urlFetch)
            var data = JSON.parse(datatext); 
            
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                console.log(resJson);
                console.log(resJson.status);
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    window.location.href='/ILicenceTypes';
                } else {
                    console.log('Something happened wrong');
                    console.log(resJson);
                }
            }).catch(err => err);

        } else {
            var arrfieldName = ['licence_name','licence_price','licence_category','licence_price_usd','licence_status'];
            var arrvalue = [this.textInputLicenceName.value,this.textInputLicencePrice.value,this.textInputLicenceCategory.value,this.textInputLicencePriceUSD.value,this.textInputLicenceStatus.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    componentDidMount() {
        //var memberId = document.getElementById("contentid").innerText;
        document.getElementById('loading-area').style.display = 'block'; 
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        console.log("id:"+othersContent.lictypeId+"--");
        if(othersContent.lictypeId !== '') {
            var disableform = false;
            if(othersContent.action === "View") { disableform = true; }
            fetch(global.config.urlLink+"/licencetypeview/"+othersContent.lictypeId, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                console.log(resJson);
                console.log(resJson.status);
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') { 
                    this.setState({
                        licencedoc_id: resJson.values.licenceData[0].docid,
                        licence_name: resJson.values.licenceData[0].content[0].maincontent.licenceType,
                        licence_price: resJson.values.licenceData[0].content[0].maincontent.licencePrice,
                        licence_category: resJson.values.licenceData[0].content[0].maincontent.licenceCategory,
                        licence_region: resJson.values.licenceData[0].content[0].maincontent.licencePriceForCountry,
                        licence_currency: resJson.values.licenceData[0].content[0].maincontent.licenceCurrency,
                        licence_price_usd: resJson.values.licenceData[0].content[0].maincontent.licencePriceUSD,
                        licence_status: resJson.values.licenceData[0].content[0].maincontent.licenceStatus,
                        formErrors: { licence_name: '',licence_price: '', licence_category: '', licence_price_usd: '', licence_status: ''},
                        licencenameValid: false,
                        licencepriceValid: false,
                        licencecategoryValid: false,
                        licencepriceusdValid: false,
                        licencestatusValid: false,
                        formValid: false,
                        input_disabled: disableform
                    }); 
                } else {
                    console.log('Something happened wrong');
                    console.log(resJson);
                }
              }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
                console.log(err);
              });
        } else {
            console.log(othersContent.action);
            console.log(othersContent.usageFor);
            var currency = "USD";
            if(othersContent.usageFor === "Indonesia") { currency = "IDR"; }
            else if(othersContent.usageFor === "Malaysia") { currency = "RM"; }
            else if(othersContent.usageFor === "Singapore") { currency = "SGD"; }
            document.getElementById('loading-area').style.display = 'none'; 
            this.setState({
                licencedoc_id: othersContent.lictypeId,
                licence_name: '',
                licence_price: '',
                licence_category: '',
                licence_region: othersContent.usageFor,
                licence_currency: currency,
                licence_price_usd: '',
                licence_status: '',
                formErrors: { licence_name: '',licence_price: '', licence_category: '', licence_price_usd: '', licence_status: ''},
                licencenameValid: false,
                licencepriceValid: false,
                licencecategoryValid: false,
                licencepriceusdValid: false,
                licencestatusValid: false,
                formValid: false,
                input_disabled: false
            }); 
        }
    }
    render() {
        console.log("country: "+ this.state.licence_region);
        console.log("currency: "+ this.state.licence_currency);
        
        var sellicenceCategoryList = [];
        if(global.config.categoryLicence.length > 0) {
            for(var r in global.config.categoryLicence) {
                sellicenceCategoryList.push(<option key={r} value={global.config.categoryLicence[r]}>{global.config.categoryLicence[r]}</option>)
            }
        }
        return (
            <div className="main-container">
                <div className="mgT30">
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <input type="hidden" id="category_id" name="category_id" value={this.state.category_id} />
                    </div>
                    <div className="row-field mgT20">
                        <label className="labelFormL" htmlFor="licence_category">Category</label>
                        <div className="w70p">
                            <select name="licence_category" className="form-control" 
                                value={this.state.licence_category} 
                                onChange={this.handleUserSelect}
                                ref={(input) => this.textInputLicenceCategory = input}
                                disabled={this.state.input_disabled}
                            >
                            <option value="" key="">-- please select --</option>
                            {sellicenceCategoryList}
                            </select>
                            
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.licence_category}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT20">
                        <label className="labelFormL" htmlFor="licence_name">Licence Name</label>
                        <div className="w70p">
                            <input type="text" className="form-control" name="licence_name"
                                placeholder={this.state.licence_name}
                                value={this.state.licence_name}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputLicenceName = input}
                                disabled={this.state.input_disabled}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.licence_name}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT20">
                        <label className="labelFormL" htmlFor="licence_price">Price / licence / month</label>
                        <div className="w70p">
                            <div className="flex-container">
                                <div className="divMiddle mgR20" style={{width:"50px"}}>{this.state.licence_currency}</div>
                                <div><input type="text" className="form-control" name="licence_price"
                                    placeholder={this.state.licence_price}
                                    value={this.state.licence_price}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputLicencePrice = input}
                                    disabled={this.state.input_disabled}/> 
                                </div>
                            </div>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.licence_price}/>  }
                            </div>
                            <div className="flex-container mgT10">
                                <div className="divMiddle mgR20" style={{width:"50px"}}>USD</div>
                                <div><input type="text" className="form-control" name="licence_price_usd"
                                    placeholder={this.state.licence_price_usd}
                                    value={this.state.licence_price_usd}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputLicencePriceUSD = input}
                                    disabled={this.state.input_disabled}/> 
                                </div>
                            </div>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.licence_price_usd}/>  }
                            </div>
                        </div>
                    </div>
                    
                    <div className="flex-container mgT20">
                        <div className="divMiddle mgR20" style={{width:"50px"}}>Status</div>
                        <div>
                            <select name="licence_status" className="form-control" 
                                value={this.state.licence_status} 
                                onChange={this.handleUserSelect}
                                ref={(input) => this.textInputLicenceStatus = input}
                                disabled={this.state.input_disabled}
                            >
                            <option value={global.config.licenceStatus[1]} key="1">{global.config.licenceStatus[1]}</option>
                            <option value={global.config.licenceStatus[2]} key="0">{global.config.licenceStatus[2]}</option>
                            </select>
                            
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.licence_status}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT30 mgB30">
                        {(this.state.input_disabled === false) ? 
                            <div className="w100p"><button id="submit-button"  type="submit" className="w100p submitButton">Submit</button></div>
                        : <div className="w100p"></div> 
                        }
                    </div>
                </form>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
          );
        }
     }
      
export default ILicenceTypeForm;
