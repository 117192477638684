import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';

const authKey = global.config.authBearerKey;
  

class IAddTestDemo extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stTitle: '',
            user_id: '',
            user_email: '',
            user_position: '',
            user_level: '',
            user_region: '',
            formErrors: { user_email: '', user_position: '', user_level: '', user_region: ''},
            useremailValid: false,
            userpositionValid: false,
            userlevelValid: false,
            userregionValid: false,
            formValid: false,
            input_disabled: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleBack = () => {
        this.props.history.goBack();
    }    
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    handleUserSelect = (e) => {
        const value = e.target.value;
        const inputname = e.target.name;
        console.log(value);
        console.log(inputname);
        if(inputname === "user_region") {
            if(value !== '') {
                this.setState( 
                    prevState => { 
                        return {  ...prevState.user_region, user_region: value }
                    }, () => { this.validateField(inputname, value) }
                ) 
            } else {
                this.setState( 
                    prevState => { 
                        return {  ...prevState.user_region, user_region: value }
                    }
                )
            }
        } else { 
            if(value !== '') {
                this.setState( 
                    prevState => { 
                        return {  ...prevState.user_level, user_level: value }
                    }, () => { this.validateField(inputname, value) }
                ) 
            } else {
                this.setState( 
                    prevState => { 
                        return {  ...prevState.user_level, user_level: value }
                    }
                )
            }
        }
        
      }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let useremailValid = this.state.useremailValid;
        let userpositionValid = this.state.userpositionValid;
        let userlevelValid = this.state.userlevelValid;
        let userregionValid = this.state.userregionValid;
        
        switch(fieldName) {
            case 'user_email': 
                useremailValid = value.trim().length > 0;
                fieldValidationErrors.user_email = useremailValid ? '' : 'email need to be filled';
                break;
            case 'user_position': 
                userpositionValid = value.trim().length > 0;
                fieldValidationErrors.user_position = userpositionValid ? '' : 'position need to be filled';
                break;
            case 'user_level': 
                userlevelValid = value.trim().length > 0;
                fieldValidationErrors.user_level = userlevelValid ? '' : 'level need to be filled';
                break;
            case 'user_region': 
                userregionValid = value.trim().length > 0;
                fieldValidationErrors.user_region = userregionValid ? '' : 'region need to be filled';
                break;
            default:
                break;
        }
        
        this.setState({formErrors: fieldValidationErrors,
                        useremailValid: useremailValid,
                        userpositionValid: userpositionValid,
                        userlevelValid: userlevelValid,
                        userregionValid: userregionValid,
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.useremailValid && this.state.userpositionValid && this.state.userlevelValid && this.state.userregionValid});
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit(e) {
        e.preventDefault();
        //alert(JSON.stringify(this.state));
        //alert(this.event_enddate)
        if(this.state.user_email !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var userEmail, userPosition, userLevel, userRegion;
            if(this.textInputUserEmail.value === '') { userEmail = this.state.user_email; } else { userEmail = this.textInputUserEmail.value; }
            if(this.textInputUserPosition.value === '') { userPosition = this.state.user_position; } else { userPosition = this.textInputUserPosition.value; }
            if(this.textInputUserLevel.value === '') { userLevel = this.state.user_level; } else { userLevel = this.textInputUserLevel.value; }
            if(this.textInputUserRegion.value === '') { userRegion = this.state.user_region; } else { userRegion = this.textInputUserRegion.value; }
            
            var datatext ='{"user_id":"'+this.state.user_id+'","user_email":"'+userEmail+'","user_position":"'+userPosition+'","user_level":"'+userLevel+'","user_region":"'+userRegion+'","user_role":"guest"}';
            var urlFetch = global.config.urlLink;
            // if(this.state.user_id !== '') { urlFetch = urlFetch+"/testedit"; }
            // else { urlFetch = urlFetch+"/testadd"; }
            urlFetch = urlFetch+"/testadd";
            //console.log(datatext);
            var data = JSON.parse(datatext); 
            
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                console.log(resJson);
                console.log(resJson.status);
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    window.location.href='/TestDemoAppsheet';
                } else {
                    console.log('Something happened wrong');
                    console.log(resJson);
                }
            }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
                console.log(err);
            });

        } else {
            var arrfieldName = ['user_email','user_position','user_level','user_region'];
            var arrvalue = [this.textInputUserEmail.value,this.textInputUserPosition.value,this.textInputUserLevel.value,this.textInputUserRegion.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    componentDidMount() {
        //var memberId = document.getElementById("contentid").innerText;
        document.getElementById('loading-area').style.display = 'block'; 
        var geturl = this.props.location; 
        var arrString = geturl.pathname;
        var arrUrl = [];
        arrUrl = arrString.split('/');
        
        if(arrUrl[1] === 'TestEditDemoAppsheet' && arrUrl[2] !== '') {
            fetch(global.config.urlLink+"/testdetail/"+arrUrl[2], {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                console.log(resJson);
                console.log(resJson.status);
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') { 
                    this.setState({
                        stTitle: 'Edit Data',
                        user_id: resJson.values[0].docid,
                        user_email: resJson.values[0].content[0].maincontent.userEmail,
                        user_position: resJson.values[0].content[0].maincontent.userPosition,
                        user_level: resJson.values[0].content[0].maincontent.userLevel,
                        user_region: resJson.values[0].content[0].maincontent.userRegion,
                        formErrors: { user_email: '', user_position: '', user_level: '', user_region: ''},
                        useremailValid: false,
                        userpositionValid: false,
                        userlevelValid: false,
                        userregionValid: false,
                        formValid: false,
                        input_disabled: false
                    }); 
                } else {
                    console.log('Something happened wrong');
                    console.log(resJson);
                }
              }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
                console.log(err);
              });
        } else {
            document.getElementById('loading-area').style.display = 'none'; 
            this.setState( prevState => {
                return {
                    ...prevState.stTitle, stTitle: 'Add New',
                    ...prevState.input_disabled, input_disabled: false
                }
            })
        }

    }

 render() {
    
    
   return (
    <div className="main-container">
        <div className="flex-container">
            <div className="w100p mgL20 mgR20" style={{marginTop:"20px"}}>
            <div className="stTitle"></div>
            <div>
                <div className="stIndiTitle"></div>
                        
                <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="flex-container">
                            <div className="st2Title w70p">{this.state.stTitle}</div>
                            <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        <div id="FormCenter" className="mgT20">
                            <form onSubmit={this.handleSubmit}>
                                <div className="flex-container">
                                    <input type="hidden" required name="user_id" value={this.state.user_id} />
                                </div>
                                <div className="row-field mgT20">
                                    <label className="w30p textMiddle" htmlFor="user_email">Email</label>
                                    <div className="w70p">
                                        <input type="text" className="form-control" name="user_email"
                                            placeholder={this.state.user_email}
                                            value={this.state.user_email}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputUserEmail = input}
                                            disabled={this.state.input_disabled}/>
                                        <div className="mgT05">
                                            { <FormErrors formErrors={this.state.formErrors.user_email}/>  }
                                        </div>
                                    </div>
                                </div>
                                <div className="row-field mgT20">
                                    <label className="w30p textMiddle" htmlFor="user_position">Position</label>
                                    <div className="w70p">
                                        <input type="text" className="form-control" name="user_position"
                                            placeholder={this.state.user_position}
                                            value={this.state.user_position}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputUserPosition = input}
                                            disabled={this.state.input_disabled}/>
                                        <div className="mgT05">
                                            { <FormErrors formErrors={this.state.formErrors.user_position}/>  }
                                        </div>
                                    </div>
                                </div>
                                <div className="row-field mgT20">
                                    <label className="w30p textMiddle" htmlFor="user_level">Level</label>
                                    <div className="w70p">
                                        <select name="user_level" className="form-control"
                                                    value={this.state.user_level} 
                                                    onChange={this.handleUserSelect}
                                                    ref={(input) => this.textInputUserLevel = input}
                                                    disabled={this.state.input_disabled}>
                                                <option value="">-- please select --</option>
                                                <option value="Admin">Admin</option>
                                                <option value="User">User</option>
                                            </select>
                                        <div className="mgT05">
                                            { <FormErrors formErrors={this.state.formErrors.user_level}/>  }
                                        </div>
                                    </div>
                                </div>
                                <div className="row-field mgT20">
                                    <label className="w30p textMiddle" htmlFor="user_region">Region</label>
                                    <div className="w70p">
                                        <select name="user_region" className="form-control"
                                                    value={this.state.user_region} 
                                                    onChange={this.handleUserSelect}
                                                    ref={(input) => this.textInputUserRegion = input}
                                                    disabled={this.state.input_disabled}>
                                                <option value="">-- please select --</option>
                                                <option value="Indonesia">Indonesia</option>
                                                <option value="Singapore">Singapore</option>
                                                <option value="Malaysia">Malaysia</option>
                                            </select>
                                        <div className="mgT05">
                                            { <FormErrors formErrors={this.state.formErrors.user_region}/>  }
                                        </div>
                                    </div>
                                </div>
                                <div className="row-field mgT30 mgB30">
                                    <div className="w30p"></div>
                                    <div className="w70p"><button type="submit" className="w100p submitButton">Submit</button></div>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                    {/* ====== */}
                </div>
            </div>
            
            </div>
        </div>
        <div id="loading-area" className="divHide loading"></div> 
    </div>
   );
 }
}
 
export default IAddTestDemo;