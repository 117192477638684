import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import DateTimeFormats from '../../inc/date-time-format';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[13];

class FeedbackDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            library_id: "",
            library_title: "",
            library_feedback_id: "",
            member_name: "",
            member_email: "",
            submit_date: "",
            answer_list: [],
        }
    }
    componentDidMount() {
        document.getElementById('loading-area').style.display = 'none'; 
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        this.setState({
          library_id: othersContent.library_docid,
          library_title: othersContent.library_title,
          library_feedback_id: othersContent.library_feedback_docid,
          member_name: othersContent.member_name,
          member_email: othersContent.member_email,
          submit_date: DateTimeFormats(othersContent.submit_date,"date-long"),
          answer_list: othersContent.answer_list
        });
    }
    render() {
      return (
        <div className="main-container">
            <div className="mgT20">
              <div className="mgL20">
                <div className="st3Title">{this.state.library_title}</div>
                <div><h5 className="detailJoined mgT20">{this.state.member_name}</h5></div>
                <div><h5 className="detailJoined">{this.state.member_email}</h5></div>
                <div><h5 className="detailJoined">{this.state.submit_date}</h5></div>
              </div>
              <div className="mgT20 mgL20">
                <div>
                    <div className="mgT20" id="feedback-detail-info">
                        <div className="mgT30 textBold">Feedback Data</div>
                        <div className="mgL10">
                            {
                                this.state.answer_list.map(answer => {
                                    return(
                                        <div className="mgT10">
                                            <div>
                                                {answer.subsubcontent.feedbackNumber + ". " + answer.subsubcontent.feedbackText}
                                            </div>
                                            <div><strong>&emsp;{answer.subsubcontent.feedbackAnswer}</strong></div>
                                        </div>
                                    )       
                                })
                            }
                        </div>                   
                    </div>
                </div>
              </div>
              <div className="row-field mgT30 mgB30">                    
              </div>
            </div>
            <div id="loading-area" className="divHide loading"></div> 
        </div>
      );
    }
 }
  
export default FeedbackDetail;