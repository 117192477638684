import React, { Component } from 'react';
import '../../inc/genstyle.css';
import DateTimeFormats from '../../inc/date-time-format';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[28];
  
function urladdContents() {
    window.location.href='/IAddNewContent';
}

class IAddContents extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        selectType: '',
        styleTb: 'table',
        data: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  loadData(keyword,pagenum,seltype) {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+keyword+'","pageShow":"'+pagenum+'"';
    if(seltype !== "") {
        datatext += ',"filter1":["'+seltype+'"]';
    } else { datatext += ',"filter1":[]'; }
    datatext += '}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/addcontentlist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        var styleTb = 'table';
        if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; } 
        var arrContentsList = [];
        var arrContentSortByDesc = [];
        if(resJson.values.length > 0 && resJson.status === '200') {
            for(var v=0; v < resJson.values.length; v++) {
                arrContentsList.push({
                    docid: resJson.values[v].docid,
                    content: resJson.values[v].content,
                    contentdate: resJson.values[v].content.contentDate
                })
            }
            if(arrContentsList.length>0) {
                arrContentSortByDesc = arrContentsList.sort((c,d) => {
                    return d.contentdate._seconds - c.contentdate._seconds;
                })
            }
        } 
        this.setState({
            styleTb: styleTb,
            data: arrContentSortByDesc 
        })
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  urlpublishContent(valId,nextstat) {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"content_docid":"'+valId+'","content_status":"'+nextstat+'","form_id":"form-publish"}';
    var data = JSON.parse(datatext); 
    var urlFetch = global.config.urlLink+"/addcontentedit";
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) return res.json() 
        }).then( resJson => {
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') {
                window.location.href='/IAddContent';
            } else {
                console.log('Something happened wrong');
            }
        }).catch(err => { 
            document.getElementById('loading-area').style.display = 'none'; 
        });
  }
  urldeleteContent(valId) {
    var stat = false;
    stat = window.confirm("Are you sure you want to delete this data?");
    if(stat === true) {  
        document.getElementById('loading-area').style.display = 'block'; 
        var urlString = global.config.urlLink+"/addcontentdelete";
        var datatext ='{"docid":"'+valId+'","numchild":0}';
        var data = JSON.parse(datatext); 
        fetch(urlString, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                return res.json() 
        }).then( resJson => {
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === "200") {
                window.location.reload();
            } else {
                console.log('Something happened wrong delete');
            }
        }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
        });
    }
  }
  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value });
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    const inputname = e.target.name;
    if(inputname === "select_type") { 
        this.setState( prevState => {
            return {  
                ...prevState.selectType, selectType: value
            }
        })
        this.loadData(this.state.keyword,this.state.pageShow,value);
    } else {
        this.setState( prevState => {
            return {  
                ...prevState.pageShow, pageShow: value
            }
        })
    }
  }
    
  handleSubmit(e) {
    e.preventDefault();
    this.loadData(this.textInputKeyword.value,this.state.pageShow,this.textInputContentType.value);
  }
  componentDidMount() {
    this.loadData(this.state.keyword,this.state.pageShow,this.state.selectType);
  }
    
  render() {
    var contentsList = [];
    if(this.state.data.length > 0) {
        for(var ls=0; ls < this.state.data.length; ls++) {
            var retResult = this.state.data[ls];
            var editURL = "/IEditAddContent/"+retResult.docid;
            var viewURL = "/IViewAddContent/"+retResult.docid;
            var cPostedDatez = "";
            if(retResult.content.contentDate !== undefined) {
                cPostedDatez = DateTimeFormats(retResult.content.contentDate._seconds,"date-short");
            }
            var iconPub = <ToggleOffIcon className="iconGreyOff cursor-on mgR20" onClick={this.urlpublishContent.bind(this, retResult.docid,global.config.contentStatus[1])}/>;
            var iconEdit = <EditIcon className="iconGreyDis mgR20" title="edit" />;
            var iconDelete = <DeleteIcon className="iconGreyDis"/>;
            if(retResult.content.status === "Publish" ) { iconPub =  <ToggleOnIcon className="iconGrey cursor-on mgR20" onClick={this.urlpublishContent.bind(this, retResult.docid, global.config.contentStatus[2])}/> }
            if(retResult.content.createById === localStorage.getItem('utk')) { iconEdit = <a href={editURL}><EditIcon className="iconGrey mgR20" title="edit" /></a>; }
            if(retResult.content.status !== global.config.contentStatus[1]) { iconDelete = <DeleteIcon className="iconGrey cursor-on" onClick={this.urldeleteContent.bind(this, retResult.docid)}/>; }
            var typeNameVal="";
            for(var z=0; z<global.config.contentType.length; z++) {
                if(global.config.contentType[z].type === retResult.content.typeName) { typeNameVal = global.config.contentType[z].name; }
            }
            contentsList.push(<tr key={retResult.docid}>
                <th scope="row-field">{ls+1}</th>
                <td>{cPostedDatez}</td>
                <td>{typeNameVal}</td>
                <td>{retResult.content.contentTitle}</td>
                <td>
                    {iconEdit}
                    <a href={viewURL}><DescriptionIcon className="iconGrey mgR20" /></a>
                    {iconPub}
                    {iconDelete}
                </td>
              </tr>)
        }
    } else {
        contentsList.push(<tr key="1">
            <td colSpan="5" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
          </tr>)
    }

    var optType = [];
     for(var c=0; c<global.config.contentType.length; c++) {
        optType.push(<option value={global.config.contentType[c].type} key={global.config.contentType[c].id}>{global.config.contentType[c].name}</option>)
    }
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Content Insight</div>
                {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[5] === "ON") || (JSON.parse(localStorage.getItem('urole'))[6] === "ON"))? 
                <div>
                    <div className="stIndiTitle">List of Content Insight</div>
                    <div className="mgT30">
                        {/*--*/}
                        <div className="content-container card card-body">
                            <div className="row show">
                                <div className="col-6 divMiddle">
                                    <form method="post" id="search-form" className="form-inline" onSubmit={this.handleSubmit}>
                                        <div className="flex-container">
                                            <select name="select_type" className="form-control"
                                                    value={this.state.selectType} 
                                                    onChange={this.handleUserSelect}
                                                    ref={(input) => this.textInputContentType = input}
                                                >
                                                <option value="">-- All --</option>
                                                {optType}
                                            </select>
                                        </div>
                                        <div className="flex-container" style={{display:'none'}}>
                                            <input type="text" className="inputSearch mr-sm-2" name="keyword" id="keyword"
                                                placeholder="Search entries.."
                                                value={this.state.keyword}
                                                onChange={this.handleChange.bind(this)}/> 
                                            <SearchIcon style={{ fontSize: 35 }} className="iconGrey mgT05 cursor-on" onClick={this.handleSubmit}></SearchIcon>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-6 form-inline justify-content-end">
                                    <button className="genButton mgL20" onClick={urladdContents}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>Add New Content</button>
                                </div>
                            </div>
                            <div className="row-field mgT20">
                                <table className={this.state.styleTb}>
                                <thead>
                                    <tr key="0">
                                    <th scope="col">#</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Title</th>
                                    <th scope="col" style={{width:"180px"}}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contentsList}
                                </tbody>
                                </table>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <div className="flex-container" style={{display:'none'}}>
                                        <div className="mgR20 divMiddle">Showing</div>
                                        <div className="mgR20 divMiddle show">
                                            <select className="btn btn-secondary" name="pageShow" 
                                                value={this.state.pageShow} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputPageShow = input} >
                                                <option value="1">1</option>
                                                <option value="10">10</option>
                                                <option value="25">25</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </div>
                                        <div className="mgR20 divMiddle">list</div>
                                    </div>
                                </div>
                                <div className="col-6 form-inline justify-content-end">
                                    
                                </div>
                            </div>
                        </div>
                        {/*--*/}
                    </div>
                </div>
                :   
                <div><INA/></div>
                }
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div>
    </div>
   );
  }
}
 
export default IAddContents;