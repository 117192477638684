import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../cinc/cStyle.css';
import CheckLogin from '../cinc/ccekLogin';
import { FormErrors } from '../../inc/error-form';
import CheckBox from '../../inc/checkbox';
import DateTimeFormats from '../../inc/date-time-format';
import ReplaceAllContent from '../../inc/replace-all';
import CHeader from './cfragmentheader';
import CSidemenu from './cfragmentsidemenu';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const authKey = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[18];

class CReqTraining extends Component {
  constructor (props) {
    super(props);
    this.state = {
        memberdoc_id: localStorage.getItem('cudocid'),
        clientdoc_id: '',
        company_name: '',
        domain_name: '',
        client_country: '',
        product_type: '',
        request_event_date: '',
        request_attendance: '',
        request_level_user: [],
        request_subject_training: '',
        request_message: '',
        request_list: [],
        formErrors: {product_type: '',request_event_date: '',request_attendance: '', request_level_user: '', request_subject_training: '', request_message: ''},
        productTypeValid: false,
        requestEventDateValid: false,
        requestAttendanceValid: false,
        requestLevelUserValid: false,
        requestSubjectTrainingValid: false,
        formValid: false,
        errmsg: '',
        isSignin: false,
        hideNav: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  resize() {
    let currentHideNav = (window.innerWidth <= 480);
    if (currentHideNav !== this.state.hideNav) {
        this.setState({hideNav: currentHideNav});
    }
    if(currentHideNav === false) { 
      document.getElementById("sidemenu").style.display = "block";
    } else {
      document.getElementById("sidemenu").style.display = "none";
    }
  
  }
  urlmore() {
    window.location.href='/cviewreqtraining';
  }

  handleInputChecked = (e) => {
    var stat = false;
    const ulevels = this.state.request_level_user;
    ulevels.forEach(ulevel => {
       if (ulevel.value === e.target.value) { ulevel.isChecked =  e.target.checked; }
       if(ulevel.isChecked === true) { stat = true; }
    })
    this.setState({
      request_level_user: ulevels
    }, () => { 
      this.validateField("request_level_user", stat)
    })
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    const inputname = e.target.name;
    this.setState( prevState => {
      return {  
          ...prevState.product_type, product_type: value
      }
    }, () => { 
        this.validateField(inputname, value)
    }) 
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  } 
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let productTypeValid = this.state.productTypeValid;
    let requestEventDateValid = this.state.requestEventDateValid;
    let requestAttendanceValid = this.state.requestAttendanceValid;
    let requestLevelUserValid = this.state.requestLevelUserValid;
    let requestSubjectTrainingValid = this.state.requestSubjectTrainingValid;
    
    switch(fieldName) {
        case 'product_type': 
          productTypeValid = value.trim().length > 0;
          fieldValidationErrors.product_type = productTypeValid ? '' : 'product type need to be selected';
          break;
        case 'request_event_date': 
          requestEventDateValid = value.trim().length > 0;
          fieldValidationErrors.request_event_date = requestEventDateValid ? '' : 'estimated time for training need to be filled';
          break;
        case 'request_attendance': 
          requestAttendanceValid = value.trim().length > 0;
          fieldValidationErrors.request_attendance = requestAttendanceValid ? '' : 'number of attendance need to be choosed';
          break;
        case 'request_level_user': 
          requestLevelUserValid = value === true;
          fieldValidationErrors.request_level_user = requestLevelUserValid ? '' : 'level user need to be filled';
          break;
        case 'request_subject_training': 
          requestSubjectTrainingValid = value.trim().length > 0;
          fieldValidationErrors.request_subject_training = requestSubjectTrainingValid ? '' : 'training topic need to be filled';
          break;
        default:
            break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    productTypeValid: productTypeValid,
                    requestEventDateValid: requestEventDateValid,
                    requestAttendanceValid: requestAttendanceValid,
                    requestLevelUserValid: requestLevelUserValid,
                    requestSubjectTrainingValid: requestSubjectTrainingValid,
                }, this.validateForm);                            
  }
  validateForm() {
    this.setState({formValid: this.state.domainNameValid && this.state.licenceTypeValid && this.state.licenceAddSeatValid });
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  handleSubmit(e) {
    e.preventDefault();
    var statReqLevelUser = true;
    if(this.state.request_level_user[0].isChecked === false && this.state.request_level_user[1].isChecked === false) {
      statReqLevelUser = false;
    }
    if(this.state.product_type !== '' && this.state.request_event_date !== '' && this.state.request_attendance !== '' && this.state.request_subject_training !== '' && statReqLevelUser !== false) {
      document.getElementById('loading-area').style.display = 'block'; 
      var levelUser = '';
      for(var r=0; r<this.state.request_level_user.length; r++) {
          if(this.state.request_level_user[r].isChecked === true) { 
              if(levelUser !== '') { levelUser += ", "; }
              levelUser += this.state.request_level_user[r].value; 
          }
      }
      var req_message = this.state.request_message;
      if(req_message !== '') { req_message = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',req_message)); }
      
      var urlFetch, datatext;
      datatext ='{"member_id":"'+this.state.memberdoc_id+'","client_id":"'+this.state.clientdoc_id+'","client_name":"'+this.state.company_name+'","domain_name":"'+this.state.domain_name+'","client_country":"'+this.state.client_country+'","product_type":"'+this.state.product_type+'","request_event_date":"'+this.state.request_event_date+'","request_attendance":"'+this.state.request_attendance+'","request_level_user":"'+levelUser+'","request_subject_training":"'+this.state.request_subject_training+'","request_message":"'+req_message+'","form_id":"form-reqtraining"}';
      urlFetch = global.cconfig.urlLink+"/addreqtraining";
  
      var data = JSON.parse(datatext); 
      fetch(urlFetch, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }, body : JSON.stringify(data)
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          document.getElementById('form-content').style.display = 'none';
          if(resJson.status === '200') {
              document.getElementById('thankyou-page').style.display = 'block';
              document.getElementById('error-page').style.display = 'none';
          } else {
              document.getElementById('thankyou-page').style.display = 'none';
              document.getElementById('error-page').style.display = 'block';
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
          document.getElementById('form-content').style.display = 'none';
          document.getElementById('thankyou-page').style.display = 'none';
          document.getElementById('error-page').style.display = 'block';
        }) ;
    } else {
      var arrfieldName = ['product_type','request_event_date','request_attendance','request_level_user','request_subject_training'];
      var arrvalue = [this.textInputProductType.value,this.textInputRequestEventDate.value,this.state.request_attendance,statReqLevelUser,this.textInputSubjectTraining.value];
      for(var ck in arrfieldName) {
          this.validateField(arrfieldName[ck], arrvalue[ck]);
      }
    }
  }
  componentDidMount() {
    document.title = global.cconfig.webtitle;
    document.getElementById('loading-area').style.display = 'block'; 
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    CheckLogin().then((statL) =>{ 
      if(statL === true) {

        document.getElementById('form-content').style.display='block';
        var arrUserLevel = [];
        arrUserLevel = arrUserLevel.concat({'id':1,'fieldId':'ulevels_'+1,'value':'Admin','label':'Admin','isChecked': false,'isDisable': false});
        arrUserLevel = arrUserLevel.concat({'id':2,'fieldId':'ulevels_'+2,'value':'User','label':'User','isChecked': false,'isDisable': false});
        
        fetch(global.cconfig.urlLink+"/inforeqtraining/"+this.state.memberdoc_id, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') { 

            this.setState({
              memberdoc_id: resJson.values.memberData[0].docid,
              clientdoc_id: resJson.values.companyData[0].docid,
              company_name: resJson.values.companyData[0].content[0].maincontent.clientName,
              domain_name: resJson.values.companyData[0].content[0].maincontent.domainName,
              client_country: resJson.values.companyData[0].content[0].maincontent.clientCountry,
              product_type: '',
              request_event_date: '',
              request_attendance: '',
              request_level_user: arrUserLevel,
              request_subject_training: '',
              request_message: '',
              request_list: resJson.values.memberData[0].spcontent,
              formErrors: {product_type: '',request_event_date: '',request_attendance: '', request_level_user: '', request_subject_training: '', request_message: ''},
              productTypeValid: false,
              requestEventDateValid: false,
              requestAttendanceValid: false,
              requestLevelUserValid: false,
              requestSubjectTrainingValid: false,
              formValid: false,
              errmsg: '',
              isSignin: true
            });
          } else {
            document.getElementById('loading-area').style.display = 'none'; 
          }
        }).catch(err => { 
          document.getElementById('loading-area').style.display = 'none'; 
        });
      } else {
        window.location.href="/clogin";
      }
    });
  } 
  requestlist() {
    var arrRequestList = [];
    if(this.state.request_list.length > 0) {
      var limitlen = this.state.request_list.length;
      if(limitlen > 5) { limitlen = 5; } 
      for(var ls3=0; ls3<limitlen; ls3++) {
        var cDatez="";
        if(this.state.request_list[ls3].specialcontent.createDate !== undefined) {
            cDatez = DateTimeFormats(this.state.request_list[ls3].specialcontent.createDate._seconds,"datetime-long");
        }
        arrRequestList.push(<div className="list-request">
            <div key={ls3} className="detail-list w100p">
              <span className="title-request">{this.state.request_list[ls3].specialcontent.requestSubjectTraining}</span><br/>
              <span className="content-request">{this.state.request_list[ls3].specialcontent.productType}</span><br/>
              <span className="content-request">will be held <span className="textBold">{this.state.request_list[ls3].specialcontent.requestEventDate}</span> </span><br/>
              <span className="content-request">attandence <span className="textBold">{this.state.request_list[ls3].specialcontent.requestAttendance}</span> </span><br/>
              <span className="date-request">{cDatez} by {this.state.request_list[ls3].specialcontent.createByName}</span>
              <span className="smcontent-request divRight">status : <span className="mgL10" style={{fontWeight:"500", lineHeight:"1.2"}}>{this.state.request_list[ls3].specialcontent.requestStatus}</span></span>
            </div>
          </div>)
      }
      if(this.state.request_list.length > limitlen) {
        arrRequestList.push(<div key={ls3+1}>
            <div className="w100p divCenter more-request" onClick={this.urlmore.bind(this)}>
              more »
            </div>
          </div>)
      }
    } else {
      arrRequestList.push(<div className="list-request textCenter">
            <div key="0" className="detail-list w100p"><span className="content-request">no record</span></div>
          </div>)
    }
    return arrRequestList;
  }
  render() {
    var requestList = this.requestlist();

    var estParticipantList=["< 10 persons","10-50 persons","50-100 persons",">100 persons"];
    var arrParticipant=[];
    if(estParticipantList.length > 0) {
      for(var ls=0; ls<estParticipantList.length; ls++) {
        arrParticipant.push(<div key={ls}><label>
            <input type="radio" name="request_attendance" 
                value={estParticipantList[ls]}
                onChange={this.handleUserInput} /> 
            <span className="mgL10">{estParticipantList[ls]}</span>
          </label></div>
        )
      }
    }
   return (
    <div className="main-container flex-container cwrapper">
        <div id="sidemenu" className="csidebar">{(this.state.isSignin === true)? <CSidemenu showHideNav={this.state.hideNav}/> : '' }</div>
        <div className="w100p">
          <div className="cheader"><CHeader showHideNav={this.state.hideNav}/></div>
          <div className="cmain-content">
            {/* ====== */}
            {(this.state.isSignin === true)? 
            <div>
                <div className="stTitle">Training Session</div>
                <div className="stIndiTitle">Set up a training with our certified trainer</div>
                        
                <div className="mgT30">
                    <div className="row">
                      <div className="col-xl-8">
                        <div className="card card-main card-main-white">
                          <div id="error-page" className="divHide">
                            <div className="flex-container">
                                <CancelIcon className="iconRed"></CancelIcon>
                                <div className="mgL20 done-text">Failed to submit the request.</div>
                              </div>
                          </div>
                          <div id="thankyou-page" className="divHide">
                            <div className="flex-container">
                                <CheckCircleIcon className="iconGreen"></CheckCircleIcon>
                                <div className="mgL20 done-text">Thank you, your customer representative will contact you shortly.</div>
                              </div>
                          </div>
                          <div id="form-content">
                            <div className="label-title-card">Requirement</div>
                            <div id="FormCenter" className="mgT20">
                              <form onSubmit={this.handleSubmit}>
                                <div className="row-field mgT20">
                                  <label className="w220x" htmlFor="product_type">Product Type</label>
                                  <div>
                                    <select name="product_type" className="form-control" 
                                        value={this.state.product_type} 
                                        onChange={this.handleUserSelect}
                                        ref={(input) => this.textInputProductType = input}
                                    >
                                        <option value="">-- please select --</option>
                                        <option value="Google Workspace">Google Workspace</option>
                                        <option value="Microsoft 365">Microsoft 365</option>
                                        <option value="Asana">Asana</option>
                                        <option value="Google Cloud Platform">Google Cloud Platform</option>
                                        <option value="Others">Others</option>
                                    </select>
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.product_type}/>  }</div>
                                  </div>
                                </div>
                                <div className="row-field mgT20">
                                  <label className="w220x" htmlFor="request_event_date">Estimated time for training ?</label>
                                  <div className="w220x-min">
                                    <input type="text" className="form-control" name="request_event_date"
                                        placeholder={this.state.request_event_date}
                                        value={this.state.request_event_date}
                                        onChange={this.handleUserInput}  
                                        ref={(input) => this.textInputRequestEventDate = input}/>
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.request_event_date}/>  }</div>
                                    <div className="mgT10 notes-text">eg. mid July 2021, end of Aug 2021</div>
                                  </div>
                                </div>
                                <div className="row-field mgT20">
                                  <label className="w220x" htmlFor="request_attendance">Number of Participants ?</label>
                                  <div>
                                    {arrParticipant}
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.request_attendance}/>  }</div>
                                  </div>
                                </div>
                                <div className="row-field mgT20">
                                  <label className="w220x" htmlFor="request_level_user">Level User</label>
                                  <div>
                                    {
                                      this.state.request_level_user.map((ulevel, index) => {
                                        return (<CheckBox key={index} handleInputChecked={this.handleInputChecked}  {...ulevel} />)
                                      })
                                    }
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.request_level_user}/>  }</div>
                                  </div>
                                </div>
                                <div className="mgT20">
                                  <label htmlFor="request_subject_training">Training Topic</label>
                                  <div>
                                    <input type="text" className="form-control" name="request_subject_training"
                                        placeholder={this.state.request_subject_training}
                                        value={this.state.request_subject_training}
                                        onChange={this.handleUserInput}  
                                        ref={(input) => this.textInputSubjectTraining = input}/>
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.request_subject_training}/>  }</div>
                                  </div>
                                </div>
                                <div className="mgT20">
                                  <label htmlFor="request_message">Remarks</label>
                                  <div>
                                    <textarea name="request_message" className="form-control" 
                                      rows={5} cols={30} 
                                      value={this.state.request_message} 
                                      onChange={this.handleUserInput} 
                                      ref={(input) => this.textInputRequestMessage = input}/>
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.request_message}/>  }</div>
                                  </div>
                                </div>
                                <div className="mgT30"><button id="submit-button"  type="submit" className="submitButton">Request</button></div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="card card-main card-main-blue">
                          <div className="card-request">
                            <div className="label-title-card white-font">Request</div>
                            <div>
                              {requestList}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            : <div id="form-content"></div> }
          {/* ====== */}
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div> 
      </div>
    
   );
 }
}
 
export default CReqTraining;