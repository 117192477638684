import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../cinc/cStyle.css';
import CheckLogin from '../cinc/ccekLogin';
import DateTimeFormats from '../../inc/date-time-format';
import ReplaceAllContent from '../../inc/replace-all';
import defMemberPicture from '../../assets/pic-profile.png';
import NAimage from '../../assets/na.png';
import bgHome from "../../assets/bg_hpage.jpg";
import CHeader from './cfragmentheader';
import CSidemenu from './cfragmentsidemenu';

const authKey = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[15];
const authKey2 = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[28];
const authKey3 = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[29];

class CDashboard extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        memberdoc_id: localStorage.getItem('cudocid'),
        member_email: '',
        member_position: '',
        member_fullname: '',
        member_picture: '',
        member_phone: '',
        company_list: [],
        am_list: [],
        company_licence_list: [],
        company_request_list: [],
        news_list: [],
        isSignin: false,
        hideNav: '',
    }
  }
  resize() {
    let currentHideNav = (window.innerWidth <= 480);
    if (currentHideNav !== this.state.hideNav) {
        this.setState({hideNav: currentHideNav});
    }
    if(currentHideNav === false) {
      document.getElementById("sidemenu").style.display = "block";
    } else {
      document.getElementById("sidemenu").style.display = "none";
    }
  
  }
  urldetailContent(valid) {
    window.location.href='/cnewsview/'+valid;
  }
  urllicencedetail() {
    window.location.href='/cmyprofile';
  }
  urlrequestlist(url) {
    window.location.href=url;
  }
  componentDidMount() {
    document.title = global.cconfig.webtitle;
    document.getElementById('loading-area').style.display = 'block'; 
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    CheckLogin().then((statL) =>{ 
      if(statL === true) {
        var urlFetch = global.cconfig.urlLink+"/customerprofile/"+this.state.memberdoc_id;
        fetch(urlFetch, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') { 
              var memberPicture = '', memberPhone = '';
              if(resJson.values.memberData[0].content[0].maincontent.memberPicture !== undefined && resJson.values.memberData[0].content[0].maincontent.memberPicture !== '') {
                  memberPicture = resJson.values.memberData[0].content[0].maincontent.memberPicture;
              } else { memberPicture = defMemberPicture; }

              if(resJson.values.memberData[0].content[0].maincontent.memberPhone !== undefined && resJson.values.memberData[0].content[0].maincontent.memberPhone !== '') {
                memberPhone = resJson.values.memberData[0].content[0].maincontent.memberPhone;
              } 
              // ---- Start - GET LICENCE LIST ----------
              var arrLicList = [];
              var arrLicSortByDesc = [];
              for(var x=0; x<resJson.values.companyData.length; x++) {
                if(resJson.values.companyData[x].clientlicencelist.length > 0) {
                  for(var x1=0; x1<resJson.values.companyData[x].clientlicencelist.length; x1++) {
                    if(resJson.values.companyData[x].clientlicencelist[x1].clientliccontent.licenceStatus === "Active") {
                      arrLicList.push({
                        docid: resJson.values.companyData[x].clientlicencelist[x1].clientlicdocid,
                        expireddate: resJson.values.companyData[x].clientlicencelist[x1].clientliccontent.endPeriode,
                        content: resJson.values.companyData[x].clientlicencelist[x1].clientliccontent,
                        domainname: resJson.values.companyData[x].clientcontent.domainName
                      })
                    }
                  }
                }
              }
              if(arrLicList.length>0) {
                arrLicSortByDesc = arrLicList.sort((c,d) => {
                  return c.expireddate._seconds - d.expireddate._seconds;
                })
              }
              // ---- End - GET LICENCE LIST ----------
              // ----------
              var datatext2 ='{"keyword":"","pageShow":"3","filter1":[],"form_id":"pubonly"}';
              var data2 = JSON.parse(datatext2); 
              fetch(global.cconfig.urlLink+"/addcontentlist", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey2,
                  }, body : JSON.stringify(data2)
              }).then(res2 => {
                if(res2.status === 200) 
                    return res2.json() 
              }).then( resJson2 => {
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson2.status === '200') { 

                  var arrContentsList = [];
                  var arrContentSortByDesc = [];
                  if(resJson2.values.length > 0 && resJson2.status === '200') {
                      for(var v=0; v < resJson2.values.length; v++) {
                          arrContentsList.push({
                              docid: resJson2.values[v].docid,
                              content: resJson2.values[v].content,
                              contentdate: resJson2.values[v].content.contentDate
                          })
                      }
                      if(arrContentsList.length>0) {
                          arrContentSortByDesc = arrContentsList.sort((c,d) => {
                              return d.contentdate._seconds - c.contentdate._seconds;
                          })
                      }
                  } 

                  // ----------
                  var datatext3 ='{"keyword":"","pageShow":"3","type":"","memberId":"'+this.state.memberdoc_id+'","status":["'+global.cconfig.requestStatus[0]+'","'+global.cconfig.requestStatus[1]+'"]}';
                  var data3 = JSON.parse(datatext3); 
                  fetch(global.cconfig.urlLink+"/infoallrequest", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey3,
                      }, body : JSON.stringify(data3)
                  }).then(res3 => {
                    if(res3.status === 200) 
                        return res3.json() 
                  }).then( resJson3 => {
                    document.getElementById('loading-area').style.display = 'none'; 
                    if(resJson3.status === '200') { 
                      var arrReq = [];
                      var contentResult, contentType;
                      for(var y=0; y<5; y++) {
                        if(y===0) { contentResult= resJson3.values[0].content; contentType = "Add Licence"; }
                        else if(y===1) { contentResult= resJson3.values[0].content2; contentType = "Training Session"; }
                        else if(y===2) { contentResult= resJson3.values[0].content3; contentType = "Renewal Licence";}
                        else if(y===3) { contentResult= resJson3.values[0].content4; contentType = "Upgrade Licence";}
                        else if(y===4) { contentResult= resJson3.values[0].content5; contentType = "Claim GCP";}

                        if(contentResult !== undefined && contentResult.length > 0) {
                          for(var c1=0; c1<contentResult.length; c1++) {
                            arrReq.push({
                              docid: contentResult[c1].docid,
                              createdate: contentResult[c1].createdate,
                              content: contentResult[c1].content,
                              type: contentType
                            });
                          }
                        }

                      }
                      let arrReqSortByDesc = arrReq.sort((a,b) => {
                        return b.createdate._seconds - a.createdate._seconds;
                      })
                      this.setState({
                        stTitle: 'Detail Member',
                        memberdoc_id: resJson.values.memberData[0].docid,
                        member_email: resJson.values.memberData[0].content[0].maincontent.memberEmail,
                        member_position: resJson.values.memberData[0].content[0].maincontent.memberPosition,
                        member_fullname: resJson.values.memberData[0].content[0].maincontent.memberFullname,
                        member_picture: memberPicture,
                        member_phone: memberPhone,
                        company_list: resJson.values.companyData,
                        am_list: resJson.values.amData,
                        company_licence_list: arrLicSortByDesc,
                        company_request_list: arrReqSortByDesc,
                        news_list: arrContentSortByDesc,
                        isSignin: true
                      }) 
                    } else {
                      this.setState({
                        stTitle: 'Detail Member',
                        memberdoc_id: resJson.values.memberData[0].docid,
                        member_email: resJson.values.memberData[0].content[0].maincontent.memberEmail,
                        member_position: resJson.values.memberData[0].content[0].maincontent.memberPosition,
                        member_fullname: resJson.values.memberData[0].content[0].maincontent.memberFullname,
                        member_picture: memberPicture,
                        member_phone: memberPhone,
                        company_list: resJson.values.companyData,
                        am_list: resJson.values.amData,
                        company_licence_list: [],
                        company_request_list: [],
                        news_list: arrContentSortByDesc,
                        isSignin: true
                      }) 
                    }
                  }).catch(err => { 
                    document.getElementById('loading-area').style.display = 'none'; 
                  });

                } else {
                  this.setState({
                    stTitle: 'Detail Member',
                    memberdoc_id: resJson.values.memberData[0].docid,
                    member_email: resJson.values.memberData[0].content[0].maincontent.memberEmail,
                    member_position: resJson.values.memberData[0].content[0].maincontent.memberPosition,
                    member_fullname: resJson.values.memberData[0].content[0].maincontent.memberFullname,
                    member_picture: memberPicture,
                    member_phone: memberPhone,
                    company_list: resJson.values.companyData,
                    am_list: resJson.values.amData,
                    company_licence_list: [],
                    company_request_list: [],
                    news_list: [],
                    isSignin: true
                  }) 
                }
              }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
              });
          } else {
              document.getElementById('loading-area').style.display = 'none'; 
          }
        }).catch(err => { 
          document.getElementById('loading-area').style.display = 'none'; 
        })
      } else {
        window.location.href="/clogin";
      }
    })
 } 

 licviewlist() {
  var arrlicenceViewList = [];
  var arrcompanyList = [];
  if(this.state.company_list.length > 0) {
    for(var ls=0; ls < this.state.company_list.length; ls++) {
      var retResult = this.state.company_list[ls];
      var company_name = retResult.clientcontent.clientName;
      var domain_name = retResult.clientcontent.domainName;
      if(this.state.company_list.length >1) {
        arrcompanyList.push(<div key={ls+1}>
                {(ls===0)? <div className="textBold">Organization Name</div> : '' }
                <div className="mgT05">{company_name} <span className="sub-font mgL10">({domain_name})</span></div>
                {(ls===(this.state.company_list.length-1))?
                <div className="mgT05"><a href="/cmyprofile" style={{color:"#1E73BE", fontSize:"14px", fontWeight:"bold", whiteSpace:"nowrap"}}>View My Subscription </a><span> • </span><a href="/ccontact" style={{color:"#1E73BE", fontSize:"14px", fontWeight:"bold", whiteSpace:"nowrap"}}> Contact Support</a></div>
                : '' }
            </div>)
      } else {
      arrcompanyList.push(<div key={ls+1}>
                    <p><strong>Organization Name:</strong> {company_name}</p>
                    <p><strong>Domain Name:</strong> {domain_name}</p>
                    <p><a href="/cmyprofile" style={{color:"#1E73BE", fontSize:"14px", fontWeight:"bold", whiteSpace:"nowrap"}}>View My Subscription </a><span> • </span><a href="/ccontact" style={{color:"#1E73BE", fontSize:"14px", fontWeight:"bold", whiteSpace:"nowrap"}}> Contact Support</a></p>
                  </div>);
      }
    }
  }
  if(this.state.company_licence_list !== null && this.state.company_licence_list.length > 0) {
    for(var ls2=0; ls2 < this.state.company_licence_list.length; ls2++) {
      var retResult2 = this.state.company_licence_list[ls2];
      var exp_datez = '';
      if(retResult2.content.endPeriode !== undefined) {
        exp_datez = DateTimeFormats(retResult2.content.endPeriode._seconds,"date-short");
      }
      var ky2 = "B"+ls+ls2;
      arrlicenceViewList.push(<li key={ky2} className="row">
        <div className="col-9" onClick={this.urllicencedetail.bind(this)}>
          <p className="licence"><strong>{retResult2.content.licenceType}</strong></p>
          <p className="expire">in {retResult2.domainname}</p>
          <p className="expire">Expired in {exp_datez} </p>
        </div>
        <div className="col-3"></div>
      </li>);
    }
  } 
  return [arrcompanyList,arrlicenceViewList];
 } 

 requestviewlist() {
  var arrrequestViewList = [];
  if(this.state.company_request_list !== null && this.state.company_request_list.length > 0) {
    var els3 = this.state.company_request_list.length; if(els3 > 6) { els3 = 6; }
    for(var ls3=0; ls3 < els3; ls3++) {
      var retResult3 = this.state.company_request_list[ls3];
      var cDatez3 = "", cTitle3="", cType3="",cUrl3="";
      if(retResult3.createdate !== undefined) {
          cDatez3 = DateTimeFormats(retResult3.createdate._seconds,"date-short");
      }
      cType3 = retResult3.type;
      if(retResult3.type !== undefined) { 
        if(retResult3.type === "Add Licence") {
          cTitle3 = retResult3.content.licenceType; 
          cUrl3 = "/cviewaddlicence";
        } else if(retResult3.type === "Renewal Licence") {
          cTitle3 = retResult3.content.licenceType; 
          cUrl3 = "/cviewrenewlicence";
        } else if(retResult3.type === "Upgrade Licence") {
          cTitle3 = retResult3.content.licenceType; 
          cUrl3 = "/cviewupgrade";
        } else if(retResult3.type === "Claim GCP") {
          cTitle3 = retResult3.content.domainName; 
          cUrl3 = "/cviewclaim";
        } else if(retResult3.type === "Training Session") {
          cTitle3 = retResult3.content.requestSubjectTraining;
          cUrl3 = "/cviewreqtraining";
        }
      }
      var ky3 = "C"+ls3;
      arrrequestViewList.push(<li key={ky3} className="row">
          <div className="col" onClick={this.urlrequestlist.bind(this,cUrl3)}>
            <p className="expire">{cType3}</p>
            <p className="licence"><strong>{cTitle3}</strong></p>
            <p className="expire">Submitted in {cDatez3}</p>
          </div>
        </li>);
    }
  } else {
    arrrequestViewList.push(<li key="C0" className="row">
        <div className="divCenter w100p"> no record </div>
      </li>);
  }
  return arrrequestViewList;
 } 

 newsviewlist() {
  var arrnewsViewList = [];
  if(this.state.news_list !== null && this.state.news_list.length > 0) {
    var els4 = this.state.news_list.length; if(els4 > 3) { els4 = 3; }
    for(var ls4=0; ls4 < els4; ls4++) {
      var retResult4 = this.state.news_list[ls4];
      var cPostedDatez="", typesName="", cTitle="", cTitleSlug="", cShortDesc="",cPicture="";
      if(retResult4.content.contentDate !== undefined) {
        cPostedDatez = DateTimeFormats(retResult4.content.contentDate._seconds,"date-short");
      }
      if(retResult4.content.contentTitle !== undefined) { 
        cTitle = retResult4.content.contentTitle;
        cTitleSlug = ReplaceAllContent(' ','-',cTitle); 
      }
      if(retResult4.content.contentShortDesc !== undefined) {
        cShortDesc = ReplaceAllContent('[;]',',',retResult4.content.contentShortDesc);
        cShortDesc = ReplaceAllContent('<br/>','\n',cShortDesc);
      }
      if(retResult4.content.contentPicture !== undefined && retResult4.content.contentPicture !== "") { 
        cPicture = retResult4.content.contentPicture;
      } else { cPicture = NAimage; }
      for(var t=0; t<3; t++) {
        if(retResult4.content.typeName === global.cconfig.contentType[t].type) { typesName=global.cconfig.contentType[t].name; }
      }
      
      var ky4 = "D"+ls4;
      arrnewsViewList.push(<li key={ky4} onClick={this.urldetailContent.bind(this, retResult4.docid)}>
        <p className="news-title">{cTitle}</p>
        <p className="news-info">{cPostedDatez} in {typesName}</p>
        <p className="news-thumbnail"><img alt={cTitleSlug} style={{maxHeight:"250px",maxWidth:"400px"}} src={cPicture}/></p>
        <p className="news-content-thumbnail">{cShortDesc}</p>
      </li>);
      
    }
  } else {
    arrnewsViewList.push(<li key="D0" className="row">
        <div className="divCenter w100p"> no record </div>
      </li>);
  }
  return arrnewsViewList;
 } 


 render() {
  var companyViewList = this.licviewlist()[0];
  var licenceViewList = this.licviewlist()[1];
  var newsViewList = this.newsviewlist();
  var requestViewList = this.requestviewlist();
   return (
    
      <div className="main-container flex-container cwrapper">
        <div id="sidemenu"  className="csidebar">{(this.state.isSignin === true)?<CSidemenu showHideNav={this.state.hideNav}/>:''}</div>
        <div className="w100p">
          <div className="cheader"><CHeader showHideNav={this.state.hideNav}/></div>
          <div className="cmain-content">
          {(this.state.isSignin === true)? 
            <div>
              <div className="general-card">
                <div className="scenery" style={{backgroundImage: `url(${bgHome})`}}></div>
                <div className="row">
                  <div className="user-photo col-xl-2 col-md-12" style={{backgroundImage: `url(${this.state.member_picture})`}}></div>
                  <div className="row col-xl-10 col-md-12">
                    <div className="user-info">
                      {companyViewList}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-4 col-md-12 col-sm-12 widget-container-left">
                  <div className="widget-expiration">
                    <h3>Active Licence</h3>
                    <ul className="items">
                      {licenceViewList}
                    </ul>
                  </div>
                  <div className="widget-submission">
                    <h3>Request Submission</h3>
                    <ul className="items">
                      {requestViewList}
                    </ul>
                  </div>
                </div>
                <div className="col-md-12 col-xl-8">
                  <div className="widget-news">
                    <h3>Recent Articles</h3>
                    <ul className="items">
                      {newsViewList}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          : '' }
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div>
      </div>
   );
 }
}
 
export default CDashboard;