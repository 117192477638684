import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iGlobal';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import DateTimeFormats from '../../inc/date-time-format';
import Popup from '../../inc/popup';
import '../../inc/popup.css'
import SubscribeDetail from './ipopusersubscribe-detail';
import PurchaseDetail from './ipopuserpurchase-detail';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[13];

class IPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        styleTb: 'table',
        status_payment: 'Verifying',
        data: [],
        isOpen: false,
        otherContent: "",
        isOpen2: false,
        otherContent2: ""
    };
    this.loadData = this.loadData.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  loadData(current_status) {
    this.setState({
      data: []
    }, () => {
      document.getElementById('loading-area').style.display = 'block'; 
      var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'"}';
      var datasend = JSON.parse(datatext); 
      fetch(global.config.urlLink+"/traininguserlist", {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }, body : JSON.stringify(datasend)
      }).then(response => {
          if(response.status === 200) {
              return response.json()
          } else {
              console.log('Something happened wrong');
          }
      }).then(resJson => {
        resJson.values.forEach(content => {
          var memberId = content.docid;
          var memberName = content.content.memberName;
          var memberEmail = content.content.memberEmail;
          fetch(global.config.urlLink+"/traininguserview/" + memberId, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
            }
          }).then(response2 => {
              if(response2.status === 200) {
                  return response2.json()
              } else {
                  console.log('Something happened wrong');
              }
          }).then(resJson2 => {
              document.getElementById('loading-area').style.display = 'none'; 
              var styleTb = 'table';
              if(resJson2.status === '200' && resJson2.values.length > 0) { styleTb = 'table table-striped'; } 
              this.setState({
                  styleTb: styleTb
              });
              var content = resJson2.values[0].content[0].maincontent;
              var subscribes = resJson2.values[0].scontent
              var purchases = resJson2.values[0].scontent2;
              if (subscribes.length > 0) {
                subscribes.forEach(subscribe => {
                  var subscribe_status = '';
                  if (subscribe.subcontent.subscribeStatus === 'New Request') {
                    if (subscribe.subcontent.paymentConfirmationDate) {
                      subscribe_status = 'Verifying';
                    } else {
                      subscribe_status = 'New';
                    }
                  } else {
                    subscribe_status = 'Completed';
                  }
                  if (subscribe_status === current_status) {
                    var data = {
                      'memberid': memberId,
                      'membername': memberName,
                      'memberemail': memberEmail,
                      'subdocid': subscribe.subdocid,
                      'order_date': subscribe.subcontent.requestSubscribeDate._seconds,
                      'type': 'Subscribe',
                      'type_info': subscribe.subcontent.subscribePlan,
                      'status': subscribe_status,
                      'member_name': content.memberName,
                      'payment_method': subscribe.subcontent.paymentMethod,
                      'payment_info': subscribe.subcontent.paymentInfo.replace('<br/>', ' '),
                      'amount': subscribe.subcontent.subscribePrice,
                      'confirmation_date': subscribe.subcontent.paymentConfirmationDate ? subscribe.subcontent.paymentConfirmationDate._seconds : ''
                    }
                    this.state.data.push(data);
                  }
                });
              }
              if (purchases.length > 0) {
                purchases.forEach(purchase => {
                  var purchase_status = '';
                  if (purchase.subcontent.purchaseStatus === 'New Purchase') {
                    if (purchase.subcontent.paymentConfirmationDate) {
                      purchase_status = 'Verifying';
                    } else {
                      purchase_status = 'New';
                    }
                  } else {
                    purchase_status = 'Completed';
                  }
                  if (purchase_status === current_status) {
                    var data = {
                      'memberid': memberId,
                      'membername': memberName,
                      'memberemail': memberEmail,
                      'subdocid': purchase.subdocid,
                      'order_date': purchase.subcontent.purchaseDate._seconds,
                      'type': 'Workshop',
                      'type_info': purchase.subcontent.courseTitle,
                      'status': purchase_status,
                      'member_name': content.memberName,
                      'payment_method': purchase.subcontent.paymentMethod,
                      'payment_info': purchase.subcontent.paymentInfo.replace('<br/>', ' '),
                      'amount': purchase.subcontent.purchasePrice,
                      'confirmation_date': purchase.subcontent.paymentConfirmationDate ? purchase.subcontent.paymentConfirmationDate._seconds : ''
                    }
                    this.state.data.push(data);
                  }
                });
              }
          });
        });
      }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
      });
    });
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    this.setState({
      status_payment: value
    });
    this.loadData(value);
  }
  handleSubmit(e) {
    e.preventDefault();
    this.loadData(this.state.status_payment);
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen, isOpen: false,
      }
    })
  }
  togglePopClose2() {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen2, isOpen2: false,
      }
    })
  }
  urldetailSubscribe(valId, memberId, name, email) {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen, isOpen: true,
        ...prevState.otherContent, otherContent: '{"subscribe_id":"'+valId+'","member_id":"'+memberId+'","member_fullname":"'+name+'","member_email":"'+email+'"}'
      }
    })
  }
  urldetailPurchase(valId, memberId, name, email) {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen2, isOpen2: true,
        ...prevState.otherContent2, otherContent2: '{"purchase_id":"'+valId+'","member_id":"'+memberId+'","member_fullname":"'+name+'","member_email":"'+email+'"}'
      }
    })
  }
  componentDidMount() {
    this.loadData(this.state.status_payment);
 }
 render() {
    var paymentList = [];
    if(this.state.data.length > 0) {
        this.state.data.sort((a, b) => a.order_date - b.order_date);
        for(var ls=0; ls < this.state.data.length; ls++) {
            var retResult = this.state.data[ls];
            paymentList.push(
              <tr key={ls+1}>
                <th scope="row">{ls+1}</th>
                <td>{DateTimeFormats(retResult.order_date,"date-long")}</td>
                <td>{retResult.type}</td>
                <td>{retResult.member_name}</td>
                <td>{retResult.payment_method}<br/><span className="font-sublist">{retResult.payment_info}</span></td>
                <td>IDR {retResult.amount.toLocaleString('en-US',{maximumFractionDigits:2})}</td>
                <td>
                  {
                    retResult.type === 'Subscribe' ? (
                      <RemoveRedEyeIcon className="iconGrey cursor-on" onClick={this.urldetailSubscribe.bind(this, retResult.subdocid, retResult.memberid, retResult.membername, retResult.memberemail)}></RemoveRedEyeIcon>
                    ) : (
                      <RemoveRedEyeIcon className="iconGrey cursor-on" onClick={this.urldetailPurchase.bind(this, retResult.subdocid, retResult.memberid, retResult.membername, retResult.memberemail)}></RemoveRedEyeIcon>
                    )
                  }
                </td>
              </tr>
            );
        }
    } else {
        paymentList.push(
          <tr key="1">
            <td colSpan="8" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
          </tr>
        );
    }
    
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
        <div className="flex-container">
          <div className="sidebar-main"><ISidemenu/></div>
          <div className="main-content">
            <div className="stTitle">Payment List</div>
            <div className="stIndiTitle">List of payments to be verified</div>
            <div className="mgT30">
              {/*--*/}
              <div className="content-container card card-body">
                <div className="row show">
                  <div className="col-6 divMiddle">
                    <form method="post" id="search-form" className="form-inline" onSubmit={this.handleSubmit}>
                      <div className="flex-container" >
                        <select name="status_payment" className="form-control" 
                          value={this.state.status_payment} 
                          onChange={this.handleUserSelect}
                          ref={(input) => this.textInputStatusPayment = input}
                        >
                          <option value="New" key="0">New</option>
                          <option value="Verifying" key="1">Verifying</option>
                          <option value="Completed" key="2">Completed</option>
                        </select>
                      </div>
                    </form>
                  </div>
                  <div className="col-6 form-inline justify-content-end">
                  </div>
                </div>
                <div className="row show">
                  <div className="col-6 divMiddle">  
                  </div>
                  <div className="col-6 form-inline justify-content-end">
                  </div>
                </div>
                <div className="row mgT20">
                  <table className={this.state.styleTb}>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Order Date</th>
                        <th scope="col">Type</th>
                        <th scope="col">Member Name</th>
                        <th scope="col">Payment Method</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentList}
                    </tbody>
                  </table>
                </div>
                <div className="row mt-3">
                  <div className="col-6">                                
                  </div>
                  <div className="col-6 form-inline justify-content-end">                                
                  </div>
                </div>
              </div>
              {/*--*/}
              {
                this.state.isOpen && <Popup 
                  content={<SubscribeDetail/>}
                  title={"History User Subscription"}
                  others={this.state.otherContent}
                  styles={"70%"}
                  handleClose={this.togglePopClose.bind(this)}
                />
              }
              {
                this.state.isOpen2 && <Popup
                  content={<PurchaseDetail/>}
                  title={"History User Purchase"}
                  others={this.state.otherContent2}
                  styles={"70%"}
                  handleClose={this.togglePopClose2.bind(this)}
                />
              }
            </div>
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div>
      </div>
    );
  }
}
 
export default IPayment;