let Credentials = require('./iOACredentials.json');
let sessionUToken = "";
let vers = "lcin";
if(window.location.hostname === "internal.point-star.com") { vers = "lvin"; } 
if(localStorage.getItem('utk') !== null) { sessionUToken = localStorage.getItem('utk'); }
module.exports = global.config = {
    urlLink: 'https://us-central1-ps-product-internalportal-api.cloudfunctions.net/api',
    timezone: 'en-GB',
    datetimeoption: '{"year":"numeric", "month": "long", "day": "2-digit", "hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": false, "timeZone": "Asia/Jakarta"}',
    authBearerKey: sessionUToken+"##"+Credentials.web.client_secret+"##"+vers,
    credClientId: Credentials.web.client_id,
    credApiKey: Credentials.web.api_key,
    zteAPIKey: "AIzaSyCNP1hZLujUa7ZbvykTK5R5L1CycOBXTOw",
    userlevel: ["Admin","User"],
    userregion: ["Singapore","Malaysia","Indonesia"],
    userrole: ["Finance","Renewals","Training Booking - Content", "Training Booking - Finance", "Training Booking - Admin", "Customer Portal - Admin", "Customer Portal - Content Editor", "Account Executive","ZTE"],
    kindcode: [
        "31G785","98U109","56T428","78Y851","28R393","93I200","52T892","62F210","81J037","73P159",
        "18J332","74G679","52K024","93Y210","28H193","53J268","45K027","82Y167","14K330","73M865",
        "26K321","94H190","85E180","06Y478","48B217","27S509","36V185","25M411","50P271","10K732",
        "75W369","88L352","17U196","44P737","61L385","38S299","66F165","34Q284","77W190","2Y8KH0",
        "90K114","98E213","","","","52I768","J3R201","02U134"
    ],
    licenceStatus: ["New","Active","Deactive","Expired","Upgrade","Downgrade"],
    licenceRenewStatus: ["New Request","Active","Stop Subscription","Cancel","Upgrade","Downgrade"],
    licenceRenewPaymentStatus: ["New Bill","On Progress","Paid","Cancel"],
    licenceNotif: [
        "System has not been send email notification",
        "System has sent an email notification as reminder 2 months before expiration ",
        "System has sent an email notification as reminder 1 month before expiration ",
        "System has sent an email notification as reminder 3 weeks before expiration ",
        "System has sent an email notification as reminder 1 week before expiration ",
        "System has sent an expired email notification",
        "Email notification has been stopped"
    ],
    typeLicenceAddon: "Google Workspace Business Starter",
    addonLicence: ["Vault","Google Drive - 20GB","Google Drive - 50GB","Google Drive - 200GB","Google Drive - 400GB","Google Drive - 1TB","Google Drive - 2TB","Google Drive - 4TB","Google Drive - 8TB","Google Drive - 16TB"],
    categoryLicence: ["Adobe","AODocs","AppSheet","Backupify","BetterCloud","Chrome","Cloud Identity Free","G Suite","Google Workspace","Jamboard","Meraki","M365","O365","Power BI","Support","Trend Micro"],
    zteType: ["Register Enrollment","Delete Enrollment"],
    requestStatus: ["New Request", "On Progress", "Completed", "Cancel"],
    contentStatus: ["Draft","Publish","Unpublish"],
    contentType: [
        {id:1, type:"news-info",name:"News, Info & Updates"},
        {id:2, type:"promo",name:"Promo"},
        {id:3, type:"announcement",name:"Events & Announcement"},
        
    ],
    libraryStatus: ["Draft","Publish","Unpublish"],
    courseStatus: ["Draft","Publish","Unpublish"],
    subscribeStatus: ["New Request","Active","Expired","Cancel"],
    // subscribePlan: [
    //     {name:"Monthly",period:1,type:"month",price:[25000,6.25],symbolCurrency:["Rp ","$ "],currency:["IDR","USD"]},
    //     {name:"Yearly",period:1,type:"year",price:[100000,25],symbolCurrency:["Rp ","$ "],currency:["IDR","USD"]}
    // ],
    subscribePlan: [
        {name:"Monthly",period:1,type:"month",price:[30000,2],symbolCurrency:["Rp ","$ "],currency:["IDR","USD"]},
        {name:"Yearly",period:1,type:"year",price:[300000,20],symbolCurrency:["Rp ","$ "],currency:["IDR","USD"]}
    ],
    subscribeMemberStatus: ["No Subscribe","On Process","Subscribe","Expired"],
}