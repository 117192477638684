import React, { Component } from 'react';
import '../../inc/genstyle.css';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
 
class IUC extends Component {
 constructor(props) {
   super(props);
   this.state = {
     done: undefined
   };
 }
 render() {
   return (
    <div className="divCenter mgT50">

      <div><ReportProblemIcon className="iconGrey mgR20" style={{ fontSize: 120 }}></ReportProblemIcon></div>
      <div className="heading1 fontGrey">Forbidden Access</div>
      <div className="mgT20 fontGrey textCenter">You don't have permission to access this page.</div>
      <div className="mgT05 fontGrey textCenter">Please contact administrator to request the access.</div>
    </div>
   );
 }
}
 
export default IUC;