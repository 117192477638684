import React, { Component } from 'react';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import '../../inc/popup.css'
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import DateTimeFormats from '../../inc/date-time-format';
import ReplaceAllContent from '../../inc/replace-all';
import NAimage from '../../assets/na.png';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[35];

class ITutorDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stTitle: '',
            tutor_docid: '',
            tutor_name: '',
            tutor_email: '',
            tutor_bio: '',
            tutor_linkedin: '',
            tutor_picture: ''
        }
        this.loadDetailData = this.loadDetailData.bind(this);
    }
    loadDetailData(element,id) {
        document.getElementById('loading-area').style.display = 'block';
        fetch(global.config.urlLink+"/tutorview/"+id, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }
            }).then(res => {
            if(res.status === 200) 
                return res.json() 
            }).then( resJson => {
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') { 
                var lName,lEmail,lBio,lLinkedin,lPicture,cDate;
                var resultContent = resJson.values[0].content[0].maincontent;
                
                if(resJson.values[0].content[0].createdate !== undefined) {
                    cDate = DateTimeFormats(resJson.values[0].content[0].createdate._seconds,"datetime-short");
                }
                if(resultContent.tutorName !== undefined) { 
                    lName = resultContent.tutorName; 
                }
                if(resultContent.tutorEmail !== undefined) { 
                    lEmail = resultContent.tutorEmail; 
                }
                if(resultContent.tutorBio !== undefined) {
                    lBio = ReplaceAllContent('[;]',',',resultContent.tutorBio);
                    lBio = ReplaceAllContent('<br/>','\n',lBio);
                }
                if(resultContent.tutorLinkedin !== undefined) { 
                    lLinkedin = resultContent.tutorLinkedin; 
                }
                if(resultContent.tutorPicture !== undefined && resultContent.tutorPicture !== "") { 
                    lPicture = resultContent.tutorPicture;
                } else { 
                    lPicture = NAimage; 
                }
                this.setState({
                    stTitle: 'Detail Trainer',
                    tutor_docid: resJson.values[0].docid,
                    tutor_createby: resJson.values[0].content[0].maincontent.createByName,
                    tutor_createdate: cDate,
                    tutor_name: lName,
                    tutor_email: lEmail,
                    tutor_bio: lBio,
                    tutor_linkedin: lLinkedin,
                    tutor_picture: lPicture
                }); 
            } else {
                console.log('Something happened wrong');
            }
        }).catch(err => { 
            document.getElementById('loading-area').style.display = 'none'; 
        });
    }

    handleBack = () => {
      this.props.history.goBack();
    }

    componentDidMount() {
        var geturl = this.props.location; 
        var arrString = geturl.pathname;
        var arrUrl = [];
        arrUrl = arrString.split('/');
        
        if(arrUrl[1] === 'IViewTutor') {
            if(arrUrl[2] !== '') { 
                this.loadDetailData(arrUrl[1],arrUrl[2]);
            }
        }
    }

    render() {
        return (
            <div className="main-container">
                <div  className="header-main"><IHeader/></div>
                <div className="flex-container">
                    <div className="sidebar-main"><ISidemenu/></div>
                    <div className="main-content">
                        <div className="stTitle">Trainers</div>
                        {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[2] === "ON") || (JSON.parse(localStorage.getItem('urole'))[3] === "ON")  || (JSON.parse(localStorage.getItem('urole'))[4] === "ON"))? 
                        <div>
                            <div className="stIndiTitle">Trainers { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                            <div className="mgT30">
                                {/* ====== */}
                                <div className="content-container card card-body">  
                                    <div className="row-field">
                                        <div className="w80p">
                                            <h4>{this.state.tutor_name}</h4>
                                        </div>
                                        <div className="divRight w20p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                                    </div>
                                    <div className="divRight">
                                    <div className="detailJoined"><span>Created By : {this.state.tutor_createby}</span> <span className="mgL20">On : {this.state.tutor_createdate}</span></div>
                                    </div>          
                                    <div className="mgT30">
                                        <div className="mgT20 flex-container">
                                            <div className="w20p"><h6>Name</h6></div>
                                            <div className="w80p"><span className="mgR10">:</span>{this.state.tutor_name}</div>
                                        </div>
                                        <div className="mgT05 flex-container">
                                            <div className="w20p"><h6>Email</h6></div>
                                            <div className="w80p"><span className="mgR10">:</span>{this.state.tutor_email}</div>
                                        </div>
                                        <div className="mgT30">
                                            <div><h6>Bio :</h6></div>
                                            <div>{this.state.tutor_bio}</div>
                                        </div>
                                        <div className="mgT30 flex-container">
                                            <div className="w20p"><h6>Linkedin</h6></div>
                                            <div className="w80p"><span className="mgR10">:</span>{this.state.tutor_linkedin}</div>
                                        </div>
                                        <div className="mgT30">
                                            <div><h6>Picture :</h6></div>
                                            <div><img width="150" src={this.state.tutor_picture} alt=""/></div>
                                        </div>
                                    </div>
                                    <div id="loading-area" className="divHide loading"></div> 
                                </div>
                                {/* ====== */}
                            </div>
                        </div>
                        : 
                        <div><INA/></div>
                        }
                    </div>
                </div>
                <div id="loading-area" className="divHide loading"></div>
            </div>
          );
        }
     }
      
export default ITutorDetail;
