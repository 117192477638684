import React, { Component } from 'react';
import '../../inc/genstyle.css';
import DateTimeFormats from '../../inc/date-time-format';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[30];

class ZTEList extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            contentoth: this.props.linksurl,
            styleTb: 'table',
            pageShow: '10',
            keyword: '',
            data: [],
            copySuccess: '',
            link1_url: ''
        }
    }
    copyToClipboard(ln) {
        var contentSelect = ln;
        navigator.clipboard.writeText(contentSelect);
        this.setState({ copySuccess: 'Copied!' });
    };
    urlopenFile(ln) {
        window.open(ln);
    }
    componentDidMount() {
        document.getElementById('loading-area').style.display = 'block'; 
        var from_set = "zte";
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        if(othersContent.usage !== undefined && othersContent.usage === "deletezte") {
            from_set = "ztedel"; 
        } else { from_set = "zte"; }
        var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'","fromset":"'+from_set+'"}';
        var data = JSON.parse(datatext); 
        fetch(global.config.urlLink+"/ztelist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }, body : JSON.stringify(data)
          }).then(res => {
            if(res.status === 200) 
                return res.json() 
          }).then( resJson => {
            document.getElementById('loading-area').style.display = 'none'; 
            this.setState({
                data: resJson,
                otherContent: ''
            })
          }).catch(err => { 
            document.getElementById('loading-area').style.display = 'none'; 
          }); 
    }
    render() {
        var fileList = [];
        if(this.state.data.values.length > 0 && this.state.data.status === '200') {
            for(var ls=0; ls < this.state.data.values.length; ls++) {
                var retResult = this.state.data.values[ls];
                var cDatez = "";
                if(retResult.content.createDate !== undefined) {
                    cDatez = DateTimeFormats(retResult.content.createDate._seconds,"date-short");
                }
                fileList.push(<tr key={retResult.docid}>
                    <th scope="row-field">{ls+1}</th>
                    <td>
                        <div>{retResult.content.filename}</div>
                        <div className="mgT05">( {cDatez} )</div>
                        
                    </td>
                    <td onClick={this.copyToClipboard.bind(this,retResult.content.filelink)}>
                        <div id="fileurl" className="textWrap cursor-on" style={{width:"430px"}}>{retResult.content.filelink}</div>
                        <div className="pickerButton divCenter mgT10" style={{width:"120px"}}>copy this link</div>
                    </td>
                    <td style={{width:'50px'}}>
                        <OpenInNewIcon className="iconGrey mgR20 cursor-on" onClick={this.urlopenFile.bind(this, retResult.content.filelink)}/>
                    </td>
                  </tr>)
            }
        } else {
            fileList.push(<tr key="1">
                <td colSpan="4" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
              </tr>)
        }
        return (
            <div className="main-container">
                <div className="mgT20">
                    <div className="content-container card card-body">
                        
                        <div className="row-field mgT20">
                            <table className={this.state.styleTb}>
                            <thead>
                                <tr key="0">
                                <th scope="col">#</th>
                                <th scope="col">File Name</th>
                                <th scope="col">Link</th>
                                <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {fileList}
                            </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
        )
    }
}

export default ZTEList;