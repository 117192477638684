/* global gapi */
import React from 'react';
import '../iinc/iGlobal';
import '../../inc/genstyle.css';
import logoPS from '../../assets/logo-ps.png';
import IDashboard from './ihomepage';
import Alert from '@material-ui/lab/Alert';

const authKey = global.config.authBearerKey;

class ILogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSignedIn: false,
            statLoad: 0
        }
        this.handleLoad = this.handleLoad.bind(this);
        this.clearSession = this.clearSession.bind(this);
    }
    clearSession() {
        var auth2 = gapi.auth2.getAuthInstance();
        auth2.signOut().then(function () {
            auth2.disconnect();
            localStorage.clear();
            console.log('User signed out.');
            window.location.reload();
        });
    }
    onSuccess(res) {
        var datatext ='{"user_email":"'+res.getBasicProfile().getEmail()+'","user_id":"'+res.getBasicProfile().getId()+'","user_fullname":"'+res.getBasicProfile().getName()+'","user_picture":"'+res.getBasicProfile().getImageUrl()+'"}';
        var uidValue = res.getBasicProfile().getEmail();
        var utkValue = res.getBasicProfile().getId();
        document.getElementById("loginButton").style.display = "none";
        document.getElementById("logoutButton").style.display = "block";
        var data = JSON.parse(datatext); 
        fetch(global.config.urlLink+"/ceklogin", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }, body : JSON.stringify(data)
          }).then(res2 => {
            if(res2.status === 200) 
                return res2.json() 
          }).then( resJson => {
            if(resJson.status === '200') {
                var uroles = [];
                if(resJson.values[0].userRole !== '') {
                    var arrUserRole = resJson.values[0].userRole.split("$$");
                    for(var ur=0; ur<global.config.userrole.length; ur++) {
                        var resuroles = "";
                        for(var ir=0; ir<arrUserRole.length; ir++) {
                            if(global.config.userrole[ur] === arrUserRole[ir]) {
                                resuroles = "ON"; break;
                            } else { resuroles = "OFF"; }
                        }
                        uroles.push(resuroles);
                    }
                } 
                localStorage.setItem('uid', uidValue);
                localStorage.setItem('utk', utkValue);
                localStorage.setItem('udocid', resJson.values[0].docid);
                localStorage.setItem('uposition', resJson.values[0].userPosition);
                localStorage.setItem('ulevel', resJson.values[0].userLevel);
                localStorage.setItem('ufullname', resJson.values[0].userFullname);
                localStorage.setItem('urole', JSON.stringify(uroles));
                this.setState( prevState => {
                    return {  
                        ...prevState.isSignedIn, isSignedIn: true,
                        ...prevState.statLoad, statLoad: 1
                    }
                })
                window.location.reload();
            } else {
                console.log('Something happened wrong');
                document.getElementById("loginButton").style.display = "block";
                document.getElementById("errormsg").style.display = "block"; 
                this.setState( prevState => {
                    return {  
                        ...prevState.isSignedIn, isSignedIn: false,
                        ...prevState.statLoad, statLoad: 1
                    }
                })
                var auth2 = gapi.auth2.getAuthInstance();
                auth2.signOut().then(function () {
                    auth2.disconnect();
                    localStorage.clear();
                }); 
            }
          }).catch(err => err);
    }

    onLoginFailed(err) {
        document.getElementById("loginButton").style.display = "block";
        document.getElementById("errormsg").style.display = "block";
        this.setState( prevState => {
            return {  
                ...prevState.isSignedIn, isSignedIn: false,
                ...prevState.statLoad, statLoad: 1
            }
        })
    }
    
    handleLoad() {
        const successCallback = this.onSuccess.bind(this);
        const failureCallback = this.onLoginFailed.bind(this);
            gapi.load('auth2', () => {
              this.auth2 = gapi.auth2.init({
                client_id: global.config.credClientId,
                scope: ('profile https://www.googleapis.com/auth/drive.readonly')
              })
            });    
        
            gapi.load('signin2', function() {
              var opts = {
                width: 220,
                height: 50,
                longtitle: true,
                theme: 'dark', 
                client_id: global.config.credClientId,
                onsuccess: successCallback,
                onfailure: failureCallback
              }
              gapi.signin2.render('loginButton', opts)
              
            })
            
            localStorage.clear();
            
    }
    componentDidMount() {
        window.addEventListener('load',this.handleLoad);
        document.getElementById("errormsg").style.display = 'none';
    }

    getContent() {
        if (this.state.isSignedIn === true) {
            return <div><IDashboard/></div>
        } else {
              return (
                <div className="main-container center-screen">
                    <div className="login-area divMiddle">
                        <div className="mgT30 divCenter"><img src={logoPS} alt="pointstar" width="150"></img></div>
                        <div className="mgT20 mgB30 divCenter textBold">Sign in with your PointStar Intranet Account</div>
                        <div className="mgT20 mgB20 divCenter"><div className="divhide w70p" id="errormsg"><Alert severity="error">You are not successfully login.<br/>Please contact administrator for more information</Alert></div></div>
                        <div className="mgT30 mgB50 divCenter">
                            <div id="loginButton"></div>
                            <div id="logoutButton" className="divHide">
                            <button className="genButton mgL20" onClick={() => this.clearSession()}>clear</button>
                            </div>
                        </div>
                    </div>
                </div>
              )
        }
    
    }
    

    render() {
        return (
            <div>{this.getContent()}</div>
           
        )
    }
}
export default ILogin;
