import React, { Component } from 'react';
import '../iinc/iStyle.css';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import IUC from './ifragmentuc';
 
class IDashboard extends Component {
 render() {
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle"></div>
                <div><IUC/></div>
              </div>
          </div>
    </div>
    
   );
 }
}
 
export default IDashboard;