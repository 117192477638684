import NumberFormats from '../../inc/number-format';
import qrcode from '../../assets/qrcode_paynow.png';
import picpaynow from '../../assets/pic_paynow.png';
import '../../inc/genstyle.css';

const MessageThx = (currency,gtotal,domain,licname,mpayment) => {
    var resMessage = "";
      if(mpayment === global.cconfig.methodPayment[1]) {
        resMessage += '<div class="w100p">';
        resMessage += '<div class="st3Title">Send us a cheque</div>';
        resMessage += '<div class="mgT30 mgB30">';
        resMessage += '<div>Thank you for your order.</div>';
        resMessage += '<div class="mgT20">Please continue your payment by writing a cheque payable to &quot;<strong> PointStar Pte Ltd</strong> &quot; for the amount of <strong>'+currency+' '+NumberFormats(gtotal)+' </strong></div>';
        resMessage += '<div class="mgT20">Write your domain and no. of licences (if applicable) at the back of the cheque for faster and more accurate processing.</div>';
        resMessage += '<div class="mgT20">No receipt will be provided for cheque payment. We will contact you by email and/or phone to confirm we&raquo;ve received it and beginning work on setting up your request for your domain. We will only proceed with setup once we&raquo;ve received it.</div>';
        resMessage += '<div class="mgT20">Mail / courier your cheque to:<br /><br /><strong>PointStar Pte Ltd <br />51 Ayer Rajah Crescent #06-10 <br />Singapore 139948</strong>';
        resMessage += '<div class="mgT20">Please check your inbox for an email from <strong>PointStar</strong> (license@point-star.com) with the subject: <strong>&quot;Your Order: '+domain +' on '+licname+'&quot;</strong>. It contains crucial instructions on how to proceed. There are key pieces of information that you need to provide us for us to proceed with the setup.</div>';
        resMessage += '<div class="mgT20">Remember that you had agreed to not hold PointStar Pte Ltd responsible if there is any lapse or delay in our services if the information you had provided us was inaccurate or incomplete, and that if your cheque payment do not reach us within 72 hours, we will treat your order as <u>cancelled</u>.</div>';
        resMessage += '<div class="mgT20">If you have any further enquiry, contact us at +65 6773 0987 or sales@point-star.com (please do tell us your company and domain name).</div>';
        resMessage += '</div>';
        resMessage += '</div>';
      } else if(mpayment === global.cconfig.methodPayment[0]) {
        resMessage += '<div class="w100p">';
        resMessage += '<div class="st3Title">You&raquo;ve paid via PayPal</div>';
        resMessage += '<div class="mgT30 mgB30">';
        resMessage += '<div>Thank you for your order and payment.</div>';
        resMessage += '<div class="mgT20">You&raquo;ve sent your payment via PayPal with the amount of <strong>'+currency+' '+NumberFormats(gtotal)+' </strong></div>';
        
        resMessage += '<div class="mgT20">Please check your inbox for an email from <strong>PointStar</strong> (license@point-star.com). It contains crucial instructions on how to proceed. There are key pieces of information that you need to provide us for us to proceed with the setup.</div>';
        resMessage += '<div class="mgT20">Remember that you had agreed to not hold PointStar Pte Ltd responsible if there is any lapse or delay in our services if the information you had provided us was inaccurate or incomplete.</div>';
        resMessage += '<div class="mgT20">If you have any further enquiry, contact us at +65 6773 0987 or sales@point-star.com (please do tell us your company and domain name).</div>';
        resMessage += '</div>';
        resMessage += '</div>';
      } else if(mpayment === global.cconfig.methodPayment[2]) {
        resMessage += '<div class="w100p">';
        resMessage += '<div class="st3Title">Pay us using PayNow</div>';
        resMessage += '<div class="mgT30 mgB30">';
        resMessage += '<div>Thank you for your order.</div>';
        resMessage += '<div class="mgT20">';
        resMessage += 'Please continue your payment in the amount of <strong>'+currency+' '+NumberFormats(gtotal)+' </strong> by PayNow or Wire Transfer.';
        resMessage += '<br/>';
        resMessage += '<p class="mgT20"><img src='+picpaynow+'  style="width: 80px" alt="" /><br/>';
        resMessage += '<strong>';
        resMessage += 'Bank Name : HSBC <br/>';
        resMessage += 'Unique Entity Number (UEN) : 200702445Z<br/>';
        resMessage += 'Account Name : PointStar Pte Ltd';
        resMessage += '</strong></p>';
        resMessage += '<p>Wire Transfer</p>';
        resMessage += '<p><img src='+qrcode+'  style="width: 500px; border: 1px solid #E1E1E1;" alt="" /></p>';
        resMessage += '</div>';
        resMessage += '<div class="mgT20">Kindly write your domain name and number of additional licence(s) in the beneficiary details to expedite the process. We will proceed with the request once the payment is made and print screen your transaction receipt for your safekeeping.</div>';
        resMessage += '<div class="mgT20">Please check your email inbox from license@point-star.com with the title <strong>"Your Order: '+domain +' on '+licname+'"</strong> for the updates on licence provisioning.</div>';
        resMessage += '<div class="mgT20">By continuing, you have agreed to not hold PointStar PTE LTD to responsible for any lapse or delay in the process should the information provided is wrong or deficient.</div>';
        // resMessage += '<div class="mgT20">If you have any queries or concerns, please contact +65 6773 0987 or email us at sales@point-star.com.</div>';
        resMessage += '<div class="mgT20">If you have any other queries or concerns, you may write in to renewal-sg@point-star.com</div>';
        resMessage += '</div>';
        resMessage += '</div>';
      } else if(mpayment === global.cconfig.methodPayment[4]) {
        resMessage += '<div class="w100p">';
        resMessage += '<div class="st3Title">You\'ve paid via Credit Card</div>';
        resMessage += '<div class="mgT30 mgB30">';
        resMessage += '<div>Thank you for your order and payment.</div>';
        resMessage += '<div class="mgT20">You\'ve sent your payment using a credit card in the amount of <strong>'+currency+' '+NumberFormats(gtotal)+' </strong></div>';
        resMessage += '<div class="mgT20">Please check your email inbox from license@point-star.com with the title <strong>"Your Order: '+domain +' on '+licname+'"</strong> for the updates on licence provisioning.</div>';
        resMessage += '<div class="mgT20">Kindly remember that you have agreed to not hold PointStar PTE LTD to responsible for any lapse or delay in the process should the information provided is wrong or deficient.</div>';
        // resMessage += '<div class="mgT20">If you have any queries or concerns, please contact +65 6773 0987 or email us at sales@point-star.com.</div>';
        resMessage += '<div class="mgT20">If you have any other queries or concerns, you may write in to renewal-sg@point-star.com</div>';
        resMessage += '</div>';
        resMessage += '</div>';
      } else if(mpayment === global.cconfig.methodPayment[3]) {
        resMessage += '<div class="w100p">';
        resMessage += '<div class="st3Title">Transfer to Bank Account</div>';
        resMessage += '<div class="mgT30 mgB30">';
        resMessage += '<div>Thank you for your order.</div>';
        resMessage += '<div class="mgT20">Please continue your payment by transfer to &quot;<strong> PointStar Pte Ltd</strong> &quot; Bank Account in the amount of <strong>'+currency+' '+NumberFormats(gtotal)+' </strong></div>';
        resMessage += '<div class="mgT20">Transfer your payment to:<br /><br />';
        if(currency === "SGD") { 
        resMessage += '<strong>Bank Name: The Hongkong and Shanghai Banking Corporation Limited<br />Account Name : PointStar Pte Ltd <br />Bank Code: 7232<br />HSBC Swift Code: HSBCSGSG<br />Account Number: 147-731533-001</strong>';
        } else if(currency === "USD") {
        resMessage += '<strong>Bank Name: The Hongkong and Shanghai Banking Corporation Limited<br />Account Name : PointStar Pte Ltd <br />Bank Code: 7232<br />HSBC Swift Code: HSBCSGSG<br />Account Number: 260-659453-178</strong>';
        } else {
          resMessage += '<strong>To Be Confirmed</strong>';
        }
        resMessage += '<div class="mgT20">Input your domain and no. of licences (if applicable) in the message field when you do the transfer for faster and more accurate processing.</div>';
        resMessage += '<div class="mgT20">Please check your email inbox from license@point-star.com with the title <strong>"Your Order: '+domain +' on '+licname+'"</strong> for the updates on licence provisioning.</div>';
        resMessage += '<div class="mgT20">Kindly remember that you have agreed to not hold PointStar PTE LTD to responsible for any lapse or delay in the process should the information provided is wrong or deficient.</div>';
        resMessage += '<div class="mgT20">If you have any other queries or concerns, you may write in to renewal-sg@point-star.com</div>';

        resMessage += '</div>';
        resMessage += '</div>';
      }
    return resMessage;
}
export default MessageThx;