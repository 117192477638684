import React, { Component } from 'react';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[4];

class DriveForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            client_id: '',
            licencedoc_id: '',
            licdrivedoc_id: '',
            licdrive_type: '',
            licdrive_seat: '',
            licdrive_edate: '',
            formErrors: {licdrive_type: '', licdrive_seat: ''},
            licdrivetypeValid: false,
            licdriveseatValid: false,
            formValid: false,
            input_disabled: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleUserSelect = (e) => {
        const value = e.target.value;
        const inputname = e.target.name;
        if(value !== '') {
            this.setState( prevState => {
                return {  
                    ...prevState.licdrive_type, licdrive_type: value
                }
            }, () => { this.validateField(inputname, value)
            }) 
        } else {
            this.setState( prevState => {
                return {  
                    ...prevState.licdrive_type, licdrive_type: value
                }
            })
        }
      }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let licdrivetypeValid = this.state.licdrivetypeValid;
        let licdriveseatValid = this.state.licdriveseatValid;
        
        switch(fieldName) {
            case 'licdrive_type': 
                licdrivetypeValid = value.trim().length > 0;
                fieldValidationErrors.licdrive_type = licdrivetypeValid ? '' : 'add on type need to be filled';
                break;
            case 'licdrive_seat': 
                licdriveseatValid = value.trim().length > 0;
                fieldValidationErrors.licdrive_seat = licdriveseatValid ? '' : 'seats need to be filled';
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        licdrivetypeValid: licdrivetypeValid,
                        licdriveseatValid: licdriveseatValid,
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.licdrivetypeValid && this.state.licdriveseatValid});
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit(e) {
        e.preventDefault();
        if(this.state.licdrive_type !== '' && this.state.licdrive_seat !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var urlFetch, datatext;
            if(this.state.licdrivedoc_id !== null && this.state.licdrivedoc_id !== ''  && this.state.licdrivedoc_id !== undefined) { 
                var licdriveType,licdriveSeat;
                if(this.textInputLicDriveType.value === '') { licdriveType = this.state.licdrive_type; } else { licdriveType = this.textInputLicDriveType.value; }
                if(this.textInputLicDriveSeat.value === '') { licdriveSeat = this.state.licdrive_seat; } else { licdriveSeat = this.textInputLicDriveSeat.value; }
                
                datatext ='{"licdrive_type":"'+licdriveType+'","licdrive_seat":"'+licdriveSeat+'","licdrive_edate":"'+this.state.licdrive_edate+'","licdrivedoc_id":"'+this.state.licdrivedoc_id+'","clientdoc_id":"'+this.state.client_id+'","licencedoc_id":"'+this.state.licencedoc_id+'","form_id":"form-add-licdrive"}';
                urlFetch = global.config.urlLink+"/licencedriveedit";
            } else {
                datatext ='{"licdrive_type":"'+this.state.licdrive_type+'","licdrive_seat":"'+this.state.licdrive_seat+'","licdrive_edate":"'+this.state.licdrive_edate+'","clientdoc_id":"'+this.state.client_id+'","licencedoc_id":"'+this.state.licencedoc_id+'","form_id":"form-add-licdrive"}';
                urlFetch = global.config.urlLink+"/licencedriveadd";
            }
            var data = JSON.parse(datatext); 
            
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    window.location.href='/IViewLicence/'+this.state.client_id+'/'+this.state.licencedoc_id;
                } else {
                    console.log('Something happened wrong');
                }
            }).catch(err => err);

        } else {
            var arrfieldName = ['licdrive_type','licdrive_seat'];
            var arrvalue = [this.textInputLicDriveType.value,this.textInputLicDriveSeat.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    componentDidMount() {
        var othersContent4 = JSON.parse(document.getElementById("contentoth").innerText);
        this.setState({
            stPopTitle: othersContent4.stPopTitle,
            client_id: othersContent4.client_id,
            licencedoc_id: othersContent4.licence_id,
            licdrivedoc_id: othersContent4.licdrive_id,
            licdrive_type: othersContent4.licdrive_type,
            licdrive_seat: othersContent4.licdrive_seat,
            licdrive_edate: othersContent4.licdrive_endperiode,
            formErrors: {licdrive_type: '', licdrive_seat: ''},
            licdrivetypeValid: false,
            licdriveseatValid: false,
            formValid: false,
            input_disabled: false
        }); 
    }

    render() {
        var drivetype = global.config.addonLicence;
        var licDriveTypeList = [];
        if(drivetype.length > 0) {
            for(var u in drivetype) {
                licDriveTypeList.push(<option value={drivetype[u]}>{drivetype[u]}</option>)
            }
        }
        return (
            <div className="main-container">
                <div className="mgT30">
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <input type="hidden" name="client_id" value={this.state.client_id} />
                        <input type="hidden" name="licence_id" value={this.state.licencedoc_id} />
                        <input type="hidden" name="drive_id" value={this.state.licdrivedoc_id} />
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="licdrive_type">Add On Type</label>
                        <div className="w70p">
                            <select name="licdrive_type" className="form-control"
                                    value={this.state.licdrive_type} 
                                    onChange={this.handleUserSelect}
                                    ref={(input) => this.textInputLicDriveType = input}
                                    disabled={this.state.input_disabled}>
                                <option value="">-- please select --</option>
                                {licDriveTypeList}
                            </select>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.licdrive_type}/>  }
                            </div>
                        </div>
                    </div>
                    
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="licdrive_seat">Amount of Seats</label>
                        <div className="w70p">
                            <input type="number" className="form-control" name="licdrive_seat"
                                    placeholder={this.state.licdrive_seat}
                                    value={this.state.licdrive_seat}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputLicDriveSeat = input}
                                    min="1"/> 
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.licdrive_seat}/>  }
                            </div>
                        </div>
                    </div>
                    
                    <div className="row-field mgT30 mgB30">
                        <div className="w100p"><button id="submit-button"  type="submit" className="w100p submitButton">Submit</button></div>
                    </div>
                </form>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
          );
        }
     }
      
export default DriveForm;
