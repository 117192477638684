import React, { Component } from 'react';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';
import DatePicker from 'react-date-picker';
import ReplaceAllContent from '../../inc/replace-all';
//import 'react-date-picker/dist/DatePicker.css';
//import 'react-calendar/dist/Calendar.css';

//import TextField from '@material-ui/core/TextField';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[2];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[3];

class LicenceForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            licencetypelist: [],
            licencedoc_id: '',
            licence_type: '',
            licence_amount_seat: '',
            // licence_price: '',
            licence_price_currency: 'USD',
            licence_start_date: new Date(),
            licence_periode_num: '',
            licence_periode_unit: '',
            licence_remarks: '',
            licence_price_seat: '',
            licence_periode_num_billcycle: '',
            licence_periode_unit_billcycle: '',
            // formErrors: {licence_type: '', licence_amount_seat: '', licence_price: '', licence_start_date: '', licence_periode_num: '', licence_periode_unit: '', licence_price_seat: '', licence_periode_num_billcycle: '', licence_periode_unit_billcycle: ''},
            formErrors: {licence_type: '', licence_amount_seat: '', licence_start_date: '', licence_periode_num: '', licence_periode_unit: '', licence_price_seat: '', licence_periode_num_billcycle: '', licence_periode_unit_billcycle: ''},
            licencetypeValid: false,
            licenceamountseatValid: false,
            // licencepriceValid: false,
            licencestartdateValid: false,
            licenceperiodenumValid: false,
            //licenceperiodeunitValid: false,
            licencepriceseatValid: false,
            licenceperiodenumbillcycleValid: false,
            // licenceperiodeunitbillcycleValid: false,
            formValid: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleStartDate = date => {
        console.log(date);
        this.setState( prevState => {
            return {  
                ...prevState.licence_start_date, licence_start_date: date
            }
            }, () => { this.validateField('licence_start_date', date)
        }) 
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(value);
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    handleUserSelect = (e) => {
        const value = e.target.value;
        const inputname = e.target.name;
        console.log(value);
        console.log(inputname);
        if(inputname === "licence_type") {
            this.setState( prevState => {
                return {  
                    ...prevState.licence_type, licence_type: value,
                }
              }, () => { this.validateField(inputname, value)
            }) 
        } else if(inputname === "licence_periode_unit") {
            this.setState( prevState => {
                return {  
                    ...prevState.licence_periode_unit, licence_periode_unit: value
                }
              }, () => { this.validateField(inputname, value)
            }) 
        } else if(inputname === "licence_price_currency") {
            this.setState( prevState => {
                return {  
                    ...prevState.licence_price_currency, licence_price_currency: value
                }
              }) 
        } else if(inputname === "licence_periode_unit_billcycle") {
            this.setState( prevState => {
                return {  
                    ...prevState.licence_periode_unit_billcycle, licence_periode_unit_billcycle: value
                }
                }, () => { this.validateField(inputname, value)
            }) 
        }
        
    }
    
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let licencetypeValid = this.state.licencetypeValid;
        let licenceamountseatValid = this.state.licenceamountseatValid;
        // let licencepriceValid = this.state.licencepriceValid;
        let licencestartdateValid = this.state.licencestartdateValid;
        let licenceperiodenumValid = this.state.licenceperiodenumValid;
        let licenceperiodeunitValid = this.state.licenceperiodeunitValid;
        let licencepriceseatValid = this.state.licencepriceseatValid;
        let licenceperiodenumbillcycleValid = this.state.licenceperiodenumbillcycleValid;
        let licenceperiodeunitbillcycleValid = this.state.licenceperiodeunitbillcycleValid;
        

        switch(fieldName) {
            case 'licence_type': 
                licencetypeValid = value.trim().length > 0;
                fieldValidationErrors.licence_type = licencetypeValid ? '' : 'type of licences need to be filled';
                break;
            case 'licence_amount_seat': 
                licenceamountseatValid = value.trim().length > 0;
                fieldValidationErrors.licence_amount_seat = licenceamountseatValid ? '' : 'amount of seats need to be filled';
                break;
            // case 'licence_price': 
            //     licencepriceValid = value.trim().length > 0;
            //     fieldValidationErrors.licence_price = licencepriceValid ? '' : 'charge of licence need to be filled';
            //     break;
            case 'licence_start_date':
                licencestartdateValid = value !== null; 
                //licencestartdateValid = value.trim().length > 0;
                fieldValidationErrors.licence_start_date = licencestartdateValid ? '' : 'start activated date need to be filled';
                break;
            case 'licence_periode_num': 
            case 'licence_periode_unit': 
                licenceperiodenumValid = value.trim().length > 0;
                licenceperiodeunitValid = value.trim().length > 0;
                fieldValidationErrors.licence_periode_num = (licenceperiodenumValid || licenceperiodeunitValid) ? '' : 'duration need to be filled';
                break;
            case 'licence_price_seat': 
                licencepriceseatValid = value.trim().length > 0;
                fieldValidationErrors.licence_price_seat = licencepriceseatValid ? '' : 'price per seat need to be filled';
                break;
            case 'licence_periode_num_billcycle': 
            case 'licence_periode_unit_billcycle': 
                licenceperiodenumbillcycleValid = value.trim().length > 0;
                licenceperiodeunitbillcycleValid = value.trim().length > 0;
                fieldValidationErrors.licence_periode_num_billcycle = (licenceperiodenumbillcycleValid || licenceperiodeunitbillcycleValid) ? '' : 'duration of billing cycle need to be filled';
                break;
            // case 'licence_periode_num': 
            //     licenceperiodenumValid = value.trim().length > 0;
            //     fieldValidationErrors.licence_periode_num = licenceperiodenumValid ? '' : 'long periode need to be filled';
            //     break;
            // case 'licence_periode_unit': 
            //     licenceperiodeunitValid = value.trim().length > 0;
            //     fieldValidationErrors.licence_periode_unit = licenceperiodeunitValid ? '' : 'long periode need to be filled';
            //     break;
            default:
                break;
        }
        //alert(fieldName + ':' + JSON.stringify(fieldValidationErrors));
        
        this.setState({formErrors: fieldValidationErrors,
                        licencetypeValid: licencetypeValid,
                        licenceamountseatValid: licenceamountseatValid,
                        // licencepriceValid: licencepriceValid,
                        licencestartdateValid: licencestartdateValid,
                        licenceperiodenumValid: licenceperiodenumValid,
                        //licenceperiodeunitValid: licenceperiodeunitValid,
                        licencepriceseatValid: licencepriceseatValid,
                        licenceperiodenumbillcycleValid: licenceperiodenumbillcycleValid,
                        // licenceperiodeunitbillcycleValid: licenceperiodeunitbillcycleValid
                    }, this.validateForm);
      }
      validateForm() {
        // this.setState({formValid: this.state.licencetypeValid && this.state.licenceamountseatValid && this.state.licencepriceValid && this.state.licencestartdateValid && this.state.licenceperiodenumValid && this.state.licencepriceseatValid && this.state.licenceperiodenumbillcycleValid && this.state.licenceperiodeunitbillcycleValid });
        this.setState({formValid: this.state.licencetypeValid && this.state.licenceamountseatValid  && this.state.licencestartdateValid && this.state.licenceperiodenumValid && this.state.licencepriceseatValid && this.state.licenceperiodenumbillcycleValid && this.state.licenceperiodeunitbillcycleValid });
      }
    
      errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
      }
      handleSubmit(e) {
        e.preventDefault();
        //alert(JSON.stringify(this.state));
        //alert(this.event_enddate)
        console.log(this.state.licence_start_date);
        if(this.state.licence_type !== '' && this.state.licence_amount_seat !== '' && this.state.licence_start_date !== null && this.state.licence_periode_num !== '' && this.state.licence_periode_unit !== '' && this.state.licence_price_seat !== '' && this.state.licence_periode_num_billcycle !== '' && this.state.licence_periode_unit_billcycle !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var urlFetch, datatext;
            var licenceType,licenceAmountSeat,licencePriceCurrency,licenceStartDate,licencePeriodeNum,licencePeriodeUnit,licenceRemarks,licencePriceSeat,licencePeriodeNumBillCycle,licencePeriodeUnitBillCycle;
            // var licencePrice;
            if(this.textInputLicenceType.value === '') { licenceType = this.state.licence_type; } else { licenceType = this.textInputLicenceType.value; }
            var arrLicValue = licenceType.split("$$");
    
            if(this.textInputLicenceAmountSeat.value === '') { licenceAmountSeat = this.state.licence_amount_seat; } else { licenceAmountSeat = this.textInputLicenceAmountSeat.value; }
            // if(this.textInputLicencePrice.value === '') { licencePrice = this.state.licence_price; } else { licencePrice = this.textInputLicencePrice.value; }
            if(this.textInputLicencePriceCurrency.value === '') { licencePriceCurrency = this.state.licence_price_currency; } else { licencePriceCurrency = this.textInputLicencePriceCurrency.value; }
            if(this.textInputLicencePeriodeNum.value === '') { licencePeriodeNum = this.state.licence_periode_num; } else { licencePeriodeNum = this.textInputLicencePeriodeNum.value; }
            if(this.textInputLicencePeriodeUnit.value === '') { licencePeriodeUnit = this.state.licence_periode_unit; } else { licencePeriodeUnit = this.textInputLicencePeriodeUnit.value; }
            if(this.textInputLicenceRemarks.value === '') { licenceRemarks = this.state.licence_remarks; } else { licenceRemarks = this.textInputLicenceRemarks.value; }
            if(licenceRemarks !== "") { 
                licenceRemarks = ReplaceAllContent('\n','<br/>',licenceRemarks);
            }
            if(this.textInputLicencePriceSeat.value === '') { licencePriceSeat = this.state.licence_price_seat; } else { licencePriceSeat = this.textInputLicencePriceSeat.value; }
            if(this.textInputLicencePeriodeNumBillCycle.value === '') {  licencePeriodeNumBillCycle = this.state.licence_periode_num_billcycle; } else { licencePeriodeNumBillCycle = this.textInputLicencePeriodeNumBillCycle.value; }
            if(this.textInputLicencePeriodeUnitBillCycle.value === '') { licencePeriodeUnitBillCycle = this.state.licence_periode_unit_billcycle; } else { licencePeriodeUnitBillCycle = this.textInputLicencePeriodeUnitBillCycle.value; }
            if(this.state.clientdoc_id !== null && this.state.clientdoc_id !== ''  && this.state.clientdoc_id !== undefined) { 
                
                if(this.textInputLicenceStartDate.value === '') { licenceStartDate = this.state.licence_start_date; } else { licenceStartDate = this.state.licence_start_date; }
                
                // datatext ='{"licence_type":"'+arrLicValue[0]+'","licence_category":"'+arrLicValue[1]+'","licence_amount_seat":"'+licenceAmountSeat+'","licence_price":"'+licencePrice+'","licence_price_currency":"'+licencePriceCurrency+'","licence_start_date":"'+licenceStartDate+'","licence_periode_num":"'+licencePeriodeNum+'","licence_periode_unit":"'+licencePeriodeUnit+'","licence_remarks":"'+licenceRemarks+'","licence_price_seat":"'+licencePriceSeat+'","licence_periode_num_billcycle":"'+licencePeriodeNumBillCycle+'","licence_periode_unit_BillCycle":"'+licencePeriodeUnitBillCycle+'","licence_client_docid":"'+this.state.licencedoc_id+'"}';
                datatext ='{"licence_type":"'+arrLicValue[0]+'","licence_category":"'+arrLicValue[1]+'","licence_amount_seat":"'+licenceAmountSeat+'","licence_price_currency":"'+licencePriceCurrency+'","licence_start_date":"'+licenceStartDate+'","licence_periode_num":"'+licencePeriodeNum+'","licence_periode_unit":"'+licencePeriodeUnit+'","licence_remarks":"'+licenceRemarks+'","licence_price_seat":"'+licencePriceSeat+'","licence_periode_num_billcycle":"'+licencePeriodeNumBillCycle+'","licence_periode_unit_billcycle":"'+licencePeriodeUnitBillCycle+'","licence_client_docid":"'+this.state.licencedoc_id+'"}';
                urlFetch = global.config.urlLink+"/licenceedit";
            } else {
                 
                // datatext ='{"licence_type":"'+arrLicValue[0]+'","licence_category":"'+arrLicValue[1]+'","licence_amount_seat":"'+this.state.licence_amount_seat+'","licence_price":"'+this.state.licence_price+'","licence_price_currency":"'+this.state.licence_price_currency+'","licence_start_date":"'+this.state.licence_start_date+'","licence_periode_num":"'+this.state.licence_periode_num+'","licence_periode_unit":"'+this.state.licence_periode_unit+'","licence_remarks":"'+this.state.licence_remarks+'","licence_client_docid":"'+this.state.licencedoc_id+'"}';
                // datatext ='{"licence_type":"'+arrLicValue[0]+'","licence_category":"'+arrLicValue[1]+'","licence_amount_seat":"'+licenceAmountSeat+'","licence_price":"'+licencePrice+'","licence_price_currency":"'+licencePriceCurrency+'","licence_start_date":"'+this.state.licence_start_date+'","licence_periode_num":"'+licencePeriodeNum+'","licence_periode_unit":"'+licencePeriodeUnit+'","licence_remarks":"'+licenceRemarks+'","licence_price_seat":"'+licencePriceSeat+'","licence_periode_num_billcycle":"'+licencePeriodeNumBillCycle+'","licence_periode_unit_billcycle":"'+licencePeriodeUnitBillCycle+'","licence_client_docid":"'+this.state.licencedoc_id+'"}';
                datatext ='{"licence_type":"'+arrLicValue[0]+'","licence_category":"'+arrLicValue[1]+'","licence_amount_seat":"'+licenceAmountSeat+'","licence_price_currency":"'+licencePriceCurrency+'","licence_start_date":"'+this.state.licence_start_date+'","licence_periode_num":"'+licencePeriodeNum+'","licence_periode_unit":"'+licencePeriodeUnit+'","licence_remarks":"'+licenceRemarks+'","licence_price_seat":"'+licencePriceSeat+'","licence_periode_num_billcycle":"'+licencePeriodeNumBillCycle+'","licence_periode_unit_billcycle":"'+licencePeriodeUnitBillCycle+'","licence_client_docid":"'+this.state.licencedoc_id+'"}';
                urlFetch = global.config.urlLink+"/licenceadd";
            }
            var data = JSON.parse(datatext); 
            console.log(datatext);
            
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
              }).then(res => {
                console.log(res);
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                console.log(resJson);
                console.log(resJson.status);
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    window.location.href='/IViewClients/'+this.state.licencedoc_id;
                } else {
                    console.log('Something happened wrong');
                    console.log(resJson);
                }
              }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
                console.log(err);
              });
              
        } else {
            // var arrfieldName = ['licence_type','licence_amount_seat','licence_price','licence_start_date','licence_periode_num','licence_periode_unit','licence_price_seat','licence_periode_num_billcycle','licence_periode_unit_billcycle'];
            // var arrvalue = [this.textInputLicenceType.value,this.textInputLicenceAmountSeat.value,this.textInputLicencePrice.value,this.state.licence_start_date,this.textInputLicencePeriodeNum.value,this.textInputLicencePeriodeUnit.value,this.textInputLicencePriceSeat.value,this.textInputLicencePeriodeNumBillCycle.value,this.textInputLicencePeriodeUnitBillCycle.value];
            var arrfieldName = ['licence_type','licence_amount_seat','licence_start_date','licence_periode_num','licence_periode_unit','licence_price_seat','licence_periode_num_billcycle','licence_periode_unit_billcycle'];
            var arrvalue = [this.textInputLicenceType.value,this.textInputLicenceAmountSeat.value,this.state.licence_start_date,this.textInputLicencePeriodeNum.value,this.textInputLicencePeriodeUnit.value,this.textInputLicencePriceSeat.value,this.textInputLicencePeriodeNumBillCycle.value,this.textInputLicencePeriodeUnitBillCycle.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        } 
      }
    componentDidMount() {
        //console.log("id:"+document.getElementById("contentid").innerText);
        document.getElementById('loading-area').style.display = 'block'; 
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        //var jsonOthersContent = othersContent.json()
        console.log(othersContent.country);
        var keywCountry = othersContent.country;
        if(keywCountry.toLowerCase() === "indonesia") { keywCountry = keywCountry.toLowerCase(); }
        else if(keywCountry.toLowerCase() === "malaysia") { keywCountry = keywCountry.toLowerCase(); }
        else { keywCountry = "singapore"; }
        var lictypedata = JSON.parse('{"keyword":"'+keywCountry+'","category":"","pageShow":"1000","form_id":"pubonly"}'); 
        fetch(global.config.urlLink+"/licencetypelist/", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey2,
            }, body : JSON.stringify(lictypedata)
          }).then(res => {
            if(res.status === 200) 
                return res.json() 
          }).then( resJsonLicType => {
            console.log(resJsonLicType);
            console.log(resJsonLicType.status);
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJsonLicType.status === '200') {
                console.log(resJsonLicType);
                var arrLicList = [];
                var arrLicTypeSortByAsc = [];
                for(var x=0; x<resJsonLicType.values.length; x++) {
                    console.log(resJsonLicType.values[x].content.licenceType);
                    arrLicList.push({
                        docid: resJsonLicType.values[x].docid,
                        licenceType: resJsonLicType.values[x].content.licenceType,
                        licenceCategory: resJsonLicType.values[x].content.licenceCategory,
                        createDate: resJsonLicType.values[x].createdate,
                        // licencePrice: resJsonLicType.values[x].content.licencePrice,
                        licencePriceUSD: resJsonLicType.values[x].content.licencePriceUSD,
                        licenceStatus: resJsonLicType.values[x].content.licenceStatus,
                        
                    })
                }
                if(arrLicList.length>0) {
                    arrLicTypeSortByAsc = arrLicList.sort((c,d) => {
                      return (c.licenceType > d.licenceType)?1:-1;
                    })
                }
                console.log(arrLicTypeSortByAsc);
                this.setState({
                    stPopTitle: '',
                    licencetypelist: arrLicTypeSortByAsc,
                    licencedoc_id: document.getElementById("contentid").innerText,
                    licence_type: '',
                    licence_amount_seat: '',
                    // licence_price: '',
                    licence_price_currency: 'USD',
                    licence_start_date: new Date(),
                    licence_periode_num: '',
                    licence_periode_unit: '',
                    licence_remarks: '',
                    licence_price_seat: '',
                    licence_periode_num_billcycle: '',
                    licence_periode_unit_billcycle: '',
                    // formErrors: {licence_type: '', licence_amount_seat: '', licence_price: '', licence_start_date: '', licence_periode_num: '', licence_periode_unit: '', licence_price_seat: '', licence_periode_num_billcycle: '', licence_periode_unit_billcycle: ''},
                    formErrors: {licence_type: '', licence_amount_seat: '', licence_start_date: '', licence_periode_num: '', licence_periode_unit: '', licence_price_seat: '', licence_periode_num_billcycle: '', licence_periode_unit_billcycle: ''},
                    licencetypeValid: false,
                    licenceamountseatValid: false,
                    // licencepriceValid: false,
                    licencestartdateValid: false,
                    licenceperiodenumValid: false,
                    //licenceperiodeunitValid: false,
                    //licenceperiodeunitValid: false,
                    licencepriceseatValid: false,
                    licenceperiodenumBillCycleValid: false,
                    formValid: false,            
                }); 
            } else {
                console.log('Something happened wrong');
                console.log(resJsonLicType);
                this.setState({
                    stPopTitle: '',
                    licencetypelist: [],
                    licencedoc_id: document.getElementById("contentid").innerText,
                    licence_type: '',
                    licence_amount_seat: '',
                    // licence_price: '',
                    licence_price_currency: 'USD',
                    licence_start_date: new Date(),
                    licence_periode_num: '',
                    licence_periode_unit: '',
                    licence_remarks: '',
                    licence_price_seat: '',
                    licence_periode_num_billcycle: '',
                    licence_periode_unit_billcycle: '',
                    // formErrors: {licence_type: '', licence_amount_seat: '', licence_price: '', licence_start_date: '', licence_periode_num: '', licence_periode_unit: ''},
                    formErrors: {licence_type: '', licence_amount_seat: '', licence_start_date: '', licence_periode_num: '', licence_periode_unit: ''},
                    licencetypeValid: false,
                    licenceamountseatValid: false,
                    // licencepriceValid: false,
                    licencestartdateValid: false,
                    licenceperiodenumValid: false,
                    //licenceperiodeunitValid: false,
                    licencepriceseatValid: false,
                    licenceperiodenumBillCycleValid: false,
                    //licenceperiodeunitBillCycleValid: false,
                    formValid: false,
                }); 
            }
          }).catch(err => { 
            document.getElementById('loading-area').style.display = 'none'; 
            console.log(err);
          });
        
        /*
        var resStrJsonLicType = '{"status": "200", "values": [{ "docid": "9URgJsqKWCtp9M01YLWn", "content": {"licenceType":"GSuite Business","licencePrice": "12","licenceCurrency": "USD","licencePriceForCountry": "Singapore"}}]}';
        var resJsonLicType = JSON.parse(resStrJsonLicType);
        this.setState({
            stPopTitle: '',
            licencetypelist: resJsonLicType,
            licencedoc_id: document.getElementById("contentid").innerText,
            licence_type: '',
            licence_amount_seat: '',
            licence_price: '',
            licence_start_date: new Date(),
            licence_periode_num: '',
            licence_periode_unit: '',
            formErrors: {licence_type: '', licence_amount_seat: '', licence_price: '', licence_start_date: '', licence_periode_num: '', licence_periode_unit: ''},
            licencetypeValid: false,
            licenceamountseatValid: false,
            licencepriceValid: false,
            licencestartdateValid: false,
            licenceperiodenumValid: false,
            //licenceperiodeunitValid: false,
            formValid: false,
        }); 
        */
    }
    render() {
      //console.log(this.state.licencetypelist.values.length);
      //console.log("aa"+this.state.licencedoc_id);
      //console.log("body id: "+document.getElementById("contentid").innerText)
      var sellicenceTypeList = [];
      if(this.state.licencetypelist.length > 0) {
         for(var u in this.state.licencetypelist) {
            sellicenceTypeList.push(<option value={this.state.licencetypelist[u].licenceType+"$$"+this.state.licencetypelist[u].licenceCategory} key={u}>{this.state.licencetypelist[u].licenceType}</option>)
             //sellicenceTypeList.push(<option value={this.state.licencetypelist.values[u].content.licenceType+"$$"+this.state.licencetypelist.values[u].content.licenceCategory} key={u}>{this.state.licencetypelist.values[u].content.licenceType}</option>)
         }
      }
      //var date_now = new Date();
      //var datetimeoption = '{"year":"numeric", "month": "long", "day": "2-digit", "timeZone": "Asia/Jakarta"}';
    
    //var resDateTimeFormat = new Intl.DateTimeFormat(global.config.timezone, JSON.parse(datetimeoption)).format(new Date());
      //var nowDateTime = new Intl.DateTimeFormat('en-US').format(new Date());
      return (
        <div className="main-container">
            <div className="mgT30">
            <form onSubmit={this.handleSubmit}>
              <div className="flex-container">
                <div className="col-6">
                    <label htmlFor="licence_type" className="textBold">Product </label>
                    <div>
                        <select name="licence_type" className="form-control" 
                             value={this.state.licence_type} 
                             onChange={this.handleUserSelect}
                             ref={(input) => this.textInputLicenceType = input}
                             disabled={this.state.input_disabled}
                        >
                        <option value="" key="">-- please select --</option>
                        {sellicenceTypeList}
                        </select>
                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_type}/>  }</div>
                    </div>

                    <label className="mgT30 textBold" htmlFor="licence_amount_seat">Number of Seats </label>
                    <div>
                        <div className="flex-container">
                            <div>
                                <input type="number" className="form-control" name="licence_amount_seat"
                                    placeholder={this.state.licence_amount_seat}
                                    value={this.state.licence_amount_seat}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputLicenceAmountSeat = input}
                                    min="1"/> 
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_amount_seat}/>  }</div>
                            </div>
                            <div className="mgL20 mgT10"> seat(s) </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex-container">
                            <div className="mgR20">
                                <label className="mgT30 textBold" htmlFor="licence_price">Currency</label>
                                <select name="licence_price_currency" className="form-control" 
                                    value={this.state.licence_price_currency} 
                                    onChange={this.handleUserSelect}
                                    ref={(input) => this.textInputLicencePriceCurrency = input}
                                    disabled={this.state.input_disabled}
                                >
                                    <option value="USD">USD</option>
                                    <option value="SGD">SGD</option>
                                    <option value="IDR">IDR</option>
                                    <option value="RM">RM</option>
                                </select>
                                {/* <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_price_currency}/>  }</div> */}
                            </div>
                            <div>
                                <label className="mgT30 textBold" htmlFor="licence_price_seat">Price /seat/month</label>
                                <input type="number" className="form-control" name="licence_price_seat"
                                    placeholder={this.state.licence_price_seat}
                                    value={this.state.licence_price_seat}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputLicencePriceSeat = input}/> 
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_price_seat}/>  }</div>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>

                <div className="col-6">
                    <label htmlFor="licence_type" className="textBold">Date of Activation </label>
                    <div>
                        <div className="flex-container">
                            <div>
                            { /*<TextField
                                id="licence_start_date"
                                label=""
                                type="date"
                                defaultValue={nowDateTime}
                                //className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={this.handleUser}
                            /> */}
                            <DatePicker
              onChange={this.handleStartDate}
              value={this.state.licence_start_date}
              customStyles={{dateInput:{borderWidth: 0}}}
            />
                            { /*<DatePicker
            calendarAriaLabel="Toggle calendar"
            clearAriaLabel="Clear value"
            dayAriaLabel="Day"
            monthAriaLabel="Month"
            nativeInputAriaLabel="Date"
            //onChange={this.handleStartDate}
            //value={this.state.licence_start_date}
            yearAriaLabel="Year"
                            /> */}
                            
                            
                                
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_start_date}/>  }</div>
                            </div>
                        </div>
                    </div>

                    <label className="mgT30 textBold" htmlFor="licence_periode_num">Period of Billing Cycle </label>
                    <div>
                        <div className="flex-container">
                            <div className="w30p"><input type="number" className="form-control" name="licence_periode_num_billcycle"
                                        placeholder={this.state.licence_periode_num_billcycle}
                                        value={this.state.licence_periode_num_billcycle}
                                        onChange={this.handleUserInput}  
                                        ref={(input) => this.textInputLicencePeriodeNumBillCycle = input}
                                        min="1"/> 
                           </div>
                            <div className="w70p mgL20">
                                <select name="licence_periode_unit_billcycle" className="form-control" 
                                    value={this.state.licence_periode_unit_billcycle} 
                                    onChange={this.handleUserSelect}
                                    ref={(input) => this.textInputLicencePeriodeUnitBillCycle = input}
                                    disabled={this.state.input_disabled}
                                >
                                    <option value="">-- please select --</option>
                                    <option value="Month">Month</option>
                                    <option value="Year">Year</option>
                                </select>
                            
                            </div>
                        </div>
                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_periode_num_billcycle}/>  }</div>
                           
                    </div>

                    <label className="mgT30 textBold" htmlFor="licence_periode_num">Period of Contract </label>
                    <div>
                        <div className="flex-container">
                            <div className="w30p"><input type="number" className="form-control" name="licence_periode_num"
                                        placeholder={this.state.licence_periode_num}
                                        value={this.state.licence_periode_num}
                                        onChange={this.handleUserInput}  
                                        ref={(input) => this.textInputLicencePeriodeNum = input}
                                        min="1"/> 
                           </div>
                            <div className="w70p mgL20">
                                <select name="licence_periode_unit" className="form-control" 
                                    value={this.state.licence_periode_unit} 
                                    onChange={this.handleUserSelect}
                                    ref={(input) => this.textInputLicencePeriodeUnit = input}
                                    disabled={this.state.input_disabled}
                                >
                                    <option value="">-- please select --</option>
                                    <option value="Month">Month</option>
                                    <option value="Year">Year</option>
                                </select>
                            
                            </div>
                        </div>
                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_periode_num}/>  }</div>
                           
                    </div>

                </div>
              </div>
              <div className="mgL20 mgR20 mgB20">
                <label className="mgT30 textBold" htmlFor="licence_remarks">Remarks</label>
                <div>
                    <div className="flex-container">
                    <textarea name="licence_remarks" className="form-control" 
                        rows={5} cols={30} 
                        /*placeholder={this.state.event_name}*/
                        value={this.state.licence_remarks} 
                        onChange={this.handleUserInput} 
                        ref={(input) => this.textInputLicenceRemarks = input}/> 
                    </div>
                </div>
              </div>
              <div className="row-field mgT30 mgB30">
                    <input type="hidden" required name="clientdoc_id" value={this.state.clientdoc_id} />
                    <button type="submit" className="w100p submitButton">Submit Licence</button>
              </div>
            </form>
            </div>
            <div id="loading-area" className="divHide loading"></div> 
        </div>
      );
    }
 }
  
export default LicenceForm;