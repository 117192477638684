import React, { Component } from 'react';
import axios from 'axios';
import '../../inc/genstyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import ReplaceAllContent from '../../inc/replace-all';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[35];

class ITutorForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        tutor_docid: '',
        tutor_name: '',
        tutor_email: '',
        tutor_bio: '',
        tutor_linkedin: '',
        tutor_picture: '',
        selectedFileDocument: null,
        formErrors: {tutor_name: '', tutor_email: '', tutor_bio: '', selectedFileDocument:''},
        tutornameValid: false,
        tutoremailValid: false,
        tutorbioValid: false,
        selectedFileDocumentValid: false,
        formValid: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitData = this.submitData.bind(this);
  }
  handleBack = () => {
    this.props.history.goBack();
  }
  handleEventDate = date => {
    this.setState( prevState => {
        return {  
            ...prevState.course_date, course_date: date
        }
        }, () => { this.validateField('course_date', date)
    }) 
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let tutornameValid = this.state.tutornameValid;
    let tutoremailValid = this.state.tutoremailValid;
    let tutorbioValid = this.state.tutorbioValid;
    let selectedFileDocumentValid = this.state.selectedFileDocumentValid;
    switch(fieldName) {
        case 'tutor_name': 
            tutornameValid = value.trim().length > 0;
            fieldValidationErrors.tutor_name = tutornameValid ? '' : 'name need to be filled';
            break;
        case 'tutor_email': 
            tutoremailValid = value.trim().length > 0;
            fieldValidationErrors.tutor_email = tutoremailValid ? '' : 'email need to be filled';
            break;
        case 'tutor_bio': 
            tutorbioValid = value.trim().length > 0;
            fieldValidationErrors.tutor_bio = tutorbioValid ? '' : 'bio need to be filled';
            break;
        default:
            break;
    }    
    this.setState({
        formErrors: fieldValidationErrors,
        tutornameValid: tutornameValid,
        tutoremailValid: tutoremailValid,
        tutorbioValid: tutorbioValid,
        selectedFileDocumentValid: selectedFileDocumentValid
    }, this.validateForm);
  }
  validateForm() {
    this.setState({
        formValid: this.state.tutornameValid && this.state.tutoremailValid && this.state.tutorbioValid &&
        this.state.selectedFileDocumentValid
    });
  }
  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  onChangeFileHandler = (e) => {
    if(e.target.files[0] !== undefined) {
        var inputType = e.target.files[0].type;
        var inputValue = e.target.files[0];
        var name = ''; 
        var validname = '';
        let fieldValidationErrors = this.state.formErrors;
        let selectedFileValid = false;
        if(inputType.indexOf('pdf') > -1 || inputType.indexOf('video') > -1|| inputType.indexOf('image') > -1) { //add image
            selectedFileValid = true;
        } else {
            selectedFileValid = false;
        }
        if(e.target.id === "fileDocument2") {
            name =  'selectedFileDocument2';
            validname = 'selectedFileDocument2Valid';
            fieldValidationErrors.selectedFileDocument2 = selectedFileValid ? '' : 'please upload video only';
        } else {
            name =  'selectedFileDocument';
            validname = 'selectedFileDocumentValid';
            fieldValidationErrors.selectedFileDocument = selectedFileValid ? '' : 'please upload image only';
        }        
        this.setState({
            [name]: inputValue,
            formErrors: fieldValidationErrors,
            [validname] : selectedFileValid
        }, this.validateForm);
    }
  }

  submitData(datasend,imgurl) {
    var urlFetch, datatext;
    var tutorName,tutorEmail,tutorBio,tutorLinkedin,tutorPicture;
    if(imgurl !== '' && imgurl !== undefined) { tutorPicture = imgurl; } else { tutorPicture = this.state.tutor_picture; }
    if(this.state.tutor_docid !== null && this.state.tutor_docid !== ''  && this.state.tutor_docid !== undefined) { 
        if(this.textInputTutorName.value === '') { tutorName = this.state.tutor_name; } else { tutorName = this.textInputTutorName.value; }
        if(this.textInputTutorEmail.value === '') { tutorEmail = this.state.tutor_email; } else { tutorEmail = this.textInputTutorEmail.value; }
        if(this.textInputTutorBio.value === '') { tutorBio = this.state.tutor_bio; } else { tutorBio = this.textInputTutorBio.value; }
        if(this.textInputTutorLinkedin.value === '') { tutorLinkedin = this.state.tutor_linkedin; } else { tutorLinkedin = this.textInputTutorLinkedin.value; }
        tutorBio = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',tutorBio));
        datatext ='{"tutor_name":"'+tutorName+'","tutor_email":"'+tutorEmail+'","tutor_bio":"'+tutorBio+'","tutor_linkedin":"'+tutorLinkedin+'","tutor_picture":"'+tutorPicture+'","tutor_docid":"'+this.state.tutor_docid+'","form_id":"form-edit"}';
        urlFetch = global.config.urlLink+"/tutoredit";
    } else {
        tutorBio = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',this.state.tutor_bio));
        datatext ='{"tutor_name":"'+this.state.tutor_name+'","tutor_email":"'+this.state.tutor_email+'","tutor_bio":"'+tutorBio+'","tutor_linkedin":"'+this.state.tutor_linkedin+'","tutor_picture":"'+tutorPicture+'","form_id":"form-add"}';
        urlFetch = global.config.urlLink+"/tutoradd";
    }
    var data = JSON.parse(datatext);
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') {
            var docId = resJson.values;
            window.location.href='/IEditDetailTutor/'+docId;
        } else {
            console.log('Something happened wrong');
        }

        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  handleSubmit(e) {
    e.preventDefault();    
    if(this.state.tutor_name !== '' && this.state.tutor_email !== '' && this.state.tutor_bio !== '') {
        document.getElementById('loading-area').style.display = 'block'; 
        var selectedFileValue = this.state.selectedFileDocument;
        if(selectedFileValue !== null && ((selectedFileValue.type.indexOf('pdf') > -1 || selectedFileValue.type.indexOf('video') > -1|| selectedFileValue.type.indexOf('image') > -1))) { 
            const data = new FormData() 
            data.append('file', selectedFileValue);
            data.append('docid', this.state.tutor_docid);
            data.append('source', 'tutor');
            axios.post(global.config.urlLink+"/uploadfile", 
            data, { 
                headers: { 
                    'content-type': 'multipart/form-data',
                    'Authorization': authKey
                }
            }).then(res => { 
                document.getElementById('loading-area').style.display = 'none'; 
                if(res.data.status === "200") {
                    this.submitData(this,res.data.values.linkfile1);
                } else {
                    document.getElementById('loading-area').style.display = 'none'; 
                }
            }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
            })
        } else {
            document.getElementById('loading-area').style.display = 'none'; 
            this.submitData(this,"","");
        }
    
    } else {
        var arrfieldName = ['tutor_name','tutor_email','tutor_bio','tutor_linkedin','selectedFileDocument'];
        var arrvalue = [this.textInputTutorName.value,this.textInputTutorEmail.value,this.textInputTutorBio.value,this.textInputTutorLinkedin.value,this.state.selectedFileDocument];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
    
  }
  componentDidMount() {
    document.getElementById('loading-area').style.display = 'block'; 
    document.getElementById('loading-area').style.display = 'none'; 
    var geturl = this.props.location; 
    var arrString = geturl.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');
    
    if(arrUrl[1] === 'IEditTutor') {
        if(arrUrl[2] !== '') { 
            fetch(global.config.urlLink+"/tutorview/"+arrUrl[2], {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
                }).then(res => {
                if(res.status === 200) 
                    return res.json() 
                }).then( resJson => {
                if(resJson.status === '200') {
                    var resultContent = resJson.values[0].content[0].maincontent;
                    var lName="",lEmail="",lBio="",lLinkedin="",lPicture="";
                    
                    if(resultContent.tutorName !== undefined) { lName = resultContent.tutorName; }
                    if(resultContent.tutorEmail !== undefined) { lEmail = resultContent.tutorEmail; }
                    if(resultContent.tutorBio !== undefined) {
                        lBio = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resultContent.tutorBio));
                    }
                    if(resultContent.tutorLinkedin !== undefined) { lLinkedin = resultContent.tutorLinkedin; }
                    if(resultContent.tutorPicture !== undefined) { 
                        lPicture = resultContent.tutorPicture;
                    }
                    this.setState({
                        stTitle: 'Edit Trainer',
                        tutor_docid: resJson.values[0].docid,
                        tutor_name: lName,
                        tutor_email: lEmail,
                        tutor_bio: lBio,
                        tutor_linkedin: lLinkedin,
                        tutor_picture: lPicture,
                        selectedFileDocument: null,
                        formErrors: {tutor_name: '', tutor_email: '', tutor_bio: '', selectedFileDocument:''},
                        tutornameValid: false,
                        tutoremailValid: false,
                        tutorbioValid: false,
                        selectedFileDocumentValid: false,
                        formValid: false,
                    }) 
                } else {
                    console.log('Something wrong happened');
                }
                }).catch(err => err);
        }
    } else if(arrUrl[1] === 'IAddTutor') {
        this.setState( prevState => {
            return {
                ...prevState.stTitle, stTitle: 'New Trainer'
            }
        })
    }    
  }
  render() {
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Trainers</div>
                {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[2] === "ON") || (JSON.parse(localStorage.getItem('urole'))[3] === "ON") || (JSON.parse(localStorage.getItem('urole'))[4] === "ON"))? 
                <div>
                <div className="stIndiTitle">Trainers { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="flex-container">
                            <div className="st2Title w70p">{ this.state.stTitle.includes("New")? 'Add ' : '' } {this.state.stTitle}</div>
                            <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        <div id="FormCenter" className="mgT20">
                            <div id="FormCourseInfo">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="flex-container">
                                        <input type="hidden" required name="tutor_docid" value={this.state.tutor_docid}/>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="tutor_name">Name</label>
                                        <div className="w70p">
                                            <input type="text" className="form-control" name="tutor_name"
                                                placeholder={this.state.tutor_name}
                                                value={this.state.tutor_name}
                                                onChange={this.handleUserInput}  
                                                ref={(input) => this.textInputTutorName = input}/>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.tutor_name}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="tutor_email">Email</label>
                                        <div className="w70p">
                                            <input type="text" className="form-control" name="tutor_email"
                                                placeholder={this.state.tutor_email ? this.state.tutor_email : ''}
                                                value={this.state.tutor_email ? this.state.tutor_email : ''}
                                                onChange={this.handleUserInput}
                                                ref={(input) => this.textInputTutorEmail = input}/>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.tutor_email}/>  }</div>
                                        </div>
                                    </div>                                    
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="tutor_bio">Bio</label>
                                        <div className="w70p">
                                            <textarea name="tutor_bio" className="form-control" 
                                                    rows={5} cols={30} 
                                                    value={this.state.tutor_bio} 
                                                    onChange={this.handleUserInput} 
                                                    ref={(input) => this.textInputTutorBio = input}/>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.tutor_bio}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="tutor_linkedin">Linked In</label>
                                        <div className="w70p">
                                            <input type="text" className="form-control" name="tutor_linkedin"
                                                placeholder={this.state.tutor_linkedin ? this.state.tutor_linkedin : ''}
                                                value={this.state.tutor_linkedin ? this.state.tutor_linkedin : ''}
                                                onChange={this.handleUserInput}
                                                ref={(input) => this.textInputTutorLinkedin = input}/>
                                        </div>
                                    </div>
                                    <div>
                                        {(this.state.tutor_picture !== "")? 
                                        <div className="row-field mgT20">
                                            <div className="labelFormL"></div>
                                            <div className="w70p"><img width="150" src={this.state.tutor_picture} alt=""/></div>
                                        </div>
                                        : '' }
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="tutor_document">Upload Picture</label>
                                        <div className="w70p">
                                            <input id="fileDocument" type="file"
                                                onChange={this.onChangeFileHandler}>
                                            </input> 
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>  }</div>
                                            <div className="mgT10 warningText">
                                            Please upload file in jpg, jpeg, png format.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT30 mgB30">
                                        <div className="labelFormL"></div>
                                        <div className="w70p"><button type="submit" className="w100p submitButton">Submit</button></div>
                                    </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>
                    {/* ====== */}
                  </div>  
                </div>
                :   
                <div><INA/></div>
                }
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div> 
    </div>
   );
  }
}
 
export default ITutorForm;