import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../cinc/cStyle.css';
import parse from 'html-react-parser';
import DateTimeFormats from '../../inc/date-time-format';
import ReplaceAllContent from '../../inc/replace-all';
import NAimage from '../../assets/na.png';
 
const authKey = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[28];

class CAppNewsDetail extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        st2Title: '',
        content_docid: '',
        content_createby: '',
        content_createdate: '',
        content_date: '',
        content_title: '',
        content_title_slug: '',
        content_short_desc: '',
        content_long_desc: '',
        content_picture: '',
        content_video: '',
        content_types: '',
        content_types_str: '',
        datalist: [],
        isSignin: false,
        hideNav: '',
    }
    this.loadData = this.loadData.bind(this);
  }
  resize() {
    let currentHideNav = (window.innerWidth <= 480);
    if (currentHideNav !== this.state.hideNav) {
        this.setState({hideNav: currentHideNav});
    }
  }
  handleBack = () => {
    this.props.history.goBack();
  }
  urldetailContent(valid) {
    window.location.href='/cnewsview/'+valid;
  }
  loadData(element,id) {
    if(element.toLowerCase() === "cappnewsview") {
      document.getElementById('loading-area').style.display = 'block'; 
      fetch(global.cconfig.urlLink+"/appcontentview/"+id, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          if(resJson.status === '200') {
              var cTitle,cTitleSlug,cShortDesc,cLongDesc,cTypes,cTypesStr,cPicture,cVideo,cDate,cPostedDate;
              var result = resJson.values[0].content[0].maincontent;
              
              if(result.contentTitle !== undefined) { 
                cTitle = result.contentTitle; 
                cTitleSlug = ReplaceAllContent(' ','-',cTitle)
              }
              if(result.contentShortDesc !== undefined) {
                  cShortDesc = ReplaceAllContent('[;]',',',result.contentShortDesc);
                  cShortDesc = ReplaceAllContent('<br/>','\n',cShortDesc);
              }
              if(result.contentLongDesc !== undefined) { 
                  cLongDesc = ReplaceAllContent('[;]',',',result.contentLongDesc);
                  cLongDesc = ReplaceAllContent('<br/>','\n',cLongDesc);
                  cLongDesc = ReplaceAllContent('<img ','<img style="max-width: 100%;"',cLongDesc);
                  cLongDesc = ReplaceAllContent('<a href','<a target="_blank" href',cLongDesc);
                  cLongDesc = ReplaceAllContent('<oembed url=','<center><iframe width="300" height="225" scrolling="no" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen src=',cLongDesc);
                  cLongDesc = ReplaceAllContent('</oembed>','</iframe></center>',cLongDesc);
                  
              }
              if(result.typeName !== undefined) { 
                  cTypes = result.typeName;
                  for(var t=0; t<global.cconfig.contentType.length; t++) {
                    if(cTypes === global.cconfig.contentType[t].type) { cTypesStr=global.cconfig.contentType[t].name; }
                  }
              }
              if(resJson.values[0].content[0].createdate !== undefined) {
                cDate = DateTimeFormats(resJson.values[0].content[0].createdate._seconds,"date-long");
              }
              if(result.contentDate !== undefined) {
                cPostedDate = DateTimeFormats(result.contentDate._seconds,"date-long");
              }
              if(result.contentPicture !== undefined && result.contentPicture !== "") { 
                cPicture = result.contentPicture;
              } else { cPicture = NAimage; }
              if(result.contentVideo !== undefined && result.contentVideo !== "") { 
                cVideo = result.contentVideo;
              }

              var datatext2 ='{"keyword":"","pageShow":"3","filter1":["'+cTypes+'"],"form_id":"pubonly"}';
              var data2 = JSON.parse(datatext2); 
    
              fetch(global.cconfig.urlLink+"/appcontentlist", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data2)
              }).then(response2 => {
                  if(response2.status === 200) {
                      return response2.json()
                  } else {
                      console.log('Something happened wrong');
                  }
              }).then(resJson2 => {
                  document.getElementById('loading-area').style.display = 'none'; 
                  var arrContentsList = [];
                  var arrContentSortByDesc = [];
                  if(resJson2.values.length > 0 && resJson2.status === '200') {
                      for(var v=0; v < resJson2.values.length; v++) {
                          arrContentsList.push({
                              docid: resJson2.values[v].docid,
                              content: resJson2.values[v].content,
                              contentdate: resJson2.values[v].content.contentDate,
                          })
                      }
                      if(arrContentsList.length>0) {
                          arrContentSortByDesc = arrContentsList.sort((c,d) => {
                              return d.contentdate._seconds - c.contentdate._seconds;
                          })
                      }
                  } 
                this.setState({
                    stTitle: 'Detail Content Insight',
                    st2Title: cTypesStr,
                    content_docid: resJson.values[0].docid,
                    content_createby: resJson.values[0].content[0].maincontent.createByName,
                    content_createdate: cDate,
                    content_date: cPostedDate,
                    content_title: cTitle,
                    content_title_slug: cTitleSlug,
                    content_short_desc: cShortDesc,
                    content_long_desc: cLongDesc,
                    content_picture: cPicture,
                    content_video: cVideo,
                    content_types: cTypes,
                    content_types_str: cTypesStr,
                    datalist: arrContentSortByDesc,
                    isSignin: true
                  }) 
              }).catch(err => {
                  document.getElementById('loading-area').style.display = 'none'; 
              });
          } else {
              document.getElementById('loading-area').style.display = 'none'; 
              console.log('Something happened wrong');
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
        }); 
    }
  }
 componentDidMount() {
    document.title = global.cconfig.webtitle;
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
        var geturl = this.props.location; 
        var arrString = geturl.pathname;
        var arrUrl = [];
        arrUrl = arrString.split('/');
        if(arrUrl[1].toLowerCase() === "cappnewsview") {
          if(arrUrl[2] !== '') { 
            this.loadData(arrUrl[1],arrUrl[2]);
          }
        }
 } 
 
 render() {
  var contentsList = [];
  if(this.state.datalist.length > 0) {
      var limlist = this.state.datalist.length;
      if(this.state.datalist.length > 3) { limlist = 3; }
      for(var ls=0; ls < limlist; ls++) {
          var retResult = this.state.datalist[ls];
          var cTitle="", cTitleSlug="", cPicture="";
          if(retResult.content.contentTitle !== undefined) { 
            cTitle = retResult.content.contentTitle;
            cTitleSlug = ReplaceAllContent(' ','-',cTitle); 
          }
          
          if(retResult.content.contentPicture !== undefined && retResult.content.contentPicture !== "") { 
            cPicture = retResult.content.contentPicture;
          } else { cPicture = NAimage; }
          if(this.state.content_docid !== retResult.docid) {
            contentsList.push(<li className="items-related col-md-4 cursor-on" onClick={this.urldetailContent.bind(this, retResult.docid)}>
                            
                <p className="news-related-thumbnail"><img alt={cTitleSlug} src={cPicture}/></p>
                <p className="news-related-title">{cTitle}</p>
            </li>)
          }
      }
  } else {
      contentsList.push(<div key="1" className="news-list-item w100p"></div>)
  }
   return (
    
      <div className="main-container border" style={{backgroundColor: "#FFFFFF"}}>
        <div className="w100p">
          <div className="cappmain-content">
            {(this.state.isSignin === true)? 
            <div className="mgB50 mgT30">
                      <div className="news-detail">
                        <p className="news-title">{this.state.content_title}</p>
                        <p className="news-info">{this.state.content_date} in {this.state.content_types_str}</p>
                        <p><img alt={this.state.content_title_slug} style={{maxWidth:"100%"}} src={this.state.content_picture}/></p>
                        <div className="news-detail-content textJustify ck-content">{parse(this.state.content_long_desc)}</div>
                      </div>
                    </div>
            : <div></div> }
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div>
      </div>
   );
 }
}
 
export default CAppNewsDetail;