import React, { Component } from 'react';
import parse from 'html-react-parser';
import '../../inc/genstyle.css';
import '../iinc/iGlobal';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import ReplaceAllContent from '../../inc/replace-all';
import DateTimeFormats from '../../inc/date-time-format';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Popup from '../../inc/popup';
import '../../inc/popup.css'
import LicenceForm from './ipoplicence-form';
import ContactPersonForm from './ipopcontactperson-form';
import defMemberPicture from '../../assets/pic-profile.png';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[1];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[6];
const authKey3 = global.config.authBearerKey+"##"+global.config.kindcode[0];
    
class IClientDetail extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        create_date: [],
        clientdoc_id: '',
        client_name: '',
        domain_name: '',
        client_country: '',
        client_address: '',
        client_phone: '',
        client_licence_list: [],
        client_pic_list: [],
        client_am_list: [],
        client_log_list: [],
        styleTb: 'table',
        isOpen: false,
        isOpen2: false,
        otherContent2: '',
        stPopTitle2: '',
        allow_set_contactp: false,
        allow_set_am: false,
        allow_set_licence: false
    }
    this.loadClientData = this.loadClientData.bind(this);
  }
  loadClientData(element,id) {
    if(element === "IViewClients") {
      document.getElementById('loading-area').style.display = 'block'; 
      fetch(global.config.urlLink+"/clientview/"+id, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') {
              var cName,domainName,cCountry,cAddress,cPhone,cDatez;
              var allw_set_contactp = false, allw_set_am = false, allw_set_licence = false;
              if(resJson.values[0].content[0].maincontent.clientName !== undefined) { cName = resJson.values[0].content[0].maincontent.clientName; }
              if(resJson.values[0].content[0].maincontent.domainName !== undefined) { domainName = resJson.values[0].content[0].maincontent.domainName; }
              if(resJson.values[0].content[0].maincontent.clientCountry !== undefined) { cCountry = resJson.values[0].content[0].maincontent.clientCountry; }
              if(resJson.values[0].content[0].maincontent.clientAddress !== undefined) {
                  cAddress = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resJson.values[0].content[0].maincontent.clientAddress));
              }
              if(resJson.values[0].content[0].maincontent.clientPhone !== undefined) { cPhone = resJson.values[0].content[0].maincontent.clientPhone; }
              if(resJson.values[0].content[0].maincontent.createDate !== undefined) {
                cDatez = DateTimeFormats(resJson.values[0].content[0].maincontent.createDate._seconds,"date-long");
              }
              if(
                (localStorage.getItem('ulevel') === global.config.userlevel[0])  || 
                (JSON.parse(localStorage.getItem('urole'))[7] !== undefined && JSON.parse(localStorage.getItem('urole'))[7] === "ON")
              ) { allw_set_contactp = true; }
              if(
                (localStorage.getItem('ulevel') === global.config.userlevel[0])  || 
                (JSON.parse(localStorage.getItem('urole'))[7] !== undefined && JSON.parse(localStorage.getItem('urole'))[7] === "ON")
              ) { allw_set_am = true; }
              if(
                (localStorage.getItem('ulevel') === global.config.userlevel[0])  || 
                (JSON.parse(localStorage.getItem('urole'))[1] !== undefined && JSON.parse(localStorage.getItem('urole'))[1] === "ON")  || 
                (JSON.parse(localStorage.getItem('urole'))[7] !== undefined && JSON.parse(localStorage.getItem('urole'))[7] === "ON")
              ) { allw_set_licence = true; }
              
              var arrLogList = resJson.values[0].s3content;
              var arrLogSortByAsc = [];
              arrLogSortByAsc = arrLogList.sort((c,d) => {
                return c.subcontent.createDate._seconds - d.subcontent.createDate._seconds;
              })

              this.setState({
                  stTitle: 'Detail Client',
                  create_date: cDatez,
                  clientdoc_id: resJson.values[0].docid,
                  client_name: cName,
                  domain_name: domainName,
                  client_country: cCountry,
                  client_address: cAddress,
                  client_phone: cPhone,
                  client_licence_list: resJson.values[0].scontent,
                  client_pic_list: resJson.values[0].s2content,
                  client_am_list: resJson.values[0].spcontent,
                  client_log_list: arrLogSortByAsc,
                  isOpen: false,
                  isOpen2: false,
                  otherContent2: '',
                  stPopTitle2: '',
                  allow_set_contactp: allw_set_contactp,
                  allow_set_am: allw_set_am,
                  allow_set_licence: allw_set_licence
              }) 
          } else {
              console.log('Something happened wrong');
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
        }); 
    }
  }

  handleBack = () => {
    this.props.history.goBack();
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false,
      }
    })
  }
  urlremoveAMClient(cId,mId,valId) {
    var urlString = global.config.urlLink+"/userremoveam/"+valId;
    fetch(urlString, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey3,
        }
    }).then(response => {
        if(response.status === 200) {
            window.location.reload();
        } else {
            console.log('Something happened wrong delete');
        }
    }).catch(err => err);

  }
  urladdLicence(valId) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: true,
      }
    })
  }
  urladdPicClient(valId) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen2, isOpen2: true,
          ...prevState.stPopTitle2, stPopTitle2: 'Add New',
          ...prevState.otherContent2, otherContent2: '{"stPopTitle":"Add","clientpic_id":"'+valId+'","member_id":"","member_name":"","member_email":"","member_position":"","member_phone":""}', 
      }
    })
  }
  
  urlsetAdminClient(valId,mId,mName,mEmail,mPosition,mPhone,statSet) {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"member_docid":"'+mId+'","client_docid":"'+valId+'","member_name":"'+mName+'","member_email":"'+mEmail+'","member_phone":"'+mPhone+'","member_position":"'+mPosition+'","flagSet":"'+statSet+'","form_id":"form-setadmin"}';
    var urlFetch = global.config.urlLink+"/membersetadmin";
    var data = JSON.parse(datatext); 
    fetch(urlFetch, {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey2,
      }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
            window.location.reload();
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => {
      document.getElementById('loading-area').style.display = 'none'; 
    });
    
  }
  urleditPicClient(valId, mId, mName, mEmail, mPosition, mPhone) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen2, isOpen2: true,
          ...prevState.stPopTitle2, stPopTitle2: 'Edit',
          ...prevState.otherContent2, otherContent2: '{"stPopTitle":"Edit","clientpic_id":"'+valId+'","member_id":"'+mId+'","member_name":"'+mName+'","member_email":"'+mEmail+'","member_position":"'+mPosition+'","member_phone":"'+mPhone+'"}', 
      }
    })
  }
  urldeactivePicClient(valId, mId) {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"member_docid":"'+mId+'","client_docid":"'+valId+'","flagActive":false,"form_id":"form-deactive"}';
    var urlFetch = global.config.urlLink+"/memberdeactive";
    var data = JSON.parse(datatext); 
    fetch(urlFetch, {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey2,
      }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
            window.location.reload();
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => {
      document.getElementById('loading-area').style.display = 'none'; 
    });

  }
  componentDidMount() {
    var geturl = this.props.location; 
    var arrString = geturl.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');
    
    if(arrUrl[1] === 'IViewClients') {
        if(arrUrl[2] !== '') { 
            this.loadClientData(arrUrl[1],arrUrl[2]);
        }
    } 
  }

  licClientList() {
    var arrLicenceList = [];
    if(this.state.client_licence_list.length > 0) {
      for(var ls=0; ls < this.state.client_licence_list.length; ls++) {
          var retResult = this.state.client_licence_list[ls];
          var viewURL = "/IViewLicence/"+this.state.clientdoc_id+"/"+retResult.subdocid;
          var expDatez = "";
          if(retResult.subcontent.endPeriode !== undefined) {
               expDatez = DateTimeFormats(retResult.subcontent.endPeriode._seconds,"date-short");
          }
          var trcls = '';
          arrLicenceList.push(<tr key={ls+1} className={trcls}>
              <th scope="row">{ls+1}</th>
              <td>{retResult.subcontent.licenceType}</td>
              <td>{retResult.subcontent.licenceTotalSeat}</td>
              <td>{expDatez}</td>
              <td>{retResult.subcontent.licenceStatus}</td>
              <td>
                <a href={viewURL}><DescriptionIcon className="iconGrey mgR20" alt="aaa" /></a>
              </td>
            </tr>)
      }
    } else {
        arrLicenceList.push(<tr key="0">
            <td colSpan="6" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
          </tr>)
    }
    return arrLicenceList;
  }
  picClientList() {
    var arrPicList = [];
    if(this.state.client_pic_list.length > 0) { 
      for(var ls2=0; ls2 < this.state.client_pic_list.length; ls2++) {
          var retResult2 = this.state.client_pic_list[ls2];
          var detailInfo = "", valButton="";
          if(retResult2.subcontent.contactPhone !== "-" && retResult2.subcontent.contactPhone !== "") {
            detailInfo +='<br/><span>'+retResult2.subcontent.contactPhone+'</span>';
          }
          if(retResult2.subcontent.contactPosition !== "-" && retResult2.subcontent.contactPosition !== "") {
            detailInfo +='<br/><span>'+retResult2.subcontent.contactPosition+'</span>';
          }
          if(retResult2.subcontent.contactLevel === "admin") { valButton = "remove from admin portal"; }
          else { valButton = "set as admin portal"; }
          
          arrPicList.push(<li key={ls2+1} className="eventContent flex-container">
                          <div className="w100p">
                            <div>
                              <p className="event">{retResult2.subcontent.contactName}</p>
                              <p className="eventDate">
                                {retResult2.subcontent.contactEmail}
                                {parse(detailInfo)}
                              </p>
                            </div>
                            <div className="mgB10"><button className="trspButton" onClick={this.urlsetAdminClient.bind(this, this.state.clientdoc_id,retResult2.subdocid,retResult2.subcontent.contactName,retResult2.subcontent.contactEmail,retResult2.subcontent.contactPosition,retResult2.subcontent.contactPhone,retResult2.subcontent.contactLevel)}>{valButton}</button></div>
                          </div>
                          <div className="w50x flex-container">
                            <EditIcon className="iconGrey mgR05 cursor-on" alt="edit" style={{fontSize:"15pt"}} onClick={this.urleditPicClient.bind(this, this.state.clientdoc_id, retResult2.subdocid, retResult2.subcontent.contactName, retResult2.subcontent.contactEmail, retResult2.subcontent.contactPosition, retResult2.subcontent.contactPhone)}/>
                          </div>
                        </li>);
      }
    } else {
      arrPicList.push(<div key="0">
            <div className="mgT20 mgB20 textCenter">:: no record ::</div>
          </div>)
    }
    return arrPicList;
  }
  
  amClientList() {
    var arrAMClientList = [];
    if(this.state.client_am_list.length > 0) { 
      for(var ls3=0; ls3 < this.state.client_am_list.length; ls3++) {
          var retResult3 = this.state.client_am_list[ls3];
          if(retResult3.specialcontent1 !== undefined) {
            var picPhoto = '';
            if(retResult3.specialcontent1.userPicture !== undefined && retResult3.specialcontent1.userPicture !== '') {
                picPhoto = retResult3.specialcontent1.userPicture;
            } else { picPhoto = defMemberPicture; }
          
            arrAMClientList.push(<li key={ls3+1} className="eventContent flex-container">
                          <div className="mgR10 w20p"><img src={picPhoto} className="memberListPhoto" alt={retResult3.specialcontent1.userFullname}/></div>
                          <div className="w80p">
                            <p className="event">{retResult3.specialcontent1.userFullname}</p>
                            <p className="eventDate">{retResult3.specialcontent1.userEmail}<br/><span>{retResult3.specialcontent1.userPosition}</span></p>
                          </div>
                          {(this.state.allow_set_am === true)? 
                          <div className="w50x flex-container">
                            <DeleteIcon className="iconGrey cursor-on" alt="remove" style={{fontSize:"15pt"}} onClick={this.urlremoveAMClient.bind(this, this.state.clientdoc_id, retResult3.specialcontent.userId, retResult3.specialdocid)}/>
                          </div>
                          : <div className="w50x flex-container"></div> }
                        </li>);
          }
      }
      
    } 
    if(arrAMClientList.length === 0) {
      arrAMClientList.push(<div key="0">
            <div className="mgT20 mgB20 textCenter">:: no record ::</div>
          </div>)
    }
    return arrAMClientList;  
  }
  logClientList() {
    var arrLogList = [];
    if(this.state.client_log_list.length > 0) {
      for(var ls4=0; ls4 < this.state.client_log_list.length; ls4++) {
          var retResult4 = this.state.client_log_list[ls4];
          var cDatez = "", cTimez = "", descriptionz="";
          if(retResult4.subcontent.createDate !== undefined) {
               cDatez = DateTimeFormats(retResult4.subcontent.createDate._seconds,"date-short");
               cTimez = DateTimeFormats(retResult4.subcontent.createDate._seconds,"time-24-second");
          }
          
          var s2HeadDesc = ["Client Name", "Domain Name", "Country"];
          var contentDesc = [retResult4.subcontent.clientName,retResult4.subcontent.domainName,retResult4.subcontent.clientCountry];
          for(var q=0; q<s2HeadDesc.length; q++) {
            if(contentDesc[q] !== undefined && contentDesc[q].before !== contentDesc[q].after) { 
              if(descriptionz !== "") { descriptionz += "<br/><br/>"; }
              descriptionz += '<b>'+s2HeadDesc[q]+'</b>';
              descriptionz += '<br/>Before : '+contentDesc[q].before;
              descriptionz += '<br/>After : '+contentDesc[q].after;
              
            }  
          }
          var trcls = '';
          arrLogList.push(<tr key={ls4+1} className={trcls}>
              <th scope="row">{ls4+1}</th>
              <td>{cDatez}<br/>{cTimez}</td>
              <td>{retResult4.subcontent.createByName}</td>
              <td>{parse(descriptionz)}</td>
            </tr>)
      }
    } else {
      arrLogList.push(<tr key="0">
            <td colSpan="6" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
          </tr>)
    }
    return arrLogList;
  }
  render() {
    var suppContentPop = '{"country":"'+this.state.client_country+'"}';
    var licenceList=this.licClientList();
    var picList=this.picClientList();
    var amList = this.amClientList();
    var logList = this.logClientList();
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Clients</div>
                {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[0] === "ON") || (JSON.parse(localStorage.getItem('urole'))[1] === "ON")  || (JSON.parse(localStorage.getItem('urole'))[7] === "ON"))? 
                <div>
                  <div className="stIndiTitle">Clients { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                  <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="flex-container">
                            <div className=" w70p">
                              <h4>{this.state.client_name}</h4>
                              <h5 className="detailJoined">Date joined : {this.state.create_date}</h5>
                            </div>
                            <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        <div id="details" className="mgT20">
                            <div className="row">
                              <div className="col-6">
                                <div id="client-info">
                                  <div className="row">
                                    <div className="col-3"><p>Domain :</p></div>
                                    <div className="col-9">
                                      <div className="textBold textItalic">{this.state.domain_name}</div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-3"><p>Address :</p></div>
                                    <div className="col-9">
                                      <div>{this.state.client_address}</div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-3"><p>Country :</p></div>
                                    <div className="col-9">
                                      <div>{this.state.client_country}</div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-3"><p>Phone :</p></div>
                                    <div className="col-9">
                                      <div>{this.state.client_phone}</div>
                                    </div>
                                  </div>                                  
                                </div>
                                <div id="am-info" className="w100p mgT30">
                                    <div className="flex-container">
                                      <div className="w70p"><h6>Account Representative</h6></div>
                                      {(this.state.allow_set_am === true)? 
                                        <div className="w30p divRight">
                                        </div>
                                      : <div className="w30p divRight"></div> }
                                    </div>
                                    <div className="picContainer w100p">
                                    {amList}
                                    </div>
                                    
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="flex-container">
                                  <div className="w70p"><h6>Person In Charge</h6></div>
                                  <div className="w30p divRight">
                                    {(this.state.allow_set_contactp === true)? 
                                    <div className="mgR05 linkText cursor-on" onClick={this.urladdPicClient.bind(this, this.state.clientdoc_id)}><PersonAddIcon className="mgB05" style={{fontSize:"14pt", marginTop: "-3px"}}></PersonAddIcon> <span style={{marginTop:"3px"}}>Add &raquo;</span></div>
                                    : '' }
                                  </div>
                                </div>
                                <div className="picContainer w100p" style={{maxHeight:"310px"}}>
                                {picList}
                                </div>
                              
                              </div>
                            </div>
                            <div className="mgT30" id="licence-area">
                              <div className="flex-container">
                                <div className="w70p st3Title divMiddle">Licences</div>
                                <div className="w30p divRight">
                                  <button className="genButton mgL20" onClick={this.urladdLicence.bind(this, this.state.clientdoc_id)}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>Add New Licence</button>
                                </div>
                              </div>
                              <div className="mgT20">
                                <table className="table table-hover">
                                  <thead className="thead-light">
                                      <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Licence Type</th>
                                      <th scope="col">Number of Seat(s)</th>
                                      <th scope="col">Expiry Date</th>
                                      <th scope="col">Status</th>
                                      <th scope="col">Action</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {licenceList}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            {(localStorage.getItem('ulevel') === global.config.userlevel[0])? 
                            <div className="mgT50" id="log-area">
                              <div className="flex-container">
                                <div className="w70p st3Title divMiddle">Log</div>
                                <div className="w30p divRight"></div>
                              </div>
                              <div className="mgT20">
                                <table className="table table-hover">
                                  <thead className="thead-light">
                                      <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Date</th>
                                      <th scope="col">Update By</th>
                                      <th scope="col">Description</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {logList}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            : '' }
                            <div className="row mgT50">
                              
                            
                            </div>
                        </div>
                    </div>
                    <div>
                    {this.state.isOpen && <Popup
                      content={<><LicenceForm/></>}
                      id={this.state.clientdoc_id} 
                      title={"Add New Licence"}
                      others={suppContentPop}
                      styles={"50%"}
                      handleClose={this.togglePopClose.bind(this, this.state.clientdoc_id)}
                    />}
                    {this.state.isOpen2 && <Popup
                      content={<><ContactPersonForm/></>}
                      id={this.state.clientdoc_id} 
                      title={this.state.stPopTitle2 + " Person In Charge"}
                      others={this.state.otherContent2}
                      styles={"50%"}
                      handleClose={this.togglePopClose.bind(this, this.state.clientdoc_id)}
                    />}
                    </div>
                    {/* ====== */}
                  </div>
                  
              </div>
              : <div><INA/></div>
            }
          </div>
          <div id="loading-area" className="divHide loading"></div>
        </div>
    </div>
   );
  }
}
 
export default IClientDetail;