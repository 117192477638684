import React, { Component } from 'react';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import DateTimeFormats from '../../inc/date-time-format';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[3];

class ILicenceTypeDetail extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: 'Detail',
        licencedoc_id: '',
        licence_name: '',
        licence_price: '',
        licence_category: '',
        licence_region: '',
        licence_currency: '',
        content_client_list: [],
        styleTb: 'table',
    }
  }
  
  componentDidMount() {
    document.getElementById('loading-area').style.display = 'block'; 
    var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
    if(othersContent.lictypeId !== '') {
        fetch(global.config.urlLink+"/licencetypeview/"+othersContent.lictypeId, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }
          }).then(res => {
              if(res.status === 200) 
                  return res.json() 
          }).then( resJson => {
              document.getElementById('loading-area').style.display = 'none'; 
              if(resJson.status === '200') {
                  var listClientLicence = [];
                  if(resJson.values.companyData.length > 0) {
                    resJson.values.companyData.forEach(docsnapshot => {
                        if(docsnapshot.clientlicencelist.length > 0) {
                            var cities = docsnapshot.clientlicencelist;
                            var result = cities.filter(city => city.clientliccontent.licenceType === resJson.values.licenceData[0].content[0].maincontent.licenceType);
                            if(result.length > 0) {
                                listClientLicence.push({
                                    "companyData": {
                                        "clientid": docsnapshot.clientdocid,
                                        "clientcontent": docsnapshot.clientcontent
                                    },
                                    "licenceData": result
                                });
                            }
                        }
                    });
                  }
                  this.setState({
                    stTitle: 'Detail',
                    licencedoc_id: resJson.values.licenceData[0].docid,
                    licence_name: resJson.values.licenceData[0].content[0].maincontent.licenceType,
                    licence_price: resJson.values.licenceData[0].content[0].maincontent.licencePrice,
                    licence_category: resJson.values.licenceData[0].content[0].maincontent.licenceCategory,
                    licence_region: resJson.values.licenceData[0].content[0].maincontent.licencePriceForCountry,
                    licence_currency: resJson.values.licenceData[0].content[0].maincontent.licenceCurrency,
                    content_client_list: listClientLicence,
                    styleTb: 'table',
                  }) 
              } else {
                  console.log('Something happened wrong');
              }
          }).catch(err => { 
              document.getElementById('loading-area').style.display = 'none'; 
          }); 
    }  
  }

  render() {
   var clientLicenceList = [];
   if(this.state.content_client_list.length > 0) {
    for(var ls=0; ls < this.state.content_client_list.length; ls++) {
        var retResult = this.state.content_client_list[ls];
        var viewURL = "/IViewLicence/"+retResult.companyData.clientid+"/"+retResult.licenceData[0].clientlicdocid;
        var expDatez = "";
        if(retResult.licenceData[0].clientliccontent.endPeriode !== undefined) {
            expDatez = DateTimeFormats(retResult.licenceData[0].clientliccontent.endPeriode._seconds,"date-short");
        }
        clientLicenceList.push(<tr key={ls+1}>
          <th scope="row">{ls+1}</th>
          <td>{retResult.companyData.clientcontent.clientName}<br/><span className="font-sublist">{retResult.companyData.clientcontent.domainName}</span><br/><span className="font-sublist">{retResult.companyData.clientcontent.clientCountry}</span></td>
      
          <td>{retResult.licenceData[0].clientliccontent.licenceType}</td>
          <td>{expDatez}</td>
          <td>{retResult.licenceData[0].clientliccontent.licenceStatus}</td>
          <td><a href={viewURL}><RemoveRedEyeIcon className="iconGrey cursor-on"></RemoveRedEyeIcon></a></td>
        </tr>)
    }
   } else {
    clientLicenceList.push(<tr key="0">
          <td colSpan="6" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
        </tr>)
   }
   return (
    <div className="main-container">
      <div className="mgT30">
            <div className="row-field mgT20">
                <label className="w30p textMiddle" htmlFor="licence_category">Category  :</label>
                <div className="w70p">{this.state.licence_category} </div>
            </div>
            <div className="row-field mgT20">
                <label className="w30p textMiddle" htmlFor="licence_name">Licence Name :</label>
                <div className="w70p">{this.state.licence_name} </div>
            </div>
            <div className="row-field mgT20">
                <label className="w30p textMiddle" htmlFor="licence_price">Pricing for each Licence  :</label>
                <div className="w70p">{this.state.licence_currency} {this.state.licence_price} </div>
            </div>
            
            <div className="mgT30" id="licence-area">
                <div className="mgT20">
                    <table className="table table-hover">
                    <thead className="thead-light">
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Client Name</th>
                        <th scope="col">Licence Name</th>
                        <th scope="col">Expired Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientLicenceList}
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div id="loading-area" className="divHide loading"></div>
    </div>
   );
  }
}
 
export default ILicenceTypeDetail;