const DateTimeFormats = (dateSeconds,type) => {
    var timezone = 'en-GB';
    var datetimeoption = '{"year":"numeric", "month": "long", "day": "2-digit", "hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": false, "timeZone": "Asia/Jakarta"}';
    if(type === "datetime-short") { 
        datetimeoption = '{"year":"numeric", "month": "short", "day": "2-digit", "hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": false, "timeZone": "Asia/Jakarta"}';
    } else if(type === "datetime-long") { 
        datetimeoption = '{"year":"numeric", "month": "long", "day": "2-digit", "hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": false, "timeZone": "Asia/Jakarta"}';
    } else if(type === "date-long") { 
        datetimeoption = '{"year":"numeric", "month": "long", "day": "2-digit", "hour12": false, "timeZone": "Asia/Jakarta"}';
    } else if(type === "date-short") { 
        datetimeoption = '{"year":"numeric", "month": "short", "day": "2-digit", "hour12": false, "timeZone": "Asia/Jakarta"}';
    } else if(type === "time-24") { 
        datetimeoption = '{"hour": "numeric", "minute": "numeric", "hour12": false, "timeZone": "Asia/Jakarta"}';
    } else if(type === "time-24-second") { 
        datetimeoption = '{"hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": false, "timeZone": "Asia/Jakarta"}';
    } else if(type === "time-12") { 
        datetimeoption = '{"hour": "numeric", "minute": "numeric", "hour12": true, "timeZone": "Asia/Jakarta"}';
    } else if(type === "time-12-second") { 
        datetimeoption = '{"hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": true, "timeZone": "Asia/Jakarta"}';
    } else if(type === "month-long") { 
        datetimeoption = '{"month": "long", "hour12": false, "timeZone": "Asia/Jakarta"}';
    } else if(type === "year-long") { 
        datetimeoption = '{"year":"numeric", "hour12": false, "timeZone": "Asia/Jakarta"}';
    }
    var dtSeconds = dateSeconds*1000;
    var date_ob = new Date(dtSeconds);
    var resDateTimeFormat = new Intl.DateTimeFormat(timezone, JSON.parse(datetimeoption)).format(new Date(date_ob));
    return resDateTimeFormat;
}
export default DateTimeFormats;