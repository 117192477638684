import React, { Component } from 'react';
import parse from 'html-react-parser';
import Accordion from 'react-bootstrap/Accordion';
import '../../inc/genstyle.css';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import Popup from '../../inc/popup';
import '../../inc/popup.css'
import ILibraryLessonForm from './ilibrary-lesson-form';
import ILibraryAttachForm from './ilibrary-attach-form';
import ILibraryAttachEdit from './ilibrary-attach-edit';
import ILibraryExamEdit from './ilibrary-exam-edit';
import ILibraryQuestionForm from './ilibrary-question-form';
import ILibraryQuestionImport from './ilibrary-exam-import';
import ReplaceAllContent from '../../inc/replace-all';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import NAimage from '../../assets/na.png';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[9];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[12];

class ILibraryDetailEdit extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: 'Edit Library',
        library_docid: '',
        library_title: '',
        library_short_desc: '',
        library_long_desc: '',
        library_language: '',
        library_picture: '',
        library_video: '',
        library_category: '',
        library_subcategory: '',
        library_lesson_list: [],
        library_exam: [],
        library_question_list: [],
        isOpen: false,
        isOpen2: false,
        isOpen3: false,
        isOpen4: false,
        isOpen5: false,
        isOpen6: false,
        stPopTitle: '',
        stPopTitle2: '',
        stPopTitle3: '',
        stPopTitle4: '',
        stPopTitle5: '',
        stPopTitle6: '',
        otherContent: '',
        otherContent2: '',
        otherContent3: '',
        otherContent4: '',
        otherContent5: '',
        otherContent6: ''
    }
    this.loadData = this.loadData.bind(this);
    this.deleteDataLesson = this.deleteDataLesson.bind(this);
  }

  handleBack = () => {
    this.props.history.goBack();
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false,
          ...prevState.isOpen3, isOpen3: false,
          ...prevState.isOpen4, isOpen4: false,
          ...prevState.isOpen5, isOpen5: false,
          ...prevState.isOpen6, isOpen6: false
      }
    })
  }  
  urlnewLesson(valId,subvalId,action) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: true,
          ...prevState.isOpen2, isOpen2: false,
          ...prevState.isOpen3, isOpen3: false,
          ...prevState.isOpen4, isOpen4: false,
          ...prevState.isOpen5, isOpen5: false,
          ...prevState.isOpen6, isOpen6: false,
          ...prevState.stPopTitle, stPopTitle: action+" Module",
          ...prevState.otherContent, otherContent : '{"libraryId":"'+valId+'","libraryTitle":"'+this.state.library_title+'","liblessonid":"'+subvalId+'"}'
      }
    })
  }
  deleteDataLesson(valId,subvalId) {
    var urlString = global.config.urlLink+"/librarylessondelete";
    var datatext ='{"docid":"'+valId+'","subdocid":"'+subvalId+'","numchild":1}';
    var data = JSON.parse(datatext); 
    fetch(urlString, {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': authKey,
      }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === "200") {
          window.location.reload();
        } else {
          console.log('Something happened wrong delete');
        }
    }).catch(err => { 
      document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  urldeleteLesson(valId,subvalId,filesList) {
    var stat = false;
    stat = window.confirm("Are you sure you want to delete this module?");
    if(stat === true) {
      document.getElementById('loading-area').style.display = 'block'; 
      if(filesList !== "") {
        var dataFile = JSON.parse('{"fileid":"'+filesList+'"}');
        fetch(global.config.urlLink+"/deletefile", {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }, body : JSON.stringify(dataFile)
        }).then(res2 => {
            if(res2.status === 200) 
                return res2.json() 
        }).then( resJson2 => {
            if(resJson2.status === '200') { 
                this.deleteDataLesson(valId,subvalId);
            } else {
                console.log('Something happened wrong');
            }
        }).catch(err => {
            document.getElementById('loading-area').style.display = 'none'; 
        });
      } else {
        this.deleteDataLesson(valId,subvalId);
      }
        
        
    }
  }

  urlnewAttachment(valId,subvalId,subsubvalId,action) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: true,
          ...prevState.isOpen3, isOpen3: false,
          ...prevState.isOpen4, isOpen4: false,
          ...prevState.isOpen5, isOpen5: false,
          ...prevState.isOpen6, isOpen6: false,
          ...prevState.stPopTitle2, stPopTitle2: action+" Attachment",
          ...prevState.otherContent2, otherContent2: '{"libraryId":"'+valId+'","libraryTitle":"'+this.state.library_title+'","libraryLessonId":"'+subvalId+'","libraryAttachId":"'+subsubvalId+'"}'
      
      }
    })
  }
  urleditAttachment(valId,subvalId,subsubvalId,action,libAttachUrl) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false,
          ...prevState.isOpen3, isOpen3: true,
          ...prevState.isOpen4, isOpen4: false,
          ...prevState.isOpen5, isOpen5: false,
          ...prevState.isOpen6, isOpen6: false,
          ...prevState.stPopTitle3, stPopTitle3: action+" Attachment",
          ...prevState.otherContent3, otherContent3: '{"libraryId":"'+valId+'","libraryTitle":"'+this.state.library_title+'","libraryLessonId":"'+subvalId+'","libraryAttachId":"'+subsubvalId+'","libraryAttachUrl":"'+libAttachUrl+'"}'
      
      }
    })
  }
  
  urldeleteAttachment(valId,subvalId,subsubvalId,fileId) {
    var stat = false;
    stat = window.confirm("Are you sure you want to delete this attachment?");
    if(stat === true) {
      document.getElementById('loading-area').style.display = 'block'; 
      var urlString = global.config.urlLink+"/libraryattachmentdelete";
      var datatext ='{"docid":"'+valId+'","subdocid":"'+subvalId+'","subsubdocid":"'+subsubvalId+'","numchild":0}';
      var data = JSON.parse(datatext); 
      fetch(urlString, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
      }).then(res => {
          if(res.status === 200) 
              return res.json() 
      }).then( resJson => {
          if(resJson.status === "200") {
            var dataFile = JSON.parse('{"fileid":"'+fileId+'"}');
            fetch(global.config.urlLink+"/deletefile", {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': authKey,
              }, body : JSON.stringify(dataFile)
            }).then(res2 => {
                if(res2.status === 200) 
                    return res2.json() 
            }).then( resJson2 => {
                if(resJson2.status === '200') { 
                    document.getElementById('loading-area').style.display = 'none'; 
                    window.location.reload();
                } else {
                    console.log('Something happened wrong');
                }
            }).catch(err => {
                document.getElementById('loading-area').style.display = 'none'; 
            });
          } else {
            document.getElementById('loading-area').style.display = 'none'; 
            console.log('Something happened wrong delete');
          }
      }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
      });
    }
  }
  urleditExam(valId,subvalId,action) {
    subvalId = subvalId !== undefined ? subvalId : "";
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false,
          ...prevState.isOpen3, isOpen3: false,
          ...prevState.isOpen4, isOpen4: true,
          ...prevState.isOpen5, isOpen5: false,
          ...prevState.isOpen6, isOpen6: false,
          ...prevState.stPopTitle4, stPopTitle4: action+" Exam",
          ...prevState.otherContent4, otherContent4: '{"libraryId":"'+valId+'","libraryTitle":"'+this.state.library_title+'","examId":"'+subvalId+'","examTitle":"'+this.state.library_exam.examTitle+'","examDesc":"'+this.state.library_exam.examDesc+'","examPassScore":"'+this.state.library_exam.passingScore+'","examNumQuestion":"'+this.state.library_exam.numberQuestion+'","examStatus":"'+this.state.library_exam.examStatus+'"}'
      }
    })
  }

  urlpublishExam(valId,nextstat) {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"library_id":"'+this.state.library_docid+'","libexam_id":"'+valId+'","libexam_title":"'+this.state.library_exam.examTitle+'","libexam_number":0,"libexam_desc":"'+this.state.library_exam.examDesc+'","libexam_pass_score":"'+this.state.library_exam.passingScore+'","libexam_num_question":"'+this.state.library_exam.numberQuestion+'","libexam_status":'+nextstat+',"form_id":"form-editexam"}';
    var data = JSON.parse(datatext);

    var urlFetch = global.config.urlLink+"/libraryexamedit";
    fetch(urlFetch, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey2,
      }, body : JSON.stringify(data)
    }).then(res => {
      if(res.status === 200) return res.json() 
    }).then( resJson => {
      document.getElementById('loading-area').style.display = 'none'; 
      if(resJson.status === '200') {
        window.location.href='/IEditDetailLibrary/'+this.state.library_docid;
      } else {
        console.log('Something happened wrong');
      }
    }).catch(err => {
      document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  urlimportExam(valId,subvalId,subsubvalId,action) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false,
          ...prevState.isOpen3, isOpen3: false,
          ...prevState.isOpen4, isOpen4: false,
          ...prevState.isOpen5, isOpen5: false,
          ...prevState.isOpen6, isOpen6: true,
          ...prevState.stPopTitle6, stPopTitle6: action+" Question",
          ...prevState.otherContent6, otherContent6: '{"libraryId":"'+valId+'","libraryTitle":"'+this.state.library_title+'","examId":"'+subvalId+'","examTitle":"'+this.state.library_exam.examTitle+'","questionId":"'+this.state.library_question_list.examDesc+'","examScore":"'+this.state.library_exam.passingScore+'","examQty":"'+this.state.library_exam.numberQuestion+'"}'
      
      }
    })
  }
  urlnewQuestion(valId,subvalId,subsubvalId,action) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false,
          ...prevState.isOpen3, isOpen3: false,
          ...prevState.isOpen4, isOpen4: false,
          ...prevState.isOpen5, isOpen5: true,
          ...prevState.isOpen6, isOpen6: false,
          ...prevState.stPopTitle5, stPopTitle5: action+" Question",
          ...prevState.otherContent5, otherContent5: '{"libraryId":"'+valId+'","libraryTitle":"'+this.state.library_title+'","examId":"'+subvalId+'","examTitle":"'+this.state.library_exam.examTitle+'","questionId":"'+this.state.library_question_list.examDesc+'","examScore":"'+this.state.library_exam.passingScore+'","examQty":"'+this.state.library_exam.numberQuestion+'"}'
      
      }
    })
  }

  urleditQuestion(valId,subvalId,subsubval,action) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
          ...prevState.isOpen2, isOpen2: false,
          ...prevState.isOpen3, isOpen3: false,
          ...prevState.isOpen4, isOpen4: false,
          ...prevState.isOpen5, isOpen5: true,
          ...prevState.isOpen6, isOpen6: false,
          ...prevState.stPopTitle5, stPopTitle5: action+" Question",
          ...prevState.otherContent5, otherContent5: '{"libraryId":"'+valId+'","libraryTitle":"'+this.state.library_title+'","examId":"'+subvalId+'","examTitle":"'+this.state.library_exam.examTitle+'","questionId":"'+subsubval.subsubdocid+'","questionText":"'+subsubval.subsubcontent.questionText+'","questionNumber":"'+subsubval.subsubcontent.questionNumber+'","questionType":"'+subsubval.subsubcontent.questionType+'","questionAnswer":"'+subsubval.subsubcontent.questionAnswer+'","questionOption1":"'+subsubval.subsubcontent.option1+'","questionOption2":"'+subsubval.subsubcontent.option2+'","questionOption3":"'+subsubval.subsubcontent.option3+'","questionOption4":"'+subsubval.subsubcontent.option4+'","questionOption5":"'+subsubval.subsubcontent.option5+'"}'
      
      }
    })
  }
  loadData(id) {
    
      document.getElementById('loading-area').style.display = 'block'; 
      fetch(global.config.urlLink+"/libraryview/"+id, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') {
              var lTitle="",lShortDesc="",lLongDesc="",lLanguage="",lCategory="",lSubCategory="",lPicture="",lVideo="";
              var lExam ="",lQuestion ="";
              if(resJson.values[0].content[0].maincontent.libraryTitle !== undefined) { lTitle = resJson.values[0].content[0].maincontent.libraryTitle; }
              if(resJson.values[0].content[0].maincontent.libraryShortDesc !== undefined) {
                  lShortDesc = ReplaceAllContent('[;]',',',resJson.values[0].content[0].maincontent.libraryShortDesc);
                  lShortDesc = ReplaceAllContent('<br/>','\n',lShortDesc);
              }
              if(resJson.values[0].content[0].maincontent.libraryLongDesc !== undefined) { 
                  lLongDesc = ReplaceAllContent('[;]',',',resJson.values[0].content[0].maincontent.libraryLongDesc);
                  lLongDesc = ReplaceAllContent('<br/>','\n',lLongDesc);
              }
              if(resJson.values[0].content[0].maincontent.libraryLanguage !== undefined) { 
                lLanguage = resJson.values[0].content[0].maincontent.libraryLanguage;
              }
              if(resJson.values[0].content[0].maincontent.categoryId !== undefined) { 
                  lCategory = resJson.values[0].content[0].maincontent.categoryName;
              }
              if(resJson.values[0].content[0].maincontent.subcategoryId !== undefined) { 
                  lSubCategory = resJson.values[0].content[0].maincontent.subcategoryName;
              }
              if(resJson.values[0].content[0].maincontent.libraryPicture !== undefined && resJson.values[0].content[0].maincontent.libraryPicture !== "") { 
                lPicture = resJson.values[0].content[0].maincontent.libraryPicture;
              } else { lPicture = NAimage; }
              if(resJson.values[0].content[0].maincontent.libraryVideo !== undefined && resJson.values[0].content[0].maincontent.libraryVideo !== "") { 
                lVideo = resJson.values[0].content[0].maincontent.libraryVideo;
              }
              if(resJson.values[0].scontent2.length > 0) {
                lExam = resJson.values[0].scontent2[0].subcontent;
                lExam.docid = resJson.values[0].scontent2[0].subdocid;
                if(resJson.values[0].scontent2[0].sub2content.length > 0) {
                  lQuestion = resJson.values[0].scontent2[0].sub2content
                }
              }
              this.setState({
                  stTitle: 'Edit Library',
                  library_docid: resJson.values[0].docid,
                  library_title: lTitle,
                  library_short_desc: lShortDesc,
                  library_long_desc: lLongDesc,
                  library_language: lLanguage,
                  library_picture: lPicture,
                  library_video: lVideo,
                  library_category: lCategory,
                  library_subcategory: lSubCategory,
                  library_lesson_list: resJson.values[0].scontent,
                  library_exam: lExam,
                  library_question_list: lQuestion,
                  isOpen: false,
                  isOpen2: false,
                  isOpen3: false,
                  isOpen4: false,
                  isOpen5: false,
                  isOpen6: false,
                  stPopTitle: '',
                  stPopTitle2: '',
                  stPopTitle3: '',
                  stPopTitle4: '',
                  stPopTitle5: '',
                  stPopTitle6: '',
                  otherContent: '',
                  otherContent2: '',
                  otherContent3: '',
                  otherContent4: '',
                  otherContent5: '',
                  otherContent6: ''
              }) 
          } else {
              console.log('Something happened wrong');
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
        }); 
    
  }

  componentDidMount() {
    var geturl = this.props.location; 
    var arrUrl = [];
    arrUrl = geturl.pathname.split('/');
    this.loadData(arrUrl[2]);
  }

  render() {
   var lessonsList = [];
   if(this.state.library_lesson_list.length > 0) {
    this.state.library_lesson_list.sort((a, b) => a.subcontent.lessonNumber - b.subcontent.lessonNumber);
    for(var ls=0; ls < this.state.library_lesson_list.length; ls++) {
        var retResult3 = this.state.library_lesson_list[ls];
        var attachmentList = [];
        var fileList = [];
        if(retResult3.sub2content.length > 0) {
          for(var ls2=0; ls2 < retResult3.sub2content.length; ls2++) {
            fileList.push(retResult3.sub2content[ls2].subsubcontent.fileId);
            var thumbfile = "";
            if(retResult3.sub2content[ls2].subsubcontent.fileThumbnail !== undefined) { thumbfile = retResult3.sub2content[ls2].subsubcontent.fileThumbnail; }
            attachmentList.push(<li key={ls2}>
              <div className="flex-container">
                <div className="w90p"><a href={retResult3.sub2content[ls2].subsubcontent.fileUrl} target="_blank" rel="noreferrer"><AttachFileIcon className="iconGrey mgR10"></AttachFileIcon> {retResult3.sub2content[ls2].subsubcontent.fileName}</a></div>
                {(retResult3.sub2content[ls2].subsubcontent.fileType === "video/mp4")? 
                <div className="w10p divRight"><EditIcon className="iconGrey cursor-on" onClick={this.urleditAttachment.bind(this, this.state.library_docid,retResult3.subdocid, retResult3.sub2content[ls2].subsubdocid, 'Edit', thumbfile)}/></div>
                : <div className="w10p divRight"></div>}
                <div className="w10p divRight"><DeleteIcon className="iconGrey cursor-on" onClick={this.urldeleteAttachment.bind(this, this.state.library_docid, retResult3.subdocid, retResult3.sub2content[ls2].subsubdocid, retResult3.sub2content[ls2].subsubcontent.fileId)}/></div>
              </div>
            </li>
            )
          }
        }

        var trcls = '';
        var lessonDesc = retResult3.subcontent.lessonDesc !== undefined ?  ReplaceAllContent('[;]',',',ReplaceAllContent('<br/>','\n',retResult3.subcontent.lessonDesc)) : '';
        lessonsList.push(<tr key={ls+1} className={trcls}>
            <th scope="row">{retResult3.subcontent.lessonNumber}</th>
            <td>{retResult3.subcontent.lessonTitle}</td>
            <td>
              <div>{lessonDesc}</div>
              <div className="mgT10 flex-container w100p">
                <div className="textBold w70p">Attachment</div>
                <div className="divRight w30p  cursor-on"><button className="noneButton linkText" onClick={this.urlnewAttachment.bind(this, this.state.library_docid,retResult3.subdocid,'','Add New')}><AddIcon style={{fontSize:"14pt"}}></AddIcon>Add &raquo;</button></div>
              </div>
              <div><ul>{attachmentList}</ul></div>
            </td>
            <td>
                <EditIcon className="iconGrey cursor-on mgR20" title="edit" onClick={this.urlnewLesson.bind(this, this.state.library_docid,retResult3.subdocid,'Edit')}/>
                <DeleteIcon className="iconGrey cursor-on" onClick={this.urldeleteLesson.bind(this, this.state.library_docid, retResult3.subdocid, fileList)}/> 
            </td>
          </tr>)
    }
   } else {
      lessonsList.push(<tr key="0">
          <td colSpan="5" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
        </tr>)
   }
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Library</div>
                {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[2] === "ON") || (JSON.parse(localStorage.getItem('urole'))[3] === "ON") || (JSON.parse(localStorage.getItem('urole'))[4] === "ON"))? 
                <div>
                <div className="stIndiTitle">Library { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="flex-container mgB20">
                            <div className="st2Title w70p"></div>
                            <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        {/* --- Start - DETAIL INFORMATION ---------- */}
                        <div id="detail-info-area">
                          <div className="flex-container">
                            <div className="w70p st4Title divMiddle">Detail Information</div>
                            <div className="w30p divRight">
                                <div className="linkText cursor-on"><a href={"/IEditLibrary/"+this.state.library_docid}><EditIcon className="mgR05" style={{fontSize:"14pt"}} ></EditIcon><span style={{marginTop:"3px"}}>Edit Info &raquo;</span></a></div>
                            </div>
                          </div>
                          <div className="mgT30">
                            <div className="mgT20 flex-container">
                                <div className="w20p"><h6>Title</h6></div>
                                <div className="w80p"><span className="mgR10">:</span>{this.state.library_title}</div>
                            </div>
                            <div className="mgT05 flex-container">
                                <div className="w20p"><h6>Category</h6></div>
                                <div className="w80p"><span className="mgR10">:</span>{this.state.library_category} <KeyboardArrowRightIcon className="iconLightGrey"></KeyboardArrowRightIcon> {this.state.library_subcategory}</div>
                            </div>
                            <div className="mgT05 flex-container">
                                <div className="w20p"><h6>Language</h6></div>
                                <div className="w80p"><span className="mgR10">:</span>{this.state.library_language} </div>
                            </div>
                            <div className="mgT20">
                                <div><h6>Short Description :</h6></div>
                                <div>{parse(this.state.library_short_desc)}</div>
                            </div>
                            <div className="mgT30">
                                <div><h6>Introduction :</h6></div>
                                <div>{parse(this.state.library_long_desc)}</div>
                            </div>
                            {(this.state.library_picture !== "" && this.state.library_picture !== undefined)?
                            <div className="mgT30">
                                <div><h6>Picture :</h6></div>
                                <div><img width="150" src={this.state.library_picture} alt=""/></div>
                            </div>
                            : '' }
                            {(this.state.library_video !== "" && this.state.library_video !== undefined)?
                            <div className="mgT30">
                              <div>
                                <h6>Video Intro :</h6> 
                                <iframe src={this.state.library_video} title={this.state.library_title}></iframe><br/>
                                <a href={this.state.library_video} target="_blank" rel="noreferrer">{this.state.library_video}</a>
                              </div>
                            </div>
                            : '' }
                          </div>
                        </div>
                        {/* --- End - DETAIL INFORMATION ---------- */}

                        {/* --- Start - LESSON ---------- */}
                        <div className="mgT50">
                          <Accordion className="accord" flush>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>
                                Modules
                                <KeyboardArrowRightIcon className="toggle-open"/>
                                <KeyboardArrowDownIcon className="toggle-close"/>
                              </Accordion.Header>
                              <Accordion.Body>
                                {/* --- Start - LESSON ---------- */}
                                <div id="lessons-area">
                                  <div className="flex-container">
                                    <div className="w70p st4Title divMiddle">&nbsp;</div>
                                    <div className="w30p divRight">
                                      <button className="subgenButton mgL20" onClick={this.urlnewLesson.bind(this, this.state.library_docid,'','Add New')}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>add new module</button>
                                    </div>
                                  </div>
                                  <div className="mgT20">
                                    <table className="table table-hover">
                                      <thead className="thead-light">
                                          <tr key="0">
                                          <th scope="col textWrap" style={{width:"50px"}}>#</th>
                                          <th scope="col textWrap">Subject</th>
                                          <th scope="col textWrap">Description</th>
                                          <th scope="col textWrap" style={{width:"100px"}}>Action</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {lessonsList}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                {/* --- End - LESSON ---------- */}
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>
                                Exam  
                                <KeyboardArrowRightIcon className="toggle-open"/>
                                <KeyboardArrowDownIcon className="toggle-close"/>
                              </Accordion.Header>
                              <Accordion.Body>
                                {/* --- Start - EXAM ---------- */}
                                <div id="lessons-area">
                                  <div className="flex-container">
                                    <div className="w70p st4Title divMiddle">Exam Information</div>
                                    <div className="w30p divRight">
                                        {
                                          this.state.library_exam.docid ? (
                                            this.state.library_exam.examStatus === 1 ? (
                                              <div className="flex-container" style={{marginTop: "-3px"}}><a href="#!" onClick={this.urlpublishExam.bind(this, this.state.library_exam.docid, 0)}><ToggleOnIcon className="iconGrey cursor-on mgR20" /> {/*<span className="linkText" style={{marginLeft:"-20px", marginRight:"10px"}}>ON </span>*/}</a></div>
                                            ) : (
                                              <div className="flex-container" style={{marginTop: "-3px"}}><a href="#!" onClick={this.urlpublishExam.bind(this, this.state.library_exam.docid, 1)}><ToggleOffIcon className="iconGreyOff cursor-on mgR20" />{/*<span className="linkText" style={{marginLeft:"-20px", marginRight:"10px"}}> OFF</span>*/}</a></div>
                                            )
                                          ) : ''
                                        }
                                        <div className="linkText cursor-on"><a href="#!" onClick={this.urleditExam.bind(this, this.state.library_docid,this.state.library_exam.docid,'Edit')}><EditIcon className="mgR05" style={{fontSize:"14pt"}} ></EditIcon><span style={{marginTop:"3px"}}>Edit Info &raquo;</span></a></div>
                                    </div>
                                  </div>
                                  <div className="mgT20 flex-container">
                                      <div className="w20p"><h6>Description</h6></div>
                                      <div className="w80p"><span className="mgR10">:</span>{this.state.library_exam.examDesc}</div>
                                  </div>
                                  <div className="mgT05 flex-container">
                                      <div className="w20p"><h6>Passing score</h6></div>
                                      <div className="w80p"><span className="mgR10">:</span>{this.state.library_exam.passingScore}</div>
                                  </div>
                                  <div className="mgT05 flex-container">
                                      <div className="w20p"><h6>Num. of questions</h6></div>
                                      <div className="w80p"><span className="mgR10">:</span>{this.state.library_exam.numberQuestion}</div>
                                  </div>
                                  {
                                    this.state.library_exam.docid ? (
                                      <div>
                                        <div className="flex-container mgT30">
                                          <div className="w50p st4Title divMiddle">Questions List</div>
                                          <div className="w50p divRight">
                                            <div className="flex-container">
                                              <button className="subgenButton mgL20" onClick={this.urlimportExam.bind(this, this.state.library_docid,this.state.library_exam.docid,'','Import ')}>import question</button>
                                              <button className="subgenButton mgL20" onClick={this.urlnewQuestion.bind(this, this.state.library_docid,this.state.library_exam.docid,'','Add New')}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>add new question</button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="mgT20">
                                          <table className="table table-hover">
                                            <thead className="thead-light">
                                                <tr key="0">
                                                  <th scope="col textWrap" style={{width:"50px"}}>#</th>
                                                  <th scope="col textWrap">Question</th>
                                                  <th scope="col textWrap">Type</th>
                                                  <th scope="col textWrap">Answer</th>
                                                  <th scope="col textWrap" style={{width:"100px"}}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                              {
                                                this.state.library_question_list.length > 0 ? (    
                                                  this.state.library_question_list
                                                  .sort((a,b) => a.subsubcontent.questionNumber - b.subsubcontent.questionNumber)
                                                  .map((question, i) => {
                                                    return (
                                                      <tr>
                                                        <td>{question.subsubcontent.questionNumber}</td>
                                                        <td>
                                                          {question.subsubcontent.questionText}
                                                          <ol className="list-alpha">
                                                            {question.subsubcontent.option1 ? <li>{question.subsubcontent.option1}</li> : ''}
                                                            {question.subsubcontent.option2 ? <li>{question.subsubcontent.option2}</li> : ''}
                                                            {question.subsubcontent.option3 ? <li>{question.subsubcontent.option3}</li> : ''}
                                                            {question.subsubcontent.option4 ? <li>{question.subsubcontent.option4}</li> : ''}
                                                            {question.subsubcontent.option5 ? <li>{question.subsubcontent.option5}</li> : ''}
                                                          </ol>
                                                        </td>
                                                        <td>{question.subsubcontent.questionType}</td>
                                                        <td>{question.subsubcontent.questionAnswer}</td>
                                                        <td>            
                                                          <a href="#!" onClick={this.urleditQuestion.bind(this, this.state.library_docid,this.state.library_exam.docid,question,'Edit')}><EditIcon className="iconGrey cursor-on mgR20" title="edit"/></a>
                                                          {/*<DeleteIcon className="iconGrey cursor-on"/> */}
                                                        </td>
                                                      </tr>
                                                    )
                                                  })
                                                ) : <tr key="0"><td colSpan="5" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td></tr>
                                              }
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    ) : ''
                                  }
                                </div>
                                {/* --- End - EXAM ---------- */}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                        {/* --- End - LESSON ---------- */}
                    </div>
                    {/* ====== */}
                  </div>  
              </div>
                :   
                <div><INA/></div>
                }
              </div>
          </div>
          <div>
                  {this.state.isOpen && <Popup
                  content={<><ILibraryLessonForm/></>}
                  id={this.state.library_id} 
                  title={this.state.stPopTitle}
                  others={this.state.otherContent}
                  styles={"70%"}
                  handleClose={this.togglePopClose.bind(this)}
                  />}
                  {this.state.isOpen2 && <Popup
                  content={<><ILibraryAttachForm/></>}
                  id={this.state.library_id} 
                  title={this.state.stPopTitle2}
                  others={this.state.otherContent2}
                  styles={"60%"}
                  handleClose={this.togglePopClose.bind(this)}
                  />}
                  {this.state.isOpen3 && <Popup
                  content={<><ILibraryAttachEdit/></>}
                  id={this.state.library_id} 
                  title={this.state.stPopTitle3}
                  others={this.state.otherContent3}
                  styles={"60%"}
                  handleClose={this.togglePopClose.bind(this)}
                  />}
                  {this.state.isOpen4 && <Popup
                  content={<><ILibraryExamEdit/></>}
                  id={this.state.library_id} 
                  title={this.state.stPopTitle4}
                  others={this.state.otherContent4}
                  styles={"60%"}
                  handleClose={this.togglePopClose.bind(this)}
                  />}
                  {this.state.isOpen5 && <Popup
                  content={<><ILibraryQuestionForm/></>}
                  id={this.state.library_id} 
                  title={this.state.stPopTitle5}
                  others={this.state.otherContent5}
                  styles={"60%"}
                  handleClose={this.togglePopClose.bind(this)}
                  />}  
                  {this.state.isOpen6 && <Popup
                  content={<><ILibraryQuestionImport/></>}
                  id={this.state.library_id} 
                  title={this.state.stPopTitle6}
                  others={this.state.otherContent6}
                  styles={"60%"}
                  handleClose={this.togglePopClose.bind(this)}
                  />} 
          </div>
          <div id="loading-area" className="divHide loading"></div>
    </div>
   );
  }
}
 
export default ILibraryDetailEdit;