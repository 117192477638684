const CheckLogin = () => {
    return new Promise((resolve ,reject)=>{
        var result = false;
        if(localStorage.getItem('cuid') !== '' && localStorage.getItem('cuid') !== null) {
            result = true; 
            resolve(result);
        } else {
            resolve(result);
        }
    })
}
export default CheckLogin;