import React, { Component } from 'react';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';
import DatePicker from 'react-date-picker';
import ReplaceAllContent from '../../inc/replace-all';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[2];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[4];

class AdditionalForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            clientdoc_id: '',
            licencedoc_id: '',
            licence_type: '',
            licence_amount_seat: '',
            licence_price: '',
            licence_price_currency: 'USD',
            licence_start_date: new Date(),
            licence_remarks: '',
            formErrors: { licence_start_date: '', licence_amount_seat: '', licence_price: ''},
            licencestartdateValid: false,
            licenceamountseatValid: false,
            licencepriceValid: false,
            formValid: false,
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleStartDate = date => {
        this.setState( prevState => {
            return {  
                ...prevState.licence_start_date, licence_start_date: date
            }
            }, () => { this.validateField('licence_start_date', date)
        }) 
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    handleUserSelect = (e) => {
        const value = e.target.value;
        const inputname = e.target.name;
        if(inputname === "licence_price_currency") {
            this.setState( prevState => {
                return {  
                    ...prevState.licence_price_currency, licence_price_currency: value
                }
              }) 
        }
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let licenceamountseatValid = this.state.licenceamountseatValid;
        let licencepriceValid = this.state.licencepriceValid;
        let licencestartdateValid = this.state.licencestartdateValid;
        
        switch(fieldName) {
            case 'licence_amount_seat': 
                licenceamountseatValid = value.trim().length > 0;
                fieldValidationErrors.licence_amount_seat = licenceamountseatValid ? '' : 'number of licence need to be filled';
                break;
            case 'licence_price': 
                licencepriceValid = value.trim().length > 0;
                fieldValidationErrors.licence_price = licencepriceValid ? '' : 'price of licence need to be filled';
                break;
            case 'licence_start_date':
                licencestartdateValid = value !== null; 
                fieldValidationErrors.licence_start_date = licencestartdateValid ? '' : 'start activated date need to be filled';
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        licenceamountseatValid: licenceamountseatValid,
                        licencepriceValid: licencepriceValid,
                        licencestartdateValid: licencestartdateValid,
                    }, this.validateForm);
      }
      validateForm() {
        this.setState({formValid: this.state.licenceamountseatValid && this.state.licencepriceValid && this.state.licencestartdateValid});
      }
    
      errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
      }
      handleSubmit(e) {
        e.preventDefault();
        if(this.state.licence_amount_seat !== '' && this.state.licence_price !== '' && this.state.licence_start_date !== null) {
            document.getElementById('loading-area').style.display = 'block'; 
            fetch(global.config.urlLink+"/licenceview/"+this.state.clientdoc_id+"/"+this.state.licencedoc_id, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                    var licenceAmountSeat,licencePrice,licencePriceCurrency,licenceRemarks;
                    
                    var licenceCurrAmountSeat = resJson.values[0].scontent[0].subcontent.licenceTotalSeat;
                    var licenceEndDate = new Date(resJson.values[0].scontent[0].subcontent.endPeriode._seconds*1000)
                    
                    if(this.textInputLicenceAmountSeat.value === '') { licenceAmountSeat = this.state.licence_amount_seat; } else { licenceAmountSeat = this.textInputLicenceAmountSeat.value; }
                    if(this.textInputLicencePrice.value === '') { licencePrice = this.state.licence_price; } else { licencePrice = this.textInputLicencePrice.value; }
                    if(this.textInputLicencePriceCurrency.value === '') { licencePriceCurrency = this.state.licence_price_currency; } else { licencePriceCurrency = this.textInputLicencePriceCurrency.value; }
                    if(this.textInputLicenceRemarks.value === '') { licenceRemarks = this.state.licence_remarks; } else { licenceRemarks = this.textInputLicenceRemarks.value; }
                    if(licenceRemarks !== "") { 
                        licenceRemarks = ReplaceAllContent('\n','<br/>',licenceRemarks);
                    }
                    var urlFetch = global.config.urlLink+"/additionallicenceadd";
                    var datatext ='{"licence_amount_seat":"'+licenceAmountSeat+'","licence_curr_amount_seat":"'+licenceCurrAmountSeat+'","licence_price":"'+licencePrice+'","licence_price_currency":"'+licencePriceCurrency+'","licence_start_date":"'+this.state.licence_start_date+'","licence_end_date":"'+licenceEndDate+'","licence_remarks":"'+licenceRemarks+'","licencedoc_id":"'+this.state.licencedoc_id+'","clientdoc_id":"'+this.state.clientdoc_id+'","form_id":"form-additional-licence-add"}';
                    var data = JSON.parse(datatext); 
                    
                    fetch(urlFetch, {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Authorization': authKey2,
                        }, body : JSON.stringify(data)
                    }).then(res2 => {
                        if(res2.status === 200) 
                            return res2.json() 
                    }).then( resJson2 => {
                        document.getElementById('loading-area').style.display = 'none'; 
                        if(resJson2.status === '200') {
                            window.location.href='/IViewLicence/'+this.state.clientdoc_id+"/"+this.state.licencedoc_id;
                        } else {
                            console.log('Something happened wrong');
                        }
                    }).catch(err => { 
                        document.getElementById('loading-area').style.display = 'none'; 
                    });
                    
              }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
              }); 
        } else {
            var arrfieldName = ['licence_amount_seat','licence_price','licence_start_date'];
            var arrvalue = [this.textInputLicenceAmountSeat.value,this.textInputLicencePrice.value,this.state.licence_start_date];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        } 
      }
    componentDidMount() {
        var licenceId = document.getElementById("contentid").innerText;
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        this.setState({
            stPopTitle: '',
            clientdoc_id: othersContent.client_id,
            licencedoc_id: licenceId,
            licence_type: othersContent.licence_type,
            licence_amount_seat: '',
            licence_price: '',
            licence_price_currency: 'USD',
            licence_start_date: new Date(),
            licence_remarks: '',
            formErrors: { licence_start_date: '', licence_amount_seat: '', licence_price: ''},
            licencestartdateValid: false,
            licenceamountseatValid: false,
            licencepriceValid: false,
            formValid: false
        }); 
    }
    render() {
      return (
        <div className="main-container">
            <div className="mgT30">
            <form onSubmit={this.handleSubmit}>
              <div className="flex-container">
                <div className="col-6">
                    <label className="textBold" htmlFor="licence_amount_seat">Number of Licence </label>
                    <div>
                        <div className="flex-container">
                            <div>
                                <input type="number" className="form-control" name="licence_amount_seat"
                                    placeholder={this.state.licence_amount_seat}
                                    value={this.state.licence_amount_seat}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputLicenceAmountSeat = input}
                                    min="1"/> 
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_amount_seat}/>  }</div>
                            </div>
                            <div className="mgL20 mgT10"> seat(s) </div>
                        </div>
                    </div>
                    <label className="mgT30 textBold" htmlFor="licence_price">Total Price</label>
                    <div>
                        <div className="flex-container">
                            <div className="mgR20">
                                <select name="licence_price_currency" className="form-control" 
                                    value={this.state.licence_price_currency} 
                                    onChange={this.handleUserSelect}
                                    ref={(input) => this.textInputLicencePriceCurrency = input}
                                    disabled={this.state.input_disabled}
                                >
                                    <option value="USD">USD</option>
                                    <option value="SGD">SGD</option>
                                    <option value="IDR">IDR</option>
                                    <option value="RM">RM</option>
                                </select>
                            </div>
                            <div>
                                <input type="number" className="form-control" name="licence_price"
                                    placeholder={this.state.licence_price}
                                    value={this.state.licence_price}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputLicencePrice = input}/> 
                            </div>
                        </div>
                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_price}/>  }</div>
                        
                    </div>
                </div>

                <div className="col-6">
                    <label htmlFor="licence_start_date" className="textBold">Date of Activation </label>
                    <div>
                        <div className="flex-container">
                            <div>
                            <DatePicker
                                onChange={this.handleStartDate}
                                value={this.state.licence_start_date}
                                customStyles={{dateInput:{borderWidth: 0}}}
                            />
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_start_date}/>  }</div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div className="mgL20 mgR20 mgB20">
                <label className="mgT30 textBold" htmlFor="licence_remarks">Remarks</label>
                <div>
                    <div className="flex-container">
                    <textarea name="licence_remarks" className="form-control" 
                        rows={5} cols={30} 
                        value={this.state.licence_remarks} 
                        onChange={this.handleUserInput} 
                        ref={(input) => this.textInputLicenceRemarks = input}/> 
                    </div>
                </div>
              </div>
              <div className="row-field mgT30 mgB30">
                    <input type="hidden" required name="clientdoc_id" value={this.state.clientdoc_id} />
                    <button type="submit" className="w100p submitButton">Submit Licence</button>
              </div>
            </form>
            </div>
            <div id="loading-area" className="divHide loading"></div> 
        </div>
      );
    }
 }
  
export default AdditionalForm;