let Credentials = require('./cOACredentials.json');
let sessionUToken = "";
let vers = "lccs";
if(localStorage.getItem('cutk') !== null) { sessionUToken = localStorage.getItem('cutk'); }
if(window.location.hostname === "customers.point-star.com") { vers = "lvcs"; } 
module.exports = global.cconfig = {
    urlLink: 'https://us-central1-ps-product-internalportal-api.cloudfunctions.net/api',
    timezone: 'en-GB',
    datetimeoption: '{"year":"numeric", "month": "long", "day": "2-digit", "hour": "numeric", "minute": "numeric", "second": "numeric", "hour12": false, "timeZone": "Asia/Jakarta"}',
    authBearerKey: sessionUToken+"##"+Credentials.web.client_secret+"##"+vers,
    kindcode: [
        "31G785","98U109","56T428","78Y851","28R393","93I200","52T892","62F210","81J037","73P159",
        "18J332","74G679","52K024","93Y210","28H193","53J268","45K027","82Y167","14K330","73M865",
        "26K321","94H190","85E180","06Y478","48B217","27S509","36V185","25M411","50P271","10K732",
        "75W369","88L352","17U196","44P737","61L385","38S299","66F165","34Q284","77W190","2Y8KH0",
        "90K114","98E213","","","","52I768","J3R201","02U134"
    ],
    webtitle: "POINTSTAR CUSTOMER PORTAL",
    userlevel: ["Admin","User"],
    requestStatus: ["New Request", "On Progress", "Completed", "Cancel", "New Order","Verifying","Verified","Failed"],
    contentStatus: ["Draft","Publish","Unpublish"],
    contentType: [
        {id:1, type:"news-info",name:"News, Info & Updates"},
        {id:2, type:"promo",name:"Promo"},
        {id:3, type:"announcement",name:"Events & Announcement"},
    ],
    typeLicenceAddon: "Google Workspace Business Starter",
    addonLicence: ["Google Drive - 20GB","Google Drive - 50GB","Google Drive - 200GB","Google Drive - 400GB","Google Drive - 1TB","Google Drive - 2TB","Google Drive - 4TB","Google Drive - 8TB","Google Drive - 16TB"],
    methodPayment: ["paypal","cash","paynow","banktransfer","creditcard-stripe"],
    taxRate: [
        {country: "singapore", value: 0.09, label: "9%"},
        {country: "malaysia", value: 0.06, label: "6%"},
        {country: "indonesia", value: 0.11, label: "11%"},
    ],
    licenceStatus: ["New","Active","Deactive","Expired","Upgrade","Downgrade"],
}
