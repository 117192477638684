import React, { Component } from 'react';
import axios from 'axios';
import '../../inc/genstyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import ReplaceAllContent from '../../inc/replace-all';
import MyCustomUploadAdapterPlugin from '../iinc/ckImageUploader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DatePicker from 'react-date-picker';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[28];

class IAddContentsForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        content_docid: '',
        content_date: '',
        content_title: '',
        content_short_desc: '',
        content_long_desc: '',
        content_picture: '',
        content_video: '',
        content_types: '',
        source: 'content',
        selectedFileDocument: null,
        selectedFileDocument2: null,
        formErrors: {content_date: '', content_title: '', content_short_desc: '', content_long_desc: '', content_types: '', selectedFileDocument:'', selectedFileDocument2:''},
        contentdateValid: false,
        contenttitleValid: false,
        contentshortdescValid: false,
        contentlongdescValid: false,
        contenttypeValid: false,
        selectedFileDocumentValid: false,
        selectedFileDocument2Valid: false,
        formValid: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitData = this.submitData.bind(this);
  }
  handleBack = () => {
    this.props.history.goBack();
  }
  handleUserCKEditorInput = (e) => {
    const name = "content_long_desc";
    const value = e.getData();
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }
  handleDate = date => {
    this.setState( prevState => {
        return {  
            ...prevState.content_date, content_date: date
        }
      }, () => { this.validateField('content_date', date)
    }) 
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    const inputname = e.target.name; 
    this.setState( prevState => {
        return {  
            ...prevState.content_types, content_types: value
        }
      }, () => { this.validateField(inputname, value)
    }) 
    
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let contentdateValid = this.state.contentdateValid;
    let contenttitleValid = this.state.contenttitleValid;
    let contentshortdescValid = this.state.contentshortdescValid;
    let contentlongdescValid = this.state.contentlongdescValid;
    let contenttypesValid = this.state.contenttypesValid;
    
    switch(fieldName) {
        case 'content_date':
            contentdateValid = value !== "";
            fieldValidationErrors.content_date = contentdateValid ? '' : 'date need to be filled';
            break;
        case 'content_title': 
            contenttitleValid = value.trim().length > 0;
            fieldValidationErrors.content_title = contenttitleValid ? '' : 'title need to be filled';
            break;
        case 'content_short_desc': 
            contentshortdescValid = value.trim().length > 0;
            fieldValidationErrors.content_short_desc = contentshortdescValid ? '' : 'short description need to be filled';
            break;
        case 'content_long_desc': 
            contentlongdescValid = value.trim().length > 0;
            fieldValidationErrors.content_long_desc = contentlongdescValid ? '' : 'full description need to be filled';
            break;
        case 'content_types': 
            contenttypesValid = value.trim().length > 0;
            fieldValidationErrors.content_types = contenttypesValid ? '' : 'type need to be filled';
            break;
        default:
            break;
    }
    
    this.setState({formErrors: fieldValidationErrors,
                    contentdateValid: contentdateValid,
                    contenttitleValid: contenttitleValid,
                    contentshortdescValid: contentshortdescValid,
                    contentlongdescValid: contentlongdescValid,
                    contenttypesValid: contenttypesValid,
                }, this.validateForm);
  }
  validateForm() {
    this.setState({formValid: this.state.contentdateValid && this.state.contenttitleValid && this.state.contentshortdescValid && this.state.contentlongdescValid && this.state.contenttypesValid});
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  onChangeFileHandler = (e) => {
    if(e.target.files[0] !== undefined) {
        var inputType = e.target.files[0].type;
        var inputValue = e.target.files[0];
        var name = ''; 
        var validname = '';
        let fieldValidationErrors = this.state.formErrors;
        let selectedFileValid = false;
        if(inputType.indexOf('video') > -1 || inputType.indexOf('image') > -1) { //add image
            selectedFileValid = true;
        } else {
            selectedFileValid = false;
        }
        if(e.target.id === "fileDocument2") {
            name =  'selectedFileDocument2';
            validname = 'selectedFileDocument2Valid';
            fieldValidationErrors.selectedFileDocument2 = selectedFileValid ? '' : 'please upload video only';
        } else {
            name =  'selectedFileDocument';
            validname = 'selectedFileDocumentValid';
            fieldValidationErrors.selectedFileDocument = selectedFileValid ? '' : 'please upload image only';
        }
        
        this.setState({
            [name]: inputValue,
            formErrors: fieldValidationErrors,
            [validname] : selectedFileValid
        }, this.validateForm);
    }
  }

  submitData(datasend,imgurl,vidurl) {
    var urlFetch, datatext;
    var contentDate,contentTitle,contentShortDesc,contentLongDesc,contentPicture,contentVideo,contentTypes;
    if(imgurl !== '' && imgurl !== undefined) { contentPicture = imgurl; } else { contentPicture = this.state.content_picture; }
    if(vidurl !== '' && vidurl !== undefined) { contentVideo = vidurl; } else { contentVideo = this.state.content_video; }
    if(this.state.content_docid !== null && this.state.content_docid !== ''  && this.state.content_docid !== undefined) { 
        if(this.textInputContentTitle.value === '') { contentTitle = this.state.content_title; } else { contentTitle = this.textInputContentTitle.value; }
        if(this.textInputContentShortDesc.value === '') { contentShortDesc = this.state.content_short_desc; } else { contentShortDesc = this.textInputContentShortDesc.value; }
        if(this.textInputContentTypes.value === '') { contentTypes = this.state.content_types; } else { contentTypes = this.textInputContentTypes.value; }
        contentDate = this.state.content_date;
        contentLongDesc = this.state.content_long_desc;
        contentShortDesc = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',contentShortDesc));
        contentLongDesc = ReplaceAllContent('"',"'",this.state.content_long_desc);
        datatext ='{"content_date":"'+contentDate+'","content_title":"'+contentTitle+'","content_short_desc":"'+contentShortDesc+'","content_long_desc":"'+contentLongDesc+'","content_picture":"'+contentPicture+'","content_video":"'+contentVideo+'","content_types":"'+contentTypes+'","content_docid":"'+this.state.content_docid+'","form_id":"form-edit"}';
        urlFetch = global.config.urlLink+"/addcontentedit";
    } else {
        contentShortDesc = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',this.state.content_short_desc));
        contentLongDesc = ReplaceAllContent('"',"'",this.state.content_long_desc);
        datatext ='{"content_date":"'+this.state.content_date+'","content_title":"'+this.state.content_title+'","content_short_desc":"'+contentShortDesc+'","content_long_desc":"'+contentLongDesc+'","content_picture":"'+contentPicture+'","content_video":"'+contentVideo+'","content_types":"'+this.state.content_types+'","form_id":"form-add"}';
        urlFetch = global.config.urlLink+"/addcontentadd";
    }
    var data = JSON.parse(datatext); 
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') {
            var docId = resJson.values;
            window.location.href='/IEditAddContent/'+docId;
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    if(this.state.content_title !== '' && this.state.content_short_desc !== '' && this.state.content_long_desc !== '' && this.state.content_types !== '') {
        document.getElementById('loading-area').style.display = 'block'; 
        var selectedFileValue = this.state.selectedFileDocument;
        var selectedFileValue2 = this.state.selectedFileDocument2;
        if((selectedFileValue !== null && ((selectedFileValue.type.indexOf('pdf') > -1 || selectedFileValue.type.indexOf('video') > -1|| selectedFileValue.type.indexOf('image') > -1))) 
        || (selectedFileValue2 !== null && ((selectedFileValue2.type.indexOf('pdf') > -1 || selectedFileValue2.type.indexOf('video') > -1|| selectedFileValue2.type.indexOf('image') > -1))) 
        ) { 
            const data = new FormData() 
            data.append('file', selectedFileValue);
            data.append('file2', selectedFileValue2);
            data.append('docid', this.state.content_docid);
            data.append('source', 'content');
            axios.post(global.config.urlLink+"/uploadfile", 
            data, { 
                headers: { 
                    'content-type': 'multipart/form-data',
                    'Authorization': authKey
                }
            }).then(res => { 
                document.getElementById('loading-area').style.display = 'none'; 
                if(res.data.status === "200") {
                    this.submitData(this,res.data.values.linkfile1,res.data.values.linkfile2);
                } else {
                    document.getElementById('loading-area').style.display = 'none'; 
                    console.log("Something happened wrong");
                }
            }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
            })
        } else {
            document.getElementById('loading-area').style.display = 'none'; 
            this.submitData(this,"","");
        }
    
    } else {
        var arrfieldName = ['content_date','content_title','content_short_desc','content_long_desc','content_types'];
        var arrvalue = [this.state.content_date,this.textInputContentTitle.value,this.textInputContentShortDesc.value,this.state.content_long_desc,this.textInputContentTypes.value];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
  }
  
  componentDidMount() {
    
    var geturl = this.props.location; 
    var arrString = geturl.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');
    
    if(arrUrl[1] === 'IEditAddContent') {
        if(arrUrl[2] !== '') { 
            document.getElementById('loading-area').style.display = 'block'; 
            fetch(global.config.urlLink+"/addcontentview/"+arrUrl[2], {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    var cDate="", cTitle="",cShortDesc="",cLongDesc="",cPicture="",cVideo="", cTypes="";
                    if(resJson.values[0].content[0].maincontent.contentDate !== undefined) { cDate = new Date(resJson.values[0].content[0].maincontent.contentDate._seconds*1000); }
                    if(resJson.values[0].content[0].maincontent.contentTitle !== undefined) { cTitle = resJson.values[0].content[0].maincontent.contentTitle; }
                    if(resJson.values[0].content[0].maincontent.contentShortDesc !== undefined) {
                      cShortDesc = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resJson.values[0].content[0].maincontent.contentShortDesc));
                    }
                    if(resJson.values[0].content[0].maincontent.contentLongDesc !== undefined) { 
                      cLongDesc = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resJson.values[0].content[0].maincontent.contentLongDesc));
                    }
                    if(resJson.values[0].content[0].maincontent.typeName !== undefined) { 
                      cTypes = resJson.values[0].content[0].maincontent.typeName;
                    }
                    if(resJson.values[0].content[0].maincontent.contentPicture !== undefined) { 
                      cPicture = resJson.values[0].content[0].maincontent.contentPicture;
                    }
                    if(resJson.values[0].content[0].maincontent.contentVideo !== undefined) { 
                      cVideo = resJson.values[0].content[0].maincontent.contentVideo;
                    }
                    
                    this.setState({
                        stTitle: 'Edit Content Insight',
                        content_docid: resJson.values[0].docid,
                        content_date: cDate,
                        content_title: cTitle,
                        content_short_desc: cShortDesc,
                        content_long_desc: cLongDesc,
                        content_picture: cPicture,
                        content_video: cVideo,
                        content_types: cTypes,
                        source: 'content',
                        selectedFileDocument: null,
                        selectedFileDocument2: null,
                        formErrors: {content_date: '', content_title: '', content_short_desc: '', content_long_desc: '', content_types: '', selectedFileDocument:'', selectedFileDocument2:''},
                        contentdateValid: false,
                        contenttitleValid: false,
                        contentshortdescValid: false,
                        contentlongdescValid: false,
                        contenttypeValid: false,
                        selectedFileDocumentValid: false,
                        selectedFileDocument2Valid: false,
                        formValid: false,
                    }) 
                } else {
                    console.log('Something wrong happened');
                }
              }).catch(err => err);
        }
    } else if(arrUrl[1] === 'IAddNewContent') {
        this.setState( prevState => {
            return {  
                ...prevState.stTitle, stTitle: 'New Add Content Insight'
            }
        })
    }  
  }

 render() {
     var optType = [];
     for(var c=0; c<global.config.contentType.length; c++) {
        optType.push(<option value={global.config.contentType[c].type} key={global.config.contentType[c].id}>{global.config.contentType[c].name}</option>)
     }
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Content Insight</div>
                {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[5] === "ON") || (JSON.parse(localStorage.getItem('urole'))[6] === "ON"))? 
              <div>
                <div className="stIndiTitle">Content Insight { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="flex-container">
                            <div className="st2Title w70p">{ this.state.stTitle.includes("New")? '' : '' } {this.state.stTitle}</div>
                            <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        <div id="FormCenter" className="mgT20">
                            <div id="FormAddContentInfo">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="flex-container">
                                        <input type="hidden" required name="content_docid" value={this.state.content_docid} ref={(input) => this.textInputContentId = input} />
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="content_types">Type</label>
                                        <div className="w70p">
                                            <select name="content_types" className="form-control" 
                                                value={this.state.content_types} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputContentTypes = input}
                                                disabled={this.state.input_disabled}
                                            >
                                            <option value="" key="">-- please select --</option>
                                            {optType}
                                            </select>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.content_types}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="content_date">Date</label>
                                        <div className="w70p">
                                            <DatePicker
                                                showTimeSelect 
                                                onChange={this.handleDate}
                                                value={this.state.content_date}
                                                customStyles={{dateInput:{borderWidth: 0}}}
                                                className="datepickers"
                                            />  
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.content_date}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="content_title">Title</label>
                                        <div className="w70p">
                                            <input type="text" className="form-control" name="content_title"
                                                placeholder={this.state.content_title}
                                                value={this.state.content_title}
                                                onChange={this.handleUserInput}  
                                                ref={(input) => this.textInputContentTitle = input}/>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.content_title}/>  }</div>
                                        </div>
                                    </div>
                                    
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="content_short_desc">Short Description</label>
                                        <div className="w70p">
                                            <textarea name="content_short_desc" className="form-control" 
                                                    rows={5} cols={30} 
                                                    /*placeholder={this.state.event_name}*/
                                                    value={this.state.content_short_desc} 
                                                    onChange={this.handleUserInput} 
                                                    ref={(input) => this.textInputContentShortDesc = input}/>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.content_short_desc}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="content_long_desc">Full Description</label>
                                        <div className="w70p">
                                            <CKEditor
                                                editor  = { ClassicEditor }
                                                name    = "content_long_desc"
                                                data    = {this.state.content_long_desc}
                                                config  = {{extraPlugins:[MyCustomUploadAdapterPlugin], state: this.state, key:authKey}}
                                                onReady = { editor => {
                                                    editor.ui.view.editable.element.style.minHeight = "500px";
                                                } }
                                                onChange={ ( event, editor ) => {
                                                    this.handleUserCKEditorInput(editor);
                                                    const data = editor.getData();
                                                    
                                                    editor.ui.view.editable.element.style.minHeight = "500px";
                                                } }
                                                onBlur={ ( event, editor ) => {
                                                    editor.ui.view.editable.element.style.minHeight = "500px";
                                                } }
                                                onFocus={ ( event, editor ) => {
                                                    editor.ui.view.editable.element.style.minHeight = "500px";
                                                } }
                                            />

                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.content_long_desc}/>  }</div>
                                        </div>
                                    </div>
                                
                                    
                                    <div>
                                        {(this.state.content_picture !== "")? 
                                        <div className="row-field mgT20">
                                            <div className="labelFormL"></div>
                                            <div className="w70p"><img width="150" src={this.state.content_picture} alt=""/></div>
                                        </div>
                                        : '' }
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="content_document">Upload Picture</label>
                                        <div className="w70p">
                                            <input id="fileDocument" type="file"
                                                onChange={this.onChangeFileHandler}>
                                            </input> 
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>  }</div>
                                            <div className="mgT10 warningText">
                                            Please upload file in jpg, jpeg, png format.<br/>
                                            Resolusi width picture min 1060px X 350px.
                                            </div>
                                        </div>
                                    </div>
                                    {(this.state.content_video !== "" && this.state.content_video !== undefined)?
                                    <div className="mgT30">
                                    <div><h6>Video :</h6> <a href={this.state.content_video} target="_blank" rel="noreferrer">{this.state.content_video}</a> </div>
                                    </div>
                                    : '' }
                                   
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="content_document2">Upload Video</label>
                                        <div className="w70p">
                                            <input id="fileDocument2" type="file"
                                                onChange={this.onChangeFileHandler}>
                                            </input> 
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument2}/>  }</div>
                                            <div className="mgT10 warningText">
                                            Please upload file in mp4 format.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT30 mgB30">
                                        <div className="labelFormL"></div>
                                        <div className="w70p"><button type="submit" className="w100p submitButton">Submit</button></div>
                                    </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>
                    {/* ====== */}
                  </div>  
              </div>
              :   
              <div><INA/></div>
              }
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div> 
    </div>
   );
 }
}
 
export default IAddContentsForm;