import React, { Component } from 'react';
import '../../inc/genstyle.css';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import DateTimeFormats from '../../inc/date-time-format';
import NumberFormats from '../../inc/number-format';
import ReplaceAllContent from '../../inc/replace-all';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import NAimage from '../../assets/na.png';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[10];

class ICourseDetail extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        course_docid: '',
        course_createby: '',
        course_createdate: '',
        course_title: '',
        course_meet_link: '',
        course_short_desc: '',
        course_long_desc: '',
        course_language: '',
        course_currency: '',
        course_price: '',
        course_date: '',
        course_start_time: '',
        course_end_time: '',
        course_picture: '',
        course_video: '',
        course_recording: '',
        course_category: '',
        course_subcategory: '',
        course_tutor: '',
        course_agenda_list: [],
    }
    this.loadData = this.loadData.bind(this);
  }

  handleBack = () => {
    this.props.history.goBack();
  }
  
  loadData(element,id) {
    if(element === "IViewCourse") {
      document.getElementById('loading-area').style.display = 'block'; 
      fetch(global.config.urlLink+"/courseview/"+id, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') {
              var lTitle="",lMeetLink="",lShortDesc="",lLongDesc="",lLanguage="",lCategory="",lSubCategory="",lTutor="",lPriceCurrency="",lPrice="",crsDate="",crsStartTime="",crsEndTime="",lPicture="",lVideo="",lRecording="",cDate="";
              var resultContent = resJson.values[0].content[0].maincontent;
              
              if(resultContent.courseTitle !== undefined) { 
                lTitle = resultContent.courseTitle; 
              }
              if(resultContent.courseShortDesc !== undefined) {
                  lShortDesc = ReplaceAllContent('[;]',',',resultContent.courseShortDesc);
                  lShortDesc = ReplaceAllContent('<br/>','\n',lShortDesc);
              }
              if(resultContent.courseLongDesc !== undefined) { 
                  lLongDesc = ReplaceAllContent('[;]',',',resultContent.courseLongDesc);
                  lLongDesc = ReplaceAllContent('<br/>','\n',lLongDesc);
              }
              if(resultContent.courseLanguage !== undefined) { 
                lLanguage = resultContent.courseLanguage;
              }
              if(resultContent.categoryId !== undefined) { 
                  lCategory = resultContent.categoryName;
              }
              if(resultContent.subcategoryId !== undefined) { 
                  lSubCategory = resultContent.subcategoryName;
              }
              if(resultContent.tutorId !== undefined) {
                var tutorNames = resultContent.tutorName.split(',');
                lTutor = tutorNames.join(', ');
              }
              if(resJson.values[0].content[0].createdate !== undefined) {
                cDate = DateTimeFormats(resJson.values[0].content[0].createdate._seconds,"datetime-short");
              }
              if(resultContent.courseMeetLink !== undefined) { 
                lMeetLink = resultContent.courseMeetLink; 
              }
              if(resultContent.coursePicture !== undefined && resultContent.coursePicture !== "") { 
                lPicture = resultContent.coursePicture;
              } else { lPicture = NAimage; }
              if(resultContent.courseVideo !== undefined && resultContent.courseVideo !== "") { 
                lVideo = resultContent.courseVideo;
              }
              if(resultContent.courseRecording !== undefined && resultContent.courseRecording !== "") { 
                lRecording = resultContent.courseRecording;
              }
              if(resultContent.courseCurrency !== undefined && resultContent.courseCurrency !== "") { 
                lPriceCurrency = resultContent.courseCurrency;
              }
              if(resultContent.coursePrice !== undefined && resultContent.coursePrice !== "") { 
                lPrice = NumberFormats(resultContent.coursePrice);
              }
              if(resultContent.courseDate !== undefined && resultContent.courseDate !== "") { 
                crsDate = DateTimeFormats(resultContent.courseDate._seconds,"date-long");
              }
              if(resultContent.courseStartTime !== undefined && resultContent.courseStartTime !== "") { 
                crsStartTime = DateTimeFormats(resultContent.courseStartTime._seconds,"time-24");
              }
              if(resultContent.courseEndTime !== undefined && resultContent.courseEndTime !== "") { 
                crsEndTime = DateTimeFormats(resultContent.courseEndTime._seconds,"time-24");
              }
              this.setState({
                  stTitle: 'Detail Workshop',
                  course_docid: resJson.values[0].docid,
                  course_createby: resJson.values[0].content[0].maincontent.createByName,
                  course_createdate: cDate,
                  course_title: lTitle,
                  course_meet_link: lMeetLink,
                  course_short_desc: lShortDesc,
                  course_long_desc: lLongDesc,
                  course_language: lLanguage,
                  course_currency: lPriceCurrency,
                  course_price: lPrice,
                  course_date: crsDate,
                  course_start_time: crsStartTime,
                  course_end_time: crsEndTime,
                  course_picture: lPicture,
                  course_video: lVideo,
                  course_recording: lRecording,
                  course_category: lCategory,
                  course_subcategory: lSubCategory,
                  course_tutor: lTutor,
                  course_agenda_list: resJson.values[0].scontent,
              }) 
          } else {
              console.log('Something happened wrong');
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
        }); 
    }
  }

  componentDidMount() {
    var geturl = this.props.location; 
    var arrString = geturl.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');
    
    if(arrUrl[1] === 'IViewCourse') {
        if(arrUrl[2] !== '') { 
            this.loadData(arrUrl[1],arrUrl[2]);
        }
    }  
  }

  render() {
   var agendaList = [];
   if(this.state.course_agenda_list.length > 0) {
    this.state.course_agenda_list.sort((a, b) => a.subcontent.agendaTime._seconds - b.subcontent.agendaTime._seconds);
    for(var ls=0; ls < this.state.course_agenda_list.length; ls++) {
      var retResult3 = this.state.course_agenda_list[ls];
      var trcls = '';
      var time = DateTimeFormats(retResult3.subcontent.agendaTime._seconds,"time-24");
      agendaList.push(
        <tr key={ls+1} className={trcls}>
          <th scope="row">{time}</th>
          <td>{retResult3.subcontent.agendaDuration + " minutes"}</td>
          <td>{retResult3.subcontent.agendaTitle}</td>
          <td>{retResult3.subcontent.agendaDesc}</td>
        </tr>
      );
    }
   } else {
      agendaList.push(<tr key="0">
          <td colSpan="5" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
        </tr>)
   }

   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Workshops</div>
                {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[2] === "ON") || (JSON.parse(localStorage.getItem('urole'))[3] === "ON")  || (JSON.parse(localStorage.getItem('urole'))[4] === "ON"))? 
                <div>
                  <div className="stIndiTitle">Workshops { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                  <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="row-field">
                            <div className="w80p">
                              <h4>{this.state.course_title}</h4>
                              <h5 className="font-sublist">{this.state.course_category} <KeyboardArrowRightIcon className="iconLightGrey"></KeyboardArrowRightIcon> {this.state.course_subcategory}</h5>
                            </div>
                            <div className="divRight w20p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        <div className="divRight">
                          <div className="detailJoined"><span>Created By : {this.state.course_createby}</span> <span className="mgL20">On : {this.state.course_createdate}</span></div>
                        </div>
                        <div className="mgT20 flex-container">
                            <div className="w20p"><h6>Title</h6></div>
                            <div className="w80p"><span className="mgR10">:</span>{this.state.course_title}</div>
                        </div>
                        <div className="mgT05 flex-container">
                            <div className="w20p"><h6>Category</h6></div>
                            <div className="w80p"><span className="mgR10">:</span>{this.state.course_category} <KeyboardArrowRightIcon className="iconLightGrey"></KeyboardArrowRightIcon> {this.state.course_subcategory}</div>
                        </div>
                        <div className="mgT05 flex-container">
                            <div className="w20p"><h6>Language</h6></div>
                            <div className="w80p"><span className="mgR10">:</span>{this.state.course_language}</div>
                        </div>
                        <div className="mgT05 flex-container">
                            <div className="w20p"><h6>Price</h6></div>
                            <div className="w80p"><span className="mgR10">:</span>{this.state.course_currency} {this.state.course_price}</div>
                        </div>
                        <div className="mgT30 flex-container">
                            <div className="w20p"><h6>Tutor</h6></div>
                            <div className="w80p"><span className="mgR10">:</span>{this.state.course_tutor}</div>
                        </div>
                        <div className="mgT05 flex-container">
                            <div className="w20p"><h6>Schedule</h6></div>
                            <div className="w80p flex-container">
                              <div><span className="mgR10">:</span>{this.state.course_date} </div>
                              <div className="mgL30">
                                <div className="flex-container"><div style={{width:"70px"}}> Start at</div> <div>{this.state.course_start_time}</div></div>
                                <div className="flex-container"><div style={{width:"70px"}}> End at </div> <div>{this.state.course_end_time} </div></div>
                              </div>
                            </div>
                        </div>
                        <div className="mgT20">
                          <div><h6>Short Description :</h6></div>
                          <div>{this.state.course_short_desc}</div>
                        </div>
                        <div className="mgT30">
                          <div><h6>Full Description :</h6></div>
                          <div>{this.state.course_long_desc}</div>
                        </div>
                        <div className="mgT30">
                          <div><h6>Google Meet Link :</h6></div>
                          <div><a href={this.state.course_meet_link} target="_blank" rel="noreferrer">{this.state.course_meet_link}</a></div>
                        </div>
                        <div className="mgT30">
                          <div><h6>Picture :</h6></div>
                          <div><img width="150" src={this.state.course_picture} alt=""/></div>
                        </div>
                        {(this.state.course_video !== "" && this.state.course_video !== undefined)?
                        <div className="mgT30">
                          <div>
                            <h6>Video Intro :</h6>
                            <iframe src={this.state.course_video} title={this.state.course_title}></iframe>
                            <br/>
                            <a href={this.state.course_video} target="_blank" rel="noreferrer">{this.state.course_video}</a>
                          </div>
                        </div>
                        : '' }
                        {(this.state.course_recording !== "" && this.state.course_recording !== undefined)?
                        <div className="mgT30">
                          <div>
                            <h6>Video Recording :</h6>
                            <iframe src={this.state.course_recording} title={this.state.course_title}></iframe>
                          </div>
                        </div>
                        : '' }
                        <div className="mgT50" id="agenda-area">
                          <div className="flex-container">
                            <div className="w70p st4Title divMiddle">Agenda</div>
                            <div className="w30p divRight">
                            </div>
                          </div>
                          <div className="mgT20">
                            <table className="table table-hover">
                              <thead className="thead-light">
                                  <tr key="0">
                                  <th scope="col textWrap">Time</th>
                                  <th scope="col textWrap">Duration</th>
                                  <th scope="col textWrap">Subject</th>
                                  <th scope="col textWrap">Description</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {agendaList}
                              </tbody>
                            </table>
                          </div>
                        </div>

                    </div>
                    {/* ====== */}
                  </div>  
                </div>
                :   
                <div><INA/></div>
                }
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div>
    </div>
   );
  }
}
 
export default ICourseDetail;