import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iGlobal';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import DateTimeFormats from '../../inc/date-time-format';
import Popup from '../../inc/popup';
import '../../inc/popup.css'
import NotifDetail from './ipopnotif-detail';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[8];

class ILogNotifDetail extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: 'Detail',
        create_date: '',
        logdoc_id: '',
        subjectLog: '',
        contentLog_list: [],
        styleTb: 'table',
        isOpen: false,
        otherContent: ''  
    }
    this.loadLogNotifData = this.loadLogNotifData.bind(this);
  }

  handleBack = () => {
    this.props.history.goBack();
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false
      }
    })
  }  
  urldetailNotif(docId, content) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: true,
          ...prevState.otherContent, otherContent: content,
      }
    })
  }

  loadLogNotifData(id) {
      document.getElementById('loading-area').style.display = 'block'; 
      fetch(global.config.urlLink+"/lognotifview/"+id, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }
      }).then(res => {
          if(res.status === 200) 
              return res.json() 
      }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') {
              var subjectLog = '', cDatez = '';
              if(resJson.values[0].content[0].maincontent.subjectLog !== undefined) { subjectLog = resJson.values[0].content[0].maincontent.subjectLog; }
              if(resJson.values[0].content[0].createdate !== undefined) {
                  cDatez = DateTimeFormats(resJson.values[0].content[0].createdate._seconds,"date-long");
              }
              this.setState({
                stTitle: 'Detail',
                create_date: cDatez,
                logdoc_id: resJson.values[0].docid,
                subjectLog: subjectLog,
                contentLog_list: resJson.values[0].scontent,
                styleTb: 'table',
              }) 
          } else {
              console.log('Something happened wrong');
          }
      }).catch(err => { 
          document.getElementById('loading-area').style.display = 'none'; 
      }); 
    
  }
  
  componentDidMount() {    
    var geturl = this.props.location; 
    var arrString = geturl.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');
    if(arrUrl[2] !== '') { 
        this.loadLogNotifData(arrUrl[2]);
    } 
    
  }

  render() {
   var logNotifDetailList = [];
   if(this.state.contentLog_list.length > 0) {
    for(var ls=0; ls < this.state.contentLog_list.length; ls++) {
        var retResult = this.state.contentLog_list[ls];
        var trcls = '';
        var expDatez = "";
        if(retResult.subcontent.endPeriode !== undefined) {
            expDatez = DateTimeFormats(retResult.subcontent.endPeriode._seconds,"date-short");
        }
        logNotifDetailList.push(<tr key={ls+1} className={trcls}>
          <th scope="row">{ls+1}</th>
          <td>{retResult.subcontent.clientName}<br/><span className="font-sublist">{retResult.subcontent.domainName}</span><br/><span className="font-sublist">{retResult.subcontent.clientCountry}</span></td>
      
          <td>{retResult.subcontent.licenceType + "\n Seat : " + retResult.subcontent.licenceSeat}</td>
          <td>{expDatez}</td>
          <td>{retResult.subcontent.result}</td>
          <td><RemoveRedEyeIcon className="iconGrey cursor-on" onClick={this.urldetailNotif.bind(this, this.state.logdoc_id, JSON.stringify(retResult))}></RemoveRedEyeIcon></td>
        </tr>)
    }
   } else {
    logNotifDetailList.push(<tr key="0">
          <td colSpan="4" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
        </tr>)
   }
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Log Notification</div>
                <div className="stIndiTitle">Log Notificaton { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                <div className="mgT30">
                  {/* ====== */}
                  <div  className="content-container card card-body">
                      <div className="flex-container">
                          <div className=" w70p">
                            <h4>{this.state.subjectLog}</h4>
                            <h5 className="detailJoined">sent date : {this.state.create_date}</h5>
                          </div>
                          <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                      </div>
                      <div id="details" className="mgT20">
                        <div className="mgT20">
                            <table className="table table-hover">
                                <thead className="thead-light">
                                    <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Client Name</th>
                                    <th scope="col">Licence Type</th>
                                    <th scope="col">Expired On</th>
                                    <th scope="col">Result</th>
                                    <th scope="col" style={{width:"100px"}}>Action</th>
                                    
                                    </tr>
                                </thead>
                                <tbody>
                                    {logNotifDetailList}
                                </tbody>
                            </table>
                        </div>
                      </div>
                  </div>
                  <div>
                          {this.state.isOpen && <Popup
                            content={<><NotifDetail/></>}
                            id={this.state.logdoc_id} 
                            title={"Log Notification Detail"}
                            others={this.state.otherContent}
                            styles={"70%"}
                            handleClose={this.togglePopClose.bind(this, this.state.licencedoc_id)}
                          />}
                  </div>
                  {/* ====== */}
                </div>
                
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div>
    </div>
   );
  }
}
 
export default ILogNotifDetail;