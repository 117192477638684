import React, { Component } from 'react';
import iconlogo from './assets/logo-icon.png';
import logo from './assets/logo-w.png';
import './inc/screen.css';
import './inc/genstyle.css';


class Screens extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(ln) {    
        document.location.href=ln;
    }

    render() {
        return (
            <div className="sscreen">
                <div className="sscreen-header">
                    <img src={iconlogo} className="sscreen-logo" alt="logo" />
                    <p><img src={logo} alt="logo" /></p>
                    <div className="flex-container mgT30">
                        <button className="btMainScreen mgR30" onClick={() => this.handleClick('/CustomerPortal')}>customer portal</button>
                        <button className="btMainScreen mgL30" onClick={() => this.handleClick('/InternalPortal')}>internal portal</button>
                    </div>
                </div>
                
            </div>
        )
    }
}
export default Screens;