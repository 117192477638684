import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../cinc/cStyle.css';
import ReplaceAllContent from '../../inc/replace-all';
import CheckLogin from '../cinc/ccekLogin';
import { FormErrors } from '../../inc/error-form';
import CHeader from './cfragmentheader';
import CSidemenu from './cfragmentsidemenu';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const authKey = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[20];

class CContact extends Component {
  constructor (props) {
    super(props);
    this.state = {
        memberdoc_id: localStorage.getItem('cudocid'),
        clientdoc_id: '',
        company_name: '',
        domain_name: '',
        client_country: '',
        customer_name: '',
        customer_email: '',
        customer_phone: '',
        customer_message: '',
        formErrors: {customer_name: '',customer_email: '', customer_phone: '', customer_message: ''},
        customerNameValid: false,
        customerEmailValid: false,
        customerMessageValid: false,
        formValid: false,
        errmsg: '',
        isSignin: false,
        hideNav: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  resize() {
    let currentHideNav = (window.innerWidth <= 480);
    if (currentHideNav !== this.state.hideNav) {
        this.setState({hideNav: currentHideNav});
    }
    if(currentHideNav === false) { 
      document.getElementById("sidemenu").style.display = "block";
    } else {
      document.getElementById("sidemenu").style.display = "none";
    }
  
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  } 
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let customerNameValid = this.state.customerNameValid;
    let customerEmailValid = this.state.customerEmailValid;
    let customerMessageValid = this.state.customerMessageValid;
    
    switch(fieldName) {
        case 'customer_name': 
          customerNameValid = value.trim().length > 0;
          fieldValidationErrors.customer_name = customerNameValid ? '' : 'name need to be filled';
          break;
        case 'customer_email': 
          customerEmailValid = value.trim().length > 0;
          fieldValidationErrors.customer_email = customerEmailValid ? '' : 'email need to be filled';
          break;
        case 'customer_message': 
          customerMessageValid = value.trim().length > 0;
          fieldValidationErrors.customer_message = customerMessageValid ? '' : 'message need to be filled';
          break;
        default:
            break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    customerNameValid: customerNameValid,
                    customerEmailValid: customerEmailValid,
                    customerMessageValid: customerMessageValid,
                }, this.validateForm);                            
  }
  validateForm() {
    this.setState({formValid: this.state.customerNameValid && this.state.customerEmailValid && this.state.customerMessageValid });
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  handleSubmit(e) {
    e.preventDefault();
    
    if(this.state.customer_name !== '' && this.state.customer_email !== '' && this.state.customer_message !== '') {
      document.getElementById('loading-area').style.display = 'block'; 
      var cust_message = this.state.customer_message;
      if(cust_message !== '') { cust_message = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',cust_message)); }
      
      var urlFetch, datatext;
      datatext ='{"member_id":"'+this.state.memberdoc_id+'","client_id":"'+this.state.clientdoc_id+'","client_name":"'+this.state.company_name+'","domain_name":"'+this.state.domain_name+'","client_country":"'+this.state.client_country+'","customer_name":"'+this.state.customer_name+'","customer_email":"'+this.state.customer_email+'","customer_phone":"'+this.state.customer_phone+'","customer_message":"'+cust_message+'","form_id":"form-contactus"}';
      urlFetch = global.cconfig.urlLink+"/addclientmessage";
  
      var data = JSON.parse(datatext); 
      fetch(urlFetch, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }, body : JSON.stringify(data)
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          document.getElementById('form-content').style.display = 'none';
          if(resJson.status === '200') {
              document.getElementById('thankyou-page').style.display = 'block';
              document.getElementById('error-page').style.display = 'none';
          } else {
              document.getElementById('thankyou-page').style.display = 'none';
              document.getElementById('error-page').style.display = 'block';
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
          document.getElementById('form-content').style.display = 'none';
          document.getElementById('thankyou-page').style.display = 'none';
          document.getElementById('error-page').style.display = 'block';
        }) ;
    } else {
      var arrfieldName = ['customer_name','customer_email','customer_message'];
      var arrvalue = [this.textInputCustomerName.value,this.textInputCustomerEmail.value,this.textInputCustomerMessage.value];
      for(var ck in arrfieldName) {
          this.validateField(arrfieldName[ck], arrvalue[ck]);
      }
    }
  }

 componentDidMount() {
    document.title = global.cconfig.webtitle;
    document.getElementById('loading-area').style.display = 'block'; 
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    CheckLogin().then((statL) =>{ 
      if(statL === true) {
        document.getElementById('form-content').style.display='block';
        fetch(global.cconfig.urlLink+"/infoclientmessage/"+this.state.memberdoc_id, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') { 

            this.setState({
              memberdoc_id: resJson.values.memberData[0].docid,
              clientdoc_id: resJson.values.companyData[0].docid,
              company_name: resJson.values.companyData[0].content[0].maincontent.clientName,
              domain_name: resJson.values.companyData[0].content[0].maincontent.domainName,
              client_country: resJson.values.companyData[0].content[0].maincontent.clientCountry,
              customer_name: resJson.values.memberData[0].content[0].maincontent.memberName,
              customer_email: resJson.values.memberData[0].content[0].maincontent.memberEmail,
              customer_phone: '',
              customer_message: '',
              message_list: resJson.values.memberData[0].spcontent,
              formErrors: {customer_name: '',customer_email: '',  customer_phone: '',  customer_message: ''},
              customerNameValid: false,
              customerEmailValid: false,
              customerPhonealid: false,
              customerMessageValid: false,
              requestSubjectTrainingValid: false,
              formValid: false,
              errmsg: '',
              isSignin: true
            });
          } else {
            document.getElementById('loading-area').style.display = 'none'; 
          }
        }).catch(err => { 
          document.getElementById('loading-area').style.display = 'none'; 
        });
      } else {
        window.location.href="/clogin";
      }
    });
 } 
 
 render() {
   return (
    
      <div className="main-container flex-container cwrapper">
        <div id="sidemenu" className="csidebar">{(this.state.isSignin === true)? <CSidemenu showHideNav={this.state.hideNav}/> : '' }</div>
        <div className="w100p">
          <div className="cheader"><CHeader showHideNav={this.state.hideNav}/></div>
          <div className="cmain-content">
            {/* ====== */}
            {(this.state.isSignin === true)? 
            <div>
                <div className="stTitle">Contact Us</div>
                <div className="stIndiTitle">Contact us to get solution from your problem</div>
                        
                <div className="mgT30">
                    
                    <div className="card card-main card-main-white">
                      <div id="error-page" className="divHide">
                        <div className="flex-container">
                            <CancelIcon className="iconRed"></CancelIcon>
                            <div className="mgL20 done-text">Failed to submit the request.</div>
                          </div>
                      </div>
                      <div id="thankyou-page" className="divHide">
                        <div className="flex-container">
                            <CheckCircleIcon className="iconGreen"></CheckCircleIcon>
                            <div className="mgL20 done-text">Thank you, your customer representative will contact you shortly.</div>
                          </div>
                      </div>
                      <div id="form-content">
                        <div id="FormCenter" className="mgT20">
                          <form onSubmit={this.handleSubmit}>
                            <div className="row-field mgT20">
                              <label style={{width:"150px"}} htmlFor="customer_name">Name</label>
                              <div className="w70p">
                                <input type="text" className="form-control" name="customer_name"
                                    placeholder={this.state.customer_name}
                                    value={this.state.customer_name}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputCustomerName = input}/>
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.customer_name}/>  }</div>
                              </div>
                            </div>
                            <div className="row-field mgT20">
                              <label style={{width:"150px"}} htmlFor="customer_email">Email</label>
                              <div className="w70p">
                                <input type="text" className="form-control" name="customer_email"
                                    placeholder={this.state.customer_email}
                                    value={this.state.customer_email}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputCustomerEmail = input}/>
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.customer_email}/>  }</div>
                              </div>
                            </div>
                            <div className="row-field mgT20">
                              <label style={{width:"150px"}} htmlFor="customer_phone">Phone Number</label>
                              <div className="w70p">
                                <input type="text" className="form-control" name="customer_phone"
                                    placeholder={this.state.customer_phone}
                                    value={this.state.customer_phone}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputCustomerPhone = input}/>
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.customer_phone}/>  }</div>
                              </div>
                            </div>
                            <div className="mgT20">
                              <label style={{width:"150px"}} htmlFor="customer_message">Message</label>
                              <div>
                                <textarea name="customer_message" className="form-control" 
                                  rows={5} cols={30} 
                                  value={this.state.customer_message} 
                                  onChange={this.handleUserInput} 
                                  ref={(input) => this.textInputCustomerMessage = input}/>
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.customer_message}/>  }</div>
                              </div>
                            </div>
                            <div className="mgT30"><button id="submit-button"  type="submit" className="submitButton">Submit</button></div>
                          </form>
                        </div>
                      </div>
                        
                    </div>
                </div>

            </div>
            : <div id="form-content"></div> }
            {/* ====== */}
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div>
      </div>
   
    
    
   );
 }
}
 
export default CContact;