import React, { Component } from 'react';
import '../../inc/genstyle.css';

 
class IUC extends Component {
 constructor(props) {
   super(props);
   this.state = {
     done: undefined
   };
 }
 render() {
   return (
    <div className="divCenter mgT50">
      <div className="heading1 fontGrey">Under Construction</div>
      <div className="mgT20 fontGrey textCenter">Hey! Thank you for checking out our app.</div>
      <div className="mgT05 fontGrey textCenter">It’s not quite ready yet, but we are working hard and it will be ready in approximately.</div>
    </div>
   );
 }
}
 
export default IUC;