import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import '../../inc/popup.css';
import Popup from '../../inc/popup';
import ZTEList from './ipopzte-list';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import { FormErrors } from '../../inc/error-form';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[30];
const zteKey = global.config.zteAPIKey;
const hostlinktodelete = "https://chromecommercial.googleapis.com/v1";

function thirdCall(docid) {
    return new Promise(function (resolve, reject) {
        if(docid !== "") {
            var datatext ='{"doc_id":"'+docid+'","form_id":"form-edit-zte"}';
            var urlFetch = global.config.urlLink+"/zteedit";
            var dataupdate = JSON.parse(datatext); 
            
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(dataupdate)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                resolve(resJson.values);
            }).catch(err => err);
        } else {
            resolve("empty");
        }
    })
}
function secondCall(idx,data_send) {
    return new Promise(function (resolve, reject) {
        var jsonStrResponse = "";
        var serialNumber = data_send.dataSerialNumber[idx].serialNumber;
        var preprovisionDeviceid = data_send.dataSerialNumber[idx].preprovisionDeviceId;
        var urlFetch = hostlinktodelete+"/preProvisionedDevices/"+preprovisionDeviceid+"?key="+zteKey;
        fetch(urlFetch, {
            method: 'DELETE', 
        }).then(res => {
            document.getElementById('loading-area').style.display = 'none'; 
            return res.json() 
        }).then( resJson => {
            jsonStrResponse += '{"serial_number": "'+serialNumber+'","preprovision_deviceid": "'+preprovisionDeviceid+'",';
            if(resJson.error === undefined) {
                if(JSON.stringify(resJson.values) === "{}") {
                    jsonStrResponse += '"status": "DELETED", "message": "Successfully Deleted", "code": "200"';
                } else {
                    jsonStrResponse += '"status": "ERROR", "message": "Unknown Error", "code": "---"';
                }
            } else {
                jsonStrResponse += '"status": "'+resJson.error.status+'", "message": "'+resJson.error.message+'", "code": "'+resJson.error.code+'"';
            }
            jsonStrResponse += '}';
            resolve(jsonStrResponse);
        });
    });
}
async function firstCall(docid,resRow,limitSend,data) {
    if(resRow > 0) {
        var arrPromise = [];
        for(var a=0; a<resRow; a++) {
            arrPromise.push(secondCall(a,data));
            if(a === (resRow-1)) {
                arrPromise.push(thirdCall(docid));
                await Promise.all(arrPromise).then((values) => {
                    var jsonResult = "";
                    var returnErrMessage = "";
                    var arrResJson = []; 
                    var endRowValues = values.length-1;
                    for(var p=0; p<endRowValues; p++) {
                        jsonResult = JSON.parse(values[p]);
                        arrResJson.push(jsonResult);
                        if(jsonResult.code !== "200") { returnErrMessage = jsonResult.message; }
                        if(p === (endRowValues-1)) {
                            var reqData = '{"domain": "'+data.domainName+'","fileid": "'+data.fileId+'"}';
                            var datatext2 = '{"request":'+reqData+', "response":'+JSON.stringify(arrResJson)+',"fromset":"ztedel", "form_id":"insert-log"}';
                            var data2 = JSON.parse(datatext2); 
                            var urlFetch2 = global.config.urlLink+"/inszteres";
                            fetch(urlFetch2, {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Access-Control-Allow-Origin': '*',
                                    'Authorization': authKey,
                                }, body : JSON.stringify(data2)
                            }).then(res2 => {
                                if(res2.status === 200) 
                                    return res2.json() 
                            }).then( resJson2 => {
                                document.getElementById('loading-area').style.display = 'none'; 
                                document.getElementById('result2-area').style.display = 'block';
                                
                                if(returnErrMessage !== "") {
                                    document.getElementById('result2-value').innerHTML = returnErrMessage;
                                } else {
                                    document.getElementById('result2-value').innerHTML = "Successfully Delete";
                                }
                                    
                                return returnErrMessage;
                            })
                        }

                    }
                });
            }
        }
        
    } else return false;
}

class IZTEFormDel extends Component {
    constructor(props) {
      super(props);
      this.state = {
          domain_name: '',
          preprovision_deviceid: '',
          domain2_name: '',
          link2_url: '',
          defTabIndex: 0,
          formErrors: { domain_name: '', preprovision_deviceid: '', domain2_name: '', link2_url: ''},
          domainnameValid: false,
          preprovisiondeviceidValid:false,
          domain2nameValid: false,
          link2urlValid: false,
          formValid: false,
          isOpen: false,
          otherContent: '', 
      };
      this.handleSelectTab = this.handleSelectTab.bind(this);
      this.handleSubmit1 = this.handleSubmit1.bind(this);
      this.handleSubmitBulk = this.handleSubmitBulk.bind(this);
    }
    togglePopClose() {
        this.setState( prevState => {
          return {  
              ...prevState.isOpen, isOpen: false,
          }
        })
    }
    handleSelectTab(idx) {
        var used = "bulk";
        if(idx===1) { used = "single"; }
        
        this.setState( prevState => {
          return {  
              ...prevState.usageFor, usageFor: used
          }
        })
    }
    
    handleOpenListFile(e) {
        e.preventDefault();
        this.setState( prevState => {
            return {  
                ...prevState.isOpen, isOpen: true,
                ...prevState.otherContent, otherContent: '{}', 
            }
        })
        
    }
    handleGetFile(e) {
        e.preventDefault();
        document.getElementById('loading-area').style.display = 'block'; 
        var datatext = '{"fromset":"ztedel","form_id":"create-template"}';
        var data = JSON.parse(datatext); 
        var urlFetch = global.config.urlLink+"/createtemplatess";
        fetch(urlFetch, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }, body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) 
                 return res.json() 
        }).then( resJson => {
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') {
                this.setState({ link2_url : resJson.values.fileinfo.fileLink});
                window.open(resJson.values.fileinfo.fileLink);
            } else {
                console.log('Something happened wrong');
            }
        }).catch(err => {
            document.getElementById('loading-area').style.display = 'none'; 
        });
    }
    
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    handleUserSelect = (e) => {
        var value = e.target.value;
        var inputname = e.target.name;
        
        this.setState( prevState => {
          return {  
              ...prevState.hardware_model, hardware_model: value,
          }
        }, () => { 
            this.validateField(inputname, value)
        }) 
      }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let domainnameValid = this.state.domainnameValid;
        let preprovisiondeviceidValid = this.state.preprovisiondeviceidValid; 
        let domain2nameValid = this.state.domain2nameValid;
        let link2urlValid = this.state.link2urlValid;

        switch(fieldName) {
            case 'domain_name': 
                domainnameValid = value.trim().length > 0;
                fieldValidationErrors.domain_name = domainnameValid ? '' : 'domain name need to be filled';
                break;
            case 'preprovision_deviceid': 
                preprovisiondeviceidValid = value.trim().length > 0;
                fieldValidationErrors.preprovision_deviceid = preprovisiondeviceidValid ? '' : 'pre-provisioning device id need to be filled';
                break;
            case 'domain2_name': 
                domain2nameValid = value.trim().length > 0;
                fieldValidationErrors.domain2_name = domain2nameValid ? '' : 'domain name need to be filled';
                break;
            case 'link2_url':
                link2urlValid = value.trim().length > 0;
                fieldValidationErrors.link2_url = link2urlValid ? '' : 'link url need to be filled';
                break;
            default:
                break;
        }
        
        this.setState({formErrors: fieldValidationErrors,
                        domainnameValid: domainnameValid,
                        preprovisiondeviceidValid: preprovisiondeviceidValid,
                        domain2nameValid: domain2nameValid,
                        link2urlValid: link2urlValid
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.domainnameValid && this.state.preprovisiondeviceidValid});
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit1(e) {
        e.preventDefault();
        if(this.state.domain_name !== '' && this.state.preprovision_deviceid !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var domainName,preprovisionDeviceid;
            if(this.textInputDomainName.value === '') { domainName = this.state.domain_name.trim(); } else { domainName = this.textInputDomainName.value.trim(); }
            if(this.textInputPreprovisionDeviceid.value === '') { preprovisionDeviceid = this.state.preprovision_deviceid.trim(); } else { preprovisionDeviceid = this.textInputPreprovisionDeviceid.value.trim(); }

            var urlFetch = hostlinktodelete+"/preProvisionedDevices/"+preprovisionDeviceid+"?key="+zteKey;
            fetch(urlFetch, {
                method: 'DELETE',
            }).then(res => {
                document.getElementById('loading-area').style.display = 'none'; 
                return res.json() 
            }).then( resJson => {
                var datatext ='{"domain": "'+domainName+'","preprovision_deviceid": "'+preprovisionDeviceid+'"}';
                var datatext2 = '{"request":'+datatext+', "response":'+JSON.stringify(resJson)+',"fromset":"ztedel", "form_id":"insert-log"}';
                var data2 = JSON.parse(datatext2); 
                var urlFetch2 = global.config.urlLink+"/inszteres";
                fetch(urlFetch2, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey,
                    }, body : JSON.stringify(data2)
                }).then(res2 => {
                    if(res2.status === 200) 
                        return res2.json() 
                }).then( resJson2 => {
                
                    document.getElementById('loading-area').style.display = 'none'; 
                    document.getElementById('result-area').style.display = 'block';
                    
                    if(resJson.error === undefined) {
                        if(JSON.stringify(resJson.values) === "{}") {
                            document.getElementById('result-value').innerHTML = "successfully delete";
                        } else {
                            document.getElementById('result-value').innerHTML = "unknown error";
                        }
                    } else {
                        console.log('Something happened wrong');
                        document.getElementById('result-value').innerHTML = resJson.error.message + " (code : "+resJson.error.code+")";
                    }
                })
            }).catch(err => {
                document.getElementById('loading-area').style.display = 'none'; 
            });
        } else {
            var arrfieldName = ['domain_name','preprovision_deviceid'];
            var arrvalue = [this.textInputDomainName.value,this.textInputPreprovisionDeviceid.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    handleSubmitBulk(e) {
        e.preventDefault();
        if(this.state.domain2_name !== '' && this.state.link2_url !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var domain2Name,link2Url;
            if(this.textInputDomain2Name.value === '') { domain2Name = this.state.domain2_name; } else { domain2Name = this.textInputDomain2Name.value; }
            if(this.textInputLink2Url.value === '') { link2Url = this.state.link2_url; } else { link2Url = this.textInputLink2Url.value; }
            
            var arrLinkPath = link2Url.replace("https://","").split('/');
            var fileid = link2Url;
            if(arrLinkPath.length > 0) {
                fileid = arrLinkPath[3];
            }
            var rangez = "Data!A2:B";
            var datatext = '{"fileid":"'+fileid+'","range":"'+rangez+'","fromset":"ztedel"}';
            var data = JSON.parse(datatext); 
            var urlFetch = global.config.urlLink+"/getssdata";
            
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                 }, body : JSON.stringify(data)
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                if(resJson.status === '200') {
                    var dataRes = resJson.values.content;
                    var docid = resJson.values.docid;
                    if(dataRes.length > 0) {
                        var dataTransfer = {
                            "domainName": domain2Name,
                            "fileId": fileid,
                            "dataSerialNumber": dataRes
                        }
                        var resRow = dataRes.length;
                        var limitSend = 99;
                        var response = firstCall(docid,resRow,limitSend,dataTransfer);
                    } else {
                        document.getElementById('loading-area').style.display = 'none'; 
                    }
                } else {
                    document.getElementById('loading-area').style.display = 'none'; 
                }
              }).catch(err => {
                document.getElementById('loading-area').style.display = 'none'; 
              }) ;
              
        } else {
            var arrfieldName = ['domain2_name','link2_url'];
            var arrvalue = [this.textInputDomain2Name.value,this.textInputLink2Url.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    contentTabs(param,props) {
      if(param === "bulk") {
        return(<div>
            <div className="row show">
                <div className="col-6 divMiddle"></div>
                <div className="col-6 form-inline justify-content-end">#1 </div>
            </div>
            <div className="mgT20">
                    <div className="divHide" id="result2-area">
                        <div id="result2-value" className="row-field mgT30 mgB30 errText textCenter"></div>
                    </div>
                <form onSubmit={this.handleSubmitBulk}>
                    <div className="row-field mgT20">
                        <label className="w350x mgT05" htmlFor="domain2_name">Domain Name</label>
                        <div className="w350x-min">
                            <input type="text" className="form-control" name="domain2_name"
                                placeholder={this.state.domain2_name}
                                value={this.state.domain2_name}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputDomain2Name = input}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.domain2_name}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT20">
                        <label className="w350x mgT05">To input your list of Preprovisioning Device Id</label>
                        <div className="w350x-min flex-container">
                                <button className="subgenButton" onClick={this.handleGetFile.bind(this)}>Get new file</button>
                                <button className="mgL20 subgenButton" onClick={this.handleOpenListFile.bind(this)}>Open existing file</button>                 
                        </div>                            
                    </div>
                    <div className="row-field mgT05">
                        <label className="w350x mgT05" htmlFor="link2_url">File Url</label>
                        <div className="w350x-min">
                            <input type="text" className="form-control" name="link2_url"
                                placeholder={this.state.link2_url}
                                value={this.state.link2_url}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputLink2Url = input}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.link2_url}/>  }
                            </div>
                        </div>
                    </div>
                    
                    <div className="row-field mgT30 mgB30">
                        <div className="w100p"><button id="submit-button"  type="submit" className="w100p submitButton">Submit</button></div>
                    </div>
                    
                </form>
            </div>
         </div>)
      
      } else {
        return(<div>
                <div className="row show">
                    <div className="col-6 divMiddle"></div>
                    <div className="col-6 form-inline justify-content-end">#2 </div>
                </div>
                <div className="mgT20">
                        <div className="divHide" id="result-area">
                            <div id="result-value" className="row-field mgT10 mgB30 errText textCenter"></div>
                        </div>
                    <form onSubmit={this.handleSubmit1}>
                        <div className="row-field mgT20">
                            <label className="w280x mgT05" htmlFor="domain_name">Domain Name</label>
                            <div className="w280x-min">
                                <input type="text" className="form-control" name="domain_name"
                                    placeholder={this.state.domain_name}
                                    value={this.state.domain_name}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputDomainName = input}/>
                                <div className="mgT05">
                                    { <FormErrors formErrors={this.state.formErrors.domain_name}/>  }
                                </div>
                            </div>
                        </div>
                        <div className="row-field mgT20">
                            <label className="w280x mgT05" htmlFor="preprovision_token">Pre-provisioning Device Id</label>
                            <div className="w280x-min">
                                <input type="text" className="form-control" name="preprovision_deviceid"
                                    placeholder={this.state.preprovision_deviceid}
                                    value={this.state.preprovision_deviceid}
                                    onChange={this.handleUserInput}  
                                    ref={(input) => this.textInputPreprovisionDeviceid = input}/>
                                <div className="mgT05">
                                    { <FormErrors formErrors={this.state.formErrors.preprovision_deviceid}/>  }
                                </div>
                            </div>
                        </div>
                        <div className="row-field mgT30 mgB30">
                            <div className="w100p"><button id="submit-button"  type="submit" className="w100p submitButton">Submit</button></div>
                        </div>
                    </form>
                </div>
             </div>
        )
      }
    }  
    
    componentDidMount() {
        var usageFor = 'bulk'
        var  defindex = 0;
        
        this.setState({
          domain_name: '',
          preprovision_deviceid: '',
          domain2_name: '',
          link2_url: '',
          defTabIndex: defindex,
          usageFor: usageFor,
          formErrors: { domain_name: '', preprovision_deviceid: '', domain2_name: '',  link2_url:''},
          domainnameValid: false,
          preprovisiondeviceidValid:false,
          domain2nameValid: false,
          link2urlValid: false,
          formValid: false,
          isOpen: false,
          otherContent: '',
        })
    }
    render() {
      if(this.state.usageFor === '') {
        return (<div className="main-container"></div>);
      } else {
        var  defindex = 0;
        var suppContentPop = '{"usage":"deletezte"}';
        return (
          <div className="main-container">
            <div  className="header-main"><IHeader/></div>
                <div className="flex-container">
                    <div className="sidebar-main"><ISidemenu/></div>
                    <div className="main-content">
                      <div className="stTitle">Delete ZTE</div>
                      {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[8] === "ON"))? 
                      <div>
                        <div className="stIndiTitle">deleted chrome book identity from google</div>
                        <div className="mgT30">
                            <Tabs defaultIndex={defindex} onSelect={this.handleSelectTab}>
                              <TabList className="tabs-class__tab-list">
                                <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Delete in Bulk</Tab>
                                <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Single Delete</Tab>
                              </TabList>
  
                              <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">{this.contentTabs("bulk")}</TabPanel>
                              <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">{this.contentTabs("single")}</TabPanel>
                            </Tabs>
                        </div>
                        <div>
                            {this.state.isOpen && <Popup
                            content={<><ZTEList/></>}
                            id={localStorage.getItem('uid')} 
                            title={"File Deleted List"}
                            others={suppContentPop}
                            styles={"80%"}
                            handleClose={this.togglePopClose.bind(this, localStorage.getItem('uid'))}
                          />}
                        </div>
                      </div>
                      :   
                      <div><INA/></div>
                      }
                    </div>
                </div>
                <div id="loading-area" className="divHide loading"></div>
          </div>
        );
      }
    }
  }
   
  export default IZTEFormDel;