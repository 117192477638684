import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';

const authKey = global.config.authBearerKey;
  

class IAddTestDemo extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stTitle: '',
            user_id: '',
            user_email: '',
            user_fullname: '',
            content_message: '',
            formErrors: { user_email: '', user_fullname: '', content_message: ''},
            useremailValid: false,
            userfullnameValid: false,
            contentmessageValid: false,
            formValid: false,
            input_disabled: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleBack = () => {
        // this.props.history.goBack();
        window.location.href='/demothankyoubot';
    }    
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    handleUserSelect = (e) => {
        const value = e.target.value;
        const inputname = e.target.name;
        this.setState({[inputname]: value},
            () => { this.validateField(inputname, value)});
        
      }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let useremailValid = this.state.useremailValid;
        let userfullnameValid = this.state.userfullnameValid;
        let contentmessageValid = this.state.contentmessageValid;
        
        switch(fieldName) {
            case 'user_email': 
                useremailValid = value.trim().length > 0;
                fieldValidationErrors.user_email = useremailValid ? '' : 'email need to be filled';
                break;
            case 'user_fullname': 
                userfullnameValid = value.trim().length > 0;
                fieldValidationErrors.user_position = userfullnameValid ? '' : 'name need to be filled';
                break;
            case 'content_message': 
                contentmessageValid = value.trim().length > 0;
                fieldValidationErrors.user_level = contentmessageValid ? '' : 'message need to be filled';
                break;
            default:
                break;
        }
        
        this.setState({formErrors: fieldValidationErrors,
                        useremailValid: useremailValid,
                        userfullnameValid: userfullnameValid,
                        contentmessageValid: contentmessageValid,
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.useremailValid && this.state.userfullnameValid && this.state.contentmessageValid});
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit(e) {
        e.preventDefault();
        if(this.state.user_email !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var space_id = "AAAAIGabC6A";
            var userEmail, userFullname, contentMessage;
            if(this.textInputUserEmail.value === '') { userEmail = this.state.user_email; } else { userEmail = this.textInputUserEmail.value; }
            if(this.textInputUserFullName.value === '') { userFullname = this.state.user_fullname; } else { userFullname = this.textInputUserFullName.value; }
            if(this.textInputContentMessage.value === '') { contentMessage = this.state.content_message; } else { contentMessage = this.textInputContentMessage.value; }
            
            var datatext ='{"from_useremail":"'+this.state.user_id+'","touseremail":"'+userEmail+'","touser":"'+userFullname+'","message":"'+contentMessage+'","spaceid":"'+space_id+'","form_id":"scriptapps-chatbot-form"}';
            var urlFetch = global.config.urlLink;
            // if(this.state.user_id !== '') { urlFetch = urlFetch+"/testedit"; }
            // else { urlFetch = urlFetch+"/testadd"; }
            // urlFetch = urlFetch+"/sendbotmsg";
            //console.log(datatext);
            var data = JSON.parse(datatext); 
            
            fetch(urlFetch+"/sendbotmsg", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                console.log(resJson);
                console.log(resJson.status);
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    window.location.href='/demothankyoubot';
                    
                    // fetch(urlFetch+"/sendtochat", {
                    //     method: 'POST',
                    //     headers: {
                    //         'Accept': 'application/json',
                    //         'Content-Type': 'application/json',
                    //         'Access-Control-Allow-Origin': '*',
                    //         'Authorization': authKey,
                    //     }, body : JSON.stringify(data)
                    // }).then(res2 => {
                    //     if(res2.status === 200) 
                    //         return res2.json() 
                    // }).then( resJson2 => {
                    //     console.log(resJson2);
                    //     console.log(resJson2.status);
                    //     document.getElementById('loading-area').style.display = 'none'; 
                    //     if(resJson2.status === '200') {
                    //         window.location.href='/demothankyoubot';
                    //     } else {
                    //         console.log('Something happened wrong 2');
                    //         console.log(resJson2);
                    //     }
                    // }).catch(err => { 
                    //     document.getElementById('loading-area').style.display = 'none'; 
                    //     console.log(err);
                    // });
                    /* ====== */
                } else {
                    console.log('Something happened wrong 1');
                    console.log(resJson);
                }
            }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
                console.log(err);
            });

        } else {
            var arrfieldName = ['user_email','user_position','user_level','user_region'];
            var arrvalue = [this.textInputUserEmail.value,this.textInputUserPosition.value,this.textInputUserLevel.value,this.textInputUserRegion.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    componentDidMount() {
        //var memberId = document.getElementById("contentid").innerText;
        /*
        document.getElementById('loading-area').style.display = 'block'; 
        
        var geturl = this.props.location; 
        var arrString = geturl.pathname;
        var arrUrl = [];
        arrUrl = arrString.split('/');
        
        if(arrUrl[1] === 'TestEditDemoAppsheet' && arrUrl[2] !== '') {
            fetch(global.config.urlLink+"/testdetail/"+arrUrl[2], {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                console.log(resJson);
                console.log(resJson.status);
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') { 
                    this.setState({
                        stTitle: 'Edit Data',
                        user_id: resJson.values[0].docid,
                        user_email: resJson.values[0].content[0].maincontent.userEmail,
                        user_position: resJson.values[0].content[0].maincontent.userPosition,
                        user_level: resJson.values[0].content[0].maincontent.userLevel,
                        user_region: resJson.values[0].content[0].maincontent.userRegion,
                        formErrors: { user_email: '', user_position: '', user_level: '', user_region: ''},
                        useremailValid: false,
                        userpositionValid: false,
                        userlevelValid: false,
                        userregionValid: false,
                        formValid: false,
                        input_disabled: false
                    }); 
                } else {
                    console.log('Something happened wrong');
                    console.log(resJson);
                }
              }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
                console.log(err);
              });
        } else {
            document.getElementById('loading-area').style.display = 'none'; 
            this.setState( prevState => {
                return {
                    ...prevState.stTitle, stTitle: 'Add New',
                    ...prevState.input_disabled, input_disabled: false
                }
            })
        }
        */

        var userid = "";
        if(localStorage.getItem('ufullname') !== undefined && localStorage.getItem('ufullname') !== "") {
            userid = localStorage.getItem('ufullname');
        }
        this.setState( prevState => {
            return {
                ...prevState.stTitle, stTitle: 'Appreciation Form',
                ...prevState.user_id, user_id: userid,
                ...prevState.input_disabled, input_disabled: false
            }
        })
    }

 render() {
    
    
   return (
    <div className="main-container">
        <div className="flex-container">
            <div className="w100p mgL20 mgR20" style={{marginTop:"20px"}}>
            <div className="stTitle"></div>
            <div>
                <div className="stIndiTitle"></div>
                        
                <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="flex-container">
                            <div className="st2Title w70p">{this.state.stTitle}</div>
                            <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        <div className="row-field mgT10">Send your appreciation to your colleagues for their help. </div>
                        <div id="FormCenter" className="mgT20">
                            <form onSubmit={this.handleSubmit}>
                                <div className="flex-container">
                                    <input type="hidden" required name="user_id" value={this.state.user_id} />
                                </div>
                                <div className="row-field mgT20">
                                    <label className="w30p textMiddle" htmlFor="user_fullname">Full Name</label>
                                    <div className="w70p">
                                        <input type="text" className="form-control" name="user_fullname"
                                            placeholder={this.state.user_fullname}
                                            value={this.state.user_fullname}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputUserFullName = input}
                                            disabled={this.state.input_disabled}/>
                                        <div className="mgT05">
                                            { <FormErrors formErrors={this.state.formErrors.user_fullname}/>  }
                                        </div>
                                    </div>
                                </div>
                                <div className="row-field mgT20">
                                    <label className="w30p textMiddle" htmlFor="user_email">Email</label>
                                    <div className="w70p">
                                        <input type="text" className="form-control" name="user_email"
                                            placeholder={this.state.user_email}
                                            value={this.state.user_email}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputUserEmail = input}
                                            disabled={this.state.input_disabled}/>
                                        <div className="mgT05">
                                            { <FormErrors formErrors={this.state.formErrors.user_email}/>  }
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="row-field mgT20">
                                    <label className="w30p textMiddle" htmlFor="content_message">Message</label>
                                    <div className="w70p">
                                        <textarea name="content_message" className="form-control" 
                                                    rows={5} cols={30} 
                                                    value={this.state.content_message} 
                                                    onChange={this.handleUserInput} 
                                                    ref={(input) => this.textInputContentMessage = input}/>
                                        <div className="mgT05">
                                            { <FormErrors formErrors={this.state.formErrors.content_message}/>  }
                                        </div>
                                    </div>
                                </div>
                                <div className="row-field mgT30 mgB30">
                                    <div className="w30p"></div>
                                    <div className="w70p"><button type="submit" className="w100p submitButton">Submit</button></div>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                    {/* ====== */}
                </div>
            </div>
            
            </div>
        </div>
        <div id="loading-area" className="divHide loading"></div> 
    </div>
   );
 }
}
 
export default IAddTestDemo;