import React, { Component } from 'react';
import parse from 'html-react-parser';
import '../../inc/genstyle.css';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import DateTimeFormats from '../../inc/date-time-format';
import NumberFormats from '../../inc/number-format';
import ReplaceAllContent from '../../inc/replace-all';
import Popup from '../../inc/popup';
import '../../inc/popup.css'
import ReqRenewForm from './ipopreqrenew-form';
import CommentForm from './ipopcomment-form';
import RenewDetail from './ipoprenew-detail';
import AdditionalForm from './ipoplicenceadditional-form';
import AdditionalDetail from './ipoplicenceadditional-detail';
import PICForm from './ipoplicencepic-form';
import DriveForm from './ipoplicencedrive-form';

import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import StarRateIcon from '@material-ui/icons/StarRate';
import ClosedCaptionIcon from '@material-ui/icons/ClosedCaption';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
//import ReplaceAllContent from '../../inc/replace-all';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[2];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[4];

class IClientLicence extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stTitle: '',
            create_date: [],
            clientdoc_id: '',
            client_name: '',
            domain_name: '',
            client_country: '',
            licencedoc_id: '',
            client_licence_category: '',
            client_licence_type: '',
            client_licence_seat: '',
            client_licence_price_currency: '', 
            client_licence_price_seat: '', 
            client_licence_duration: '',
            client_licence_duration_long: '',
            client_licence_duration_periode: '', 
            client_licence_duration_billcycle: '',
            client_licence_duration_long_billcycle: '',
            client_licence_duration_periode_billcycle: '', 

            client_licence_speriod: '',
            client_licence_eperiod: '',
            client_licence_eperiod_fb: '',
            client_licence_eperiod_billcycle: '',
            client_licence_status: '',
            client_licence_remarks: '',

            client_licence_renewal_list: [],
            client_licence_notif_list: [],
            client_licence_additional_list: [],
            client_licence_drive_list: [],
            client_licence_pic_list: [],
            flag_licence_exp: '',
            styleTb: 'table',
            isOpen: false,
            isOpen2: false,
            isOpen3: false,
            isOpen4: false,
            isOpen5: false,
            isOpen6: false,
            isOpen7: false,
            otherContent3: '',  
            otherContent4: '',  
            otherContent5: '',
            otherContent6: '',
            otherContent7: '',
            allow_set_pic: false,
            allow_set_drive: false,
            allow_set_seat: false  
        }
    }
    handleBack = () => {
        this.props.history.goBack();
    }
    togglePopClose() {
      this.setState( prevState => {
        return {  
            ...prevState.isOpen, isOpen: false,
            ...prevState.isOpen2, isOpen2: false,
            ...prevState.isOpen3, isOpen3: false,
            ...prevState.isOpen4, isOpen4: false,
            ...prevState.isOpen5, isOpen5: false,
            ...prevState.isOpen6, isOpen6: false,
            ...prevState.isOpen7, isOpen7: false
        }
      })
      // this.loadLicencePage(this.state.clientdoc_id,this.state.licencedoc_id);
      // window.location.reload();
      // window.location.href='/IRenewLicence';
    }  
    urldetailAdditionalLicence(valId,content) {
      this.setState( prevState => {
        return {  
            ...prevState.isOpen7, isOpen7: true,
            ...prevState.otherContent7, otherContent7: '{"additional_id":"'+valId+'","client_id":"'+this.state.clientdoc_id+'","licence_id":"'+this.state.licencedoc_id+'","client_name":"'+this.state.client_name+'","client_country":"'+this.state.client_country+'","domain_name":"'+this.state.domain_name+'","licence_type":"'+this.state.client_licence_type+'","licence_seat":"'+this.state.client_licence_seat+'","addlicence_info": '+content+'}', 
        }
      })
    }
    urladdAdditionalLicence(valId) {
      this.setState( prevState => {
        return {  
            ...prevState.isOpen6, isOpen6: true,
            ...prevState.otherContent6, otherContent6: '{"client_id":"'+this.state.clientdoc_id+'","licence_id":"'+this.state.licencedoc_id+'","client_name":"'+this.state.client_name+'","client_country":"'+this.state.client_country+'","domain_name":"'+this.state.domain_name+'","licence_type":"'+this.state.client_licence_type+'","licence_seat":"'+this.state.client_licence_seat+'"}', 
            
        }
      })
    }
    urlstopSubscribe(valId) {
        this.setState( prevState => {
          return {  
              ...prevState.isOpen2, isOpen2: true
          }
        })
    }
    urlreqRenew(valId) {
      this.setState( prevState => {
        return {  
            ...prevState.isOpen, isOpen: true
        }
      })
    }
    urldetailRenew(valId, reqLicenceSeat) {
      this.setState( prevState => {
        return {  
            ...prevState.isOpen3, isOpen3: true,
            ...prevState.otherContent3, otherContent3: '{"renew_id":"'+valId+'","client_id":"'+this.state.clientdoc_id+'","licence_id":"'+this.state.licencedoc_id+'","client_name":"'+this.state.client_name+'","client_country":"'+this.state.client_country+'","domain_name":"'+this.state.domain_name+'","licence_type":"'+this.state.client_licence_type+'","licence_seat":"'+this.state.client_licence_seat+'","licence_reqseat":"'+reqLicenceSeat+'","licence_periode_unit": "'+this.state.client_licence_duration_periode+'","licence_periode_num": "'+this.state.client_licence_duration_long+'","licence_periode_unit_billcycle": "'+this.state.client_licence_duration_periode_billcycle+'","licence_periode_num_billcycle": "'+this.state.client_licence_duration_long_billcycle+'"}', 
        }
      })
    }
    urladdDriveClient(valId,lId) {
      this.setState( prevState => {
        return {  
            ...prevState.isOpen4, isOpen4: true,
            ...prevState.stPopTitle4, stPopTitle4: 'Add New',
            ...prevState.otherContent4, otherContent4: '{"stPopTitle":"Add","client_id":"'+valId+'","licence_id":"'+lId+'","licdrive_id":"","licdrive_type":"","licdrive_seat":"","licdrive_endperiode":"'+this.state.client_licence_eperiod_fb+'"}', 
        }
      })
    }
    urleditDriveClient(valId, lId, dId, dType, dSeat) {
      this.setState( prevState => {
        return {  
            ...prevState.isOpen4, isOpen4: true,
            ...prevState.stPopTitle4, stPopTitle4: 'Edit',
            ...prevState.otherContent4, otherContent4: '{"stPopTitle":"Edit","client_id":"'+valId+'","licence_id":"'+lId+'","licdrive_id":"'+dId+'","licdrive_type":"'+dType+'","licdrive_seat":"'+dSeat+'","licdrive_endperiode":"'+this.state.client_licence_eperiod_fb+'"}', 
        }
      })
    }
    urladdPicClient(valId,lId) {
      this.setState( prevState => {
        return {  
            ...prevState.isOpen5, isOpen5: true,
            ...prevState.stPopTitle5, stPopTitle5: 'Add New',
            ...prevState.otherContent5, otherContent5: '{"stPopTitle":"Add","clientpic_id":"'+valId+'","licence_id":"'+lId+'","pic_id":"","pic_name":"","pic_phone":"","pic_email":"","pic_level":""}', 
        }
      })
    }
    urleditPicClient(valId, lId, pId, pName, pPhone, pEmail, pLevel) {
      this.setState( prevState => {
        return {  
            ...prevState.isOpen5, isOpen5: true,
            ...prevState.stPopTitle5, stPopTitle5: 'Edit',
            ...prevState.otherContent5, otherContent5: '{"stPopTitle":"Edit","clientpic_id":"'+valId+'","licence_id":"'+lId+'","pic_id":"'+pId+'","pic_name":"'+pName+'","pic_phone":"'+pPhone+'","pic_email":"'+pEmail+'","pic_level":"'+pLevel+'"}', 
        }
      })
    }
    urldeleteElement(valId, lId, pId, fId) {
      var stat = false;
      stat = window.confirm("Are you sure you want to delete this data?");
      if(stat === true) {
          document.getElementById('loading-area').style.display = 'block'; 
          var urlString = global.config.urlLink;
          if(fId === "licpic") {
            urlString = urlString+"/licencepicdelete";
          } else {
            urlString = urlString+"/licencedrivedelete";
            
          }
          var datatext ='{"docid":"'+valId+'","subdocid":"'+lId+'","subsubdocid":"'+pId+'","numchild":0,"form_id":"form-delete-'+fId+'"}';
          var data = JSON.parse(datatext); 
          fetch(urlString, {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  'Authorization': authKey2,
              }, body : JSON.stringify(data)
          }).then(res => {
              if(res.status === 200) 
                  return res.json() 
          }).then( resJson => {
              document.getElementById('loading-area').style.display = 'none'; 
              if(resJson.status === "200") {
                window.location.reload();
              } else {
                console.log('Something happened wrong delete');
                //console.log(response);
              }
          }).catch(err => err);
      }
      /*
      var urlString = "";
      if(fId === "licence-pic") {
        urlString = global.config.urlLink+"/licencepicdelete/"+valId+"/"+lId+"/"+pId;
      } else {
        urlString = global.config.urlLink+"/licencedrivedelete/"+valId+"/"+lId+"/"+pId;
        
      }
      fetch(urlString, {
          method: 'DELETE',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
      }).then(response => {
          if(response.status === 200) {
              window.location.reload();
              //window.location.href='/listevent';
          } else {
              console.log('Something happened wrong delete');
              console.log(response);
          }
      }).catch(err => err);
      */
    }
    loadLicencePage(clientId,licenceId) {
      document.getElementById('loading-area').style.display = 'block'; 
      fetch(global.config.urlLink+"/licenceview/"+clientId+"/"+licenceId, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
            var cName,domainName,cCountry,cDatez,sDatez,eDatez,eDateBillCyclez;
            var allw_set_pic = false, allw_set_drive = false, allw_set_seat = false;
            if(resJson.values[0].content[0].maincontent.clientName !== undefined) { cName = resJson.values[0].content[0].maincontent.clientName; }
            if(resJson.values[0].content[0].maincontent.domainName !== undefined) { domainName = resJson.values[0].content[0].maincontent.domainName; }
            if(resJson.values[0].content[0].maincontent.clientCountry !== undefined) { cCountry = resJson.values[0].content[0].maincontent.clientCountry; }
            if(resJson.values[0].content[0].createdate !== undefined) {
                cDatez = DateTimeFormats(resJson.values[0].content[0].createdate._seconds,"date-long");
            }
            if(resJson.values[0].scontent[0].subcontent.startPeriode !== undefined) {
                sDatez = DateTimeFormats(resJson.values[0].scontent[0].subcontent.startPeriode._seconds,"date-long");
            }
            if(resJson.values[0].scontent[0].subcontent.endPeriode !== undefined) {
                eDatez = DateTimeFormats(resJson.values[0].scontent[0].subcontent.endPeriode._seconds,"date-long");
            }
            if(resJson.values[0].scontent[0].subcontent.endPeriodeBillCycle !== undefined) {
              eDateBillCyclez = DateTimeFormats(resJson.values[0].scontent[0].subcontent.endPeriodeBillCycle._seconds,"date-long");
            }
            if(
              (localStorage.getItem('ulevel') === global.config.userlevel[0])  || 
              (JSON.parse(localStorage.getItem('urole'))[1] !== undefined && JSON.parse(localStorage.getItem('urole'))[1] === "ON")  || 
              (JSON.parse(localStorage.getItem('urole'))[7] !== undefined && JSON.parse(localStorage.getItem('urole'))[7] === "ON")
            ) { allw_set_pic = true; }
            if(
              (localStorage.getItem('ulevel') === global.config.userlevel[0])  || 
              (JSON.parse(localStorage.getItem('urole'))[1] !== undefined && JSON.parse(localStorage.getItem('urole'))[1] === "ON")  || 
              (JSON.parse(localStorage.getItem('urole'))[7] !== undefined && JSON.parse(localStorage.getItem('urole'))[7] === "ON")
            ) { allw_set_drive = true; }
            if(
              (localStorage.getItem('ulevel') === global.config.userlevel[0])  || 
              (JSON.parse(localStorage.getItem('urole'))[1] !== undefined && JSON.parse(localStorage.getItem('urole'))[1] === "ON")  
            ) { allw_set_seat = true; }
            
            var licRemarks = "";
            if(resJson.values[0].scontent[0].subcontent.licenceRemarks !== undefined) {
                licRemarks = ReplaceAllContent('<br/>','\n',resJson.values[0].scontent[0].subcontent.licenceRemarks.toString());
            }
            var licCategory = "";
            if(resJson.values[0].scontent[0].subcontent.licenceCategory !== undefined) {
              licCategory = resJson.values[0].scontent[0].subcontent.licenceCategory;
            }
            var longPeriodeC = "", durPeriodeC = "", licPriceCurrency = "", licPriceSeat = "";
            if(resJson.values[0].scontent[0].subcontent.longPeriodeBillCycle !== undefined) {
              longPeriodeC = resJson.values[0].scontent[0].subcontent.longPeriodeBillCycle;
            }
            if(resJson.values[0].scontent[0].subcontent.durationPeriodeBillCycle !== undefined) {
              durPeriodeC = resJson.values[0].scontent[0].subcontent.durationPeriodeBillCycle;
            }
            if(resJson.values[0].scontent[0].subcontent.licencePriceSeat !== undefined) {
              licPriceSeat = resJson.values[0].scontent[0].subcontent.licencePriceSeat;
            }
            if(resJson.values[0].scontent[0].subcontent.licencePriceCurrency !== undefined) {
              licPriceCurrency = resJson.values[0].scontent[0].subcontent.licencePriceCurrency;
            }
            this.setState({
                stTitle: 'Detail Licence',
                create_date: cDatez,
                clientdoc_id: resJson.values[0].docid,
                client_name: cName,
                domain_name: domainName,
                client_country: cCountry,
                licencedoc_id: resJson.values[0].scontent[0].subdocid,
                client_licence_category: licCategory,
                client_licence_type: resJson.values[0].scontent[0].subcontent.licenceType,
                client_licence_seat: resJson.values[0].scontent[0].subcontent.licenceTotalSeat,
                client_licence_duration: resJson.values[0].scontent[0].subcontent.longPeriode + " " +resJson.values[0].scontent[0].subcontent.durationPeriode,
                client_licence_duration_long: resJson.values[0].scontent[0].subcontent.longPeriode,
                client_licence_duration_periode: resJson.values[0].scontent[0].subcontent.durationPeriode,
                client_licence_duration_billcycle: longPeriodeC + " " +durPeriodeC,
                client_licence_duration_long_billcycle: longPeriodeC,
                client_licence_duration_periode_billcycle: durPeriodeC,
                client_licence_price_currency: licPriceCurrency, 
                client_licence_price_seat: licPriceSeat, 
    
                client_licence_speriod: sDatez,
                client_licence_eperiod: eDatez,
                client_licence_eperiod_fb: new Date(resJson.values[0].scontent[0].subcontent.endPeriode._seconds*1000),
                client_licence_eperiod_billcycle: eDateBillCyclez,
                client_licence_status: resJson.values[0].scontent[0].subcontent.licenceStatus,
                client_licence_remarks: licRemarks,
                client_licence_renewal_list: resJson.values[0].ss1content,
                client_licence_notif_list: resJson.values[0].ss2content,
                client_licence_additional_list: resJson.values[0].ss3content,
                client_licence_drive_list: resJson.values[0].ss4content,
                client_licence_pic_list: resJson.values[0].ss5content,
                flag_licence_exp: resJson.values[0].scontent[0].flag,
                styleTb: 'table',
                isOpen: false,
                isOpen2: false,
                isOpen3: false,
                isOpen4: false,
                isOpen5: false,
                isOpen6: false,
                isOpen7: false,
                otherContent3: '', 
                otherContent4: '', 
                otherContent5: '' ,
                otherContent6: '' ,
                otherContent7: '' ,
                allow_set_pic: allw_set_pic,
                allow_set_drive: allw_set_drive,
                allow_set_seat: allw_set_seat
            }) 
        } else {
            console.log('Something happened wrong');
        }
      }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
        console.log(err);
      }); 
    }
    componentDidMount() {
        var geturl = this.props.location; 
        var arrString = geturl.pathname;
        var arrUrl = [];
        arrUrl = arrString.split('/');
        if(arrUrl[1] === 'IViewLicence') {
            if(arrUrl[2] !== '' && arrUrl[3] !== '') {
                this.loadLicencePage(arrUrl[2],arrUrl[3]);
            } // end if arrUrl[2] & arrUrl[3]
        } // end if arrUrl [1]
        
    }
    licNotifList() {
      var arrLicenceNotifList = [];
      if(this.state.client_licence_notif_list.length > 0) {
        //document.getElementById("notif-list").style.display = "block";
        for(var ls=0; ls < this.state.client_licence_notif_list.length; ls++) {
            var retResult = this.state.client_licence_notif_list[ls];
            var sendDatez = "";
            if(retResult.subsubcontent.createDate !== undefined) {
                sendDatez = DateTimeFormats(retResult.subsubcontent.createDate._seconds,"datetime-short");
            }
            arrLicenceNotifList.push(<li className="eventContent">
                <p className="event">{retResult.subsubcontent.contentNotif}</p>
                <p className="eventDate">{sendDatez}</p>
            </li>)
        }
      } else {
        arrLicenceNotifList.push(<div key="1">
              <div className="mgT20 mgB20 textCenter">none</div>
            </div>)
      }
      return arrLicenceNotifList;
    }
    licRenewList() {
      var arrLicenceRenewList = [];
      if(this.state.client_licence_renewal_list.length > 0) {
        for(var ls2=0; ls2 < this.state.client_licence_renewal_list.length; ls2++) {
            var retResult2 = this.state.client_licence_renewal_list[ls2];
            // var editURL = "";
            //var viewURL = "/IViewLicence/"+this.state.clientdoc_id+"/"+retResult2.subdocid;
            // var date_ob = new Date(lastLoginz);
            // var date1 = date_ob.getDate();
            // var month1 = date_ob.getMonth() + 1;
            // var year1 = date_ob.getFullYear();
            var reqDatez = "";
            if(retResult2.subsubcontent.renewRequestDate !== undefined) {
                reqDatez = DateTimeFormats(retResult2.subsubcontent.renewRequestDate._seconds,"date-short");
            }
            var expDatez = "";
            if(retResult2.subsubcontent.renewExpiredOn !== undefined) {
                expDatez = DateTimeFormats(retResult2.subsubcontent.renewExpiredOn._seconds,"date-short");
            }
            var priceCurrency = "USD";
            if(retResult2.subsubcontent.renewLicencePriceCurrency !== undefined) {
              priceCurrency = retResult2.subsubcontent.renewLicencePriceCurrency;
            }
            var descAmount = "";
            if(retResult2.subsubcontent.renewLicenceSeat !== "" && retResult2.subsubcontent.renewLicenceSeat !== undefined) {
              descAmount += "Request : "+retResult2.subsubcontent.renewLicenceSeat+" seats";
            }
            if(retResult2.subsubcontent.renewActiveSeats !== "" && retResult2.subsubcontent.renewActiveSeats !== undefined) {
              descAmount += "<br/>Renew : "+retResult2.subsubcontent.renewActiveSeats+" seats";
            }
            var trcls2 = '';
            arrLicenceRenewList.push(<tr key={ls2+1} className={trcls2}>
                <td>{reqDatez}</td>
                <td>{parse(descAmount)}</td>
                <td>{expDatez}</td>
                <td>{priceCurrency} {NumberFormats(retResult2.subsubcontent.renewLicenceTotalPrice)}</td>
                <td>{retResult2.subsubcontent.renewStatus}</td>
                {(this.state.allow_set_seat === true) ? <td><RemoveRedEyeIcon className="iconGrey cursor-on" onClick={this.urldetailRenew.bind(this, retResult2.subsubdocid, retResult2.subsubcontent.renewLicenceSeat)}></RemoveRedEyeIcon></td> : '' }
              </tr>)
            
          }
      } else {
            arrLicenceRenewList.push(<tr key="2">
                <td colSpan="6" className="textCenter"><div className="mgT20 mgB20">none</div></td>
              </tr>)
      }
      return arrLicenceRenewList;
    }
    licAdditionalList() {
      var arrLicenceAdditionalList = [];
      if(this.state.client_licence_additional_list.length > 0) {
        for(var ls3=0; ls3 < this.state.client_licence_additional_list.length; ls3++) {
            var retResult3 = this.state.client_licence_additional_list[ls3];
            // var editURL = "";
            //var viewURL = "/IViewLicence/"+this.state.clientdoc_id+"/"+retResult3.subdocid;
            // var date_ob = new Date(lastLoginz);
            // var date1 = date_ob.getDate();
            // var month1 = date_ob.getMonth() + 1;
            // var year1 = date_ob.getFullYear();
            var sDatez = "", eDatez = "";
            if(retResult3.subsubcontent.additionalStartPeriode !== undefined) {
                sDatez = DateTimeFormats(retResult3.subsubcontent.additionalStartPeriode._seconds,"date-short");
            }
            if(retResult3.subsubcontent.additionalEndPeriode !== undefined) {
              eDatez = DateTimeFormats(retResult3.subsubcontent.additionalEndPeriode._seconds,"date-short");
          }
            
            var trcls3 = '';
            arrLicenceAdditionalList.push(<tr key={ls3+1} className={trcls3}>
                <td>{sDatez}</td>
                <td>{retResult3.subsubcontent.licenceAddSeat}</td>
                <td>{eDatez}</td>
                <td>{retResult3.subsubcontent.licencePriceCurrency} {NumberFormats(retResult3.subsubcontent.licenceTotalPrice)}</td>
                <td>{retResult3.subsubcontent.additionalStatus}</td>
                {(this.state.allow_set_seat === true)? <td><RemoveRedEyeIcon className="iconGrey cursor-on" onClick={this.urldetailAdditionalLicence.bind(this, retResult3.subsubdocid, JSON.stringify(retResult3.subsubcontent))}></RemoveRedEyeIcon></td> : '' }
              </tr>)
            
          }
      } else {
            arrLicenceAdditionalList.push(<tr key="3">
                <td colSpan="6" className="textCenter"><div className="mgT20 mgB20">none</div></td>
              </tr>)
      }
      return arrLicenceAdditionalList;
    }
    licDriveList() {
      var arrLicenceDriveList = [];
      if(this.state.client_licence_drive_list.length > 0) {
        for(var ls4=0; ls4 < this.state.client_licence_drive_list.length; ls4++) {
            var retResult4 = this.state.client_licence_drive_list[ls4];
            // var sDatez = "";
            // if(retResult4.subsubcontent.startPeriode !== undefined) {
            //   sDatez = DateTimeFormats(retResult4.subsubcontent.startPeriode._seconds,"date-short");
            // }
            // var eDatez = "";
            // if(retResult4.subsubcontent.endPeriode !== undefined) {
            //   eDatez = DateTimeFormats(retResult4.subsubcontent.endPeriode._seconds,"date-short");
            // }
            arrLicenceDriveList.push(<tr key={ls4+1}>
              <td><span style={{marginLeft:"-10px"}}>{retResult4.subsubcontent.licenceDriveType}</span></td>
              <td>{retResult4.subsubcontent.licenceTotalSeat} seat(s)</td>
              {(this.state.allow_set_drive === true)? 
                <td style={{width:"90px"}}>
                  <EditIcon className="iconGrey mgR20 cursor-on" alt="edit" style={{fontSize:"15pt"}} onClick={this.urleditDriveClient.bind(this, this.state.clientdoc_id, this.state.licencedoc_id, retResult4.subsubdocid, retResult4.subsubcontent.licenceDriveType, retResult4.subsubcontent.licenceTotalSeat)}/>
                  <DeleteIcon className="iconGrey cursor-on" alt="deactive" style={{fontSize:"15pt"}} onClick={this.urldeleteElement.bind(this, this.state.clientdoc_id, this.state.licencedoc_id, retResult4.subsubdocid, "licdrive")}/>
                </td>
              : <td style={{width:"90px"}}></td>
              }
              
            </tr>) 
            
            /*
              arrLicenceDriveList.push(<li key={ls4+1} className="eventContent flex-container">
                <div className="w100p">
                  <span className="event">{retResult4.subsubcontent.licenceDriveType} : {retResult4.subsubcontent.licenceTotalSeat} seat(s)</span>
                </div>
                <div style={{width:"70px"}}>
                <EditIcon className="iconGrey cursor-on" alt="edit" style={{fontSize:"15pt"}} onClick={this.urleditDriveClient.bind(this, this.state.clientdoc_id, this.state.licencedoc_id, retResult4.subsubdocid, retResult4.subsubcontent.licenceDriveType, retResult4.subsubcontent.licenceTotalSeat)}/>
                <DeleteIcon className="iconGrey mgL15 cursor-on" alt="deactive" style={{fontSize:"15pt"}} onClick={this.urldeleteElement.bind(this, this.state.clientdoc_id, this.state.licencedoc_id, retResult4.subsubdocid, "licence-drive")}/>
                </div>
              </li>)  
            */      
          }
      } else {
          arrLicenceDriveList.push(<tr key="3">
            <td colSpan="3" className="textCenter"><div className="mgT20 mgB20">none</div></td>
          </tr>)
          /*
            arrLicenceDriveList.push(<li key="4" className="eventContent flex-container">
            <div className="w100p textCenter">
            <p className="event">none</p>
            </div>
          </li>)
          */
      }
      return arrLicenceDriveList;
    }
    licPicList() {
      var arrLicencePICList = [];
      if(this.state.client_licence_pic_list.length > 0) {
        for(var ls5=0; ls5 < this.state.client_licence_pic_list.length; ls5++) {
            var retResult5 = this.state.client_licence_pic_list[ls5];
            var pic_phone_result = retResult5.subsubcontent.picPhone;
            if(pic_phone_result === undefined) { pic_phone_result = ""; }
            arrLicencePICList.push(<li key={ls5+1} className="eventContent">
                <div className="flex-container">
                  <div className="w100p">
                    <div className="event">
                      {retResult5.subsubcontent.picName} 
                      {(retResult5.subsubcontent.picLevel === "main")? 
                        <StarRateIcon className="iconLightGrey mgL10" style={{fontSize:"15pt"}}></StarRateIcon>
                        : <ClosedCaptionIcon className="iconLightGrey mgL10" style={{fontSize:"15pt"}}></ClosedCaptionIcon>
                      }
                    </div>
                    {(pic_phone_result !== "")? <div className="eventDate">{pic_phone_result}</div> : '' }
                    <div className="eventDate">{retResult5.subsubcontent.picEmail}</div>
                  </div>
                  {(this.state.allow_set_pic === true)? 
                    <div className="w50x flex-container">
                      <EditIcon className="iconGrey mgR05 cursor-on" alt="edit" style={{fontSize:"15pt"}} onClick={this.urleditPicClient.bind(this, this.state.clientdoc_id, this.state.licencedoc_id, retResult5.subsubdocid, retResult5.subsubcontent.picName, pic_phone_result, retResult5.subsubcontent.picEmail, retResult5.subsubcontent.picLevel)}/>
                      <DeleteIcon className="iconGrey cursor-on" alt="deactive" style={{fontSize:"15pt"}} onClick={this.urldeleteElement.bind(this, this.state.clientdoc_id, this.state.licencedoc_id, retResult5.subsubdocid, "licpic")}/>
                    </div>
                    : <div className="w50x flex-container"></div>
                  }
                  
                </div>
                <div className="eventDate">
                  {(retResult5.subsubcontent.picLevel === "main")? 
                        'Email notification will be addressed directly to him/her' : 
                        'Email notification will be cc to him/her'
                      }
                </div>
              </li>);
             
          }
      } else {
            arrLicencePICList.push(<li key="5" className="eventContent flex-container">
                <div className="w100p textCenter">none</div>
              </li>);
      }
      return arrLicencePICList;
    }
    render() {
        var clientLicRemarks = ReplaceAllContent('\n','<br/>',this.state.client_licence_remarks); if(clientLicRemarks === "") { clientLicRemarks = ""; }
        var licenceinfo = this.state.client_licence_category+"$$"+this.state.client_licence_speriod+"$$"+this.state.client_licence_eperiod+"$$"+this.state.client_licence_duration_long+"$$"+this.state.client_licence_duration_periode+"$$"+clientLicRemarks+"$$"+this.state.client_licence_duration_long_billcycle+"$$"+this.state.client_licence_duration_periode_billcycle+"$$"+this.state.client_licence_eperiod_billcycle+"$$"+this.state.client_licence_price_currency+"$$"+this.state.client_licence_price_seat;
        var suppContentPop = '{"client_id":"'+this.state.clientdoc_id+'","client_name":"'+this.state.client_name+'","client_country":"'+this.state.client_country+'","domain_name":"'+this.state.domain_name+'","licence_type":"'+this.state.client_licence_type+'","licence_category":"'+this.state.client_licence_category+'","licence_seat":"'+this.state.client_licence_seat+'","licence_info":"'+licenceinfo+'"}'; 
        
        var licenceNotifList = this.licNotifList();
        var licenceRenewList = this.licRenewList();
        var licenceAdditionalList = this.licAdditionalList();
        var licenceDriveList = this.licDriveList();
        var licencePicList = this.licPicList();
          
        return (
            <div className="main-container">
              <div  className="header-main"><IHeader/></div>
                  <div className="flex-container">
                      <div className="sidebar-main"><ISidemenu/></div>
                      <div className="main-content">
                        <div className="stTitle">Clients</div>
                        {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[0] === "ON") || (JSON.parse(localStorage.getItem('urole'))[1] === "ON")  || (JSON.parse(localStorage.getItem('urole'))[7] === "ON"))? 
                        <div>
                          <div className="stIndiTitle">Clients &gt; Licence { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                          <div className="mgT30">
                            {/* ====== */}
                            <div  className="content-container card card-body">
                                <div className="flex-container">
                                    <div className=" w70p">
                                      <h4>{this.state.client_name}</h4>
                                      <h5 className="detailJoined">{this.state.client_country} - {this.state.domain_name}</h5>
                                    </div>
                                    <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                                </div>
                                <div id="details" className="mgT20">
                                    <div className="row">
                                      <div id="client-info" className="col-6">
                                        
                                        <div className="mgT05 flex-container">
                                          <div className="w40p">Product </div>
                                          <div className="mgL10"> {this.state.client_licence_type}</div>
                                        </div>
                                        <div className="mgT05 flex-container">
                                          <div className="w40p">Active Licence </div>
                                          <div className="mgL10"> <strong>{this.state.client_licence_seat} seat(s)</strong></div>
                                        </div>
                                        {(this.state.client_licence_price_seat !== undefined && this.state.client_licence_price_seat !== "")?
                                        <> 
                                        <div className="mgT05 flex-container">
                                          <div className="w40p">Current Price </div>
                                          <div className="mgL10"> {this.state.client_licence_price_currency} {this.state.client_licence_price_seat} /seat/month</div>
                                        </div>
                                        </> 
                                        : <></>
                                        }
                                        {(this.state.client_licence_eperiod_billcycle !== undefined && this.state.client_licence_eperiod_billcycle !== "")? 
                                        <>
                                        <div className="mgT10 mgB10" style={{backgroundColor:"#DDDDDD",height:"1px"}}></div>
                                        <div className="mgT05 flex-container">
                                          <div className="w40p">Billing Cycle </div>
                                          <div className="mgL10"> {this.state.client_licence_duration_billcycle} </div>
                                        </div>
                                        <div className="mgT05 flex-container">
                                          <div className="w40p">Due Date Payment </div>
                                          <div className="mgL10"> {this.state.client_licence_eperiod_billcycle} </div>
                                        </div>
                                        </>
                                        : <></>
                                        }
                                        <div className="mgT10 mgB10" style={{backgroundColor:"#DDDDDD",height:"1px"}}></div>
                                        <div className="mgT05 flex-container">
                                          <div className="w40p">Period of Contract </div>
                                          <div className="mgL10"> {this.state.client_licence_duration} </div>
                                        </div>
                                        <div className="mgT05 flex-container">
                                          <div className="w40p">Date of Renewal </div>
                                          <div className="mgL10"> {this.state.client_licence_eperiod} </div>
                                        </div>
                                        <div className="mgT20 flex-container">
                                          <div className="w40p">Status Licence </div>
                                          <div className="mgL10 st4Title textBold"> <span className={this.state.client_licence_status === global.config.licenceStatus[1]? "fontGreen" : (this.state.client_licence_status === global.config.licenceStatus[0]? "" : "fontRed")}>{this.state.client_licence_status} </span></div>
                                        </div>
                                        <div className="mgT05">
                                          <div>{(this.state.client_licence_remarks !== "")? "Remarks :" : ""}</div>
                                          <div>{this.state.client_licence_remarks}</div>
                                        </div>
                                        {
                                          /*(this.state.client_licence_type === global.config.typeLicenceAddon)? */
                                        <div className="mgT10" id="licence-drive-area">
                                          
                                          <div style={{maxHeight:"500px"}}>
                                            <div className="flex-container" style={{height:"50px"}}>
                                              <div className="w70p st4Title divMiddle">Add On</div>
                                              <div className="w30p divRight mgR10">
                                                <div className="divMiddle">
                                                {(this.state.allow_set_drive === true)? 
                                                <div className="mgR05 linkText cursor-on" onClick={this.urladdDriveClient.bind(this, this.state.clientdoc_id, this.state.licencedoc_id)}><AddIcon className="mgB05" style={{fontSize:"14pt", marginTop: "-3px"}}></AddIcon> <span style={{marginTop:"3px"}}>Add &raquo;</span></div>
                                                : ''
                                                }
                                                </div>
                                              </div>
                                            </div>
                                            <div><table className="table">
                                            <tbody>{licenceDriveList}</tbody></table></div>
                                          </div>
                                        </div>
                                        /*: '' */
                                        }
                                        { 
                                          (this.state.flag_licence_exp === 'warning' && this.state.client_licence_status === global.config.licenceStatus[1] && this.state.allow_set_seat === true)? 
                                          <div className="mgT30">
                                            <div className="warningText">
                                              Warning! This licence is close to the expiration date.<br/>
                                              Please regularly check your mail in case they responded.
                                            </div>
                                            <div className="mgT20 flex-container">
                                              <button className="redButton" onClick={this.urlstopSubscribe.bind(this, this.state.licencedoc_id)}>stop subscription</button>
                                              <button className="greenButton mgL20" onClick={this.urlreqRenew.bind(this, this.state.licencedoc_id)}>renew request</button>
                                            </div> 
                                          </div>
                                          : <div className="mgT30">
                                              <div className="mgT20 flex-container">
                                                {(localStorage.getItem('ulevel') === global.config.userlevel[0])? <button className="redButton mgR20" onClick={this.urlstopSubscribe.bind(this, this.state.licencedoc_id)}>stop subscription</button> : '' }
                                                <button className="greenButton" onClick={this.urlreqRenew.bind(this, this.state.licencedoc_id)}>renew request</button>
                                              </div> 
                                            </div>
                                        }
                                        
          
                                      </div>
                                      <div className="col-6">
                                        <div>
                                          <div className="flex-container">
                                            <div className="w70p"><h6>Email Recipient</h6></div>
                                            <div className="w30p divRight">
                                              {(this.state.allow_set_pic === true)? 
                                              <div className="mgR05 linkText cursor-on" onClick={this.urladdPicClient.bind(this, this.state.clientdoc_id, this.state.licencedoc_id)}><PersonAddIcon className="mgB05" style={{fontSize:"14pt", marginTop: "-3px"}}></PersonAddIcon> <span style={{marginTop:"3px"}}>Add &raquo;</span></div>
                                              : ''
                                              }
                                            </div>
                                          </div>
                                          <div className="picContainer w100p" style={{height:"220px"}}>
                                          {licencePicList}
                                          </div>
                                        </div>
                                        <div className="mgT30" id="notif-list">
                                          <h6>Reminder Notification Log</h6>
                                          <ul className="eventContainer w100p" style={{maxHeight:"150px"}}>
                                          {licenceNotifList}
                                          </ul>
                                        </div>
                                      </div>
                                      
                                    </div>
                                    
                                    <div className="mgT50" id="licence-area">
                                      <div className="flex-container">
                                        <div className="w70p st4Title divMiddle">History Renewal Licence</div>
                                        <div className="w30p divRight">
                                          {/*<button className="genButton mgL20" onClick={this.urlreqRenewLicence.bind(this, this.state.clientdoc_id)}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>add request renew licence</button>*/}
                                        </div>
                                      </div>
                                      <div className="mgT20">
                                        <table className="table table-hover">
                                          <thead className="thead-light">
                                              <tr key="0">
                                              <th scope="col textWrap">Request Date</th>
                                              <th scope="col textWrap">Number of Seat(s)</th>
                                              <th scope="col textWrap">Expiry On</th>
                                              <th scope="col textWrap">Price</th>
                                              <th scope="col textWrap">Status</th>
                                              {(this.state.allow_set_seat === true)? <th scope="col textWrap">Action</th> : '' }
                                              </tr>
                                          </thead>
                                          <tbody>
                                              {licenceRenewList}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>

                                    <div className="mgT50" id="licence-area">
                                      <div className="flex-container">
                                        <div className="w70p st4Title divMiddle">History Additional Licence</div>
                                        <div className="w30p divRight">
                                          { (this.state.allow_set_seat === true)? 
                                          <button className="genButton mgL20" onClick={this.urladdAdditionalLicence.bind(this, this.state.clientdoc_id)}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>add additional licence</button>
                                          : '' }
                                        </div>
                                      </div>
                                      <div className="mgT20">
                                        <table className="table table-hover">
                                          <thead className="thead-light">
                                              <tr key="0">
                                              <th scope="col textWrap">Activation Date</th>
                                              <th scope="col textWrap">Number of Seat(s)</th>
                                              <th scope="col textWrap">Expiry On</th>
                                              <th scope="col textWrap">Price</th>
                                              <th scope="col textWrap">Status</th>
                                              {(this.state.allow_set_seat === true)? <th scope="col textWrap">Action</th> : '' }
                                              </tr>
                                          </thead>
                                          <tbody>
                                              {licenceAdditionalList}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    

                                </div>
                            </div>
                            <div>
                            {this.state.isOpen && <Popup
                              content={<><ReqRenewForm/></>}
                              id={this.state.licencedoc_id} 
                              title={"Request Licence"}
                              others={suppContentPop}
                              styles={"50%"}
                              handleClose={this.togglePopClose.bind(this, this.state.licencedoc_id)}
                            />}
                            {this.state.isOpen2 && <Popup
                              content={<><CommentForm/></>}
                              id={this.state.licencedoc_id} 
                              title={"Stop Subscription"}
                              others={suppContentPop}
                              styles={"50%"}
                              handleClose={this.togglePopClose.bind(this, this.state.licencedoc_id)}
                            />}
                            {this.state.isOpen3 && <Popup
                              content={<><RenewDetail/></>}
                              id={this.state.licencedoc_id} 
                              title={"Renew Licence"}
                              others={this.state.otherContent3}
                              styles={"70%"}
                              handleClose={this.togglePopClose.bind(this, this.state.licencedoc_id)}
                            />}
                            {this.state.isOpen4 && <Popup
                              content={<><DriveForm/></>}
                              id={this.state.licencedoc_id} 
                              title={"Add On"}
                              others={this.state.otherContent4}
                              styles={"70%"}
                              handleClose={this.togglePopClose.bind(this, this.state.licencedoc_id)}
                            />}
                            {this.state.isOpen5 && <Popup
                              content={<><PICForm/></>}
                              id={this.state.licencedoc_id} 
                              title={"Email Recipient"}
                              others={this.state.otherContent5}
                              styles={"70%"}
                              handleClose={this.togglePopClose.bind(this, this.state.licencedoc_id)}
                            />}
                            {this.state.isOpen6 && <Popup
                              content={<><AdditionalForm/></>}
                              id={this.state.licencedoc_id} 
                              title={"Additional Licence"}
                              others={this.state.otherContent6}
                              styles={"70%"}
                              handleClose={this.togglePopClose.bind(this, this.state.licencedoc_id)}
                            />}
                            {this.state.isOpen7 && <Popup
                              content={<><AdditionalDetail/></>}
                              id={this.state.licencedoc_id} 
                              title={"Additional Licence"}
                              others={this.state.otherContent7}
                              styles={"70%"}
                              handleClose={this.togglePopClose.bind(this, this.state.licencedoc_id)}
                            />}
                            </div>
                            {/* ====== */}
                          </div>
                        </div>
                      : <div><INA/></div>
                      }
                      </div>
                  </div>
                  <div id="loading-area" className="divHide loading"></div>
            </div>
        );
    }
}
export default IClientLicence;