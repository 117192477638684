import React, { Component } from 'react';
import '../../inc/genstyle.css';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import DescriptionIcon from '@material-ui/icons/Description';
import SearchIcon from '@material-ui/icons/Search';
import DateTimeFormats from '../../inc/date-time-format';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[7];
 
class ILicencesExpired extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        expired_period: '0',
        keyword: '',
        styleTb: 'table',
        data: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  loadData(state,value) {
    var selectExpPeriod = this.state.expired_period;
    if(state !== undefined) {
      if(state === "expired_period") {
        selectExpPeriod = value;   
      }
    }
    var datatext ='{"keyword":"'+this.state.keyword+'","expired_period":"'+selectExpPeriod+'","pageShow":"'+this.state.pageShow+'"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/explicencelist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
            console.log(response);
        }
    }).then(resJson => {
        var styleTb = 'table';
        if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; } 
        this.setState({
            styleTb: styleTb,
            expired_period: selectExpPeriod,
            data: resJson
        })
    }).catch(err => {
        console.log(err);
    });
  }
  
  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value });
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    const inputname = e.target.name;
    if(inputname === "expired_period") {
      this.setState( prevState => {
        return {  
            ...prevState.expired_period, expired_period: value
        }
      })
      this.loadData("expired_period",value);
    } else if(inputname === "pageShow") {
      this.setState( prevState => {
        return {  
            ...prevState.pageShow, pageShow: value
        }
      })
    }
    
  }
  handleSubmit(e) {
    e.preventDefault();
    this.loadData();
  }
  componentDidMount() {
    this.loadData();
  }

  render() {
   var expLicenceList = [];
   if(this.state.data.values.length > 0 && this.state.data.status === '200') {
    for(var ls=0; ls < this.state.data.values.length; ls++) {
        var retResult = this.state.data.values[ls];
        var viewURL = "/IViewLicence/"+retResult.docid+"/"+retResult.subdocid;
        var expDatez = "";
        if(retResult.subcontent.endPeriode !== undefined) {
             expDatez = DateTimeFormats(retResult.subcontent.endPeriode._seconds,"date-short");
        }
        var statusNotif = '';
        if(retResult.subcontent.sendNotif === -1) {
            statusNotif = retResult.subcontent.licenceStatus;
        } else {
            statusNotif = global.config.licenceNotif[Number(retResult.subcontent.sendNotif)];
        }
        expLicenceList.push(<tr>
            <th scope="row-field">{ls+1}</th>
            <td>{retResult.content.clientName}<br/><span className="font-sublist">{retResult.content.domainName}</span><br/><span className="font-sublist">{retResult.content.clientCountry}</span></td>
            <td>{retResult.subcontent.licenceType}</td>
            <td>{expDatez}</td>
            <td style={{maxWidth:"250px"}}>{statusNotif}</td>
            <td>
                <a href={viewURL}><DescriptionIcon className="iconGrey mgR20" /></a>
            </td>
          </tr>)
    }
   } else {
    expLicenceList.push(<tr>
          <td colSpan="6" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
        </tr>)
    }
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Licence Expired</div>
                <div className="stIndiTitle">List of licence to be expired</div>
                <div className="mgT30">
                    {/*--*/}
                    <div className="content-container card card-body">
                        <div className="row show">
                            <div className="col-6 divMiddle">
                                <form method="post" id="search-form" className="form-inline" onSubmit={this.handleSubmit}>
                                    <div className="flex-container" >
                                        <select name="expired_period" className="form-control" 
                                            value={this.state.expired_period} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputExpiredPeriod = input}
                                        >
                                          <option value="0" key="0">Expired in 3 months</option>
                                          <option value="1" key="1">Expired in 2 months</option>
                                          <option value="2" key="2">Expired in 1 month</option>
                                          <option value="3" key="3">Expired in 3 weeks</option>
                                          <option value="4" key="4">Expired in 1 week</option>
                                          
                                        </select>
                                        <div style={{display:'none'}}>
                                        <input type="text" className="inputSearch mr-sm-2" name="keyword" id="keyword"
                                            placeholder="Search entries.."
                                            value={this.state.keyword}
                                            onChange={this.handleChange.bind(this)}/> 
                                        <SearchIcon style={{ fontSize: 35 }} className="iconGrey mgT05 cursor-on" onClick={this.handleSubmit}></SearchIcon>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-6 form-inline justify-content-end">
                            </div>
                        </div>
                        <div className="row-field mgT20">
                            <table className={this.state.styleTb}>
                            <thead>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Client Name</th>
                                <th scope="col">Licence Type</th>
                                <th scope="col">Expired Date</th>
                                <th scope="col">Status Sent Notif</th>
                                <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {expLicenceList}
                            </tbody>
                            </table>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className="flex-container" style={{display:'none'}}>
                                    <div className="mgR20 divMiddle">Showing</div>
                                    <div className="mgR20 divMiddle show">
                                        <select className="btn btn-secondary" name="pageShow" 
                                            value={this.state.pageShow} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputPageShow = input} >
                                            <option value="1">1</option>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                    <div className="mgR20 divMiddle">list</div>
                                </div>
                            </div>
                            <div className="col-6 form-inline justify-content-end">
                                
                            </div>
                        </div>
                    </div>
                    {/*--*/}
                </div>
              </div>
          </div>
    </div>
   );
 }
}
 
export default ILicencesExpired;