import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iGlobal';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import defMemberPicture from '../../assets/pic-profile.png';
import DateTimeFormats from '../../inc/date-time-format';
import Popup from '../../inc/popup';
import '../../inc/popup.css'
import SubscribeDetail from './ipopusersubscribe-detail';
import PurchaseDetail from './ipopuserpurchase-detail';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[13];

class IUserTrainingsDetail extends Component {
  constructor (props) {
    super(props);
    this.state = {
      stTitle: "",
      memberdoc_id: "",
      member_email: "",
      member_fullname: "",
      member_picture: "",
      member_jobs: "",
      member_regdate: "",
      member_status_subscribe: "",
      member_plan: "",
      training_plan: "",
      subscribe_list: [],
      membercourse_list: [],
      isOpen: false,
      otherContent: "",
      isOpen2: false,
      otherContent2: "",
    }
  }
  handleBack = () => {
    this.props.history.goBack();
  }

  togglePopClose() {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen, isOpen: false,
      }
    })
  }

  togglePopClose2() {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen2, isOpen2: false,
      }
    })
  }

  urldetailSubscribe(valId) {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen, isOpen: true,
        ...prevState.otherContent, otherContent: '{"subscribe_id":"'+valId+'","member_id":"'+this.state.memberdoc_id+'","member_fullname":"'+this.state.member_fullname+'","member_email":"'+this.state.member_email+'","member_status_subscribe":"'+this.state.member_status_subscribe+'","member_plan":"'+this.state.member_plan+'"}'
      }
    })
  }

  urldetailPurchase(valId) {
    this.setState( prevState => {
      return {  
        ...prevState.isOpen2, isOpen2: true,
        ...prevState.otherContent2, otherContent2: '{"purchase_id":"'+valId+'","member_id":"'+this.state.memberdoc_id+'","member_fullname":"'+this.state.member_fullname+'","member_email":"'+this.state.member_email+'","member_status_subscribe":"'+this.state.member_status_subscribe+'","member_plan":"'+this.state.member_plan+'"}'
      }
    })
  }

  componentDidMount() {
    document.getElementById('loading-area').style.display = 'block'; 
    var geturl = this.props.location; 
    var arrString = geturl.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');

    fetch(global.config.urlLink+"/traininguserview/" + arrUrl[2], {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': authKey,
      }
    }).then(response => {
      if(response.status === 200) {
        return response.json();
      } else {
        console.log('Something happened wrong');
      }
    }).then(resJson => {
      document.getElementById('loading-area').style.display = 'none'; 
      if(resJson.status === '200') { 
        var memberPicture = '', regDatez = '';
        if(resJson.values[0].content[0].maincontent.memberPicture !== undefined && resJson.values[0].content[0].maincontent.memberPicture !== '') {
          memberPicture = resJson.values[0].content[0].maincontent.memberPicture;
        } else { 
          memberPicture = defMemberPicture; 
        }
        if(resJson.values[0].content[0].maincontent.registeredDate !== undefined) {
          regDatez = DateTimeFormats(resJson.values[0].content[0].maincontent.registeredDate._seconds,"date-long");
        }
        this.setState({
          stTitle: 'Detail Member',
          memberdoc_id: resJson.values[0].docid,
          member_email: resJson.values[0].content[0].maincontent.memberEmail,
          member_fullname: resJson.values[0].content[0].maincontent.memberName,
          member_picture: memberPicture,
          member_jobs: resJson.values[0].content[0].maincontent.memberJobs,
          member_regdate: regDatez,
          member_status_subscribe: resJson.values[0].content[0].maincontent.statusSubscribeMember,
          member_plan: resJson.values[0].content[0].maincontent.memberPlan,
          training_plan: resJson.values[0].content[0].maincontent.trainingPlan,
          subscribe_list: resJson.values[0].scontent,
          membercourse_list: resJson.values[0].scontent2,
          isOpen: false,
          otherContent: ''
        })
      } else {
        console.log('Something happened wrong');
      }
    }).catch(err => { 
      document.getElementById('loading-area').style.display = 'none'; 
    })
  }
  render() {
    var subscribeList = [];
    if(this.state.subscribe_list !== undefined && this.state.subscribe_list.length > 0) {
      for(var ls=0; ls < this.state.subscribe_list.length; ls++) {
        var retResult = this.state.subscribe_list[ls];
        var reqDatez = "";
        if(retResult.subcontent.requestSubscribeDate !== undefined) {
          reqDatez = DateTimeFormats(retResult.subcontent.requestSubscribeDate._seconds,"date-short");
        }
        subscribeList.push(
          <div className="subs-block" onClick={this.urldetailSubscribe.bind(this, retResult.subdocid)}>
            {
              retResult.subcontent.subscribeStatus === 'New Request' ? (
                retResult.subcontent.paymentConfirmationDate ? (
                  <label className="subs-block-status bg-warning">Verifying</label> 
                ) : (
                  <label className="subs-block-status bg-info">New Request</label> 
                )
              ) : (
                <label className="subs-block-status bg-success">Completed</label>
              )
            }
            <h4 className="subs-block-title">{retResult.subcontent.subscribePlan} Subscription</h4>
            <p className="subs-block-price">IDR {retResult.subcontent.subscribeGTotal.toLocaleString('en-US',{maximumFractionDigits:2})}</p>
            <p className="subs-block-date">{reqDatez}</p>
            <RemoveRedEyeIcon className="subs-block-view iconGrey cursor-on" onClick={this.urldetailSubscribe.bind(this, retResult.subdocid)}></RemoveRedEyeIcon>
          </div>
        )
      }
    } else {
      subscribeList.push(<p className="mgT20 mgB20">:: no record ::</p>)
    }

    var membercourseList = [];
    if(this.state.membercourse_list !== undefined && this.state.membercourse_list.length > 0) {
      for(var ls2=0; ls2 < this.state.membercourse_list.length; ls2++) {
        var retResult2 = this.state.membercourse_list[ls2];
        var reqDatez2 = "";
        if(retResult2.subcontent.purchaseDate !== undefined) {
          reqDatez2 = DateTimeFormats(retResult2.subcontent.purchaseDate._seconds,"date-short");
        }
        var trcls = '';
        membercourseList.push(
          <tr key={ls2+1} className={trcls}>
            <th scope="row">{ls2+1}</th>
            <td>{reqDatez2}</td>
            <td>{retResult2.subcontent.courseTitle}</td>
            <td>IDR {retResult2.subcontent.purchasePrice.toLocaleString('en-US',{maximumFractionDigits:2})}</td>
            <td>
              {
                retResult2.subcontent.purchaseStatus === 'New Purchase' ? (
                  retResult2.subcontent.paymentConfirmationDate ? 'Verifying' : 'New Purchase'
                ) : 'Completed'
              }
            </td>
            <td>
              <RemoveRedEyeIcon className="iconGrey cursor-on" onClick={this.urldetailPurchase.bind(this, retResult2.subdocid)}></RemoveRedEyeIcon>
            </td>
          </tr>
        )
      }
    } else {
      membercourseList.push(
        <tr key="0">
          <td colSpan="6" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
        </tr>
      )
    }
  
    return (
    <div className="main-container">
      <div className="header-main"><IHeader/></div>
        <div className="flex-container">
          <div className="sidebar-main"><ISidemenu/></div>
          <div className="main-content">
            <div className="stTitle">Users</div>
            <div className="stIndiTitle">Training Booking User Detail Information</div>
            <div className="mgT30">
              <div className="content-container card card-body">
                <div className="flex-container">
                  <div className="w70p">
                    <h4>{this.state.member_fullname}</h4>
                    <h5 className="detailJoined">Joined On : {this.state.member_regdate}</h5>
                  </div>
                  <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                </div>
                <div id="profile-detail" className="mgT20">
                  <div className="row">
                    <div id="profile-info" className="col-6">
                      {
                        this.state.member_picture !== '' ? (
                          <div className="mgB30"><img src={this.state.member_picture} alt={this.state.member_fullname} className="memberPhoto"/></div>
                        ) : ''
                      }
                      <div className="row">
                        <div className="col-12"><span className="textBold textItalic">{this.state.member_email}</span></div>
                      </div>
                      <div className="row">
                        <div className="col-12">{this.state.member_jobs}</div>
                      </div>
                      <div className="row mgT05">
                        <div className="col-12">Status Membership : {this.state.member_plan}</div>
                      </div>
                      <div className="row">
                        <div className="col-12">Join Workshops : { this.state.membercourse_list.length > 0 ? <span>YES</span> : <span>NO</span> }</div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="flex-container">
                          <div className="w100p"><h6><strong>History User Subscription</strong></h6></div>
                      </div>
                      <div className="picContainer w100">
                        {subscribeList}
                      </div>                      
                    </div>
                  </div>
                    
                  <div className="mgT30" id="subscribe-area">
                    <div className="flex-container">
                      <div className="w70p st3Title divMiddle">Applied Workshops</div>
                      <div className="w30p divRight"></div>
                    </div>
                    <div className="mgT20">
                      <table className="table table-hover">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Request Date</th>
                            <th scope="col">Workshop Name</th>
                            <th scope="col">Price</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {membercourseList}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {
                  this.state.isOpen && <Popup 
                    content={<SubscribeDetail/>}
                    id={this.state.memberdoc_id} 
                    title={"History User Subscription"}
                    others={this.state.otherContent}
                    styles={"70%"}
                    handleClose={this.togglePopClose.bind(this, this.state.memberdoc_id)}
                  />
                }
                {
                  this.state.isOpen2 && <Popup
                    content={<><PurchaseDetail/></>}
                    id={this.state.memberdoc_id} 
                    title={"History User Purchase"}
                    others={this.state.otherContent2}
                    styles={"70%"}
                    handleClose={this.togglePopClose2.bind(this, this.state.memberdoc_id)}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div>
      </div>
    );
  }
}

export default IUserTrainingsDetail;