import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import DateTimeFormats from '../../inc/date-time-format';
import ReplaceAllContent from '../../inc/replace-all';
import { FormErrors } from '../../inc/error-form';
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[21];

class IClientMessageDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            requestdoc_id: '',
            clientdoc_id: '',
            
            client_name: '',
            domain_name: '',
            client_country: '',
            customer_name: '',
            customer_email: '',
            customer_phone: '',
            customer_message: '',
            request_status: '',
            create_by: '',
            create_date: '',
            process_by: '',
            process_date: '',
            process_comment: '',
            completed_by: '',
            completed_date: '',
            completed_comment: '',
            cancel_by: '',
            cancel_date: '',
            cancel_comment: '',
            
            update_status: '',
            contactus_comment: '',
            formErrors: {update_status: '', contactus_comment: ''},
            updateStatusValid: false,
            formValid: false,
            errmsg: '',
            contentoth: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadFormRequestData = this.loadFormRequestData.bind(this);
        this.resetErrorMsg = this.resetErrorMsg.bind(this);
    }
    loadFormRequestData() {
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        var requestId = othersContent.request_id;
        fetch(global.config.urlLink+"/clientmessageview/"+requestId, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }
          }).then(res => {
            if(res.status === 200) 
                return res.json() 
          }).then( resJson => {
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') {
                var resultContent = resJson.values[0].content[0].maincontent;
                var cDate = '', custMessage = '', processBy='', processDate='', processComment='', completedBy='', completedDate='', completedComment='', cancelBy='', cancelDate='', cancelComment='';
                if(resultContent.createDate !== undefined) { cDate = DateTimeFormats(resultContent.createDate._seconds,"datetime-long"); }
                if(resultContent.processByName !== undefined) { processBy = resultContent.processByName; }
                if(resultContent.processDate !== undefined) { processDate = DateTimeFormats(resultContent.processDate._seconds,"datetime-long"); }
                if(resultContent.processRemarks !== undefined) { processComment = resultContent.processRemarks; }
                if(resultContent.completedByName !== undefined) { completedBy = resultContent.completedByName; }
                if(resultContent.completedDate !== undefined) { completedDate = DateTimeFormats(resultContent.completedDate._seconds,"datetime-long"); }
                if(resultContent.completedRemarks !== undefined) { completedComment = resultContent.completedRemarks; }
                if(resultContent.cancelByName !== undefined) { cancelBy = resultContent.cancelByName; }
                if(resultContent.cancelDate !== undefined) { cancelDate = DateTimeFormats(resultContent.cancelDate._seconds,"datetime-long"); }
                if(resultContent.cancelRemarks !== undefined) { cancelComment = resultContent.cancelRemarks; }
                if(resultContent.customerMessage !== undefined) { custMessage = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resultContent.customerMessage)); }
                
                this.setState({
                    stPopTitle: '',
                    requestdoc_id: resJson.values[0].docid,
                    clientdoc_id: resultContent.clientId,
                    
                    client_name: resultContent.clientName,
                    domain_name: resultContent.domainName,
                    client_country: resultContent.clientCountry,
                    customer_name: resultContent.customerName,
                    customer_email: resultContent.customerEmail,
                    customer_phone: resultContent.customerPhone,
                    customer_message: custMessage,
                    request_status: resultContent.requestStatus,
                    create_by: resultContent.createByName,
                    create_date: cDate,
                    process_by: processBy,
                    process_date: processDate,
                    process_comment: processComment,
                    completed_by: completedBy,
                    completed_date: completedDate,
                    completed_comment: completedComment,
                    cancel_by: cancelBy,
                    cancel_date: cancelDate,
                    cancel_comment: cancelComment,
                    update_status: '',
                    request_comment: '',
                    formErrors: {update_status: '', request_comment: ''},
                    updateStatusValid: false,
                    formValid: false,
                    errmsg: '',
                    contentoth: document.getElementById("contentoth").innerText
                }); 
            } else {
                console.log('Something happened wrong');
            }
          }).catch(err => { 
            document.getElementById('loading-area').style.display = 'none'; 
          }); 
        
    }
    resetErrorMsg() {
        this.setState({
            formErrors: {update_status: ''},
            updateStatusValid: false,
            formValid: false,
            errmsg: '' 
        }, this.validateForm);
        
    }
    urlcancelForm(valId,divId,exdivId) {
        document.getElementById(divId).style.display = "none";
        document.getElementById(exdivId).style.display = "block";        
    }
    urleditStatus(valId) {
        document.getElementById("request-edit-form").style.display = "block";
        document.getElementById("request-detail-info").style.display = "none";
        this.loadFormRequestData();
    }
    handleUserSelect = (e) => {
        const value = e.target.value;
        const inputname = e.target.name;
        this.setState({[inputname]: value},
            () => { this.validateField(inputname, value)});
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    } 
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let updateStatusValid = this.state.updateStatusValid;
        
        switch(fieldName) {
            case 'update_status': 
                updateStatusValid = value.trim().length > 0;
                fieldValidationErrors.update_status = updateStatusValid ? '' : 'status need to be filled';
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        updateStatusValid: updateStatusValid,
                    }, this.validateForm);                            
    }
    validateForm() {
        this.setState({formValid: this.state.updateStatusValid });
    }
    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit(e) {
        e.preventDefault();
        if(this.state.update_status !== '') {
            document.getElementById('loading-area').style.display = 'block'; 

            var updatestatusval = "", requestcommentval ="";
            if(this.textInputUpdateStatus.value === '') { updatestatusval = this.state.update_status; } else { updatestatusval = this.textInputUpdateStatus.value; }
            if(this.textInputRequestComment.value === '') { requestcommentval = this.state.request_comment; } else { requestcommentval = this.textInputRequestComment.value; }
            
            var urlFetch, datatext;
            datatext ='{"request_id":"'+this.state.requestdoc_id+'","client_id":"'+this.state.clientdoc_id+'","update_status":"'+updatestatusval+'","request_comment":"'+requestcommentval+'","form_id":"form-updatestatus-training"}';
            urlFetch = global.config.urlLink+"/updateclientmessage";
            
            var data = JSON.parse(datatext); 
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none'; 
                document.getElementById('request-edit-form').style.display = 'none';
                document.getElementById('request-detail-info').style.display = 'block';
                if(resJson.status === '200') {
                    window.location.href='/IClientMessages';
                } else {
                    console.log('Something happened wrong');
                }
            }).catch(err => {
                document.getElementById('loading-area').style.display = 'none'; 
            }) ;
            
        } else {
            var arrfieldName = ['update_status'];
            var arrvalue = [this.textInputUpdateStatus.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
          }      
    }
    componentDidMount() {
        document.getElementById('loading-area').style.display = 'block'; 
        document.getElementById("errormsg").style.display = 'none';
        this.loadFormRequestData();
    }
    render() {
        return (
            <div className="main-container">
                <div className="mgT20">
                <div className="divhide w100p" id="errormsg"><Alert severity="error">{this.state.errmsg}</Alert></div>
                  <div className="mgL20 st3Title">{this.state.client_name}</div>
                  <div className="mgL20 flex-container">
                    <div className="w70p"><h5 className="detailJoined">{this.state.client_country} - {this.state.domain_name}</h5></div>
                    {(this.state.request_status === global.config.requestStatus[0] || this.state.request_status === global.config.requestStatus[1])?
                    <div className="w30p divRight">
                        <div className="mgR30 linkText cursor-on" onClick={this.urleditStatus.bind(this, this.state.renewdoc_id)}><EditIcon className="mgR05" style={{fontSize:"14pt"}} ></EditIcon><span style={{marginTop:"3px"}}>Edit Status &raquo;</span></div>
                    </div>
                    : ''}
                  </div>
                  <div className="mgT20 mgL20">
                    {/* ---- */}
                    <div>
    
                    { /* --------- */ }
                        <div className="mgT20 divHide" id="request-edit-form">
                        <form id="form-renew-edit" onSubmit={this.handleSubmit}>
                            <div className="smallForm">
                                
                                <div className="flex-container mgT10">
                                    <div className="w40p"><label className="mgT05" htmlFor="update_status">Update Status</label></div>
                                    <div className="w60p">
                                        <select name="update_status" className="form-control" 
                                            value={this.state.update_status} 
                                            onChange={this.handleUserSelect}
                                            ref={(input) => this.textInputUpdateStatus = input}
                                        >
                                            <option value="">-- please select --</option>
                                            <option value={global.config.requestStatus[1]}>{global.config.requestStatus[1]}</option>
                                            <option value={global.config.requestStatus[2]}>{global.config.requestStatus[2]}</option>
                                            <option value={global.config.requestStatus[3]}>{global.config.requestStatus[3]}</option>
                                        </select>
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.update_status}/>  }</div>
                                    </div>
                                </div>
                                <div className="mgT10 textBold">Remarks</div>
                                <div className="mgT05">
                                    <textarea name="request_comment" className="form-control" 
                                        rows={3} cols={30} 
                                        value={this.state.request_comment} 
                                        onChange={this.handleUserInput} 
                                        ref={(input) => this.textInputRequestComment = input}
                                    />
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.request_comment}/>  }</div>
                                </div>
                                <div className="mgT30 divCenter">
                                    <div className="flex-container">
                                    <button type="submit" className="submitButton mgR20">Submit</button>
                                    <div className="submitButton cursor-on" onClick={this.urlcancelForm.bind(this, this.state.memberdoc_id, "request-edit-form", "request-detail-info")}>Cancel</div>
                                    </div>
                                </div>
    
                            </div>
                        </form>
                        </div>
                        { /* --------- */ }

                        <div className="mgT05" id="request-detail-info">
                            <div className="flex-container">
                                <div className="w280x">Posted on </div>
                                <div className="w280x-min mgL10"> {this.state.create_date} <span className="mgL30">by.</span><span className="mgL10"> {this.state.create_by}</span></div>
                            </div>
                            <div className="mgT05 flex-container">
                                <div className="w280x">Status </div>
                                <div className="w280x-min mgL10 st4Title"> <span className={this.state.request_status === global.config.requestStatus[2]? "fontGreen textBold" : "textBold"}>{this.state.request_status}</span> </div>
                            </div>

                            <div className="mgT30 flex-container">
                                <div className="w280x">Name </div>
                                <div className="w280x-min mgL10"> {this.state.customer_name}</div>
                            </div>
                            <div className="mgT05 flex-container">
                                <div className="w280x">Email</div>
                                <div className="w280x-min mgL10"> {this.state.customer_email}</div>
                            </div>
                            <div className="mgT05 flex-container">
                                <div className="w280x">Phone Number</div>
                                <div className="w280x-min mgL10"> {this.state.customer_phone}</div>
                            </div>
                            <div className="mgT20 flex-container">
                                <div className="w280x">Message</div>
                                <div className="w280x-min mgL10"> {this.state.customer_message}</div>
                            </div>
                            
                            
                            {(this.state.process_by !== '' || this.state.completed_by !== '' || this.state.cancel_by !== '')? 
                            <div>
                                <div className="mgT30">
                                    <div className="textBold">Detail Information</div>
                                </div>
                                <div className="row">
                                {(this.state.process_by !== '')? 
                                    <div className="col-sm mgL10 mgR10 picContainer">
                                        <div className="textBold">In Process</div>
                                        <div className="mgT10 smText">By : {this.state.process_by}</div>
                                        <div className="mgT10 smText">On : {this.state.process_date} </div>
                                        {(this.state.process_comment !== '')? 
                                        <div className="mgT10 smText">Remarks : <br/>{this.state.process_comment}</div>
                                        : ''}
                                    </div>
                                : ''}
                                {(this.state.completed_by !== '')? 
                                    <div className="col-sm mgL10 mgR10 picContainer">
                                        <div className="textBold">Completed</div>
                                        <div className="mgT10 smText">By : {this.state.completed_by}</div>
                                        <div className="mgT10 smText">On : {this.state.completed_date} </div>
                                        {(this.state.completed_comment !== '')? 
                                        <div className="mgT10 smText">Remarks : <br/>{this.state.completed_comment}</div>
                                        : ''}
                                    </div>
                                : ''}
                                {(this.state.cancel_by !== '')? 
                                    <div className="col-sm mgL10 mgR10 picContainer">
                                        <div className="textBold">Cancel</div>
                                        <div className="mgT10 smText">By : {this.state.cancel_by}</div>
                                        <div className="mgT10 smText">On : {this.state.cancel_date} </div>
                                        {(this.state.cancel_comment !== '')? 
                                        <div className="mgT10 smText">Remarks : <br/>{this.state.cancel_comment}</div>
                                        : ''}
                                    </div>
                                : ''}
                                </div>
                            </div>
                            : ''}
                        </div> 
                        
                        
                        
                    </div>
    
                    {/* ---- */}
                    
                  </div>
                  <div className="row-field mgB30">
                  </div>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
          );
    }
}

export default IClientMessageDetail;