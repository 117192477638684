import React, { Component } from 'react';
import axios from 'axios';
import '../../inc/genstyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import ReplaceAllContent from '../../inc/replace-all';
import DatePicker from 'react-date-picker';
import DeleteIcon from '@material-ui/icons/Delete';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[10];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[11];
const authKey3 = global.config.authBearerKey+"##"+global.config.kindcode[35];

class ICourseForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        course_docid: '',
        course_title: '',
        course_meet_link: '',
        course_short_desc: '',
        course_long_desc: '',
        course_language: '',
        course_picture: '',
        course_video: '',
        course_category: '',
        course_subcategory: '',
        course_tutor: [],
        course_currency: 'USD',
        course_price: '',
        course_date: new Date(),
        course_start_time_hour: 8,
        course_start_time_minute: 0,
        course_end_time_hour: 17,
        course_end_time_minute: 0,
        categoryList: [],
        subcategoryList: [],
        tutorList: [],
        selectedFileDocument: null,
        selectedFileDocument2: null,
        selectedFileDocument3: null,
        formErrors: {course_title: '', course_meet_link: '', course_short_desc: '', course_long_desc: '', course_language: '', course_category: '', course_subcategory: '', course_tutor: '', course_currency: '', course_price: '', course_date: '', course_start_time: '', course_end_time: '', selectedFileDocument:'', selectedFileDocument2:'', selectedFileDocument3:''},
        coursetitleValid: false,
        coursemeetlinkValid: false,
        courseshortdescValid: false,
        courselongdescValid: false,
        courselanguageValid: false,
        coursecategoryValid: false,
        coursesubcategoryValid: false,
        coursetutorValid: false,
        coursecurrencyValid: false,
        coursepriceValid: false,
        coursedateValid: false,
        coursestarttimehourValid: false,
        coursestarttimeminuteValid: false,
        courseendtimehourValid: false,
        courseendtimeminuteValid: false,
        selectedFileDocumentValid: false,
        selectedFileDocument2Valid: false,
        selectedFileDocument3Valid: false,
        formValid: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadSubcategory = this.loadSubcategory.bind(this);
    this.submitData = this.submitData.bind(this);
  }
  handleBack = () => {
    this.props.history.goBack();
  }
  handleEventDate = date => {
    this.setState( prevState => {
        return {  
            ...prevState.course_date, course_date: date
        }
        }, () => { this.validateField('course_date', date)
    }) 
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    const inputname = e.target.name;
    var arrValues = value.split("$$");
    if(inputname === 'course_category') {
        this.loadSubcategory(arrValues[0]);
        this.setState( prevState => {
            return {  
                ...prevState.course_category, course_category: value
            }
          }, () => { this.validateField(inputname, value)
        }) 
    } else if(inputname === 'course_subcategory') {
        this.setState( prevState => {
            return {  
                ...prevState.course_subcategory, course_subcategory: value
            }
          }, () => { this.validateField(inputname, value)
        }) 
    } else if(inputname === 'course_tutor') {
        this.setState( prevState => {
            return {  
                ...prevState.course_tutor, course_tutor: value
            }
          }, () => { this.validateField(inputname, value)
        })       
    } else if(inputname === 'course_currency') {
      this.setState( prevState => {
          return {  
              ...prevState.course_currency, course_currency: value
          }
        }, () => { this.validateField(inputname, value)
      }) 
    } else if(inputname === 'course_start_time_hour') {
      this.setState( prevState => {
          return {  
              ...prevState.course_start_time_hour, course_start_time_hour: value
          }
        }, () => { this.validateField(inputname, value)
      }) 
    } else if(inputname === 'course_start_time_minute') {
        this.setState( prevState => {
            return {  
                ...prevState.course_start_time_minute, course_start_time_minute: value
            }
          }, () => { this.validateField(inputname, value)
        }) 
    } else if(inputname === 'course_end_time_hour') {
        this.setState( prevState => {
            return {  
                ...prevState.course_end_time_hour, course_end_time_hour: value
            }
          }, () => { this.validateField(inputname, value)
        }) 
    } else if(inputname === 'course_end_time_minute') {
        this.setState( prevState => {
            return {  
                ...prevState.course_end_time_minute, course_end_time_minute: value
            }
          }, () => { this.validateField(inputname, value)
        }) 
    } else if(inputname === 'course_language') {
        this.setState( prevState => {
            return {  
                ...prevState.course_language, course_language: value
            }
          }, () => { this.validateField(inputname, value)
        })     
    } 
  }
  handleUserMultiSelect = (e) => {
    const inputname = e.target.name;
    var options = e.target.options;
    var values = [];
    
    for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
            values.push(options[i].value);
        }
    }
    this.setState({
        course_tutor: values
    }, () => { 
        this.validateField(inputname, values)
    })
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let coursetitleValid = this.state.coursetitleValid;
    let coursemeetlinkValid = this.state.coursemeetlinkValid;
    let courseshortdescValid = this.state.courseshortdescValid;
    let courselongdescValid = this.state.courselongdescValid;
    let courselanguageValid = this.state.courselanguageValid;
    let coursecategoryValid = this.state.coursecategoryValid;
    let coursesubcategoryValid = this.state.coursesubcategoryValid;
    let coursetutorValid = this.state.coursetutorValid;
    let coursepriceValid = this.state.coursepriceValid;
    let coursedateValid = this.state.coursedateValid;
    let coursestarttimehourValid = this.state.coursestarttimehourValid;
    let coursestarttimeminuteValid = this.state.coursestarttimeminuteValid;
    let courseendtimehourValid = this.state.courseendtimehourValid;
    let courseendtimeminuteValid = this.state.courseendtimeminuteValid;
    let selectedFileDocumentValid = this.state.selectedFileDocumentValid;
    let selectedFileDocument2Valid = this.state.selectedFileDocument2Valid;
    let selectedFileDocument3Valid = this.state.selectedFileDocument3Valid;
    switch(fieldName) {
        case 'course_title': 
            coursetitleValid = value.trim().length > 0;
            fieldValidationErrors.course_title = coursetitleValid ? '' : 'title need to be filled';
            break;
        case 'meet_link': 
            coursemeetlinkValid = value.trim().length > 0;
            fieldValidationErrors.course_meet_link = coursemeetlinkValid ? '' : 'meet link need to be filled';
            break;
        case 'course_short_desc': 
            courseshortdescValid = value.trim().length > 0;
            fieldValidationErrors.course_short_desc = courseshortdescValid ? '' : 'short description need to be filled';
            break;
        case 'course_long_desc': 
            courselongdescValid = value.trim().length > 0;
            fieldValidationErrors.course_long_desc = courselongdescValid ? '' : 'full description need to be filled';
            break;
        case 'course_language': 
            courselanguageValid = value.trim().length > 0;
            fieldValidationErrors.course_language = courselanguageValid ? '' : 'language need to be filled';
            break;
        case 'course_category': 
            coursecategoryValid = value.trim().length > 0;
            fieldValidationErrors.course_category = coursecategoryValid ? '' : 'category need to be filled';
            break;
        case 'course_subcategory': 
            coursesubcategoryValid = value.trim().length > 0;
            fieldValidationErrors.course_subcategory = coursesubcategoryValid ? '' : 'sub category need to be filled';
            break;
        case 'course_tutor': 
            coursetutorValid = value.length > 0;
            fieldValidationErrors.course_tutor = coursetutorValid ? '' : 'tutor need to be filled';
            break;
        case 'course_price': 
            coursepriceValid = value.trim().length > 0;
            fieldValidationErrors.course_price = coursepriceValid ? '' : 'price need to be filled';
            break;
        case 'course_date': 
            coursedateValid = value !== null;
            fieldValidationErrors.course_date = coursedateValid ? '' : 'event date need to be filled';
            break;
        case 'course_start_time_hour': 
        case 'course_start_time_minute': 
            coursestarttimehourValid = value.trim().length > 0;
            coursestarttimeminuteValid = value.trim().length > 0;
            fieldValidationErrors.course_start_time = (coursestarttimehourValid || coursestarttimeminuteValid) ? '' : 'start time event need to be filled';
            break;
        case 'course_end_time_hour': 
        case 'course_end_time_minute': 
            courseendtimehourValid = value.trim().length > 0;
            courseendtimeminuteValid = value.trim().length > 0;
            fieldValidationErrors.course_end_time = (courseendtimehourValid || courseendtimeminuteValid) ? '' : 'end time event need to be filled';
            break;
        default:
            break;
    }
    
    this.setState({formErrors: fieldValidationErrors,
            coursetitleValid: coursetitleValid,
            coursemeetlinkValid: coursemeetlinkValid,
            courseshortdescValid: courseshortdescValid,
            courselongdescValid: courselongdescValid,
            courselanguageValid: courselanguageValid,
            coursecategoryValid: coursecategoryValid,
            coursesubcategoryValid: coursesubcategoryValid,
            coursetutorValid: coursetutorValid,
            coursepriceValid: coursepriceValid,
            coursedateValid: coursedateValid,
            coursestarttimehourValid: coursestarttimehourValid,
            coursestarttimeminuteValid: coursestarttimeminuteValid,
            courseendtimehourValid: courseendtimehourValid,
            courseendtimeminuteValid: courseendtimeminuteValid,
            selectedFileDocumentValid: selectedFileDocumentValid,
            selectedFileDocument2Valid: selectedFileDocument2Valid,
            selectedFileDocument3Valid: selectedFileDocument3Valid
        }, this.validateForm);
  }
  validateForm() {
    this.setState({formValid: 
        this.state.coursetitleValid && this.state.coursemeetlinkValid && this.state.courseshortdescValid && this.state.courselongdescValid && 
        this.state.courselanguageValid && this.state.coursecategoryValid && this.state.coursesubcategoryValid && this.state.coursetutorValid && 
        this.state.coursepriceValid && this.state.coursedateValid && 
        this.state.coursestarttimehourValid && this.state.coursestarttimeminuteValid &&
        this.state.courseendtimehourValid && this.state.courseendtimeminuteValid &&
        this.state.selectedFileDocumentValid && this.state.selectedFileDocument2Valid && this.state.selectedFileDocument3Valid
    });
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  onChangeFileHandler = (e) => {
    if(e.target.files[0] !== undefined) {
        var inputType = e.target.files[0].type;
        var inputValue = e.target.files[0];
        var name = ''; 
        var validname = '';
        let fieldValidationErrors = this.state.formErrors;
        let selectedFileValid = false;
        if(inputType.indexOf('pdf') > -1 || inputType.indexOf('video') > -1|| inputType.indexOf('image') > -1) { //add image
            selectedFileValid = true;
        } else {
            selectedFileValid = false;
        }
        if(e.target.id === "fileDocument3") {
            name =  'selectedFileDocument3';
            validname = 'selectedFileDocument3Valid';
            fieldValidationErrors.selectedFileDocument3 = selectedFileValid ? '' : 'please upload video only';
        } else if(e.target.id === "fileDocument2") {
            name =  'selectedFileDocument2';
            validname = 'selectedFileDocument2Valid';
            fieldValidationErrors.selectedFileDocument2 = selectedFileValid ? '' : 'please upload video only';
        } else {
            name =  'selectedFileDocument';
            validname = 'selectedFileDocumentValid';
            fieldValidationErrors.selectedFileDocument = selectedFileValid ? '' : 'please upload image only';
        }
        
        this.setState({
            [name]: inputValue,
            formErrors: fieldValidationErrors,
            [validname] : selectedFileValid
        }, this.validateForm);
    }
  }
  onDeleteFileHandler = (e) => {
    var name = '';
    if(e.target.id === "deletePicture") {
        name = 'course_picture';
    } else if(e.target.id === "deleteVideo") {
        name = 'course_video';
    } else if(e.target.id === "deleteRecording") {
        name = 'course_recording';
    }    
    this.setState({
        [name]: ''
    });
  }
  submitData(datasend,imgurl,vidurl,recurl) {
    var urlFetch, datatext;
    var courseTitle,courseMeetLink,courseShortDesc,courseLongDesc,courseLanguage,coursePicture,courseVideo,courseRecording,courseCategory,courseSubCategory,courseCategoryId,courseCategoryName,courseSubCategoryId,courseSubCategoryName,courseTutorId,courseTutorName;
    var courseCurrency,coursePrice,courseDate,courseStartTimeHour,courseStartTimeMinute,courseEndTimeHour,courseEndTimeMinute;
    var arrCategory = [], arrSubCategory = [], arrTutor = [], courseTutorIds = [], courseTutorNames = [];
    if(imgurl !== '' && imgurl !== undefined) { coursePicture = imgurl; } else { coursePicture = this.state.course_picture; }
    if(vidurl !== '' && vidurl !== undefined) { courseVideo = vidurl; } else { courseVideo = this.state.course_video; }
    if(recurl !== '' && recurl !== undefined) { courseRecording = recurl; } else { courseRecording = this.state.course_recording; }
    if(this.state.course_docid !== null && this.state.course_docid !== ''  && this.state.course_docid !== undefined) { 
        if(this.textInputCourseTitle.value === '') { courseTitle = this.state.course_title; } else { courseTitle = this.textInputCourseTitle.value; }
        if(this.textInputCourseMeetLink.value === '') { courseMeetLink = this.state.course_meet_link; } else { courseMeetLink = this.textInputCourseMeetLink.value; }
        if(this.textInputCourseShortDesc.value === '') { courseShortDesc = this.state.course_short_desc; } else { courseShortDesc = this.textInputCourseShortDesc.value; }
        if(this.textInputCourseLongDesc.value === '') { courseLongDesc = this.state.course_long_desc; } else { courseLongDesc = this.textInputCourseLongDesc.value; }
        if(this.textInputCourseLanguage.value === '') { courseLanguage = this.state.course_long_desc; } else { courseLanguage = this.textInputCourseLanguage.value; }
        if(this.textInputCourseCategory.value === '') { courseCategory = this.state.course_category; } else { courseCategory = this.textInputCourseCategory.value; }
        if(this.textInputCourseSubcategory.value === '') { courseSubCategory = this.state.course_subcategory; } else { courseSubCategory = this.textInputCourseSubcategory.value; }
        if(this.textInputCourseCurrency.value === '') { courseCurrency = this.state.course_currency; } else { courseCurrency = this.textInputCourseCurrency.value; }
        if(this.textInputCoursePrice.value === '') { coursePrice = this.state.course_price; } else { coursePrice = this.textInputCoursePrice.value; }
        if(this.textInputCourseStartTimeHour.value === '') { courseStartTimeHour = this.state.course_start_time_hour; } else { courseStartTimeHour = this.textInputCourseStartTimeHour.value; }
        if(this.textInputCourseStartTimeMinute.value === '') { courseStartTimeMinute = this.state.course_start_time_minute; } else { courseStartTimeMinute = this.textInputCourseStartTimeMinute.value; }
        if(this.textInputCourseEndTimeHour.value === '') { courseEndTimeHour = this.state.course_end_time_hour; } else { courseEndTimeHour = this.textInputCourseEndTimeHour.value; }
        if(this.textInputCourseEndTimeMinute.value === '') { courseEndTimeMinute = this.state.course_end_time_minute; } else { courseEndTimeMinute = this.textInputCourseEndTimeMinute.value; }
        
        courseDate = this.state.course_date;
        courseShortDesc = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',courseShortDesc));
        courseLongDesc = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',this.state.course_long_desc));
        arrCategory = courseCategory.split("$$");
        if(arrCategory.length > 0) {
            courseCategoryId=arrCategory[0];
            courseCategoryName=arrCategory[1];
        }
        arrSubCategory = courseSubCategory.split("$$"); 
        if(arrSubCategory.length > 0) {
            courseSubCategoryId=arrSubCategory[0];
            courseSubCategoryName=arrSubCategory[1];
        }
        arrTutor = this.state.course_tutor;
        if(arrTutor.length > 0) {
            arrTutor.forEach((tutors) => {
                var tutor = tutors.split("$$");
                courseTutorIds.push(tutor[0]);
                courseTutorNames.push(tutor[1]);
            });
        }
        courseTutorId = courseTutorIds.join(',');
        courseTutorName = courseTutorNames.join(',');
        datatext ='{"course_title":"'+courseTitle+'","course_meet_link":"'+courseMeetLink+'","course_short_desc":"'+courseShortDesc+'","course_long_desc":"'+courseLongDesc+'","course_language":"'+courseLanguage+'","course_currency":"'+courseCurrency+'","course_price":"'+coursePrice+'","course_date":"'+courseDate+'","course_start_time_h":"'+courseStartTimeHour+'","course_start_time_m":"'+courseStartTimeMinute+'","course_end_time_h":"'+courseEndTimeHour+'","course_end_time_m":"'+courseEndTimeMinute+'","course_picture":"'+coursePicture+'","course_video":"'+courseVideo+'","course_recording":"'+courseRecording+'","course_category_id":"'+courseCategoryId+'","course_category_name":"'+courseCategoryName+'","course_subcategory_id":"'+courseSubCategoryId+'","course_subcategory_name":"'+courseSubCategoryName+'","course_tutor_id":"'+courseTutorId+'","course_tutor_name":"'+courseTutorName+'","course_docid":"'+this.state.course_docid+'","form_id":"form-edit"}';
        urlFetch = global.config.urlLink+"/courseedit";
    } else {
        courseShortDesc = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',this.state.course_short_desc));
        courseLongDesc = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',this.state.course_long_desc));
        arrCategory = this.state.course_category.split("$$");
        if(arrCategory.length > 0) {
            courseCategoryId=arrCategory[0];
            courseCategoryName=arrCategory[1];
        }
        arrSubCategory = this.state.course_subcategory.split("$$"); 
        if(arrSubCategory.length > 0) {
            courseSubCategoryId=arrSubCategory[0];
            courseSubCategoryName=arrSubCategory[1];
        }
        arrTutor = this.state.course_tutor;
        if(arrTutor.length > 0) {
            arrTutor.forEach((tutors) => {
                var tutor = tutors.split("$$");
                courseTutorIds.push(tutor[0]);
                courseTutorNames.push(tutor[1]);
            });
        }
        courseTutorId = courseTutorIds.join(',');
        courseTutorName = courseTutorNames.join(',');
        datatext ='{"course_title":"'+this.state.course_title+'","course_meet_link":"'+this.state.course_meet_link+'","course_short_desc":"'+courseShortDesc+'","course_long_desc":"'+courseLongDesc+'","course_language":"'+this.state.course_language+'","course_currency":"'+this.state.course_currency+'","course_price":"'+this.state.course_price+'","course_date":"'+this.state.course_date+'","course_start_time_h":"'+this.state.course_start_time_hour+'","course_start_time_m":"'+this.state.course_start_time_minute+'","course_end_time_h":"'+this.state.course_end_time_hour+'","course_end_time_m":"'+this.state.course_end_time_minute+'","course_picture":"'+coursePicture+'","course_video":"'+courseVideo+'","course_recording":"'+courseRecording+'","course_category_id":"'+courseCategoryId+'","course_category_name":"'+courseCategoryName+'","course_subcategory_id":"'+courseSubCategoryId+'","course_subcategory_name":"'+courseSubCategoryName+'","course_tutor_id":"'+courseTutorId+'","course_tutor_name":"'+courseTutorName+'","form_id":"form-add"}';
        urlFetch = global.config.urlLink+"/courseadd";
    }
    var data = JSON.parse(datatext);
    
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') {
            var docId = resJson.values;
            window.location.href='/IEditDetailCourse/'+docId;
        } else {
            console.log('Something happened wrong');
        }

        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    
    if(this.state.course_title !== '' && this.state.course_short_desc !== '' && this.state.course_long_desc !== '' && this.state.course_language !== '' && this.state.course_category !== '' && this.state.course_subcategory !== '' && this.state.course_tutor !== ''
        && this.state.course_date !== '' && this.state.course_price !== '' && this.state.course_start_time_hour !== '' && this.state.course_start_time_minute !== '' && this.state.course_end_time_hour !== '' && this.state.course_end_time_minute !== ''
        ) {
        document.getElementById('loading-area').style.display = 'block'; 
        var selectedFileValue = this.state.selectedFileDocument;
        var selectedFileValue2 = this.state.selectedFileDocument2;
        var selectedFileValue3 = this.state.selectedFileDocument3;
        if((selectedFileValue !== null && ((selectedFileValue.type.indexOf('pdf') > -1 || selectedFileValue.type.indexOf('video') > -1|| selectedFileValue.type.indexOf('image') > -1))) 
        || (selectedFileValue2 !== null && ((selectedFileValue2.type.indexOf('pdf') > -1 || selectedFileValue2.type.indexOf('video') > -1|| selectedFileValue2.type.indexOf('image') > -1))) 
        || (selectedFileValue3 !== null && ((selectedFileValue3.type.indexOf('pdf') > -1 || selectedFileValue3.type.indexOf('video') > -1|| selectedFileValue3.type.indexOf('image') > -1))) 
        ) { 
            const data = new FormData() 
            data.append('file', selectedFileValue);
            data.append('file2', selectedFileValue2);
            data.append('file3', selectedFileValue3);
            data.append('docid', this.state.course_docid);
            data.append('source', 'course');
            axios.post(global.config.urlLink+"/uploadfile", 
            data, { 
                headers: { 
                    'content-type': 'multipart/form-data',
                    'Authorization': authKey
                }
            }).then(res => { 
                document.getElementById('loading-area').style.display = 'none'; 
                if(res.data.status === "200") {
                    this.submitData(this,res.data.values.linkfile1,res.data.values.linkfile2,res.data.values.linkfile3);
                } else {
                    document.getElementById('loading-area').style.display = 'none'; 
                }
            }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
            })
        } else {
            document.getElementById('loading-area').style.display = 'none'; 
            this.submitData(this,"","");
        }
    
    } else {
        var arrfieldName = ['course_title','course_meet_link','course_short_desc','course_long_desc','course_language','course_category','course_subcategory','course_tutor','course_price','course_date','course_start_time_hour','course_start_time_minute','course_end_time_hour','course_end_time_minute','selectedFileDocument'];
        var arrvalue = [this.textInputCourseTitle.value,this.textInputCourseMeetLink.value,this.textInputCourseShortDesc.value,this.textInputCourseLongDesc.value,this.textInputCourseLanguage.value,this.textInputCourseCategory.value,this.textInputCourseSubcategory.value,this.textInputCourseTutor.value,this.textInputCoursePrice.value,this.state.course_date,this.textInputCourseStartTimeHour.value,this.textInputCourseStartTimeMinute.value,this.textInputCourseEndTimeHour.value,this.textInputCourseEndTimeMinute.value,this.state.selectedFileDocument];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
    
  }
  loadSubcategory(catid) {
    fetch(global.config.urlLink+"/categoryview/course/"+catid, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey2,
        }
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') { 
            this.setState( prevState => {
                return {  
                    ...prevState.subcategoryList, subcategoryList: resJson.values[0].scontent, 
                }
            }) 
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => err);
  }
  componentDidMount() {
    document.getElementById('loading-area').style.display = 'block'; 
    var geturl = this.props.location; 
    var arrString = geturl.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');
    
    var datatext ='{"keyword":"","pageShow":"","usageFor":"course"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/categorylist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey2,
        }, body : JSON.stringify(data)
    }).then(response2 => {
        if(response2.status === 200) {
            return response2.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson2 => {        
        fetch(global.config.urlLink+"/tutorlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey3,
            }, body : JSON.stringify(data)
        }).then(response2 => {
            if(response2.status === 200) {
                return response2.json()
            } else {
                console.log('Something happened wrong');
            }
        }).then(resJson3 => {
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson3.status === '200') {
                if(arrUrl[1] === 'IEditCourse') {
                    if(arrUrl[2] !== '') { 
                        fetch(global.config.urlLink+"/courseview/"+arrUrl[2], {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Access-Control-Allow-Origin': '*',
                                'Authorization': authKey,
                            }
                        }).then(res => {
                            if(res.status === 200) 
                                return res.json() 
                        }).then( resJson => {
                            if(resJson.status === '200') {
                                var resultContent = resJson.values[0].content[0].maincontent;
                                var lTitle="",lMeetLink="",lShortDesc="",lLongDesc="",lLanguage="",lPicture="",lVideo="",lRecording="",lCategory="",lSubCategory="",lPriceCurrency="",lPrice="",lTutor=[];
                                var crsDate = new Date();
                                var crsTimeSHour = 8;
                                var crsTimeSMinute = 0;
                                var crsTimeEHour = 17;
                                var crsTimeEMinute = 0;
                                
                                if(resultContent.courseTitle !== undefined) { lTitle = resultContent.courseTitle; }
                                if(resultContent.courseMeetLink !== undefined) { lMeetLink = resultContent.courseMeetLink; }
                                if(resultContent.courseShortDesc !== undefined) {
                                lShortDesc = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resultContent.courseShortDesc));
                                }
                                if(resultContent.courseLongDesc !== undefined) { 
                                    lLongDesc = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resultContent.courseLongDesc));
                                }
                                if(resultContent.courseLanguage !== undefined) { 
                                    lLanguage = resultContent.courseLanguage;
                                }
                                if(resultContent.categoryId !== undefined) { 
                                    lCategory = resultContent.categoryId+"$$"+resultContent.categoryName;
                                    this.loadSubcategory(resultContent.categoryId);
                                }
                                if(resultContent.subcategoryId !== undefined) { 
                                    lSubCategory = resultContent.subcategoryId+"$$"+resultContent.subcategoryName;
                                }
                                if(resultContent.coursePicture !== undefined) { 
                                    lPicture = resultContent.coursePicture;
                                }
                                if(resultContent.courseVideo !== undefined && resultContent.courseVideo !== "") { 
                                    lVideo = resultContent.courseVideo;
                                }
                                if(resultContent.courseRecording !== undefined && resultContent.courseRecording !== "") { 
                                    lRecording = resultContent.courseRecording;
                                }
                                if(resultContent.courseCurrency !== undefined) { 
                                    lPriceCurrency = resultContent.courseCurrency;
                                }
                                if(resultContent.coursePrice !== undefined) { 
                                    lPrice = resultContent.coursePrice;
                                }
                                if(resultContent.courseDate !== undefined) { 
                                    crsDate = new Date(resultContent.courseDate._seconds*1000); 
                                }
                                if(resultContent.courseStartTime !== undefined) { 
                                    var crsStartTime = new Date(resultContent.courseStartTime._seconds*1000);
                                    crsTimeSHour = crsStartTime.getHours();
                                    crsTimeSMinute = crsStartTime.getMinutes();
                                }  
                                if(resultContent.courseEndTime !== undefined) { 
                                    var crsEndTime = new Date(resultContent.courseEndTime._seconds*1000);
                                    crsTimeEHour = crsEndTime.getHours();
                                    crsTimeEMinute = crsEndTime.getMinutes();
                                }
                                if(resultContent.tutorId !== undefined) {
                                    if (resultContent.tutorId.length > 0) {
                                        var tutor_ids = resultContent.tutorId.split(',');
                                        var tutor_names = resultContent.tutorName.split(',');
                                        if (tutor_ids.length > 0) {
                                            tutor_ids.forEach((tutor, i) => {
                                                lTutor.push(tutor_ids[i]+"$$"+tutor_names[i]);
                                            });
                                        }
                                    }
                                }
                                this.setState({
                                    stTitle: 'Edit Workshop',
                                    course_docid: resJson.values[0].docid,
                                    course_title: lTitle,
                                    course_meet_link: lMeetLink,
                                    course_short_desc: lShortDesc,
                                    course_long_desc: lLongDesc,
                                    course_language: lLanguage,
                                    course_picture: lPicture,
                                    course_video: lVideo,
                                    course_recording: lRecording,
                                    course_category: lCategory,
                                    course_subcategory: lSubCategory,
                                    course_tutor: lTutor,
                                    course_currency: lPriceCurrency,
                                    course_price: lPrice,
                                    course_date: crsDate,
                                    course_start_time_hour: crsTimeSHour,
                                    course_start_time_minute: crsTimeSMinute,
                                    course_end_time_hour: crsTimeEHour,
                                    course_end_time_minute: crsTimeEMinute,
                                    categoryList: resJson2,
                                    tutorList: resJson3,
                                    selectedFileDocument: null,
                                    selectedFileDocument2: null,
                                    selectedFileDocument3: null,
                                    formErrors: {course_title: '', course_meet_link: '', course_short_desc: '', course_long_desc: '', course_language: '', course_category: '', course_subcategory: '', course_tutor: '', course_currency: '', course_price: '', course_date: '', course_start_time: '', course_end_time: '', selectedFileDocument:'', selectedFileDocument2:'', selectedFileDocument3:''},
                                    coursetitleValid: false,
                                    coursemeetlinkValid: false,
                                    courseshortdescValid: false,
                                    courselongdescValid: false,
                                    courselanguageValid: false,
                                    coursecategoryValid: false,
                                    coursesubcategoryValid: false,
                                    coursetutorValid: false,
                                    coursecurrencyValid: false,
                                    coursepriceValid: false,
                                    coursedateValid: false,
                                    coursestarttimehourValid: false,
                                    coursestarttimeminuteValid: false,
                                    courseendtimehourValid: false,
                                    courseendtimeminuteValid: false,
                                    selectedFileDocumentValid: false,
                                    selectedFileDocument2Valid: false,
                                    selectedFileDocument3Valid: false,
                                    formValid: false,
                                }) 
                            } else {
                                console.log('Something wrong happened');
                            }
                        }).catch(err => err);
                    }
                } else if(arrUrl[1] === 'IAddCourse') {
                    this.setState( prevState => {
                        return {  
                            ...prevState.categoryList, categoryList: resJson2,
                            ...prevState.tutorList, tutorList: resJson3,
                            ...prevState.stTitle, stTitle: 'New Workshop'
                        }
                    })
                } 
            } else {
                console.log('Something wrong happened');
            }
        });
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });

    
  }
  render() {
   var selcategoryList = [];
   if(this.state.categoryList.values.length > 0) {
     for(var u in this.state.categoryList.values) {
      var selvalue = this.state.categoryList.values[u].docid+'$$'+this.state.categoryList.values[u].content.categoryName;
      selcategoryList.push(<option value={selvalue} key={u}>{this.state.categoryList.values[u].content.categoryName}</option>)
     }
   }

   var selsubcategoryList = [];
   if(this.state.subcategoryList.length > 0) {
      for(var v in this.state.subcategoryList) {
      var selvalue2 = this.state.subcategoryList[v].subdocid+'$$'+this.state.subcategoryList[v].subcontent.subcategoryName;
       selsubcategoryList.push(<option value={selvalue2} key={v}>{this.state.subcategoryList[v].subcontent.subcategoryName}</option>)
      }
   }
   var seltutorList = [];
   if(this.state.tutorList.values.length > 0) {
     for(var x in this.state.tutorList.values) {
      var selvalue3 = this.state.tutorList.values[x].docid+'$$'+this.state.tutorList.values[x].content.tutorName;
      seltutorList.push(<option value={selvalue3} key={x}>{this.state.tutorList.values[x].content.tutorName}</option>)
     }
   }
 
   var hourList = [];
   for(var h=0; h<24; h++) {
       hourList.push(<option value={h} key={h}>{h.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}</option>)
   }
   var minuteList = [];
   for(var m=0; m<60; m+=15) {
      minuteList.push(<option value={m} key={m}>{m.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}</option>)
   }
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Classes</div>
                {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[2] === "ON") || (JSON.parse(localStorage.getItem('urole'))[3] === "ON") || (JSON.parse(localStorage.getItem('urole'))[4] === "ON"))? 
                <div>
                <div className="stIndiTitle">Classes { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="flex-container">
                            <div className="st2Title w70p">{ this.state.stTitle.includes("New")? 'Add ' : '' } {this.state.stTitle}</div>
                            <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        <div id="FormCenter" className="mgT20">
                            <div id="FormCourseInfo">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="flex-container">
                                        <input type="hidden" required name="course_docid" value={this.state.course_docid} ref={(input) => this.textInputCourseId = input} />
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="course_title">Title</label>
                                        <div className="w70p">
                                            <input type="text" className="form-control" name="course_title"
                                                placeholder={this.state.course_title}
                                                value={this.state.course_title}
                                                onChange={this.handleUserInput}  
                                                ref={(input) => this.textInputCourseTitle = input}/>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.course_title}/>  }</div>
                                        </div>
                                    </div>
                                    
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="course_short_desc">Short Description</label>
                                        <div className="w70p">
                                            <textarea name="course_short_desc" className="form-control" 
                                                    rows={5} cols={30} 
                                                    value={this.state.course_short_desc} 
                                                    onChange={this.handleUserInput} 
                                                    ref={(input) => this.textInputCourseShortDesc = input}/>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.course_short_desc}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="course_long_desc">Full Description</label>
                                        <div className="w70p">
                                            <textarea name="course_long_desc" className="form-control" 
                                                    rows={5} cols={30} 
                                                    value={this.state.course_long_desc} 
                                                    onChange={this.handleUserInput} 
                                                    ref={(input) => this.textInputCourseLongDesc = input}/>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.course_long_desc}/>  }</div>
                                        </div>
                                    </div>
                                
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="course_category">Category</label>
                                        <div className="w70p">
                                            <select name="course_category" className="form-control" 
                                                value={this.state.course_category} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputCourseCategory = input}
                                                disabled={this.state.input_disabled}
                                            >
                                            <option value="" key="">-- please select --</option>
                                            {selcategoryList}
                                            </select>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.course_category}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="course_subcategory">Sub Category</label>
                                        <div className="w70p">
                                            <select name="course_subcategory" className="form-control" 
                                                value={this.state.course_subcategory} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputCourseSubcategory = input}
                                                disabled={this.state.input_disabled}
                                            >
                                            <option value="" key="">-- please select --</option>
                                            {selsubcategoryList}
                                            </select>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.course_subcategory}/>  }</div>
                                        </div>
                                    </div>                                
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="course_tutor">Tutor</label>
                                        <div className="w70p">
                                            <select name="course_tutor" className="form-control" 
                                                value={this.state.course_tutor} 
                                                onChange={this.handleUserMultiSelect}
                                                ref={(input) => this.textInputCourseTutor = input}
                                                disabled={this.state.input_disabled}
                                                multiple={true}
                                            >
                                            {seltutorList}
                                            </select>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.course_tutor}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="course_language">Language</label>
                                        <div className="w70p">
                                            <select name="course_language" className="form-control" 
                                                value={this.state.course_language} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputCourseLanguage = input}
                                                disabled={this.state.input_disabled}
                                            >
                                                <option value="">-- please select --</option>
                                                <option value="English">English</option>
                                                <option value="Indonesian">Indonesian</option>
                                            </select>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.course_language}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="course_price">Price</label>
                                        <div className="w70p">
                                            <div className="flex-container">
                                                <select name="course_currency" className="form-control" style={{width:"150px"}}
                                                    value={this.state.course_currency} 
                                                    onChange={this.handleUserSelect}
                                                    ref={(input) => this.textInputCourseCurrency = input}
                                                    disabled={this.state.input_disabled}
                                                >
                                                <option value="USD">USD</option>
                                                <option value="SGD">SGD</option>
                                                <option value="IDR">IDR</option>
                                                <option value="MYR">MYR</option>
                                                </select>
                                                <input type="number" className="form-control mgL20" name="course_price"
                                                    placeholder={this.state.course_price}
                                                    value={this.state.course_price}
                                                    onChange={this.handleUserInput}  
                                                    ref={(input) => this.textInputCoursePrice = input}
                                                    min="0"/> 
                                            </div>
                                            
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.course_price}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="course_date">Event Date</label>
                                        <div className="w70p">
                                                <DatePicker
                                                    onChange={this.handleEventDate}
                                                    value={this.state.course_date}
                                                    customStyles={{dateInput:{borderWidth: 0}}}
                                                    />
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.course_date}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="course_start_time">Start Time</label>
                                        <div className="w70p">
                                            <div className="flex-container">
                                                <select name="course_start_time_hour" className="form-control" style={{width:"70px"}}
                                                    value={this.state.course_start_time_hour} 
                                                    onChange={this.handleUserSelect}
                                                    ref={(input) => this.textInputCourseStartTimeHour = input}
                                                    disabled={this.state.input_disabled}
                                                >{hourList}
                                                </select>
                                                <select name="course_start_time_minute" className="form-control mgL10" style={{width:"70px"}}
                                                    value={this.state.course_start_time_minute} 
                                                    onChange={this.handleUserSelect}
                                                    ref={(input) => this.textInputCourseStartTimeMinute = input}
                                                    disabled={this.state.input_disabled}
                                                >{minuteList}
                                                </select>
                                            </div>
                                            
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.course_start_time}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="course_end_time">End Time</label>
                                        <div className="w70p">
                                            <div className="flex-container">
                                                <select name="course_end_time_hour" className="form-control" style={{width:"70px"}}
                                                    value={this.state.course_end_time_hour} 
                                                    onChange={this.handleUserSelect}
                                                    ref={(input) => this.textInputCourseEndTimeHour = input}
                                                    disabled={this.state.input_disabled}
                                                >{hourList}
                                                </select>
                                                <select name="course_end_time_minute" className="form-control mgL10" style={{width:"70px"}}
                                                    value={this.state.course_end_time_minute} 
                                                    onChange={this.handleUserSelect}
                                                    ref={(input) => this.textInputCourseEndTimeMinute = input}
                                                    disabled={this.state.input_disabled}
                                                >{minuteList}
                                                </select>
                                            </div>
                                            
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.course_end_time}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="course_meet_link">Meet Link</label>
                                        <div className="w70p">
                                            <input type="text" className="form-control" name="course_meet_link"
                                                placeholder={this.state.course_meet_link ? this.state.course_meet_link : ''}
                                                value={this.state.course_meet_link ? this.state.course_meet_link : ''}
                                                onChange={this.handleUserInput}
                                                ref={(input) => this.textInputCourseMeetLink = input}/>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.course_meet_link}/>  }</div>
                                            <a href="https://meet.google.com/new" className="btn btn-sm btn-secondary" target="_blank" rel="noreferrer">Create a link</a>
                                        </div>
                                    </div>
                                    <div>
                                        {(this.state.course_picture !== "")? 
                                        <div className="row-field mgT20">
                                            <div className="labelFormL"></div>
                                            <div className="w70p frg15e">
                                                <img width="150" src={this.state.course_picture} alt=""/>
                                                <a className="linkText" href="#!" onClick={this.onDeleteFileHandler} id="deletePicture"><DeleteIcon/> Delete Picture &raquo;</a>
                                            </div>
                                        </div>
                                        : '' }
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="course_document">Upload Picture</label>
                                        <div className="w70p">
                                            <input id="fileDocument" type="file"
                                                onChange={this.onChangeFileHandler}>
                                            </input> 
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>  }</div>
                                            <div className="mgT10 warningText">
                                            Please upload file in jpg, jpeg, png format.
                                            </div>
                                        </div>
                                    </div>
                                    {(this.state.course_video !== "" && this.state.course_video !== undefined)?
                                    <div className="row-field mgT20">
                                        <div className="labelFormL"></div>
                                        <div className="w70p frg15e">
                                            <iframe src={this.state.course_video} title={this.state.course_title}></iframe>
                                            <a className="linkText" href="#!" onClick={this.onDeleteFileHandler} id="deleteVideo"><DeleteIcon/> Delete Video &raquo;</a>
                                        </div>
                                    </div>
                                    : '' }
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="course_document2">Upload Video Intro</label>
                                        <div className="w70p">
                                            <input id="fileDocument2" type="file"
                                                onChange={this.onChangeFileHandler}>
                                            </input> 
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument2}/>  }</div>
                                            <div className="mgT10 warningText">
                                            Please upload file in mp4 format.
                                            </div>
                                        </div>
                                    </div>
                                    {(this.state.course_recording !== "" && this.state.course_recording !== undefined)?
                                    <div className="row-field mgT20">
                                        <div className="labelFormL"></div>
                                        <div className="w70p frg15e">
                                            <iframe src={this.state.course_recording} title={this.state.course_title}></iframe>
                                            <a className="linkText" href="#!" onClick={this.onDeleteFileHandler} id="deleteRecording"><DeleteIcon/> Delete Recording &raquo;</a>
                                        </div>
                                    </div>
                                    : '' }
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="course_document3">Upload Video Recording</label>
                                        <div className="w70p">
                                            <input id="fileDocument3" type="file"
                                                onChange={this.onChangeFileHandler}>
                                            </input> 
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument3}/>  }</div>
                                            <div className="mgT10 warningText">
                                            Please upload file in mp4 format.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT30 mgB30">
                                        <div className="labelFormL"></div>
                                        <div className="w70p"><button type="submit" className="w100p submitButton">Submit</button></div>
                                    </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>
                    {/* ====== */}
                  </div>  
                </div>
                :   
                <div><INA/></div>
                }
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div> 
    </div>
   );
  }
}
 
export default ICourseForm;