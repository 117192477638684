import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../cinc/cStyle.css';
import '../cinc/cGlobal';
import CheckLogin from '../cinc/ccekLogin';
import CHeader from './cfragmentheader';
import CSidemenu from './cfragmentsidemenu';
import defMemberPicture from '../../assets/pic-profile.png';
import defAMPicture from '../../assets/pic-profile-sq.jpg';
import Banner from '../../assets/bann-promo.jpg';
import DateTimeFormats from '../../inc/date-time-format';

const authKey = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[15];

class CMyProfiles extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        memberdoc_id: localStorage.getItem('cudocid'),
        member_email: '',
        member_position: '',
        member_fullname: '',
        member_picture: '',
        member_phone: '',
        company_list: [],
        am_list: [],
        isSignin: false,
        hideNav: '',
    }
  }
  resize() {
    let currentHideNav = (window.innerWidth <= 480);
    if (currentHideNav !== this.state.hideNav) {
        this.setState({hideNav: currentHideNav});
    }
    if(currentHideNav === false) { 
      document.getElementById("sidemenu").style.display = "block";
    } else {
      document.getElementById("sidemenu").style.display = "none";
    }
  
  }
  urleditProfile(valId) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: true,
      }
    })
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
      }
    })
  }
  componentDidMount() {
    document.title = global.cconfig.webtitle;
    document.getElementById('loading-area').style.display = 'block'; 
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    CheckLogin().then((statL) =>{ 
      if(statL === true) {
        var urlFetch = global.cconfig.urlLink+"/customerprofile/"+this.state.memberdoc_id;
        fetch(urlFetch, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }
          }).then(res => {
            if(res.status === 200) 
                return res.json() 
          }).then( resJson => {
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') { 
                var memberPicture = '', memberPhone = '';
                if(resJson.values.memberData[0].content[0].maincontent.memberPicture !== undefined && resJson.values.memberData[0].content[0].maincontent.memberPicture !== '') {
                    memberPicture = resJson.values.memberData[0].content[0].maincontent.memberPicture;
                } else { memberPicture = defMemberPicture; }

                if(resJson.values.memberData[0].content[0].maincontent.memberPhone !== undefined && resJson.values.memberData[0].content[0].maincontent.memberPhone !== '') {
                  memberPhone = resJson.values.memberData[0].content[0].maincontent.memberPhone;
                } 

                this.setState({
                  stTitle: 'Detail Member',
                  memberdoc_id: resJson.values.memberData[0].docid,
                  member_email: resJson.values.memberData[0].content[0].maincontent.memberEmail,
                  member_position: resJson.values.memberData[0].content[0].maincontent.memberPosition,
                  member_fullname: resJson.values.memberData[0].content[0].maincontent.memberFullname,
                  member_picture: memberPicture,
                  member_phone: memberPhone,
                  company_list: resJson.values.companyData,
                  am_list: resJson.values.amData,
                  company_licence_list: [],
                  isSignin: true
                }) 
            } else {
                console.log('Something happened wrong');
            }
          }).catch(err => { 
            document.getElementById('loading-area').style.display = 'none'; 
          })
      } else {
        window.location.href="/clogin";
      }
    });   
    
  }
   
  urlRenewLic(valid) {
    window.location.href='/crenewlicence';
  }
  urlAddLic(valid) {
    window.location.href='/caddlicence';
  }
 licviewlist(arrLic) {
  var arrlicenceViewList = [];
  var arrLicFilter = arrLic?.filter(
    (reslist) =>  (reslist.clientliccontent.licenceStatus === global.cconfig.licenceStatus[1])
  );
  if(arrLicFilter.length > 0) {
    for(var ls2=0; ls2 < arrLicFilter.length; ls2++) {
      var retResult2 = arrLicFilter[ls2];
      var licenceName = retResult2.clientliccontent.licenceType;
      var licenceSeatAmount = retResult2.clientliccontent.licenceTotalSeat;
      var licenceStatus = retResult2.clientliccontent.licenceStatus;
      var expDatez = '';
      if(retResult2.clientliccontent.endPeriode !== undefined) {
        expDatez = DateTimeFormats(retResult2.clientliccontent.endPeriode._seconds,"date-short");
      }
      var modr = ls2%4;
      var cssCol="";
      if(modr===0) { cssCol = "card-main lightBlue-bg"; }
      else if(modr===1) { cssCol = "card-main darkBlue-bg"; }
      else if(modr===2) { cssCol = "card-main orange-bg"; }
      else if(modr===3) { cssCol = "card-main darkorange-bg"; }
      
      
      arrlicenceViewList.push(<div className="mgL10 mgR10"><div className={cssCol} style={{width:"350px",height:"270px"}}>
          <div className="label-title-card white-font mgB20">{licenceName}</div>
          <div className="quantity-seat white-font">has <span>{licenceSeatAmount}</span>Seats</div>

          <div className="quantity-seat white-font">will be expired on <span>{expDatez}</span></div>
          <div className="quantity-seat white-font">status <span>{licenceStatus}</span></div>
          <div className="mgT30">
            <button className="transpButton mgR10" onClick={this.urlRenewLic.bind(this, retResult2.clientlicdocid)}>Renew</button>
            {(licenceStatus === "Active")? 
              <button className="transpButton" onClick={this.urlAddLic.bind(this, retResult2.clientlicdocid)}>Add More Seat</button>
            : '' }
          </div>
        </div></div>);
    }
  }
  return arrlicenceViewList;
 } 
 companylist() {
  
  var arrcompanyList = [];
  if(this.state.company_list.length > 0) {
    for(var ls=0; ls < this.state.company_list.length; ls++) {
      var retResult = this.state.company_list[ls];
      arrcompanyList.push(<div className="mgB50">
                    <div className="st4Title">{retResult.clientcontent.clientName}</div>
                    <div className="textItalic">{retResult.clientcontent.domainName}</div>
                    <div id="company-licence" className="mgT20 mgB30 w100p">
                      <div className="row">{ this.licviewlist(retResult.clientlicencelist) }</div>
                  
                    </div>
                  </div>);
    }
  }
  return arrcompanyList;
 }
 amviewlist() {
  var arramList = [];
  if(this.state.am_list.length > 0) {
    for(var ls3=0; ls3 < this.state.am_list.length; ls3++) {
      var retResult3 = this.state.am_list[ls3];
      if(retResult3.membercontent !== undefined) {
        var amPicture = retResult3.membercontent.userPicture; if(amPicture === undefined || amPicture === "" || amPicture === "-") { amPicture = defAMPicture; }
        var amName = retResult3.membercontent.userFullname; if(amName === undefined || amName === "" || amName === "-") { amName = ""; }
        var amPosition = retResult3.membercontent.userPosition; if(amPosition === undefined || amPosition === "" || amPosition === "-") { amPosition = ""; }
        var phoneNumber = retResult3.membercontent.userPhone; if(phoneNumber === undefined ||  phoneNumber === "" || phoneNumber === "-") { phoneNumber = ""; }
        var amEmail = retResult3.membercontent.userEmail; if(amEmail === undefined ||  amEmail === "" || amEmail === "-") { amEmail = ""; }

        // var linkphone = "tel:"+phoneNumber;
        var linkemail = "mailto:"+retResult3.membercontent.userEmail;
        // var linkwa = "whatsapp://send?text=MESSAGE&phone=+"+phoneNumber+"&abid=+"+phoneNumber;
        arramList.push(<div>
                <div className="account-photo divCenter">
                  <img src={amPicture} alt={amName} className="memberPhoto"/>
                </div>
                <div className="account-info mgB10">
                    <p><strong>{amName}</strong></p>
                    {(amPosition !== "" && amPosition !== "-")? <p>{amPosition} at PointStar</p> : '' }
                    {(amEmail !== "" && amEmail !== "-")?
                      <div className="divCenter">
                          <div className="flex-container">
                            <div className="notes-text divMiddle">{amEmail}</div>
                            <div className="mgL10">
                              <a href={linkemail}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                    : '' }
                </div>
              </div>);
      }
    }
  }
  return arramList;
 }
 render() {
  var companyViewList = this.companylist();
  var amViewList = this.amviewlist();
   return (
    <div className="main-container flex-container cwrapper">
      <div id="sidemenu" className="csidebar">{(this.state.isSignin === true)? <CSidemenu showHideNav={this.state.hideNav}/> : '' }</div>
      <div className="w100p">
        <div className="cheader"><CHeader showHideNav={this.state.hideNav}/></div>
        <div className="cmain-content">
          {(this.state.isSignin === true)? 
          <div>
              <div className="stTitle">My Subscription</div>
              <div className="stIndiTitle">My detail & company information</div>
                      
              <div className="mgT30">
                  {/* ====== */}
                  <div className="profiles">
                    <div className="row">

                      <div className="col-xl-8">
                          <div className="profile card card-main card-main-white">
                            <div id="profile-detail">
                              <div className="row">
                                { (this.state.member_picture !== '')? 
                                    <div className="col-md-4"><img src={this.state.member_picture} alt={this.state.member_fullname} className="memberPhoto" style={{width:"120px",height:"120px"}}/></div>
                                    : ''
                                } 
                                <div className="col-md-8" id="profile-info" >
                                  <div className="row mgT10"><h4>{this.state.member_fullname}</h4></div>
                                  <div className="row textBold textItalic">{this.state.member_email}</div>
                                  { (this.state.member_phone !== '' && this.state.member_phone !== '-')? <div className="row">{this.state.member_phone}</div> : '' }
                                  { (this.state.member_position !== '' && this.state.member_position !== '-')? <div className="row">{this.state.member_position}</div> : '' }
                                  <div className="row mgT10">
                                  {/*<div className="mgR05 cursor-on" onClick={this.urleditProfile.bind(this, "")}><EditIcon className="mgB05" style={{fontSize:"14pt", marginTop: "-3px"}}></EditIcon> <span style={{marginTop:"3px"}}>Edit Profile &raquo;</span></div>*/}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mgT20 mgB20"><img src={Banner} alt="" style={{width:"100%", borderRadius:"12px"}}/></div>
                          
                      </div>
                      <div  className="col-xl-4">
                        <div id="profile-am" className="widget-account card card-main card-main-white border">
                          <h3 className="textCenter">My Account Representative</h3>
                          <div>
                            {amViewList}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="mgT50" id="company-detail">
                    <div className="stTitle"> Active Licences</div>
                    <div className="mgB30 stIndiTitle">Current active licences with PointStar</div>
                    <div>{companyViewList}</div>
                  </div>
                  {/* ====== */}
              </div>
          </div>
          : <div></div> }
        </div>
      </div>
      <div id="loading-area" className="divHide loading"></div>
    </div>
    
   );
 }
}
 
export default CMyProfiles;