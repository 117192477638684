import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import DateTimeFormats from '../../inc/date-time-format';

class AdditionalDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            clientdoc_id: '',
            licencedoc_id: '',
            additionaldoc_id: '',
            client_name: '',
            domain_name: '',
            client_country: '',
            client_licence_type: '',
            client_licence_currseat: '',
            client_licence_additional_status: '',
            client_licence_additional_seat: '',
            client_licence_additional_active_date: '',
            client_licence_additional_expired_date: '',
            client_licence_additional_reqdate: '',
            client_licence_additional_reqby: '',
            client_licence_additional_remarks: ''
        }
    }
    
    componentDidMount() {
        var licenceId = document.getElementById("contentid").innerText;
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        
        this.setState({
            stPopTitle: '',
            clientdoc_id: othersContent.client_id,
            licencedoc_id: licenceId,
            additionaldoc_id: othersContent.additional_id,
            client_name: othersContent.client_name,
            domain_name: othersContent.domain_name,
            client_country: othersContent.client_country,
            client_licence_type: othersContent.licence_type,
            client_licence_currseat: othersContent.licence_seat,
            client_licence_additional_status: othersContent.addlicence_info.additionalStatus,
            client_licence_additional_seat: othersContent.addlicence_info.licenceAddSeat,
            client_licence_additional_active_date: DateTimeFormats(othersContent.addlicence_info.additionalStartPeriode._seconds,"date-long"),
            client_licence_additional_expired_date: DateTimeFormats(othersContent.addlicence_info.additionalEndPeriode._seconds,"date-long"),
            client_licence_additional_reqdate: DateTimeFormats(othersContent.addlicence_info.createDate._seconds,"date-long"),
            client_licence_additional_reqby: othersContent.addlicence_info.createByName,
            client_licence_additional_remarks: othersContent.addlicence_info.additionalLicenceRemarks
        }); 
    }
    render() {
      return (
        <div className="main-container">
            <div className="mgT20">
              <div className="mgL20">
                <div className="st3Title">{this.state.client_name}</div>
                <div><h5 className="detailJoined">{this.state.client_country} - {this.state.domain_name}</h5></div>
              </div>
              <div className="mgT20 mgL20">
                <div>
                    <div className="mgT05 flex-container">
                        <div className="w30p">Type of Licence </div>
                        <div className="mgL10"> {this.state.client_licence_type}</div>
                    </div>
                    <div className="mgT05 flex-container">
                        <div className="w30p">Current Active Licence </div>
                        <div className="mgL10"> {this.state.client_licence_currseat} seat(s)</div>
                    </div>
                    <div className="mgT30 lineBottom">
                        <div className="flex-container">
                            <div className="w30p textBold">Detail Information</div>
                            <div className="w70p divRight">
                            </div>
                        </div>
                    </div>
                    <div className="mgT20" id="renew-detail-info">
                        <div className="mgT20">
                            <div className="mgT10 flex-container">
                                <div className="w40p">Additional Licence Status </div>
                                <div className="mgL10 st4Title"> <span className={this.state.client_licence_additional_status === global.config.licenceRenewStatus[1]? "fontGreen textBold" : "textBold"}>{this.state.client_licence_additional_status}</span> </div>
                            </div>
                            <div className="mgT05 flex-container">
                                <div className="w40p">Additional Number of Licence </div>
                                <div className="mgL10"> {this.state.client_licence_additional_seat} seat(s) </div>
                            </div>
                            {(this.state.client_licence_additional_active_date !== '' && this.state.client_licence_additional_expired_date !== '')? 
                            <div className="mgT05 flex-container">
                                <div className="w40p">Active Periode </div>
                                <div className="mgL10"> {this.state.client_licence_additional_active_date} <span className="mgL10 mgR10">-</span> {this.state.client_licence_additional_expired_date}</div>
                            </div>
                            : ''}
                        
                            {(this.state.client_licence_additional_reqdate !== '')? 
                                <div className="mgT30 flex-container">
                                    <div className="w40p">Request</div>
                                    <div className="mgL10">
                                        <span>on.</span><span className="mgL10"> {this.state.client_licence_additional_reqdate}</span>
                                        <span className="mgL30">by.</span><span className="mgL10"> {this.state.client_licence_additional_reqby}</span>
                                    </div>
                                </div>
                            : ''}
                                                    
                            {(this.state.client_licence_additional_remarks !== '')? 
                            <div className="mgT05 flex-container">
                                <div className="w40p">Remarks </div>
                                <div className="mgL10"> {this.state.client_licence_additional_remarks} </div>
                            </div>
                            : ''}
                        </div>
                    </div> 
                </div>
              </div>
            </div>
            <div id="loading-area" className="divHide loading"></div> 
        </div>
      );
    }
 }
  
export default AdditionalDetail;