import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../cinc/cStyle.css';
import CheckLogin from '../cinc/ccekLogin';
import { FormErrors } from '../../inc/error-form';
import DateTimeFormats from '../../inc/date-time-format';
import CHeader from './cfragmentheader';
import CSidemenu from './cfragmentsidemenu';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const authKey = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[24];
const authKey2 = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[3];

class CReqUpgrade extends Component {
  constructor (props) {
    super(props);
    this.state = {
        memberdoc_id: localStorage.getItem('cudocid'),
        domain_name: '',
        licence_type: '',
        licence_reqtype: '',
        company_list: [],
        licence_list: [],
        licence_request_list: [],
        request_list: [],
        formErrors: {domain_name: '',licence_type: '',licence_reqtype: ''},
        domainNameValid: false,
        licenceTypeValid: false,
        licenceReqTypeValid: false,
        formValid: false,
        errmsg: '',
        isSignin: false,
        hideNav: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  resize() {
    let currentHideNav = (window.innerWidth <= 480);
    if (currentHideNav !== this.state.hideNav) {
        this.setState({hideNav: currentHideNav});
    }
    if(currentHideNav === false) { 
      document.getElementById("sidemenu").style.display = "block";
    } else {
      document.getElementById("sidemenu").style.display = "none";
    }
  
  }
  urlmore() {
    window.location.href='/cviewupgrade';
  }

  handleLicenceType = (e) => {
    document.getElementById('loading-area').style.display = 'block'; 
    const name = e.target.name;
    const value = e.target.value;

    var arrValue2 = value.split("$$");
    var keywCountry = "singapore";

    var lictypedata = JSON.parse('{"keyword":"'+keywCountry+'","category":"'+arrValue2[2]+'","pageShow":"1000"}'); 
    fetch(global.config.urlLink+"/licencetypelist/", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey2,
        }, body : JSON.stringify(lictypedata)
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJsonLicType => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJsonLicType.status === '200') {
          this.setState( prevState => {
            return {  
                ...prevState.licence_type, licence_type: value,
                ...prevState.licence_request_list, licence_request_list: resJsonLicType.values
            }
          }, () => { 
              this.validateField(name, value)
          })
        } else {
          this.setState( prevState => {
            return {  
                ...prevState.licence_type, licence_type: value
            }
          }, () => { 
              this.validateField(name, value)
          })
        }
      }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
      });
  } 
  handleUserSelect = (e) => {
    const value = e.target.value;
    const inputname = e.target.name;
    if(inputname === "domain_name") {

      if(this.state.licence_list.length > 0) {
        var liclist = JSON.parse(this.state.licence_list);
        for(var x=0; x<liclist.length; x++) {
          document.getElementById("licence_type_"+x).checked = false;
        }
      }

      var arrLicenceList = [];
      if(value !== "") {
        var arrValue = value.split("$$");
        arrLicenceList = arrValue[4];
      }
      this.setState( prevState => {
        return {  
            ...prevState.domain_name, domain_name: value,
            ...prevState.licence_list, licence_list: arrLicenceList,
            ...prevState.licence_type, licence_type: '',
            ...prevState.licence_request_list, licence_request_list: []
        }
      }, () => { 
          this.validateField(inputname, value)
      }) 
    } else if(inputname === "licence_reqtype") {
        this.setState( prevState => {
          return {  
              ...prevState.licence_reqtype, licence_reqtype: value
          }
        }, () => { 
            this.validateField(inputname, value)
        }) 
    }
    
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  } 
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let domainNameValid = this.state.domainNameValid;
    let licenceTypeValid = this.state.licenceTypeValid;
    let licenceReqTypeValid = this.state.licenceReqTypeValid;

    switch(fieldName) {
        case 'domain_name': 
          domainNameValid = value.trim().length > 0;
          fieldValidationErrors.domain_name = domainNameValid ? '' : 'domain name need to be selected';
          break;
        case 'licence_type': 
          licenceTypeValid = value.trim().length > 0;
          fieldValidationErrors.licence_type = licenceTypeValid ? '' : 'licence need to be selected';
          break;
        case 'licence_reqtype': 
          licenceReqTypeValid = value.trim().length > 0;
          fieldValidationErrors.licence_reqtype = licenceReqTypeValid ? '' : 'request licence need to be filled';
          break;
        default:
            break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    domainNameValid: domainNameValid,
                    licenceTypeValid: licenceTypeValid,
                    licenceReqTypeValid: licenceReqTypeValid,
                }, this.validateForm);                            
  }
  validateForm() {
    this.setState({formValid: this.state.domainNameValid && this.state.licenceTypeValid && this.state.licenceReqTypeValid });
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  handleSubmit(e) {
    e.preventDefault();
    
    if(this.state.domain_name !== '' && this.state.licence_type !== '' && this.state.licence_reqtype !== '') {
      document.getElementById('loading-area').style.display = 'block'; 

      var arrDomainName = this.state.domain_name.split("$$");
      var arrLicence = this.state.licence_type.split("$$");
      
      var valueRequestType = 0;
      if(this.textInputLicenceReqType.value === '') { valueRequestType = this.state.licence_reqtype; } else { valueRequestType = this.textInputLicenceReqType.value; }
            
      var urlFetch, datatext;
      datatext ='{"client_id":"'+arrDomainName[0]+'","client_name":"'+arrDomainName[1]+'","domain_name":"'+arrDomainName[2]+'","client_country":"'+arrDomainName[3]+'","licence_id":"'+arrLicence[0]+'","licence_type":"'+arrLicence[1]+'","licence_reqtype":"'+valueRequestType+'","member_id":"'+this.state.memberdoc_id+'","form_id":"form-add-upgradelic"}';
      urlFetch = global.cconfig.urlLink+"/addrequpgradelicence";
  
      var data = JSON.parse(datatext); 
      fetch(urlFetch, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }, body : JSON.stringify(data)
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          document.getElementById('form-content').style.display = 'none';
          if(resJson.status === '200') {
              document.getElementById('thankyou-page').style.display = 'block';
              document.getElementById('error-page').style.display = 'none';
          } else {
              document.getElementById('thankyou-page').style.display = 'none';
              document.getElementById('error-page').style.display = 'block';
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
          document.getElementById('form-content').style.display = 'none';
          document.getElementById('thankyou-page').style.display = 'none';
          document.getElementById('error-page').style.display = 'block';
        }) ;

    } else {
      var arrfieldName = ['domain_name','licence_type','licence_reqtype'];
      var arrvalue = [this.state.domain_name,this.state.licence_type,this.state.licence_reqtype];
      for(var ck in arrfieldName) {
          this.validateField(arrfieldName[ck], arrvalue[ck]);
      }
    }
  }
  componentDidMount() {
    document.title = global.cconfig.webtitle;
    document.getElementById('loading-area').style.display = 'block'; 
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    CheckLogin().then((statL) =>{ 
      if(statL === true) {
        document.getElementById('form-content').style.display='block';
        fetch(global.cconfig.urlLink+"/infolicence/"+this.state.memberdoc_id, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') { 

            this.setState({
              memberdoc_id: resJson.values.memberData[0].docid,
              domain_name: '',
              licence_type: '',
              licence_reqtype: '',
              company_list: resJson.values.companyData,
              licence_list: [],
              licence_request_list: [],
              request_list: resJson.values.memberData[0].spcontent,
              formErrors: {domain_name: '',licence_type: '',licence_reqtype: ''},
              domainNameValid: false,
              licenceTypeValid: false,
              licenceReqTypeValid: false,
              formValid: false,
              errmsg: '',
              isSignin: true
            });
          } else {
            document.getElementById('loading-area').style.display = 'none'; 
          }
        }).catch(err => { 
          document.getElementById('loading-area').style.display = 'none'; 
        });
      } else {
        window.location.href="/clogin";
      }
    });
  } 
  requestlist() {
    var arrRequestList = [];
    if(this.state.request_list.length > 0) {
      var limitlen = this.state.request_list.length;
      if(limitlen > 5) { limitlen = 5; } 
      for(var ls3=0; ls3<limitlen; ls3++) {
        var cDatez="";
        if(this.state.request_list[ls3].specialcontent.createDate !== undefined) {
            cDatez = DateTimeFormats(this.state.request_list[ls3].specialcontent.createDate._seconds,"datetime-long");
        }
        arrRequestList.push(<div className="list-request" key={ls3+1}>
            <div className="detail-list w100p">
              <span className="title-request">{this.state.request_list[ls3].specialcontent.licenceType}</span><br/>
              <span className="content-request">{this.state.request_list[ls3].specialcontent.domainName}</span><br/>
              <span className="content-request">request upgrade to <span className="textBold">{this.state.request_list[ls3].specialcontent.licenceRequestType}</span> </span><br/>
              <span className="date-request">{cDatez} by {this.state.request_list[ls3].specialcontent.createByName}</span>
              <span className="smcontent-request divRight">status : <span className="mgL10" style={{fontWeight:"500"}}>{this.state.request_list[ls3].specialcontent.requestStatus}</span></span>
            </div>
          </div>)
      }
      if(this.state.request_list.length > limitlen) {
        arrRequestList.push(<div key={ls3+1}>
            <div className="w100p divCenter more-request" onClick={this.urlmore.bind(this)}>
              more »
            </div>
          </div>)
      }
    } else {
      arrRequestList.push(<div key="0" className="list-request textCenter">
            <div className="detail-list w100p"><span className="content-request">no record</span></div>
          </div>)
    }
    return arrRequestList;
  }
  inputdomainlist() {
    var arrinputDomainList = [];
    if(this.state.company_list.length > 0) {
      for(var ls2=0; ls2<this.state.company_list.length; ls2++) {
        arrinputDomainList.push(<option key={ls2} value={
          this.state.company_list[ls2].clientdocid+"$$"+
          this.state.company_list[ls2].clientcontent.clientName+"$$"+
          this.state.company_list[ls2].clientcontent.domainName+"$$"+
          this.state.company_list[ls2].clientcontent.clientCountry+"$$"+
          JSON.stringify(this.state.company_list[ls2].clientlicencelist)
        }>{this.state.company_list[ls2].clientcontent.domainName}</option>)
      }
    }
    return arrinputDomainList;
  }
  inputlicencelist() {
    var arrinputLicList = [];
    if(this.state.licence_list.length > 0) {
      var licenceList = JSON.parse(this.state.licence_list);
      for(var ls=0; ls<licenceList.length; ls++) {
        arrinputLicList.push(<div key={ls}><label>
            <input type="radio" name="licence_type" id={"licence_type_"+ls} 
              value={licenceList[ls].clientlicdocid+"$$"+licenceList[ls].clientliccontent.licenceType+"$$"+licenceList[ls].clientliccontent.licenceCategory}
              onChange={this.handleLicenceType} /> 
              <span className="mgL10">{licenceList[ls].clientliccontent.licenceType}</span>
          </label></div>
        )
      }
    }
    return arrinputLicList;
  }
  inputlicencetype() {
    var arrinputLicType = [];
    if(this.state.licence_request_list.length > 0) {
      for(var ls4=0; ls4<this.state.licence_request_list.length; ls4++) {
        arrinputLicType.push(<option value={this.state.licence_request_list[ls4].content.licenceType} key={ls4}>{this.state.licence_request_list[ls4].content.licenceType}</option>)
      }
    }
    return arrinputLicType;
  }
  render() {
  var inputLicList = this.inputlicencelist();
  var inputDomainList = this.inputdomainlist();
  var requestList = this.requestlist();
  var sellicenceTypeList = this.inputlicencetype()
   return (
    
      <div className="main-container flex-container cwrapper">
        <div id="sidemenu" className="csidebar">{(this.state.isSignin === true)? <CSidemenu showHideNav={this.state.hideNav}/>:''}</div>
        <div className="w100p">
          <div className="cheader"><CHeader showHideNav={this.state.hideNav}/></div>
          <div className="cmain-content">
            {/* ====== */}
            {(this.state.isSignin === true)? 
            <div>
                <div className="stTitle">Upgrade Licence</div>
                <div className="stIndiTitle">Request for Upgrade Licence</div>
                        
                <div className="mgT30">
                  <div className="row">
                      <div className="col-xl-8">
                        <div className="card card-main card-main-white">
                          <div id="error-page" className="divHide">
                            <div className="flex-container">
                                <CancelIcon className="iconRed"></CancelIcon>
                                <div className="mgL20 done-text">Failed to submit the request.</div>
                              </div>
                          </div>
                          <div id="thankyou-page" className="divHide">
                            <div className="flex-container">
                                <CheckCircleIcon className="iconGreen"></CheckCircleIcon>
                                <div className="mgL20 done-text">Thank you, your customer representative will contact you shortly.</div>
                              </div>
                          </div>
                          <div id="form-content">
                            <div className="label-title-card">Requirement</div>
                            <div id="FormCenter" className="mgT20">
                              <form onSubmit={this.handleSubmit}>
                                <div className="mgT20">
                                  <label className="textMiddle" htmlFor="domain_name">Which Domain do you want to upgrade?</label>
                                  <div>
                                    <select name="domain_name" className="form-control" 
                                        value={this.state.domain_name} 
                                        onChange={this.handleUserSelect}
                                        ref={(input) => this.textInputDomainName = input}
                                    >
                                        <option value="" key="">-- please select --</option>
                                        {inputDomainList}
                                    </select>
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.domain_name}/>  }</div>
                                  </div>
                                </div>
                                <div className="mgT30">
                                  {(inputLicList.length > 0)? 
                                  <div>
                                    <label className="textMiddle" htmlFor="licence_type">Which product plan do you want to upgrade?</label>
                                    {inputLicList}
                                   <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_type}/>  }</div>
                                  </div>
                                   : '' } 
                                </div>
                                <div className="mgT30 flex-container">
                                  {(sellicenceTypeList.length > 0)? 
                                  <div>
                                    <label htmlFor="licence_reqtype" className="textMiddle">Which product plan do you request to upgrade? </label>
                                    <select name="licence_reqtype" className="form-control" 
                                          value={this.state.licence_reqtype} 
                                          onChange={this.handleUserSelect}
                                          ref={(input) => this.textInputLicenceReqType = input}
                                      >
                                      <option value="" key="">-- please select --</option>
                                      {sellicenceTypeList}
                                    </select>
                                   <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_reqtype}/>  }</div>
                                  </div>
                                   : '' } 
                                </div>
                                <div className="mgT30"><button id="submit-button"  type="submit" className="submitButton">Ask for Quotation</button></div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="card card-main card-main-blue">
                          <div className="card-request">
                            <div className="label-title-card white-font">Request</div>
                            <div>
                              {requestList}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            : <div id="form-content"></div> }
            {/* ====== */}
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div> 
      </div>
   );
  }
}
 
export default CReqUpgrade;