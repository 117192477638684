import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../cinc/cStyle.css';
import CheckLogin from '../cinc/ccekLogin';
import { FormErrors } from '../../inc/error-form';
import DateTimeFormats from '../../inc/date-time-format';
import CHeader from './cfragmentheader';
import CSidemenu from './cfragmentsidemenu';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const authKey = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[22];

class CReqClaim extends Component {
  constructor (props) {
    super(props);
    this.state = {
        memberdoc_id: localStorage.getItem('cudocid'),
        domain_name: '',
        company_list: [],
        request_list: [],
        formErrors: {domain_name: ''},
        domainNameValid: false,
        formValid: false,
        errmsg: '',
        isSignin: false,
        hideNav: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  resize() {
    let currentHideNav = (window.innerWidth <= 480);
    if (currentHideNav !== this.state.hideNav) {
        this.setState({hideNav: currentHideNav});
    }
    if(currentHideNav === false) { 
      document.getElementById("sidemenu").style.display = "block";
    } else {
      document.getElementById("sidemenu").style.display = "none";
    }
  
  }
  urlmore() {
    window.location.href='/cviewclaim';
  }

  handleUserSelect = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  } 
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let domainNameValid = this.state.domainNameValid;
    
    switch(fieldName) {
        case 'domain_name': 
          domainNameValid = value.trim().length > 0;
          fieldValidationErrors.domain_name = domainNameValid ? '' : 'domain name need to be selected';
          break;
        default:
            break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    domainNameValid: domainNameValid,
                }, this.validateForm);                            
  }
  validateForm() {
    this.setState({formValid: this.state.domainNameValid });
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  handleSubmit(e) {
    e.preventDefault();
    if(this.state.domain_name !== '') {
      document.getElementById('loading-area').style.display = 'block'; 

      var arrDomainName = this.state.domain_name.split("$$");
      var urlFetch, datatext;
      datatext ='{"client_id":"'+arrDomainName[0]+'","client_name":"'+arrDomainName[1]+'","domain_name":"'+arrDomainName[2]+'","client_country":"'+arrDomainName[3]+'","member_id":"'+this.state.memberdoc_id+'","form_id":"form-add-claimgcp"}';
      urlFetch = global.cconfig.urlLink+"/addreqclaimgcp";
  
      var data = JSON.parse(datatext); 
      fetch(urlFetch, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }, body : JSON.stringify(data)
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          document.getElementById('form-content').style.display = 'none';
          if(resJson.status === '200') {
              document.getElementById('thankyou-page').style.display = 'block';
              document.getElementById('error-page').style.display = 'none';
          } else {
              document.getElementById('thankyou-page').style.display = 'none';
              document.getElementById('error-page').style.display = 'block';
              console.log('Something happened wrong');
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
          document.getElementById('form-content').style.display = 'none';
          document.getElementById('thankyou-page').style.display = 'none';
          document.getElementById('error-page').style.display = 'block';
        }) ;

    } else {
      var arrfieldName = ['domain_name'];
      var arrvalue = [this.textInputDomainName.value];
      for(var ck in arrfieldName) {
          this.validateField(arrfieldName[ck], arrvalue[ck]);
      }
    }
  }
  componentDidMount() {
    document.title = global.cconfig.webtitle;
    document.getElementById('loading-area').style.display = 'block'; 
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    CheckLogin().then((statL) =>{ 
      if(statL === true) {
        document.getElementById('form-content').style.display='block';
        fetch(global.cconfig.urlLink+"/infolicence/"+this.state.memberdoc_id, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') { 

            this.setState({
              memberdoc_id: resJson.values.memberData[0].docid,
              domain_name: '',
              company_list: resJson.values.companyData,
              request_list: resJson.values.memberData[0].spcontent,
              formErrors: {domain_name: ''},
              domainNameValid: false,
              formValid: false,
              errmsg: '',
              isSignin: true
            });
          } else {
            document.getElementById('loading-area').style.display = 'none'; 
            console.log('Something happened wrong');
          }
        }).catch(err => { 
          document.getElementById('loading-area').style.display = 'none'; 
        });
      } else {
        window.location.href="/clogin";
      }
    });
  } 
  requestlist() {
    var arrRequestList = [];
    if(this.state.request_list.length > 0) {
      var limitlen = this.state.request_list.length;
      if(limitlen > 5) { limitlen = 5; } 
      for(var ls3=0; ls3<limitlen; ls3++) {
        var cDatez="";
        if(this.state.request_list[ls3].specialcontent.createDate !== undefined) {
            cDatez = DateTimeFormats(this.state.request_list[ls3].specialcontent.createDate._seconds,"datetime-long");
        }
        arrRequestList.push(<div className="list-request" key={ls3+1}>
            <div className="detail-list w100p">
              <span className="title-request">{this.state.request_list[ls3].specialcontent.domainName}</span><br/>
              <span className="date-request">{cDatez} by {this.state.request_list[ls3].specialcontent.createByName}</span>
              <span className="smcontent-request divRight">status : <span className="mgL10" style={{fontWeight:"500"}}>{this.state.request_list[ls3].specialcontent.requestStatus}</span></span>
            </div>
          </div>)
      }
      if(this.state.request_list.length > limitlen) {
        arrRequestList.push(<div key={ls3+1}>
            <div className="w100p divCenter more-request" onClick={this.urlmore.bind(this)}>
              more »
            </div>
          </div>)
      }
    } else {
      arrRequestList.push(<div key="0" className="list-request textCenter">
            <div className="detail-list w100p"><span className="content-request">no record</span></div>
          </div>)
    }
    return arrRequestList;
  }
  inputdomainlist() {
    var arrinputDomainList = [];
    if(this.state.company_list.length > 0) {
      for(var ls2=0; ls2<this.state.company_list.length; ls2++) {
        arrinputDomainList.push(<option key={ls2} value={
          this.state.company_list[ls2].clientdocid+"$$"+
          this.state.company_list[ls2].clientcontent.clientName+"$$"+
          this.state.company_list[ls2].clientcontent.domainName+"$$"+
          this.state.company_list[ls2].clientcontent.clientCountry+"$$"+
          JSON.stringify(this.state.company_list[ls2].clientlicencelist)
        }>{this.state.company_list[ls2].clientcontent.domainName}</option>)
      }
    }
    return arrinputDomainList;
  }
  render() {
    var inputDomainList = this.inputdomainlist();
    var requestList = this.requestlist();
  
   return (
    
      <div className="main-container flex-container cwrapper">
        <div id="sidemenu" className="csidebar">{(this.state.isSignin === true)? <CSidemenu showHideNav={this.state.hideNav}/> : '' }</div>
        <div className="w100p">
          <div className="cheader"><CHeader showHideNav={this.state.hideNav}/></div>
          <div className="cmain-content">
            {/* ====== */}
            {(this.state.isSignin === true)? 
            <div>
                <div className="stTitle">Claim GCP</div>
                <div className="stIndiTitle">Request for Technical Assistance on GCP</div>
                        
                <div className="mgT30">
                  <div className="row">
                      <div className="col-xl-8">
                        <div className="card card-main card-main-white">
                          <div id="error-page" className="divHide">
                            <div className="flex-container">
                                <CancelIcon className="iconRed"></CancelIcon>
                                <div className="mgL20 done-text">Failed to submit the request.</div>
                              </div>
                          </div>
                          <div id="thankyou-page" className="divHide">
                            <div className="flex-container">
                                <CheckCircleIcon className="iconGreen"></CheckCircleIcon>
                                <div className="mgL20 done-text">Thank you, your customer representative will contact you shortly.</div>
                              </div>
                          </div>
                          <div id="form-content">
                            <div className="textJustify mgB30"> 
                              <div>If you are an established customer exploring new product innovations and are looking to learn the basics of Google Cloud, the Google Cloud Free Program has you covered.</div>
                              <div className="mgT05">The Free Trial provides you with free Cloud Billing credits 'till $300 to pay for resources used while you learn about Google Cloud for 90 days.</div>
                              <div className="mgT05"><a className="fontlink" href="https://cloud.google.com/free/docs/gcp-free-tier#free-trial" target="_blank" rel="noreferrer">click here for more information &raquo;</a></div>
                            </div>


                            {/* <div className="label-title-card">Requirement</div> */}
                            <div id="FormCenter" className="mgT20">
                              <form onSubmit={this.handleSubmit}>
                                <div className="mgT20">If you interest, we can assist you to get the GCP free trial.</div>
                                <div className="mgT05">
                                  <label className="textMiddle" htmlFor="domain_name">Which Domain do you want to claim?</label>
                                  <div>
                                    <select name="domain_name" className="form-control" 
                                        value={this.state.domain_name} 
                                        onChange={this.handleUserSelect}
                                        ref={(input) => this.textInputDomainName = input}
                                    >
                                        <option value="">-- please select --</option>
                                        {inputDomainList}
                                    </select>
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.domain_name}/>  }</div>
                                  </div>
                                </div>
                                
                                <div className="mgT30"><button id="submit-button"  type="submit" className="submitButton">Ask for Quotation</button></div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="card card-main card-main-blue">
                          <div className="card-request">
                            <div className="label-title-card white-font">Request</div>
                            <div>
                              {requestList}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            : <div id="form-content"></div> }
            {/* ====== */}
                
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div> 
      </div>
   
    
    
   );
  }
}
 
export default CReqClaim;