import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../cinc/cStyle.css';
import CheckLogin from '../cinc/ccekLogin';
import { FormErrors } from '../../inc/error-form';
import CheckBox from '../../inc/checkbox';
import DateTimeFormats from '../../inc/date-time-format';
import RoundDecimal from '../../inc/round-decimal';
import NumberFormats from '../../inc/number-format';
import ReplaceAllContent from '../../inc/replace-all';
import RandomNumber from '../../inc/random-number';
import MessageThx from '../cinc/cmsgthx';
import CHeader from './cfragmentheader';
import CSidemenu from './cfragmentsidemenu';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import picpaynow from '../../assets/pic_paynow.png';
import picbt from '../../assets/pic_bt.png';
import piccc from '../../assets/pic_cc.jpg';
 
const authKey = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[16];
const authKey2 = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[3];
const authKey3 = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[17];
const authKey4 = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[26];

function sendmail(data_send) {
  return new Promise((resolve) => {
    var URL_SENDMAIL = global.cconfig.urlLink+'/sendmaildata';
    fetch(URL_SENDMAIL, {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
      // }, body : JSON.stringify(data_customer)
    }, body : JSON.stringify(data_send)
      }).then(res => {
          if(res.status === 200) { return res.json();  }
      }).then( resJson => {
        resolve("success");
        // var replymsg = MessageThx(this.state.current_licCurrency,this.state.grand_total,this.state.current_domainName,this.state.current_licName,this.state.method_payment);
        // var replymsg = MessageThx(this.state.current_licCurrency,this.state.grand_total,arrDomainName[2],this.state.current_licName,this.state.method_payment);
        // document.getElementById('thankyou-page-msg').style.display = 'block';
        // document.getElementById('thankyou-page-msg').innerHTML = replymsg;
      })
  })
}

class CReqAddLicence extends Component {
  constructor (props) {
    super(props);
    this.state = {
        memberdoc_id: localStorage.getItem('cudocid'),
        requester_name: '',
        requester_email: '',
        requester_phone: '',
        client_id: '',
        client_name: '',
        licence_country: '',
        domain_name: '',
        
        licence_id: '',
        licence_type: '',
        licence_addseat: '',
        current_licName: '',
        current_licSeat: 0,
        current_licExpired: '',
        current_domainName: '',
        current_licCurrency: '',
        current_licPrice: 0,
        current_licExpiredBillCycle: '',
        current_licDurationBillCycle: '',
        current_statInvoice: 'NotSend',
        
        company_list: [],
        licence_list: [],
        licence_addon_list: [],
        licence_addon_seat: [],
        request_list: [],
        
        enable_vault: 'No',
        request_vault: 0,
        // request_vault_rate: 0,
        current_vault_price: 0,
        request_vault_rp: 0,
        licence_month_rate: 0,
        licence_addseat_rate: 0,
        licence_addseat_rp: 0,
        tax_label_rate: '',
        tax_value_rate: 0,
        tax_value_rp: 0,
        price_total: 0,
        grand_total: 0,
        method_payment: '',
        is_tnc: false,
        
        cc_num: '',
        cc_expired: '',
        cc_cvc: '',
        cc_cardholder_name: '',
        licence_renewseat: '',
        formErrors: {domain_name: '',licence_type: '',licence_addseat: '', method_payment: '', is_tnc: '', cc_num: '', cc_expired: '', cc_cvc: '', cc_cardholder_name: '', licence_renewseat: '', requester_phone: ''},
        domainNameValid: false,
        licenceTypeValid: false,
        licenceAddSeatValid: false,
        methodPaymentValid: false,
        isTncValid: false,
        cardNumberValid: false,
        cardExpiryValid: false,
        cardCvcValid: false,
        cardHolderNameValid: false,
        licenceRenewSeatValid: false,
        formValid: false,
        errmsg: '',
        isSignin: false,
        hideNav: '',
        otherContent: ''
    }
    this.handleSubmit1 = this.handleSubmit1.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.handleSubmit3 = this.handleSubmit3.bind(this);
    
  }
  resize() {
    let currentHideNav = (window.innerWidth <= 480);
    if (currentHideNav !== this.state.hideNav) {
        this.setState({hideNav: currentHideNav});
    }
    if(currentHideNav === false) { 
      document.getElementById("sidemenu").style.display = "block";
    } else {
      document.getElementById("sidemenu").style.display = "none";
    }
  
  }
  urlmore() {
    window.location.href='/cviewaddlicence';
  }
  urlcancelreq() {
    window.location.href='/caddlicence';
  }
  urlrenewreq() {
    window.location.href='/crenewlicence';
  }
  handleLicenceAddOn = (e) => {
    var stat = false;
    var licaddon = this.state.licence_addon_list;
    licaddon.forEach(licaddons => {
       if (licaddons.value === e.target.value) { licaddons.isChecked =  e.target.checked; }
       if(licaddons.isChecked === true) { stat = true; }
    })
    this.setState({
      licence_addon_list: licaddon,
      formErrors: {domain_name: '',licence_type: '',licence_addseat: '', method_payment: '', is_tnc: '', cc_num: '', cc_expired: '', cc_cvc: '', cc_cardholder_name: '', licence_renewseat: '', requester_phone: ''},
    }, () => { 
      this.validateField("licence_addon_list", stat,"")
    })
    
  } 
  handleUserInput2 = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    
    var licaddonseat = this.state.licence_addon_seat;
    licaddonseat.forEach(licaddonseats => {
       if (licaddonseats.fieldId === name) { licaddonseats.value =  value; }
    })
    this.setState({
      licence_addon_seat: licaddonseat
    })
  } 
  handleLicenceType = (e) => {
    var idt = e.target.id;
    var name = e.target.name;
    var value = e.target.value;
    this.handleClickLicenceType(name,value,idt.replace("licence_type_",""));
  }
  handleClickLicenceType(name,value,idx) {
    var radios_lictype = document.getElementsByName("licence_type").length;
    for(var lt=0; lt<radios_lictype; lt++) {
      if(parseInt(idx) === parseInt(lt)) {
        document.getElementById("licence_input_"+idx).style["boxShadow"] = "0 0 1px 1px #90cbfa";
      } else {
        document.getElementById("licence_input_"+lt).style["boxShadow"] = "0 0 0px 0px";
      }
    }

    this.setState({[name]: value},
        () => { this.validateField(name, value,"")});
    if(value !== "") {
      var arrSelLic = value.split("$$");
      var licName = arrSelLic[1];
      this.setState( prevState => {
        return {  
            ...prevState.current_licName, current_licName: licName,
            ...prevState.current_licSeat, current_licSeat: arrSelLic[2],
            ...prevState.current_licExpired, current_licExpired: arrSelLic[7],
            ...prevState.current_licExpiredBillCycle, current_licExpiredBillCycle: arrSelLic[6],
            ...prevState.current_statInvoice, current_statInvoice: arrSelLic[8],
            ...prevState.current_licDurationBillCycle, current_licDurationBillCycle: arrSelLic[9],
            ...prevState.current_licCurrency, current_licCurrency: arrSelLic[5],
            ...prevState.current_licPrice, current_licPrice: arrSelLic[4],
        }
      });
      if(licName === global.cconfig.typeLicenceAddon) {
        document.getElementById("gaddon-area").style.display = 'none';
      } else {
        document.getElementById("gaddon-area").style.display = 'none';  
      }
      if(arrSelLic[10] === "true") {
        document.getElementById("textfield-lic-addseat").style.display = 'none';  
        document.getElementById("submit-button").style.display = 'none'; 
        document.getElementById("submit-button1b").style.display = 'none'; 
        document.getElementById("renew-button").style.display = 'block'; 
        
      } else {
        document.getElementById("textfield-lic-addseat").style.display = 'block';  
        document.getElementById("submit-button").style.display = 'block';  
        document.getElementById("submit-button1b").style.display = 'none'; 
        document.getElementById("renew-button").style.display = 'none'; 
      }
    } else {   
      document.getElementById("gaddon-area").style.display = 'none';  
    }
  } 
  handleUserSelect = (e) => {
    var value = e.target.value;
    var inputname = e.target.name;
    
    if(inputname === "method_payment") {
      if(value === global.cconfig.methodPayment[4]) {
        document.getElementById("payment-cc-form").style.display = "block";
      } else {
        document.getElementById("payment-cc-form").style.display = "none";
      }
      this.setState( prevState => {
          return {  
              ...prevState.method_payment, method_payment: value
          }
      }, () => { this.validateField(inputname, value, "") })
    } else if(inputname === "is_tnc") {
      this.setState( prevState => {
          return {  
              ...prevState.is_tnc, is_tnc: e.target.checked
          }
      }, () => { this.validateField(inputname, value, "") })
    } else if(inputname === "domain_name") { 
      document.getElementById("gaddon-area").style.display = 'none';
      if(this.state.licence_list.length > 0) {
        var liclist = JSON.parse(this.state.licence_list)?.filter(
          (list) =>  (list.clientliccontent.licenceCategory === "Google Workspace" || list.clientliccontent.licenceCategory === "G Suite")
        );
        for(var x=0; x<liclist.length; x++) {
          document.getElementById("licence_type_"+x).checked = false;
          document.getElementById("licence_input_"+x).style["boxShadow"] = "0 0 0px 0px";
        }
      }
      var arrValue = value.split("$$");
      this.setState( prevState => {
        return {  
            ...prevState.domain_name, domain_name: value,
            ...prevState.current_domainName, current_domainName: arrValue[2],
            ...prevState.licence_list, licence_list: arrValue[4],
            ...prevState.licence_type, licence_type: '',
            ...prevState.licence_country, licence_country: arrValue[3]
        }
      }, () => { 
          this.validateField(inputname, value, "")
      }) 
    }
  }
  handleUserInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    
    if(name==="licence_addseat") { 
      var nextlicenceseat = parseInt(this.state.current_licSeat) + parseInt(value);
      if(this.state.current_licName.includes("Google Workspace Business") === true && nextlicenceseat > 300) {
        this.validateField(name, value, "You've reach the limit user(s) for this SKU. By continuing, our account representative will contact you soon.");
        this.setState({[name]: value});
  
        document.getElementById("submit-button").style.display = 'none';  
        document.getElementById("submit-button1b").style.display = 'block'; 
        document.getElementById("renew-button").style.display = 'none'; 
      } else {
        this.setState({[name]: value},
          () => { this.validateField(name, value, "")});
      }
    } else {
      if(name === "cc_expired") {
        if(isNaN(Number(value.replace('/',''))) === true) { 
          if(value.indexOf("/") > -1) { value = value.replace('/',''); }
          value = this.state.cc_expired; 
        }
        if(value.length === 2 && this.state.cc_expired.length < 3) { value = value+"/"; }
        else if(value.length > 2) { 
          value = value.replace('/','');
          value = value.substring(0,2)+"/"+value.substring(2,value.length);  
        } 
      } else if(name === "cc_cvc") {
        if(value.length > 4) { value = value.substring(0,4); }
        if(isNaN(Number(value)) === true) { value = this.state.cc_cvc; }
      } else if(name === "cc_num") {
        if(isNaN(Number(value)) === true) { value = this.state.cc_num; }
      }
      this.setState({[name]: value},
          () => { this.validateField(name, value, "")});
    }
  } 
  validateField(fieldName, value, errmsg) {
    let fieldValidationErrors = this.state.formErrors;
    let domainNameValid = this.state.domainNameValid;
    let licenceTypeValid = this.state.licenceTypeValid;
    let licenceAddSeatValid = this.state.licenceAddSeatValid;
    let methodPaymentValid = this.state.methodPaymentValid;
    let isTncValid = this.state.isTncValid;
    let cardNumberValid = this.state.cc_num;
    let cardExpiryValid = this.state.cc_expired;
    let cardCvcValid = this.state.cc_cvc;
    let cardHolderNameValid = this.state.cc_cardholder_name;
    let licenceRenewSeatValid = this.state.licence_renewseat;
    let requesterPhoneValid = this.state.requester_phone;

    switch(fieldName) {
        case 'domain_name': 
          domainNameValid = value.trim().length > 0;
          fieldValidationErrors.domain_name = domainNameValid ? '' : 'domain name need to be selected';
          break;
        case 'licence_type': 
          licenceTypeValid = value.trim().length > 0;
          fieldValidationErrors.licence_type = licenceTypeValid ? '' : 'google workspace licence need to be selected';
          break;
        case 'licence_addseat': 
          if(errmsg !== "") { 
            fieldValidationErrors.licence_addseat = errmsg;
          } else {
            licenceAddSeatValid = value.trim().length > 0;
            fieldValidationErrors.licence_addseat = licenceAddSeatValid ? '' : 'licences amount need to be filled';
          }
          break;
        case 'method_payment': 
          methodPaymentValid = value.trim().length > 0;
          fieldValidationErrors.method_payment = methodPaymentValid ? '' : 'field cannot be empty';
          break;
        case 'is_tnc': 
          isTncValid = value !== false;
          fieldValidationErrors.is_tnc = isTncValid ? '' : 'please accept the terms and condition';
          break;
        case 'cc_num': 
          cardNumberValid = value.trim().length > 0;
          fieldValidationErrors.cc_num = cardNumberValid ? '' : 'card information need to be filled';
          break;
        case 'cc_expired': 
          cardExpiryValid = value.trim().length > 0;
          fieldValidationErrors.cc_expired = cardExpiryValid ? '' : 'card information need to be filled';
          break;
        case 'cc_cvc': 
          cardCvcValid = value.trim().length > 0;
          fieldValidationErrors.cc_cvc = cardCvcValid ? '' : 'card information need to be filled';
          break;
        case 'cc_cardholder_name': 
          cardHolderNameValid = value.trim().length > 0;
          fieldValidationErrors.cc_cardholder_name = cardHolderNameValid ? '' : 'card information need to be filled';
          break;
        case 'licence_renewseat': 
          licenceRenewSeatValid = value.trim().length > 0;
          fieldValidationErrors.licence_renewseat = licenceRenewSeatValid ? '' : 'licence amount need to be filled';
          break;
        case 'requester_phone': 
          requesterPhoneValid = value.trim().length > 0;
          fieldValidationErrors.requester_phone = requesterPhoneValid ? '' : 'contact phone number need to be filled';
          break;
        default:
            break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    domainNameValid: domainNameValid,
                    licenceTypeValid: licenceTypeValid,
                    licenceAddSeatValid: licenceAddSeatValid,
                    methodPaymentValid: methodPaymentValid,
                    isTncValid: isTncValid,
                    cardNumberValid: cardNumberValid,
                    cardExpiryValid: cardExpiryValid,
                    cardCvcValid: cardCvcValid,
                    cardHolderNameValid: cardHolderNameValid,
                    licenceRenewSeatValid: licenceRenewSeatValid,
                    requesterPhoneValid: requesterPhoneValid
                }, this.validateForm);                            
  }
  validateForm() {
    this.setState({formValid: this.state.domainNameValid && this.state.licenceTypeValid && this.state.licenceAddSeatValid  && this.state.methodPaymentValid  && this.state.isTncValid  && this.state.cardNumberValid && this.state.cardExpiryValid && this.state.cardCvcValid && this.state.cardHolderNameValid && this.state.licenceRenewSeatValid  && this.state.requesterPhoneValid });
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }

  getLicenceType(country,licTypeName) {
    return new Promise(resolve => {
      document.getElementById('loading-area').style.display = 'block'; 
      var datatext = '{"keyword":"'+country.toLowerCase()+'","licence_type":"'+licTypeName+'","pageShow":"10"}'; 
      var data = JSON.parse(datatext); 
      fetch(global.cconfig.urlLink+"/licencetypelist", {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey2,
          }, body : JSON.stringify(data)
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') {
            resolve({priceSeat: resJson.values[0].content.licencePrice, priceCurrency: resJson.values[0].content.licenceCurrency});
          } else {
            resolve({priceSeat: 1000000, priceCurrency: 'USD'});
          }
        });
    })
  }

  async sendingEmail() {
      var arrDomainName = this.state.domain_name.split("$$");
      var arrLicence = this.state.licence_type.split("$$");

      // var renewalsMonths = DateTimeFormats(this.state.current_licExpiredBillCycle,"month-long") + " " + DateTimeFormats(this.state.current_licExpiredBillCycle,"year-long");
      var renewalsMonths = DateTimeFormats(this.state.current_licExpiredBillCycle,"date-long");
      var methodmessage = "";
      if (this.state.method_payment === global.cconfig.methodPayment[0]) {
          // methodmessage = "PayPal (the above amount has to reach our PayPal account within 24 hours, failing which this order might be delayed or cancelled)";
          methodmessage = "PayPal";
      } else if (this.state.method_payment === global.cconfig.methodPayment[2]) {
          // methodmessage = "PayNow (the above amount has to reach our Paynow account within 24 hours, failing which this order might be delayed or cancelled)";
          methodmessage = "PayNow (licence will only be provisioned upon payment received)";
          methodmessage += '<br/><strong>UEN No: 200702445Z</strong>';
      } else if (this.state.method_payment === global.cconfig.methodPayment[3]) {
          // methodmessage = "Wire/Bank Transfer (the above amount has to reach our bank account within 72 hours, failing which this order might be delayed or cancelled)";
          methodmessage = "Wire/Bank Transfer (licence will only be provisioned upon payment received)";
          if(this.state.current_licCurrency === "SGD") { 
            methodmessage += '<br/>Transfer your payment to:<br/><strong>Bank Name: The Hongkong and Shanghai Banking Corporation Limited<br />Account Name : PointStar Pte Ltd <br />Bank Code: 7232<br />HSBC Swift Code: HSBCSGSG<br />Account Number: 147-731533-001</strong>';
          } else if(this.state.current_licCurrency === "USD") {
            methodmessage += '<br/>Transfer your payment to:<br/><strong>Bank Name: The Hongkong and Shanghai Banking Corporation Limited<br />Account Name : PointStar Pte Ltd <br />Bank Code: 7232<br />HSBC Swift Code: HSBCSGSG<br />Account Number: 260-659453-178</strong>';
          }
      } else if (this.state.method_payment === global.cconfig.methodPayment[4]) {
          // methodmessage = "Credit Card (the above amount has to reach our account within 24 hours, failing which this order might be delayed or cancelled)";
          methodmessage = "Credit Card";
            
      } else { 
          methodmessage = "Cheque (the cheque has to reach our office within 72 hours, failing which this order might be delayed or cancelled)";
      }
      
      var template = require('../cinc/ctemplate.js');
      
      // var from_email = "melia@point-star.com";
      // var to_admin_email = "melia@demo.point-star.com";
      var from_email = "license@point-star.com";
      var to_admin_email = "accountmanagement@point-star.com";
      if(window.location.hostname === 'localhost' || window.location.hostname === 'ps-product-internalportal.firebaseapp.com') {
        from_email = "melia@point-star.com";
        to_admin_email = "melia@demo.point-star.com";
      }
      var to_cust_email = this.state.requester_email;
      var subject_email = "", body_email = "";
      var subject_cust_email, body_cust_email;
      var subject_admin_email, body_admin_email;
      var arrTemplate = [
        template.message("admin", this.state.licence_type, this.state.request_vault, this.state.tax_value_rp, this.state.licence_country),
        template.message("customer", this.state.licence_type, this.state.request_vault, this.state.tax_value_rp, this.state.licence_country)        
      ];
    for(var s=0; s<arrTemplate.length; s++) {
        subject_email =  arrTemplate[s][0]; 
        body_email = arrTemplate[s][1];
        
        subject_email = ReplaceAllContent('[[domainname]]',arrDomainName[2],subject_email);
        subject_email = ReplaceAllContent('[[licenseval]]',this.state.licence_addseat,subject_email);
        subject_email = ReplaceAllContent('[[packageTypeName]]',arrLicence[1],subject_email);
        subject_email = ReplaceAllContent('[[grandtotal]]',NumberFormats(this.state.grand_total),subject_email);
        subject_email = ReplaceAllContent('[[licenceCurrency]]',this.state.current_licCurrency,subject_email);
        
        body_email = ReplaceAllContent('[[packageTypeName]]',arrLicence[1],body_email);
        // body_email = ReplaceAllContent('[[contactname]]',localStorage.getItem("cufullname"),body_email);
        body_email = ReplaceAllContent('[[contactname]]',this.state.requester_name,body_email);
        body_email = ReplaceAllContent('[[contactemail]]',this.state.requester_email,body_email);
        body_email = ReplaceAllContent('[[contactphone]]',this.state.requester_phone,body_email);

        body_email = ReplaceAllContent('[[domainname]]',arrDomainName[2],body_email);
        body_email = ReplaceAllContent('[[companyname]]',this.state.client_name,body_email);
        // body_email = ReplaceAllContent('[[country]]',this.state.licence_country,body_email);
        // body_email = ReplaceAllContent('[[licmonthval]]','',body_email);
        // body_email = ReplaceAllContent('[[renDate]]',DateTimeFormats(this.state.current_licExpired,"date-short"),body_email);
        body_email = ReplaceAllContent('[[methodmessage]]',methodmessage,body_email);
        
        body_email = ReplaceAllContent('[[licenceCurrency]]',this.state.current_licCurrency,body_email);
        body_email = ReplaceAllContent('[[licenceMonthRate]]',this.state.licence_month_rate,body_email);
        body_email = ReplaceAllContent('[[licenceMonthRenewal]]',renewalsMonths,body_email);
        
        body_email = ReplaceAllContent('[[licenseval]]',this.state.licence_addseat,body_email);
        body_email = ReplaceAllContent('[[licenceSeatRp_output]]',NumberFormats((this.state.current_licPrice*12)),body_email);
        body_email = ReplaceAllContent('[[licenseTotalRp_output]]',NumberFormats(this.state.licence_addseat_rp),body_email);

        body_email = ReplaceAllContent('[[vaultval]]',this.state.request_vault,body_email);
        body_email = ReplaceAllContent('[[vaultSeatRp_output]]',this.state.current_vault_price,body_email);
        body_email = ReplaceAllContent('[[vaultTotal_output]]',NumberFormats(this.state.request_vault_rp),body_email);

        body_email = ReplaceAllContent('[[taxRatePersen]]',this.state.tax_label_rate,body_email);
        body_email = ReplaceAllContent('[[licenceSubtotalRp_output]]',NumberFormats(this.state.grand_total - this.state.tax_value_rp),body_email);
        body_email = ReplaceAllContent('[[taxRp_output]]',NumberFormats(this.state.tax_value_rp),body_email);
        body_email = ReplaceAllContent('[[grandtotal]]',NumberFormats(this.state.grand_total),body_email);
        
        // body_email = ReplaceAllContent('[[licensevalRate_output]]',NumberFormats(this.state.licence_addseat_rate),body_email);
        // body_email = ReplaceAllContent('[[vaultvalRate_output]]',NumberFormats(this.state.request_vault_rate),body_email);

        // body_email = ReplaceAllContent('[[vaultvalPrice_output]]',NumberFormats(this.state.current_vault_price),body_email);
        
        if(s===0) { 
            subject_admin_email =  subject_email; 
            body_admin_email = body_email;
        } else { 
            subject_cust_email =  subject_email; 
            body_cust_email = body_email;
        } 
    }  
    
    var data_admin = { to: to_admin_email, from: from_email, subject: subject_admin_email, Message: body_admin_email };
    var data_customer = { to: to_cust_email, from: from_email, subject: subject_cust_email, Message: body_cust_email };
    
    
    var [action1, action2] = await Promise.all([sendmail(data_customer), sendmail(data_admin)]);
    if(action1 && action2) {
        var replymsg = MessageThx(this.state.current_licCurrency,this.state.grand_total,arrDomainName[2],this.state.current_licName,this.state.method_payment);
        document.getElementById('thankyou-page-msg').style.display = 'block';
        document.getElementById('thankyou-page-msg').innerHTML = replymsg;

        document.getElementById('loading-area').style.display = 'none'; 
        document.getElementById('form-s1-content').style.display = 'none';
        document.getElementById('form-s2-content').style.display='none';
        document.getElementById('error-page').style.display = 'none';
    }

    // fetch(URL_SENDMAIL, {
    //   method: 'POST',
    //   headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json',
    //       'Access-Control-Allow-Origin': '*',
    //   }, body : JSON.stringify(data_customer)
    //   }).then(res => {
    //       if(res.status === 200) { return res.json();  }
    //   }).then( resJson => {
    //     // var replymsg = MessageThx(this.state.current_licCurrency,this.state.grand_total,this.state.current_domainName,this.state.current_licName,this.state.method_payment);
    //     var replymsg = MessageThx(this.state.current_licCurrency,this.state.grand_total,arrDomainName[2],this.state.current_licName,this.state.method_payment);
    //     document.getElementById('thankyou-page-msg').style.display = 'block';
    //     document.getElementById('thankyou-page-msg').innerHTML = replymsg;
    //   })
  }
  handleSubmit4(e) {
    e.preventDefault();
    if(this.state.licence_addseat !== "") { 
      document.getElementById('loading-area').style.display='block';
      document.getElementById('form-s1-content').style.display='none';
      document.getElementById('form-s2-content').style.display = 'none';
      document.getElementById('form-s2b-content').style.display = 'none';

      var arrDomainName4 = this.state.domain_name.split("$$");
      var arrLicence4 = this.state.licence_type.split("$$");
      var orderId4 = "AD"+new Date().getTime()+RandomNumber(101,999);
      var urlFetch4, datatext4;
      datatext4 ='{"client_id":"'+arrDomainName4[0]+'","client_name":"'+arrDomainName4[1]+'","domain_name":"'+arrDomainName4[2]+'","client_country":"'+arrDomainName4[3]+'","licence_id":"'+arrLicence4[0]+'","licence_type":"'+arrLicence4[1]+'","licence_addseat":"'+this.state.licence_addseat+'","member_id":"'+this.state.memberdoc_id+'","form_id":"form-add-addlicence","licence_addon":"'+this.state.licence_addon_list+'","licence_addon_seat":"'+this.state.licence_addon_seat+'","method_payment":"'+this.state.method_payment+'","licence_currency":"'+this.state.current_licCurrency+'","gtotal_order":"'+this.state.grand_total+'","card_number":"'+this.state.cc_num+'","card_expired":"'+this.state.cc_expired+'","card_cvc":"'+this.state.cc_cvc+'","cardholder_name":"'+this.state.cc_cardholder_name+'","order_id":"'+orderId4+'"}';
      urlFetch4 = global.cconfig.urlLink+"/addreqaddlicence";
  
      var data4 = JSON.parse(datatext4); 
      fetch(urlFetch4, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }, body : JSON.stringify(data4)
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') {
              document.getElementById('thankyou-page-p0').style.display = 'block';
              document.getElementById('error-page').style.display = 'none';
          } else {
              document.getElementById('thankyou-page-p0').style.display = 'none';
              document.getElementById('error-page').style.display = 'block';
              console.log('Something happened wrong');
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
          document.getElementById('thankyou-page-p0').style.display = 'none';
          document.getElementById('error-page').style.display = 'block';
          console.log(err);
        }) ;
    } else {
      var arrfieldName4 = ['licence_addseat'];
      var arrvalue4 = [this.state.licence_addseat];
      for(var ck4 in arrfieldName4) {
          this.validateField(arrfieldName4[ck4], arrvalue4[ck4],"");
      }
    }
    
  }
  handleSubmit3(e) {
    e.preventDefault();
    if(this.state.licence_renewseat !== "") { 
      document.getElementById('loading-area').style.display = 'block'; 
      document.getElementById('form-s1-content').style.display='none';
      document.getElementById('form-s2-content').style.display = 'none';
      document.getElementById('form-s2b-content').style.display = 'none';

      var arrDomainName3 = this.state.domain_name.split("$$");
      var arrLicence3 = this.state.licence_type.split("$$");
     
      var urlFetch, datatext;
      datatext ='{"client_id":"'+arrDomainName3[0]+'","client_name":"'+arrDomainName3[1]+'","domain_name":"'+arrDomainName3[2]+'","client_country":"'+arrDomainName3[3]+'","licence_id":"'+arrLicence3[0]+'","licence_type":"'+arrLicence3[1]+'","licence_addseat":"'+this.state.licence_renewseat+'","member_id":"'+this.state.memberdoc_id+'","form_id":"form-add-renewlicence","licence_addon":"'+this.state.licence_addon_list+'","licence_addon_seat":"'+this.state.licence_addon_seat+'"}';
      urlFetch = global.cconfig.urlLink+"/addreqrenewlicence";
  
      var data = JSON.parse(datatext); 
      fetch(urlFetch, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey4,
          }, body : JSON.stringify(data)
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') {
              document.getElementById('thankyou-page-p0').style.display = 'block';
              document.getElementById('error-page').style.display = 'none';
          } else {
              document.getElementById('thankyou-page-p0').style.display = 'none';
              document.getElementById('error-page').style.display = 'block';
              console.log('Something happened wrong');
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
          document.getElementById('thankyou-page-p0').style.display = 'none';
          document.getElementById('error-page').style.display = 'block';
          console.log(err);
        }) ;
    } else {
      var arrfieldName3 = ['licence_renewseat'];
      var arrvalue3 = [this.state.licence_renewseat];
      for(var ck3 in arrfieldName3) {
          this.validateField(arrfieldName3[ck3], arrvalue3[ck3],"");
      }
    }
    
  }
  handleSubmit2(e) {
    e.preventDefault();
    var allow=true;
    if(this.state.method_payment === global.cconfig.methodPayment[4]) { if(this.state.cc_number !== "" && this.state.cc_expired !== "" && this.state.cc_cvc !== "" && this.state.cc_cardholder_name !== "") { allow=true; } else { allow=false;} }
    if(this.state.method_payment !== '' && this.state.is_tnc !== false && allow === true) {
    
      document.getElementById('loading-area').style.display='block';
      document.getElementById('form-s1-content').style.display='none';
      document.getElementById('form-s2-content').style.display = 'none';

      var arrDomainName = this.state.domain_name.split("$$");
      var arrLicence = this.state.licence_type.split("$$");
      var orderId = "AD"+new Date().getTime()+RandomNumber(101,999);
      var urlFetch, datatext;
      datatext ='{"client_id":"'+arrDomainName[0]+'","client_name":"'+arrDomainName[1]+'","domain_name":"'+arrDomainName[2]+'","client_country":"'+arrDomainName[3]+'","licence_id":"'+arrLicence[0]+'","licence_type":"'+arrLicence[1]+'","licence_addseat":"'+this.state.licence_addseat+'","member_id":"'+this.state.memberdoc_id+'","form_id":"form-add-addlicence","licence_addon":"'+this.state.licence_addon_list+'","licence_addon_seat":"'+this.state.licence_addon_seat+'","method_payment":"'+this.state.method_payment+'","licence_currency":"'+this.state.current_licCurrency+'","gtotal_order":"'+this.state.grand_total+'","card_number":"'+this.state.cc_num+'","card_expired":"'+this.state.cc_expired+'","card_cvc":"'+this.state.cc_cvc+'","cardholder_name":"'+this.state.cc_cardholder_name+'","order_id":"'+orderId+'"}';
      urlFetch = global.cconfig.urlLink+"/addreqaddlicence";
  
      var data = JSON.parse(datatext); 
      fetch(urlFetch, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }, body : JSON.stringify(data)
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('form-s1-content').style.display = 'none';
          if(resJson.status === '200') {
              document.getElementById('error-page').style.display = 'none';

              if(this.state.method_payment === global.cconfig.methodPayment[0]) {
                var returnsuccess = window.location.hostname+"/caddlicence/licencesuccess/";
                var returncancel = window.location.hostname+"/caddlicence";
                if(window.location.hostname === 'localhost') {
                  returnsuccess = "http://localhost:5000/caddlicence/licencesuccess/";
                  returncancel = "http://localhost:5000/caddlicence";
                }
                var linkURL_Paypal = 'https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=admin@point-star.com&lc=SG&item_name=PointStar-'+arrDomainName[2]+'&item_number=cg-'+arrDomainName[2]+'&amount='+this.state.grand_total+'&currency_code='+this.state.current_licCurrency+'&button_subtype=services&no_note=1&no_shipping=1&rm=1&return='+returnsuccess+'&cancel_return='+returncancel;
                document.location.href= linkURL_Paypal;
              } else {
                if(this.state.method_payment === global.cconfig.methodPayment[4]) { 
                  var urlFetch2, datatext2;
                  datatext2 ='{"request_id":"'+resJson.values[3]+'","client_id":"'+arrDomainName[0]+'","licence_id":"'+arrLicence[0]+'","update_status":"'+global.cconfig.requestStatus[6]+'","form_id":"form-update-status-addlicence"}';
                  urlFetch2 = global.cconfig.urlLink+"/updatereqaddlicence";
            
                  var data2 = JSON.parse(datatext2); 
                  fetch(urlFetch2, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey3,
                    }, body : JSON.stringify(data2)
                    }).then(res => {
                        if(res.status === 200) { return res.json();  }
                    }).then( resJson => {
                      this.sendingEmail();
                    }).catch(err => {
                      document.getElementById('loading-area').style.display = 'none'; 
                      document.getElementById('form-s1-content').style.display = 'none';
                      document.getElementById('form-s2-content').style.display='none';
                      document.getElementById('thankyou-page-msg').style.display = 'none';
                      document.getElementById('error-page').style.display = 'block';
                      console.log(err);
                    })
                } else {
                  this.sendingEmail();
                }
              }
          } else {
            if(this.state.method_payment === global.cconfig.methodPayment[4]) { 
              var urlFetch3, datatext3;
              datatext3 ='{"request_id":"'+resJson.values[3]+'","client_id":"'+arrDomainName[0]+'","licence_id":"'+arrLicence[0]+'","update_status":"'+global.cconfig.requestStatus[7]+'","form_id":"form-update-status-addlicence"}';
              urlFetch3 = global.cconfig.urlLink+"/updatereqaddlicence";
        
              var data3 = JSON.parse(datatext3); 
              fetch(urlFetch3, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey3,
                }, body : JSON.stringify(data3)
                }).then(res3 => {
                    if(res3.status === 200) { return res3.json();  }
                }).then( resJson3 => {
                  document.getElementById('loading-area').style.display='none';
                  document.getElementById('form-s1-content').style.display = 'none';
                  document.getElementById('form-s2-content').style.display='none';
                  document.getElementById('thankyou-page-msg').style.display = 'none';
                  document.getElementById('error-page').style.display = 'block';
                  console.log('Something happened wrong');
                  console.log(resJson3);
                }).catch(err => {

                  document.getElementById('loading-area').style.display='none';
                  document.getElementById('form-s1-content').style.display = 'none';
                  document.getElementById('form-s2-content').style.display='none';
                  document.getElementById('thankyou-page-msg').style.display = 'none';
                  document.getElementById('error-page').style.display = 'block';
                  console.log('Something happened wrong');
                  console.log(err);
                })
            } else {
              document.getElementById('loading-area').style.display='none';
              document.getElementById('form-s1-content').style.display = 'none';
              document.getElementById('form-s2-content').style.display='none';
              document.getElementById('thankyou-page-msg').style.display = 'none';
              document.getElementById('error-page').style.display = 'block';
              console.log('Something happened wrong');
              console.log(resJson);
            }
              
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
          document.getElementById('form-s1-content').style.display = 'none';
          document.getElementById('form-s2-content').style.display='none';
          document.getElementById('thankyou-page-msg').style.display = 'none';
          document.getElementById('error-page').style.display = 'block';
          console.log(err);
        }) ;
        
    } else {
      var arrfieldName = ['method_payment','is_tnc'];
      var arrvalue = [this.state.method_payment,this.state.is_tnc];
      if(this.state.method_payment === global.cconfig.methodPayment[4]) { 
        if(this.state.cc_num === "") { arrfieldName.push("cc_num"); arrvalue.push(this.state.cc_num); } 
        if(this.state.cc_expired === "") { arrfieldName.push("cc_expired"); arrvalue.push(this.state.cc_expired); } 
        if(this.state.cc_cvc === "") { arrfieldName.push("cc_cvc"); arrvalue.push(this.state.cc_cvc); }  
        if(this.state.cc_cardholder_name === "") { arrfieldName.push("cc_cardholder_name"); arrvalue.push(this.state.cc_cardholder_name); }  
        
      }
      for(var ck in arrfieldName) {
          this.validateField(arrfieldName[ck], arrvalue[ck],"");
      }
    }
  }


  async handleSubmit1(e) {
    e.preventDefault();
    
    var arrLicence = this.state.licence_type.split("$$");
    var checkedAddOn = false;
    var licenceAddOnList = "";
    var licenceAddOnSeat = "";
    if(arrLicence[1] === global.cconfig.typeLicenceAddon) {
      for(var al=0; al<this.state.licence_addon_list.length; al++) {
        if(licenceAddOnList !== "") { licenceAddOnList += "$$"; }
        licenceAddOnList += this.state.licence_addon_list[al].label+"|"+this.state.licence_addon_list[al].isChecked;
        if(this.state.licence_addon_list[al].isChecked === true) { checkedAddOn = true; }
      }
      for(var as=0; as<this.state.licence_addon_seat.length; as++) {
        if(licenceAddOnSeat !== "") { licenceAddOnSeat += "$$"; }
        var seatval = 0;
        if(this.state.licence_addon_seat[as].value !== "") { seatval = this.state.licence_addon_seat[as].value; }
        licenceAddOnSeat += this.state.licence_addon_seat[as].label+"|"+seatval;
      }
    }
    var amountAddSeat = "";
    if(this.textInputLicenceAddSeat.value === '') { amountAddSeat = this.state.licence_addseat; } else { amountAddSeat = this.textInputLicenceAddSeat.value; }
    
    if(checkedAddOn === true) {
      if(amountAddSeat === "") { amountAddSeat = 0; }
    } 
    if(this.state.domain_name !== '' && this.state.licence_type !== '' && amountAddSeat !== '' && this.state.requester_phone !== '') {
        document.getElementById('form-s1-content').style.display='none';
        
        var licencePrice=1000000,licencePriceCurrency="USD";
        if(arrLicence[4] !== undefined && arrLicence[4] !== "undefined" && arrLicence[4] !== "") {
          licencePrice = arrLicence[4];
          licencePriceCurrency = arrLicence[5];
        } else {
          var [resLicence] = await new Promise.all([this.getLicenceType(this.state.licence_country.toLowerCase(),arrLicence[1])]); 
          licencePrice = resLicence[0].priceSeat;
          licencePriceCurrency = resLicence[0].priceCurrency;
        }
        if(licencePrice < 0) { licencePrice = 1000000; }

        // start - Calculate 
        
        var reqrenew = false;
        var currlicDurationBillCycle = Number(this.state.current_licDurationBillCycle);
        var currlicExpiredBillCycle = this.state.current_licExpiredBillCycle;
        var currDate = new Date();
        var datenow_ms = currDate.getTime();
        var dateexpbill_ms = currlicExpiredBillCycle * 1000;
        var datediff_ms = dateexpbill_ms - datenow_ms;
        var datein_day = Math.ceil((datediff_ms/1000/60/60)/24);
        if(datediff_ms < 0) {
          // open message need request renew
          reqrenew = true;
        } else {
          if(datein_day < 14 && (this.state.current_statInvoice === 'NotSend')) {
            reqrenew = true;
          }
        }
        if(reqrenew === true) {
          document.getElementById("form-s2b-content").style.display = "flex";
          var nextsuggestrenewseat=Number(this.state.current_licSeat) + Number(this.state.licence_addseat);
          this.setState( prevState => {
            return {  
                ...prevState.licence_renewseat, licence_renewseat: nextsuggestrenewseat
            }
          });
        } else {
          document.getElementById('form-s2-content').style.display='block';
            var renmonth =  Math.round(datein_day/30); 
            var dateLicExpBillCycle = new Date(dateexpbill_ms);
            var licmonth = dateLicExpBillCycle.getMonth(), licyear = dateLicExpBillCycle.getFullYear(), licday = dateLicExpBillCycle.getDate();
            var monthnow = currDate.getMonth(), yearnow = currDate.getFullYear(), daynow = currDate.getDate();
            var addrenmonth = 1;
            if(licyear === yearnow) {
              var daydiff_s = 0;
              if(licday >= daynow) { daydiff_s = licday-daynow; }
              else { 
                var lastday = new Date(licyear, (licmonth+1) +1, 0).getDate(); 
                daydiff_s = (lastday - daynow)+licday; 
              }
              if(licmonth === monthnow) { 
                renmonth = addrenmonth;
              } else if(licmonth > monthnow) {
                if(licday<daynow) { addrenmonth=0;}
                renmonth = (licmonth - monthnow)+addrenmonth;
              } else {
                if(daydiff_s<0) { addrenmonth=0;}
                renmonth = currlicDurationBillCycle+addrenmonth;
              }
	          } else if(licyear > yearnow) {
	            var daydiff_s2 = licday-daynow;
              if(licmonth === monthnow) { 
                if(daydiff_s2<0) { addrenmonth=0;}
                renmonth = currlicDurationBillCycle+addrenmonth;
              } else if(licmonth > monthnow) {
                if(licday<daynow) { addrenmonth=0;}
                renmonth = (12-monthnow) + licmonth +addrenmonth;
              } else {
                if(daydiff_s2<0) { addrenmonth=0;}
                renmonth = (12-monthnow) + licmonth +addrenmonth;
              }
            }     
          // === ## Mid - V2 ## ============================

          var proratevaultdef = 50, vaultPrice = proratevaultdef/12; 
          if(this.state.current_licName === global.cconfig.typeLicenceAddon) { vaultPrice = 6; }
          else { vaultPrice = 12; }
          
          var enableVault = this.state.enable_vault;
          var enablevaultRes = enableVault;
          var requestVault =this.state.request_vault; 

          if(this.state.current_licName === "G Suite Basic") {
            if (enableVault === "No"){ enablevaultRes = "No";} 
            else if (enableVault === "Yes"){ enablevaultRes = "Yes"; } 
            else { enablevaultRes = "No"; }
          } else {
            enablevaultRes = "No";
          }
          if(enablevaultRes === "No") { requestVault = 0; }
          if(requestVault < 0) { requestVault = 0; }
          
          var prorate = RoundDecimal((licencePrice) * renmonth);
          var proratevault = RoundDecimal((vaultPrice) * renmonth);
          
          var licenseSeatRp = RoundDecimal(amountAddSeat * prorate);
          var vaultRp = RoundDecimal(requestVault * proratevault);
      
          //calculate tax
          var tax = 0, taxrate=0, taxlabel="";
          for(var t=0; t<global.cconfig.taxRate.length; t++) {
            taxrate = Number(global.cconfig.taxRate[t].value);
            taxlabel = global.cconfig.taxRate[t].label;
            if (this.state.licence_country.toLowerCase() === global.cconfig.taxRate[t].country) { tax = RoundDecimal((licenseSeatRp + vaultRp)*taxrate); break; } 
          }
          
          
          // calculate grand total
          var pTotal  = RoundDecimal(licenseSeatRp + vaultRp);
          var gTotal  = RoundDecimal(pTotal + tax);
          
          // end - Calculate 
          var arrDomainName = this.state.domain_name.split("$$");
          
          this.setState({
            memberdoc_id: this.state.memberdoc_id,
            client_id: arrDomainName[0],
            client_name: arrDomainName[1],
            domain_name: this.state.domain_name,
            licence_country: arrDomainName[3],
            
            licence_id: arrLicence[0],
            licence_type: this.state.licence_type,
            licence_addseat: amountAddSeat,        
            current_licName: this.state.current_licName,
            current_licSeat: this.state.current_licSeat,
            current_licExpired: this.state.current_licExpired,
            current_domainName: arrDomainName[2],
            current_licCurrency: licencePriceCurrency,
            current_licPrice: licencePrice,
            current_licExpiredBillCycle:  this.state.current_licExpiredBillCycle,
            current_statInvoice: this.state.current_statInvoice,

            company_list: this.state.company_list,
            licence_list: this.state.licence_list,
            licence_addon_list: this.state.licence_addon_list, //licenceAddOnList,
            licence_addon_seat: licenceAddOnSeat,
            request_list: this.state.request_list,
            
            enable_vault: 'No',
            request_vault: requestVault,
            // request_vault_rate: proratevault,
            current_vault_price: vaultPrice,
            request_vault_rp: vaultRp,
            
            licence_month_rate: renmonth,
            licence_addseat_rate: prorate,
            licence_addseat_rp: licenseSeatRp,
            tax_label_rate: taxlabel,
            tax_value_rate: taxrate,
            tax_value_rp: tax,
            price_total: pTotal,
            grand_total: gTotal,
            method_payment: '',
            is_tnc: false,
            cc_num: '',
            cc_expired: '',
            cc_cvc: '',
            cc_cardholder_name: '',
            licence_renewseat: '',
            formErrors: {domain_name: '',licence_type: '',licence_addseat: '', method_payment: '', is_tnc: '', cc_num: '', cc_expired: '', cc_cvc: '', cc_cardholder_name: '', licence_renewseat: '', requester_phone: ''},
            domainNameValid: false,
            licenceTypeValid: false,
            licenceAddSeatValid: false,
            methodPaymentValid: false,
            isTncValid: false,
            cardNumberValid: false,
            cardExpiryValid: false,
            cardCvcValid: false,
            licenceRenewSeatValid: false,
            formValid: false,
            errmsg: '',
            isSignin: true
          });
      }

    } else {
      var arrfieldName = ['domain_name','licence_type','licence_addseat','requester_phone'];
      var arrvalue = [this.textInputDomainName.value,this.state.licence_type,this.textInputLicenceAddSeat.value,this.textInputRequesterPhone.value];
      for(var ck in arrfieldName) {
          this.validateField(arrfieldName[ck], arrvalue[ck],"");
      }
    }
  }

  componentDidMount() {
    document.title = global.cconfig.webtitle;
    document.getElementById('loading-area').style.display = 'block'; 
    window.addEventListener("resize", this.resize.bind(this));
    
    this.resize();
    CheckLogin().then((statL) =>{ 
      if(statL === true) {
        document.getElementById('form-s1-content').style.display = 'block'; 
        var arrLicenceAddOn = [];
        var arrLicenceAddOnSeat = [];
        var addontype = global.cconfig.addonLicence;
        for(var dt=0; dt<addontype.length; dt++) {
          arrLicenceAddOn = arrLicenceAddOn.concat({'id':dt+1,'fieldId':'licence_addon_'+(dt+1),'value':addontype[dt],'label':addontype[dt],'isChecked': false,'isDisable': false});
          arrLicenceAddOnSeat = arrLicenceAddOnSeat.concat({'id':dt+1,'fieldId':'licence_addon_seat_'+(dt+1),'value':'','label':addontype[dt]});
        }
        fetch(global.cconfig.urlLink+"/infolicence/"+this.state.memberdoc_id, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') { 
            var companyList = resJson.values.companyData;
            var country_Def = companyList[0].clientcontent.internalRegion;
            var domainName_Def = companyList[0].clientdocid+"$$"+companyList[0].clientcontent.clientName+"$$"+companyList[0].clientcontent.domainName+"$$"+companyList[0].clientcontent.internalRegion+"$$"+JSON.stringify(companyList[0].clientlicencelist);
            var licenceList_Def = JSON.stringify(companyList[0].clientlicencelist);
            var memberPhone =  resJson.values.memberData[0].content[0].maincontent.memberPhone;
            if(memberPhone === "-") { memberPhone = ""; }
            this.setState({
              memberdoc_id: resJson.values.memberData[0].docid,
              requester_name: resJson.values.memberData[0].content[0].maincontent.memberName,
              requester_email: resJson.values.memberData[0].content[0].maincontent.memberEmail,
              requester_phone: memberPhone,
              domain_name: domainName_Def,
              licence_type: '',
              licence_addseat: '',
              company_list: companyList,
              licence_list: [licenceList_Def],
              licence_addon_list: arrLicenceAddOn,
              licence_addon_seat: arrLicenceAddOnSeat,
              request_list: resJson.values.memberData[0].spcontent,
              current_licName: '',
              current_licSeat: 0,
              current_licExpired: '',
              current_domainName: '',
              licence_country: country_Def,
              licence_renewseat: '',
              formErrors: {domain_name: '',licence_type: '',licence_addseat: '', method_payment: '', is_tnc: '', cc_num: '', cc_expired: '', cc_cvc: '', cc_cardholder_name: '', licence_renewseat: '', requester_phone: ''},
              domainNameValid: false,
              licenceTypeValid: false,
              licenceAddSeatValid: false,
              licenceRenewSeat: false,
              formValid: false,
              errmsg: '',
              isSignin: true
            });
          } else {
            document.getElementById('loading-area').style.display = 'none'; 
            console.log('Something happened wrong');
          }
        }).catch(err => { 
          document.getElementById('loading-area').style.display = 'none'; 
          console.log(err);
        });
      } else {
        window.location.href="/clogin";
      }
    });
  } 
  requestlist() {
    var arrRequestList = [];
    if(this.state.request_list.length > 0) {
      var limitlen = this.state.request_list.length;
      if(limitlen > 5) { limitlen = 5; }
      for(var ls3=0; ls3<limitlen; ls3++) {
        var cDatez="";
        if(this.state.request_list[ls3].specialcontent.createDate !== undefined) {
            cDatez = DateTimeFormats(this.state.request_list[ls3].specialcontent.createDate._seconds,"datetime-long");
        }
        arrRequestList.push(<div className="list-request" key={ls3+1}>
            <div className="detail-list w100p cursor-on" onClick={this.urlmore.bind(this)}>
              <span className="title-request">{this.state.request_list[ls3].specialcontent.licenceType}</span><br/>
              <span className="content-request">{this.state.request_list[ls3].specialcontent.domainName}</span><br/>
              <span className="content-request">request <span className="textBold">{this.state.request_list[ls3].specialcontent.licenceAddSeat}</span> seat(s)</span><br/>
              <span className="date-request">{cDatez} by {this.state.request_list[ls3].specialcontent.createByName}</span>
              <span className="smcontent-request divRight mgT10">status : <span className="mgL10" style={{fontWeight:"500", lineHeight:"1.2"}}>{this.state.request_list[ls3].specialcontent.requestStatus}</span></span>
            </div>
          </div>)
      }
      if(this.state.request_list.length > limitlen) {
        arrRequestList.push(<div key={ls3+1}>
            <div className="w100p divCenter more-request" onClick={this.urlmore.bind(this)}>
              more »
            </div>
          </div>)
      }
    } else {
      arrRequestList.push(<div key="0" className="list-request textCenter">
            <div className="detail-list w100p"><span className="content-request">no record</span></div>
          </div>)
    }
    return arrRequestList;
  }
  inputdomainlist() {
    var arrinputDomainList = [];
    if(this.state.company_list.length > 0) {
      for(var ls2=0; ls2<this.state.company_list.length; ls2++) {
        arrinputDomainList.push(<option key={ls2+1} value={
          this.state.company_list[ls2].clientdocid+"$$"+
          this.state.company_list[ls2].clientcontent.clientName+"$$"+
          this.state.company_list[ls2].clientcontent.domainName+"$$"+
          // this.state.company_list[ls2].clientcontent.clientCountry+"$$"+
          this.state.company_list[ls2].clientcontent.internalRegion+"$$"+
          JSON.stringify(this.state.company_list[ls2].clientlicencelist)
        }>{this.state.company_list[ls2].clientcontent.domainName}</option>)
      }
    }
    return arrinputDomainList;
  }

  inputlicencelist() {
    var arrinputLicList = [];
    if(this.state.licence_list.length > 0) {
      var licenceList = JSON.parse(this.state.licence_list)?.filter(
        (list) =>  (list.clientliccontent.licenceCategory === "Google Workspace" || list.clientliccontent.licenceCategory === "G Suite")
      );
      for(var ls=0; ls<licenceList.length; ls++) {
        var cssCol = "mgL10 mgR10 card-input base-bg";
        var licenceName = licenceList[ls].clientliccontent.licenceType;
        var licenceSeatAmount = licenceList[ls].clientliccontent.licenceTotalSeat;
        // var licenceStatus = licenceList[ls].clientliccontent.licenceStatus;
        var expDatez = '', expDateSecz = '';
        // var expDateBillCyclez='', expDateBillCycleSecz='-';
        var expDateBillCycleSecz='-';
        var durationBillCyclez='', longPeriodeBillCyclez='';
        var licencePriceCurrency='', licencePriceSeat='',termPaymentStr='',statInvoice='NotSend';
        if(licenceList[ls].clientliccontent.endPeriode !== undefined) {
          expDateSecz = licenceList[ls].clientliccontent.endPeriode._seconds;
          expDatez = DateTimeFormats(expDateSecz,"date-short");
        }
       
        if(licenceList[ls].clientliccontent.endPeriodeBillCycle !== undefined) {
          expDateBillCycleSecz = licenceList[ls].clientliccontent.endPeriodeBillCycle._seconds;
          // expDateBillCyclez = DateTimeFormats(expDateBillCycleSecz,"date-short");
        }
        if(licenceList[ls].clientliccontent.licencePriceCurrency !== undefined) {
          licencePriceCurrency = licenceList[ls].clientliccontent.licencePriceCurrency;
        }
        if(licenceList[ls].clientliccontent.licencePriceSeat !== undefined) {
          licencePriceSeat = licenceList[ls].clientliccontent.licencePriceSeat;
        }
        if(licenceList[ls].clientliccontent.statusSendingInvoice !== undefined) {
          statInvoice = licenceList[ls].clientliccontent.statusSendingInvoice;
        }
        if(licenceList[ls].clientliccontent.longPeriodeBillCycle !== undefined) {
          longPeriodeBillCyclez = licenceList[ls].clientliccontent.longPeriodeBillCycle;
          if(licenceList[ls].clientliccontent.durationPeriodeBillCycle !== undefined) {
            durationBillCyclez = licenceList[ls].clientliccontent.durationPeriodeBillCycle;
          }
          if(durationBillCyclez === "year") { longPeriodeBillCyclez = longPeriodeBillCyclez * 12; } //else { longPeriodeBillCyclez = longPeriodeBillCyclez; }
        } 
        if(licenceList[ls].clientliccontent.longPeriodeBillCycle !== undefined && licenceList[ls].clientliccontent.durationPeriodeBillCycle !== undefined) {
          if(licenceList[ls].clientliccontent.durationPeriodeBillCycle === "year") { 
            if(licenceList[ls].clientliccontent.longPeriodeBillCycle === 1) { termPaymentStr = "Annual Payment"; } else { termPaymentStr = "Payment every "+licenceList[ls].clientliccontent.longPeriodeBillCycle+" year"; }
          } else {
            if(licenceList[ls].clientliccontent.longPeriodeBillCycle === 1) { termPaymentStr = "Monthly Payment"; }
            else if(licenceList[ls].clientliccontent.longPeriodeBillCycle === 3) { termPaymentStr = "Quarterly Payment"; }
            else if(licenceList[ls].clientliccontent.longPeriodeBillCycle === 4) { termPaymentStr = "Trimester Payment"; }
            else if(licenceList[ls].clientliccontent.longPeriodeBillCycle === 6) { termPaymentStr = "Half Year Payment"; }
            else if(licenceList[ls].clientliccontent.longPeriodeBillCycle === 12) { termPaymentStr = "Annual Payment"; }
            else { termPaymentStr = "Payment every "+licenceList[ls].clientliccontent.longPeriodeBillCycle+" month"; }
            
          }
        }
        var reqrenew_lic = false;
        var currDate = new Date(); 
        var datenow_ms = currDate.getTime();
        var dateexpbill_ms = expDateBillCycleSecz * 1000;
        var datediff_ms = dateexpbill_ms - datenow_ms;
        var datein_day = Math.ceil((datediff_ms/1000/60/60)/24);
        if(datediff_ms < 0) {
          reqrenew_lic = true;
        } else {
          if(datein_day < 14 && (licenceList[ls].clientliccontent.statusSendingInvoice === undefined || licenceList[ls].clientliccontent.statusSendingInvoice === 'NotSend')) {
            reqrenew_lic = true;
          }
        }
        var licenceValue = licenceList[ls].clientlicdocid+"$$"+licenceList[ls].clientliccontent.licenceType+"$$"+licenceList[ls].clientliccontent.licenceTotalSeat+"$$"+licenceList[ls].clientliccontent.endPeriode._seconds+"$$"+licencePriceSeat+"$$"+licencePriceCurrency+"$$"+expDateBillCycleSecz+"$$"+expDateSecz+"$$"+statInvoice+"$$"+longPeriodeBillCyclez+"$$"+reqrenew_lic;
        var button_select_lic = <button className="btn btn-secondary btn-sm" type="button" onClick={this.handleClickLicenceType.bind(this,"licence_type",licenceValue,ls)}>select</button>;
        if(reqrenew_lic === true) { 
          cssCol = "mgL10 mgR10 card-input lightgrey-bg"; 
          button_select_lic = <div className="notes-text" style={{padding: "10px", backgroundColor: "#E5E5E5", borderRadius: "5px"}}>Please renew your contract to add more licenses.</div>
        }
        arrinputLicList.push(<div key={ls+1}><label>
            <input type="radio" name="licence_type" id={"licence_type_"+ls} 
              value={licenceValue}
              onChange={this.handleLicenceType} 
              className="card-input-element"/> 
              <div className={cssCol} style={{width:"320px",minHeight:"250px"}} id={"licence_input_"+ls}>
                <div className="label-title-card mgB20">{licenceName}</div>
                <div className="flex-container">
                  <div className="w40p quantity-seat">Licences</div>
                  <div className="w60p quantity-seat"><strong>{licenceSeatAmount}</strong> users</div>
                </div>
                <div className="flex-container">
                  <div className="w40p quantity-seat">Expiry date</div>
                  <div className="w60p quantity-seat"><strong>{expDatez}</strong></div>
                </div>
                {(licencePriceSeat !== "") ? 
                <>
                <div className="flex-container">
                  <div className="w40p quantity-seat">Price</div>
                  <div className="w60p quantity-seat"><strong>{licencePriceCurrency} {NumberFormats(licencePriceSeat*12)}</strong> /user/year</div>
                </div> 
                <div className="flex-container">
                  <div className="w40p quantity-seat"></div>
                  <div className="w60p notes-text">({termPaymentStr})</div>
                </div> 
                </>
                : <></>
                }
                
                {/* 
                <div className="quantity-seat">has <strong>{licenceSeatAmount}</strong> users</div>
                <div className="quantity-seat">will be expired on <strong>{expDatez}</strong></div>
                {(licencePriceSeat !== "") ? 
                  <div className="quantity-seat mgT10">with the pricing is <strong>{licencePriceCurrency} {NumberFormats(licencePriceSeat)}</strong> /user/month</div>
                  : ''
                }
                <div className="notes-text">({termPaymentStr})</div>
                 */}
                {/* <div className="quantity-seat">status <span>{licenceStatus}</span></div> */}
                {/* <div className="mgT30">
                  <button className="transpButton mgR10" onClick={this.urlRenewLic.bind(this, licenceList[ls].clientlicdocid)}>Renew</button>
                  {(licenceStatus === "Active")? 
                    <button className="transpButton" onClick={this.urlAddLic.bind(this, licenceList[ls].clientlicdocid)}>Add More Seat</button>
                  : '' }
                </div> */}
                <div className="mgT20">{button_select_lic}</div>
              </div>
              {/* <div className="panel panel-default card-input">
                <div className="panel-heading">{licenceList[ls].clientliccontent.licenceType}</div>
                <div className="panel-body">
                  Product specific content
                </div>
              </div> */}
          </label></div>
        )
      }
    }
    return arrinputLicList;
  }
  inputlicaddonseat() {
    var arrinputLicAddOnSeat = [];
    if(this.state.licence_addon_seat.length > 0) {
      for(var ls4=0; ls4<this.state.licence_addon_seat.length; ls4++) {
        arrinputLicAddOnSeat.push(<div className="mgB10" key={ls4+1}>
            <input type="number" className="form-control" 
              name={this.state.licence_addon_seat[ls4].fieldId}
              value={this.state.licence_addon_seat[ls4].value}
              onChange={this.handleUserInput2}  
              //ref={(input) => this.textInputLicenceAddOnSeat = input}
              min="1"/> 
          </div>
        )
      }
    }
    return arrinputLicAddOnSeat;
  }
  revieworder2() {
    var arrResultReview = [];
      arrResultReview.push(<tr key="AD1">
            <td >{this.state.current_licName} Licences<br/><span className="notes-text">prorated for {this.state.licence_month_rate} month(s)</span></td>
            <td className="textCenter">{this.state.licence_addseat} user(s)</td>
            <td className="textCenter">{this.state.current_licCurrency} {(this.state.current_licPrice*12)}</td>
            <td className="textCenter">{this.state.licence_month_rate} month(s)</td>
            <td className="textRight pd5">{this.state.current_licCurrency} {this.state.licence_addseat_rp}</td>
          </tr>)
    if(this.state.request_vault > 0) {
      arrResultReview.push(<tr key="AD2">
            <td >Vault Licences</td>
            <td className="textCenter">{this.state.request_vault} user(s)</td>
            <td className="textCenter">{this.state.current_licCurrency} {this.state.current_vault_price}</td>
            <td className="textCenter">{this.state.licence_month_rate} month(s)</td>
            <td className="textRight pd5">{this.state.current_licCurrency} {this.state.request_vault_rp}</td>
          </tr>)
    }
      arrResultReview.push(<tr key="AD3" className="border-top">
            <td colSpan="4" style={{ textAlign: "right"}}>Subtotal</td>
            <td className="textRight pd5">{this.state.current_licCurrency} {this.state.price_total}</td>
          </tr>)
    // if(this.state.tax_value_rp > 0) {
      arrResultReview.push(<tr key="AD4">
          <td colSpan="4" style={{ textAlign: "right"}}>GST {this.state.tax_label_rate}</td>
          <td className="textRight pd5">{this.state.current_licCurrency} {this.state.tax_value_rp}</td>
        </tr>)
      arrResultReview.push(<tr key="AD5">
          <td colSpan="4" style={{ textAlign: "right"}}>Total Price</td>
          <td className="textRight pd5">{this.state.current_licCurrency} {this.state.grand_total}</td>
        </tr>)
    // }
    return arrResultReview;
  }
  
  render() {
    var inputLicAddOnSeat = this.inputlicaddonseat();
    var inputLicList = this.inputlicencelist();
    var inputDomainList = this.inputdomainlist();
    var requestList = this.requestlist();
    var orderReview2 = this.revieworder2();
        
    
   return (
    
      <div className="main-container flex-container cwrapper">
        <div id="sidemenu" className="csidebar">{(this.state.isSignin === true)? <CSidemenu showHideNav={this.state.hideNav}/> : '' }</div>
        <div className="w100p">
          <div className="cheader"><CHeader showHideNav={this.state.hideNav}/></div>
          <div className="cmain-content">
            {/* ====== */}
            {(this.state.isSignin === true)? 
            <div>
                <div className="stTitle">Add Licence</div>
                <div className="stIndiTitle">Request additional licence for your organisation</div>
                        
                <div className="mgT30">
                    <div className="row">
                      <div className="col-xl-8">
                        <div className="card card-main card-main-white">
                          <div id="thankyou-page-msg"></div>
                          
                          <div id="error-page" className="divHide">
                            <div className="flex-container">
                                <CancelIcon className="iconRed"></CancelIcon>
                                <div className="mgL20 done-text">Failed to submit the request.</div>
                              </div>
                          </div>
                          
                          {/*---------
                          <div>{this.state.otherContent}aaaa
                            <CMessageThx payments={this.state.otherContent}></CMessageThx>
                          </div>
                          */}
                          <div id="thankyou-page-p0" className="divHide">
                            <div className="flex-container">
                                <CheckCircleIcon className="iconGreen"></CheckCircleIcon>
                                <div className="mgL20 done-text">Thank you for your request.<br/> It is our goal to serve every single of your inquiries, our customer representative will contact you shortly.</div>
                                {/* <div className="mgL20 done-text">Thank you for your request.<br/> It is our goal to serve every single of your inquiries, please contact your account representative to help you.</div> */}
                              </div>
                          </div>
                          {/*---------*/}
                          <div id="form-s2b-content" className="divHide">
                          <div className="w100p">
                              <div className="st3Title">Request For Add Licence</div>
                              <form onSubmit={this.handleSubmit3}>
                              <div className="mgT30 mgB30">
                                <div>Thank you for request to add more licence.</div>
                                <div className="mgT20">Right now your billing cycle has near, and we haven’t received your confirmation to renew this licence.</div>
                                <div className="mgT20">This is your current status</div>
                                <div className="mgT20">
                                    <div className="card-input base-bg" style={{width:"320px",height:"150px"}}>
                                      <div className="label-title-card mgB20">{this.state.current_licName}</div>
                                      <div className="flex-container">
                                        <div className="w40p quantity-seat">Licences</div>
                                        <div className="w60p quantity-seat"><strong>{this.state.current_licSeat}</strong> users</div>
                                      </div>
                                      <div className="flex-container">
                                        <div className="w40p quantity-seat">Expiry date</div>
                                        <div className="w60p quantity-seat"><strong>{DateTimeFormats(this.state.current_licExpired,"date-short")}</strong></div>
                                      </div>
                                      
                                      {/* 
                                      <div className="quantity-seat">currently has <strong>{this.state.current_licSeat}</strong> users</div>
                                      <div className="quantity-seat">will be expired on <strong>{DateTimeFormats(this.state.current_licExpired,"date-short")}</strong></div> 
                                      */}
                                      
                                    </div>
                                </div>
                                <div className="mgT30">
                                  <div className="mgT05 textBold">Input your amount of licence for the next renew</div>
                                  <div className="w220x-min mgT05">
                                    <div style={{width:"250px"}}>
                                      <input type="number" className="form-control" name="licence_renewseat"
                                          placeholder={this.state.licence_renewseat}
                                          value={this.state.licence_renewseat}
                                          onChange={this.handleUserInput}  
                                          ref={(input) => this.textInputLicenceRenewSeat = input}
                                          min="0"/> 
                                      <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_renewseat}/>  }</div>
                                    </div>
                                  </div>
                                </div>

                                <div className="mgT20">Click the button below to request your renewal and our account representative will contact you.</div>
                                <div className="mgT30 flex-container">
                                  <div className="w50p divRight mgR20"><button id="submit-button2b"  type="submit" className="submitButton">Request Renew</button></div>
                                  <div className="w50p"><button id="cancel-button"  type="button" className="submitButton" onClick={this.urlcancelreq.bind(this)}>Cancel</button></div>
                                </div>
                                <div className="mgT20"></div>

                              </div>
                              </form>
                            </div>
                          </div>
                          {/*---------*/}
                          <div id="form-s2-content" className="divHide" >
                            <div className="div-title-card">Your Personal Information</div>
                            <div id="Form2aCenter" className="mgT20">
                              <div className="mgT20 flex-container">
                                <div className="w280x mgT05">Name</div>
                                <div className="w280x-min mgT05">{this.state.requester_name}</div>
                              </div>
                              <div className="mgT20 flex-container">
                                <div className="w280x mgT05">Email</div>
                                <div className="w280x-min mgT05">{this.state.requester_email}</div>
                              </div>
                              <div className="mgT20 flex-container">
                                <div className="w280x mgT05">Phone Number</div>
                                <div className="w280x-min mgT05">{this.state.requester_phone}</div>
                              </div>
                            </div>
                            <div className="div-title-card mgT50">Review Your Request</div>
                            <div id="Form2aCenter" className="mgT20">
                              <div className="mgT20 flex-container">
                                <div className="w280x mgT05">Domain</div>
                                <div className="w280x-min mgT05">{this.state.current_domainName}</div>
                              </div>
                              <div className="mgT10 flex-container">
                                <div className="w280x mgT05">SKU</div>
                                <div className="w280x-min mgT05">{this.state.current_licName}</div>
                              </div>
                              <div className="mgT10 flex-container">
                                <div className="w280x mgT05">Current Number of Licences</div>
                                <div className="w280x-min mgT05">{this.state.current_licSeat} user(s)</div>
                              </div>
                              {/* <div className="mgT10 flex-container">
                                <div className="w280x mgT05">Expiry Date</div>
                                <div className="w280x-min mgT05">{DateTimeFormats(this.state.current_licExpired,"date-short")}</div>
                              </div> */}
                              {/* <div className="mgT20 flex-container">
                                <div className="w280x mgT05">End of Bill Cycle</div>
                                <div className="w280x-min mgT05">{DateTimeFormats(this.state.current_licExpiredBillCycle,"date-short")}</div>
                              </div> */}
                               <div className="mgT30" style={{padding: "10px", backgroundColor: "#F1F1F1", borderRadius: "5px"}}>
                                <div className="flex-container">
                                  <div className="w280x mgT05">Additional Number of Licences</div>
                                  <div className="w280x-min mgT05">{this.state.licence_addseat} user(s)</div>
                                </div>
                                {(this.state.licence_country.toLowerCase() === 'indonesia' && this.state.current_licExpired !== this.state.current_licExpiredBillCycle)? 
                                <div className="mgT10 flex-container">
                                  <div className="w280x mgT05">Next Payment</div>
                                  <div className="w280x-min mgT05">{DateTimeFormats(this.state.current_licExpiredBillCycle,"date-short")}</div>
                                </div>
                                : <></>
                                }
                              </div>
                            </div>

                            <div className="div-title-card mgT50">Review your Total Cost</div>
                            {/* <div id="Form2bCenter" className="mgT20">
                              <div className="mgT30 flex-container">
                                  <div className="textLeft pad5" style={{width:"400px"}}><b><u>Description</u></b></div>
                                  <div className="textCenter pad5" style={{width:"100px"}}><b><u>Unit</u></b></div>
                                  <div className="textCenter pad5" style={{width:"100px"}}><b><u>Price per Unit</u></b></div>
                                  <div className="textCenter pad5" style={{width:"150px"}}><b><u>Rate ({this.state.current_licCurrency})</u></b></div>
                                  <div className="textCenter pad5" style={{width:"150px"}}><b><u>Amount ({this.state.current_licCurrency})</u></b></div>
                                  <div className="textCenter pad5" style={{width:"100px"}}><b><u>Frequency</u></b></div> 
                              </div>
                              <div>{orderReview}</div>
                            </div> */}
                            <div>
                              <table className="table table-borderless">
                                <thead className="thead-light">
                                  <tr key="AD0">
                                    <th scope="col" className="w-auto" style={{verticalAlign: "middle"}}>Item</th>
                                    <th scope="col" style={{width:"100px", textAlign: "center", verticalAlign: "middle"}}>Unit</th>
                                    <th scope="col" style={{width:"100px", textAlign: "center", verticalAlign: "middle"}}>Price / unit / year</th>
                                    <th scope="col" style={{width:"100px", textAlign: "center", verticalAlign: "middle"}}>Of Subscribed Month</th>
                                    <th scope="col" style={{width:"120px", textAlign: "center", verticalAlign: "middle"}}>Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {orderReview2}                 
                                </tbody>
                              </table>
                            </div> 
                            {this.state.licence_country.toLowerCase() === 'indonesia' && <div className="mgT30" style={{padding: "10px", backgroundColor: "#F1F1F1", borderRadius: "5px"}}>
                              <div className="notes-text">Terms of Payment: </div>
                              <div className="notes-text">
                              This order will be valid until the next payment which is on <strong>{DateTimeFormats(this.state.current_licExpired,"date-short")}</strong>.<br/>
                              On <strong>{DateTimeFormats(this.state.current_licExpired,"date-short")}</strong>, we will send you the invoice for the <strong>{Number(this.state.current_licSeat) + Number(this.state.licence_addseat)}</strong> licences.
                              </div>
                            </div>
                            }
                            <div className="div-title-card mgT50">Choose a payment method</div>
                            <form onSubmit={this.handleSubmit2}>
                            <div id="Form2cCenter" className="mgT20">
                              <div className="mgT20">
                                  <div className="row-field">
                                    {(this.state.current_licCurrency.toLowerCase() === "sgd") && 
                                      <div className="custom-control custom-radio mgB30 w100p">
                                          <label>
                                              
                                                <input type="radio" name="method_payment" id="method_payment3" 
                                                      value={global.cconfig.methodPayment[2]}
                                                      onChange={this.handleUserSelect}
                                                      className="custom-control-input"
                                                      /> 
                                                <div className="custom-control-label" htmlFor="method_payment3">
                                                    <div className="row">
                                                      <div className="col" style={{width: "250px"}}><img src={picpaynow} style={{width: "140px"}} alt="" /></div>
                                                      {/* <div className="col" style={{maxWidth: "450px"}}>
                                                        <div><b>Paynow</b></div>
                                                        <div className="notes-text">Faster Provisioning (For Singapore Only)</div>
                                                      </div> */}
                                                    </div>
                                                </div>
                                              
                                          </label>
                                      </div>
                                      }
                                      {/* <div className="custom-control custom-radio mgT20">
                                          <label><input type="radio" name="method_payment" id="method_payment1" 
                                                      value={global.cconfig.methodPayment[0]}
                                                      onChange={this.handleUserSelect}
                                                      className="custom-control-input"
                                                      /> 
                                              <div className="custom-control-label" htmlFor="method_payment1">
                                                <div className="row">
                                                  <div className="col" style={{width: "250px"}}><img src={picpaypal} style={{width: "220px"}} alt="" /></div>
                                                  <div className="col" style={{maxWidth: "450px"}}>
                                                    <div><b>Credit Card / Paypal</b></div>
                                                    <div className="notes-text">Faster Provisioning</div>
                                                  </div>
                                                </div>
                                              </div>
                                          </label>
                                      </div> */}
                                      <div className="custom-control custom-radio mgB20 w100p">
                                          <label><input type="radio" name="method_payment" id="method_payment5" 
                                                      value={global.cconfig.methodPayment[4]}
                                                      onChange={this.handleUserSelect}
                                                      className="custom-control-input"
                                                      /> 
                                              <div className="custom-control-label" htmlFor="method_payment5">
                                                <div className="row">
                                                  <div className="col" style={{width: "250px"}}>
                                                    <div><img src={piccc} style={{width: "220px"}} alt="" /></div><div className="mgT10"><b>Credit Card </b></div>
                                                  </div>
                                                  {/* <div className="col" style={{maxWidth: "450px"}}>
                                                    <div><b>Credit Card </b></div>
                                                    <div className="notes-text">Faster Provisioning</div>
                                                  </div> */}
                                                </div>
                                              </div>
                                          </label>
                                          <div id="payment-cc-form" className="divHide">
                                            {/* <div className="input-group mb-3">
                                              <i className="fas fa-exclamation-circle trailing"></i>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="1234 1234 1234 1234"
                                                aria-label="card-number"
                                                aria-describedby="basic-addon1"
                                              />
                                              <div className="input-group-append">
                                                <button className="btn btn-outline-secondary" type="button">
                                                  <PaymentIcon />
                                                </button>
                                              </div>
                                            </div> */}
                                            <div className="flex-item width-12 mt-3 mb-3">
                                              <div className="FormFieldGroup">
                                                <div className="FormFieldGroup-labelContainer flex-container justify-content-space-between">
                                                  <label htmlFor="cardNumber-fieldset"><span className="Text Text-color--gray600 Text-fontSize--13 Text-fontWeight--500">Card information</span></label>
                                                </div>
                                                <fieldset className="FormFieldGroup-Fieldset" id="cardNumber-fieldset">
                                                  <div className="FormFieldGroup-container" id="cardNumber-fieldset-inner">
                                                    <div className="FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childRight FormFieldGroup-childTop">
                                                      <div className="FormFieldInput">
                                                        <div className="CheckoutInputContainer">
                                                          <span className="InputContainer" data-max="">
                                                          <input className="CheckoutInput CheckoutInput--tabularnums Input Input--empty" 
                                                                autoComplete="cc-number" id="cc_num" name="cc_num" type="text" 
                                                                inputMode="numeric" 
                                                                aria-label="Card number" 
                                                                placeholder="1234123412341234" 
                                                                value={this.state.cc_num}
                                                                onChange={this.handleUserInput}  
                                                                ref={(input) => this.textInputCCNum = input}
                                                           />
                                                          </span>
                                                        </div>
                                                        <div className="FormFieldInput-Icons" style={{opacity: "1"}}>
                                                          <div style={{transform: "none"}}><span className="FormFieldInput-IconsIcon is-visible"><img src="https://js.stripe.com/v3/fingerprinted/img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg" alt="Visa" className="BrandIcon" loading="lazy" fetchpriority="low" /></span></div>
                                                          <div style={{transform: "none"}}><span className="FormFieldInput-IconsIcon is-visible"><img src="https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg" alt="MasterCard" className="BrandIcon" loading="lazy" fetchpriority="low" /></span></div>
                                                          <div style={{transform: "none"}}><span className="FormFieldInput-IconsIcon is-visible"><img src="https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg" alt="American Express" className="BrandIcon" loading="lazy" fetchpriority="low" /></span></div>
                                                          <div className="CardFormFieldGroupIconOverflow">
                                                            <span className="CardFormFieldGroupIconOverflow-Item CardFormFieldGroupIconOverflow-Item--invisible" role="presentation"><span className="FormFieldInput-IconsIcon" role="presentation"><img src="https://js.stripe.com/v3/fingerprinted/img/discover-ac52cd46f89fa40a29a0bfb954e33173.svg" alt="Discover" className="BrandIcon" loading="lazy" fetchpriority="low" /></span></span>
                                                            <span className="CardFormFieldGroupIconOverflow-Item CardFormFieldGroupIconOverflow-Item--visible" role="presentation"><span className="FormFieldInput-IconsIcon" role="presentation"><img src="https://js.stripe.com/v3/fingerprinted/img/jcb-271fd06e6e7a2c52692ffa91a95fb64f.svg" alt="JCB" className="BrandIcon" loading="lazy" fetchpriority="low" /></span></span>
                                                            <span className="CardFormFieldGroupIconOverflow-Item CardFormFieldGroupIconOverflow-Item--invisible" role="presentation"><span className="FormFieldInput-IconsIcon" role="presentation"><img src="https://js.stripe.com/v3/fingerprinted/img/diners-fbcbd3360f8e3f629cdaa80e93abdb8b.svg" alt="Diners Club" className="BrandIcon" loading="lazy" fetchpriority="low" /></span></span>
                                                            <span className="CardFormFieldGroupIconOverflow-Item CardFormFieldGroupIconOverflow-Item--invisible" role="presentation"><span className="FormFieldInput-IconsIcon" role="presentation"><img src="https://js.stripe.com/v3/fingerprinted/img/unionpay-8a10aefc7295216c338ba4e1224627a1.svg" alt="UnionPay" className="BrandIcon" loading="lazy" fetchpriority="low" /></span></span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childRight">
                                                      <div className="FormFieldInput">
                                                        <div className="CheckoutInputContainer">
                                                          <span className="InputContainer" data-max="">
                                                          <input className="CheckoutInput CheckoutInput--tabularnums Input Input--empty" 
                                                                autoComplete="cc-cardholdername" id="cc_cardholder_name" name="cc_cardholder_name" type="text" 
                                                                inputMode="numeric" 
                                                                aria-label="Cardholder name" 
                                                                placeholder="Cardholder Name" 
                                                                value={this.state.cc_cardholder_name}
                                                                onChange={this.handleUserInput}  
                                                                ref={(input) => this.textInputCCCardholderName = input}
                                                          />
                                                          </span>
                                                        </div>
                                                        
                                                      </div>
                                                    </div>
                                                    <div className="FormFieldGroup-child FormFieldGroup-child--width-6 FormFieldGroup-childLeft FormFieldGroup-childBottom">
                                                      <div className="FormFieldInput">
                                                        <div className="CheckoutInputContainer">
                                                          <span className="InputContainer" data-max="">
                                                            <input className="CheckoutInput CheckoutInput--tabularnums Input Input--empty" 
                                                                  autoComplete="cc-exp" id="cc_expired" name="cc_expired" type="text" 
                                                                  inputMode="numeric" 
                                                                  aria-label="Expiration" 
                                                                  placeholder="MM / YY" 
                                                                  value={this.state.cc_expired} 
                                                                  onChange={this.handleUserInput}  
                                                                  ref={(input) => this.textInputCCExpired = input}
                                                                  maxLength={5}
                                                            />
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="FormFieldGroup-child FormFieldGroup-child--width-6 FormFieldGroup-childRight FormFieldGroup-childBottom">
                                                      <div className="FormFieldInput has-icon">
                                                        <div className="CheckoutInputContainer">
                                                          <span className="InputContainer" data-max="">
                                                            <input className="CheckoutInput CheckoutInput--tabularnums Input Input--empty" 
                                                                  autoComplete="cc-csc" id="cc_cvc" name="cc_cvc" type="text" 
                                                                  inputMode="numeric"
                                                                  aria-label="CVC" 
                                                                  placeholder="CVC" 
                                                                  value={this.state.cc_cvc}
                                                                  onChange={this.handleUserInput}  
                                                                  ref={(input) => this.textInputCCCVC = input}
                                                            />
                                                          </span>
                                                        </div>
                                                        <div className="FormFieldInput-Icon" data-testid="FormFieldInput-Icon">
                                                          <svg className="Icon Icon--md" focusable="false" viewBox="0 0 32 21" role="img" aria-label="CVC"><title>CVC</title><g fill="none" fillRule="evenodd"><g className="Icon-fill"><g transform="translate(0 2)"><path d="M21.68 0H2c-.92 0-2 1.06-2 2v15c0 .94 1.08 2 2 2h25c.92 0 2-1.06 2-2V9.47a5.98 5.98 0 0 1-3 1.45V11c0 .66-.36 1-1 1H3c-.64 0-1-.34-1-1v-1c0-.66.36-1 1-1h17.53a5.98 5.98 0 0 1 1.15-9z" opacity=".2"></path><path d="M19.34 3H0v3h19.08a6.04 6.04 0 0 1 .26-3z" opacity=".3"></path></g><g transform="translate(18)"><path d="M7 14A7 7 0 1 1 7 0a7 7 0 0 1 0 14zM4.22 4.1h-.79l-1.93.98v1l1.53-.8V9.9h1.2V4.1zm2.3.8c.57 0 .97.32.97.78 0 .5-.47.85-1.15.85h-.3v.85h.36c.72 0 1.21.36 1.21.88 0 .5-.48.84-1.16.84-.5 0-1-.16-1.52-.47v1c.56.24 1.12.37 1.67.37 1.31 0 2.21-.67 2.21-1.64 0-.68-.42-1.23-1.12-1.45.6-.2.99-.73.99-1.33C8.68 4.64 7.85 4 6.65 4a4 4 0 0 0-1.57.34v.98c.48-.27.97-.42 1.44-.42zm4.32 2.18c.73 0 1.24.43 1.24.99 0 .59-.51 1-1.24 1-.44 0-.9-.14-1.37-.43v1.03c.49.22.99.33 1.48.33.26 0 .5-.04.73-.1.52-.85.82-1.83.82-2.88l-.02-.42a2.3 2.3 0 0 0-1.23-.32c-.18 0-.37.01-.57.04v-1.3h1.44a5.62 5.62 0 0 0-.46-.92H9.64v3.15c.4-.1.8-.17 1.2-.17z"></path></g></g></g></svg>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div style={{opacity: "0", height: "0px"}}>
                                                      <span className="FieldError Text Text-color--red Text-fontSize--13"><span aria-hidden="true"></span></span>
                                                    </div>
                                                  </div>
                                                </fieldset>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.cc_num}/>  }</div>
                                      </div>
                                      
                                      <div className="custom-control custom-radio mgB20 w100p">
                                          <label><input type="radio" name="method_payment" id="method_payment4" 
                                                      value={global.cconfig.methodPayment[3]}
                                                      onChange={this.handleUserSelect}
                                                      className="custom-control-input"
                                                      /> 
                                              <div className="custom-control-label" htmlFor="method_payment4" style={{width: "100%"}}>
                                                <div className="row" style={{width: "100%"}}>
                                                  <div className="col" style={{width: "350px"}}><img src={picbt} style={{width: "100px"}} alt="" /></div>
                                                  {/* <div className="col" style={{maxWidth: "450px"}}>
                                                    <div><b>Wire / Bank Transfer</b></div>
                                                    <div className="notes-text">Slower Provisioning</div>
                                                  </div> */}
                                                </div>
                                              </div>
                                          </label>
                                      </div>
                                      {/* <div className="custom-control custom-radio mgT20">
                                          <label><input type="radio" name="method_payment" id="method_payment2" 
                                                      value={global.cconfig.methodPayment[1]}
                                                      onChange={this.handleUserSelect}
                                                      className="custom-control-input"
                                                      /> 
                                              <div className="custom-control-label" htmlFor="method_payment2" style={{width: "100%"}}>
                                                <div className="row" style={{width: "100%"}}>
                                                  <div className="col" style={{width: "350px"}}><img src={piccheque} style={{width: "100px"}} alt="" /></div>
                                                  <div className="col" style={{maxWidth: "450px"}}>
                                                    <div><b>Send us a Cheque</b></div>
                                                    <div className="notes-text">Slower Provisioning</div>
                                                  </div>
                                                </div>
                                              </div>
                                          </label>
                                      </div>
                                    */}
                                  </div>  
                                  <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.method_payment}/>  }</div>    
                              </div>
                              <div className="row mgT30" >
                                  <div className="col small">
                                      <label><input type="checkbox" name="is_tnc" 
                                          onChange={this.handleUserSelect} />
                                          <span className="mgL10 textBold textItalic">I agree to the <a href="https://gsuite.google.com/intl/en/terms/reseller_premier_terms.html" target="_blank" rel="noreferrer" className="fontlink">Terms and Conditions</a>.</span>
                                      </label>
                                      <div>{ <FormErrors formErrors={this.state.formErrors.is_tnc}/>  }</div> 
                                  </div>
                              </div> 
                              <div className="mgT30 flex-container">
                                {/* <div className="w50p divRight"><div className="submitButton mgR20 cursor-on" onClick={this.urlcancelreq.bind(this)}>Cancel</div></div> */}
                                <div className="w50p divRight"><button type="button" className="submitButton mgR20" onClick={this.urlcancelreq.bind(this)}>Cancel</button></div>
                                <div className="w50p"><button id="submit-button2"  type="submit" className="submitButton">Proceed</button></div>
                              </div>
                              
                            </div>
                            </form>
                          </div>
                          {/*---------*/}
                          <div id="form-s1-content">
                            <div id="FormCenter" className="mgT20">
                              <form onSubmit={this.handleSubmit1}>
                                <div className="mgT20">
                                  <label className="textBold" htmlFor="domain_name">Select your domain</label>
                                  <div>
                                    <select name="domain_name" className="form-control" 
                                        value={this.state.domain_name} 
                                        onChange={this.handleUserSelect}
                                        ref={(input) => this.textInputDomainName = input}
                                    >
                                        <option value="" key="0">-- please select --</option>
                                        {inputDomainList}
                                    </select>
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.domain_name}/>  }</div>
                                  </div>
                                </div>
                                <div className="mgT50">
                                  {(inputLicList.length > 0)? 
                                  <div>
                                    <label className="textBold" htmlFor="licence_type">Select licence to be added</label>
                                    <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_type}/>  }</div>
                                    <div className="mgT20 mgB30 w100p">
                                    <div className="row"> {inputLicList} </div>
                                    </div>
                                  </div>
                                   : '' } 
                                </div>
                                <div className="mgT30" id="textfield-lic-addseat">
                                  <div className="mgT05 textBold">How many licence to add?</div>
                                  <div className="w220x-min mgT05">
                                    <div style={{width:"250px"}}>
                                      <input type="number" className="form-control" name="licence_addseat"
                                          placeholder={this.state.licence_addseat}
                                          value={this.state.licence_addseat}
                                          onChange={this.handleUserInput}  
                                          ref={(input) => this.textInputLicenceAddSeat = input}
                                          min="0"/> 
                                      <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.licence_addseat}/>  }</div>
                                    </div>
                                  </div>
                                </div>
                                {//(this.state.requester_phone === "" || this.state.requester_phone === null || this.state.requester_phone === undefined || this.state.requester_phone === "-")? 
                                <div className="mgT30" id="textfield-lic-addseat">
                                  <div className="mgT05 textBold">Input your contact phone number</div>
                                  <div className="w220x-min mgT05">
                                    <div style={{width:"250px"}}>
                                      <input type="text" className="form-control" name="requester_phone"
                                          placeholder={this.state.requester_phone}
                                          value={this.state.requester_phone}
                                          onChange={this.handleUserInput}  
                                          ref={(input) => this.textInputRequesterPhone = input}
                                          onWheel={ e => e.currentTarget.blur() }
                                          min="0"/> 
                                      <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.requester_phone}/>  }</div>
                                    </div>
                                  </div>
                                </div>
                                //: <></> 
                                }
                                <div id="gaddon-area" className="mgT30 divHide">
                                  {(this.state.licence_addon_list.length > 0)? 
                                  <div>
                                    <label className="textMiddle mgT10 mgB10" htmlFor="licence_addon">Do you also want to add new licence for the Add On Licence?</label>
                                    <div className="flex-container">
                                      <div className="mgR20">
                                        {
                                          this.state.licence_addon_list.map((licence_addon, index) => {
                                            return (<div className="divMiddle mgB10" style={{height:"38px"}}><CheckBox key={index} handleInputChecked={this.handleLicenceAddOn}  {...licence_addon} /></div>)
                                          })
                                        }
                                      </div>
                                      <div>
                                      {inputLicAddOnSeat}
                                      </div>
                                    </div>
                                    
                                  </div>
                                  : '' } 
                                </div>
                                <div className="mgT30">
                                  <button id="submit-button"  type="submit" className="submitButton">Proceed</button>
                                  <button id="submit-button1b"  type="button" className="submitButton divHide" onClick={this.handleSubmit4.bind(this)}>Proceed</button>
                                  <button id="renew-button"  type="button" className="submitButton divHide" onClick={this.urlrenewreq.bind(this)}>Continue to Renew</button>
                                </div>
                              </form>
                            </div>
                          </div>
                          {/*---------*/}
                        </div>
                      </div>
                      <div className="col-xl-4">
                        <div className="card card-main card-main-blue">
                          <div className="card-request">
                            <div className="label-title-card white-font">Request</div>
                            <div>
                              {requestList}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            : <div><div id="form-s1-content"></div><div id="thankyou-page-msg"></div><div id="error-page"></div></div> }
          {/* ====== */}
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div> 
      </div>
   
    
    
   );
  }
}
 
export default CReqAddLicence;