import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import '../../inc/popup.css'
import Popup from '../../inc/popup';
import DateTimeFormats from '../../inc/date-time-format';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import IReqRenewLicenceDetail from './ipopreqrenewlicence-detail';
import DescriptionIcon from '@material-ui/icons/Description';
//import SearchIcon from '@material-ui/icons/Search';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[27];

class IReqRenewLicence extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        styleTb: 'table',
        data: [],
        isOpen: false,
        otherContent: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
      }
    })
  } 
  urlviewDetail(valId,content) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: true,
          ...prevState.otherContent, otherContent: '{"request_id":"'+valId+'"}'
      }
    })
  }
  loadData() {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/reqrenewlicencelist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200' && resJson.values.length > 0) { 
          var arrList = [];
          var arrSortByDesc = [];
          for(var x=0; x<resJson.values.length; x++) {
              arrList.push({
                  docid: resJson.values[x].docid,
                  createdDate: resJson.values[x].createdate,
                  content: resJson.values[x].content
              })
          }
          if(arrList.length>0) {
              arrSortByDesc = arrList.sort((c,d) => {
                return d.createdDate._seconds - c.createdDate._seconds;
              })
            }
          this.setState({
              styleTb: 'table table-striped',
              data: arrSortByDesc,
              isOpen: false,
              otherContent: ''
          })
        } else {
            this.setState({
                styleTb: 'table',
                data: [],
                isOpen: false,
                otherContent: ''
            })
        } 
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  urldelMember(valId) {
    document.getElementById('loading-area').style.display = 'block'; 
    var urlString = global.config.urlLink+"/reqrenewdelete/"+valId;
    fetch(urlString, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }
    }).then(response => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(response.status === 200) {
            window.location.reload();
        } else {
            console.log('Something happened wrong delete');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value });
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    this.setState( prevState => {
        return {  
            ...prevState.pageShow, pageShow: value
        }
    })
  }
    
  handleSubmit(e) {
    e.preventDefault();
    this.loadData();
  }
  componentDidMount() {
    this.loadData();
  }
  render() {
    var reqRenewLicenceList = [];
    if(this.state.data.length > 0) {
     for(var ls=0; ls < this.state.data.length; ls++) {
         var retResult = this.state.data[ls];
         var cDatez = "", cTimez="";
         if(retResult.content.createDate !== undefined) {
             cDatez = DateTimeFormats(retResult.content.createDate._seconds,"date-short");
             cTimez = DateTimeFormats(retResult.content.createDate._seconds,"time-24-second");
         }
         reqRenewLicenceList.push(<tr key={retResult.docid}>
             <th scope="row-field">{ls+1}</th>
             <td>{cDatez}<br/>({cTimez})</td>
             <td>{retResult.content.clientName}<br/><span className="font-sublist">{retResult.content.domainName}</span></td>
             <td>{retResult.content.licenceType}</td>
             <td>{retResult.content.licenceAddSeat}</td>
             <td>{retResult.content.requestStatus}</td>
             <td style={{width:'150px'}}>
                 <DescriptionIcon className="iconGrey mgR20" onClick={this.urlviewDetail.bind(this, retResult.docid, JSON.stringify(retResult))}/>
             </td>
           </tr>)
     }
    } else {
      reqRenewLicenceList.push(<tr key="1">
          <td colSpan="6" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
        </tr>)
    }
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Renewal Licence Request</div>
                <div className="stIndiTitle">List of renew licence request from clients</div>
                <div className="mgT30">
                    {/*--*/}
                    <div className="content-container card card-body">
                        <div className="row show">
                            <div className="col-6 divMiddle">
                                
                            </div>
                            <div className="col-6 form-inline justify-content-end">
                            </div>
                        </div>
                        <div className="row-field mgT20">
                            <table className={this.state.styleTb}>
                            <thead>
                                <tr key="0">
                                <th scope="col">#</th>
                                <th scope="col">Request Date</th>
                                <th scope="col">Client Name</th>
                                <th scope="col">Licence Info</th>
                                <th scope="col">Seat Amount</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reqRenewLicenceList}
                            </tbody>
                            </table>
                        </div>
                        <div className="row mt-3">
                            
                        </div>
                    </div>
                    <div>
                          {this.state.isOpen && <Popup
                            content={<><IReqRenewLicenceDetail/></>}
                            id="a"
                            title={"Renew Licence Request"}
                            others={this.state.otherContent}
                            styles={"80%"}
                            handleClose={this.togglePopClose.bind(this, this.state.domainname)}
                          />}
                    </div>
                    {/*--*/}
                </div>
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div> 
    </div>
   );
 }
}
 
export default IReqRenewLicence;