import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import '../../inc/genstyle.css';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import StarOnIcon from '@material-ui/icons/Star';
import StarOffIcon from '@material-ui/icons/StarBorderOutlined';
import DeleteIcon from '@material-ui/icons/Delete';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[10];
  
function urladdCourse() {
    window.location.href='/IAddCourse';
}

class ICourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        styleTb: 'table',
        data: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  loadData() {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/courselist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        var styleTb = 'table';
        if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; } 
        this.setState({
            styleTb: styleTb,
            data: resJson
        })
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  urlpublishCourse(valId,nextstat) {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"course_docid":"'+valId+'","course_status":"'+nextstat+'","form_id":"form-publish"}';
    var data = JSON.parse(datatext); 
    var urlFetch = global.config.urlLink+"/courseedit";
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) return res.json() 
        }).then( resJson => {
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') {
                window.location.href='/ICourse';
            } else {
                console.log('Something happened wrong');
            }
        }).catch(err => { 
            document.getElementById('loading-area').style.display = 'none'; 
        });
  }
  urlfeaturedCourse(valId,isFeatured) {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"course_docid":"'+valId+'","is_featured":"'+isFeatured+'","form_id":"form-featured"}';
    var data = JSON.parse(datatext); 
    var urlFetch = global.config.urlLink+"/courseedit";
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
        }).then(res => {
            if(res.status === 200) return res.json() 
        }).then( resJson => {
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') {
                window.location.href='/ICourse';
            } else {
                console.log('Something happened wrong');
            }
        }).catch(err => { 
            document.getElementById('loading-area').style.display = 'none'; 
        });
  }
  urldeleteCourse(valId) {
    var stat = false;
    stat = window.confirm("Are you sure you want to delete this data?");
    if(stat === true) {
      document.getElementById('loading-area').style.display = 'block'; 
      var urlString = global.config.urlLink+"/coursedelete";
      var datatext ='{"docid":"'+valId+'","numchild":1}';
      var data = JSON.parse(datatext); 
      fetch(urlString, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === "200") {
              window.location.reload();
          } else {
              console.log('Something happened wrong delete');
          }
      }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
      });
    }
  }
  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value });
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    this.setState( prevState => {
        return {  
            ...prevState.pageShow, pageShow: value
        }
    })
  }
    
  handleSubmit(e) {
    e.preventDefault();
    this.loadData();
  }
  componentDidMount() {
    this.loadData();
  }
  contentTabs(param) {
    var stLabel = "English"; if(param === 'Indonesian') { stLabel = "Indonesian"; }  
    
    var courseList = [];
    if(this.state.data.values.length > 0 && this.state.data.status === '200') {
        var number = 0;
      for(var ls=0; ls < this.state.data.values.length; ls++) {
          var retResult = this.state.data.values[ls];
          var editURL = "/IEditDetailCourse/"+retResult.docid;
          var viewURL = "/IViewCourse/"+retResult.docid;
          var iconPub = <ToggleOffIcon className="iconGrey cursor-on mgR20" onClick={this.urlpublishCourse.bind(this, retResult.docid,global.config.libraryStatus[1])}/>;
          var iconEdit = <EditIcon className="iconGreyDis mgR20" title="edit" />;
          var iconFeatured = <StarOffIcon className="iconGreyDis cursor-on mgR20" onClick={this.urlfeaturedCourse.bind(this, retResult.docid, 1)}/>;
          if(retResult.content.status === "Publish" ) { iconPub =  <ToggleOnIcon className="iconGrey cursor-on mgR20" onClick={this.urlpublishCourse.bind(this, retResult.docid, global.config.libraryStatus[2])}/> }
          if(retResult.content.isFeatured === "1") { iconFeatured =  <StarOnIcon className="cursor-on mgR20" onClick={this.urlfeaturedCourse.bind(this, retResult.docid, 0)}/> }
          // if(retResult.content.createById === localStorage.getItem('utk')) { iconEdit = <a href={editURL}><EditIcon className="iconGrey mgR20" title="edit" /></a>; }
          iconEdit = <a href={editURL}><EditIcon className="iconGrey mgR20" title="edit" /></a>
          if(retResult.content.courseLanguage === param) {
              number++;
            courseList.push(<tr key={retResult.docid}>
                <th scope="row-field">{number}</th>
                <td>{retResult.content.courseTitle}</td>
                <td>{retResult.content.categoryName}</td>
                <td>{retResult.content.subcategoryName}</td>
                <td>
                    {iconFeatured}
                    {iconEdit}
                    <a href={viewURL}><DescriptionIcon className="iconGrey mgR20" /></a>
                    {iconPub}
                    <DeleteIcon className="iconGrey cursor-on" onClick={this.urldeleteCourse.bind(this, retResult.docid)}/>
                </td>
                </tr>)
          }
      }
    } else {
        courseList.push(<tr key="1">
            <td colSpan="5" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
          </tr>)
    }

    return (   
        <div className="content-container">
            <div className="row show">
                <div className="col-6 divMiddle">
                </div>
                <div className="col-6 form-inline justify-content-end">
                    <button className="genButton mgL20" onClick={urladdCourse}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>Add New Workshop</button>
                </div>
            </div>
            <div className="row-field mgT20">
                <table className={this.state.styleTb}>
                <thead>
                    <tr key="0">
                    <th scope="col">#</th>
                    <th scope="col">Title</th>
                    <th scope="col">Category</th>
                    <th scope="col">SubCategory</th>
                    <th scope="col" style={{width:"230px"}}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {courseList}
                </tbody>
                </table>
            </div>
            <div className="row mt-3">
                
            </div>
        </div>
    )
  }
  render() {
    var  defindex = 0;
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Workshops</div>
                {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[2] === "ON") || (JSON.parse(localStorage.getItem('urole'))[3] === "ON") || (JSON.parse(localStorage.getItem('urole'))[4] === "ON"))? 
                <div>
                  <div className="stIndiTitle">List of Workshops</div>
                  <div className="mgT30">
                      {/*--*/}
                        <Tabs defaultIndex={defindex} onSelect={this.handleSelectTab}>
                            <TabList className="tabs-class__tab-list">
                                <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">English</Tab>
                                <Tab  className="tabs-class__tab" disabledClassName="tabs-class__tab--disabled" selectedtabclassname="tabs-class__tab--selected">Indonesian</Tab>
                            </TabList>

                            <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">{this.contentTabs("English")}</TabPanel>
                            <TabPanel className="tabs-class__tab-panel" selectedClassName="tabs-class__tab-panel--selected">{this.contentTabs("Indonesian")}</TabPanel>
                        </Tabs>
                      {/*--*/}
                  </div>
                </div>
                :   
                <div><INA/></div>
                }
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div>
    </div>
   );
  }
}
 
export default ICourse;