import React, { Component } from 'react';
import '../../inc/genstyle.css';
import DateTimeFormats from '../../inc/date-time-format';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

class ZTELogDetail extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            postType: '',
            createDate: '',
            createBy: '',
            domainName: '',
            customerId: '',
            preprovToken: '',
            fileId: '',
            styleTb: 'table',
            data: [],
            linkUrl: '',
            actiontype: ''
        }
    }
    urlopenFile(ln) {
        window.open(ln);
    }
    componentDidMount() {
        document.getElementById('loading-area').style.display = 'block'; 
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        var cDatez = "", domain_name = "", customer_id="", provisioning_token = "", file_id="", post_type="", link_url="";
        var resultData = [];
         if(othersContent.content.createDate !== undefined) {
              cDatez = DateTimeFormats(othersContent.content.createDate._seconds,"datetime-long");
         }
         if(othersContent.content.request.preProvisionedDevice !== undefined) {
            domain_name = othersContent.content.request.preProvisionedDevice.domain;
	    if(othersContent.content.request.preProvisionedDevice.customer_id !== undefined) {
            	customer_id = othersContent.content.request.preProvisionedDevice.customer_id;
            } 
         } else { 
	   domain_name = othersContent.content.request.domain; 
	    if(othersContent.content.request.customer_id !== undefined) {
            	customer_id = othersContent.content.request.customer_id;
            } 
	 }
         if(othersContent.content.request.device_pre_provisioning_token !== undefined) {
            provisioning_token = othersContent.content.request.device_pre_provisioning_token;
         }
        if(othersContent.content.request.fileid !== undefined) {
            post_type = "bulk";
            file_id = othersContent.content.request.fileid;
            link_url = 'https://docs.google.com/spreadsheets/d/'+file_id;
            if(othersContent.content.type === global.config.zteType[1]) { 
                resultData = othersContent.content.response;
            } else {
                if(othersContent.content.response.responses !== undefined) {
                    resultData = othersContent.content.response.responses;
                } else {
                    resultData.push({
                        "preProvisionedDevice": 
                            {
                                "hardwareModel": "",
                                "serialNumber": "",
                                "attestedDeviceId": "",
                                "deviceState": othersContent.content.response.error.status,
                                "brandCode": '',
                                "name": '',
                                "preProvisionerId": '',
                                "errMessage": othersContent.content.response.error.message,
                                "errCode": othersContent.content.response.error.code
                            }
                        
                    })
                }
            }
        } else {
            post_type = "single";
            if(othersContent.content.type === global.config.zteType[1]) { 
                if(othersContent.content.response.error !== undefined) { 
                    resultData.push({
                        "status": othersContent.content.response.error.status,
                        "brandCode": '',
                        "name": '',
                        "preprovision_deviceid": othersContent.content.request.preprovision_deviceid,
                        "message": othersContent.content.response.error.message,
                        "code": othersContent.content.response.error.code
                    })
                } else {
                    resultData = othersContent.content.response;
                } 
            } else {
                if(othersContent.content.response.error !== undefined) {
                    resultData.push({
                        "preProvisionedDevice": 
                            {
                                "hardwareModel": othersContent.content.request.hardwareModel,
                                "serialNumber": othersContent.content.request.serialNumber,
                                "attestedDeviceId": othersContent.content.request.attestedDeviceId,
                                "deviceState": othersContent.content.response.error.status,
                                "brandCode": '',
                                "name": '',
                                "preProvisionerId": '',
                                "errMessage": othersContent.content.response.error.message,
                                "errCode": othersContent.content.response.error.code
                            }
                        
                    })
                } else {
                    resultData = othersContent.content.response.responses;
                }
            }
        }
        this.setState({
            postType: post_type,
            createDate: cDatez,
            createBy: othersContent.content.createByName,
            domainName: domain_name,
            customerId: customer_id,
            preprovToken: provisioning_token,
            fileId: file_id,
            linkUrl: link_url,
            data: resultData,
            actiontype: othersContent.content.type
        })
        document.getElementById('loading-area').style.display = 'none'; 
    }
    render() {
        var resultList = [];
        if(this.state.data.length > 0) {
            for(var ls=0; ls < this.state.data.length; ls++) {
                var retResult = this.state.data[ls];
                if(this.state.actiontype === global.config.zteType[1]) {
                    resultList.push(<tr key={ls+1}>
                        <th scope="row-field">{ls+1}</th>
                        <td>{retResult.preprovision_deviceid}</td>
                        <td>{retResult.serial_number}</td>
                        <td>
                            <div>{retResult.status}</div>
                            {(retResult.message !== undefined)? 
                            <div className="font-sublist">{retResult.message}</div>
                            : <div></div> }
                            {(retResult.code !== undefined)? 
                            <div className="font-sublist">( Code : {retResult.code} )</div>
                            : <div></div> }
                        </td>

                    </tr>)
                } else {
                    if(retResult.preProvisionedDevice !== undefined) {
                        resultList.push(<tr key={ls+1}>
                            <th scope="row-field">{ls+1}</th>
                            <td>{retResult.preProvisionedDevice.preProvisionedDeviceId}</td>
                            <td>{retResult.preProvisionedDevice.serialNumber}</td>
                            <td>{retResult.preProvisionedDevice.attestedDeviceId}</td>
                            <td>
                                <div>{retResult.preProvisionedDevice.hardwareModel}</div>
                                {(retResult.preProvisionedDevice.brandCode !== "")? 
                                <div className="mgT05">( Brand : {retResult.preProvisionedDevice.brandCode} )</div>
                                : <div></div> }
                            </td>
                            <td>{retResult.preProvisionedDevice.preProvisionerId}</td>
                            <td>
                                <div>{retResult.preProvisionedDevice.deviceState}</div>
                                {(retResult.preProvisionedDevice.errMessage !== undefined)? 
                                <div className="font-sublist">{retResult.preProvisionedDevice.errMessage}</div>
                                : <div></div> }
                                {(retResult.preProvisionedDevice.errCode !== undefined)? 
                                <div className="font-sublist">( Code : {retResult.preProvisionedDevice.errCode} )</div>
                                : <div></div> }
                            </td>

                        </tr>)
                    } else {
                        resultList.push(<tr key={ls+1}>
                            <th scope="row-field">{ls+1}</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                <div>{retResult.status.message}</div>
                                <div className="mgT05 font-sublist">Code : {retResult.status.code}</div>
                            </td>

                        </tr>)
                    }
                }
            }
        } else {
            resultList.push(<tr key="0">
                    <td colSpan="6" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
                </tr>)
        }
        return (
            <div className="main-container">
                <div className="mgT20">
                    <div className="content-container card card-body">
                        <div className="mgT20" id="detail-info">
                            <div className="flex-container">
                                <div className="w30p">Created on </div>
                                <div className="mgL10"> {this.state.createDate} <span className="mgL30">by.</span><span className="mgL10"> {this.state.createBy}</span></div>
                            </div>
                            <div className="mgT20 flex-container">
                                <div className="w30p">Domain </div>
                                <div className="mgL10"> {this.state.domainName} </div>
                            </div>
                            {(this.state.customerId !== "" && this.state.customerId !== undefined)? 
                            <div className="mgT10 flex-container">
                                <div className="w30p">Customer Id</div>
                                <div className="mgL10"> {this.state.customerId} </div>
                            </div>
                            : <div></div> }
                            {(this.state.preprovToken !== "")? 
                            <div className="mgT05 flex-container">
                                <div className="w30p">Pre-provisioning Token</div>
                                <div className="mgL10"> {this.state.preprovToken} </div>
                            </div>
                            : <div></div> }
                            {(this.state.linkUrl !== "")? 
                            <div className="mgT20 flex-container">
                                <div className="w30p">Reference Files</div>
                                <div className="mgL10" > 
                                    <div className="flex-container">
                                        <div id="fileurl" className="textWrap cursor-on" style={{width:"400px"}} onClick={this.urlopenFile.bind(this, this.state.linkUrl)}>{this.state.linkUrl}</div>
                                        <div className="mgL20"><OpenInNewIcon className="iconGrey mgR20 cursor-on" onClick={this.urlopenFile.bind(this, this.state.linkUrl)}/></div>
                                    </div>
                                </div>
                            </div>
                            : <div></div> }
                        </div>
                        {(this.state.actiontype === global.config.zteType[1])? 
                        <div className="row-field mgT30">
                            <table className={this.state.styleTb}>
                            <thead>
                                <tr key="0">
                                <th scope="col">#</th>
                                <th scope="col">PreProvision Device Id</th>
                                <th scope="col">Serial Number</th>
                                <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {resultList}
                            </tbody>
                            </table>
                        </div>
                        :
                        <div className="row-field mgT30">
                            <table className={this.state.styleTb}>
                            <thead>
                                <tr key="0">
                                <th scope="col">#</th>
                                <th scope="col">PreProvision Device Id</th>
                                <th scope="col">Serial Number</th>
                                <th scope="col">AD ID</th>
                                <th scope="col">Hardware Model</th>
                                <th scope="col">Provision Id</th>
                                <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {resultList}
                            </tbody>
                            </table>
                        </div>
                        }
                    </div>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
        )
    }
}

export default ZTELogDetail;