import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import ReplaceAllContent from '../../inc/replace-all';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[1];

class IClientForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        clientdoc_id: '',
        client_name: '',
        domain_name: '',
        client_country: '',
        client_address: '',
        client_phone: '',
        client_old_data: {client_name: '', domain_name: '', client_country: '', client_address: '', client_phone: ''},
        formErrors: {client_name: '', domain_name: '', client_country: ''},
        clientnameValid: false,
        domainnameValid: false,
        client_countryValid: false,
        formValid: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleBack = () => {
    this.props.history.goBack();
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    const inputname = e.target.name;
        this.setState( prevState => {
            return {  
                ...prevState.client_country, client_country: value
            }
          }, () => { this.validateField(inputname, value)
        }) 
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let clientnameValid = this.state.clientnameValid;
    let domainnameValid = this.state.domainnameValid;
    let clientcountryValid = this.state.clientcountryValid;
    
    switch(fieldName) {
        case 'client_name': 
            clientnameValid = value.trim().length > 0;
            fieldValidationErrors.client_name = clientnameValid ? '' : 'client name need to be filled';
            break;
        case 'domain_name': 
            domainnameValid = value.trim().length > 0;
            fieldValidationErrors.domain_name = domainnameValid ? '' : 'domain name need to be filled';
            break;
        case 'client_country': 
            clientcountryValid = value.trim().length > 0;
            fieldValidationErrors.client_country = clientcountryValid ? '' : 'country need to be filled';
            break;
        default:
            break;
    }
    
    this.setState({formErrors: fieldValidationErrors,
                    clientnameValid: clientnameValid,
                    domainnameValid: domainnameValid,
                    clientcountryValid: clientcountryValid,
                }, this.validateForm);
  }
  validateForm() {
    this.setState({formValid: this.state.clientnameValid && this.state.domainnameValid && this.state.clientcountryValid});
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  handleSubmit(e) {
    e.preventDefault();
    if(this.state.client_name !== '' && this.state.domain_name !== '' && this.state.client_country !== '') {
        document.getElementById('loading-area').style.display = 'block'; 
        var urlFetch, datatext;
        var clientName,domainName,clientCountry,clientAddress,clientPhone;
            
        if(this.state.clientdoc_id !== null && this.state.clientdoc_id !== ''  && this.state.clientdoc_id !== undefined) { 
            if(this.textInputClientName.value === '') { clientName = this.state.client_name; } else { clientName = this.textInputClientName.value; }
            if(this.textInputDomainName.value === '') { domainName = this.state.domain_name; } else { domainName = this.textInputDomainName.value; }
            if(this.textInputClientCountry.value === '') { clientCountry = this.state.client_country; } else { clientCountry = this.textInputClientCountry.value; }
            if(this.textInputClientAddress.value === '') { clientAddress = this.state.client_address; } else { clientAddress = this.textInputClientAddress.value; }
            if(this.textInputClientPhone.value === '') { clientPhone = this.state.client_phone; } else { clientPhone = this.textInputClientPhone.value; }
            
            if(clientAddress !== '') { clientAddress = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',clientAddress)); }
            
            var flagHistory=false;
            if(this.state.client_old_data.client_name !== clientName) { flagHistory = true; }
            else if(this.state.client_old_data.domain_name !== domainName) { flagHistory = true; }
            else if(this.state.client_old_data.client_country !== clientCountry) { flagHistory = true; }
            var clientolddata = '{';
            clientolddata += '"client_name":"'+this.state.client_old_data.client_name+'",';
            clientolddata += '"domain_name":"'+this.state.client_old_data.domain_name+'",';
            clientolddata += '"client_country":"'+this.state.client_old_data.client_country+'"';
            clientolddata += '}';
            
            datatext ='{"client_name":"'+clientName+'","domain_name":"'+domainName+'","client_country":"'+clientCountry+'","client_address":"'+clientAddress+'","client_phone":"'+clientPhone+'","fg_history":'+flagHistory+',"client_olddata":'+clientolddata+',"client_docid":"'+this.state.clientdoc_id+'","form_id":"form-edit"}';
            urlFetch = global.config.urlLink+"/clientedit";
        } else {
            if(clientAddress !== '') { clientAddress = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',this.state.client_address)); }
            datatext ='{"client_name":"'+this.state.client_name+'","domain_name":"'+this.state.domain_name+'","client_country":"'+this.state.client_country+'","client_address":"'+clientAddress+'","client_phone":"'+this.state.client_phone+'"}';
            urlFetch = global.config.urlLink+"/clientadd";
        }
        var data = JSON.parse(datatext); 
        
        fetch(urlFetch, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': authKey,
            }, body : JSON.stringify(data)
          }).then(res => {
            if(res.status === 200) 
                return res.json() 
          }).then( resJson => {
            document.getElementById('loading-area').style.display = 'none'; 
            if(resJson.status === '200') {
                window.location.href='/IClients';
            } else {
                console.log('Something happened wrong');
            }
          }).catch(err => err);

    } else {
        var arrfieldName = ['client_name','domain_name','client_country','client_address','client_phone'];
        var arrvalue = [this.textInputClientName.value,this.textInputDomainName.value,this.textInputClientCountry.value,this.textInputClientAddress.value,this.textInputClientPhone.value];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
  }
  componentDidMount() {
    var geturl = this.props.location; 
    var arrString = geturl.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');
    
    if(arrUrl[1] === 'IEditClients') {
        if(arrUrl[2] !== '') { 
            document.getElementById('loading-area').style.display = 'block';
            fetch(global.config.urlLink+"/clientview/"+arrUrl[2], {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none';
                if(resJson.status === '200') {
                    var cName="",domainName="",cCountry="",cAddress="",cPhone="";
                    if(resJson.values[0].content[0].maincontent.clientName !== undefined) { cName = resJson.values[0].content[0].maincontent.clientName; }
                    if(resJson.values[0].content[0].maincontent.domainName !== undefined) { domainName = resJson.values[0].content[0].maincontent.domainName; }
                    if(resJson.values[0].content[0].maincontent.clientCountry !== undefined) { cCountry = resJson.values[0].content[0].maincontent.clientCountry; }
                    if(resJson.values[0].content[0].maincontent.clientAddress !== undefined) {
                        cAddress = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resJson.values[0].content[0].maincontent.clientAddress));
                    }
                    if(resJson.values[0].content[0].maincontent.clientPhone !== undefined) { cPhone = resJson.values[0].content[0].maincontent.clientPhone; }
                    
                    this.setState({
                        stTitle: 'Edit Client',
                        clientdoc_id: resJson.values[0].docid,
                        client_name: cName,
                        domain_name: domainName,
                        client_country: cCountry,
                        client_address: cAddress,
                        client_phone: cPhone,
                        client_old_data: {client_name: cName, domain_name: domainName, client_country: cCountry, client_address: cAddress, client_phone: cPhone},
                        formErrors: {client_name: '', domain_name: '', client_country: ''},
                        clientnameValid: false,
                        domainnameValid: false,
                        clientcountryValid: false,
                        formValid: false,
                    }) 
                } else {
                    console.log('Something wrong happened');
                }
              }).catch(err => { 
                  document.getElementById('loading-area').style.display = 'none'; 
              });
        }
    } else if(arrUrl[1] === 'IAddClients') {
        this.setState( prevState => {
            return {  
                ...prevState.stTitle, stTitle: 'New Client'
            }
        })
    } 
  }

  render() {
    var arrCountry = ["Singapore","Malaysia","Indonesia","United States","United Kingdom","Afghanistan","Albania","Algeria","American Samoa","Andorra","Angola",
    "Anguilla","Antarctica","Antigua and Barbuda","Argentina","Armenia","Aruba","Australia","Austria","Azerbaijan","Bahamas","Bahrain",
    "Bangladesh","Barbados","Belarus","Belgium","Belize","Benin","Bermuda","Bhutan","Bolivia","Bosnia and Herzegovina","Botswana","Bouvet Island",
    "Brazil","British Indian Ocean Territory","Brunei Darussalam","Bulgaria","Burkina Faso","Burundi","Cambodia","Cameroon","Canada","Cape Verde",
    "Cayman Islands","Central African Republic","Chad","Chile","China","Christmas Island","Cocos (Keeling) Islands","Colombia","Comoros",
    "Congo","Congo, The Democratic Republic of The","Cook Islands","Costa Rica","Cote D'ivoire","Croatia","Cuba","Cyprus","Czech Republic",
    "Denmark","Djibouti","Dominica","Dominican Republic","Ecuador","Egypt","El Salvador","Equatorial Guinea","Eritrea","Estonia","Ethiopia",
    "Falkland Islands (Malvinas)","Faroe Islands","Fiji","Finland","France","French Guiana","French Polynesia","French Southern Territories",
    "Gabon","Gambia","Georgia","Germany","Ghana","Gibraltar","Greece","Greenland","Grenada","Guadeloupe","Guam","Guatemala","Guinea",
    "Guinea-bissau","Guyana","Haiti","Heard Island and Mcdonald Islands","Holy See (Vatican City State)","Honduras","Hong Kong",
    "Hungary","Iceland","India","Iran, Islamic Republic of","Iraq","Ireland","Israel","Italy","Jamaica","Japan","Jordan","Kazakhstan",
    "Kenya","Kiribati","Korea, Democratic People's Republic of","Korea, Republic of","Kuwait","Kyrgyzstan","Lao People's Democratic Republic",
    "Latvia","Lebanon","Lesotho","Liberia","Libyan Arab Jamahiriya","Liechtenstein","Lithuania","Luxembourg","Macao","Macedonia, The Former Yugoslav Republic of",
    "Madagascar","Malawi","Maldives","Mali","Malta","Marshall Islands","Martinique","Mauritania","Mauritius","Mayotte","Mexico","Micronesia, Federated States of",
    "Moldova, Republic of","Monaco","Mongolia","Montserrat","Morocco","Mozambique","Myanmar","Namibia","Nauru","Nepal","Netherlands","Netherlands Antilles",
    "New Caledonia","New Zealand","Nicaragua","Niger","Nigeria","Niue","Norfolk Island","Northern Mariana Islands","Norway","Oman","Pakistan","Palau",
    "Palestinian Territory, Occupied","Panama","Papua New Guinea","Paraguay","Peru","Philippines","Pitcairn","Poland","Portugal","Puerto Rico","Qatar","Reunion",
    "Romania","Russian Federation","Rwanda","Saint Helena","Saint Kitts and Nevis","Saint Lucia","Saint Pierre and Miquelon","Saint Vincent and The Grenadines",
    "Samoa","San Marino","Sao Tome and Principe","Saudi Arabia","Senegal","Serbia and Montenegro","Seychelles","Sierra Leone","Slovakia","Slovenia",
    "Solomon Islands","Somalia","South Africa","South Georgia and The South Sandwich Islands","Spain","Sri Lanka","Sudan","Suriname","Svalbard and Jan Mayen",
    "Swaziland","Sweden","Switzerland","Syrian Arab Republic","Taiwan, Province of China","Tajikistan","Tanzania, United Republic of","Thailand","Timor-leste",
    "Togo","Tokelau","Tonga","Trinidad and Tobago","Tunisia","Turkey","Turkmenistan","Turks and Caicos Islands","Tuvalu","Uganda","Ukraine","United Arab Emirates",
    "United Kingdom","United States","United States Minor Outlying Islands","Uruguay","Uzbekistan","Vanuatu","Venezuela","Vietnam","Virgin Islands, British",
    "Virgin Islands, U.S.","Wallis and Futuna","Western Sahara","Yemen","Zambia","Zimbabwe"];
    var countryset = [];
    for(var c=0; c<arrCountry.length; c++) {
        countryset.push(<option value={arrCountry[c]}>{arrCountry[c]}</option>);
    }
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
        <div className="flex-container">
            <div className="sidebar-main"><ISidemenu/></div>
            <div className="main-content">
            <div className="stTitle">Clients</div>
            {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[0] === "ON") || (JSON.parse(localStorage.getItem('urole'))[1] === "ON")  || (JSON.parse(localStorage.getItem('urole'))[7] === "ON"))? 
            <div>
                <div className="stIndiTitle">Clients { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                        
                <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="flex-container">
                            <div className="st2Title w70p">{ this.state.stTitle.includes("New")? 'Add ' : '' } {this.state.stTitle}</div>
                            <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        <div id="FormCenter" className="mgT20">
                            <form onSubmit={this.handleSubmit}>
                                <div className="flex-container">
                                    <input type="hidden" required name="clientdoc_id" value={this.state.clientdoc_id} />
                                </div>
                                <div className="row-field mgT20">
                                    <label className="labelFormL" htmlFor="client_name">Company Name</label>
                                    <div className="w70p">
                                        <input type="text" className="form-control" name="client_name"
                                            placeholder={this.state.client_name}
                                            value={this.state.client_name}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputClientName = input}/>
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.client_name}/>  }</div>
                                    </div>
                                </div>
                                
                                <div className="row-field mgT20">
                                    <label className="labelFormL" htmlFor="domain_name">Domain Name</label>
                                    <div className="w70p">
                                        <input type="text" className="form-control" name="domain_name"
                                            placeholder={this.state.domain_name}
                                            value={this.state.domain_name}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputDomainName = input}/>
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.domain_name}/>  }</div>
                                    </div>
                                </div>
                                <div className="row-field mgT20">
                                    <label className="labelFormL" htmlFor="client_address">Company Address</label>
                                    <div className="w70p">
                                        <textarea name="client_address" className="form-control" 
                                                rows={5} cols={30} 
                                                value={this.state.client_address} 
                                                onChange={this.handleUserInput} 
                                                ref={(input) => this.textInputClientAddress = input}/>
                                    </div>
                                </div>
                            
                                <div className="row-field mgT20">
                                    <label className="labelFormL" htmlFor="client_country">Country</label>
                                    <div className="w70p">
                                        <select name="client_country" className="form-control"
                                                value={this.state.client_country} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputClientCountry = input}>
                                            <option value="">-- please select --</option>
                                            {countryset}
                                            </select> 
                                        
                                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.client_country}/>  }</div>
                                    </div>
                                </div>
                                <div className="row-field mgT20">
                                    <label className="labelFormL" htmlFor="client_phone">Company Phone Number</label>
                                    <div className="w70p">
                                        <input type="text" className="form-control" name="client_phone"
                                            placeholder={this.state.client_phone}
                                            value={this.state.client_phone}
                                            onChange={this.handleUserInput}  
                                            ref={(input) => this.textInputClientPhone = input}/>
                                    </div>
                                </div>
                                <div className="row-field mgT30 mgB30">
                                    <div className="labelFormL"></div>
                                    <div className="w70p"><button type="submit" className="w100p submitButton">Submit</button></div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* ====== */}
                </div>
            </div>
            : <div><INA/></div>
            }
            </div>
        </div>
        <div id="loading-area" className="divHide loading"></div> 
    </div>
   );
 }
}
 
export default IClientForm;