import React, { Component } from 'react';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[12];
  
function urlgetTemplate() {
    window.open("https://docs.google.com/spreadsheets/d/1BCF1pEhdYVdN4dAQSKA6ka0fO2nnAqxwA0WP2euN4ZY/","_blank");
}
function insertFunction(libid,examid,content) {
    return new Promise(resolve => {
        var libquestionText, libquestionType, libquestionNumber, libquestionAnswer;
        var libquestionOption1, libquestionOption2, libquestionOption3, libquestionOption4, libquestionOption5;

        if(content.length > 0) {
            var sn=0;
            for(var a=0; a<content.length; a++) {
                var datacontent = content[a];
                libquestionText = datacontent[2];
                libquestionType = datacontent[1]; 
                libquestionNumber = (a+1);
                libquestionAnswer = datacontent[8];
                libquestionOption1 = datacontent[3];
                libquestionOption2 = datacontent[4];
                libquestionOption3 = datacontent[5];
                libquestionOption4 = datacontent[6];
                libquestionOption5 = datacontent[7];
                var datatext2 ='{"library_id":"'+libid+'","libexam_id":"'+examid+'","libquestion_text":"'+libquestionText+'","libquestion_type":"'+libquestionType+'","libquestion_number":"'+libquestionNumber+'","libquestion_answer":"'+libquestionAnswer+'","libquestion_option1":"'+libquestionOption1+'","libquestion_option2":"'+libquestionOption2+'","libquestion_option3":"'+libquestionOption3+'","libquestion_option4":"'+libquestionOption4+'","libquestion_option5":"'+libquestionOption5+'","form_id":"form-addquestion"}';
                var data2 = JSON.parse(datatext2);
                fetch(global.config.urlLink+"/libraryquestionadd", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey,
                    }, body : JSON.stringify(data2)
                }).then(res => {
                    if(res.status === 200) 
                        return res.json() 
                }).then( resJson => {
                    sn++;
                    if(sn===content.length) {
                        resolve("DONE");
                    }
                }).catch(err => err);
                // resolve("DONE");
            }
        } else { resolve("EMPTY"); }
    });
    
    

    
}; 

async function asyncCall(libid,examid,content) {
    const result = await insertFunction(libid,examid,content);
    return result;
}
class ILibraryQuestionImport extends Component {
    constructor (props) {
        super(props);
        this.state = {
            library_id: '',
            library_title: '',
            libexam_id: '',
            libexam_title: '',
            libquestion_file: '',
            formErrors: { libquestion_file: ''},
            libquestionFileValid: false,
            formValid: false,
            input_disabled: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let libquestionFileValid = this.state.libquestionFileValid;
        
        switch(fieldName) {
            case 'libquestion_file': 
                libquestionFileValid = value.trim().length > 0;
                fieldValidationErrors.libquestion_text = libquestionFileValid ? '' : 'field need to be filled';
                break;
            default:
                break;
        }
        
        this.setState({formErrors: fieldValidationErrors,
            libquestionFileValid: libquestionFileValid
        }, this.validateForm);
    }

    validateForm() {
        this.setState({formValid: this.state.libquestionFileValid});
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }

    handleSubmit(e) {
        e.preventDefault();
        if(this.state.libquestion_file !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var libquestionFile="";

            if(this.textInputLibquestionFile.value === '') { libquestionFile = this.state.libquestion_file; } else { libquestionFile = this.textInputLibquestionFile.value; }
                var arrLinkPath = libquestionFile.replace("https://","").split('/');
                var fileid = libquestionFile;
                if(arrLinkPath.length > 0) {
                    fileid = arrLinkPath[3];
                }
                var rangez = "Sheet1!A2:I";
                var datatext = '{"fileid":"'+fileid+'","range":"'+rangez+'","fromset":"libquestion-import"}';
                var data = JSON.parse(datatext); 
                fetch(global.config.urlLink+"/getssdata", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey,
                    }, body : JSON.stringify(data)
                }).then(res => {
                    if(res.status === 200) 
                        return res.json() 
                }).then( resJson => {
                    document.getElementById('loading-area').style.display = 'none'; 
                    if(resJson.status === '200') {
                        asyncCall(this.state.library_id,this.state.libexam_id,resJson.values)
                        .then((response) => {
                            document.getElementById('loading-area').style.display = 'none'; 
                            window.location.href='/IEditDetailLibrary/'+this.state.library_id;
                        })
                    } else {
                        console.log('Something happened wrong');
                    }
                }).catch(err => {
                    document.getElementById('loading-area').style.display = 'none'; 
                });
            
            
        } else {
            var arrfieldName = ['libquestion_file'];
            var arrvalue = [this.textInputLibquestionFile.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }

    componentDidMount() {
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        document.getElementById('loading-area').style.display = 'block';
        this.setState({
            library_id: othersContent.libraryId,
            library_title: othersContent.libraryTitle,
            libexam_id: othersContent.examId,
            libexam_title: othersContent.examTitle,
            libquestion_file: '',
            formErrors: { libquestion_file: ''},
            libquestionFileValid: false,
            formValid: false,
            input_disabled: false
            
        });
        document.getElementById('loading-area').style.display = 'none'; 
        
    }

    render() {
        return (
            <div className="main-container">
                <div className="mgT30">
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <input type="hidden" id="library_id" name="library_id" value={this.state.library_id} />
                        <input type="hidden" id="libexam_id" name="libexam_id" value={this.state.libexam_id} />
                        <input type="hidden" id="libquestion_id" name="libquestion_id" value={this.state.libquestion_id} />
                    </div>
                    <div className="row-field mgT20">
                        <div className="w80p"><h6>{this.state.library_title}</h6></div>
                        <div className="w20p form-inline justify-content-end"><button className="subgenButton" onClick={urlgetTemplate}>get a template</button></div>
                    </div>
                    <div className="row-field mgT30">
                        <label className="w30p textMiddle" htmlFor="libquestion_option1">Link Drive Url</label>
                        <div className="w70p">
                            <input type="text" className="form-control" name="libquestion_file"
                                placeholder={this.state.libquestion_file}
                                value={this.state.libquestion_file}
                                onChange={this.handleUserInput} 
                                ref={(input) => this.textInputLibquestionFile = input}
                                disabled={this.state.input_disabled}
                                required/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.libquestion_file}/>  }
                            </div>
                            <div className="mgT10 warningText">
                            Set your sharing file to "Anyone on the internet with the link can view"
                            </div>
                        </div>
                    </div>
                    
                    <div className="row-field mgT30 mgB30">
                        <div className="w100p"><button id="submit-button"  type="submit" className="w100p submitButton">Submit</button></div>
                    </div>
                </form>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
          );
        }
     }
      
export default ILibraryQuestionImport;
