import React, { Component } from 'react';
import '../../inc/genstyle.css';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import DescriptionIcon from '@material-ui/icons/Description';
import DateTimeFormats from '../../inc/date-time-format';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[8];
 
class ILogNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        styleTb: 'table',
        data: []
    };
    this.loadData = this.loadData.bind(this);
  }
  loadData() {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/lognotiflist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200' && resJson.values.length > 0) { 
            var arrList = [];
            var arrSortByDesc = [];
            for(var x=0; x<resJson.values.length; x++) {
                arrList.push({
                    docid: resJson.values[x].docid,
                    createdDate: resJson.values[x].createdate,
                    content: resJson.values[x].content,
                    codez: resJson.values[x].content.codes
                })
            }
            if(arrList.length>0) {
                arrSortByDesc = arrList.sort((c,d) => {
                    return d.codez - c.codez;
                })
              }
            this.setState({
                styleTb: 'table table-striped',
                data: arrSortByDesc
            })
        } else {
            this.setState({
                styleTb: 'table',
                data: []
            })
        }
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  componentDidMount() {
    this.loadData();
  }

  render() {
    var logNotifList = [];
    if(this.state.data.length > 0) {
     for(var ls=0; ls < this.state.data.length; ls++) {
         var retResult = this.state.data[ls];
         var viewURL = "/IViewLogNotif/"+retResult.docid;
         var cDatez = "";
         if(retResult.content.createDate !== undefined) {
              cDatez = DateTimeFormats(retResult.content.createDate._seconds,"date-short");
         }
         logNotifList.push(<tr key={ls+1}>
             <th scope="row-field">{ls+1}</th>
             <td>{cDatez}</td>
             <td>{retResult.content.subjectLog}</td>
             <td>
                 <a href={viewURL}><DescriptionIcon className="iconGrey mgR20" /></a>
             </td>
           </tr>)
     }
    } else {
     logNotifList.push(<tr key="0">
           <td colSpan="4" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
         </tr>)
    }
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Log Notification</div>
                <div className="stIndiTitle">List of log notification</div>
                <div className="mgT30">
                    {/*--*/}
                    <div className="content-container card card-body">
                        
                        <div className="row-field mgT20">
                            <table className={this.state.styleTb}>
                            <thead>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Create Date</th>
                                <th scope="col">Description</th>
                                <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logNotifList}
                            </tbody>
                            </table>
                        </div>
                        <div className="row mt-3">
                            
                        </div>
                    </div>
                    {/*--*/}
                </div>
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div> 
    </div>
   );
 }
}
 
export default ILogNotification;