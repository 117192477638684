import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import './inc/genstyle.css';
import Screens from './screen';
import ILogin from './internal/ipages/ilogin';
import IDashboard from './internal/ipages/ihomepage';
import IClient from './internal/ipages/iclient-list';
import IClientForm from './internal/ipages/iclient-form';
import IClientDetail from './internal/ipages/iclient-detail';
import IClientLicence from './internal/ipages/iclient-licence';
import IReqRenewLicence from './internal/ipages/ireqrenewlicence-list';
import IReqAddLicence from './internal/ipages/ireqaddlicence-list';
import IReqClaimGCP from './internal/ipages/ireqclaimgcp-list';
import IReqUpgradeLicence from './internal/ipages/irequpgrade-list';
import IReqTraining from './internal/ipages/ireqtraining-list';
import ILicencesExpired from './internal/ipages/ilicenceexp-list';
import ILicencesType from './internal/ipages/ilicencetype-list';
import IAddContents from './internal/ipages/iaddcontent-list';
import IAddContentsForm from './internal/ipages/iaddcontent-form';
import IAddContentsDetail from './internal/ipages/iaddcontent-detail';
import IClientMessages from './internal/ipages/iclientmessage-list';
import IMyProfiles from './internal/ipages/imyprofile';
import IMember from './internal/ipages/imember-list';
import IMemberForm from './internal/ipages/imember-form';
import IMemberSetForm from './internal/ipages/imember-set-form';
import ILogNotification from './internal/ipages/ilognotification-list';
import ILogNotifDetail from './internal/ipages/ilognotification-detail';
import ILibrary from './internal/ipages/ilibrary-list';
import ILibraryForm from './internal/ipages/ilibrary-form';
import ILibraryDetailEdit from './internal/ipages/ilibrary-detail-edit';
import ILibraryDetail from './internal/ipages/ilibrary-detail';
import ICourse from './internal/ipages/icourse-list';
import ICourseForm from './internal/ipages/icourse-form';
import ICourseDetail from './internal/ipages/icourse-detail';
import ICourseDetailEdit from './internal/ipages/icourse-detail-edit';
import ICategory from './internal/ipages/icategory-list';
import ITutor from './internal/ipages/itutor-list';
import ITutorForm from './internal/ipages/itutor-form';
import ITutorDetail from './internal/ipages/itutor-detail';
import ITutorDetailEdit from './internal/ipages/itutor-detail-edit';
import IUserTrainings from './internal/ipages/iusertraining-list';
import IUserTrainingsForm from './internal/ipages/iusertraining-form';
import IUserTrainingsDetail from './internal/ipages/iusertraining-detail';
import IZTEForm from './internal/ipages/izte-form';
import IZTElog from './internal/ipages/izte-log-list';
import IZTEFormDel from './internal/ipages/izte-formdel';
import IPayment from './internal/ipages/ipayment-list';
import IFeedback from './internal/ipages/ifeedback-list';
import IFeedbackForm from './internal/ipages/ifeedback-form';
import IFeedbackDetail from './internal/ipages/ifeedback-detail';
import ISExtDrive from './internal/ipages/iextdrive-list';

import CLogin from './customer/cpages/clogin';
import CDashboard from './customer/cpages/chomepage';
import CMyProfiles from './customer/cpages/cmyprofile';
import CNewsList from './customer/cpages/cnews-list';
import CNewsDetail from './customer/cpages/cnews-detail';
import CAbout from './customer/cpages/cabout';
import CReqAddLicence from './customer/cpages/creqaddlicence-form';
import CReqClaim from './customer/cpages/creqclaim-form';
import CReqUpgrade from './customer/cpages/crequpgrade-form';
import CReqTraining from './customer/cpages/creqtraining-form';
import CReqRenewLicence from './customer/cpages/creqrenew-form';
import CContact from './customer/cpages/ccontact';
import CRequestList from './customer/cpages/crequest-list';

import CAppNewsDetail from './customer/cpages/cappnews-detail';

import ITestDemo from './internal/ipages/testdemoapi';
import IAddTestDemo from './internal/ipages/testdemoapi-form';

import ITestDemoThankyouBot from './internal/ipages/testdemo-thankyoubot';
import IAddTestDemoThankyouBot from './internal/ipages/testdemo-thankyoubot-form';

var mainPage = Screens;
var homePage = ILogin, clientPage = ILogin, addclientPage = ILogin, renewPage = ILogin, addlicencePage = ILogin, licenceexpPage = ILogin, licencetypePage = ILogin, detailclientPage = ILogin, licenceclientPage = ILogin; 
var claimPage = ILogin, upgradePage = ILogin, trainingPage = ILogin,  clientmessagePage = ILogin;
var addcontentPage = ILogin, addnewcontentPage = ILogin, detailaddcontentPage = ILogin;
var myprofilePage = ILogin, memberPage = ILogin, addmemberPage = ILogin, setmemberPage = ILogin;
var logNotification = ILogin, detaillognotifPage = ILogin;
var libraryPage = ILogin, addlibraryPage = ILogin, editlibraryPage = ILogin, detaillibraryPage = ILogin;
var coursePage = ILogin, addcoursePage = ILogin, editcoursePage = ILogin, detailcoursePage = ILogin;
var categoryPage = ILogin; 
var tutorPage = ILogin, addtutorPage = ILogin, edittutorPage = ILogin, detailtutorPage = ILogin; 
var usertrainingPage = ILogin, addusertrainingPage = ILogin, detailusertrainingPage=ILogin;
var paymentPage = ILogin;
var ztePage = ILogin, ztelogPage = ILogin, ztedelPage = ILogin;
var feedbackPage = ILogin, addfeedbackPage = ILogin, editfeedbackPage = ILogin, detailfeedbackPage = ILogin;
var myextdrivePage = ILogin;

var custhomePage = CLogin;
var custnewsPage = CLogin, custnewsdetailPage= CLogin; 
var custaddlicencePage = CLogin;
var custclaimPage = CLogin;
var custupgradePage = CLogin;
var custreqtrainingPage = CLogin;
var custrenewlicencePage = CLogin;
var custcontactPage = CLogin;
var custmyprofilePage = CLogin;
var custaboutPage = CLogin;
var custrequestlistPage = CLogin;

var custappnewsdetailPage = CLogin;

var hostName = window.location.hostname;
var currentURL = window.location.pathname;
var arrPath = currentURL.split('/');

var portalname = "";
if(hostName === 'customers.point-star.com') { portalname = "customerportal"; }
else if(hostName === 'internal.point-star.com') { portalname = "internalportal"; }
else {
  if(arrPath[1] !== "") {
    if(arrPath[1].toLowerCase() === "customerportal") { portalname = "customerportal"; }
    else if(arrPath[1].toLowerCase() === "internalportal") { portalname = "internalportal"; }
    else {
      if(arrPath[1].toLowerCase().substring(0,1) === "c") { portalname = "customerportal"; }
      else if(arrPath[1].toLowerCase().substring(0,1) === "i") { portalname = "internalportal"; }
    }
  }
}

var sessionUID = '';
if(portalname === "customerportal") {
    if(localStorage.getItem('cuid') !== null) { 
      sessionUID = localStorage.getItem('cuid'); 
      mainPage =CDashboard;
    } else {
      sessionUID = "";
      mainPage =CLogin;
    }
} else if(portalname === "internalportal") {
    if(localStorage.getItem('uid') !== null) { 
      sessionUID = localStorage.getItem('uid'); 
      mainPage =IDashboard;
    } else {
      sessionUID = "";
      mainPage =ILogin;
    }
  } else {
    localStorage.clear();
}

if(sessionUID !== '') { 
    homePage=IDashboard;
    clientPage=IClient;
    addclientPage=IClientForm;
    detailclientPage=IClientDetail;
    licenceclientPage=IClientLicence;
    renewPage=IReqRenewLicence;
    addlicencePage=IReqAddLicence;
    claimPage=IReqClaimGCP;
    upgradePage=IReqUpgradeLicence;
    trainingPage=IReqTraining;
    licenceexpPage=ILicencesExpired;
    licencetypePage=ILicencesType;
    addcontentPage=IAddContents;
    addnewcontentPage=IAddContentsForm;
    detailaddcontentPage=IAddContentsDetail;
    clientmessagePage=IClientMessages;
    myprofilePage=IMyProfiles;
    memberPage=IMember;
    addmemberPage=IMemberForm;
    setmemberPage=IMemberSetForm;
    logNotification=ILogNotification;
    detaillognotifPage=ILogNotifDetail;
    libraryPage=ILibrary;
    addlibraryPage=ILibraryForm;
    editlibraryPage=ILibraryDetailEdit;
    detaillibraryPage=ILibraryDetail;
    coursePage=ICourse;
    addcoursePage=ICourseForm;
    editcoursePage=ICourseDetailEdit;
    detailcoursePage=ICourseDetail;
    categoryPage=ICategory;
    tutorPage=ITutor;
    addtutorPage=ITutorForm;
    edittutorPage=ITutorDetailEdit;
    detailtutorPage=ITutorDetail;
    usertrainingPage=IUserTrainings;
    addusertrainingPage=IUserTrainingsForm;
    detailusertrainingPage = IUserTrainingsDetail;
    paymentPage=IPayment;
    ztePage = IZTEForm;
    ztelogPage = IZTElog;
    ztedelPage = IZTEFormDel;
    feedbackPage=IFeedback;
    addfeedbackPage=IFeedbackForm;
    editfeedbackPage=IFeedbackForm;
    detailfeedbackPage=IFeedbackDetail;
    myextdrivePage=ISExtDrive;
} 

custhomePage=CDashboard;
custnewsPage = CNewsList; 
custnewsdetailPage = CNewsDetail;
custaddlicencePage = CReqAddLicence;
custclaimPage = CReqClaim;
custupgradePage = CReqUpgrade;
custreqtrainingPage = CReqTraining;
custrenewlicencePage = CReqRenewLicence;
custcontactPage = CContact;
custmyprofilePage = CMyProfiles;
custaboutPage = CAbout;
custrequestlistPage = CRequestList;
custappnewsdetailPage = CAppNewsDetail;

const routing = (
  <Router>
      <div>
          <Route exact path="/" component={mainPage}></Route>
          <Route path="/CustomerPortal" component={custhomePage}></Route>
          <Route path="/InternalPortal" component={homePage}></Route>
          
          <Route path="/IClients" component={clientPage}></Route>
          <Route path="/IAddClients" component={addclientPage}></Route>
          <Route path="/IEditClients" component={addclientPage}></Route>
          <Route path="/IViewClients" component={detailclientPage}></Route>
          <Route path="/IViewLicence" component={licenceclientPage}></Route>
          
          <Route path="/IRenewLicence" component={renewPage}></Route>
          <Route path="/IAddLicence" component={addlicencePage}></Route>
          <Route path="/IClaimGCP" component={claimPage}></Route>
          <Route path="/IUpgradeLicence" component={upgradePage}></Route>
          <Route path="/ITraining" component={trainingPage}></Route>
          <Route path="/ILicenceExpired" component={licenceexpPage}></Route>
          <Route path="/ILicenceTypes" component={licencetypePage}></Route>
          <Route path="/IClientMessages" component={clientmessagePage}></Route>
          
          <Route path="/IAddContent" component={addcontentPage}></Route>
          <Route path="/IAddNewContent" component={addnewcontentPage}></Route>
          <Route path="/IEditAddContent" component={addnewcontentPage}></Route>
          <Route path="/IViewAddContent" component={detailaddcontentPage}></Route>
          
          <Route path="/IMyProfile" component={myprofilePage}></Route>

          <Route path="/IMembers" component={memberPage}></Route>
          <Route path="/IAddMembers" component={addmemberPage}></Route>
          <Route path="/IEditMembers" component={addmemberPage}></Route>
          <Route path="/IViewMembers" component={addmemberPage}></Route>
          <Route path="/ISetMembers" component={setmemberPage}></Route>

          <Route path="/ILogNotification" component={logNotification}></Route>
          <Route path="/IViewLogNotif" component={detaillognotifPage}></Route>
          
          <Route path="/ILibrary" component={libraryPage}></Route>
          <Route path="/IAddLibrary" component={addlibraryPage}></Route>
          <Route path="/IEditLibrary" component={addlibraryPage}></Route>
          <Route path="/IEditDetailLibrary" component={editlibraryPage}></Route>
          <Route path="/IViewLibrary" component={detaillibraryPage}></Route>
          
          <Route path="/ICourse" component={coursePage}></Route>
          <Route path="/IAddCourse" component={addcoursePage}></Route>
          <Route path="/IEditCourse" component={addcoursePage}></Route>
          <Route path="/IEditDetailCourse" component={editcoursePage}></Route>
          <Route path="/IViewCourse" component={detailcoursePage}></Route>
          
          <Route path="/ICategory" component={categoryPage}></Route>
          <Route path="/ICategoryLibrary" component={categoryPage}></Route>
          <Route path="/ICategoryCourse" component={categoryPage}></Route>
          
          <Route path="/ITutor" component={tutorPage}></Route>
          <Route path="/IAddTutor" component={addtutorPage}></Route>
          <Route path="/IEditTutor" component={addtutorPage}></Route>
          <Route path="/IEditDetailTutor" component={edittutorPage}></Route>
          <Route path="/IViewTutor" component={detailtutorPage}></Route>
          
          <Route path="/IUserTraining" component={usertrainingPage}></Route>
          <Route path="/IAddUserTraining" component={addusertrainingPage}></Route>
          <Route path="/IViewUserTraining" component={detailusertrainingPage}></Route>
          
          <Route path="/IPayment" component={paymentPage}></Route>

          <Route path="/IZTE" component={ztePage}></Route>
          <Route path="/IZTELog" component={ztelogPage}></Route>
          <Route path="/IZTEDel" component={ztedelPage}></Route>
          
          <Route path="/IFeedback" component={feedbackPage}></Route>
          <Route path="/IAddFeedback" component={addfeedbackPage}></Route>
          <Route path="/IEditFeedback" component={editfeedbackPage}></Route>
          <Route path="/IViewFeedback" component={detailfeedbackPage}></Route>
          
          <Route path="/IExtDrive" component={myextdrivePage}></Route>
          
          <Route path="/ILogin" component={ILogin}></Route>
          
          <Route path="/chome" component={custhomePage}></Route>
          <Route path="/cnews" component={custnewsPage}></Route>
          <Route path="/cnewsview" component={custnewsdetailPage}></Route>
          <Route path="/caddlicence" component={custaddlicencePage}></Route>
          <Route path="/cclaim" component={custclaimPage}></Route>
          <Route path="/cupgrade" component={custupgradePage}></Route>
          <Route path="/creqtraining" component={custreqtrainingPage}></Route>
          <Route path="/crenewlicence" component={custrenewlicencePage}></Route>
          <Route path="/ccontact" component={custcontactPage}></Route>
          <Route path="/cmyprofile" component={custmyprofilePage}></Route>
          <Route path="/cabout" component={custaboutPage}></Route>
          <Route path="/clogin" component={CLogin}></Route>
          <Route path="/cviewaddlicence" component={custrequestlistPage}></Route>
          <Route path="/cviewclaim" component={custrequestlistPage}></Route>
          <Route path="/cviewupgrade" component={custrequestlistPage}></Route>
          <Route path="/cviewreqtraining" component={custrequestlistPage}></Route>
          <Route path="/cviewrenewlicence" component={custrequestlistPage}></Route>

          <Route path="/cappnewsview" component={custappnewsdetailPage}></Route>

          <Route path="/TestDemoAppsheet" component={ITestDemo}></Route>
          <Route path="/TestAddDemoAppsheet" component={IAddTestDemo}></Route>
          <Route path="/TestEditDemoAppsheet" component={IAddTestDemo}></Route>

          <Route path="/demothankyoubot" component={ITestDemoThankyouBot}></Route>
          <Route path="/demothankyouform" component={IAddTestDemoThankyouBot}></Route>
          
      </div>
  </Router>
)
ReactDOM.render(<React.StrictMode>{routing}</React.StrictMode>, document.getElementById('root'));
reportWebVitals();

