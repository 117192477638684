import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../cinc/cStyle.css';
import CheckLogin from '../cinc/ccekLogin';
import DateTimeFormats from '../../inc/date-time-format';
import ReplaceAllContent from '../../inc/replace-all';
import CHeader from './cfragmentheader';
import CSidemenu from './cfragmentsidemenu';
import NAimage from '../../assets/na.png';

const authKey = global.cconfig.authBearerKey+"##"+global.cconfig.kindcode[28];

class CNewsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        st2Title: '',
        type: '',
        pageShow: '10',
        keyword: '',
        styleTb: 'table',
        data: [],
        isSignin: false,
        hideNav: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  resize() {
    let currentHideNav = (window.innerWidth <= 480);
    if (currentHideNav !== this.state.hideNav) {
        this.setState({hideNav: currentHideNav});
    }
    if(currentHideNav === false) { 
      document.getElementById("sidemenu").style.display = "block";
    } else {
      document.getElementById("sidemenu").style.display = "none";
    }
  
  }
  loadData(value) {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'","filter1":["'+value+'"],"form_id":"pubonly"}';
    var data = JSON.parse(datatext); 
    fetch(global.cconfig.urlLink+"/addcontentlist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        var styleTb = 'table';
        if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; } 
        var arrContentsList = [];
        var arrContentSortByDesc = [];
        if(resJson.values.length > 0 && resJson.status === '200') {
            for(var v=0; v < resJson.values.length; v++) {
                arrContentsList.push({
                    docid: resJson.values[v].docid,
                    content: resJson.values[v].content,
                    contentdate: resJson.values[v].content.contentDate,
                })
            }
            if(arrContentsList.length>0) {
                arrContentSortByDesc = arrContentsList.sort((c,d) => {
                    return d.contentdate._seconds - c.contentdate._seconds;
                })
            }
        } 
        this.setState({
            styleTb: styleTb,
            data: arrContentSortByDesc,
            isSignin: true
        })
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  urldetailContent(valid) {
    window.location.href='/cnewsview/'+valid;
  }
  handleChange(e) {
    this.setState({ [e.target.name] : e.target.value });
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    this.setState( prevState => {
        return {  
            ...prevState.pageShow, pageShow: value
        }
    })
  }
  handleSubmit(e) {
    e.preventDefault();
    this.loadData();
  }
 componentDidMount() {
    document.title = global.cconfig.webtitle;
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    CheckLogin().then((statL) =>{ 
      if(statL === true) {
        var typeSlug="", typeName="";
        var geturl = this.props.location; 
        var arrString = geturl.pathname;
        var arrUrl = [];
        arrUrl = arrString.split('/');
        if(arrUrl[2] === '1') { typeSlug = global.cconfig.contentType[0].type; typeName=global.cconfig.contentType[0].name; }
        else if(arrUrl[2] === '2') { typeSlug = global.cconfig.contentType[1].type; typeName=global.cconfig.contentType[1].name; }
        else if(arrUrl[2] === '3') { typeSlug = global.cconfig.contentType[2].type; typeName=global.cconfig.contentType[2].name; }
        
        this.setState({
          st2Title: typeName,
          type: typeSlug
        })

        this.loadData(typeSlug);
      } else {
        window.location.href="/clogin";
      }
    });

 } 
 
 render() {
  var contentsList = [];
  if(this.state.data.length > 0) {
      for(var ls=0; ls < this.state.data.length; ls++) {
          var retResult = this.state.data[ls];
          var cPostedDate = "", typesName="", cTitle="", cTitleSlug="", cShortDesc="", cLongDesc="",cPicture="";
          if(retResult.content.contentDate !== undefined) {
            cPostedDate = DateTimeFormats(retResult.content.contentDate._seconds,"date-short");
          }
          if(retResult.content.contentTitle !== undefined) { 
            cTitle = retResult.content.contentTitle;
            cTitleSlug = ReplaceAllContent(' ','-',cTitle); 
          }
          if(retResult.content.contentShortDesc !== undefined) {
            cShortDesc = ReplaceAllContent('[;]',',',retResult.content.contentShortDesc);
            cShortDesc = ReplaceAllContent('<br/>','\n',cShortDesc);
          }
          if(retResult.content.contentLongDesc !== undefined) { 
              cLongDesc = ReplaceAllContent('[;]',',',retResult.content.contentLongDesc);
              cLongDesc = ReplaceAllContent('<br/>','\n',cLongDesc);
          }
          if(retResult.content.contentPicture !== undefined && retResult.content.contentPicture !== "") { 
            cPicture = retResult.content.contentPicture;
          } else { cPicture = NAimage; }
          for(var t=0; t<3; t++) {
            if(retResult.content.typeName === global.cconfig.contentType[t].type) { typesName=global.cconfig.contentType[t].name; }
          }
          contentsList.push(<div key={retResult.docid} className="news-list-item col-md-6" onClick={this.urldetailContent.bind(this, retResult.docid)}>
            <p className="news-title">{cTitle}</p>
            <p className="news-info">{cPostedDate} in {typesName}</p>
            <p className="news-thumbnail"><img alt={cTitleSlug} style={{maxHeight:"250px",maxWidth:"400px"}} src={cPicture}/></p>
            <p className="news-content-thumbnail">{cShortDesc}</p>
          </div>)
      }
  } else {
      contentsList.push(<div key="1" className="news-list-item w100p">
          <div className="divCenter"><div className="mgT20 mgB20 news-info">:: there's no {this.state.st2Title.toLocaleLowerCase()} ::</div></div>
        </div>)
  }

   return (
    
      <div className="main-container flex-container cwrapper">
        <div id="sidemenu" className="csidebar">{(this.state.isSignin === true)? <CSidemenu showHideNav={this.state.hideNav}/> : '' }</div>
        <div className="w100p">
          <div className="cheader"><CHeader showHideNav={this.state.hideNav}/></div>
          <div className="cmain-content">
            {/* ====== */}
            {(this.state.isSignin === true)? 
            <div>
                <div className="stTitle">Insights</div>
                <div className="stIndiTitle">Latest {this.state.st2Title}</div>
                        
                <div className="mgT30">
                  <div className="general-card">
                    <div className="row">
                    {contentsList}
                    </div>
                  </div>
                </div>
            </div>
            : <div></div> }
             {/* ====== */}
          </div>
        </div>
        <div id="loading-area" className="divHide loading"></div>
      </div>
   
   );
 }
}
 
export default CNewsList;