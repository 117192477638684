import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iGlobal';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import defMemberPicture from '../../assets/pic-profile.png';
import EditIcon from '@material-ui/icons/Edit';
import Popup from '../../inc/popup';
import '../../inc/popup.css'
import ProfileForm from './ipopprofile-form';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[0];

class IMyProfiles extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        memberdoc_id: localStorage.getItem('udocid'),
        member_email: '',
        member_position: '',
        member_fullname: '',
        member_picture: '',
        member_phone: ''
    }
  }
  urleditProfile(valId) {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: true,
      }
    })
  }
  togglePopClose() {
    this.setState( prevState => {
      return {  
          ...prevState.isOpen, isOpen: false,
      }
    })
  }
  componentDidMount() {
    document.getElementById('loading-area').style.display = 'block'; 
    var urlFetch = global.config.urlLink+"/userview/"+this.state.memberdoc_id;
    fetch(urlFetch, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }
      }).then(res => {
        if(res.status === 200) 
            return res.json() 
      }).then( resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') { 
            var memberPicture = '', memberPhone = '';
            if(resJson.values[0].content[0].maincontent.userPicture !== undefined && resJson.values[0].content[0].maincontent.userPicture !== '') {
                memberPicture = resJson.values[0].content[0].maincontent.userPicture;
            } else { memberPicture = defMemberPicture; }

            if(resJson.values[0].content[0].maincontent.userPhone !== undefined && resJson.values[0].content[0].maincontent.userPhone !== '') {
              memberPhone = resJson.values[0].content[0].maincontent.userPhone;
          } 
          
                this.setState({
                    stTitle: 'Detail Member',
                    memberdoc_id: resJson.values[0].docid,
                    member_email: resJson.values[0].content[0].maincontent.userEmail,
                    member_position: resJson.values[0].content[0].maincontent.userPosition,
                    member_fullname: resJson.values[0].content[0].maincontent.userFullname,
                    member_picture: memberPicture,
                    member_phone: memberPhone
                }) 
            
        } else {
            console.log('Something happened wrong');
        }
      }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
      })
        
    
  }
 render() {
  var suppContentPop = '{"member_fullname":"'+this.state.member_fullname+'","member_email":"'+this.state.member_email+'","member_position":"'+this.state.member_position+'","member_phone":"'+this.state.member_phone+'"}';
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">My Profile</div>
                <div className="stIndiTitle">My detail information</div>
                        
                <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="flex-container">
                          <div className="w70p"><h4>{this.state.member_fullname}</h4></div>
                          <div className="w30p divRight">
                            <div className="mgR05 linkText cursor-on" onClick={this.urleditProfile.bind(this, "")}><EditIcon className="mgB05" style={{fontSize:"14pt", marginTop: "-3px"}}></EditIcon> <span style={{marginTop:"3px"}}>Edit Profile &raquo;</span></div>
                          </div>
                        </div>
                        <div id="profile-detail" className="mgT20">
                              { this.state.member_picture !== ''? 
                                <div className="mgB30"><img src={this.state.member_picture} alt={this.state.member_fullname} className="memberPhoto"/></div>
                                : ''
                              } 
                            
                            <div className="row">
                              
                              <div id="profile-info" className="col-6">
                                <div className="row">
                                  <div className="col-3"><p>Email :</p></div>
                                  <div className="col-9">
                                    <div className="textBold textItalic">{this.state.member_email}</div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-3"><p>Position :</p></div>
                                  <div className="col-9">
                                    {this.state.member_position}
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-3"><p>Phone :</p></div>
                                  <div className="col-9">
                                    {this.state.member_phone}
                                  </div>
                                </div>
                                
                              </div>
                              <div className="col-6"></div>
                            </div>
                            
                        </div>
                    </div>
                    <div>
                      {this.state.isOpen && <Popup
                        content={<><ProfileForm/></>}
                        id={this.state.memberdoc_id} 
                        title={"Edit My Profile"}
                        others={suppContentPop}
                        styles={"50%"}
                        handleClose={this.togglePopClose.bind(this, this.state.memberdoc_id)}
                      />}
                      </div>
                    {/* ====== */}
                </div>
              </div>
          </div>
        <div id="loading-area" className="divHide loading"></div>
    </div>
   );
 }
}
 
export default IMyProfiles;