import React, { Component } from 'react';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import DateTimeFormats from '../../inc/date-time-format';
// import Popup from '../../inc/popup';
// import '../../inc/popup.css'
// import NotifDetail from './ipopnotif-detail';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
//import AddIcon from '@material-ui/icons/Add';
// import ReplaceAllContent from '../../inc/replace-all';
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
// import DescriptionIcon from '@material-ui/icons/Description';
// import PersonAddIcon from '@material-ui/icons/PersonAdd';
// import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
// import Popup from '../../inc/popup';
// import '../../inc/popup.css'
// import LicenceForm from './ipoplicence-form';
// import PICForm from './ipoppic-form';
// import defMemberPicture from '../../assets/pic-profile.png';

//import IUC from './ifragmentuc';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[47];

class ISExtDriveDetail extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: 'Detail',
        licencedoc_id: '',
        licence_name: '',
        licence_price: '',
        licence_category: '',
        licence_region: '',
        licence_currency: '',
        content_client_list: [],
        styleTb: 'table',
    }
  }

  loadData(id) {
      document.getElementById('loading-area').style.display = 'block'; 
      // var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'","docid":"'+id+'"}';
      // var data = JSON.parse(datatext); 
    
      
    
  }
  
  componentDidMount() {
    
  }

  render() {
    
   return (
    <div className="main-container">
      <div className="mgT30">
            
        </div>
        <div id="loading-area" className="divHide loading"></div>
    </div>
   );
  }
}
 
export default ISExtDriveDetail;