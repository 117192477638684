import React, { Component } from 'react';
import '../../inc/genstyle.css';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import DateTimeFormats from '../../inc/date-time-format';
import ReplaceAllContent from '../../inc/replace-all';
import NAimage from '../../assets/na.png';
import parse from 'html-react-parser';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[28];

class IAddContentsDetail extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        content_docid: '',
        content_createby: '',
        content_createdate: '',
        content_date: '',
        content_title: '',
        content_short_desc: '',
        content_long_desc: '',
        content_picture: '',
        content_video: '',
        content_types: '',
        content_types_str: ''
    }
    this.loadData = this.loadData.bind(this);
  }

  handleBack = () => {
    this.props.history.goBack();
  }
  
  loadData(element,id) {
    if(element === "IViewAddContent") {
      document.getElementById('loading-area').style.display = 'block'; 
      fetch(global.config.urlLink+"/addcontentview/"+id, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': authKey,
          }
        }).then(res => {
          if(res.status === 200) 
              return res.json() 
        }).then( resJson => {
          document.getElementById('loading-area').style.display = 'none'; 
          if(resJson.status === '200') {
              var cTitle,cShortDesc,cLongDesc,cTypes,cTypesStr,cPicture,cVideo,cDate,cPostDate;
              var result = resJson.values[0].content[0].maincontent;
              
              if(result.contentTitle !== undefined) { cTitle = result.contentTitle; }
              if(result.contentShortDesc !== undefined) {
                  cShortDesc = ReplaceAllContent('[;]',',',result.contentShortDesc);
                  cShortDesc = ReplaceAllContent('<br/>','\n',cShortDesc);
              }
              if(result.contentLongDesc !== undefined) { 
                  cLongDesc = result.contentLongDesc;
                  cLongDesc = ReplaceAllContent('<img ','<img style="max-width: 100%;"',cLongDesc);
                  cLongDesc = ReplaceAllContent('<a href','<a target="_blank" href',cLongDesc);
                  cLongDesc = ReplaceAllContent('<oembed url=','<center><iframe width="480" height="270" scrolling="no" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen src=',cLongDesc);
                  cLongDesc = ReplaceAllContent('</oembed>','</iframe></center>',cLongDesc);
              }
              if(result.typeName !== undefined) { 
                  cTypes = result.typeName;
                  for(var t=0; t<global.config.contentType.length; t++) {
                    if(cTypes === global.config.contentType[t].type) { cTypesStr=global.config.contentType[t].name; }
                  }
              }
              if(resJson.values[0].content[0].createdate !== undefined) {
                cDate = DateTimeFormats(resJson.values[0].content[0].createdate._seconds,"datetime-short");
              }
              if(result.contentDate !== undefined) {
                cPostDate = DateTimeFormats(result.contentDate._seconds,"date-long");
              }
              
              if(result.contentPicture !== undefined && result.contentPicture !== "") { 
                cPicture = result.contentPicture;
              } else { cPicture = NAimage; }
              if(result.contentVideo !== undefined && result.contentVideo !== "") { 
                cVideo = result.contentVideo;
              }
              
              this.setState({
                  stTitle: 'Detail Content Insight',
                  content_docid: resJson.values[0].docid,
                  content_createby: resJson.values[0].content[0].maincontent.createByName,
                  content_createdate: cDate,
                  content_date: cPostDate,
                  content_title: cTitle,
                  content_short_desc: cShortDesc,
                  content_long_desc: cLongDesc,
                  content_picture: cPicture,
                  content_video: cVideo,
                  content_types: cTypes,
                  content_types_str: cTypesStr
              }) 
          } else {
              console.log('Something happened wrong');
          }
        }).catch(err => {
          document.getElementById('loading-area').style.display = 'none'; 
        }); 
    }
  }
  
  componentDidMount() {
    var geturl = this.props.location; 
    var arrString = geturl.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');
    
    if(arrUrl[1] === 'IViewAddContent') {
        if(arrUrl[2] !== '') { 
            this.loadData(arrUrl[1],arrUrl[2]);
        }
    } 
  }

  render() {
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Content Insight</div>
                {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[5] === "ON") || (JSON.parse(localStorage.getItem('urole'))[6] === "ON"))? 
                <div>
                  <div className="stIndiTitle">
                    Content Insight 
                    { this.state.stTitle !== ''? ' > '+this.state.stTitle : '' }
                    { this.state.content_types_str !== ''? ' > '+this.state.content_types_str : '' }
                  </div>
                  <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="row-field">
                            <div className="w80p">
                              <h4>{this.state.content_title}</h4>
                            </div>
                            <div className="divRight w20p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        <div className="mgT10 divRight">
                          <div className="detailJoined"><span>Created By : {this.state.content_createby}</span> <span className="mgL20">On : {this.state.content_createdate}</span></div>
                        </div>
                        <div className="mgT30 flex-container">
                          <div><h6>Posted Date :</h6></div>
                          <div className="mgL20">{this.state.content_date}</div>
                        </div>
                        <div className="mgT30">
                          <div><h6>Short Description :</h6></div>
                          <div>{this.state.content_short_desc}</div>
                        </div>
                        <div className="mgT30">
                          <div><h6>Full Description :</h6></div>
                          <div className="ck-content">{parse(this.state.content_long_desc)}</div>
                        </div>
                        <div className="mgT30">
                          <div><h6>Picture :</h6></div>
                          <div><img width="150" src={this.state.content_picture} alt=""/></div>
                        </div>
                        {(this.state.content_video !== "" && this.state.content_video !== undefined)?
                        <div className="mgT30">
                          <div><h6>Video Intro :</h6> <a href={this.state.content_video} target="_blank" rel="noreferrer">{this.state.content_video}</a> </div>
                        </div>
                        : '' }
                        
                    </div>
                    {/* ====== */}
                  </div>  
                </div>
                :   
                <div><INA/></div>
                }
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div>
    </div>
   );
  }
}
 
export default IAddContentsDetail;