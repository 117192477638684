import React, { Component } from 'react';
import axios from 'axios';
import '../../inc/genstyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import INA from './ifragmentna';
import ReplaceAllContent from '../../inc/replace-all';
import MyCustomUploadAdapterPlugin from '../iinc/ckImageUploader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DeleteIcon from '@material-ui/icons/Delete';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[9];
const authKey2 = global.config.authBearerKey+"##"+global.config.kindcode[11];
class ILibraryForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
        stTitle: '',
        library_docid: '',
        library_title: '',
        library_short_desc: '',
        library_long_desc: '',
        library_language: '',
        library_picture: '',
        library_video: '',
        library_category: '',
        library_subcategory: '',
        source: 'library',
        categoryList: [],
        subcategoryList: [],
        selectedFileDocument: null,
        selectedFileDocument2: null,
        formErrors: {library_title: '', library_short_desc: '', library_long_desc: '', library_language: '', library_category: '', library_subcategory: '', selectedFileDocument:'', selectedFileDocument2:''},
        librarytitleValid: false,
        libraryshortdescValid: false,
        librarylongdescValid: false,
        librarylanguageValid: false,
        librarycategoryValid: false,
        librarysubcategoryValid: false,
        selectedFileDocumentValid: false,
        selectedFileDocument2Valid: false,
        formValid: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadSubcategory = this.loadSubcategory.bind(this);
    this.submitData = this.submitData.bind(this);
  }
  handleBack = () => {
    this.props.history.goBack();
  }
  handleUserCKEditorInput = (e) => {
    const name = "library_long_desc";
    const value = e.getData();
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }
  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value},
        () => { this.validateField(name, value)});
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    const inputname = e.target.name;
    var arrValues = value.split("$$");
    if(inputname === 'library_category') {
        this.loadSubcategory(arrValues[0]);
        this.setState( prevState => {
            return {  
                ...prevState.library_category, library_category: value
            }
          }, () => { this.validateField(inputname, value)
        }) 
    } else if(inputname === 'library_language') {
        this.setState( prevState => {
            return {  
                ...prevState.library_language, library_language: value
            }
            }, () => { this.validateField(inputname, value)
        }) 
    } else {
        this.setState( prevState => {
            return {  
                ...prevState.library_subcategory, library_subcategory: value
            }
          }, () => { this.validateField(inputname, value)
        }) 
    }
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let librarytitleValid = this.state.librarytitleValid;
    let libraryshortdescValid = this.state.libraryshortdescValid;
    let librarylongdescValid = this.state.librarylongdescValid;
    let librarylanguageValid = this.state.librarylanguageValid;
    let librarycategoryValid = this.state.librarycategoryValid;
    let librarysubcategoryValid = this.state.librarysubcategoryValid;
    let selectedFileDocumentValid = this.state.selectedFileDocumentValid;
    let selectedFileDocument2Valid = this.state.selectedFileDocument2Valid;
    switch(fieldName) {
        case 'library_title': 
            librarytitleValid = value.trim().length > 0;
            fieldValidationErrors.library_title = librarytitleValid ? '' : 'title need to be filled';
            break;
        case 'library_short_desc': 
            libraryshortdescValid = value.trim().length > 0;
            fieldValidationErrors.library_short_desc = libraryshortdescValid ? '' : 'short description need to be filled';
            break;
        case 'library_long_desc': 
            librarylongdescValid = value.trim().length > 0;
            fieldValidationErrors.library_long_desc = librarylongdescValid ? '' : 'introduction need to be filled';
            break;
        case 'library_language': 
            librarylanguageValid = value.trim().length > 0;
            fieldValidationErrors.library_language = librarylanguageValid ? '' : 'language need to be filled';
            break;
        case 'library_category': 
            librarycategoryValid = value.trim().length > 0;
            fieldValidationErrors.library_category = librarycategoryValid ? '' : 'category need to be filled';
            break;
        case 'library_subcategory': 
            librarysubcategoryValid = value.trim().length > 0;
            fieldValidationErrors.library_subcategory = librarysubcategoryValid ? '' : 'sub category need to be filled';
            break;
        default:
            break;
    }
    this.setState({formErrors: fieldValidationErrors,
                    librarytitleValid: librarytitleValid,
                    libraryshortdescValid: libraryshortdescValid,
                    librarylongdescValid: librarylongdescValid,
                    librarylanguageValid: librarylanguageValid,
                    librarycategoryValid: librarycategoryValid,
                    librarysubcategoryValid: librarysubcategoryValid,
                    selectedFileDocumentValid: selectedFileDocumentValid,
                    selectedFileDocument2Valid: selectedFileDocument2Valid
                }, this.validateForm);
  }
  validateForm() {
    this.setState({formValid: this.state.librarytitleValid && this.state.libraryshortdescValid && this.state.librarylongdescValid && this.state.librarylanguageValid && this.state.librarycategoryValid && this.state.librarysubcategoryValid && this.state.selectedFileDocumentValid && this.state.selectedFileDocument2Valid});
  }

  errorClass(error) {
    return(error.length === 0 ? '' : 'has-error');
  }
  onChangeFileHandler = (e) => {
    if(e.target.files[0] !== undefined) {
        var inputType = e.target.files[0].type;
        var inputValue = e.target.files[0];
        var name = ''; 
        var validname = '';
        let fieldValidationErrors = this.state.formErrors;
        let selectedFileValid = false;
        if(inputType.indexOf('pdf') > -1 || inputType.indexOf('video') > -1|| inputType.indexOf('image') > -1) { //add image
            selectedFileValid = true;
        } else {
            selectedFileValid = false;
        }
        if(e.target.id === "fileDocument2") {
            name =  'selectedFileDocument2';
            validname = 'selectedFileDocument2Valid';
            fieldValidationErrors.selectedFileDocument2 = selectedFileValid ? '' : 'please upload video only';
        } else {
            name =  'selectedFileDocument';
            validname = 'selectedFileDocumentValid';
            fieldValidationErrors.selectedFileDocument = selectedFileValid ? '' : 'please upload image only';
        }
        
        this.setState({
            [name]: inputValue,
            formErrors: fieldValidationErrors,
            [validname] : selectedFileValid
        }, this.validateForm);
    }
  }
  onDeleteFileHandler = (e) => {
    var name = ''; 
    if(e.target.id === "deletePicture") {
        name =  'library_picture';
    } else {
        name =  'library_video';
    }
    this.setState({
        [name]: ''
    });
  }
  submitData(datasend,imgurl,vidurl) {
    var urlFetch, datatext;
    var libraryTitle,libraryShortDesc,libraryLongDesc,libraryLanguage,libraryPicture,libraryVideo,libraryCategory,librarySubCategory,libraryCategoryId,libraryCategoryName,librarySubCategoryId,librarySubCategoryName;
    var arrCategory = [], arrSubCategory = [];
    if(imgurl !== '' && imgurl !== undefined) { libraryPicture = imgurl; } else { libraryPicture = this.state.library_picture; }
    if(vidurl !== '' && vidurl !== undefined) { libraryVideo = vidurl; } else { libraryVideo = this.state.library_video; }
    if(this.state.library_docid !== null && this.state.library_docid !== ''  && this.state.library_docid !== undefined) { 
        if(this.textInputLibraryTitle.value === '') { libraryTitle = this.state.library_title; } else { libraryTitle = this.textInputLibraryTitle.value; }
        if(this.textInputLibraryShortDesc.value === '') { libraryShortDesc = this.state.library_short_desc; } else { libraryShortDesc = this.textInputLibraryShortDesc.value; }
        if(this.textInputLibraryLanguage.value === '') { libraryLanguage = this.state.library_language; } else { libraryLanguage = this.textInputLibraryLanguage.value; }
        if(this.textInputLibraryCategory.value === '') { libraryCategory = this.state.library_category; } else { libraryCategory = this.textInputLibraryCategory.value; }
        if(this.textInputLibrarySubcategory.value === '') { librarySubCategory = this.state.library_subcategory; } else { librarySubCategory = this.textInputLibrarySubcategory.value; }
        
        libraryShortDesc = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',libraryShortDesc));
        libraryLongDesc = ReplaceAllContent('"',"'",ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',this.state.library_long_desc)));

        arrCategory = libraryCategory.split("$$");
        if(arrCategory.length > 0) {
            libraryCategoryId=arrCategory[0];
            libraryCategoryName=arrCategory[1];
        }
        arrSubCategory = librarySubCategory.split("$$"); 
        if(arrSubCategory.length > 0) {
            librarySubCategoryId=arrSubCategory[0];
            librarySubCategoryName=arrSubCategory[1];
        }
        datatext ='{"library_title":"'+libraryTitle+'","library_short_desc":"'+libraryShortDesc+'","library_long_desc":"'+libraryLongDesc+'","library_language":"'+libraryLanguage+'","library_picture":"'+libraryPicture+'","library_video":"'+libraryVideo+'","library_category_id":"'+libraryCategoryId+'","library_category_name":"'+libraryCategoryName+'","library_subcategory_id":"'+librarySubCategoryId+'","library_subcategory_name":"'+librarySubCategoryName+'","library_docid":"'+this.state.library_docid+'","form_id":"form-edit"}';
        urlFetch = global.config.urlLink+"/libraryedit";
    } else {
        libraryShortDesc = ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',this.state.library_short_desc));
        libraryLongDesc = ReplaceAllContent('"',"'",ReplaceAllContent('\n','<br/>',ReplaceAllContent(',','[;]',this.state.library_long_desc)));
        arrCategory = this.state.library_category.split("$$");
        if(arrCategory.length > 0) {
            libraryCategoryId=arrCategory[0];
            libraryCategoryName=arrCategory[1];
        }
        arrSubCategory = this.state.library_subcategory.split("$$"); 
        if(arrSubCategory.length > 0) {
            librarySubCategoryId=arrSubCategory[0];
            librarySubCategoryName=arrSubCategory[1];
        }
        datatext ='{"library_title":"'+this.state.library_title+'","library_short_desc":"'+libraryShortDesc+'","library_long_desc":"'+libraryLongDesc+'","library_language":"'+this.state.library_language+'","library_picture":"'+libraryPicture+'","library_video":"'+libraryVideo+'","library_category_id":"'+libraryCategoryId+'","library_category_name":"'+libraryCategoryName+'","library_subcategory_id":"'+librarySubCategoryId+'","library_subcategory_name":"'+librarySubCategoryName+'","form_id":"form-add"}';
        urlFetch = global.config.urlLink+"/libraryadd";
    }
    var data = JSON.parse(datatext); 
    
    fetch(urlFetch, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson.status === '200') {
            var docId = resJson.values;
            window.location.href='/IEditDetailLibrary/'+docId;
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    if(this.state.library_title !== '' && this.state.library_short_desc !== '' && this.state.library_long_desc !== '' && this.state.library_language !== '' && this.state.library_category !== '' && this.state.library_subcategory !== ''
        ) {
        document.getElementById('loading-area').style.display = 'block'; 
        var selectedFileValue = this.state.selectedFileDocument;
        var selectedFileValue2 = this.state.selectedFileDocument2;
        if((selectedFileValue !== null && ((selectedFileValue.type.indexOf('pdf') > -1 || selectedFileValue.type.indexOf('video') > -1|| selectedFileValue.type.indexOf('image') > -1))) 
        || (selectedFileValue2 !== null && ((selectedFileValue2.type.indexOf('pdf') > -1 || selectedFileValue2.type.indexOf('video') > -1|| selectedFileValue2.type.indexOf('image') > -1))) 
        ) { 
            const data = new FormData() 
            data.append('file', selectedFileValue);
            data.append('file2', selectedFileValue2);
            data.append('docid', this.state.library_docid);
            data.append('source', 'library');
            axios.post(global.config.urlLink+"/uploadfile",
            data, { 
                headers: { 
                    'content-type': 'multipart/form-data',
                    'Authorization': authKey
                }
            }).then(res => { 
                document.getElementById('loading-area').style.display = 'none'; 
                if(res.data.status === "200") {
                    this.submitData(this,res.data.values.linkfile1,res.data.values.linkfile2);
                } else {
                    document.getElementById('loading-area').style.display = 'none'; 
                    console.log("Something happened wrong");
                }
            }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
            })
        } else {
            document.getElementById('loading-area').style.display = 'none'; 
            this.submitData(this,"","");
        }
    
    } else {
        var arrfieldName = ['library_title','library_short_desc','library_long_desc','library_language','library_category','library_subcategory','selectedFileDocument'];
        var arrvalue = [this.textInputLibraryTitle.value,this.textInputLibraryShortDesc.value,this.state.library_long_desc,this.textInputLibraryLanguage.value,this.textInputLibraryCategory.value,this.textInputLibrarySubcategory.value,this.state.selectedFileDocument];
        for(var ck in arrfieldName) {
            this.validateField(arrfieldName[ck], arrvalue[ck]);
        }
    }
    
  }
  loadSubcategory(catid) {
    fetch(global.config.urlLink+"/categoryview/library/"+catid, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey2,
        }
    }).then(res => {
        if(res.status === 200) 
            return res.json() 
    }).then( resJson => {
        if(resJson.status === '200') { 
            this.setState( prevState => {
                return {  
                    ...prevState.subcategoryList, subcategoryList: resJson.values[0].scontent, 
                }
            }) 
        } else {
            console.log('Something happened wrong');
        }
    }).catch(err => err);
  }
  componentDidMount() {
    document.getElementById('loading-area').style.display = 'block'; 
    var geturl = this.props.location; 
    var arrString = geturl.pathname;
    var arrUrl = [];
    arrUrl = arrString.split('/');

    var datatext ='{"keyword":"","pageShow":"","usageFor":"library"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/categorylist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey2,
        }, body : JSON.stringify(data)
    }).then(response2 => {
        if(response2.status === 200) {
            return response2.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson2 => {
        document.getElementById('loading-area').style.display = 'none'; 
        if(resJson2.status === '200') {
            if(arrUrl[1] === 'IEditLibrary') {
                if(arrUrl[2] !== '') { 
                    fetch(global.config.urlLink+"/libraryview/"+arrUrl[2], {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Authorization': authKey,
                        }
                      }).then(res => {
                        if(res.status === 200) 
                            return res.json() 
                      }).then( resJson => {
                        if(resJson.status === '200') {
                            var lTitle="",lShortDesc="",lLongDesc="",lLanguage="",lPicture="",lVideo="",lCategory="",lSubCategory="";
                            if(resJson.values[0].content[0].maincontent.libraryTitle !== undefined) { lTitle = resJson.values[0].content[0].maincontent.libraryTitle; }
                            if(resJson.values[0].content[0].maincontent.libraryShortDesc !== undefined) {
                               lShortDesc = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resJson.values[0].content[0].maincontent.libraryShortDesc));
                            }
                            if(resJson.values[0].content[0].maincontent.libraryLongDesc !== undefined) { 
                                lLongDesc = ReplaceAllContent('<br/>','\n',ReplaceAllContent('[;]',',',resJson.values[0].content[0].maincontent.libraryLongDesc));
                            }
                            if(resJson.values[0].content[0].maincontent.libraryLanguage !== undefined) { 
                                lLanguage = resJson.values[0].content[0].maincontent.libraryLanguage;
                            }
                            if(resJson.values[0].content[0].maincontent.categoryId !== undefined) { 
                                lCategory = resJson.values[0].content[0].maincontent.categoryId+"$$"+resJson.values[0].content[0].maincontent.categoryName;
                                this.loadSubcategory(resJson.values[0].content[0].maincontent.categoryId);
                            }
                            if(resJson.values[0].content[0].maincontent.subcategoryId !== undefined) { 
                                lSubCategory = resJson.values[0].content[0].maincontent.subcategoryId+"$$"+resJson.values[0].content[0].maincontent.subcategoryName;
                            }
                            if(resJson.values[0].content[0].maincontent.libraryPicture !== undefined) { 
                                lPicture = resJson.values[0].content[0].maincontent.libraryPicture;
                            }
                            if(resJson.values[0].content[0].maincontent.libraryVideo !== undefined) { 
                                lVideo = resJson.values[0].content[0].maincontent.libraryVideo;
                            }
                            this.setState({
                                stTitle: 'Edit Library',
                                library_docid: resJson.values[0].docid,
                                library_title: lTitle,
                                library_short_desc: lShortDesc,
                                library_long_desc: lLongDesc,
                                library_language: lLanguage,
                                library_picture: lPicture,
                                library_video: lVideo,
                                library_category: lCategory,
                                library_subcategory: lSubCategory,
                                source: 'library',
                                categoryList: resJson2,
                                selectedFileDocument: null,
                                selectedFileDocument2: null,
                                formErrors: {library_title: '', library_short_desc: '', library_long_desc: '', library_language: '', library_category: '', library_subcategory: '', selectedFileDocument: '', selectedFileDocument2: ''},
                                librarytitleValid: false,
                                libraryshortdescValid: false,
                                librarylongdescValid: false,
                                librarylanguageValid: false,
                                librarycategoryValid: false,
                                librarysubcategoryValid: false,
                                selectedFileDocumentValid: false,
                                selectedFileDocument2Valid: false,
                                formValid: false,
                            }) 
                        } else {
                            console.log('Something wrong happened');
                        }
                      }).catch(err => err);
                }
            } else if(arrUrl[1] === 'IAddLibrary') {
                this.setState( prevState => {
                    return {  
                        ...prevState.categoryList, categoryList: resJson2,
                        ...prevState.stTitle, stTitle: 'New Library'
                    }
                })
            } 
        } else {
            console.log('Something wrong happened');
        }
    }).catch(err => { 
        document.getElementById('loading-area').style.display = 'none'; 
    });

    
  }
 render() {
    var selcategoryList = [];
    if(this.state.categoryList.values.length > 0) {
       for(var u in this.state.categoryList.values) {
        var selvalue = this.state.categoryList.values[u].docid+'$$'+this.state.categoryList.values[u].content.categoryName;
        selcategoryList.push(<option value={selvalue} key={u}>{this.state.categoryList.values[u].content.categoryName}</option>)
       }
    }

    var selsubcategoryList = [];
    if(this.state.subcategoryList.length > 0) {
        for(var v in this.state.subcategoryList) {
        var selvalue2 = this.state.subcategoryList[v].subdocid+'$$'+this.state.subcategoryList[v].subcontent.subcategoryName;
         selsubcategoryList.push(<option value={selvalue2} key={v}>{this.state.subcategoryList[v].subcontent.subcategoryName}</option>)
        }
     }
   
    return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
            <div className="sidebar-main"><ISidemenu/></div>
            <div className="main-content">
              <div className="stTitle">Library</div>
              {((localStorage.getItem('ulevel') === global.config.userlevel[0]) || (JSON.parse(localStorage.getItem('urole'))[2] === "ON") || (JSON.parse(localStorage.getItem('urole'))[3] === "ON") || (JSON.parse(localStorage.getItem('urole'))[4] === "ON"))? 
              <div>
                <div className="stIndiTitle">Library { this.state.stTitle !== ''? '> '+this.state.stTitle : '' }</div>
                <div className="mgT30">
                    {/* ====== */}
                    <div  className="content-container card card-body">
                        <div className="flex-container">
                            <div className="st2Title w70p">{ this.state.stTitle.includes("New")? 'Add ' : '' } {this.state.stTitle}</div>
                            <div className="divRight w30p"><div><button className="backButton" onClick={this.handleBack}>&#9664; back</button></div></div>
                        </div>
                        <div id="FormCenter" className="mgT20">
                            <div id="FormLibraryInfo">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="flex-container">
                                        <input type="hidden" required name="library_docid" value={this.state.library_docid} ref={(input) => this.textInputLibraryId = input} />
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="library_title">Title</label>
                                        <div className="w70p">
                                            <input type="text" className="form-control" name="library_title"
                                                placeholder={this.state.library_title}
                                                value={this.state.library_title}
                                                onChange={this.handleUserInput}  
                                                ref={(input) => this.textInputLibraryTitle = input}/>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.library_title}/>  }</div>
                                        </div>
                                    </div>
                                    
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="library_short_desc">Short Description</label>
                                        <div className="w70p">
                                            <textarea name="library_short_desc" className="form-control" 
                                                    rows={5} cols={30} 
                                                    value={this.state.library_short_desc} 
                                                    onChange={this.handleUserInput} 
                                                    ref={(input) => this.textInputLibraryShortDesc = input}/>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.library_short_desc}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="mgT20">
                                        <label className="labelFormL" htmlFor="library_long_desc">Introduction</label>
                                        <div>
                                            <CKEditor
                                                editor  = { ClassicEditor }
                                                name    = "library_long_desc"
                                                data    = {this.state.library_long_desc}
                                                config  = {{extraPlugins:[MyCustomUploadAdapterPlugin], state: this.state, key:authKey}}
                                                onReady = { editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                    console.log( 'Editor is ready to use!', editor );
                                                    editor.ui.view.editable.element.style.minHeight = "500px";
                                                } }
                                                onChange={ ( event, editor ) => {
                                                    this.handleUserCKEditorInput(editor);
                                                    const data = editor.getData();
                                                    
                                                    //this.textInputContentLongDesc = data;
                                                    console.log( { event, editor, data } );
                                                    editor.ui.view.editable.element.style.minHeight = "500px";
                                                } }
                                                onBlur={ ( event, editor ) => {
                                                    console.log( 'Blur.', editor );
                                                    editor.ui.view.editable.element.style.minHeight = "500px";
                                                } }
                                                onFocus={ ( event, editor ) => {
                                                    console.log( 'Focus.', editor );
                                                    editor.ui.view.editable.element.style.minHeight = "500px";
                                                } }
                                            />
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.library_long_desc}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="library_language">Language</label>
                                        <div className="w70p">
                                            <select name="library_language" className="form-control" 
                                                value={this.state.library_language} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputLibraryLanguage = input}
                                                disabled={this.state.input_disabled}
                                            >
                                            <option value="" key="">-- please select --</option>
                                            <option value="English">English</option>
                                            <option value="Indonesian">Indonesian</option>
                                            </select>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.library_language}/>  }</div>
                                        </div>
                                    </div> 
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="library_category">Category</label>
                                        <div className="w70p">
                                            <select name="library_category" className="form-control" 
                                                value={this.state.library_category} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputLibraryCategory = input}
                                                disabled={this.state.input_disabled}
                                            >
                                            <option value="" key="">-- please select --</option>
                                            {selcategoryList}
                                            </select>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.library_category}/>  }</div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="library_subcategory">Sub Category</label>
                                        <div className="w70p">
                                            <select name="library_subcategory" className="form-control" 
                                                value={this.state.library_subcategory} 
                                                onChange={this.handleUserSelect}
                                                ref={(input) => this.textInputLibrarySubcategory = input}
                                                disabled={this.state.input_disabled}
                                            >
                                            <option value="" key="">-- please select --</option>
                                            {selsubcategoryList}
                                            </select>
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.library_subcategory}/>  }</div>
                                        </div>
                                    </div>
                                    <div>
                                        {(this.state.library_picture !== "")? 
                                        <div className="row-field mgT20">
                                            <div className="labelFormL"></div>
                                            <div className="w70p frg15e">
                                                <img width="150" src={this.state.library_picture} alt=""/>
                                                <a className="linkText" href="#!" onClick={this.onDeleteFileHandler} id="deletePicture"><DeleteIcon/> Delete Picture &raquo;</a>
                                            </div>
                                        </div>
                                        : '' }
                                    </div>
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="library_document">Upload Picture</label>
                                        <div className="w70p">
                                            <input id="fileDocument" type="file"
                                                onChange={this.onChangeFileHandler}>
                                            </input> 
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>  }</div>
                                            <div className="mgT10 warningText">
                                            Please upload file in jpg, jpeg, png format.
                                            </div>
                                        </div>
                                    </div>
                                    {(this.state.library_video !== "" && this.state.library_video !== undefined)?                                                                        
                                    <div className="row-field mgT20">
                                        <div className="labelFormL"></div>
                                        <div className="w70p frg15e">
                                            <iframe src={this.state.library_video} title={this.state.library_title}></iframe>
                                            <a className="linkText" href="#!" onClick={this.onDeleteFileHandler} id="deleteVideo"><DeleteIcon/> Delete Video &raquo;</a>
                                        </div>
                                    </div>

                                    : '' }
                                    <div className="row-field mgT20">
                                        <label className="labelFormL" htmlFor="library_document2">Upload Video Intro</label>
                                        <div className="w70p">
                                            <input id="fileDocument2" type="file"
                                                onChange={this.onChangeFileHandler}>
                                            </input> 
                                            <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument2}/>  }</div>
                                            <div className="mgT10 warningText">
                                            Please upload file in mp4 format.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-field mgT30 mgB30">
                                        <div className="labelFormL"></div>
                                        <div className="w70p"><button type="submit" className="w100p submitButton">Submit</button></div>
                                    </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>
                    {/* ====== */}
                  </div>  
              </div>
              :   
              <div><INA/></div>
              }
          </div>
      </div>
      <div id="loading-area" className="divHide loading"></div> 
    </div>
   );
 }
}
 
export default ILibraryForm;