import React, { Component } from 'react';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[11];

class ICategoryForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            category_id: '',
            category_name: '',
            usage_for: '',
            formErrors: { category_name: ''},
            categorynameValid: false,
            formValid: false,
            input_disabled: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let categorynameValid = this.state.categorynameValid;
        
        switch(fieldName) {
            case 'category_name': 
                categorynameValid = value.trim().length > 0;
                fieldValidationErrors.category_name = categorynameValid ? '' : 'category name need to be filled';
                break;
            default:
                break;
        }
        
        this.setState({formErrors: fieldValidationErrors,
                        categorynameValid: categorynameValid,
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.categorynameValid});
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit(e) {
        e.preventDefault();
        if(this.state.category_name !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var categoryName;
            if(this.textInputCategoryName.value === '') { categoryName = this.state.category_name; } else { categoryName = this.textInputCategoryName.value; }
            
            var datatext ='{"category_id":"'+this.state.category_id+'","category_name":"'+categoryName+'","usage_for":"'+this.state.usage_for+'","form_id":"form-category"}';
            var urlFetch = global.config.urlLink;
            if(this.state.category_id !== '') { urlFetch = urlFetch+"/categoryedit"; }
            else { urlFetch = urlFetch+"/categoryadd"; }
            var data = JSON.parse(datatext); 
            
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    window.location.href='/ICategory'+this.state.usage_for;
                } else {
                    console.log('Something happened wrong');
                }
            }).catch(err => err);

        } else {
            var arrfieldName = ['category_name'];
            var arrvalue = [this.textInputCategoryName.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    componentDidMount() {
        document.getElementById('loading-area').style.display = 'block'; 
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        if(othersContent.catId !== '') {
            fetch(global.config.urlLink+"/categoryview/"+othersContent.usageFor+"/"+othersContent.catId, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }
              }).then(res => {
                if(res.status === 200) 
                    return res.json() 
              }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') { 
                    this.setState({
                        category_id: resJson.values[0].docid,
                        category_name: resJson.values[0].content[0].maincontent.categoryName,
                        usage_for: othersContent.usageFor,
                        formErrors: {category_name: ''},
                        categorynameValid: false,
                        formValid: false,
                        input_disabled: false
                    }); 
                } else {
                    console.log('Something happened wrong');
                }
              }).catch(err => { 
                document.getElementById('loading-area').style.display = 'none'; 
              });
        } else {
            document.getElementById('loading-area').style.display = 'none'; 
            this.setState({
                category_id: othersContent.catId,
                category_name: '',
                usage_for: othersContent.usageFor,
                formErrors: {category_name: ''},
                categorynameValid: false,
                formValid: false,
                input_disabled: false
            }); 
        }
    }

    render() {
        return (
            <div className="main-container">
                <div className="mgT30">
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <input type="hidden" id="category_id" name="category_id" value={this.state.category_id} />
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="category_name">Category Name</label>
                        <div className="w70p">
                            <input type="text" className="form-control" name="category_name"
                                placeholder={this.state.category_name}
                                value={this.state.category_name}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputCategoryName = input}
                                disabled={this.state.input_disabled}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.category_name}/>  }
                            </div>
                        </div>
                    </div>
                    
                    <div className="row-field mgT30 mgB30">
                        <div className="w100p"><button id="submit-button"  type="submit" className="w100p submitButton">Submit</button></div>
                    </div>
                </form>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
          );
        }
     }
      
export default ICategoryForm;
