import React, { Component } from 'react';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[6];

class ContactPersonForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            client_id: '',
            member_id: '',
            member_email: '',
            member_position: '',
            member_name: '',
            member_phone: '',
            formErrors: {member_email: '', member_position: '', member_name: '', member_phone: ''},
            memberemailValid: false,
            memberpositionValid: false,
            membernameValid: false,
            formValid: false,
            input_disabled: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let memberemailValid = this.state.memberemailValid;
        let memberpositionValid = this.state.memberpositionValid;
        let membernameValid = this.state.membernameValid;
        
        switch(fieldName) {
            case 'member_email': 
                memberemailValid = value.trim().length > 0;
                fieldValidationErrors.member_email = memberemailValid ? '' : 'email need to be filled';
                break;
            case 'member_position': 
                memberpositionValid = value.trim().length > 0;
                fieldValidationErrors.member_position = memberpositionValid ? '' : 'position need to be filled';
                break;
            case 'member_name': 
                membernameValid = value.trim().length > 0;
                fieldValidationErrors.member_name = membernameValid ? '' : 'name need to be filled';
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        memberemailValid: memberemailValid,
                        memberpositionValid: memberpositionValid,
                        membernameValid: membernameValid,
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.memberemailValid && this.state.memberpositionValid && this.state.membernameValid});
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit(e) {
        e.preventDefault();
        if(this.state.member_email !== '' && this.state.member_position !== '' && this.state.member_name !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var urlFetch, datatext;
            if(this.state.member_id !== null && this.state.member_id !== ''  && this.state.member_id !== undefined) { 
                var memberEmail,memberPosition,memberName,memberPhone;
                if(this.textInputMemberEmail.value === '') { memberEmail = this.state.member_email; } else { memberEmail = this.textInputMemberEmail.value; }
                if(this.textInputMemberPosition.value === '') { memberPosition = this.state.member_position; } else { memberPosition = this.textInputMemberPosition.value; }
                if(this.textInputMemberName.value === '') { memberName = this.state.member_name; } else { memberName = this.textInputMemberName.value; }
                if(this.textInputMemberPhone.value === '') { memberPhone = this.state.member_phone; } else { memberPhone = this.textInputMemberPhone.value; }
                
                datatext ='{"member_email":"'+memberEmail+'","member_position":"'+memberPosition+'","member_name":"'+memberName+'","member_phone":"'+memberPhone+'","member_docid":"'+this.state.member_id+'","client_docid":"'+this.state.client_id+'","form_id":"form-edit"}';
                urlFetch = global.config.urlLink+"/memberedit";
            } else {
                datatext ='{"member_email":"'+this.state.member_email+'","member_position":"'+this.state.member_position+'","member_name":"'+this.state.member_name+'","member_phone":"'+this.state.member_phone+'","client_docid":"'+this.state.client_id+'","form_id":"form-add"}';
                urlFetch = global.config.urlLink+"/memberadd";
            }

            var data = JSON.parse(datatext); 
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    window.location.href='/IViewClients/'+this.state.client_id;
                } else {
                    console.log('Something happened wrong');
                }
            }).catch(err => err);

        } else {
            var arrfieldName = ['member_email','member_position','member_name'];
            var arrvalue = [this.textInputMemberEmail.value,this.textInputMemberPosition.value,this.textInputMemberName.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    componentDidMount() {
        var clientId = document.getElementById("contentid").innerText;
        var othersContent2 = JSON.parse(document.getElementById("contentoth").innerText);
        this.setState({
            stPopTitle: othersContent2.stPopTitle,
            client_id: clientId,
            member_id: othersContent2.member_id,
            member_email: othersContent2.member_email,
            member_position: othersContent2.member_position,
            member_name: othersContent2.member_name,
            member_phone: othersContent2.member_phone,
            formErrors: {member_email: '', member_position: '', member_name: '', member_phone: ''},
            memberemailValid: false,
            memberpositionValid: false,
            membernameValid: false,
            formValid: false,
            input_disabled: false
        }); 
    }

    render() {
        return (
            <div className="main-container">
                <div className="mgT30">
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <input type="hidden" name="client_id" value={this.state.client_id} />
                        <input type="hidden" name="member_id" value={this.state.member_id} />
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="member_name">Name</label>
                        <div className="w70p">
                            <input type="text" className="form-control" name="member_name"
                                placeholder={this.state.member_name}
                                value={this.state.member_name}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputMemberName = input}
                                disabled={this.state.input_disabled}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.member_name}/>  }
                            </div>
                        </div>
                    </div>
                    
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="member_email">Email Address</label>
                        <div className="w70p">
                            <input type="text" className="form-control" name="member_email"
                                placeholder={this.state.member_email}
                                value={this.state.member_email}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputMemberEmail = input}
                                disabled={this.state.input_disabled}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.member_email}/>  }
                            </div>
                        </div>
                    </div>
                    
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="member_phone">Member Phone</label>
                        <div className="w70p">
                            <input type="text" className="form-control" name="member_phone"
                                placeholder={this.state.member_phone}
                                value={this.state.member_phone}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputMemberPhone = input}
                                disabled={this.state.input_disabled}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.member_phone}/>  }
                            </div>
                        </div>
                    </div>

                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="member_position">Member Position</label>
                        <div className="w70p">
                            <input type="text" className="form-control" name="member_position"
                                placeholder={this.state.member_position}
                                value={this.state.member_position}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputMemberPosition = input}
                                disabled={this.state.input_disabled}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.member_position}/>  }
                            </div>
                        </div>
                    </div>
                    
                    
                    
                    <div className="row-field mgT30 mgB30">
                        <div className="w100p"><button id="submit-button"  type="submit" className="w100p submitButton">Submit</button></div>
                    </div>
                </form>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
          );
        }
     }
      
export default ContactPersonForm;
