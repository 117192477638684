import React, { Component } from 'react';
import axios from 'axios';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';
import CancelIcon from '@material-ui/icons/Cancel';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[9];
const errmessage = ["please upload image only", "your uploaded file is more than 10Mb."];

class ILibraryAttachEdit extends Component {
    constructor (props) {
        super(props);
        this.state = {
            library_id: '',
            library_title: '',
            library_lesson_id: '',
            library_lesson_name: '',
            library_attach_id: '',
            file_url_thumb_curr: '',
            file_url_thumb_next: '',
            selectedFileDocument: null,
            formErrors: { selectedFileDocument: '' },
            selectedFileDocumentValid: false,
            formValid: false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let selectedFileDocumentValid = this.state.selectedFileDocumentValid;
        switch(fieldName) {
            case 'selectedFileDocument': 
                selectedFileDocumentValid = value !== null;
                fieldValidationErrors.selectedFileDocument = selectedFileDocumentValid ? '' : 'file need to be selected';
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        selectedFileDocumentValid: selectedFileDocumentValid,
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.selectedFileDocumentValid });
    }
    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    
    onChangeFileHandler = (e) => {
        if(e.target.files[0] !== undefined) {
            var inputType = e.target.files[0].type;
            var inputValue = e.target.files[0];
            var name = ''; 
            var validname = '';
            let fieldValidationErrors = this.state.formErrors;
            let selectedFileValid = false;
            if(e.target.id === "fileDocument") {
                if(inputType.indexOf('image') > -1) { selectedFileValid = true; }
                else { selectedFileValid = false; }
                name =  'selectedFileDocument';
                validname = 'selectedFileDocumentValid';
                fieldValidationErrors.selectedFileDocument2 = selectedFileValid ? '' :  errmessage[0];
            } 
            this.setState({
                [name]: inputValue,
                formErrors: fieldValidationErrors,
                [validname] : selectedFileValid
            }, this.validateForm);
        } else {
            this.setState({
                selectedFileDocument: null,
                formErrors: { selectedFileDocument: '' },
                selectedFileDocumentValid: false,
                formValid: false
            }); 
            
        }
    }
    
    handleSubmit(e) {
        e.preventDefault();
        if(this.state.selectedFileDocument !== '' && this.state.selectedFileDocument !== null) {
            if(this.state.selectedFileDocument.type.indexOf('image') > -1) { 
                document.getElementById('loading-area').style.display = 'block'; 
                var selectedFileValue = this.state.selectedFileDocument;
                if (selectedFileValue.size < 10000000) {
                    const data = new FormData() 
                    data.append('file', selectedFileValue);
                    data.append('docid', this.state.library_id);
                    data.append('subdocid', this.state.library_lesson_id);
                    data.append('subsubdocid', this.state.library_attach_id);
                    data.append('index', 1);
                    data.append('source', 'library-attach');
                    data.append('filetype',selectedFileValue.type);

                    axios.post(global.config.urlLink+"/uploadfile", 
                    data, { 
                        headers: { 
                            'content-type': 'multipart/form-data',
                            'Authorization': authKey
                        }
                    }).then(res => { 
                        if(res.data.status === "200") {
                            var datatext = '{"library_docid":"'+this.state.library_id+'","library_lessonid":"'+this.state.library_lesson_id+'","library_attachid":"'+this.state.library_attach_id+'","library_attach_thumb_url":"'+res.data.values.linkfile1+'","form_id":"form-edit-attach-thumb"}';
                            var data = JSON.parse(datatext); 
                            fetch(global.config.urlLink+"/libraryattachmentedit", {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Access-Control-Allow-Origin': '*',
                                    'Authorization': authKey,
                                }, body : JSON.stringify(data)
                              }).then(res => {
                                if(res.status === 200) 
                                    return res.json() 
                              }).then( resJson => {
                                document.getElementById('loading-area').style.display = 'none'; 
                                if(resJson.status === '200') {
                                    window.location.href='/IEditDetailLibrary/'+this.state.library_id;
                                } else {
                                    console.log('Something happened wrong');
                                    document.getElementById('error-page').style.display = 'block';
                                }
                              }).catch(err => err);
                        } else {
                            console.log("Something happened wrong");
                            document.getElementById('error-page').style.display = 'block';
                        }
                    
                    }).catch(err => { 
                        document.getElementById('loading-area').style.display = 'none'; 
                        document.getElementById('error-page').style.display = 'block';
                        console.log(err); 
                    })
                    
                } else {
                    document.getElementById('loading-area').style.display = 'none';
                    this.setState({
                        selectedFileDocument: this.state.selectedFileDocument,
                        formErrors: { selectedFileDocument: errmessage[1] },
                        selectedFileDocumentValid : false,
                    }, this.validateForm);
                }
                
            } else {
                document.getElementById('loading-area').style.display = 'none'; 
                this.setState({
                    selectedFileDocument: this.state.selectedFileDocument,
                    formErrors: { selectedFileDocument: errmessage[0] },
                    selectedFileDocumentValid : false,
                }, this.validateForm);
            }
        } else {
            var arrfieldName = ['selectedFileDocument'];
            var arrvalue = [this.state.selectedFileDocument];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    componentDidMount() {
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        this.setState({
            library_id: othersContent.libraryId,
            library_title: othersContent.libraryTitle,
            library_lesson_id: othersContent.libraryLessonId,
            library_lesson_name: '',
            library_attach_id: othersContent.libraryAttachId,
            file_url_thumb_curr: othersContent.libraryAttachUrl,
            file_url_thumb_next: '',
            selectedFileDocument: null,
            formErrors: { selectedFileDocument: '' },
            selectedFileDocumentValid: false,
            formValid: false
        }); 
    }

    render() {
        return (
            <div className="main-container">
                <div className="mgT30">
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <input type="hidden" id="library_id" name="library_id" value={this.state.library_id} />
                        <input type="hidden" id="library_attach_id" name="library_attach_id" value={this.state.library_attach_id} />
                    </div>
                    <div id="error-page" className="divHide" >
                        <FormErrors formErrors={
                            <div className="divCenter">
                                <div className="flex-container">
                                    <CancelIcon className="iconRed" style={{width:"20px",marginTop:"5px"}}></CancelIcon>
                                    <div className="errText">Failed to upload.</div>
                                </div>
                        </div>}/>
                    </div>
                    <div className="row-field mgT10 st4Title">
                        {this.state.library_title}
                    </div>
                    
                    <div className="row-field mgT20">
                        <label className="labelFormL" htmlFor="library_fileurl">Current Thumbnail</label>
                        <div><img width="300" src={this.state.file_url_thumb_curr} alt=""/></div>
                    </div>
                    
                    <div className="row-field mgT50">
                        <div className="labelFormL">
                            <div>Upload Thumbnail</div>
                            <div className="notesText">(only for mp4 file)</div>
                        </div>
                        <div>
                                <input id="fileDocument" type="file"
                                    onChange={this.onChangeFileHandler}>
                                </input> 
                                <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.selectedFileDocument}/>  }</div>
                                <div className="mgT10 warningText">
                                Please upload file in jpg/jpeg format with 1280 px X 720 px (max 1MB).
                                </div>
                        </div>
                        
                    </div>

                    <div className="row-field mgT30 mgB30">
                        <div className="w100p"><button id="submit-button"  type="submit" className="w100p submitButton">Submit</button></div>
                    </div>
                </form>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
          );
        }
     }
      
export default ILibraryAttachEdit;
