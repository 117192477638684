import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import { FormErrors } from '../../inc/error-form';
import '../iinc/iGlobal';
import Alert from '@material-ui/lab/Alert';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[5];

class CommentForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            clientdoc_id: '',
            licencedoc_id: '',
            client_name: '',
            domain_name: '',
            client_country: '',
            client_licence_type: '',
            client_licence_currseat: '',
            comment: '',
            formErrors: {comment: ''},
            commentValid: false,
            formValid: false,
            errmsg: '' 
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    } 
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let commentValid = this.state.commentValid;
        
        switch(fieldName) {
            case 'comment': 
                commentValid = value.trim().length > 0;
                fieldValidationErrors.comment = commentValid ? '' : 'remarks need to be filled';
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        commentValid: commentValid,
                    }, this.validateForm);
      }
      validateForm() {
        this.setState({formValid: this.state.commentValid });
      }
    
      errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
      }
      handleSubmit(e) {
        e.preventDefault();
        if(this.state.comment !== '') {
            document.getElementById('loading-area').style.display = 'block'; 
            var urlFetch, datatext;
            var comment;
                
            if(this.state.licencedoc_id !== null && this.state.licencedoc_id !== ''  && this.state.licencedoc_id !== undefined) { 
                if(this.textInputComment.value === '') { comment = this.state.comment; } else { comment = this.textInputComment.value; }
                
                datatext ='{"comment":"'+comment+'","licence_amount_seat":"'+this.state.client_licence_currseat+'","clientdoc_id":"'+this.state.clientdoc_id+'","licencedoc_id":"'+this.state.licencedoc_id+'"}';
                urlFetch = global.config.urlLink+"/reqstoplicence";
            
                var data = JSON.parse(datatext); 
            
                fetch(urlFetch, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': authKey,
                    }, body : JSON.stringify(data)
                }).then(res => {
                    if(res.status === 200) 
                        return res.json() 
                }).then( resJson => {
                    document.getElementById('loading-area').style.display = 'none'; 
                    if(resJson.status === '200') {
                        window.location.href='/IViewLicence/'+this.state.clientdoc_id+'/'+this.state.licencedoc_id;
                    } else {
                        console.log('Something happened wrong');
                    }
                }).catch(err => err);
            } else {
                var msgError = "There's no Client is defined";
                document.getElementById("errormsg").style.display="block";
                this.setState( prevState => {
                    return {  
                        ...prevState.errmsg, errmsg: msgError
                    }
                  })
            }
        } else {
            var arrfieldName = ['comment'];
            var arrvalue = [this.textInputComment.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        } 
      }
    componentDidMount() {
        document.getElementById("errormsg").style.display = 'none';
        var othersContent = JSON.parse(document.getElementById("contentoth").innerText);
        this.setState({
            stPopTitle: '',
            clientdoc_id: othersContent.client_id,
            licencedoc_id: document.getElementById("contentid").innerText,
            client_name: othersContent.client_name,
            domain_name: othersContent.domain_name,
            client_country: othersContent.client_country,
            client_licence_type: othersContent.licence_type,
            client_licence_currseat: othersContent.licence_seat,
            comment: '',
            formErrors: {comment: ''},
            commentValid: false,
            formValid: false,
            errmsg: ''
        }); 
    }
    render() {
      
      return (
        <div className="main-container">
            <div className="mgT20">
            <div className="divhide w100p" id="errormsg"><Alert severity="error">{this.state.errmsg}</Alert></div>
            <form onSubmit={this.handleSubmit}>
              <div className="mgL20">
                <div className="st3Title">{this.state.client_name}</div>
                <div><h5 className="detailJoined">{this.state.client_country} - {this.state.domain_name}</h5></div>
              </div>
              <div className="mgT20 mgL20">
                {/* ---- */}
                <div>

                    
                    <div className="mgT05 flex-container">
                        <div className="w30p">Type of Licence </div>
                        <div className="mgL10">: {this.state.client_licence_type}</div>
                    </div>
                    <div className="mgT05 flex-container">
                        <div className="w30p">Active Licence </div>
                        <div className="mgL10">: {this.state.client_licence_currseat} seat(s)</div>
                    </div>
                    
                    
                    <label className="mgT30 textBold" htmlFor="licence_type">Remarks </label>
                    <div>
                        <textarea name="comment" className="form-control" 
                            rows={4} cols={30} 
                            /*placeholder={this.state.event_name}*/
                            value={this.state.comment} 
                            onChange={this.handleUserInput} 
                            ref={(input) => this.textInputComment = input}
                            placeholder="This client has requested not to continue this subscription via email"/>
                        
                        <div className="mgT05">{ <FormErrors formErrors={this.state.formErrors.comment}/>  }</div>
                            
                    </div>
                    <div className="mgT10 warningText">
                    Please input a reason in this 'Remarks' field, why the client did not want to continue to renew this license. 
                    And where and from who you've got this information.
                    </div>
                    
                </div>

                {/* ---- */}
                
              </div>
              <div className="row-field mgT30 mgB30">
                    <input type="hidden" required name="clientdoc_id" value={this.state.clientdoc_id} />
                    <input type="hidden" required name="licencedoc_id" value={this.state.licencedoc_id} />
                    <button type="submit" className="w100p submitButton">Submit</button>
              </div>
            </form>
            </div>
            <div id="loading-area" className="divHide loading"></div> 
        </div>
      );
    }
 }
  
export default CommentForm;