import React, { Component } from 'react';
import '../../inc/genstyle.css';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import IHeader from './ifragmentheader';
import ISidemenu from './ifragmentsidemenu';
import DescriptionIcon from '@material-ui/icons/Description';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DateTimeFormats from '../../inc/date-time-format';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[13];
function urladdUserTraining() {
    window.location.href='/IAddUserTraining';
}
class IUserTrainings extends Component {
  constructor(props) {
    super(props);
    this.state = {
        stTitle: '',
        pageShow: '10',
        keyword: '',
        styleTb: 'table',
        data: []
    };
    this.loadData = this.loadData.bind(this);
  }
  loadData() {
    document.getElementById('loading-area').style.display = 'block'; 
    var datatext ='{"keyword":"'+this.state.keyword+'","pageShow":"'+this.state.pageShow+'"}';
    var data = JSON.parse(datatext); 
    fetch(global.config.urlLink+"/traininguserlist", {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': authKey,
        }, body : JSON.stringify(data)
    }).then(response => {
        if(response.status === 200) {
            return response.json()
        } else {
            console.log('Something happened wrong');
        }
    }).then(resJson => {
        document.getElementById('loading-area').style.display = 'none'; 
        var styleTb = 'table';
        if(resJson.status === '200' && resJson.values.length > 0) { styleTb = 'table table-striped'; } 
        this.setState({
            styleTb: styleTb,
            data: resJson
        })
    }).catch(err => {
        document.getElementById('loading-area').style.display = 'none'; 
    });
  }
  handleUserSelect = (e) => {
    const value = e.target.value;
    this.setState( prevState => {
        return {  
            ...prevState.pageShow, pageShow: value
        }
    })
  }
    
  componentDidMount() {
    this.loadData();
 }
 render() {
    var memberList = [];
    if(this.state.data.values.length > 0 && this.state.data.status === '200') {
        this.state.data.values.sort((a, b) => b.content.registeredDate._seconds - a.content.registeredDate._seconds);
        for(var ls=0; ls < this.state.data.values.length; ls++) {
            var retResult = this.state.data.values[ls];
            var viewURL = "/IViewUserTraining/"+retResult.docid;
            var regDatez = "";
            if(retResult.content.registeredDate !== undefined) {
                regDatez = DateTimeFormats(retResult.content.registeredDate._seconds,"datetime-short");
            }
            var memberPlan = "";
            if(retResult.content.memberPlan !== undefined  && retResult.content.memberPlan !== "") {
                memberPlan = retResult.content.memberPlan;
            }
            if(retResult.content.trainingPlan !== undefined && retResult.content.trainingPlan !== "") {
                memberPlan += "\nWorkshop Plan : "+retResult.content.trainingPlan;
            }
            memberList.push(<tr key={retResult.docid}>
                <th scope="row">{ls+1}</th>
                <td>{regDatez}</td>
                <td>{retResult.content.memberName}</td>
                <td>{retResult.content.memberEmail}</td>
                <td>{memberPlan}</td>
                <td>
                    <a href={viewURL}><DescriptionIcon className="iconGrey mgR20" /></a>
                </td>
              </tr>)
        }
    } else {
        memberList.push(<tr key="1">
            <td colSpan="7" className="textCenter"><div className="mgT20 mgB20">:: no record ::</div></td>
          </tr>)
    }
    
   return (
    <div className="main-container">
      <div  className="header-main"><IHeader/></div>
          <div className="flex-container">
              <div className="sidebar-main"><ISidemenu/></div>
              <div className="main-content">
                <div className="stTitle">Users</div>
                <div className="stIndiTitle">List of Training Booking Users</div>
                <div className="mgT30">
                    {/*--*/}
                    <div className="content-container card card-body">
                        <div className="row show">
                            <div className="col-6 divMiddle">
                                
                            </div>
                            <div className="col-6 form-inline justify-content-end">
                            <button className="genButton mgL20" onClick={urladdUserTraining}><AddCircleOutlineIcon className="iconWhite mgR05"></AddCircleOutlineIcon>Add New Users</button>
                            </div>
                        </div>
                        <div className="row mgT20">
                            <table className={this.state.styleTb}>
                            <thead>
                                <tr>
                                <th scope="col">#</th>
                                <th scope="col">Register Date</th>
                                <th scope="col">Member Name</th>
                                <th scope="col">Member Email</th>
                                <th scope="col">Status</th>
                                <th scope="col" style={{width:"150px"}}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {memberList}
                            </tbody>
                            </table>
                        </div>
                        <div className="row mt-3">
                            <div className="col-6">
                                
                            </div>
                            <div className="col-6 form-inline justify-content-end">
                                
                            </div>
                        </div>
                    </div>
                    {/*--*/}
                </div>
              </div>
          </div>
          <div id="loading-area" className="divHide loading"></div>
    </div>
   );
 }
}
 
export default IUserTrainings;