import React, { Component } from 'react';
import '../iinc/iStyle.css';
import '../iinc/iGlobal';
import { FormErrors } from '../../inc/error-form';

const authKey = global.config.authBearerKey+"##"+global.config.kindcode[4];

class PICForm extends Component {
    constructor (props) {
        super(props);
        this.state = {
            stPopTitle: '',
            client_id: '',
            licencedoc_id: '',
            pic_id: '',
            pic_email: '',
            pic_phone: '',
            pic_name: '',
            pic_level: 'secondary',
            formErrors: {pic_email: '', pic_phone: '', pic_name: '', pic_level: ''},
            picemailValid: false,
            picnameValid: false,
            picphoneValid: false,
            piclevelValid: false,
            formValid: false,
            input_disabled: true
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleUserSelect = (e) => {
        const value = e.target.value;
        const inputname = e.target.name;
        if(value !== '') {
            this.setState( prevState => {
                return {  
                    ...prevState.pic_level, pic_level: value
                }
            }, () => { this.validateField(inputname, value)
            }) 
        } else {
            this.setState( prevState => {
                return {  
                    ...prevState.pic_level, pic_level: value
                }
            })
        }
      }
    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({[name]: value},
            () => { this.validateField(name, value)});
    }
    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let picemailValid = this.state.picemailValid;
        let picnameValid = this.state.picnameValid;
        let picphoneValid = this.state.picphoneValid;
        let piclevelValid = this.state.piclevelValid;
        
        switch(fieldName) {
            case 'pic_email': 
                picemailValid = value.trim().length > 0;
                fieldValidationErrors.pic_email = picemailValid ? '' : 'email need to be filled';
                break;
            case 'pic_name': 
                picnameValid = value.trim().length > 0;
                fieldValidationErrors.pic_name = picnameValid ? '' : 'name need to be filled';
                break;
            case 'pic_phone': 
                break;
            case 'pic_level': 
                piclevelValid = value.trim().length > 0;
                fieldValidationErrors.pic_level = piclevelValid ? '' : 'level need to be filled';
                break;
            default:
                break;
        }
        this.setState({formErrors: fieldValidationErrors,
                        picemailValid: picemailValid,
                        picnameValid: picnameValid,
                        picphoneValid: picphoneValid,
                        piclevelValid: piclevelValid,
                    }, this.validateForm);
    }
    validateForm() {
        this.setState({formValid: this.state.picemailValid && this.state.picnameValid && this.state.picphoneValid && this.state.piclevelValid});
    }

    errorClass(error) {
        return(error.length === 0 ? '' : 'has-error');
    }
    handleSubmit(e) {
        e.preventDefault();
        if(this.state.pic_email !== '' && this.state.pic_name !== '' && this.state.pic_level !== '' ) {
            document.getElementById('loading-area').style.display = 'block'; 
            var urlFetch, datatext;
            var picEmail="",picName="",picPhone="",picLevel="";
            if(this.textInputPicEmail.value === '') { picEmail = this.state.pic_email; } else { picEmail = this.textInputPicEmail.value; }
            if(this.textInputPicName.value === '') { picName = this.state.pic_name; } else { picName = this.textInputPicName.value; }
            if(this.textInputPicLevel.value === '') { picLevel = this.state.pic_level; } else { picLevel = this.textInputPicLevel.value; }
            if(this.textInputPicPhone.value === '') { picPhone = this.state.pic_phone; } else { picPhone = this.textInputPicPhone.value; }
            
            if(this.state.pic_id !== null && this.state.pic_id !== ''  && this.state.pic_id !== undefined) { 
                datatext ='{"pic_email":"'+picEmail+'","pic_name":"'+picName+'","pic_phone":"'+picPhone+'","pic_level":"'+picLevel+'","pic_docid":"'+this.state.pic_id+'","clientdoc_id":"'+this.state.client_id+'","licencedoc_id":"'+this.state.licencedoc_id+'","form_id":"form-add-pic"}';
                urlFetch = global.config.urlLink+"/licencepicedit";
            } else {
                datatext ='{"pic_email":"'+picEmail+'","pic_name":"'+picName+'","pic_phone":"'+picPhone+'","pic_level":"'+picLevel+'","clientdoc_id":"'+this.state.client_id+'","licencedoc_id":"'+this.state.licencedoc_id+'","form_id":"form-add-pic"}';
                urlFetch = global.config.urlLink+"/licencepicadd";
            }
            var data = JSON.parse(datatext); 
            
            fetch(urlFetch, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': authKey,
                }, body : JSON.stringify(data)
            }).then(res => {
                if(res.status === 200) 
                    return res.json() 
            }).then( resJson => {
                document.getElementById('loading-area').style.display = 'none'; 
                if(resJson.status === '200') {
                    window.location.href='/IViewLicence/'+this.state.client_id+'/'+this.state.licencedoc_id;
                } else {
                    console.log('Something happened wrong');
                }
            }).catch(err => err);

        } else {
            var arrfieldName = ['pic_email','pic_name','pic_level'];
            var arrvalue = [this.textInputPicEmail.value,this.textInputPicName.value,this.textInputPicLevel.value];
            for(var ck in arrfieldName) {
                this.validateField(arrfieldName[ck], arrvalue[ck]);
            }
        }
    }
    componentDidMount() {
        var othersContent5 = JSON.parse(document.getElementById("contentoth").innerText);
        this.setState({
            stPopTitle: othersContent5.stPopTitle,
            client_id: othersContent5.clientpic_id,
            licencedoc_id: othersContent5.licence_id,
            pic_id: othersContent5.pic_id,
            pic_email: othersContent5.pic_email,
            pic_name: othersContent5.pic_name,
            pic_phone: othersContent5.pic_phone,
            pic_level: othersContent5.pic_level,
            formErrors: {pic_email: '', pic_name: '', pic_phone: '', pic_level: ''},
            picemailValid: false,
            picnameValid: false,
            picphoneValid: false,
            piclevelValid: false,
            formValid: false,
            input_disabled: false
        }); 
    }

    render() {
        return (
            <div className="main-container">
                <div className="mgT30">
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <input type="hidden" name="client_id" value={this.state.client_id} />
                        <input type="hidden" name="licence_id" value={this.state.licencedoc_id} />
                        <input type="hidden" name="pic_id" value={this.state.pic_id} />
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="pic_name">Name</label>
                        <div className="w70p">
                            <input type="text" className="form-control" name="pic_name"
                                placeholder={this.state.pic_name}
                                value={this.state.pic_name}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputPicName = input}
                                disabled={this.state.input_disabled}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.pic_name}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="pic_phone">Phone Number</label>
                        <div className="w70p">
                            <input type="text" className="form-control" name="pic_phone"
                                placeholder={this.state.pic_phone}
                                value={this.state.pic_phone}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputPicPhone = input}
                                disabled={this.state.input_disabled}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.pic_phone}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="pic_email">Email Address</label>
                        <div className="w70p">
                            <input type="text" className="form-control" name="pic_email"
                                placeholder={this.state.pic_email}
                                value={this.state.pic_email}
                                onChange={this.handleUserInput}  
                                ref={(input) => this.textInputPicEmail = input}
                                disabled={this.state.input_disabled}/>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.pic_email}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT20">
                        <label className="w30p textMiddle" htmlFor="pic_level">Email Notification</label>
                        <div className="w70p">
                            <select name="pic_level" className="form-control"
                                    value={this.state.pic_level} 
                                    onChange={this.handleUserSelect}
                                    ref={(input) => this.textInputPicLevel = input}
                                    disabled={this.state.input_disabled}>
                                <option value="">-- please select --</option>
                                <option value="secondary">will be cc to him/her</option>
                                <option value="main">will be addressed directly to him/her</option>
                            </select>
                            <div className="mgT05">
                                { <FormErrors formErrors={this.state.formErrors.pic_level}/>  }
                            </div>
                        </div>
                    </div>
                    <div className="row-field mgT30 mgB30">
                        <div className="w100p"><button id="submit-button"  type="submit" className="w100p submitButton">Submit</button></div>
                    </div>
                </form>
                </div>
                <div id="loading-area" className="divHide loading"></div> 
            </div>
          );
        }
     }
      
export default PICForm;
